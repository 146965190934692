import React, { useState } from 'react'
import { isNull } from 'lodash'
import PropTypes from 'prop-types'
import Submit from 'components/buttons/Submit'
import { useTranslation } from 'react-i18next'

/**
 * Component that will render both Reporting buttons (Save and Save & Run)
 * and will add loading/disabled state only to the clicked button.
 * Otherwise - clicking on a single button will add the loading/disabled state to both buttons.
 */
const SubmitButtons = ({ pristineCheck, pristine, submitting, handleSubmit, onSubmit }) => {
  const [clickedButton, setClickedButton] = useState(null)
  const { t } = useTranslation()

  const handleClick = (clickedButton, shouldSave) =>
    handleSubmit((data) => {
      setClickedButton(clickedButton)
      const params = [data, ...(shouldSave ? [true] : [])]
      return onSubmit(...params)
    })

  const renderButton = (name, text, shouldSave, className) => {
    const isDisabled = !isNull(pristineCheck) ? !pristineCheck : pristine || submitting
    return (
      <Submit
        onClick={handleClick(name, shouldSave)}
        pristine={pristine}
        // Show loader only on the clicked button
        submitting={clickedButton === name && submitting}
        disabled={isDisabled || submitting}
        className={className}
      >
        {text}
      </Submit>
    )
  }

  return <div className='o-layout__item u-text--center u-1/1'>{renderButton('run', t('Global.button.run'), false, 'rounded-md')}</div>
}

SubmitButtons.propTypes = {
  pristineCheck: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default SubmitButtons
