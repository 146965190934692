import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { batch, useSelector } from 'react-redux'
import { toPng } from 'html-to-image'
import download from 'downloadjs'
import { useFetch } from 'hooks/useFetch'
import Loader from 'components/Loader'
import ReportingAnalyticsView from '../components/ReportingAnalyticsView'
import { ReportAnalyticsHeader } from '../components/ReportAnalyticsHeader'
import { createFilter } from 'utils/redux/filter'
import { getReportDetailsByIdWithFilters } from 'routes/Reporting/selectors/reportData'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import { getLegacyReportTypes } from 'redux/config/reports'
import { ReportingAnalyticsTileService } from 'services/ReportingAnalyticsTileService'
import { ReportingAnalyticsPieChartService } from 'services/ReportingAnalyticsPieChartService'
import { ReportingAnalyticsSideBarService } from 'services/ReportingAnalyticsSideBarService'
import { ReportingAnalyticsWorkForceDetailService } from 'services/ReportingAnalyticsWorkForceDetailService'
import { DateType, getMonthsDifference } from 'utils/date'

const currentDate = new Date()
const currentEndDate = new Date()
currentDate.setMonth(currentDate.getMonth() - 6)

const mapOptions = {
  dataFilteringParams: [],
  reportStartDate: `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`,
  unknownStartDate: `${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`,
  reportEndDate: `${currentEndDate.getFullYear()}-${currentEndDate.getMonth()}-${currentEndDate.getDate()}`,
  useCalculatedTileDates: true,
}

const defaultReportingProps = {
  defaultSideBarSelection: null,
  defaultSideBarSelectionFirst: null,
}
const defaultResponse = [{ data: [] }]
const ReportAnalyticsContainer = (props) => {
  const chartsRef = useRef()
  const fetchOptions = [
    {
      name: 'reportData',
      params: [
        {
          _computed: {
            filter: (state) => createFilter({ id: props.match.params.reportId }),
          },
          disableObsoleteFlow: true,
        },
      ],
      forceFetch: true,
      dependants: [{ url: null, responseField: 'dataFileUrl', reduxEnabled: false, parent: 'reportData' }],
    },
    'companies',
    'countries',
    'currencies',
    'payrolls',
    'businessUnits',
    'costCenters',
    'departments',
    'terms',
    'termCategories',
    'termSubCategories',
  ]
  const fetcherData = useFetch(fetchOptions)

  const reportingProps = useSelector((state) => {
    if (fetcherData.isFetching) return defaultReportingProps
    if (!fetcherData.areDependentsLoaded) return defaultReportingProps
    const hasErrors = fetcherData.errors.length > 0
    const receivedData = fetcherData.dependantData['reportData']?.find((items) => items.parent === 'reportData').data ?? defaultResponse
    const reportsData = !hasErrors ? receivedData : defaultResponse
    return fetchLocalProps(state, props, reportsData)
  })
  const [filteredSideBarQuery, setFilteredSideBarQuery] = useState(reportingProps.defaultSideBarSelection)
  const [filteredSideBarQueryFirst, setFilteredSideBarQueryFirst] = useState(reportingProps.defaultSideBarSelectionFirst)

  useEffect(() => {
    if (reportingProps.defaultSideBarSelection) {
      setFilteredSideBarQuery(reportingProps.defaultSideBarSelection)
    }
    if (reportingProps.defaultSideBarSelectionFirst) {
      setFilteredSideBarQueryFirst(reportingProps.defaultSideBarSelectionFirst)
    }
  }, [reportingProps.defaultSideBarSelection])

  const [hasHeadCountSortOpened, setHeadCountSort] = useState('desc')
  const [useDefaultSideBarSelect, setUseDefaultSideBarSelect] = useState(true)
  const [isExporting, setIsExporting] = useState(false)
  const onDownloadReport = useCallback(() => {
    if (chartsRef.current === null) return
    setIsExporting(true)
    setTimeout(() => {
      toPng(chartsRef.current.parentElement.parentElement, {
        cacheBust: true,
        backgroundColor: 'white',
        style: { padding: '1rem' },
      })
        .then((dataUrl) => {
          download(dataUrl)
          setIsExporting(false)
        })
        .catch((err) => console.log(err))
    }, 10)
  }, [chartsRef])
  if (!fetcherData.areDependentsLoaded) return <Loader />
  if (fetcherData.isFetching) return <Loader />
  return (
    <>
      <ReportAnalyticsHeader report={reportingProps.report} downloadReport={onDownloadReport} hideOnExport={isExporting} />
      <div ref={chartsRef}>
        <ReportingAnalyticsView
          reportDetail={reportingProps.reportDetail}
          hasHeadCountSortOpened={hasHeadCountSortOpened}
          setHeadCountSort={setHeadCountSort}
          isExporting={isExporting}
          sideBarQuery={filteredSideBarQuery}
          sideBarQueryFirst={filteredSideBarQueryFirst}
          useDefaultSideBarSelect={useDefaultSideBarSelect}
          fetchedErrors={fetcherData.errors}
          onSideBarFilterChange={(v) => {
            batch(() => {
              setUseDefaultSideBarSelect(v.default)
              setFilteredSideBarQuery(v.value)
            })
          }}
          onFirstFilterChange={(v) => {
            batch(() => {
              setUseDefaultSideBarSelect(v.default)
              setFilteredSideBarQueryFirst(v.value)
            })
          }}
          {...props}
          {...reportingProps}
        />
      </div>
    </>
  )
}

ReportAnalyticsContainer.propTypes = {
  reportDetail: PropTypes.object,
  report: PropTypes.object,
  match: PropTypes.object,
  defaultSideBarSelection: PropTypes.string,
  defaultSideBarSelectionFirst: PropTypes.string,
}

const fetchLocalProps = (state, props, fetchedData) => {
  let fromDate = null
  let toDate = null
  let dateDifference = 0
  let report = getReportDetailsByIdWithFilters(state, { reportId: props.reportId || props.match.params.reportId })
  report['data'] = fetchedData

  const builder = new ReportItemBuilder()

  const reportNameCategory = report?.feCategoryId ? builder.getReportNameCategory(report.feCategoryId) : null
  const charts = report?.feCategoryId ? builder.getReportGraphsByCategoryId(report.feCategoryId) : null
  const chartLogic = report?.feCategoryId ? builder.getReportGraphsLogicByCategoryId(report.feCategoryId) : null
  mapOptions['CHART_LOGIC'] = chartLogic

  if (report.filters.fromDate) {
    fromDate = new DateType(report.filters.fromDate)
  }

  if (report.filters.toDate) {
    toDate = new DateType(report.filters.toDate)
  }

  if (toDate && fromDate) {
    const fromDateStartOfDay = fromDate
    const toDateEndOfDay = toDate

    toDateEndOfDay.setHours(0, 0, 0, 0)
    fromDateStartOfDay.setHours(0, 0, 0, 0)

    toDateEndOfDay.setUTCDate(toDateEndOfDay.getUTCDate() + 1)

    dateDifference = getMonthsDifference(fromDateStartOfDay, toDateEndOfDay)
  }

  if (report.filters) {
    if (report.filters.fromDate) {
      mapOptions['reportStartDate'] = report.filters.fromDate
    }

    if (report.filters.toDate) {
      mapOptions['reportEndDate'] = report.filters.toDate
    }
  }

  const { data } = report
  const reportData = data ? data[0] : []
  const otherSheet = data && data[0]?.otherSheets ? data[0]?.otherSheets[0].data : []
  const tileService = new ReportingAnalyticsTileService(reportData, otherSheet, mapOptions, charts, chartLogic)
  const pieChartService = new ReportingAnalyticsPieChartService(reportData, otherSheet, mapOptions, charts, chartLogic)
  const sideBarService = new ReportingAnalyticsSideBarService(reportData, otherSheet, mapOptions, charts, chartLogic)
  const workForceDetailService = new ReportingAnalyticsWorkForceDetailService(reportData, otherSheet, mapOptions, charts, chartLogic, data[0]?.filters)

  tileService.processReportData()
  pieChartService.processReportData()
  sideBarService.processReportData()
  workForceDetailService.processReportData()

  const types = getLegacyReportTypes()
  report['foundType'] = types.find((type) => type.type === report.type)
  let cat = report['foundType'].categories.find((cat) => cat.value === report.category).label
  let subCat = report['foundType'].subcategories.find((subcat) => subcat.value === report.subcategory).label
  if (subCat === 'Terms') subCat = report.filters.termCategoryNames.join(', ')

  report['cat'] = cat
  report['subCat'] = subCat
  report['name'] = report.feCategoryId ? reportNameCategory : report['foundType'].name
  report['categoryName'] = report.feCategoryId ? builder.getReportTypeCategory(report.feCategoryId) : null
  // After 24 months show quarters
  // After 6 years show every 6 months
  // After 12 years show every year
  // After 24 years show every 2 years
  // Library is 0 based not 1 based
  let lineGraphIntervals = 0
  switch (true) {
  case dateDifference >= 288:
    lineGraphIntervals = 23
    break
  case dateDifference >= 144:
    lineGraphIntervals = 11
    break
  case dateDifference >= 72:
    lineGraphIntervals = 5
    break
  case dateDifference > 24:
    lineGraphIntervals = 3
    break

  default:
    lineGraphIntervals = 0
    break
  }
  return {
    report,
    builder,
    charts,
    chartLogic,
    data: report.data[0].data,
    tileService,
    pieChartService,
    sideBarService,
    reportNameCategory,
    workForceDetailService,
    noFromDateSet: !report.filters.fromDate || !report.filters.toDate,
    dateRangeDifferent: dateDifference,
    lineGraphIntervals: lineGraphIntervals,
    defaultSideBarSelection: chartLogic.SIDE_BAR_FILTER_OPTIONS ? chartLogic.SIDE_BAR_FILTER_OPTIONS.find((i) => i.default)?.value : 'country',
    defaultSideBarSelectionFirst: chartLogic.SIDE_BAR_FILTER_OPTIONS_FIRST ? chartLogic.SIDE_BAR_FILTER_OPTIONS_FIRST.find((i) => i.default).value : 'element',
  }
}

export default ReportAnalyticsContainer
