import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import usePageUIState from 'hooks/usePageUIState'
import PersonalView from '../components/PersonalView'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import { InlineTableActionButtons } from 'components/buttons/InlineTableActionButtons'
import { getFieldsBySection, renderInlineEditFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import { batch, useDispatch } from 'react-redux'
import { fetchEmployeeSystemUsers, updateEmployeeSystemUser } from 'redux/actions/employeeSystemUsers'
import isObject from 'lodash/isObject'
import omit from 'lodash/omit'
import { deleteBankAccount, fetchBankAccounts, updateBankAccount, updateInlineBankAccounts } from 'redux/actions/bankAccounts'
import {
  buildBankAccountData,
  buildEmployeeAddressData,
  validateAddressPrimarys,
  validateBankAccountPrimarys,
  validateTooManyAddressPrimarys,
  validateTooManyBankAccountPrimarys,
} from './helpers'
import ConfirmationModal from 'components/ConfirmationModal'
import { showMessage } from 'redux/actions/modal'
import { phoneValidation } from 'utils/validations'
import getAddressFields from 'redux/config/employeeAddressFieldsConfig'
import {
  deleteEmployeeUserAddresses,
  fetchEmployeeUserAddresses,
  updateEmployeeUserAddresses,
  updateInlineAddresses,
} from 'redux/actions/employeeUserAddresses'
import DeleteEmployeeAddressView from '../components/DeleteEmployeeAddressView'
import DeleteEmployeeBankAccountView from '../components/DeleteEmployeeBankAccountView'
import { createFilter } from 'utils/redux/filter'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
const saveOptions = {
  dateFields: ['dateOfBirth', 'dateIssue', 'expiryDate'],
  shouldFetch: false,
  shouldInvalidate: true,
  returnInlineErrors: true,
}
const getSaveMessage = (t) => t('Global:Global.message.saveSuccess')
export const PersonalViewContainer = (props) => {
  const {
    employee,
    typeOfEmploymentSelectedValue,
    EmployeeStatus,
    isEmployeeOnly,
    isFetching,
    companies,
    countries,
    bankAccounts,
    mobileFieldOptions,
    extraFields,
  } = props
  const dispatch = useDispatch()
  const confirmCancelModal = useRef(null)
  const deleteBankAccountModal = useRef(null)
  const deleteAddressModal = useRef(null)
  const [bankAccountId, setBankAccountId] = useState(null)
  const [employeeAddressId, setEmployeeAddressId] = useState(null)
  const [nextPrimaryRecord, setNextPrimaryAddress] = useState(null)
  const [nextPrimaryBankAccount, setNextPrimaryBankAccount] = useState(null)
  const [globalAddressErrors, setGlobalAddressErrors] = useState(false)
  const [globalAddressTooManyErrors, setGlobalAddressTooManyErrors] = useState(false)
  const [globalBankAccountErrors, setGlobalBankAccountErrors] = useState(false)
  const [globalBankAccountTooManyErrors, setGlobalBankAccountTooManyErrors] = useState(false)

  if (isFetching) return <div>Loading...</div>

  saveOptions['mobilePhoneOptions'] = mobileFieldOptions

  const isContractorCompany = employee ? employee.isContractorCompany : EmployeeStatus.isContractorCompany(typeOfEmploymentSelectedValue)
  const isContractorAgency = employee ? employee.isContractorAgency : EmployeeStatus.isContractorAgency(typeOfEmploymentSelectedValue)
  const isShortTermAssignment = employee ? employee.isShortTermAssignment : EmployeeStatus.isShortTermAssignment(typeOfEmploymentSelectedValue)
  const isShortTermAssignmentHost = employee ? employee.isShortTermAssignmentHost : EmployeeStatus.isShortTermAssignmentHost(typeOfEmploymentSelectedValue)
  const showVatRegistrationNumber = isContractorCompany || isContractorAgency

  const getEmployeePersonalFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee: employee,
    section: 'personal',
    isEmployeeOnly: isEmployeeOnly,
    subSection: 'personalDetails',
    extraFields,
  })

  const getEmployeeContactFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee: employee,
    section: 'personal',
    subSection: 'contactDetails',
    extraFields,
  })

  getEmployeeContactFields.map((field) => {
    if (field.field === 'mobilePhone' && mobileFieldOptions.isDisabled) field['disabledOptions'] = mobileFieldOptions
    return field
  })

  const getEmployeeBiographicalFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee: employee,
    section: 'personal',
    subSection: 'biographicalDetails',
    extraFields,
  })

  const getEmployeeTaxFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee: employee,
    section: 'personal',
    subSection: 'taxDetails',
    extraFields,
  })

  const getContractorFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({ isEdit: true }),
    employee: employee,
    section: 'personal',
    subSection: 'contractorDetails',
    showVatRegistrationNumber,
    isContractorCompany,
    isContractorAgency,
    extraFields,
  })

  const getEmergencyPersonFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee: employee,
    section: 'personal',
    subSection: 'emergencyPersonDetails',
    extraFields,
  })

  const getEmployeePersonaBankFields = (account) =>
    getFieldsBySection({
      fields: employeeFieldsConfigAlt({}),
      employee: employee,
      section: 'personal',
      subSection: 'bankDetails',
      account,
      companies,
      countries,
      extraFields,
    })

  const defaults = {
    biographicalDetails: renderInlineEditFields(getEmployeeBiographicalFields),
    contactDetails: renderInlineEditFields(getEmployeeContactFields),
    personalDetails: renderInlineEditFields(getEmployeePersonalFields),
    taxDetails: renderInlineEditFields(getEmployeeTaxFields),
    emergencyPersonDetails: renderInlineEditFields(getEmergencyPersonFields),
    employeeUsersAddressDetails: {},
    bankDetails: {},
    contractorDetails: [],
  }
  if (employee.employeeAddresses) {
    employee.employeeAddresses.forEach((address) => {
      let result = []
      getAddressFields().forEach((add) => {
        result.push({
          ...add,
          value: address[add.field],
        })
      })
      defaults['employeeUsersAddressDetails'][address.id] = renderInlineEditFields(result, address.id)
    })
  }

  if (bankAccounts) {
    bankAccounts.forEach((account) => {
      let result = []
      getEmployeePersonaBankFields(account).forEach((field) => {
        if (field.field === 'payrollCountry') return
        if (field.field === 'company') return
        if (field.field === 'bankCountry') {
          result.push({
            ...field,
            value: account.bankCountryName,
          })
        } else if (field.field === 'currency') {
          result.push({
            ...field,
            value: account.currencyAbbr,
          })
        } else if (field.field === 'primaryBankAccount') {
          result.push({
            ...field,
            value: account.primaryBankAccount,
          })
        } else {
          result.push({
            ...field,
            value: account[field.field] || ' ',
          })
        }
      })
      if (account.customFields) {
        account.customFields.forEach((field) => {
          result.push({
            ...field,
            name: field.printable_name,
            apiName: field.name,
            subCategory: 'bankDetails',
            value: account[field.name] || ' ',
          })
        })
      }
      defaults['bankDetails'][account.id] = renderInlineEditFields(result, account.id)
    })
  }

  if (isContractorCompany || isContractorAgency || isShortTermAssignment || isShortTermAssignmentHost) {
    defaults['contractorDetails'] = renderInlineEditFields(getContractorFields)
  }

  const [data, setData] = useState(defaults)
  const [isUpdating, setIsUpdating] = useState(false)
  const [originalData] = useState(data)
  const [fieldErrors, setFieldErrors] = useState([])
  const { setPageUIState, inEditMode } = usePageUIState({})
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const onEdit = () => {
    setPageUIState(pageUIStatesEnum.EDIT)
  }

  const onCancel = () => {
    setData(originalData)
    setGlobalAddressErrors(false)
    setGlobalAddressTooManyErrors(false)
    setGlobalBankAccountErrors(false)
    setGlobalAddressTooManyErrors(false)
    setPageUIState(pageUIStatesEnum.VIEW)
    setFieldErrors([])
  }

  const onValueChange = () => {
    setFieldErrors([])
  }

  const onGlobalErrorDetected = (globalErrors, param = '') => {
    if (globalErrors.addresses) {
      if (param === 'none') {
        setGlobalAddressErrors(true)
      }
      if (param === 'toomany') {
        setGlobalAddressTooManyErrors(true)
      }
    }
    if (globalErrors.bankAccounts) {
      if (param === 'none') {
        setGlobalBankAccountErrors(true)
      }
      if (param === 'toomany') {
        setGlobalBankAccountTooManyErrors(true)
      }
    }

    setIsUpdating(false)
  }

  const onSave = () => {
    if (!isUpdating) setIsUpdating(true)
    if (isUpdating) return
    // To Build up later if we want more top level errors
    let hasGlobalErrors = false
    let globalErrors = { addresses: false, type: '' }

    let errors = []
    const bankAccountUpdates = data['bankDetails']
    const employeeAddressesUpdates = data['employeeUsersAddressDetails']
    const values = Object.values(omit(data, ['bankDetails', 'employeeUsersAddressDetails'])).flat()
    const payload = { id: employee.id }
    values.forEach((val) => {
      if (val.metaData?.component && val.metaData.component === 'phone') {
        if ((val.value.trim() !== '' || !val.value) && phoneValidation(val.value)) {
          errors.push(t('Employee.phone.validation.issue'))
        }
      }
    })

    if (errors.length) {
      setIsUpdating(false)
      return
    }

    values.forEach((val) => {
      payload[val.metaData.field] = typeof val.value === 'string' ? val.value.trim() : val.value
      // If value for mobilePhone is empty string, send `null` value to the BE
      if ((val.metaData?.field === 'mobilePhone' || val.metaData?.field === 'emergencyMobilePhone') && !payload[val.metaData.field]) {
        payload[val.metaData.field] = null
      }
    })

    const bankAccountUpdatesCalls = buildBankAccountData(bankAccountUpdates, bankAccounts)
    const employeeAddressUpdates = buildEmployeeAddressData(employeeAddressesUpdates, employee)
    const hasNoPrimaryAddress = validateAddressPrimarys(employeeAddressUpdates)
    const hasTooManyPrimaryAddress = validateTooManyAddressPrimarys(employeeAddressUpdates)
    const hasNoPrimaryBankAccount = validateBankAccountPrimarys(bankAccountUpdates)
    const hasTooManyPrimaryBankAccounts = validateTooManyBankAccountPrimarys(bankAccountUpdates)

    if (hasNoPrimaryAddress) {
      globalErrors.addresses = true
      hasGlobalErrors = true
    }

    if (hasTooManyPrimaryAddress) {
      globalErrors.addresses = true
      hasGlobalErrors = true
    }

    if (hasNoPrimaryBankAccount) {
      globalErrors.bankAccounts = true
      hasGlobalErrors = true
    }

    if (hasTooManyPrimaryBankAccounts) {
      globalErrors.bankAccounts = true
      hasGlobalErrors = true
    }

    if (globalAddressErrors && !hasNoPrimaryAddress) {
      setGlobalAddressErrors(false)
    }

    if (globalAddressErrors && !hasTooManyPrimaryAddress) {
      setGlobalAddressTooManyErrors(false)
    }

    if (globalBankAccountErrors && !hasNoPrimaryBankAccount) {
      setGlobalBankAccountErrors(false)
    }

    if (globalBankAccountErrors && !hasNoPrimaryBankAccount) {
      setGlobalBankAccountTooManyErrors(false)
    }

    if (hasGlobalErrors) {
      if (hasNoPrimaryAddress) {
        onGlobalErrorDetected(globalErrors, 'none')
      }
      if (hasTooManyPrimaryAddress) {
        onGlobalErrorDetected(globalErrors, 'toomany')
      }
      if (hasNoPrimaryBankAccount) {
        onGlobalErrorDetected(globalErrors, 'none')
      }
      if (hasTooManyPrimaryBankAccounts) {
        onGlobalErrorDetected(globalErrors, 'toomany')
      }

      return
    }

    saveOptions['fields'] = extraFields
    batch(() => {
      dispatch(updateInlineAddresses(employeeAddressUpdates)).then((res) => {
        dispatch(updateInlineBankAccounts(bankAccountUpdatesCalls)).then((res) => {
          dispatch(updateEmployeeSystemUser(payload, saveOptions)).then((resp) => {
            if (resp.errors) {
              batch(() => {
                setFieldErrors([...fieldErrors, resp.errors])
                setIsUpdating(false)
              })
            } else {
              dispatch(fetchEmployeeUserAddresses({ filter: createFilter({ employeeUser: employee.id }) })).then((r) => {
                dispatch(fetchBankAccounts({ filter: createFilter({ employee: employee.id }) })).then(() => dispatch(showMessage({ body: getSaveMessage(t) })))
              })
            }
          })
        })
      })
    })
  }

  const onUpdateGlobalDataForSave = (rowIndex, columnId, value, { metaData }) => {
    setData((old) => {
      if (old[metaData.subCategory]?.length) {
        return {
          ...old,
          [metaData.subCategory]: old[metaData.subCategory].map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[metaData.subCategory][rowIndex],
                [columnId]: value,
                isDirty: true,
              }
            }
            return row
          }),
        }
      }
      if (isObject(old[metaData.subCategory])) {
        let newRow = {}
        Object.keys(old[metaData.subCategory]).forEach((accountId) => {
          newRow[accountId] = old[metaData.subCategory][accountId].map((row, index) => {
            if (index === rowIndex && parseInt(accountId, 10) === metaData.ownerId) {
              return {
                ...old[metaData.subCategory][accountId][rowIndex],
                [columnId]: value,
                isDirty: true,
              }
            }
            return row
          })
        })

        return {
          ...old,
          [metaData.subCategory]: newRow,
        }
      }
    })
  }

  const onDeleteBankAccount = (bankAccountId) => {
    setBankAccountId(bankAccountId)
    deleteBankAccountModal.current.showModal()
  }

  const onDeleteEmployeeAddress = (addressId) => {
    setEmployeeAddressId(addressId)
    deleteAddressModal.current.showModal()
  }

  const onDeleteWithPrimarySwap = () => {
    dispatch(deleteEmployeeUserAddresses(employeeAddressId, false, true)).then((res) => {
      if (!res.errors) {
        batch(() => {
          dispatch(updateEmployeeUserAddresses({ isPrimary: true }, nextPrimaryRecord, false, true))
          dispatch(fetchEmployeeUserAddresses({ filter: createFilter({ employeeUser: employee.id }) }))
          dispatch(fetchEmployeeSystemUsers({ filter: createFilter({ id: employee.id }) }))
        })
      }
    })
  }

  const onDeleteBankAccountWithPrimarySwap = () => {
    const newPrimaryBankAccount = bankAccounts.find((ba) => ba.id === +nextPrimaryBankAccount)
    const updateBankAccountPayload = {
      primaryBankAccount: true,
      bankCountry: newPrimaryBankAccount.bankCountry,
      currency: newPrimaryBankAccount.currency,
    }
    dispatch(deleteBankAccount(bankAccountId, false, true)).then((res) => {
      if (!res.errors) dispatch(updateBankAccount(updateBankAccountPayload, nextPrimaryBankAccount, false, true))
    })
  }

  const onDeleteAddressWithNoSwap = () => {
    dispatch(deleteEmployeeUserAddresses(employeeAddressId, false, true)).then((res) => {
      if (!res.errors) {
        batch(() => {
          dispatch(fetchEmployeeUserAddresses({ filter: createFilter({ employeeUser: employee.id }) }))
          dispatch(fetchEmployeeSystemUsers({ filter: createFilter({ id: employee.id }) }))
          setNextPrimaryAddress(null)
        })
      }
    })
  }

  const onDeleteBankAccountWithNoSwap = () => {
    dispatch(deleteBankAccount(bankAccountId, false, true)).then((res) => {
      if (!res.errors) setNextPrimaryAddress(null)
    })
  }

  const onConfirmDeleteAddress = () => {
    if (nextPrimaryRecord) {
      onDeleteWithPrimarySwap()
      return false
    }
    onDeleteAddressWithNoSwap()
  }

  const onConfirmDeleteBankAccount = () => {
    if (nextPrimaryBankAccount) {
      onDeleteBankAccountWithPrimarySwap()
      return false
    }
    onDeleteBankAccountWithNoSwap()
  }
  const selectedAddressIsNotPrimary = employee.employeeAddresses.find((addr) => addr.id === employeeAddressId)
  const selectedBankAccountIsNotPrimary = bankAccounts.find((ba) => ba.id === bankAccountId)
  return (
    <>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile ' : null}>
        <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
          <SectionHeading>
            <div className='o-layout__item u-1/1 u-1/2@desktop'>
              <div className='u-float--right'>
                {inEditMode && <InlineTableActionButtons onCancel={confirmCancelModal.current.showModal} onSave={onSave} isUpdating={isUpdating} />}
                <div className={`${btnClasses} ${inEditMode ? 'disabled' : ''}`} onClick={onEdit} disabled={inEditMode} data-testid='employee-edit'>
                  <span className='icon icon--edit' />
                </div>
              </div>
            </div>
          </SectionHeading>
        </Authorization>
      </div>
      <div className='page--personal-view-container'>
        <PersonalView
          data={data}
          inEditMode={inEditMode}
          fieldErrors={fieldErrors}
          onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
          onDeleteBankAccount={onDeleteBankAccount}
          onDeleteEmployeeAddress={onDeleteEmployeeAddress}
          extraFields={extraFields}
          {...props}
          globalAddressErrors={globalAddressErrors}
          globalAddressTooManyErrors={globalAddressTooManyErrors}
          globalBankAccountErrors={globalBankAccountErrors}
          globalBankAccountTooManyErrors={globalBankAccountTooManyErrors}
          employeeAddresses={employee.employeeAddresses}
          onValueChange={onValueChange}
        />
      </div>
      <ConfirmationModal
        ref={deleteBankAccountModal}
        bodyText={t('Employee.modal.confirm_remove_bank_account')}
        className={`c-modal-delete-bank-account-confirm c-modal ${bankAccounts.length > 1 ? 'c-modal--half' : ''}`}
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={onConfirmDeleteBankAccount}
        disableConfirmButton={bankAccounts.length > 1 && !nextPrimaryBankAccount && selectedBankAccountIsNotPrimary?.primaryBankAccount}
      >
        <DeleteEmployeeBankAccountView onSelectChange={(val) => setNextPrimaryBankAccount(val)} bankAccounts={bankAccounts} selectedId={bankAccountId} />
      </ConfirmationModal>
      <ConfirmationModal
        ref={deleteAddressModal}
        bodyText={t('Employee.modal.confirm_remove_address')}
        className={`c-modal-delete-address-confirm c-modal ${employee.employeeAddresses.length > 1 ? 'c-modal--half' : ''}`}
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={onConfirmDeleteAddress}
        disableConfirmButton={employee.employeeAddresses.length > 1 && !nextPrimaryRecord && selectedAddressIsNotPrimary?.isPrimary}
      >
        <DeleteEmployeeAddressView onSelectChange={(val) => setNextPrimaryAddress(val)} addresses={employee.employeeAddresses} selectedId={employeeAddressId} />
      </ConfirmationModal>
      <ConfirmationModal ref={confirmCancelModal} className='c-modal' modalHeading={t('Global:Global.modal.confirmation')} onConfirm={() => onCancel()}>
        <p>{t('Employee.modal.cancel_all_changes')}</p>
      </ConfirmationModal>
    </>
  )
}

PersonalViewContainer.propTypes = {
  isEmployeeOnly: PropTypes.bool,
  employee: PropTypes.object,
  mobileFieldOptions: PropTypes.object,
  typeOfEmploymentSelectedValue: PropTypes.string,
  EmployeeStatus: PropTypes.object,
  isFetching: PropTypes.object,
  companies: PropTypes.array,
  countries: PropTypes.array,
  bankAccounts: PropTypes.array,
  extraFields: PropTypes.object,
}
