import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const AssignmentListOptions = ({ selectAll, deselectAll }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [isSelectAll, setIsSelectAll] = useState(true)
  const { t } = useTranslation()

  const handleSelectState = () => {
    if (isSelectAll) {
      selectAll()
    } else {
      deselectAll()
    }
    setShowOptions(false)
    setIsSelectAll((prev) => !prev)
  }
  const handleOptions = () => setShowOptions((prev) => !prev)

  return (
    <div className='u-float--right u-relative'>
      <span onClick={handleOptions} className='icon icon--dots  u-cursor--pointer' />
      {showOptions && (
        <div className='c-draggableboard__options'>
          <ul className='o-list-bare u-margin-none'>
            <li onClick={handleSelectState} className='u-cursor--pointer'>
              {isSelectAll ? t('Global.button.select_all') : t('Global.button.deselect_all')}
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

AssignmentListOptions.propTypes = {
  selectAll: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
}

export default AssignmentListOptions
