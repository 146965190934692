// Translation Keys
import { i18nNameSpaces } from 'i18n/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import DataProtectionFieldsListForm from 'components/DataProtectionFieldsListForm'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'

const ApproveFieldsForm = (props) => {
  const { t } = useTranslation([i18nNameSpaces.DataProtection])
  const { latestApprovedDateAuthor, isApproved, showApproveMsgBtn } = props

  return (
    <Form {...props}>
      {
        /**  Check if there are any approved fields to show the message and if the last approval is made by an employee or COT.
         **  If latest changes are made by COT, the modifiedBy property will be null **/
        latestApprovedDateAuthor?.hasApprovedFields && (
          <div className='text-center'>
            <p className='u-text--small'>
              {t('Global:Global.text.current_list_of_payroll_sensitive_fields_approved_on')}{' '}
              <span className='u-weight--bold'>
                {latestApprovedDateAuthor.modifiedAt}
                {latestApprovedDateAuthor.modifiedBy ? t('Global:Global.text.by_author', { author: latestApprovedDateAuthor.modifiedBy }) : null}
              </span>
            </p>
          </div>
        )
      }
      <DataProtectionFieldsListForm {...props} />
      <div className='u-text--center'>
        {showApproveMsgBtn && (
          <Field
            type='checkbox'
            component={CheckboxField}
            className='control__indicator__input'
            label={t('DataProtection.checkbox.approve_message')}
            name='approve'
          />
        )}
        {isApproved && showApproveMsgBtn && (
          <button type='submit' className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right' title={t('DataProtection.button.approve')}>
            {t('DataProtection.button.approve')}
          </button>
        )}
      </div>
    </Form>
  )
}

ApproveFieldsForm.propTypes = {
  latestApprovedDateAuthor: PropTypes.shape({
    hasApprovedFields: PropTypes.bool,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  isApproved: PropTypes.bool,
  showApproveMsgBtn: PropTypes.bool,
}

export default ApproveFieldsForm
