import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const VendorHeader = ({ vendor: { name, registrationNumber } }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className='o-media o-grid o-grid--middle'>
      <div className='o-media__body line-height--20'>
        <span className='u-weight--bold u-text--large'>{name}</span>
        <span>{t('BusinessComponent.header.registrationNumber', { registrationNumber })}</span>
      </div>
    </div>
  )
}

VendorHeader.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    registrationNumber: PropTypes.string.isRequired,
  }),
}

export default VendorHeader
