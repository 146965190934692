import CoreLayout from 'layouts/PageLayout/PageLayout'
import CalendarRoute from './routes/Calendar'
import ActionsRoute from './routes/Actions'
import NotificationsRoute from './routes/Notifications'
import InnerWrapper from 'layouts/InnerWrapper'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/toolbox',
  exact: true,
  skipChildren: true,
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Toolbox, InnerWrapper) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [CalendarRoute(store), ActionsRoute(store), NotificationsRoute(store)],
})
