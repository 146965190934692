import { i18nNameSpaces } from 'i18n/types'
import React, { useRef } from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DocumentUploadForm = (props) => {
  const { disabled, disabledReason, showSubmitBtn, isPayroll, payrolls, uploadFile, resetForm } = props
  const dropzoneRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className='o-layout'>
      <Form>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='documentType'
              label={t('BusinessComponent.form.documentType')}
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.text.category')}
              disabled={disabled}
              disabledReason={disabledReason}
              onChange={resetForm}
              options={[
                {
                  value: 'Company',
                  label: t('Global:Global.text.general'),
                },
                {
                  value: 'Payroll',
                  label: t('Global:Global.text.payroll'),
                },
              ]}
              data-testid='documentType-wrapper'
            />
          </div>
        </div>
        {isPayroll && (
          <div className='o-layout__item u-margin-top u-1/1'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='payrollId'
                label={t('BusinessComponent.form.payrollLabel')}
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                disabled={disabled}
                disabledReason={disabledReason}
                options={payrolls.map((payroll) => ({
                  value: payroll.id,
                  label: payroll.name,
                }))}
              />
            </div>
          </div>
        )}
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-padding-small'>
            <p className='u-text--small u-text--center'>{t('BusinessComponent.form.securityNotice')}</p>
          </div>
        </div>
        {showSubmitBtn && (
          <div className='o-layout__item u-text--center u-margin-top u-1/1 u-1/1@tablet'>
            <button
              type='button'
              className='c-btn c-btn--small c-btn--curious u-text--small u-margin-left-tiny u-align-self--center'
              onClick={() => dropzoneRef.current.open()}
            >
              {t('Global:Global.select.selectFile')}
            </button>
          </div>
        )}
      </Form>
      <Dropzone onDrop={(files) => uploadFile(files[0])} ref={dropzoneRef} className='u-relative' />
    </div>
  )
}

DocumentUploadForm.propTypes = {
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showSubmitBtn: PropTypes.bool,
  isPayroll: PropTypes.bool,
  payrolls: PropTypes.array,
  uploadFile: PropTypes.func,
  resetForm: PropTypes.func,
}

export default DocumentUploadForm
