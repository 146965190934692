import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DownloadTemplateView = ({ downloadTemplate }) => {
  const { t } = useTranslation()
  return (
    <div className='u-margin-bottom'>
      <button
        className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
        title={t('Global.text.download_template')}
        onClick={downloadTemplate}
      >
        <span className='icon icon--download' />
      </button>
    </div>
  )
}

DownloadTemplateView.propTypes = {
  downloadTemplate: PropTypes.func.isRequired,
}

export default DownloadTemplateView
