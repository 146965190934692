import React from 'react'
import SectionHeading from 'components/SectionHeading'
import Select from 'react-select'
import PropTypes from 'prop-types'

export const ChangeLanguageView = ({ value, options, onChange, isDisabled, disabledTooltip, t }) => {
  return (
    <>
      <SectionHeading text={t('Setting.user.change_language')} className='u-margin-top' />
      <div title={disabledTooltip} className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop u-margin-left-neg-24px'>
        <Select
          className='c-custom-select'
          disabled={isDisabled}
          value={value}
          labelClassName='c-label'
          options={options}
          clearable={false}
          onChange={onChange}
        />
      </div>
    </>
  )
}
ChangeLanguageView.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  disabledTooltip: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}
