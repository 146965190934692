import i18n from 'i18next'

export const reportIdsEnum = {
  HR: 'HR',
  FINANCE: 'FINANCE',
  PAYROLL: 'PAYROLL',
  CONTROL_AND_GOVERNANCE: 'CONTROL_AND_GOVERNANCE',
  REPORT_DESIGNER: 'REPORT_DESIGNER',
}

export const getReportGroupingLabels = () => ({
  [reportIdsEnum.HR]: i18n.t('Report:Report.category.hr_reports'),
  [reportIdsEnum.FINANCE]: i18n.t('Report:Report.category.finance_reports'),
  [reportIdsEnum.PAYROLL]: i18n.t('Report:Report.category.payroll_reports'),
  [reportIdsEnum.CONTROL_AND_GOVERNANCE]: i18n.t('Report:Report.category.control_and_governance_reports'),
  [reportIdsEnum.REPORT_DESIGNER]: i18n.t('Report:Report.category.report_designer'),
})

export const getReportGroupingDescription = () => ({
  HR: i18n.t('Report:Report.config.hr_description'),
  FINANCE: i18n.t('Report:Report.config.finance_description'),
  PAYROLL: i18n.t('Report:Report.config.payroll_description'),
  CONTROL_AND_GOVERNANCE: i18n.t('Report:Report.config.control_and_governance_description'),
})

export const reportSubcategoriesEnum = {
  TotalEmployerCosts: 'TotalEmployerCosts',
  VarianceReport: 'VarianceReport',
  OrgUnitVarianceReport: 'OrgUnitVarianceReport',
  WorkforceVarianceReport: 'WorkforceVarianceReport',
  EmployeePayrollChanges: 'EmployeePayrollChanges',
  PayAndTermsChanges: 'PayAndTermsChanges',
  GeneralUpdates: 'GeneralUpdates',
  RolesAndRights: 'RolesAndRights',
  GlobalPayrunManagement: 'GlobalPayrunManagement',
  PayrollInstance: 'PayrollInstance',
  ChangelogReportAll: 'ChangeLogReport-All',
  OrgUnitCostsReport: 'OrgUnitCostsReport',
}

export const reportsWithOnlyOneCountryToSelect = [
  reportSubcategoriesEnum.TotalEmployerCosts,
  reportSubcategoriesEnum.VarianceReport,
  reportSubcategoriesEnum.OrgUnitVarianceReport,
  reportSubcategoriesEnum.EmployeePayrollChanges,
  reportSubcategoriesEnum.PayAndTermsChanges,
  reportSubcategoriesEnum.GeneralUpdates,
  reportSubcategoriesEnum.RolesAndRights,
  reportSubcategoriesEnum.PayrollInstance,
  reportSubcategoriesEnum.WorkforceVarianceReport,
]
export const reportsWithOnlyOneCompanyToSelect = [
  reportSubcategoriesEnum.OrgUnitVarianceReport,
  reportSubcategoriesEnum.PayAndTermsChanges,
  reportSubcategoriesEnum.GeneralUpdates,
  reportSubcategoriesEnum.RolesAndRights,
  reportSubcategoriesEnum.PayrollInstance,
  reportSubcategoriesEnum.WorkforceVarianceReport,
]

export const reportsWithCombinedSubCategory = [reportSubcategoriesEnum.ChangelogReportAll]

// Dynamic selectors are for Country and Company only!
export const reportsWithDynamicSelectors = [reportSubcategoriesEnum.GlobalPayrunManagement]

export const reportsWithSinglePayroll = [
  reportSubcategoriesEnum.PayrollInstance,
  reportSubcategoriesEnum.VarianceReport,
  reportSubcategoriesEnum.WorkforceVarianceReport,
  reportSubcategoriesEnum.OrgUnitVarianceReport,
]

export const feReportIDsWithSingleCountryCompanyPayroll = [51]

export const reportsWithRemovedFields = {
  ChangeLogReport: ['payrollInstance'],
}
