import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import ApproveFieldsContainer from '../containers/ApproveFieldsContainer'
import { Authorization } from 'containers/Authorization'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { CheckIconAlt } from 'components/icons/CheckIcons'
import { i18nNameSpaces } from 'i18n/types'

const PersonalDataList = ({ dataFields, ...props }) => {
  const approveFieldsModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.DataProtection])

  const showModal = () => {
    if (approveFieldsModalRef.current) {
      approveFieldsModalRef.current.showModal()
    }
  }

  return (
    <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>
      <Modal ref={approveFieldsModalRef} className='c-modal c-modal--half c-modal--overflow-y' modalHeading={t('DataProtection.button.approve_fields')}>
        <ApproveFieldsContainer isApproveModal {...props} />
      </Modal>
      <Authorization permissions={['COMPANYDATAPROTECTIONFIELDSTATE_CREATE']}>
        <div
          onClick={showModal}
          className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom'
          title={t('DataProtection.button.approve_fields')}
        >
          {t('DataProtection.button.approve_fields')}
        </div>
      </Authorization>
      <PayslipTable
        data={dataFields.map((field) => ({
          field: (
            <div className='o-layout'>
              <div className='o-layout__item u-2/3'>
                <span className='u-text--normal'>{field.name}</span>
              </div>
              <div className='o-layout__item u-1/3'>
                {field.hasData ? <CheckIconAlt className='d-flex jc--center' width='23px' height='23px' /> : <span className='icon--ex icon--ex--bordered' />}
              </div>
            </div>
          ),
        }))}
        headings={[
          {
            accessor: 'field',
            Header: t('DataProtection.table.heading.personal_data_fields'),
            isKey: true,
            classNames: 'text-center',
            disableSortBy: true,
            disableFilters: true,
          },
        ]}
        wrapperClassName='u-margin-bottom u-1/1'
        options={{ noDataMessage: t('DataProtection.table.option.no_data_message') }}
      />
    </div>
  )
}

PersonalDataList.propTypes = {
  dataFields: PropTypes.array,
}

export default PersonalDataList
