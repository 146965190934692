import i18n from 'i18next'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { JoinColItemsByCommaFormat } from 'utils/tableDataFormatters'

export const getVendorUsersTableConfig = () => [
  new TableHeading({
    heading: i18n.t('Global:Global.text.name'),
    accessor: 'name',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.status'),
    accessor: 'status',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.mobile_phone'),
    accessor: 'mobilePhone',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.email'),
    accessor: 'workEmail',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.position'),
    accessor: 'position',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.country'),
    accessor: 'country',
    disableSortBy: true,
    className: 'text-center',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.role'),
    accessor: 'roleType',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.text.access_area'),
    accessor: 'accessAreas',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
    Cell: JoinColItemsByCommaFormat,
  }),
  new TableHeading({
    heading: i18n.t('Global:Global.button.action'),
    accessor: 'action',
    disableSortBy: true,
    actionCell: true,
    className: 'text-center',
    columnClassName: 'text-left',
  }),
]
