import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchTermVariance } from 'redux/actions/payfiles'
import { createVarianceTermFilter } from 'redux/filters/varianceTermFilter'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { getStaticPaginationOpts } from 'utils/pagination'

export const useVarianceTerm = ({ payrollInstanceId, excludeZeroes, onlyVariance, versionLeft, versionRight, selectedElement, needsRefetch }) => {
  const dispatch = useDispatch()
  const [termVarianceData, setTermVarianceData] = useState(null)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    offset: 0,
    limit: 10,
  })

  /*
   * Fetchers
   */
  const fetchTermVarianceData = async (offset, limit) => {
    const needsFetch = selectedElement && !isEmpty(versionLeft) && !isEmpty(versionRight)
    if (needsFetch) {
      const filters = {
        versionOne: versionLeft?.id,
        versionTwo: versionRight?.id,
        excludeZeroes,
        onlyVariance,
      }
      const res = await dispatch(
        fetchTermVariance(
          createVarianceTermFilter({
            payrollInstanceId,
            varianceElement: selectedElement,
            filters,
            offset,
            limit,
          })
        )
      )
      setTermVarianceData(res)
    }
  }

  /*
   * Effects
   */
  useEffect(() => {
    if (isEmpty(termVarianceData)) fetchTermVarianceData()
  }, [versionLeft, versionRight])

  useEffect(() => {
    if (needsRefetch) fetchTermVarianceData()
  }, [selectedElement, excludeZeroes, onlyVariance])

  /*
   * Pagination
   */

  const onPageChange = ({ pageIndex = 0, pageSize }) => {
    const limit = pageSize || 10
    const offset = pageIndex * limit

    if (pageIndex === pagination?.currentPage) {
      return
    }

    fetchTermVarianceData(offset, limit)
    setPagination({
      offset,
      currentPage: pageIndex,
      limit,
    })
  }

  const termTableOptions = termVarianceData?.totalCount && {
    ...getStaticPaginationOpts(),
    pageSize: pagination?.limit,
    pageIndex: pagination?.offset / pagination?.limit,
    pageCount: Math.ceil(termVarianceData.totalCount / pagination?.limit),
    totalCount: termVarianceData.totalCount,
  }

  return {
    termVarianceData,
    termTableOptions: {
      ...termTableOptions,
      onPageChange,
    },
  }
}
