import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { SelectColumnFilter } from 'components/table/filters'
import { country, icon, status } from 'utils/tableDataFormatters'
import NotificationHOC from 'components/NotificationsHOC'
import SectionHeading from 'components/SectionHeading'
import FilterButton from 'components/buttons/FilterButton'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

let classNames = require('classnames')

const getTypesOpts = (t) => [
  {
    value: 'FinalReversionTaskCompletedNotification',
    label: t('Notification.context.run_completed'),
  },
  { value: 'GTNCalculationsFinishedNotification', label: t('Notification.context.gtn_calculations_completed') },
  { value: 'GTNValidationFailedNotification', label: t('Notification.context.gtn_validation_failed') },
  { value: 'GTNValidationErrorNotification', label: t('Notification.context.gtn_validation_error') },
  { value: 'TaskFinishedNotification', label: t('Notification.context.action_completed') },
  { value: 'AODeadlinePassedNotification', label: t('Notification.context.action_owner_deadline_passed') },
  { value: 'NODeadlinePassedNotification', label: t('Notification.context.next_action_owner_deadline_passed') },
  { value: 'PODeadlinePassedNotification', label: t('Notification.context.process_owner_deadline_passed') },
  { value: 'FiveDaysToDeadlineNotification', label: t('Notification.context.five_days_to_start_of_updates') },
  { value: 'DeadlineTodayNotification', label: t('Notification.context.deadline_today') },
  { value: 'TaskNotFinishedNotification', label: t('Notification.context.action_incomplete') },
  { value: 'PODeadlineTodayNotification', label: t('Notification.context.process_owner_deadline_today') },
  { value: 'DeadlineTomorrowNotification', label: t('Notification.context.deadline_is_tomorrow') },
  { value: 'ApproveAllUpdatesDone', label: t('Notification.context.approve_all_updates_completed') },
  { value: 'CNReversionCompletedNotification', label: t('Notification.context.reversion_completed') },
  { value: 'FileUploadedNotification', label: t('Notification.context.file_uploaded') },
  { value: 'NoteCreatedNotification', label: t('Notification.context.note_created') },
  { value: 'SendForPaymentFinishedNotification', label: t('Notification.context.send_for_payment_completed') },
  { value: 'StepCreatedNotification', label: t('Notification.context.step_created') },
  { value: 'TaskCreatedNotification', label: t('Notification.context.action_created') },
  { value: 'TaskRevertedNotification', label: t('Notification.context.action_reverted') },
  { value: 'DocumentDeleteNotification', label: t('Notification.context.document_deleted') },
  { value: 'CompanyCountryTermBulkCreationNotification', label: t('Notification.context.element_created') },
  { value: 'CompanyCountryTermBulkUpdateNotification', label: t('Notification.context.element_updated') },
  { value: 'PayrollInstanceLockUnlockNotification', label: t('Notification.context.payrun_locked_unlocked') },
  { value: 'TermDeleteNotification', label: t('Notification.context.pt_element_deleted') },
]
const getColumnFilter = (t) => ({ type: 'TextFilter', delay: 1000, placeholder: t('Global:Global.text.filter') })

const NotificationList = ({ isFetching, pagination, onFilter, tenants, countries, notifications }) => {
  const [showFilters, setShowFilters] = useState(false)
  const { t } = useTranslation([i18nNameSpaces.Notification])

  const trClassFormat = ({ original }) => {
    return !original.isRead ? 'u-weight--medium' : ''
  }

  const onPageChange = ({ pageSize, pageIndex, filters }) => {
    if (!isFetching) {
      let normalizedFilters = {}
      filters.forEach((filter) => {
        normalizedFilters[filter.id] = filter
      })
      onFilter({
        page: pageIndex,
        limit: pageSize,
        filters: normalizedFilters,
      })
    }
  }

  const options = {
    noDataMessage: t('Global:Global.text.no_matching_records_found'),
    pageSize: pagination?.limit,
    sizePerPageList: [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ],
    pageIndex: pagination?.currentPage,
    pageCount: pagination?.totalPages,
    showPageSizeSelector: true,
    onPageChange: onPageChange,
    onFilterChange: (filters) => onFilter({ filters }),
  }

  return (
    <div className='u-relative'>
      <SectionHeading text={t('Global:Global.aside.alerts')} fullWidth>
        <FilterButton
          className='c-btn c-btn--small c-btn--curious u-padding-left float-right u-padding-right'
          onClick={() => setShowFilters(!showFilters)}
          aria-label={showFilters ? t('Notification.button.filters_hide_label') : t('Notification.button.filters_open_label')}
        />
      </SectionHeading>
      <PayslipTable
        data={notifications}
        headings={[
          {
            accessor: 'type',
            Header: t('Global:Global.text.type'),
            disableSortBy: true,
            setWidth: '120px',
            Cell: icon,
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'type',
              options: getTypesOpts(t),
              multi: true,
            },
          },
          {
            accessor: 'client',
            Header: t('Global:Global.text.client'),
            setWidth: '120px',
            disableSortBy: true,
            dropDownOptions: {
              name: 'client',
              options: tenants.map((tenant) => ({
                value: tenant.schema,
                label: tenant.name,
                disabled: tenant.disabled,
                className: classNames({
                  'option--as-group-title': tenant.disabled,
                }),
              })),
              multi: true,
            },
            Filter: SelectColumnFilter,
          },
          {
            accessor: 'countryName',
            Header: t('Global:Global.text.country'),
            disableSortBy: true,
            columnClassName: 'u-overflow-visible',
            Cell: country,
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'countryName',
              options: countries.map((c) => ({
                value: c.id,
                label: c.name,
              })),
              multi: true,
            },
          },
          {
            accessor: 'company',
            Header: t('Global:Global.text.company'),
            filterOptions: getColumnFilter(t),
            disableSortBy: true,
          },
          {
            accessor: 'payrollName',
            Header: t('Global:Global.text.payroll_name'),
            filterOptions: getColumnFilter(t),
            disableSortBy: true,
          },
          {
            accessor: 'period',
            Header: t('Notification.table.column.period'),
            setWidth: '100px',
            filterOptions: getColumnFilter(t),
            disableSortBy: true,
          },
          {
            accessor: 'body',
            Header: t('Notification.table.column.notification'),
            filterOptions: getColumnFilter(t),
            columnClassName: 'u-word-break-word',
            disableSortBy: true,
          },
          {
            accessor: 'status',
            Header: t('Global:Global.text.status'),
            setWidth: '170px',
            Cell: status,
            filterOptions: getColumnFilter(t),
            disableSortBy: true,
          },
        ]}
        trBodyClassName={trClassFormat}
        options={options}
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        wrapperClassName={classNames({
          'u-1/1 react-bs-table--overflow-auto': true,
          'u-half-opacity': isFetching,
        })}
        pagination
        remote
        loading={isFetching}
        showFilters={showFilters}
        totalCount={pagination?.totalCount}
      />
    </div>
  )
}
NotificationList.propTypes = {
  isFetching: PropTypes.bool,
  notifications: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      schema: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
}
export default NotificationHOC(NotificationList)
