import React, { useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import SectionHeading from 'components/SectionHeading'
import GeneralLedgersList from '../components/GeneralLedgersList'
import { createGeneralLedgers, editGeneralLedgers, fetchGeneralLedgers } from 'redux/actions/generalLedgers'
import { dummyRowForTableAdding, getGeneralLedgers } from 'redux/selectors/generalLedgers'
import { isEmpty } from 'lodash'
import { formatDataForGlobalSave, verifyData } from './helpers'
import { Authorization } from 'containers/Authorization'
import { createFilter } from 'utils/redux/filter'
import FilterButton from 'components/buttons/FilterButton'
import { invalidateGeneralLedgerGlobalData } from 'redux/actions/generalLedgerGlobalData'
import { invalidateCompanyCountryTermPivot } from 'redux/actions/companyCountryTermPivot'
import ConfirmationModal from 'components/ConfirmationModal'
import { accountStatus } from 'utils/enums/generalLedgersEnums'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const dispatch = useDispatch()
  const [showFilters, setShowFilters] = useState(false)
  const [inAddMode, setInAddMode] = useState(false)
  const [inEditMode, setInEditMode] = useState(false)

  const [data, setData] = useState(props.fetchedTableData)
  const [fieldErrors, setFieldErrors] = useState([])

  const [originalData] = useState(data)
  const [skipPageReset, setSkipPageReset] = useState(false)

  const [payloadData, setPayloadData] = useState(null)
  const confirmModal = useRef(null)

  const toggleModes = (operation, shouldToggle) => {
    if (operation === 'edit') {
      setInEditMode(shouldToggle)
      setInAddMode(false)
    }
    if (operation === 'add') {
      setInEditMode(false)
      setShowFilters(false)
      setTimeout(() => {
        setInAddMode(shouldToggle)
      }, 10)
      const newData = [dummyRowForTableAdding, ...data]
      setData(newData)
    }
  }

  const revertModifyingState = () => {
    setInEditMode(false)
    setInAddMode(false)
    setFieldErrors([])
  }

  const { t } = useTranslation([i18nNameSpaces.Company])

  const onHandleCancel = () => {
    const modifiedArray = data.map((d) => {
      if (!d.isDirty) return d
      const { status, ...rest } = d
      if (status === accountStatus.ACTIVE) return d

      return {
        ...rest,
        status: originalData.filter((j) => d.id === j.id).shift().status,
      }
    })

    setData(modifiedArray)
    confirmModal.current.hideModal()
  }

  const onHandleSave = () => {
    const errors = verifyData(data)
    if (!isEmpty(errors)) {
      setFieldErrors(errors)
    }

    let showPopupMessage = false
    const modifiedObjects = data.filter((obj) => obj.isDirty)

    modifiedObjects.map((modified) => {
      originalData.map((initial) => {
        if (initial.id === modified.id && !showPopupMessage) {
          showPopupMessage = modified.status === accountStatus.INACTIVE && initial.status === accountStatus.ACTIVE
        }
      })
    })

    const payload = formatDataForGlobalSave(data)

    if (isEmpty(payload)) return

    if (showPopupMessage) {
      setPayloadData(payload)
      return confirmModal.current.showModal()
    }

    onSaveData(payload)
  }

  const onSaveData = (payload) => {
    const errors = verifyData(data)
    if (!isEmpty(errors)) {
      setFieldErrors(errors)
      return
    }

    dispatch(editGeneralLedgers(payload)).then((resp) => {
      if (resp.errors) {
        const returnedErrors = []
        for (const key in resp.errors) {
          if (Object.hasOwnProperty.call(resp.errors, key)) {
            const element = resp.errors[key]
            returnedErrors.push({ id: parseInt(key, 10), ...element })
          }
        }
        setFieldErrors(returnedErrors)
      } else {
        dispatch(
          fetchGeneralLedgers({
            filter: createFilter({ sort: [{ name: 'id', order: 'desc' }] }),
          })
        )
        dispatch(invalidateGeneralLedgerGlobalData())
        dispatch(invalidateCompanyCountryTermPivot())?.then(
          ({
            payload: {
              response: { data },
            },
          }) => {
            setData(data)
          }
        )
      }
    })
  }

  const onCancel = () => {
    setData(originalData)
    revertModifyingState()
  }

  const onSaveInline = () => {
    const errors = verifyData(data)

    if (!isEmpty(errors)) {
      setFieldErrors(errors)
    } else {
      const payload = data.find((d) => d.isNew)
      delete payload.id
      delete payload.isDirty
      delete payload.isNew
      dispatch(
        createGeneralLedgers(payload, {
          shouldInvalidate: true,
          filter: createFilter({ sort: [{ name: 'id', order: 'desc' }] }),
        })
      ).then((resp) => {
        if (resp.errors) {
          const errors = [{ id: dummyRowForTableAdding.id, ...resp.errors }]

          setData((old) =>
            old.map((row, index) => {
              if (!row.id) {
                return {
                  ...row,
                  isNew: true,
                }
              }
              return row
            })
          )
          setFieldErrors(errors)
          return
        }
        revertModifyingState()
      })
    }
  }

  const onUpdateGlobalDataForSave = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            isDirty: true,
          }
        }
        return row
      })
    )
  }
  const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
  return (
    <div className='page--general-ledger'>
      <SectionHeading text={t('Global:Global.text.accounts')}>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <div className='u-float--right'>
            {inEditMode ? (
              <>
                <button onClick={onCancel} className={`${btnClasses}`} title={t('Global:Global.button.cancel')} data-testid='general-ledger-account-cancel'>
                  {t('Global:Global.button.cancel')}
                </button>
                <button onClick={onHandleSave} className={`${btnClasses}`} title={t('Global:Global.button.save')} data-testid='general-ledger-account-save'>
                  {t('Global:Global.button.save')}
                </button>
              </>
            ) : (
              <>
                <Authorization permissions={['GENERALLEDGER_CREATE']}>
                  <button
                    onClick={() => props.history.push('/import/gl-accounts')}
                    className={btnClasses}
                    title={t('Company.button.create_multiple_accounts')}
                    data-testid='general-ledger-account-import'
                  >
                    {t('Company.button.create_multiple_accounts')}
                  </button>
                </Authorization>
                <Authorization permissions={['GENERALLEDGER_EDIT']}>
                  <button
                    onClick={() => props.history.push('/import/gl-accounts-update')}
                    className={btnClasses}
                    title={t('Company.button.update_accounts')}
                    data-testid='general-ledger-account-update'
                  >
                    {t('Company.button.update_accounts')}
                  </button>
                </Authorization>
              </>
            )}
            <Authorization permissions={['GENERALLEDGER_EDIT']}>
              <button
                onClick={() => toggleModes('edit', !inEditMode)}
                className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
                title={t('Global:Global.text.edit')}
                disabled={inAddMode}
                data-testid='general-ledger-account-edit'
              >
                <span className='icon icon--edit' />
              </button>
            </Authorization>
            <Authorization permissions={['GENERALLEDGER_CREATE']}>
              <button
                onClick={() => toggleModes('add', !inAddMode)}
                className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
                title={t('Global:Global.text.add')}
                disabled={inEditMode || inAddMode}
                data-testid='general-ledger-account-create'
              >
                <span className='icon icon--plus' />
              </button>
            </Authorization>
            <FilterButton
              disabled={inAddMode}
              className={`c-btn c-btn--small float-right c-btn--curious
              u-padding-left u-margin-left-tiny u-padding-right ${inAddMode ? 'disabled' : ''}`}
              onClick={() => setShowFilters(!showFilters)}
            />
          </div>
        </div>
      </SectionHeading>
      <div className='o-layout'>
        <div className='o-layout__item u-margin-bottom u-1/1'>
          <GeneralLedgersList
            inAddMode={inAddMode}
            inEditMode={inEditMode}
            tableData={data}
            showFilters={showFilters}
            onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
            onCancelInlineAdd={onCancel}
            skipPageReset={skipPageReset}
            onCancel={onCancel}
            onSaveInline={onSaveInline}
            fieldErrors={fieldErrors}
          />
        </div>
      </div>

      <ConfirmationModal
        ref={confirmModal}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => onSaveData(payloadData)}
        onReject={() => onHandleCancel()}
      >
        <p>
          {t('Company.modal.once_an_account_no_longer_able_to_assign')}
          <br />
          {t('Company.modal.are_you_sure_to_inactivate_account')}
        </p>
      </ConfirmationModal>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    fetchedTableData: getGeneralLedgers(state),
  }
}

RouteView.propTypes = {
  history: PropTypes.object,
  fetchedTableData: PropTypes.array,
}

const Container = connect(mapStateToProps)(RouteView)

export default Fetcher(Container, [
  {
    name: 'generalLedgers',
    params: [
      {
        _computed: {
          filter: (state, { match: { params } }) => createFilter({ sort: [{ name: 'id', order: 'desc' }] }),
        },
      },
    ],
  },
])
