import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import Logo from '../Logo'
import MainNav from '../MainNav'
import { getVendorIdBySchema } from 'redux/selectors/vendors'
import { getContactedParentCompanies } from 'redux/selectors/tenants'
import { getTenantSubdomain } from 'utils/tenant'
import PropTypes from 'prop-types'
import { closeAllToastMessages } from 'redux/actions/toasts'
import { setShouldPoll, setStartPolling } from 'redux/actions/pollingJob'
import { useParams } from 'react-router'
import Loader from 'components/Loader'
import { isVendorAdmin } from 'redux/selectors/auth'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getMenuItems = ({ vendorId, hasAccessToUsers, hasAccessToResources, t }) => {
  const userSection = hasAccessToUsers
    ? [
      {
        title: t('Global:Global.aside.users'),
        name: 'home',
        path: `/vendors/${vendorId}/users`,
      },
    ]
    : []
  const resourcesSection = hasAccessToResources
    ? [
      {
        title: t('Global:Global.aside.resources'),
        name: 'resources',
        path: '/resources/releases',
      },
    ]
    : []
  return [
    { title: t('Global:Global.aside.home'), name: 'home', path: `/vendors/${vendorId}/company-details`, isHome: true },
    { title: t('Global:Global.aside.clients'), name: 'clients', path: `/vendors/${vendorId}/clients` },
    ...userSection,
    ...resourcesSection,
    {
      title: t('Global:Global.aside.toolbox'),
      name: 'toolbox',
      path: '/toolbox',
      isLink: false,
      children: [{ title: t('Global:Global.aside.alerts'), name: 'alerts', path: '/toolbox/alerts', sub: true }],
    },
  ]
}

const Aside = (props) => {
  const { vendorId, isFetching, asideMode, selectAside, hasAccessToUsers, hasAccessToResources } = props
  const dispatch = useDispatch()
  const params = useParams()
  const { t } = useTranslation([i18nNameSpaces.Vendor])
  useEffect(() => {
    if (!params.tenantId) {
      dispatch(closeAllToastMessages())
      dispatch(setShouldPoll({ enablePolling: false }))
      dispatch(setStartPolling({ shouldStartPolling: false }))
    }
  }, [])
  return (
    <aside className='o-aside'>
      <Logo />

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      {isFetching ? <Loader /> : <MainNav items={getMenuItems({ vendorId, hasAccessToUsers, hasAccessToResources, t })} {...props} />}
    </aside>
  )
}

// map to state
const mapStateToProps = (state, props) => {
  if (props.isFetching) return { isFetching: true }

  return {
    hasAccessToUsers: isVendorAdmin(state),
    vendorId: getVendorIdBySchema(state, { schema: getTenantSubdomain() }),
    parentCompanies: getContactedParentCompanies(state),
  }
}

const Container = connect(mapStateToProps)(Aside)

Aside.propTypes = {
  selectAside: PropTypes.func,
  isFetching: PropTypes.bool,
  asideMode: PropTypes.bool,
  isUser: PropTypes.bool,
  userId: PropTypes.number,
  hasAccess: PropTypes.func,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchAccessAreaPivotIfNeeded: PropTypes.func,
  fetchParentCompaniesIfNeeded: PropTypes.func,
  parentCompanies: PropTypes.array,
  vendorId: PropTypes.number,
  dispatch: PropTypes.func,
}

export default Fetcher(Container, ['vendors', 'parentCompanies'], { renderWhileFetching: true, showLoader: false })
