import React, { useContext } from 'react'
import { SortIcons } from '../sorting'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PayslipTableHeaderCell = ({ column, ShownFilterContext }) => {
  const showFilters = useContext(ShownFilterContext)
  let HeaderDisplay = column.render('Header')
  const { t } = useTranslation()
  return (
    <th
      {...column.getHeaderProps({
        className: column.classNames,
      })}
      rowSpan={`${column.rowSpan ?? 1}`}
      style={{
        width: column.setWidth || null,
        overflow: showFilters ? 'visible' : 'hidden',
      }}
    >
      {HeaderDisplay}
      {column.canSort ? (
        <span {...column.getSortByToggleProps()} title={t('Global.text.sort_by')}>
          {<SortIcons cell={column} />}
        </span>
      ) : null}
      <div data-testid={column.Header + '-filter'}>
        {column.canFilter && showFilters ? column.render('Filter') : null}
        {column.setWidth && <div style={{ minWidth: column.setMinWidth || 'unset', width: column.setWidth || 'unset' }} />}
      </div>
    </th>
  )
}

const PayslipTableHeaderRow = ({ headerGroup, trClassName, ShownFilterContext }) => {
  return (
    <tr {...headerGroup.getHeaderGroupProps({ className: trClassName })}>
      <th>{/* Placeholder header for the highlight cell */}</th>
      {headerGroup.headers.map((column, i) => (
        <PayslipTableHeaderCell key={`header-cell-${i}`} column={column} ShownFilterContext={ShownFilterContext} />
      ))}
    </tr>
  )
}

export const PayslipTableHeader = ({ headerGroups, trClassName, showFilters, ShownFilterContext }) => {
  return (
    <thead>
      {headerGroups.map((headerGroup, i) => (
        <PayslipTableHeaderRow
          key={`header-row-${i}`}
          trClassName={trClassName}
          headerGroup={headerGroup}
          showFilters={showFilters}
          ShownFilterContext={ShownFilterContext}
        />
      ))}
    </thead>
  )
}

const commonProps = {
  column: PropTypes.object,
  showFilters: PropTypes.bool,
  trClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ShownFilterContext: PropTypes.object,
}

PayslipTableHeaderCell.propTypes = commonProps

PayslipTableHeaderRow.propTypes = {
  ...commonProps,
  headerGroup: PropTypes.object,
}

PayslipTableHeader.propTypes = {
  ...commonProps,
  headerGroups: PropTypes.array,
}
