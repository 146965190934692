/* eslint-disable max-len */

export const privacyPolicy = {
  sectionA: {
    heading: 'WHO ARE WE?',
    p1:
      'Payslip is a product of Payslip Limited, a Company registered in Ireland with its address at Westport ' +
      'Business Centre, Prospect Avenue, Westport, Co Mayo, Ireland, F28 KW30 (“Payslip”).',
    p2:
      'This privacy policy will explain how our organization uses the personal data your employer/client provided to ' +
      'us so we can perform our contract with them. It outlines what data we collect, how we use it, for how ' +
      'long, and what your rights are in relation to your personal data.',
  },
  sectionB: {
    heading: 'WHAT DATA DO WE COLLECT AND HOW?',
    p1: 'We collect the following data of our ',
    p1U: 'clients’ employees:',
    listA: {
      l1: 'Personal data: such as names, date of birth, contact information, etc.',
      l2: 'Job and organizational data: such as company, department, position, etc.',
      l3: 'Pay and taxes data: such as pay elements, type of employment, deductions, etc.',
    },
    p2U: 'Sensitive data:',
    p2:
      ' In some limited occasions, where instructed by your employer and required strictly for ' +
      'payroll purposes we may collect sensitive data, such as church tax and trade union for Germany.',
    p3: 'We collect your data so that we can perform our obligations under the contract we have with your employer.',
    p4:
      'Where we process your data on behalf of your employer, your employer will have informed you of this ' +
      'in advance of this processing, and will have ensured you have consented to it.',
    p5: 'We also collect the following data of our ',
    p5U: 'client’s Key Users:',
    listB: {
      l1: 'Personal data: such as names, telephone number, work e-mail, etc.',
      l2: 'Job and organizational data: such as company, department, position, etc.',
    },
    p6U: 'Sensitive data:',
    p6: ' We don’t collect any sensitive data.',
    p7: 'We collect your data so that we can perform our obligations under the contract we have with your employer.',
    p8:
      'Where we process your data on behalf of your employer, your employer will have informed you of this ' +
      'in advance of this processing, and will have ensured you have consented to it.',
    p9: 'We also collect the following data of ',
    p9U: 'Vendors/ICP’s Key Users:',
    listC: {
      l1: 'Personal data: such as names, telephone number, etc.',
      l2: 'Job and organizational data: such as company, etc.',
    },
    p10U: 'Sensitive data:',
    p10: ' We don’t collect any sensitive data.',
    p11: 'We collect your data so that we can perform our obligations under the contract we have with our client.',
    p12:
      'Where we process your data on behalf of your client, your client will have informed you of this in ' +
      'advance of this processing, and will have ensured you have consented to it.',
  },
  sectionC: {
    heading: 'HOW DO WE USE YOUR DATA?',
    p1: 'We only collect your data in the capacity of a Data Processor under GDPR and upon the instruction of your employer/client (Data Controller).',
    p2: 'We will never sell your information to third parties or process it in any other way except on the explicit instructions of your employer/client.',
    p3:
      'Valid for Client Employees - when we process your data, we may send your data to, and also use the resulting information from, ' +
      'in-country payroll providers specifically appointed by your employer for processing your payroll information in the relevant country.',
  },
  sectionD: {
    heading: 'HOW DO WE STORE YOUR DATA?',
    p1:
      'Our platform is hosted in AWS, Ireland, a best in class cloud services provider. Regular reviews are ' +
      'undertaken to ensure that the required technical and organizational information security and privacy ' +
      'measures are in place and maintained by our cloud services provider to a satisfactory level.',
    p2: 'We have taken appropriate measures to protect your data. Such measures include but are not limited to:',
    list: {
      l1: 'Encrypt and pseudonymize personal data where possible.',
      l2: 'Implement technical protection mechanisms such as malware detection, and firewalls.',
      l3: 'Ensure that key information infrastructure, data systems are not physically accessible for everyone.',
      l4: 'Implement security procedures, e.g. passwords must be changed from time to time, regular back-ups.',
      l5: 'Data breach management procedure is in place, etc.',
    },
    p3:
      'We will keep your data for the duration of the contract with your employer/client unless instructed ' +
      'otherwise by your employer/client due to the relevant legal regulatory requirements. Following the ' +
      'termination of the contract with your employer/client, your data will be securely deleted within the ' +
      'period agreed with your employer/client.',
  },
  sectionE: {
    heading: 'WHAT ARE YOUR PROTECTION RIGHTS?',
    p1: 'We want to ensure you are fully aware of all your data protection rights. Every user is entitled to the following:',
    list: {
      l1: 'The right to be informed: about the purpose of the processes and the identity of the data controller.',
      l2: 'The right of access: you have the right to request copies of your data.',
      l3: 'The right to rectification of incorrect data.',
      l4: 'The right to erasure: you have the right to request that your data is deleted under certain conditions.',
      l5: 'The right to restrict processing: you have the right to request to restrict the processing of your personal data, under certain conditions.',
      l6: 'The right to object to processing: you have the right to request to object to the processing of your personal data, under certain conditions.',
      l7:
        'The right to data portability: you have the right to request that your data is transferred to ' +
        'another organization, or directly to you, under certain conditions.',
    },
    p2:
      'You may at any time exercise your rights regarding your personal data by contacting your employer/client. ' +
      'Upon the instructions of your employer/client, we will assist them in every reasonable way to fulfill their obligations to you under ' +
      'the Data Protection Law.',
  },
  sectionF: {
    heading: 'HOW TO CONTACT THE APPROPRIATE AUTHORITY?',
    p1:
      'Should you wish to report a complaint or if you feel that your concern has not been addressed in a ' +
      'satisfactory manner, you may contact the Information Commissioner’s Office.',
    p2: 'Email: ',
    p2Email: 'dpo@dataprotection.ie',
    p3: 'Address:',
    p4: 'The Data Protection Commission (c/o Data Protection Officer),',
    p5: '21 Fitzwilliam Square South,',
    p6: 'Dublin 2,',
    p7: 'Ireland, D02 RD28',
    p8: 'How to contact us',
    p9: 'Email: ',
    p9Email: 'privacy@payslip.com',
    p10: 'Address:',
    p11: 'Payslip,',
    p12: 'Westport Business Centre,',
    p13: 'Prospect Avenue,',
    p14: 'Westport, Co Mayo,',
    p15: 'Ireland, F28 KW30',
  },
  sectionG: {
    heading: 'CHANGES TO OUR PRIVACY POLICY',
    p1: 'We keep this privacy policy under regular review and place any updates on this web page. This privacy policy was last updated on 18/04/2024.',
  },
}
