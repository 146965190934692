import React from 'react'
import { getSubmitButtonOptions } from '@rjsf/utils'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

export const SubmitButtonTemplate = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Report])
  const { uiSchema } = props
  const { norender, ...options } = getSubmitButtonOptions(uiSchema)
  const { className = '', containerClassName = '', buttonProps = {} } = options

  if (norender) {
    return null
  }

  return (
    <div className={containerClassName}>
      <button className={`c-btn rounded-md ${className}`} type='submit' {...buttonProps}>
        {options?.label ?? t('Global:Global.text.save')}
      </button>
    </div>
  )
}

SubmitButtonTemplate.propTypes = {
  uiSchema: PropTypes.object.isRequired,
}
