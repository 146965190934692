import React from 'react'
import PropTypes from 'prop-types'
import { change, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { required } from 'utils/validations'
import TasksTable from './TasksTable'
import { i18nNameSpaces } from 'i18n/types'

const requiredSymbol = '*'
const SingleMoveableEditForm = ({
  payrollTaskName,
  showOwnerField,
  owners,
  tasks,
  tasksOptions,
  onOwnerChange,
  disableDeadline,
  dispatch,
  isMAOEnabled,
  ...rest
}) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  return (
    <div>
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <h2 className='u-text--center u-text--huge'>
            <span className='u-weight--bold'>{t('Payroll.title.single_edit_form', { payrollTaskName })}</span>
          </h2>
        </div>
      </div>
      <Form showSubmitBtn {...rest}>
        <div className='o-layout u-text--center'>
          <div className='o-layout__item u-1/1'>
            <Field
              name='name'
              label={t('Payroll.field.name', {
                requiredSymbol,
              })}
              labelClassName='c-label'
              component={InputField}
              type='text'
              validate={required}
            />
          </div>
          <div className='o-layout'>
            <div className='o-layout__item u-1/2'>
              {showOwnerField && (
                <div className='o-layout__item u-1/2'>
                  <Field
                    name='owner'
                    label={t('Global:Global.text.owner')}
                    component={CustomSelectField}
                    labelClassName='c-label'
                    className='u-text--left'
                    placeholder={t('Global:Global.placeholder.select')}
                    options={owners}
                    onChanged={onOwnerChange}
                  />
                </div>
              )}
              <div className={`o-layout__item ${showOwnerField ? 'u-1/2' : 'u-1/1'}`}>
                <Field
                  name='deadline'
                  label={t('Global:Global.text.timeline')}
                  labelClassName='c-label'
                  component={InputField}
                  type='number'
                  disabled={disableDeadline}
                />
              </div>
              {isMAOEnabled && showOwnerField && (
                <div className='o-layout__item u-text--center u-1/1'>
                  <span
                    onClick={() => dispatch(change('payrollTaskEdit', 'taskableType', 'BusinessUnit'))}
                    className='u-text--small u-text--curious u-cursor--pointer'
                  >
                    {t('Payroll.text.switch_to_multiple_owners')}
                  </span>
                </div>
              )}
            </div>
            <div className='o-layout__item u-1/2'>
              <div className='o-layout__item u-1/3'>
                <Field
                  name='position'
                  label={t('Payroll.field.select_position', {
                    requiredSymbol,
                  })}
                  component={CustomSelectField}
                  labelClassName='c-label'
                  className='u-text--left'
                  placeholder={t('Global:Global.placeholder.select')}
                  options={tasksOptions}
                  validate={required}
                />
              </div>
              <div className='o-layout__item u-2/3 u-margin-top'>
                <TasksTable tasks={tasks} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

SingleMoveableEditForm.propTypes = {
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  tasks: PropTypes.array.isRequired,
  tasksOptions: PropTypes.array.isRequired,
  payrollTaskName: PropTypes.string,
  showOwnerField: PropTypes.bool,
  disableDeadline: PropTypes.bool,
  onOwnerChange: PropTypes.func,
  isMAOEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default SingleMoveableEditForm
