import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import FilterContainer from '../containers/FilterContainer'
import { getFormFieldValue } from 'redux/selectors/form'
import InnerWrapper from 'layouts/InnerWrapper'
import SectionHeading from 'components/SectionHeading'
import DownloadTemplateContainer from './DownloadTemplateContainer'
import ImportContainer from './ImportContainer'
import AsyncImportWrapper from 'routes/Import/containers/AsyncImportWrapper'
import { getQuery } from 'utils/query'
import { getJobResult } from 'utils/job'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { useTranslation } from 'react-i18next'

const ImportFacade = (props) => {
  const { payrollId, heading, templateSubHeading, hasFilters = true, TemplateClarifications, children, isAsync = false } = props
  const { jobId } = getQuery(props.location.search, 'jobId')
  const job = getJobResult(jobId)
  const { t } = useTranslation()

  const showActionsAndResults = (hasFilters && payrollId) || !hasFilters || !isEmpty(job)
  const initialValues = {
    company: job?.filters?.company,
  }

  const Container = isAsync ? AsyncImportWrapper : ImportContainer

  return (
    <InnerWrapper>
      {TemplateClarifications && <TemplateClarifications />}
      {hasFilters && <FilterContainer initialValues={initialValues} {...props} />}
      {showActionsAndResults && (
        <div>
          <SectionHeading text={t('Global.text.download_template')} />
          {templateSubHeading && <SectionHeading text={templateSubHeading} priority={2} bolded={false} huge={false} />}
          <DownloadTemplateContainer hasFilters={hasFilters} {...props} />
          <SectionHeading text={heading} />
          <Container hasFilters={hasFilters} {...props} />
        </div>
      )}
      {children}
    </InnerWrapper>
  )
}

const mapStateToProps = (state) => {
  const payrollId = getFormFieldValue(state, 'companyFilter', 'payroll')
  const companyId = getFormFieldValue(state, 'companyFilter', 'company')

  return {
    payrollId,
    companyId,
    shouldUseCompanyAsFilter: payrollId === 'all',
  }
}

ImportFacade.propTypes = {
  payrollId: PropTypes.string,
  heading: PropTypes.string,
  templateSubHeading: PropTypes.string,
  hasFilters: PropTypes.bool,
  TemplateClarifications: PropTypes.elementType,
  children: PropTypes.node,
  isAsync: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}
export default connect(mapStateToProps)(ImportFacade)
