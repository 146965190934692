import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { getLegacyReportTypes } from 'redux/config/reports'
import { isFetching } from 'utils/redux/fetching'
import ReportingFilters from '../../../components/ReportingFilters'
import { fetchFxRatesIfNeeded } from 'redux/actions/fxRates'
import { fetchPayrollsIfNeeded } from 'redux/actions/payrolls'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { createReport, fetchReports } from 'redux/actions/reports'
import { getCombinedSubcategories, getReportById } from '../../../selectors/reports'
import { getPayrollCurrency, setReportingCurrency, setReportingRate } from '../../../selectors/currencies'

class ReportingFiltersContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props

    dispatch(fetchReports())
    dispatch(fetchPayrollsIfNeeded())
    dispatch(fetchCurrenciesIfNeeded())
    dispatch(fetchFxRatesIfNeeded())
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <ReportingFilters {...this.props} viewReportClass={'view-report-page u-bg--white shadow rounded u-padding u-margin--auto'} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    /*
     * Create report
     */
    onSubmit: (data) => dispatch(createReport(data)),
  }
}

const mapStateToProps = (state, props) => {
  const { reports, payrolls, currencies, fxRates } = state

  const entities = [reports, payrolls, currencies, fxRates]
  const types = getLegacyReportTypes()

  if (isFetching(entities)) return { isFetching: true }
  const report = getReportById(state, { reportId: props.match.params.reportId })
  // eslint-disable-next-line max-len
  const payrollCurrency = getPayrollCurrency(state, report)
  const reportTypes = types.find((type) => type.type === report.type)

  // For some report types, the payroll field can accept multiple values.
  // The default behavior is having only 1 payroll selected.
  let payroll =
    report.type === 'EmployeeReport' ||
    report.type === 'AuditTrailReport' ||
    report.type === 'PayrollReport' ||
    report.type === 'EmployeeDataChangesReport' ||
    report.type === 'ChangeLogReport' ||
    (report.type === 'PayrollInstanceReport' && report.filters.showOnlyTotals) ||
    report.type === 'GlobalPayrunManagementReport'
      ? report.filters.payroll
      : report.type === 'GlobalPayAndTaxesReport'
        ? []
        : report.filters.payroll[0]

  // Handling 'None' option.
  // In the case the user chooses 'None', we send to the BE [0] as a value.
  // But when the report is saved, the BE return to us an empty array [].
  // Because of this, here we should put the 0 back in the empty array [0].
  payroll = Array.isArray(payroll) && !payroll.length ? [0] : payroll
  const businessUnit = Array.isArray(report.filters.businessUnit) && !report.filters.businessUnit.length ? [0] : report.filters.businessUnit
  const costCenter = Array.isArray(report.filters.costCenter) && !report.filters.costCenter.length ? [0] : report.filters.costCenter
  const department = Array.isArray(report.filters.department) && !report.filters.department.length ? [0] : report.filters.department
  const modifiedBy = report.filters.modifiedBy ? report.filters.modifiedBy.map((item) => JSON.stringify(item)) : null

  return {
    types,
    // Type is required to display categories and subcategories described in report config
    categories: reportTypes.categories,
    subcategories: reportTypes.subcategories.filter((subcategory) => subcategory.category === report.category),
    // Please refer to `onCombinedSubcategoryChange` for more details,
    // about `combinedSubcategory, subcategory, termCategory` fields
    combinedSubcategories: report.type
      ? getCombinedSubcategories(state, {
        type: report.type,
        subcategory: report.subcategory,
        catNames: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
      })
      : [],
    selectedType: report.type,
    isPayrollReport: report.type === 'PayrollReport',
    isPayrollInstanceReport: report.type === 'PayrollInstanceReport',
    isEmployeeDataChangesReport: report.type === 'EmployeeDataChangesReport',
    isFieldDisabled: true,
    disabledReason: 'Please go to "Report Library" to run a new report',
    // In case we are customizing/creating a report, Terms field is shown,
    // only if the TermsCategory field has a selected options firstly.
    // But here, on the singe report page, we want to show Terms field, no matter a TermsCategory is being selected
    // before that.
    forceShowTerms: true,
    hideNavigationButtons: true,
    onBackClick: null,
    initialValues: {
      type: report.type,
      category: report.category,
      subcategory: report.subcategory,
      combinedSubcategory: report.subcategory === 'Terms' ? report.filters.termCategory.map((cat) => `Terms-${cat}`) : [report.subcategory],
      process: report.filters.process ? report.filters.process[0] : null,
      country: ['EmployeeDataChangesReport', 'VarianceReport'].includes(report.type) ? report.filters.country[0] : report.filters.country,
      company: ['EmployeeDataChangesReport', 'VarianceReport'].includes(report.type) ? report.filters.company[0] : report.filters.company,
      payroll,
      businessUnit,
      costCenter,
      department,
      processOwner: report.filters.processOwner,
      payrollInstance: report.filters.payrollInstance,
      fromDate: report.filters.fromDate,
      toDate: report.filters.toDate,
      showOnlyTotals: report.filters.showOnlyTotals,
      termSubcategory: report.filters.termSubcategory,
      employeeStatus: report.filters.employeeStatus,
      employmentStatus: report.filters.employmentStatus,
      term: report.filters.term,
      payrollCurrency,
      reportingCurrency: setReportingCurrency(state, report, payrollCurrency),
      reportingRate: setReportingRate(state, report),
      modifiedBy,
    },
    reportItem: report,
    mostRecentRunReport: {},
  }
}

ReportingFiltersContainer.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'reportingFilters',
  })(ReportingFiltersContainer)
)
