import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import AmountFormat from 'components/AmountFormat'
import EditableCell from 'components/table/cell/EditableCell'
import { batch, useDispatch } from 'react-redux'
import { updateEmployeeSystemUser } from 'redux/actions/employeeSystemUsers'
import { invalidateAnnualSalaries } from 'redux/actions/annualSalaries'
import ConfirmationModal from 'components/ConfirmationModal'
import { showMessage } from 'redux/actions/modal'
import { Authorization } from 'containers/Authorization'
import { isString } from 'lodash'
import { confirmMessage } from 'components/payruns/Messages'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getPostConfirmationMessage = (t) => ({
  active: {
    confirmed: t('Employee.context.confirmed'),
    rejected: t('Employee.context.rejected'),
  },
  locked: {
    confirmed: (
      <div>
        <p>{confirmMessage}</p>
        <p>{t('Employee.context.confirmed')}</p>
      </div>
    ),
    rejected: t('Employee.context.rejected'),
  },
})

const InlineButtons = ({ onEditClick, onHistoryClick = () => null, areButtonsDisable = false }) => {
  const { t } = useTranslation()
  return (
    <div className='d-flex justify-content-end'>
      <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
        <button
          onClick={onEditClick}
          className={`c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny ${areButtonsDisable && 'disabled'}`}
          title={t('Global:Global.text.edit')}
          disabled={areButtonsDisable}
          data-testid='annual-salary-edit'
        >
          <span className='icon icon--edit' />
        </button>
      </Authorization>
      <button
        onClick={onHistoryClick}
        className={`c-btn c-btn--tiny c-btn--curious u-text--tiny ${areButtonsDisable && 'disabled'}`}
        title={t('Global.text.history')}
        data-testid='annual-salary-history'
        disabled={areButtonsDisable}
      >
        <span className='icon icon--history' />
      </button>
    </div>
  )
}

InlineButtons.propTypes = {
  onEditClick: PropTypes.func,
  onHistoryClick: PropTypes.func,
  areButtonsDisable: PropTypes.bool,
}

const formatSalaryData = (salaryData) => {
  if (salaryData.value) {
    salaryData['annualSalaryValue'] = salaryData.value
  }
  return salaryData
}

const verifyData = (salaryData, t) => {
  const errors = []
  let error = { effectiveDate: [], annualSalaryValue: [] }

  // If Nothing has changed, provide both errors
  if (!salaryData.isDirty) {
    error.effectiveDate = t('Employee.validation.effective_date_required')
    error.value = t('Employee.validation.annual_salary_value_required')
    errors.push(error)
    return errors
  }

  // If the salary hasn't changed
  if (salaryData.isDirty && !salaryData.salaryIsDirty) {
    error.value = t('Employee.validation.annual_salary_value_required')
    errors.push(error)
  }

  if (!salaryData.effectiveDate) {
    error.effectiveDate = t('Employee.validation.effective_date_required')
    errors.push(error)
  }

  if (!salaryData.value || (salaryData.value && isString(salaryData.value) && salaryData.value.trim() === '')) {
    error.value = t('Employee.validation.annual_salary_value_required')
    errors.push(error)
  }

  return errors
}

const AnnualSalaryTableView = (props) => {
  const {
    inlineMode,
    setInlineMode,
    disableSalaryInlineButtons,
    onAnnualSalaryHistoryBtnClick,
    showConfirmation,
    hasEmployeeActiveInstances,
    hasEmployeeLockedInstances,
    hideInlineButtons,
  } = props
  const defaultData = { value: props.salary, effectiveDate: null, reason: null, id: props.employeeId }
  const dispatch = useDispatch()
  const confirmationModal = useRef(null)
  const [salaryData, setSalaryDate] = useState(defaultData)
  const [fieldErrors, setFieldErrors] = useState([])
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const revertModifyingState = () => {
    setInlineMode(false)
    setFieldErrors([])
  }

  const getDataForSave = () => formatSalaryData(salaryData)

  const submitData = (payload) => {
    let promise = dispatch(updateEmployeeSystemUser(payload, { shouldFetch: false, shouldInvalidate: true }, true))
    promise = promise.then((resp) => {
      dispatch(invalidateAnnualSalaries())
      revertModifyingState()
    })
    return promise
  }

  const showConfirmMessage = () => {
    confirmationModal.current.showModal()
  }

  const editableOptions = {
    inGlobalEditMode: false,
    inAddMode: inlineMode,
    onUpdateGlobalData: (rowIndex, columnId, value) =>
      setSalaryDate((old) => {
        let hasChangedSalary = {}
        if (columnId === 'value') {
          hasChangedSalary = { salaryIsDirty: true }
        }
        return { ...old, [columnId]: value, isDirty: true, ...hasChangedSalary }
      }),
    onCancelInlineAdd: () => {
      batch(() => {
        setInlineMode(false)
        setSalaryDate(defaultData)
        setFieldErrors([])
      })
    },
    onSaveInline: () => {
      const payload = getDataForSave()
      const errors = verifyData(payload, t)
      if (errors.length) {
        setFieldErrors(errors)
        return
      }

      let showConfirmApplyToPayroll = payload.annualSalaryValue !== props.salary && showConfirmation
      // Want to show the confirmation message to apply not apply the salary to the current payroll
      // Otherwise we just submit the data
      if (showConfirmApplyToPayroll) {
        showConfirmMessage()
        return
      }
      return submitData(payload)
    },
    fieldErrors: fieldErrors,
  }

  const inlineButtons = { hasInlineSave: true, hasInlineCancel: true }
  const viewColumns = [
    new TableHeading({
      accessor: 'title',
      heading: t('Employee.annual_salary_table.heading.title'),
      disableSortBy: false,
      className: 'd-none',
      columnClassName: 'text-left',
    }),
    new TableHeading({
      accessor: 'value',
      heading: t('Employee.annual_salary_table.heading.value'),
      disableSortBy: false,
      className: 'd-none',
      columnClassName: 'text-right',
    }),
    new TableHeading({
      accessor: 'buttons',
      heading: t('Employee.annual_salary_table.heading.buttons'),
      disableSortBy: false,
      className: 'd-none',
      columnClassName: 'text-right u-fixed-width--100',
    }),
  ]
  const inlineModeHeaders = [
    new TableHeading({
      accessor: 'title',
      heading: t('Employee.annual_salary_table.heading.title'),
      disableSortBy: false,
      className: 'd-none',
      columnClassName: 'u-text-left',
    }),
    {
      ...new TableHeading({
        accessor: 'value',
        heading: t('Employee.annual_salary_table.heading.value'),
        disableSortBy: false,
        className: 'd-none',
      }),
      Cell: EditableCell,
      placeholder: t('Employee.annual_salary_table.placeholder.value'),
      setWidth: 250,
      editable: true,
      inputType: 'amount',
    },
    {
      ...new TableHeading({
        accessor: 'effectiveDate',
        heading: t('Employee.annual_salary_table.heading.effective_date'),
        disableSortBy: false,
        className: 'd-none',
      }),
      Cell: EditableCell,
      editable: true,
      placeholder: t('Employee.annual_salary_table.placeholder.effective_date'),
      inputType: 'date',
    },
    {
      ...new TableHeading({
        accessor: 'reason',
        heading: t('Employee.annual_salary_table.heading.reason'),
        disableSortBy: false,
        className: 'd-none',
      }),
      Cell: EditableCell,
      ...inlineButtons,
      editable: true,
      placeholder: t('Employee.annual_salary_table.placeholder.reason'),
    },
  ]
  let tableData = [
    {
      title: t('Employee.annual_salary_table.row.annual_salary'),
      value: <AmountFormat amount={parseFloat(salaryData.value)} />,
      buttons: !hideInlineButtons ? (
        <InlineButtons
          onEditClick={() => setInlineMode(!inlineMode)}
          onHistoryClick={onAnnualSalaryHistoryBtnClick}
          areButtonsDisable={disableSalaryInlineButtons}
        />
      ) : null,
      metaData: {},
    },
  ]

  if (inlineMode) {
    tableData = [
      {
        title: t('Employee.annual_salary_table.row.annual_salary'),
        value: null,
        effectiveDate: null,
        reason: null,
        isNew: true,
        metaData: {
          checkForEmptyValue: { shouldCheck: true, field: 'value' },
          checkForEmptyDate: { shouldCheck: true, field: 'effectiveDate' },
        },
      },
    ]
  }

  return (
    <div id='pay-taxes-annual-salary-table' className='o-layout'>
      <PayslipTable
        wrapperClassName='o-layout__item u-1/1 react-bs-table--overflow-auto'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        data-testid='employee-pay-and-taxes-salary'
        editableOptions={editableOptions}
        dynamicValues
        headings={[
          {
            Header: t('Employee.annual_salary_table.row.annual_salary'),
            classNames: 'text-center',
            columns: inlineMode ? inlineModeHeaders : viewColumns,
          },
        ]}
        data={tableData}
      />
      <ConfirmationModal
        ref={confirmationModal}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => {
          const payload = getDataForSave()
          submitData({ ...payload, inherit: true })
          if (showConfirmation) {
            const status = hasEmployeeActiveInstances ? 'active' : hasEmployeeLockedInstances ? 'locked' : null
            status &&
              dispatch(
                showMessage({
                  body: getPostConfirmationMessage(t)[status]['confirmed'],
                })
              )
          }
        }}
        onReject={() => {
          const payload = getDataForSave()
          submitData({ ...payload, inherit: false })
          if (showConfirmation) {
            const status = hasEmployeeActiveInstances ? 'active' : hasEmployeeLockedInstances ? 'locked' : null
            status &&
              dispatch(
                showMessage({
                  body: getPostConfirmationMessage(t)[status]['rejected'],
                })
              )
          }
        }}
      >
        <p>{t('Employee.modal.confirmation_text_apply_salary')}</p>
      </ConfirmationModal>
    </div>
  )
}

AnnualSalaryTableView.propTypes = {
  salary: PropTypes.number,
  onAnnualSalaryHistoryBtnClick: PropTypes.func,
  employeeId: PropTypes.number,
  inlineMode: PropTypes.bool,
  setInlineMode: PropTypes.func,
  disableSalaryInlineButtons: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  hasEmployeeActiveInstances: PropTypes.bool,
  hasEmployeeLockedInstances: PropTypes.bool,
  hideInlineButtons: PropTypes.bool,
}
export default AnnualSalaryTableView
