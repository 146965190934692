import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import PasswordInputField from 'components/form/PasswordInputField'
import PasswordPolicyMessage from 'components/auth/PasswordPolicyMessage'
import RequestCodeView from 'components/RequestCodeView'
import { i18nNameSpaces } from 'i18n/types'

const ResetPasswordConfirmationView = (props) => {
  const { is2FAenabled, confirmationToken, onSubmit, error, getNewCode } = props
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <>
      <div className='o-grid o-grid--center'>
        <PasswordPolicyMessage />
      </div>
      <div className='o-grid o-grid--center'>
        <Form
          onSubmit={onSubmit}
          showSubmitBtn
          btnText={t('Global:Global.button.send')}
          colorClassBtn='c-btn--curious'
          btnFullWidth
          classNames='c-btn--rounded'
          {...props}
        >
          <div className='o-form-group o-block o-block--left'>
            <Field
              name='password'
              label={t('BusinessComponent.input.new_password')}
              component={PasswordInputField}
              type='password'
              autoFocus
              className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
              showPasswordStrengthBox
            />
          </div>
          <div className='o-form-group o-block o-block--left u-padding-top-small'>
            <Field
              name='passwordConfirmation'
              label={t('BusinessComponent.input.confirm_password')}
              component={PasswordInputField}
              type='password'
              className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
            />
          </div>
          {is2FAenabled ? (
            <div className='o-form-group o-block o-block--left u-padding-top-small'>
              <Field
                name='authCode'
                label={t('Global:Global.input.code')}
                component={InputField}
                type='text'
                className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
                labelClassName='c-label u-text--curious'
              />
            </div>
          ) : null}
          <div className='o-form-group u-text--center'>{error && <div className='error'>{t('Global:Global.error.generic')}</div>}</div>
        </Form>
      </div>
      {is2FAenabled ? (
        <RequestCodeView
          gridClassNames='u-padding-top'
          onClick={() => getNewCode(confirmationToken)}
          textClassNames='u-text--medium'
          text={t('BusinessComponent.RequestCodeView.request_code')}
        />
      ) : null}
    </>
  )
}

ResetPasswordConfirmationView.propTypes = {
  confirmationToken: PropTypes.string,
  is2FAenabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  getNewCode: PropTypes.func,
}

export default ResetPasswordConfirmationView
