import React from 'react'
import PropTypes from 'prop-types'
import { change, Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import { i18nNameSpaces } from 'i18n/types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { required } from 'utils/validations'
import TasksTable from './TasksTable'
import BusinessUnitsOwnersTable from './BusinessUnitsOwnersTable'

const requiredSymbol = '*'
const MultiMoveableEditForm = ({
  payrollTaskName,
  owners,
  onOwnerChange,
  disableDeadline,
  buOwnersData,
  isMAOEnabled,
  tasks,
  tasksOptions,
  dispatch,
  ...rest
}) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  return (
    <>
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <h2 className='u-text--center u-text--huge'>
            <span className='u-weight--bold'>{t('Payroll.header.choose_owners_action', { payrollTaskName })}</span>
          </h2>
        </div>
      </div>
      <Form showSubmitBtn {...rest}>
        <div className='o-layout u-text--center'>
          <div className='o-layout__item u-1/1'>
            <Field
              name='name'
              label={t('Payroll.field.name', {
                requiredSymbol,
              })}
              labelClassName='c-label'
              component={InputField}
              type='text'
              validate={required}
            />
          </div>
          <div className='o-layout'>
            <div className='o-layout__item u-1/2'>
              <div className='o-layout__item u-1/1'>
                <Field
                  name='deadline'
                  label={t('Global.text.timeline')}
                  labelClassName='c-label'
                  component={InputField}
                  type='number'
                  disabled={disableDeadline}
                />
              </div>
              <div className='o-layout__item u-1/1'>
                <BusinessUnitsOwnersTable buOwnersData={buOwnersData} />
              </div>
              <div className='o-layout__item u-text--center u-1/1'>
                <span
                  onClick={() => dispatch(change('payrollTaskEdit', 'taskableType', 'Company'))}
                  className='u-text--small u-text--curious u-cursor--pointer'
                >
                  {t('Payroll.switch.single_owner')}
                </span>
              </div>
            </div>
            <div className='o-layout__item u-1/2'>
              <div className='o-layout__item u-1/3'>
                <Field
                  name='position'
                  label={t('Payroll.field.select_position', {
                    requiredSymbol,
                  })}
                  component={CustomSelectField}
                  labelClassName='c-label'
                  className='u-text--left'
                  placeholder={t('Global:Global.select.placeholder')}
                  options={tasksOptions}
                  validate={required}
                />
              </div>
              <div className='o-layout__item u-2/3 u-margin-top'>
                <TasksTable tasks={tasks} />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

MultiMoveableEditForm.propTypes = {
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  tasks: PropTypes.array,
  tasksOptions: PropTypes.array,
  payrollTaskName: PropTypes.string,
  disableDeadline: PropTypes.bool,
  onOwnerChange: PropTypes.func,
  buOwnersData: PropTypes.array,
  isMAOEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default MultiMoveableEditForm
