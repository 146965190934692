import React from 'react'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import PropTypes from 'prop-types'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { useTranslation } from 'react-i18next'

const Aside = (props) => {
  const { history, selectAside, asideMode } = props
  const { t } = useTranslation()

  return (
    <aside className='o-aside'>
      <Logo />
      <TenantSelectorContainer history={history} />

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav
        items={[
          { title: t('Global:Global.aside.home'), name: 'home', path: '/home', isHome: true },
          { title: t('Global:Global.aside.countries'), name: 'payroll', path: '/countries' },
          { title: t('Global:Global.aside.globalTerms'), name: 'payroll', path: '/terms' },
          { title: t('Global:Global.aside.globalTermsSubcategories'), name: 'payroll', path: '/term-subcategories' },
          { title: t('Global:Global.aside.clients'), name: 'payroll', path: '/clients' },
          {
            title: t('Global:Global.aside.globalServices'),
            name: 'payroll',
            path: '/services',
            children: [
              { title: t('Global:Global.aside.processes'), name: 'processes', path: '/processes', sub: true },
              { title: t('Global:Global.aside.steps'), name: 'steps', path: '/steps', sub: true },
              { title: t('Global:Global.aside.actions'), name: 'actions', path: '/actions', sub: true },
            ],
          },
          { title: t('Global:Global.aside.vendors'), name: 'payroll', path: '/vendors' },
          { title: t('Global:Global.aside.cotUsers'), name: 'payroll', path: '/users' },
          { title: t('Global:Global.aside.reports'), name: 'payroll', path: '/reports' },
          { title: t('Global:Global.aside.globalDataProtection'), name: 'payroll', path: '/global-data-protection' },
          { title: t('Global:Global.aside.resources'), name: 'resources', path: '/resources/releases' },
          { title: t('Global:Global.aside.alerts'), name: 'payroll', path: '/alerts' },
        ]}
        {...props}
      />
    </aside>
  )
}

Aside.propTypes = {
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  parentCompanies: PropTypes.array,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}

export default Aside
