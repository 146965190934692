import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import EmployeePersonalFormFields from './EmployeePersonalFormFields'
import EmployeeJobOrgFormFields from './EmployeeJobOrgFormFields'
import EmployeePayTaxesFormFields from './EmployeePayTaxesFormFields'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { i18nNameSpaces } from 'i18n/types'

const EmployeeCreate = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const { handleSubmit, onSubmit, tabIndex, onChangeTab } = props

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid='employee-create-form'>
      <Tabs selectedIndex={tabIndex} onSelect={onChangeTab}>
        <TabList className='c-tabs__list c-tabs__list-border-none'>
          <Tab className='c-tabs__item'>
            <span data-testid='personal-tab'>{t('Employee.tabs.personal')}</span>
          </Tab>
          <Tab className='c-tabs__item'>
            <span data-testid='job-and-org-tab'>{t('Employee.tabs.job_and_org')}</span>
          </Tab>
          <Tab className='c-tabs__item'>
            <span data-testid='pay-and-taxes-tab'>{t('Employee.tabs.pay_and_taxes')}</span>
          </Tab>
        </TabList>
        <TabPanel>
          <EmployeePersonalFormFields {...props} />
        </TabPanel>
        <TabPanel>
          <EmployeeJobOrgFormFields {...props} />
        </TabPanel>
        <TabPanel>
          <EmployeePayTaxesFormFields {...props} />
        </TabPanel>
      </Tabs>
    </form>
  )
}

EmployeeCreate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChangeTab: PropTypes.func,
  tabIndex: PropTypes.number,
}

export default EmployeeCreate
