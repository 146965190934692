import React, { useCallback, useState } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { buildSelectOpt } from 'utils/options'
import { ROLE_VENDOR } from 'redux/config/auth'
import CheckboxOption from 'components/form/select/CheckboxOption'
import { required } from 'utils/validations'
import { buildAvailableAccessAreasOptions, buildAvailableTenants, hasEveryAccesableCompanies, hasSomeAccessableTenant } from '../vendorUserHelper'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const FIELD_NAMES = Object.freeze({
  Tenants: 'tenants',
  AccessAreas: 'accessAreas',
  RoleType: 'roleType',
})

const getFieldLabel = (label, required) => (required ? `${label} *` : label)
const hasAccessToField = (roleType) => roleType === ROLE_VENDOR

export const VendorAccessControlForm = ({ parentCompanies, externalCompanies, initialValues, resetField, canEditRole, vendorRoleTypes }) => {
  const [selectedTenants, setSelectedTenants] = useState(initialValues?.tenants || [])
  const [selectedRoleType, setSelectedRoleType] = useState(initialValues?.roleType)
  const { t } = useTranslation([i18nNameSpaces.Vendor])
  const availableAccessAreas = externalCompanies.filter((externalCompany) => externalCompany.accessable && selectedTenants?.includes(externalCompany.tenant))
  const isDisabledAccessAreas = isEmpty(selectedTenants) || !hasSomeAccessableTenant(selectedTenants, parentCompanies)
  const needsAreasValidation = !initialValues?.accessableCompanies || hasEveryAccesableCompanies(initialValues?.accessableCompanies, parentCompanies)

  const handleTenantChange = useCallback((value) => setSelectedTenants(value), [parentCompanies])
  const handleRoleTypeChange = useCallback((value) => setSelectedRoleType(value), [initialValues])
  const handleTenantChanged = useCallback(() => resetField(FIELD_NAMES.AccessAreas, []), [resetField])
  const handleRoleTypeChanged = useCallback(() => {
    resetField(FIELD_NAMES.Tenants, [])
    resetField(FIELD_NAMES.AccessAreas, [])
  }, [resetField])

  const fields = [
    {
      name: FIELD_NAMES.RoleType,
      label: getFieldLabel(t('Global:Global.text.role'), true),
      placeholder: t('Global:Global.select.placeholder'),
      options: buildSelectOpt(vendorRoleTypes, { valuePath: 'type' }),
      onChange: handleRoleTypeChange,
      onChanged: handleRoleTypeChanged,
      visible: true,
      clearable: false,
      validate: required,
      disabled: !canEditRole,
    },
    {
      name: FIELD_NAMES.Tenants,
      label: getFieldLabel(t('Global.aside.clients'), needsAreasValidation),
      placeholder: t('Global:Global.select.placeholder'),
      options: buildAvailableTenants(parentCompanies),
      optionComponent: CheckboxOption,
      multi: true,
      onChange: handleTenantChange,
      onChanged: handleTenantChanged,
      visible: hasAccessToField(selectedRoleType),
      clearable: true,
      validate: needsAreasValidation ? required : undefined,
    },
    {
      name: FIELD_NAMES.AccessAreas,
      label: getFieldLabel(t('Global:Global.text.access_areas'), needsAreasValidation),
      placeholder: t('Global:Global.select.placeholder'),
      optionComponent: CheckboxOption,
      options: buildAvailableAccessAreasOptions(availableAccessAreas),
      disabled: isDisabledAccessAreas,
      multi: true,
      visible: hasAccessToField(selectedRoleType),
      clearable: true,
      validate: needsAreasValidation ? required : undefined,
    },
  ]

  return (
    <div className='u-margin-top-tiny-half '>
      <div className='d-flex jc--center w-full'>
        <SectionHeading text={t('Vendor.text.access_control')} />
      </div>
      {fields.map(
        (field) =>
          field.visible && (
            <div key={field.name} className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  {...field}
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  data-testid={field.name}
                  validate={field?.validate}
                />
              </div>
            </div>
          )
      )}
    </div>
  )
}

VendorAccessControlForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any),
  resetField: PropTypes.func.isRequired,
  parentCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      schema: PropTypes.string.isRequired,
    })
  ),
  externalCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      accessable: PropTypes.bool.isRequired,
      companyId: PropTypes.number.isRequired,
      country: PropTypes.number.isRequired,
      tenant: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  canEditRole: PropTypes.bool,
  vendorRoleTypes: PropTypes.arrayOf(PropTypes.string),
}
