import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DownloadButton, PrimaryButton } from 'components/buttons'
import { PADLOCK_ICONS_TYPES, PadlockIcon } from 'components/PadlockIcons'
import { Authorization } from 'containers/Authorization'
import { getReportOptionsConfig } from 'configs/reports'
import usePathName from 'hooks/usePathName'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { ALL_ROUTES } from 'configs/routes'
import moment from 'moment'
import { hideReportSection } from 'routes/Reporting/utils/reportAccessManagement'
import { useSelector } from 'react-redux'
import { reportIdsEnum } from 'utils/enums/reportGroupings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const NavigationButtons = ({ basePath, button: { path, text, size = '' } }) => {
  return (
    <Link to={`${basePath}/${path}`}>
      <PrimaryButton classes={`u-margin-left${size} u-margin-bottom-tiny`}>{text}</PrimaryButton>
    </Link>
  )
}

NavigationButtons.propTypes = {
  basePath: PropTypes.string.isRequired,
  button: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.string,
  }),
}

const PayrunFileModalButtons = ({
  payrollInstance,
  togglePayrollInstanceLockUnlock,
  isPayrollInstanceLocked,
  hasAccess,
  downloadArchive,
  createReport,
  triggerGlReportCreation,
  canCreateGlReport,
  disablePayrunTimeAttendanceDownload,
  userHasAccessToChangeLogReport,
}) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  let pathname = usePathName()
  const reopenedPath = `/${ALL_ROUTES.PAYRUNS.BASE}/${ALL_ROUTES.PAYRUNS.REOPENED}`
  const alternativePath = usePathName(reopenedPath, payrollInstance.status === payrunStatesEnum.REOPENED)
  const userPermissions = useSelector((state) => state.auth.permissions)
  const reportOptionsConfig = getReportOptionsConfig()

  const navButtons = [
    { base: alternativePath, path: 'instance', text: t('Payrun.button.payrun_file'), size: '-small' },
    { base: alternativePath, path: 'pay-and-taxes-changes', text: t('Payrun.button.pay_and_taxes_changes') },
    { base: pathname, path: 'time-attendance-updates', text: t('Payrun.button.time_attendance_overtime') },
  ]

  const hidePayrunChangesBtn = hideReportSection(reportIdsEnum.PAYROLL, userPermissions)

  return (
    <div className='o-layout__item o-grid o-grid--middle o-grid--center u-text--center u-margin u-1/1'>
      <button
        type='button'
        className='
            c-btn c-btn--transparent
            c-btn--small border-none
            u-padding-left-tiny
            u-padding-right-tiny
            as-start'
        data-testid={`padlock-${payrollInstance.id}`}
        onClick={() => togglePayrollInstanceLockUnlock()}
      >
        <PadlockIcon type={isPayrollInstanceLocked ? PADLOCK_ICONS_TYPES.padlockLocked : PADLOCK_ICONS_TYPES.padlockUnlocked} />
      </button>
      {hasAccess && (
        <>
          {navButtons.map((button) => (
            <NavigationButtons key={button.path} basePath={`${button.base}/${payrollInstance.id}`} button={button} />
          ))}
          <DownloadButton
            classes={`u-margin-left u-margin-bottom-tiny ${disablePayrunTimeAttendanceDownload ? 'disabled' : ''}`}
            isDownloading={disablePayrunTimeAttendanceDownload}
            onClick={() => downloadArchive(payrollInstance.id)}
          >
            {t('Payrun.button.download_payrun_time_attendance')}
          </DownloadButton>
          <Authorization permissions={['REPORT_CREATE']}>
            <PrimaryButton
              classes='u-margin-left u-margin-bottom-tiny'
              onClick={() => {
                const data = {
                  type: 'GlobalPayrunManagementReport',
                  category: 'GlobalPayrunManagement',
                  subcategory: 'GlobalPayrunManagement',
                  showOnlyTotals: true,
                  country: [payrollInstance.countryId],
                  company: [payrollInstance.companyId],
                  payroll: payrollInstance.payrollId,
                  payrollInstance: [payrollInstance.id],
                  selectedReportType: reportOptionsConfig.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY.VALUE,
                }
                createReport(data)
              }}
            >
              {t('Payrun.button.gtn_totals')}
            </PrimaryButton>
          </Authorization>
        </>
      )}
      {!hidePayrunChangesBtn && (
        <Authorization permissions={['REPORT_CREATE']}>
          <PrimaryButton
            classes='u-margin-left u-margin-bottom-tiny'
            onClick={() => {
              const data = {
                type: 'EmployeeDataChangesReport',
                category: 'EmployeePayrollChanges',
                subcategory: 'EmployeePayrollChanges',
                country: payrollInstance.countryId,
                company: payrollInstance.companyId,
                payroll: payrollInstance.payrollId,
                payrollInstance: [payrollInstance.id],
                selectedReportType: reportOptionsConfig.PAYROLL_CHANGES_REPORT.VALUE,
              }
              createReport(data)
            }}
            data-testid='payrun-changes-button'
          >
            {t('Payrun.button.payrun_changes')}
          </PrimaryButton>
        </Authorization>
      )}
      {userHasAccessToChangeLogReport && (
        <Authorization permissions={['REPORT_CREATE']}>
          <PrimaryButton
            classes='u-margin-left u-margin-bottom-tiny'
            onClick={() => {
              const data = {
                type: 'ChangeLogReport',
                category: 'ChangeLogReport',
                subcategory: 'All',
                country: payrollInstance.countryId,
                company: payrollInstance.companyId,
                payroll: payrollInstance.payrollId,
                fromDate: moment(payrollInstance.fromDate.date),
                toDate: moment(payrollInstance.toDate.date),
                payrollInstance: [payrollInstance.id],
                selectedReportType: reportOptionsConfig.CHANGE_LOG.VALUE,
              }
              createReport(data)
            }}
            data-testid='change-log-button'
          >
            {t('Payrun.button.change_log')}
          </PrimaryButton>
        </Authorization>
      )}
      {canCreateGlReport && (
        <PrimaryButton classes='u-margin-left u-margin-bottom-tiny' onClick={() => triggerGlReportCreation(payrollInstance.id)}>
          {t('Payrun.button.gl_report')}
        </PrimaryButton>
      )}
    </div>
  )
}

PayrunFileModalButtons.propTypes = {
  payrollInstance: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    countryId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    payrollId: PropTypes.number.isRequired,
    fromDate: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }).isRequired,
    toDate: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  togglePayrollInstanceLockUnlock: PropTypes.func.isRequired,
  isPayrollInstanceLocked: PropTypes.bool.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  downloadArchive: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  triggerGlReportCreation: PropTypes.func.isRequired,
  canCreateGlReport: PropTypes.bool.isRequired,
  disablePayrunTimeAttendanceDownload: PropTypes.bool.isRequired,
  userHasAccessToChangeLogReport: PropTypes.bool.isRequired,
}

export default PayrunFileModalButtons
