import i18n from 'i18next'

import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { cellRenderAction, dateTimePayslipTable, download } from 'utils/tableDataFormatters'

export const generateSupportingDocsHeadings = ({ DocumentActions, handleDownloadDocument, handleUpdateDoc }) => [
  new TableHeading({
    accessor: 'name',
    heading: i18n.t('Global.text.name'),
    className: 'text-left',
    columnClassName: 'text-left',
    setWidth: '280px',
    disableSortBy: true,
    Cell: download,
    onClick: handleDownloadDocument,
  }),
  new TableHeading({
    accessor: 'description',
    heading: i18n.t('Global.text.description'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'documentType',
    heading: i18n.t('Global.text.type'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'version',
    heading: i18n.t('Global.text.version_clean'),
    className: 'text-center',
    columnClassName: 'text-center',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'uploadedOn',
    heading: i18n.t('Global.text.uploaded_on'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
    Cell: dateTimePayslipTable,
  }),
  new TableHeading({
    accessor: 'uploadedBy',
    heading: i18n.t('Global.text.uploaded_by'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'action',
    heading: '',
    className: 'text-center',
    columnClassName: 'text-right',
    disableSortBy: true,
    Cell: cellRenderAction({ Actions: DocumentActions }),
    onClick: handleUpdateDoc,
  }),
]
