import React from 'react'

import PropTypes from 'prop-types'
import Logo from '../Logo'
import { isMultitenancyEnabled } from 'utils/config'
import TenantSelectorContainer from 'containers/TenantSelectorContainer'
import MainNav from 'components/MainNav'
import { addRouteOptions, createGlobalRoutes } from './routes' // use the updated dynamic global routes creator
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { useTranslation } from 'react-i18next'

const generateRoutes = ({ controlCenterUrl, userHasAccessToPICC, hasAccessToResources, t }) => {
  const asideGlobalRoutes = createGlobalRoutes(t)

  return [
    addRouteOptions(asideGlobalRoutes.home, { isHome: true }),
    asideGlobalRoutes.countries,
    asideGlobalRoutes.globalTermsSubcategories,
    asideGlobalRoutes.clients,
    asideGlobalRoutes.vendors,
    ...(hasAccessToResources ? [asideGlobalRoutes.resources] : []),
    asideGlobalRoutes.alerts,
  ].filter(Boolean) // Filter out any undefined or null values
}

export const AsideCosOem = (props) => {
  const { history, asideMode, selectAside, controlCenterUrl, userHasAccessToPICC, hasAccessToResources } = props
  const { t } = useTranslation()

  const menuItems = generateRoutes({ controlCenterUrl, userHasAccessToPICC, hasAccessToResources, t })

  return (
    <aside className='o-aside'>
      <Logo />
      {isMultitenancyEnabled && <TenantSelectorContainer history={history} />}
      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />
      <MainNav asideMode={asideMode} items={menuItems} {...props} />
    </aside>
  )
}

AsideCosOem.propTypes = {
  history: PropTypes.object.isRequired,
  asideMode: PropTypes.bool.isRequired,
  selectAside: PropTypes.func.isRequired,
  controlCenterUrl: PropTypes.string.isRequired,
  userHasAccessToPICC: PropTypes.bool.isRequired,
  hasAccessToResources: PropTypes.bool.isRequired,
}
