import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'

const TasksTable = ({ tasks }) => {
  const { t } = useTranslation()
  return (
    <PayslipTable
      dynamicValues
      headings={[
        {
          accessor: 'alphabeticName',
          Header: t('Global.text.current_actions', {
            colonSymbol: ':',
          }),
          columnClassName: 'text-left',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({
            cell: {
              value,
              row: { original },
            },
          }) => {
            return (
              <div title={value} className={original.isModified ? 'u-weight--bold' : ''}>
                {value}
              </div>
            )
          },
        },
      ]}
      data={tasks}
    />
  )
}

TasksTable.propTypes = {
  tasks: PropTypes.array.isRequired,
}

export default TasksTable
