import React, { memo, useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import Select, { Option } from 'react-select'

import Flag from 'components/Flag'

import { isEmpty } from 'utils/fnkit/typeChecks'
import CheckboxWrapper from 'components/form/CheckboxWrapper'
import { isEqual } from 'utils/fnkit/base'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

/**
 * SelectCheckboxWidget component is a functional React component that renders a checkbox
 * within a styled wrapper. The component accepts the indicatorClassName for custom styling
 * and a disabled state to control the checkbox interactivity.
 *
 * @param {Object} props                              - The properties object.
 * @param {string} props.indicatorClassName           - The CSS class name for the checkbox indicator.
 * @param {boolean} props.disabled                    - Boolean flag to indicate if the checkbox should be disabled.
 * @returns {JSX.Element}                             - The rendered checkbox component.
 */
const SelectCheckboxWidget = ({ indicatorClassName = '', disabled }) => {
  return (
    <CheckboxWrapper label='' indicatorClassName={indicatorClassName} disabled={disabled}>
      &nbsp;
      <input type='checkbox' className='' disabled={disabled} />
    </CheckboxWrapper>
  )
}

SelectCheckboxWidget.propTypes = {
  indicatorClassName: PropTypes.string,
  disabled: PropTypes.bool,
}

/**
 *
 * Maps an array of option objects to an array of their values.
 *
 * @param {Array} options - The array of option objects.
 * @returns {Array} The array of option values.
 */
const mapValueOptions = (options = []) => {
  if (Array.isArray(options)) {
    return options?.map((s) => s.value)
  }
}

/**
 * A functional React component that renders an Option component with various optional elements.
 *
 * @param {Object} props - The props passed to the component.
 * @param {string} props.className - A string of classes to be added to the component for styling.
 * @param {React.ReactNode} props.children - The children elements to be rendered within the Option component.
 * @param {React.Ref} props.innerRef - A reference to be attached to the Option component.
 * @param {Object} props.widgetOptions - Additional options for configuring the widget, including whether it supports multiple selections.
 * @param {Object} props.innerProps - Additional properties to be spread onto the Option component.
 * @param {Object} [props.innerProps.option] - Additional option properties.
 * @param {string} [props.innerProps.option.abbreviature] - An optional abbreviature to display a flag.
 *
 * @returns {React.Element} The rendered Option component.
 */
const SelectOptWidget = memo(({ children, innerRef, widgetOptions, ...innerProps }) => {
  const { className, isSelected, isDisabled, optionIndex } = innerProps
  const indicatorClassName = isSelected ? 'control__indicator--small control__indicator--in-select' : 'control__indicator--small'
  const abbreviature = innerProps?.option?.abbreviature
  const isMulti = widgetOptions?.multi
  const needsCheckbox = optionIndex !== 0 && isMulti
  return (
    <Option innerRef={innerRef} {...innerProps} className={`o-block o-block--left u-padding-tiny ${className}`}>
      {needsCheckbox && <SelectCheckboxWidget indicatorClassName={indicatorClassName} disabled={isDisabled} />}
      {abbreviature && <Flag flag={abbreviature.toLowerCase()} size='miniature' classes='u-margin-right-tiny' />}
      {children}
    </Option>
  )
})
SelectOptWidget.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.any,
  widgetOptions: PropTypes.any,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  option: PropTypes.any,
}
/**
 * Retrieves extra options based on the provided option and widget options.
 *
 * @param {Object} option - The option object.
 * @param {Object} widgetOptions - The widget options including abbreviatures.
 * @returns {Object} The extra options including the abbreviature if found.
 */
const getExtraOptions = (option, widgetOptions) =>
  widgetOptions?.abbreviatures?.reduce((extraOptions, { abbreviature, value }) => {
    if (option.value === value) {
      extraOptions.abbreviature = abbreviature
    }
    return extraOptions
  }, {})

/**
 * Handles the value based on whether "select all" is enabled.
 *
 * @param {Object} params - The parameters object.
 * @param {boolean} params.enableSelectAll - Indicates whether "select all" functionality is enabled.
 * @param {Array|string} params.receivedValue - The received value(s) to be processed.
 * @returns {Array|string|undefined} - The processed value. If "select all" is enabled, returns the received value as is.
 *                                      If not, returns the first element of the received value array or undefined if the array is empty.
 */
const handleValue = ({ enableSelectAll, receivedValue }) => {
  if (enableSelectAll) {
    return receivedValue
  }
  return !isEmpty(receivedValue) ? receivedValue[0] : undefined
}
/**
 * Select widget component that renders a select dropdown with optional multi-select and "select all" functionality.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.value - The array of selected values.
 * @param {Function} props.onChange - The callback function to handle change events.
 * @param {Object} props.options - The options for the select component including enumOptions and other settings.
 * @returns {JSX.Element} The rendered select component.
 */
export const SelectWidget = ({ id, value: receivedValue, onChange, options }) => {
  const { t } = useTranslation([i18nNameSpaces.Report])

  const selectAllOpt = {
    label: t('Global:Global.button.select_all'),
    value: 'selectAll',
  }

  const enableSelectAll = options?.multi
  const [value, setValue] = useState(() => handleValue({ enableSelectAll, receivedValue }))
  const optionList = enableSelectAll ? [selectAllOpt, ...(options?.enumOptions || [])] : options?.enumOptions
  const hasValue = !isEmpty(value)

  const handleOnchange = useCallback(
    (newValue) => {
      const changedValue = typeof newValue === 'number' ? [newValue] : newValue
      const isDiff = !isEqual(changedValue, receivedValue)
      if (isDiff) {
        onChange(changedValue)
      }
    },
    [id]
  )

  /**
   * This function is used as a callback to process changes in selection for a UI select element.
   * It supports a feature to select all options and maps the selected values to the appropriate format.
   * In case of an option we will receive an object, if it is multiple an array of objects
   * @param {Array|Object} [optionsValue=[]] - The selected value(s) from the select component.
   *
   * @callback handleSelectChange
   */
  const handleSelectChange = useCallback(
    (optionsValue = []) => {
      const hasSelectAll = enableSelectAll && optionsValue.some((opt) => opt.value === selectAllOpt.value)
      let newValue = hasSelectAll ? mapValueOptions(options?.enumOptions) : mapValueOptions(optionsValue)

      if (optionsValue?.value) {
        newValue = [optionsValue.value]
      }

      if (hasSelectAll || isEmpty(newValue) || typeof optionsValue?.value === 'number') {
        handleOnchange(newValue)
        return
      }

      setValue(newValue)
    },
    [onChange]
  )

  const OptionComponent = (props) => <SelectOptWidget {...props} widgetOptions={options} />
  return (
    <div
      className={`${hasValue ? 'schema-form--select-widget-container--value' : 'schema-form--select-widget-container'}`}
      onMouseLeave={() => handleOnchange(value)}
    >
      <Select
        className='c-custom-select c-custom-select--left-padding schema-form--select-widget'
        onBlurResetsInput={false}
        removeSelected={false}
        searchable
        {...options}
        value={value}
        options={optionList?.map((o) => ({ ...o, ...getExtraOptions(o, options) }))}
        optionComponent={OptionComponent}
        placeholder={options?.placeholder || t('Global:Global.select.placeholder')}
        closeOnSelect={!options?.multi}
        onChange={handleSelectChange}
        onClose={() => handleOnchange(value)}
        id={id}
      />
    </div>
  )
}
SelectWidget.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any.isRequired,
}
