import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import omit from 'lodash/omit'
import KeyPeopleForm from '../components/KeyPeopleForm'
import { getKURoleTypes } from 'redux/config/keyPeopleRoleTypes'
import { fetchAccessAreaPivot } from 'redux/actions/accessAreaPivot'
import { fetchCompanies } from 'routes/Companies/modules/actions'
import { fetchEmployeeCountryPivot } from 'redux/actions/employeeCountryPivot'
import { createEmployeeSystemUser, fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import KeyPeopleFormHOC, { buildAccessArea } from './KeyPeopleFormHOC'
import { getUser } from 'redux/selectors/employees'
import { isCot, isPayrollAdmin } from 'redux/selectors/auth'
import { mapPropertyValueToBoolean } from 'utils/sanitizer'
import { isSelectedRoleTypeRestrictedToBeGO } from 'utils/employee'
import { createFilter } from 'utils/redux/filter'
import { getTenantSSOStatusObject } from 'redux/helpers/getTenantSSOStatusObject'

const mapDispatchToProps = (dispatch, props) => {
  const {
    filters: { sort, filterName },
  } = props
  return {
    onSubmit: (data) => {
      const { accessAreaBUs, readOnly, workflowNotifications } = data
      const submittedRoleType = readOnly
        ? { roleType: getKURoleTypes().find((roleType) => roleType.type === data.roleType).readOnlyType }
        : { roleType: data.roleType }
      const isSelectedRoleRestrictedToBeGO = isSelectedRoleTypeRestrictedToBeGO(data.roleType)

      const accessChangeLogReport = mapPropertyValueToBoolean(data, 'accessChangeLogReport')
      const unlockLockPayrunFile = mapPropertyValueToBoolean(data, 'unlockLockPayrunFile')
      const gtnReImport = mapPropertyValueToBoolean(data, 'gtnReImport')
      const payrollIntegrationsControlCenter = mapPropertyValueToBoolean(data, 'payrollIntegrationsControlCenter')
      const allPayrunDocuments = mapPropertyValueToBoolean(data, 'allPayrunDocuments')
      const reportDesigner = mapPropertyValueToBoolean(data, 'reportDesigner')

      // If selected role is not allowed to be GO, then set GO to false
      if (isSelectedRoleRestrictedToBeGO) {
        data.globalOwner = false
      }

      let sanitized = {
        ...(!props.isCot && data.globalOwner),
        ...omit(data, ['accessAreaCompanies', 'accessAreaBUs', `${!props.isCot && 'globalOwner'}`]),
        accessAreas: buildAccessArea(accessAreaBUs),
        ...submittedRoleType,
        // `workflowNotifications` value results in 2 backend fields,
        // please refer to the field options for more details
        ...(workflowNotifications && JSON.parse(workflowNotifications)),
        // If selected role is not allowed to be GO, then set these properties to false
        specialRight: isSelectedRoleRestrictedToBeGO
          ? {
            accessChangeLogReport: false,
            unlockLockPayrunFile: false,
            gtnReImport: false,
            payrollIntegrationsControlCenter: false,
            allPayrunDocuments: false,
            reportDesigner: false,
          }
          : {
            ...accessChangeLogReport,
            ...unlockLockPayrunFile,
            ...gtnReImport,
            ...payrollIntegrationsControlCenter,
            ...allPayrunDocuments,
            ...reportDesigner,
          },
      }

      return dispatch(createEmployeeSystemUser(sanitized, null, true)).then(() => {
        props.onCreate()
        dispatch(fetchAccessAreaPivot())
        dispatch(fetchEmployeeCountryPivot())
        dispatch(fetchCompanies())
        dispatch(
          fetchEmployeeSystemUsers({
            filter: createFilter(
              {
                isKeyPerson: 1,
                sort,
                limit: 25,
                offset: 0,
              },
              filterName
            ),
          })
        )
      })
    },
  }
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('keyPeopleCreateForm')
  const values = selector(state, 'filrstname', 'roleType', 'readOnly', 'globalOwner')

  const { defaultSSOEnabledFor, isTenantSSOEnabled } = getTenantSSOStatusObject(state)

  return {
    selected: values,
    isTenantSSOEnabled,
    defaultSSOEnabledFor,
    isCreate: true,
    isUserGlobalOwner: !isCot(state) && getUser(state, { userId: state.auth.userId }).globalOwner,
    isUserPayrollAdmin: isPayrollAdmin(state),
  }
}

const form = 'keyPeopleCreateForm'
const Component = connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form })(KeyPeopleForm))

export default KeyPeopleFormHOC(Component, form)
