import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import PayrollProcessTreeContainer from 'containers/PayrollProcessTreeContainer'
import PayrollTaskEditContainer from '../containers/PayrollTaskEditContainer'
import PayrollTaskCreateContainer from '../containers/PayrollTaskCreateContainer'
import PayrollProcessEditContainer from '../containers/PayrollProcessEditContainer'
import PayrollStepCreateContainer from '../containers/PayrollStepCreateContainer'
import PayrollStepEditContainer from '../containers/PayrollStepEditContainer'
import Modal from 'components/Modal'
import SectionHeading from 'components/SectionHeading'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const { location } = props
  const [state, setState] = useState({
    payrollTaskId: null,
    payrollStepId: null,
    payrollProcessId: null,
    editEntity: null,
    isStatic: false,
    isTaskEdit: false,
    isTaskCreate: false,
    isProcessEdit: false,
    isStepCreate: false,
    isStepEdit: false,
  })

  const modalEditRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  const openModal = () => {
    modalEditRef?.current?.showModal()
  }

  const hideModal = () => {
    modalEditRef?.current?.hideModal()
  }

  const handleOnTaskEdit = (payrollTaskId, isStatic) => {
    setState({
      payrollTaskId,
      isStatic,
      isTaskEdit: true,
      isTaskCreate: false,
      isProcessEdit: false,
      isStepCreate: false,
      isStepEdit: false,
    })
    openModal()
  }

  const handleOnTaskCreate = (payrollProcessId) => {
    setState({
      payrollProcessId,
      isTaskEdit: false,
      isTaskCreate: true,
      isProcessEdit: false,
      isStepCreate: false,
      isStepEdit: false,
    })
    openModal()
  }

  const handleOnStepEdit = (payrollStepId) => {
    setState({
      payrollStepId,
      isStepEdit: true,
      isTaskEdit: false,
      isTaskCreate: false,
      isProcessEdit: false,
      isStepCreate: false,
    })
    openModal()
  }

  const handleOnProcessEdit = (payrollProcessId, editEntity) => {
    setState({
      payrollProcessId,
      editEntity,
      isProcessEdit: true,
      isTaskEdit: false,
      isTaskCreate: false,
      isStepCreate: false,
      isStepEdit: false,
    })
    openModal()
  }

  const handleOnStepCreate = (payrollProcessId) => {
    setState({
      payrollProcessId,
      isStepCreate: true,
      isProcessEdit: false,
      isTaskEdit: false,
      isTaskCreate: false,
      isStepEdit: false,
    })
    openModal()
  }

  const renderModalTitle = () => {
    const { isProcessEdit, isStepCreate, isTaskCreate } = state
    if (isProcessEdit) return t('Payroll.modal.updateProcessOwner')
    if (isStepCreate) return t('Payroll.modal.createStep')
    if (isTaskCreate) return t('Payroll.modal.createAction')
    return ''
  }

  const { isTaskEdit, isStepEdit, isProcessEdit, isStepCreate, isTaskCreate, editEntity, isStatic, payrollTaskId, payrollProcessId, payrollStepId } = state

  const currentLocation = location.pathname

  return (
    <div>
      <SectionHeading text={t('Global:Global.text.workflow')}>
        <div className='o-layout__item u-1/1 u-1/2@tablet d-none'>
          <div className='u-float--right'>
            <Link to={`${currentLocation}/process-map`} className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'>
              {t('Payroll.button.viewProcessMap')}
            </Link>
          </div>
        </div>
      </SectionHeading>
      <Modal ref={modalEditRef} className={`c-modal ${isTaskEdit && 'c-modal--half'}`} modalHeading={renderModalTitle()}>
        {isTaskEdit && <PayrollTaskEditContainer payrollTaskId={payrollTaskId} isStatic={isStatic} onSubmit={hideModal} {...props} />}
        {isTaskCreate && <PayrollTaskCreateContainer payrollProcessId={payrollProcessId} onSubmit={hideModal} {...props} />}
        {isProcessEdit && <PayrollProcessEditContainer payrollProcessId={payrollProcessId} editEntity={editEntity} onSubmit={hideModal} {...props} />}
        {isStepCreate && <PayrollStepCreateContainer payrollProcessId={payrollProcessId} onSubmit={hideModal} {...props} />}
        {isStepEdit && <PayrollStepEditContainer payrollStepId={payrollStepId} onSubmit={hideModal} {...props} />}
      </Modal>
      <PayrollProcessTreeContainer
        onTaskEdit={handleOnTaskEdit}
        onMultiView={handleOnTaskEdit}
        onProcessEdit={handleOnProcessEdit}
        onStepCreate={handleOnStepCreate}
        onTaskCreate={handleOnTaskCreate}
        onStepEdit={handleOnStepEdit}
        shouldIncludeEmptySteps
        addBtnTitle={t('Payroll.button.addStepOrAction')}
        {...props}
      />
    </div>
  )
}

RouteView.propTypes = {
  location: PropTypes.object,
}

export default RouteView
