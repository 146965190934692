import React from 'react'
import PropTypes from 'prop-types'
import AmountFormat from 'components/AmountFormat'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const AnnualSalaryList = (props) => {
  const { annualSalaries } = props
  const { t } = useTranslation([i18nNameSpaces.Employee])

  return (
    <PayslipTable
      wrapperClassName='u-1/1'
      tableElementClassName='u-margin-bottom-large table--layout-auto'
      options={{ noDataMessage: t('Global:Global.text.there_is_no_data') }}
      data={annualSalaries.map((salary) => ({
        id: salary.id,
        value: <AmountFormat amount={salary.value} />,
        effectiveDate: salary.effectiveDate,
        reason: <span data-testid='reason'>{salary.reason}</span>,
        dateAdded: salary.dateAdded,
        addedBy: salary.addedBy,
      }))}
      headings={[
        { accessor: 'value', Header: t('Global:Global.text.value'), disableFilters: true, disableSortBy: true },
        {
          accessor: 'effectiveDate',
          Header: t('Employee.pay_and_taxes.field.effectiveDate'),
          disableFilters: true,
          disableSortBy: true,
        },
        {
          accessor: 'reason',
          Header: t('Employee.annual_salary_table.heading.reason'),
          disableFilters: true,
          disableSortBy: true,
        },
        { accessor: 'dateAdded', Header: t('Employee.text.date_changed'), disableFilters: true, disableSortBy: true },
        { accessor: 'addedBy', Header: t('Employee.text.changed_by'), disableFilters: true, disableSortBy: true },
      ]}
    />
  )
}

AnnualSalaryList.propTypes = {
  annualSalaries: PropTypes.array,
}

export default AnnualSalaryList
