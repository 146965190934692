import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { DualSelectorBox } from './box'
import { DualSelectorItem, DualSelectorOption } from './item'
import { ListIconDown, ListIconUp } from 'components/icons/ListIcon'
import { getUpdateOptions, getValuesBySelectedOption, hasAllSelectedOptions, hasSomeSelectedOptions } from './helper'
import { SortingTypes } from 'utils/fnkit/sort'
import { isEmpty } from '../../../../typeChecks'
import PropTypes from 'prop-types'

export const DualSelectorWidget = ({ value: items = [], onChange, schema, options, formContext }) => {
  const [sortType, toggleSorting] = useState(SortingTypes.Asc)
  const itemsBoxRef = useRef(null)
  const optionsBoxRef = useRef(null)
  const selectedItemValue = formContext?.selectedItemValue
  const updateContext = formContext.updateContext
  const selectedItem = items.find((item) => item.value === selectedItemValue)
  const selectedItemsHasAllSelected = hasAllSelectedOptions(selectedItem)
  const itemsTitle = schema?.items?.title
  const optionsTitle = schema?.items?.properties?.options?.title
  const disabled = options?.disabled
  const boxesClass = disabled ? 'disabled-greyed  pe-none' : ''
  const { t } = useTranslation()

  /*
   * Effects
   */
  useEffect(() => {
    if (itemsBoxRef.current && optionsBoxRef.current) {
      itemsBoxRef.current.scrollTop = formContext?.itemsBoxScroll
      optionsBoxRef.current.scrollTop = formContext?.optionsBoxScroll
    }
  }, [formContext])
  /*
   * Event Actions
   */
  const onSelectItem = useCallback(
    (value) => {
      updateContext?.({
        selectedItemValue: value,
        itemsBoxScroll: itemsBoxRef.current.scrollTop,
      })
    },
    [formContext]
  )
  const onChangeOption = useCallback(
    (input) => {
      if (selectedItem?.value) {
        const updatedValue = getValuesBySelectedOption(selectedItem, input, items)
        onChange(updatedValue)
        updateContext?.({
          optionsBoxScroll: optionsBoxRef.current.scrollTop,
        })
      }
    },
    [onChange, selectedItem]
  )
  const onClickSelectAll = useCallback(() => {
    if (selectedItem?.value) {
      const updatedValue = getUpdateOptions(selectedItem, selectedItemsHasAllSelected, items)
      onChange(updatedValue)
    }
  }, [onChange, selectedItem])

  const hasEmptyOptions = isEmpty(selectedItem?.options)

  return (
    <div className={'dual-selector-widget'}>
      <DualSelectorBox boxRef={itemsBoxRef} title={itemsTitle}>
        {items?.map((item, index) => (
          <div key={`dual-left-box_${item.value}-${index}`} className={boxesClass}>
            <DualSelectorItem
              {...item}
              selected={selectedItem?.value === item.value}
              onSelectItem={onSelectItem}
              showCheckIcon={hasSomeSelectedOptions(item)}
            />
          </div>
        ))}
      </DualSelectorBox>
      <DualSelectorBox boxRef={optionsBoxRef} className='dual-selector-widget--box-distance' title={optionsTitle}>
        {selectedItem?.value && (
          <div className={`${boxesClass} ${hasEmptyOptions ? 'dual-selector-widget--full u-overflow-y-hidden' : ''} `}>
            {!hasEmptyOptions && (
              <div className='dual-selector-widget--opt-header'>
                <button type='button' className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right' onClick={onClickSelectAll}>
                  {!selectedItemsHasAllSelected ? t('Global.button.select_all') : t('Global.button.deselect_all')}
                </button>
                {sortType === SortingTypes.Asc ? (
                  <ListIconDown className='u-cursor--pointer' onClick={() => toggleSorting(SortingTypes.Desc)} />
                ) : (
                  <ListIconUp className='u-cursor--pointer' onClick={() => toggleSorting(SortingTypes.Asc)} />
                )}
              </div>
            )}
            <div className='dual-selector-widget--opt-section dual-selector-widget--full'>
              <DualSelectorOption options={selectedItem?.options} onChangeOption={onChangeOption} orderType={sortType} />
            </div>
          </div>
        )}
      </DualSelectorBox>
    </div>
  )
}

DualSelectorWidget.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  schema: PropTypes.object,
  options: PropTypes.object,
  formContext: PropTypes.object,
}
