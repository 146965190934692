import React from 'react'
import PropTypes from 'prop-types'
import CheckboxStatic from 'components/form/CheckboxStatic'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const colonSymbol = ':'
export const CompanyDetails = (props) => {
  const {
    onEdit,
    isCot,
    isCotButNotCotOem,
    vendor: {
      name,
      status,
      registrationNumber,
      address,
      registrationAddress,
      website,
      hqCountry,
      holidaysCalendar,
      payrollSoftware,
      senderService,
      dataProtection,
      professionalIndemnity,
    },
  } = props
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  return (
    <div>
      {isCot && (
        <SectionHeading bolded>
          <Authorization permissions={['VENDOR_EDIT']}>
            <div className='o-layout__item u-1/1 u-1/2@desktop'>
              <div className='u-float--right'>
                <div onClick={onEdit} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny' data-testid='vendor-edit'>
                  <span className='icon icon--edit' />
                </div>
              </div>
            </div>
          </Authorization>
        </SectionHeading>
      )}

      <p className='u-text--normal u-margin-none' data-testid='name'>
        <strong>{t('Vendor.company_details_labels.name', { colonSymbol })}</strong>: {name}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='status'>
        <strong>{t('Vendor.company_details_labels.status', { colonSymbol })}</strong>: {status}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='registrationNumber'>
        <strong>{t('Vendor.company_details_labels.company_reg_number', { colonSymbol })}</strong>: {registrationNumber}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='address'>
        <strong>{t('Vendor.company_details_labels.address', { colonSymbol })}</strong>: {address}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='registeredAddress'>
        <strong>{t('Vendor.company_details_labels.registered_address', { colonSymbol })}</strong>: {registrationAddress}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='website'>
        <strong>{t('Vendor.company_details_labels.website', { colonSymbol })}</strong>: {website}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='hqCountry'>
        <strong>{t('Vendor.company_details_labels.hq_country', { colonSymbol })}</strong>: {hqCountry.name}
      </p>
      {isCotButNotCotOem && (
        <p className='u-text--normal u-margin-none' data-testid='holidaysCalendar'>
          <strong>{t('Vendor.company_details_labels.holidays_calendar', { colonSymbol })}</strong>: {holidaysCalendar}
        </p>
      )}
      <p className='u-text--normal u-margin-none' data-testid='payrollSoftware'>
        <strong>{t('Vendor.company_details_labels.payroll_software', { colonSymbol })}</strong>: {payrollSoftware}
      </p>
      {isCot && (
        <p className='u-text--normal u-margin-none' data-testid='payrollSoftware'>
          <strong>{t('Vendor.company_details_labels.sender_service', { colonSymbol })}</strong>: {senderService}
        </p>
      )}
      {isCotButNotCotOem && (
        <div className='o-layout'>
          <div className='o-layout__item'>
            <div className='u-float--left'>
              <p className='u-text--normal u-margin-none'>
                <strong>{t('Vendor.company_details_labels.data_protection', { colonSymbol })}</strong>
              </p>
            </div>
            <div className='u-float--left'>
              <CheckboxStatic
                className='control__indicator__input'
                checked={dataProtection}
                disabled
                indicatorClassName='control__indicator--center'
                data-testid='dataProtection'
              />
            </div>
          </div>
          <div className='o-layout__item'>
            <div className='u-float--left'>
              <p className='u-text--normal u-margin-none'>
                <strong>{t('Vendor.company_details_labels.professional_indemnity', { colonSymbol })}</strong>
              </p>
            </div>
            <div className='u-float--left'>
              <CheckboxStatic
                className='control__indicator__input'
                checked={professionalIndemnity}
                disabled
                indicatorClassName='control__indicator--center'
                data-testid='professionalIndemnity'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CompanyDetails.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    registrationNumber: PropTypes.string,
    address: PropTypes.string,
    registrationAddress: PropTypes.string,
    website: PropTypes.string,
    holidaysCalendar: PropTypes.string,
    payrollSoftware: PropTypes.string,
    dataProtection: PropTypes.bool,
    professionalIndemnity: PropTypes.bool,
    hqCountry: PropTypes.shape({
      name: PropTypes.string,
    }),
    senderService: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  isCot: PropTypes.bool.isRequired,
  isCotButNotCotOem: PropTypes.bool.isRequired,
}

export default CompanyDetails
