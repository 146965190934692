import React, { useRef, useState } from 'react'
import ServiceVendorsContainer from '../containers/ServiceVendorsContainer'
import AssignCountryServicesContainer from '../containers/AssignCountryServicesContainer'
import AddVendorToServiceContainer from '../containers/AddVendorToServiceContainer'
import Modal from 'components/Modal'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const [modals, setModals] = useState({
    showAssignCountryServicesModal: false,
    showVendorServiceForm: false,
  })
  const [companyId, setCompanyId] = useState(0)
  const [countryServiceId, setCountryServiceId] = useState(0)
  const [companyCountryServiceId, setCompanyCountryServiceId] = useState(0)
  const routeModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  const showModal = (modal, params = {}) => {
    const updatedModals = {}
    _.forOwn(modals, (value, key) => {
      updatedModals[key] = key === modal
    })
    setModals(updatedModals)
    if (params.companyId !== undefined) setCompanyId(params.companyId)
    if (params.countryServiceId !== undefined) setCountryServiceId(params.countryServiceId)
    if (params.companyCountryServiceId !== undefined) setCompanyCountryServiceId(params.companyCountryServiceId)
    routeModalRef.current.showModal()
  }

  const hideModal = () => {
    routeModalRef.current.hideModal()
    const resetModals = {}
    _.forOwn(modals, (value, key) => {
      resetModals[key] = false
    })
    setModals(resetModals)
  }

  return (
    <div>
      <ServiceVendorsContainer
        onOpenAssign={(params) => showModal('showAssignCountryServicesModal', params)}
        onOpenAddVendor={(params) => showModal('showVendorServiceForm', params)}
        {...props}
      />
      <Modal
        ref={routeModalRef}
        className='c-modal'
        modalHeading={modals.showVendorServiceForm ? t('Vendor.text.assign_to_service') : t('Vendor.text.assign_to_company')}
      >
        {modals.showAssignCountryServicesModal && <AssignCountryServicesContainer companyId={companyId} onSuccess={hideModal} {...props} />}
        {modals.showVendorServiceForm && (
          <AddVendorToServiceContainer countryServiceId={countryServiceId} companyCountryServiceId={companyCountryServiceId} onSuccess={hideModal} {...props} />
        )}
      </Modal>
    </div>
  )
}

export default RouteView
