import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioFields from 'components/form/RadioFields'
import Form from 'components/form/Form'
import { useTranslation } from 'react-i18next'
import { batch } from 'react-redux'
import { i18nNameSpaces } from 'i18n/types'

const DeleteEmployeeAddressView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [filteredList, setFilteredList] = useState(props.addresses)

  useEffect(() => {
    const currentRecord = props.addresses.find((address) => address.id === props.selectedId)
    const records = props.addresses.filter((address) => address.id !== props.selectedId || (address.type === '' && address.addressLine1 === ''))
    batch(() => {
      setSelectedAddress(currentRecord)
      setFilteredList(records)
    })
    return () => {
      setFilteredList(null)
    }
  }, [])

  const onChange = (e) => props.onSelectChange(e.target.value)
  const showListOfItems = selectedAddress && selectedAddress.isPrimary && filteredList.length >= 1

  return (
    <>
      <p>{t('Employee.modal.confirm_remove_address')}</p>
      {showListOfItems && (
        <div>
          <p>{t('Employee.modal.select_new_primary_address')}</p>
          <div className='o-layout__item u-margin-bottom u-1/1 '>
            <div className='c-panel u-bg--white border-0 rounded u-padding-small'>
              <Form onSubmit={null}>
                <Field
                  component={RadioFields}
                  name='addresses'
                  onChange={onChange}
                  options={filteredList.map((addr) => {
                    return {
                      label: `${addr.type || t('Employee.address.default_type')} - ${addr.addressLine1 || t('Global:Global.text.blank')}`,
                      value: addr.id,
                      compareValue: addr.id.toString(),
                    }
                  })}
                  header=''
                  headerClassName='u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                />
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

DeleteEmployeeAddressView.propTypes = {
  addresses: PropTypes.array,
  selectedId: PropTypes.number,
  onSelectChange: PropTypes.func,
}

export default reduxForm({
  // a unique name for the form
  form: 'deleteEmployeeAddress',
})(DeleteEmployeeAddressView)
