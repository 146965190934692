import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import ConfirmationModal from 'components/ConfirmationModal'
import ReportItem from 'routes/Reporting/components/ReportItem'
import Modal from 'components/Modal'
import ReportResultsModalView from 'routes/Reporting/components/ReportResultsModalView'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const SavedReportsList = (props) => {
  const [state, setState] = useState({
    reportId: null,
    reportName: null,
  })
  const modalViewReportModalRef = useRef(null)
  const deleteReportRef = useRef(null)

  const { t } = useTranslation([i18nNameSpaces.Report])

  const handleModalOpen = (modalRef, report) => {
    setState({ reportId: report.id, reportName: report.name })
    modalRef.current.showModal()
  }

  const { reports, deleteReport } = props

  return (
    <div>
      <SectionHeading text={t('Report.text.recent_reports')} />
      <Modal
        ref={modalViewReportModalRef}
        className='c-modal c-modal--full c-modal--overflow-y'
        topLevelClassnames='u-margin-top-small'
        modalHeading=''
        data-testid='view-report-modal'
      >
        <ReportResultsModalView reportId={state.reportId} />
      </Modal>
      <ConfirmationModal
        ref={deleteReportRef}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => deleteReport(state.reportId)}
      >
        <p>{t('Report.text.recent_reports')}</p>
      </ConfirmationModal>

      <div className='o-layout'>
        {reports.map((report) => (
          <ReportItem
            key={report.id}
            report={report}
            showSave={!report.saved}
            onSaveReport={props.onSaveReport}
            handleReportModalOpen={() => handleModalOpen(modalViewReportModalRef, report)}
            onDelete={() => {
              handleModalOpen(deleteReportRef, report)
            }}
            {...props}
          />
        ))}
      </div>
    </div>
  )
}

SavedReportsList.propTypes = {
  reports: PropTypes.array,
  deleteReport: PropTypes.func,
  onSaveReport: PropTypes.func,
}

export default SavedReportsList
