import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Authorization } from 'containers/Authorization'
import Modal from 'components/Modal'
import LeaveFormEditContainer from '../containers/LeaveFormEditContainer'
import LeaveDeleteContainer from '../containers/LeaveDeleteContainer'
import BaseConfirmationModal from 'components/BaseConfirmationModal'
import moment from 'moment'
import PayslipTable from 'components/table/PayslipTable'
import { i18nNameSpaces } from 'i18n/types'

const LeavesTable = ({ attendances, hasAccess }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const [attendancePeriodId, setAttendancePeriodId] = useState(null)
  const modalEditLeaveRef = useRef(null)
  const modalDeleteLeaveRef = useRef(null)

  useEffect(() => {
    if (!attendances.length) {
      setAttendancePeriodId(null)
    }
  }, [attendances])

  const handleModalOpen = (modalRef, id) => {
    modalRef.showModal()
    setAttendancePeriodId(id)
  }

  const renderRows = () => {
    return attendances.map(({ id, fromDate, toDate, localName, globalName, totalDays }) => {
      return {
        leave: `${moment(fromDate).format('DD/MM/YY')} - ${moment(toDate).format('DD/MM/YY')}`,
        type: (
          <span data-testid='leave-type'>
            {globalName} ({localName})
          </span>
        ),
        actions: (
          <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
            <div>
              <div
                onClick={() => handleModalOpen(modalEditLeaveRef.current, id)}
                data-testid={`leave-edit-${id}`}
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
                title={t('Global:Global.text.edit')}
              >
                <span className='icon icon--edit' />
              </div>
              <div
                onClick={() => handleModalOpen(modalDeleteLeaveRef.current, id)}
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
                title={t('Global:Global.text.delete')}
              >
                <span className='icon icon--trash' />
              </div>
            </div>
          </Authorization>
        ),
        totalDays: totalDays,
      }
    })
  }

  const hasAccessToEdit = hasAccess(['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT'])

  return (
    <div>
      <PayslipTable
        wrapperClassName='u-1/1'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        data-testid='employee-time-and-attendance-leaves'
        headings={[
          {
            Header: t('Global:Global.text.leave'),
            accessor: 'leave',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Header: t('Global:Global.text.type'),
            accessor: 'type',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Header: t('Employee.leave_form.total_days'),
            accessor: 'totalDays',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Header: hasAccessToEdit ? t('Global:Global.button.actions') : '',
            accessor: 'actions',
            actionCell: true,
            disableFilters: true,
            disableSortBy: true,
          },
        ]}
        data={renderRows()}
      />
      <Modal ref={modalEditLeaveRef} className='c-modal c-modal--half' modalHeading={t('Employee.modal.heading.edit_leave')} data-testid='leave-edit-modal'>
        {attendancePeriodId ? (
          <LeaveFormEditContainer onSubmit={() => modalEditLeaveRef.current.hideModal()} id={attendancePeriodId} {...{ attendances, hasAccess }} />
        ) : null}
      </Modal>
      <BaseConfirmationModal ref={modalDeleteLeaveRef} className='c-modal' modalHeading={t('Global:Global.modal.confirmation')}>
        <LeaveDeleteContainer
          attendancePeriodId={attendancePeriodId}
          onSubmit={() => modalDeleteLeaveRef.current.hideModal()}
          {...{ attendances, hasAccess }}
          onReject={() => modalDeleteLeaveRef.current.hideModal()}
        />
      </BaseConfirmationModal>
    </div>
  )
}

LeavesTable.propTypes = {
  attendances: PropTypes.array,
  hasAccess: PropTypes.func,
}

export default LeavesTable
