import CoreLayout from 'layouts/PageLayout/PageLayout'
import ReleasesRoute from './routes/Releases'
import InnerWrapper from './components/InnerWrapper'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/resources',
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Resource, InnerWrapper) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  tabbedLayout: true,
  childRoutes: [ReleasesRoute(store)],
})
