import RouteView from './components/RouteView'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import YearRoute from './routes/Year'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: 'calendar',
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Toolbox, RouteView) },
  exact: true,
  isOrphanedChild: true,
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [YearRoute(store)],
})
