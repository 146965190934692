import React from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getComputeDomVarColor } from 'utils/colors'
import { AppVarColorName } from 'utils/enums/colorsEnum'

const SwitchToggle = (props) => {
  const { t } = useTranslation()
  const { input, disabled, label } = props
  const { onChange, value } = input

  return (
    <div className={`switch-toggle ${disabled ? 'disabled-greyed' : ''}`} data-testid={props['data-testid']}>
      {label ? <span className='o-block o-block--left u-text--small u-margin-bottom-small'>{label}</span> : null}
      <div title={value ? t('Global.button.yes') : t('Global.text.no')} className={disabled ? 'u-cursor--not-allowed o-grid' : 'o-grid'}>
        <Switch
          onChange={!disabled ? onChange : () => {}}
          checked={value}
          disabled={disabled}
          onColor={getComputeDomVarColor(AppVarColorName.SwitchToggleOn)}
          onHandleColor='#fff'
          offColor='#EDEAF2'
          handleDiameter={13}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          height={15}
          width={34}
          className='react-switch'
          id='normal-switch'
        />
      </div>
    </div>
  )
}

SwitchToggle.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.bool.isRequired,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  'data-testid': PropTypes.string,
}

export default SwitchToggle
