/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Back from 'components/Back'
import Moment from 'components/Moment'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const FilterName = ({ report, t }) => {
  return (
    <div>
      {report.categoryName ? (
        <span className='u-text--xtra-huge u-text--minsk'>
          <span className='u-weight--bold'>{report.categoryName}</span>
          <span className='u-text--xtra-huge u-text--minsk'> / {report.name}</span>
        </span>
      ) : (
        <span className='u-text--xtra-huge u-text--minsk'>{report.name}</span>
      )}
      <div>
        <span>
          <Moment date={report.createdAt.date} format='DD/MM/YYYY HH:mm' />
          {report.createdAt.timezone ? ` ${report.createdAt.timezone} ` : null}
        </span>
        <span>{report.subCat}, </span>
        {report.filters.fromDate ? <Moment format='DD/MM/YYYY'>{report.filters.fromDate}</Moment> : t('Report.text.any_date')}
        &nbsp;-&nbsp;
        {report.filters.toDate ? <Moment format='DD/MM/YYYY'>{report.filters.toDate}</Moment> : t('Report.text.any_date')}
      </div>
    </div>
  )
}
export const ReportAnalyticsHeader = (props) => {
  const [showFiltersList, setShowFiltersList] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation([i18nNameSpaces.Report])

  const { report } = props
  const isPayAndTaxesReport = report.type === 'GlobalPayAndTaxesReport'

  const onBackNavigate = () => {
    history.push(location.state?.from || '/reporting/customize-report')
  }

  const getArrowClasses = () => {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-top-tiny': true,
      active: showFiltersList,
    })
  }
  if (props.hideOnExport) {
    return (
      <div className='o-layout__item u-padding-left-none u-1/1 u-2/3@tablet u-5/6@desktop u-margin-bottom-small' style={{ height: '60px' }}>
        <FilterName report={report} {...props} />
      </div>
    )
  }
  return (
    <div className='page--reporting-analytics-header'>
      <div className='o-layout o-grid ai-start u-margin-bottom-small'>
        <div className={`u-margin-right ${props.hideOnExport ? 'v-hidden' : ''}`}>
          <Back onBackNavigate={onBackNavigate} />
        </div>
        <div className='o-layout__item u-padding-left-none u-1/1 u-2/3@tablet u-5/6@desktop'>
          <FilterName report={report} t={t} />
          <button type='button' onClick={() => setShowFiltersList(!showFiltersList)} className='c-btn--unset-default-styles u-text--small u-cursor--point'>
            <span className='u-float--left'>{showFiltersList ? t('Global:Global.text.hide_filters') : t('Global:Global.text.show_filters')}</span>
            <span className={getArrowClasses()} />
          </button>
        </div>

        <div className={`d-flex d-flex-item--auto jc--flex-end  ${props.hideOnExport ? 'v-hidden' : ''}`}>
          <button
            type='button'
            className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
            title={t('Global:Global.text.download')}
            onClick={(e) => {
              e.preventDefault()
              props.downloadReport(report.id)
            }}
          >
            <span className='icon icon--download' />
          </button>
        </div>
      </div>
      <div>
        <div className={`analytics-show-filter o-block o-block--left ${showFiltersList ? 'filter-opened' : ''}`}>
          <div className='rounded shadow border u-padding-small u-margin-bottom u-text--small'>
            <span className='u-weight--bold'>{t('Report.field.country')}: </span>
            <span>{report.filters.countryNames.length ? report.filters.countryNames.join(', ') : t('Global:Global.placeholder.any')}</span>
            <br />
            <span className='u-weight--bold'>{t('Report.field.company')}: </span>
            <span>{report.filters.companyNames.length ? report.filters.companyNames.join(', ') : t('Global:Global.placeholder.any')}</span>
            <br />
            {!isPayAndTaxesReport && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.payrun')}: </span>
                <span>{report.filters.payrollNames.length ? report.filters.payrollNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
              </span>
            )}
            {report.type === 'EmployeeReport' && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.type_of_employment_engagement')}: </span>
                <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
                <span className='u-weight--bold'>{t('Report.field.business_unit')}: </span>
                <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
                <span className='u-weight--bold'>{t('Report.field.cost_center')}: </span>
                <span>{report.filters.costCenterNames.length ? report.filters.costCenterNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
              </span>
            )}
            {report.type === 'AuditTrailReport' && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.process_owner')}: </span>
                <span>{report.filters.processOwnerNames.length ? report.filters.processOwnerNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
              </span>
            )}
            {['PayrollInstanceReport', 'EmployeeDataChangesReport', 'VarianceReport', 'GlobalPayrunManagementReport'].includes(report.type) && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.payrun')}: </span>
                <span>{report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
              </span>
            )}
            {(report.type === 'PayrollReport' || report.type === 'PayrollInstanceReport' || report.type === 'GlobalPayrunManagementReport') && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.show_only_totals')}: </span>
                <span>{report.filters.showOnlyTotals ? t('Global:Global.button.yes') : t('Global:Global.text.no')}</span>
                <br />
              </span>
            )}
            {['PayrollInstanceReport', 'EmployeeDataChangesReport', 'GlobalPayrunManagementReport'].includes(report.type) && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.type_of_employment_engagement')}: </span>
                <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
              </span>
            )}
            {['PayrollReport', 'GlobalPayAndTaxesReport'].includes(report.type) && report.filters.termCategory && (
              <div>
                <span>
                  <span className='u-weight--bold'>{t('Report.field.term_category')}: </span>
                  <span>{report.filters.termCategoryNames.length ? report.filters.termCategoryNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                  <br />
                  <span className='u-weight--bold'>{t('Report.field.elements_subcategory')}: </span>
                  <span>
                    {report.filters.termSubCategoryNames.length ? report.filters.termSubCategoryNames.join(', ') : t('Global:Global.placeholder.any')}
                  </span>
                  <br />
                  <span className='u-weight--bold'>{t('Report.field.elements')}: </span>
                  <span>{report.filters.termNames.length ? report.filters.termNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                </span>
              </div>
            )}
            {['PayrollInstanceReport', 'PayrollReport', 'EmployeeDataChangesReport', 'VarianceReport', 'GlobalPayrunManagementReport'].includes(report.type) &&
              report.reportingCurrency.length > 0 && (
              <div>
                <span className='u-weight--bold'>{t('Report.field.reporting_currency')}: </span>
                <span>{report.reportingCurrency.join(', ')}</span>
              </div>
            )}
            {['PayrollInstanceReport', 'PayrollReport', 'EmployeeDataChangesReport', 'VarianceReport'].includes(report.type) &&
              report.reportingCurrency.length > 0 && (
              <div>
                <span className='u-weight--bold'>{t('Report.field.reporting_currency')}: </span>
                <span>{report.reportingCurrency.join(', ')}</span>
              </div>
            )}
            {report.type === 'ChangeLogReport' && (
              <span>
                <span className='u-weight--bold'>{t('Report.field.payrun')}: </span>
                <span>{report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
                <span className='u-weight--bold'>{t('Report.field.type_of_employment_engagement')}: </span>
                <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
                <span className='u-weight--bold'>{t('Report.field.business_unit')}: </span>
                <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                <br />
                <span className='u-weight--bold'>{t('Report.field.modified_by')}: </span>
                <span>
                  {report.filters.modifiedBy.length && report.filters.modifiedBy.filter((i) => i !== '').length
                    ? report.filters.modifiedBy.join(', ')
                    : t('Global:Global.placeholder.any')}
                </span>
                <br />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
