import i18n from 'i18next'

import EditableCell from 'components/table/cell/EditableCell'
import { TableHeading } from 'utils/helperClasses/tableHeadings'

export const generateCommentaryHeadings = () => [
  new TableHeading({
    accessor: 'body',
    heading: i18n.t('Payrun:Payrun.commentary_headings.comments'),
    requiredField: true,
    className: 'u-text--left',
    columnClassName: 'u-text--left',
    editable: true,
    Cell: EditableCell,
    inputType: 'textArea',
    disableSortBy: true,
    charactersPerLine: 110,
  }),
  new TableHeading({
    accessor: 'date',
    heading: i18n.t('Payrun:Payrun.commentary_headings.date'),
    className: 'u-text--left u-fixed-width--200',
    columnClassName: 'u-text--left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'authorName',
    heading: i18n.t('Payrun:Payrun.commentary_headings.submittedBy'),
    className: 'u-text--left u-fixed-width--250',
    columnClassName: 'u-text--left ',
    disableSortBy: true,
  }),
]
