import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import RadioFields from 'components/form/RadioFields'
import { required } from 'utils/validations'
import TasksTable from './TasksTable'
import { i18nNameSpaces } from 'i18n/types'

const requiredSymbol = '*'

const CreateForm = ({ tasks, globalOwnersOptions, tasksOptions, stepsOptions, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  return (
    <div>
      <Form showSubmitBtn {...rest}>
        <div className='o-layout u-text--center'>
          <div className='o-layout__item u-1/1'>
            <div className='o-grid o-grid--middle o-grid--center o-form-group'>
              <Field
                component={RadioFields}
                name='globalOwner'
                options={globalOwnersOptions}
                header={t('Payroll.form.globalOwner.type', { requiredSymbol })}
                headerClassName='c-label o-block--inline-block u-margin-bottom-tiny'
                formGroupClassName='u-margin-none'
                validate={required}
              />
            </div>
          </div>
          <div className='o-layout__item u-1/1'>
            <Field
              name='name'
              label={t('Payroll.field.name', { requiredSymbol })}
              labelClassName='c-label'
              component={InputField}
              type='text'
              validate={required}
            />
          </div>
          <div className='o-layout__item u-1/1'>
            <Field
              name='payrollStep'
              label={t('Payroll.form.payrollStep.label', { requiredSymbol })}
              component={CustomSelectField}
              labelClassName='c-label'
              className='u-text--left'
              placeholder={t('Global:Global.select.placeholder')}
              options={stepsOptions}
              validate={required}
            />
          </div>
          <div className='o-layout__item u-1/3'>
            <Field
              name='position'
              label={t('Payroll.field.select_position', { requiredSymbol })}
              component={CustomSelectField}
              labelClassName='c-label'
              className='u-text--left'
              placeholder={t('Global:Global.select.placeholder')}
              options={tasksOptions}
              validate={required}
            />
          </div>
          <div className='page--create-form o-layout__item u-2/3 u-margin-top'>
            <TasksTable tasks={tasks} />
          </div>
        </div>
      </Form>
    </div>
  )
}

CreateForm.propTypes = {
  tasks: PropTypes.array.isRequired,
  tasksOptions: PropTypes.array.isRequired,
  globalOwnersOptions: PropTypes.array.isRequired,
  stepsOptions: PropTypes.array.isRequired,
}

export default CreateForm
