import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'
const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'

const CommentaryView = ({ tableHeadings, data, fieldErrors, toggleModes, inEditMode, inAddMode, onCancel, onSave, onUpdateGlobalDataForSave }) => {
  const editableOptions = {
    inGlobalEditMode: inEditMode,
    inAddMode: inAddMode,
    fieldErrors: fieldErrors,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
  }
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const isDisabledEdit = inAddMode || data?.length === 0
  return (
    <div className='rounded border u-padding-small'>
      <div className='u-float--right'>
        {(inAddMode || inEditMode) && (
          <>
            <button onClick={onCancel} className={btnClasses} title={t('Global:Global.button.cancel')} data-testid='general-ledger-account-cancel'>
              {t('Global:Global.button.cancel')}
            </button>
            <button onClick={onSave} className={btnClasses} title={t('Global:Global.button.save')} data-testid='general-ledger-account-save'>
              {t('Global:Global.button.save')}
            </button>
          </>
        )}
        <button
          onClick={() => toggleModes('edit', !inEditMode)}
          className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
          title={t('Global:Global.text.edit')}
          disabled={isDisabledEdit}
          data-testid='general-ledger-account-edit'
        >
          <span className='icon icon--edit' />
        </button>
        <button
          onClick={() => toggleModes('add', !inAddMode)}
          className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
          title={t('Global:Global.text.add')}
          disabled={inEditMode || inAddMode}
          data-testid='general-ledger-account-create'
        >
          <span className='icon icon--plus' />
        </button>
      </div>
      <PayslipTable data={data} headings={tableHeadings} dynamicValues editableOptions={editableOptions} wrapperClassName='u-margin-top-large' />
    </div>
  )
}

CommentaryView.propTypes = {
  tableHeadings: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fieldErrors: PropTypes.array,
  toggleModes: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  inAddMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdateGlobalDataForSave: PropTypes.func.isRequired,
}

export default CommentaryView
