import React from 'react'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { createEmployeeFields, getFieldsBySection, renderFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const ContractorFields = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const { form, employee, typeOfEmploymentSelectedValue } = props
  const isContractorCompany = employee ? employee.isContractorCompany : EmploymentStatusHelper.isContractorCompany(typeOfEmploymentSelectedValue)
  const isContractorAgency = employee ? employee.isContractorAgency : EmploymentStatusHelper.isContractorAgency(typeOfEmploymentSelectedValue)
  const showVatRegistrationNumber = isContractorCompany || isContractorAgency
  const isCreateForm = form === 'employeeCreate'

  const createContractorFields = () =>
    createEmployeeFields({
      fields: employeeFieldsConfigAlt({ isCreate: true }),
      section: 'personal',
      subSection: 'contractorDetails',
      showVatRegistrationNumber,
    }).map((field) => renderFields(field))

  const getContractorFields = () =>
    getFieldsBySection({
      fields: employeeFieldsConfigAlt({ isEdit: true }),
      employee,
      section: 'personal',
      subSection: 'contractorDetails',
      showVatRegistrationNumber,
    }).map((field) => renderFields(field))

  return (
    <Form {...props}>
      <div className='o-layout' data-testid='contractor-fields-area'>
        {isContractorCompany && (
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div
              className='c-panel u-bg--white border-1-valhalla rounded
            u-padding-small'
              data-testid='contractor-company-name'
            >
              <Field
                name={'contractorCompanyName'}
                label={t('BusinessComponent.form.contractors_company_name')}
                component={InputField}
                type='text'
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
              />
            </div>
          </div>
        )}
        {isContractorAgency && (
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div
              className='c-panel u-bg--white border-1-valhalla rounded
            u-padding-small'
              data-testid='agency-company-name'
            >
              <Field
                name={'agencyCompanyName'}
                label={t('BusinessComponent.form.agency_company_name')}
                component={InputField}
                type='text'
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
              />
            </div>
          </div>
        )}
        {isCreateForm ? createContractorFields() : getContractorFields()}
      </div>
    </Form>
  )
}

ContractorFields.propTypes = {
  form: PropTypes.string,
  employee: PropTypes.object,
  typeOfEmploymentSelectedValue: PropTypes.string,
}

export default ContractorFields
