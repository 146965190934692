import React from 'react'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const InnerWrapper = ({ ...props }) => {
  const { t } = useTranslation(i18nNameSpaces.Resource)
  return <InnerWrapperTabs tabs={[{ name: t('Resource.tabs.releases'), to: '/resources/releases' }]} {...props} />
}

export default InnerWrapper
