import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { updateUserPhone } from 'redux/actions/auth'
import UserActivationForm from '../components/UserActivationForm'
import { showMessage } from 'redux/actions/modal'
import i18n from 'i18next'

class ChangePhoneNumberContainer extends React.Component {
  render () {
    return <UserActivationForm {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: (data) =>
      dispatch(updateUserPhone(data)).then(() => {
        props.onSuccess()
        dispatch(reset('authCodeSend'))
        dispatch(
          showMessage({
            body: i18n.t('Global:Global.message.phone_updated'),
          })
        )
      }),
  }
}

const mapStateToProps = (state, props) => {
  const mobilePhone = state.auth.mobilePhone.phone

  return {
    label: mobilePhone ? (
      'Please update your phone number here:'
    ) : (
      <span>
        We are missing your phone number!
        <br />
        To activate your account, please enter it here:
      </span>
    ),
    buttonText: 'Confirm',
    includePhoneCode: true,
    colorClassBtn: 'c-btn--curious u-margin-top-none',
    initialValues: {
      confirmationToken: props.match.params.token,
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'userPhone',
    destroyOnUnmount: false,
    enableReinitialize: true,
  })(ChangePhoneNumberContainer)
)
