import React from 'react'
import thunk from 'redux-thunk'
import api, { getParams, SERVER_ERROR, TOKEN_ERROR } from 'utils/api'
import Promise from 'es6-promise'
import { resetAction } from 'redux/actions/persist'
import { showMessage } from 'redux/actions/modal'
import { isNetworkError } from 'utils/fetch'
import { clearStorageByNamepace } from 'utils/storage'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import { BreakLine } from 'components/HtmlWrappers/BreakLine'

/**
 * Handle main (top level) errors
 *
 * Here we handle the errors, caught by utils/api._handleResponse
 *
 * @param {Error} error
 * @param {function} dispatch
 * @param {Object} state
 * @returns {*}
 */
export const handleMainErrors = (error, dispatch, { config, ...rest }, url = null) => {
  const t = i18next.t
  let message = ''

  if (url && url === 'reports' && (isNetworkError(error.message) || error.message === SERVER_ERROR)) {
    // We have no specific check for reports timing out.
    // The backend is generating the report, just cloudfront times out
    // Also based on browser the returned error can vary. So we need to check all three scenarios
    message = (
      <Trans
        i18nKey='Global.modal.report_in_progress'
        ns={i18nNameSpaces.Global}
        components={[<h2 key='0' className='u-margin-none u-text--huge u-weight--bold' />, <BreakLine key='1' />]}
      />
    )
  } else if (isNetworkError(error.message)) {
    // If it's a network error (no internet connection), then we set our custom message
    message = t('Global.modal.network_error')
  } else if (error.message === SERVER_ERROR) {
    // We just want to transform the SERVER_ERROR to JSX, because it's not possible to pass JSX via Error object.
    // TODO - find a better way. Previously I tried with a custom Error object, but in the production build,
    // there was a problem with the Error type, and we can't differentiate Errors.
    // However, have to do it better.
    message = <span>{t('Global.modal.server_error')}</span>
  } else {
    message = error.message
  }

  // If the token is expired or blacklisted on the BE,
  // then we reset the Store and we logout the user from the app
  if (message === TOKEN_ERROR) {
    const keycloakInstance = window.keycloakInstance
    if (keycloakInstance && keycloakInstance?.authenticated) {
      console.error('[Keycloak Token Error] - TOKEN_ERROR , Closing session after authentication failure')
      clearStorageByNamepace()
      keycloakInstance.logout()
    }
    if (!config?.migratedToRHSSO) {
      dispatch(resetAction({ config }))
    }
  }

  // Show error message to the user in a modal
  dispatch(showMessage({ body: message }))

  // Reject the promise, in order to prevent future chain execution (.then())
  return Promise.reject(error)
}

/**
 * Middleware that decorates `thunk` as passing the API utility.
 *
 * Doing it in that way we decouple the API layer from the Store.
 * Otherwise the API is connected to the Store, that results in Circular dependency issue,
 * because of the following flow:
 * ` store/store -> orm models -> actions -> API -> store/store `
 *
 * Now we can safely pass any Store property, to the API!
 *
 * @param dispatch
 * @param getState
 * @returns {(next:Dispatch<S>)=>Dispatch<S>}
 */
export default ({ dispatch, getState }) => {
  const decorated = thunk.withExtraArgument({
    api: {
      fetch: (uri, params) => api.fetch(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      post: (uri, params) => api.post(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState(), uri)),
      put: (uri, params) => api.put(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      patch: (uri, params) => api.patch(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      delete: (uri, params) => api.delete(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      upload: (uri, params) => api.upload(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
    },
  })
  return decorated({ dispatch, getState })
}
