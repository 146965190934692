import React from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const setValue = (selected) => (selected ? selected.value : null)

const Select = ({
  value,
  name,
  onChange,
  onFocus,
  onChanged,
  type,
  disabled,
  className = '',
  placeholder,
  options,
  clearable,
  multi,
  disableSelectAllOption,
  optionRenderer,
  optionComponent,
  valueComponent,
  hasNoneOption,
  returnFullValue,
  ...rest
}) => {
  const { t } = useTranslation()

  const allOption = {
    label: t('Global:Global.button.select_all'),
    value: '*',
  }

  const noneOption = {
    label: t('Global:Global.text.none'),
    value: 0,
  }

  return (
    <ReactSelect
      components={{ DropdownIndicator: () => {} }}
      type={type}
      name={name}
      value={value}
      className={'c-custom-select ' + className}
      searchable
      onBlurResetsInput={false}
      onChange={(selected) => {
        if (multi) {
          const areAllSelected = selected.find((option) => option.value === '*')
          // If `Select all` option is selected, then we pass all the options (except the disabled ones) as selected.
          const sanitized = areAllSelected
            ? hasNoneOption
              ? [...options.filter((o) => !o.disabled), noneOption]
              : [...options.filter((o) => !o.disabled)]
            : selected

          const selectedValues = sanitized ? sanitized.map((value) => value.value) : null

          onChange(selectedValues)
          if (onChanged) onChanged(selectedValues)
        } else {
          onChange(returnFullValue ? selected : setValue(selected))
          if (onChanged) onChanged(returnFullValue ? selected : setValue(selected))
        }
      }}
      {...(onFocus ? { onFocus } : {})}
      disabled={disabled}
      options={multi && !disableSelectAllOption ? (hasNoneOption ? [allOption, ...options, noneOption] : [allOption, ...options]) : options}
      placeholder={placeholder}
      clearable={clearable}
      multi={multi}
      closeOnSelect={!multi}
      removeSelected={false}
      optionRenderer={optionRenderer}
      optionComponent={optionComponent}
      {...(valueComponent && { valueComponent: (props) => valueComponent({ ...props, selected: value, multi }) })}
      data-testid={rest['data-testid']}
      aria-label={rest['aria-label']}
    />
  )
}

Select.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  multi: PropTypes.bool,
  // If multi is enabled, we can configure whether or not `allOption` should be included as option.
  // By default it's included.
  disableSelectAllOption: PropTypes.bool,
  optionRenderer: PropTypes.func,
  // Form field onChange func
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  // Custom form field onChange,where the value is not transformed by the react-select component
  // https://github.com/erikras/redux-form/issues/3446#issuecomment-341087152
  onChanged: PropTypes.func,
  optionComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  valueComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  hasNoneOption: PropTypes.bool,
  returnFullValue: PropTypes.bool,
  // Used by Cypress E2E framework, for querying DOM easily
  // @credits https://docs.cypress.io/guides/references/best-practices.html#Selecting-Elements
  'data-testid': PropTypes.string,
  /**
   * Enabling this flag, will protect us from having selected options as state/value,
   * in the case the same options are removed later.
   *
   * Also on a field unmount, this option will unregister the field. Otherwise the field is still kept.
   *
   *  It sounds a bit complex, please follow the example below.
   *
   * Here's the main reason we implemented this logic:
   * 1. Imagine we have the following select fields, where each select options, are relative to the parent select field:
   * 2. Countries -> Companies (filtered companies, by the selected countries) -> Business units (filtered
   *  business units, by the selected companies).
   * 3. Now choosing a Country, we are able to select a Company, this is located in that Country.
   *   The same rules apply for business units too. Let's say we make our decision and we select 1 Country, 1 Company
   *   and 1 Business unit. All's good for now.
   * 4. But here's the problem. What would happen if we change the parent Country, while we still having selected
   *  Companies and Business units? Gotcha!
   *  The Country value will be changed, Companies and Business units options will be updated, but the old selected
   *  values of Companies and Business units will be still kept in the select state.
   *  In this case, if we submit the form, the data won't be correct.
   * 5. Because of this (4.) we implemented this flag that will observe for options changes and will make sure
   * that an option can be still selected, only if this option is part of the select option list (it's not removed).
   *
   * Here's a visual representation what will the fix do, in the case we
   * are changing the parent Country select value, having this data:
   *
   * const Countries     = { 1: { name: 'Bulgaria' }, 2: { name: 'Ireland' } }
   * const Companies     = { 1: { name: 'dev_labs', country: 1 }, 2: { name: 'Google', country: 2 } }
   * const BusinessUnits = { 1: { name: 'Marketing', company: 1 }, 2: { name: 'IT', company: 2 } }
   * ---------------------------------------------------------------------------------------------
   *                                      | Country | Company              | Business Unit       |
   * First time selecting values          | 1       | 1                    | 1                   |
   * Change the country (without the fix) | 2       | 1, kept internally   | 1, kept internally  |
   * Change the country (with the fix)    | 2       | null, or empty array | null, or empty array|
   * ---------------------------------------------------------------------------------------------
   *
   * !Important: there's one side effect. In the case the whole form is unmounted,
   * our logic will still try to reset/change the select value and this will keep the form state dirty,
   * because of `componentWillUnmount` logic.
   * Later on, we can think about a way of creating a custom middleware or form reducer,
   * instead of triggering `onChange` method manually.
   */
  enableRemovedOptionFix: PropTypes.bool,
}

class SelectContainer extends React.Component {
  // On unmount, if the fix is enabled, we want to remove the field from the Store
  componentWillUnmount () {
    if (!this.props.enableRemovedOptionFix || !this.props.value) return false

    const value = setValue()

    this.props.onChange(value)
    this.props.onChanged && this.props.onChanged(value)
  }

  // On options change, we want to make sure the already selected options values aren't removed.
  // If they are removed, our goal is to unselect them from the selected options/values.
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    const { enableRemovedOptionFix, options, value, multi } = nextProps

    // If a field is changed from multi value to single value or vice versa,
    // we prefer to reset its current value to `null` in order to prevent any further problems.
    if (this.props.multi !== multi) {
      const value = setValue()
      this.props.onChange(value)
      this.props.onChanged && this.props.onChanged(value)

      return
    }

    if (!enableRemovedOptionFix || !value) return false

    // The case the field supports multiple options and the selected value is always an array
    if (multi) {
      // Do we have removed values?
      const removedValues = value.filter((value) => !options.find((nextOption) => nextOption.value === value))

      if (!removedValues.length) return false

      // If so, we remove them from the selected values
      const sanitized = value.filter((currentValue) => !removedValues.find((removedValue) => removedValue === currentValue))

      this.props.onChange(sanitized)
      this.props.onChanged && this.props.onChanged(sanitized)
    } else {
      // If one of the selected values isn't part of the next options, then's we need to remove it
      const removedValue = !options.find((o) => o.value === value)

      // Singular option is selectable (i.e. String/Number, not an Array).
      // In that case we check if the selected singular value is part of the `removedValues`.
      // If so - we just reset the selected value.
      if (removedValue) {
        this.props.onChange(setValue())
        this.props.onChanged && this.props.onChanged(setValue())
      }
    }
  }

  render () {
    return <Select {...this.props} />
  }
}

export default SelectContainer
