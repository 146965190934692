import i18n from 'i18next'

/**
 * Function to get all report getLegacyReportTypes with their details
 *
 * @returns {Array} List of report getLegacyReportTypes with their corresponding categories and subcategories
 */

export const getLegacyReportTypes = () => {
  return [
    {
      type: 'EmployeeReport',
      name: i18n.t('Report:Report.new_types_name.employee_headcount'),
      categories: [
        {
          value: 'All',
          label: 'All employees',
        },
        {
          value: 'Joiners',
          label: 'Joiners',
        },
        {
          value: 'Leavers',
          label: 'Leavers',
        },
      ],
      subcategories: [
        {
          value: 'All',
          label: 'All employees',
          category: 'All',
        },
        {
          value: 'Joiners',
          label: 'Joiners',
          category: 'Joiners',
        },
        {
          value: 'Leavers',
          label: 'Leavers',
          category: 'Leavers',
        },
      ],
    },
    {
      type: 'AuditTrailReport',
      name: i18n.t('Report:Report.new_types_name.audit_workflow'),
      categories: [
        {
          value: 'Operational',
          label: 'Operational',
        },
      ],
      subcategories: [
        {
          value: 'PayrollCalculation',
          label: 'Payroll calculation',
          category: 'Operational',
        },
      ],
    },
    {
      type: 'PayrollInstanceReport',
      name: i18n.t('Report:Report.new_types_name.payrun_report'),
      categories: [
        {
          value: 'PayrollInstance',
          label: 'Payrun',
        },
      ],
      subcategories: [
        {
          value: 'PayrollInstance',
          label: 'Payrun',
          category: 'PayrollInstance',
        },
      ],
    },
    {
      type: 'PayrollReport',
      name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
      categories: [
        {
          value: 'Costs',
          label: 'Costs',
        },
      ],
      subcategories: [
        {
          value: 'TotalEmployerCosts',
          label: 'Total employer costs',
          category: 'Costs',
        },
        {
          value: 'Terms',
          label: 'Terms',
          category: 'Costs',
        },
      ],
    },
    {
      type: 'EmployeeDataChangesReport',
      name: i18n.t('Report:Report.new_types_name.employee_payroll_changes_report'),
      categories: [
        {
          value: 'EmployeePayrollChanges',
          label: 'Employee payroll changes',
        },
      ],
      subcategories: [
        {
          value: 'EmployeePayrollChanges',
          label: 'Employee payroll changes',
          category: 'EmployeePayrollChanges',
        },
      ],
    },
    {
      type: 'VarianceReport',
      name: i18n.t('Report:Report.new_types_name.variance_report'),
      categories: [
        {
          value: 'VarianceReport',
          label: 'Variance Report',
        },
      ],
      subcategories: [
        {
          value: 'VarianceReport',
          label: 'Variance Report',
          category: 'VarianceReport',
        },
      ],
    },
    {
      type: 'ChangeLogReport',
      name: i18n.t('Report:Report.new_types_name.change_log_report'),
      categories: [
        {
          value: 'ChangeLogReport',
          label: 'Change Log Report',
        },
      ],
      subcategories: [
        {
          value: 'All',
          label: 'All',
          category: 'ChangeLogReport',
        },
        {
          value: 'PayAndTermsChanges',
          label: 'Payroll Data',
          category: 'ChangeLogReport',
        },
        {
          value: 'GeneralUpdates',
          label: 'General Updates',
          category: 'ChangeLogReport',
        },
        {
          value: 'RolesAndRights',
          label: 'Roles & Rights',
          category: 'ChangeLogReport',
        },
      ],
    },
    {
      type: 'GlobalPayrunManagementReport',
      name: i18n.t('Report:Report.new_types_name.global_payrun_management_report'),
      categories: [
        {
          value: 'GlobalPayrunManagement',
          label: 'Global Payrun Management',
        },
      ],
      subcategories: [
        {
          value: 'GlobalPayrunManagement',
          label: 'Global Payrun Management',
          category: 'GlobalPayrunManagement',
        },
      ],
    },
    {
      type: 'GlobalPayAndTaxesReport',
      name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
      categories: [
        {
          value: 'GlobalPayAndTaxes',
          label: 'Global Pay and Taxes Set Up Report',
        },
      ],
      subcategories: [
        {
          value: 'Terms',
          label: 'Terms',
          category: 'GlobalPayAndTaxes',
        },
      ],
    },
    {
      type: 'AccessRightsReport',
      name: i18n.t('Report:Report.new_types_name.access_management_report'),
      categories: [
        {
          value: 'Operational',
          label: 'Operational',
        },
      ],
      subcategories: [
        {
          value: 'PayrollCalculation',
          label: 'Payroll calculation',
          category: 'Operational',
        },
      ],
    },
    {
      type: 'CustomBuildReport',
      name: i18n.t('Report:Report.new_types_name.workforce_and_payrun_detail'),
      categories: [
        {
          value: 'Workforce',
          label: 'Workforce',
        },
      ],
      subcategories: [
        {
          value: 'PayrunDetail',
          label: 'PayrunDetail',
          category: 'Workforce',
        },
      ],
    },
    {
      type: 'OrgUnitVarianceReport',
      name: i18n.t('Report:Report.new_types_name.org_unit_variance_report'),
      categories: [
        {
          value: 'OrgUnitVarianceReport',
          label: 'Org Unit Variance Report',
        },
      ],
      subcategories: [
        {
          value: 'businessUnit',
          label: 'Business Unit',
          category: 'OrgUnitVarianceReport',
        },
        {
          value: 'costCenter',
          label: 'Cost Center',
          category: 'OrgUnitVarianceReport',
        },
        {
          value: 'department',
          label: 'Department',
          category: 'OrgUnitVarianceReport',
        },
        {
          value: 'OrgUnitVarianceReport',
          label: 'Org Unit Variance Report',
          category: 'OrgUnitVarianceReport',
        },
      ],
    },
    {
      type: 'WorkforceVarianceReport',
      name: i18n.t('Report:Report.new_types_name.workforce_variance'),
      categories: [
        {
          value: 'WorkforceVarianceReport',
          label: 'Workforce Variance',
        },
      ],
      subcategories: [
        {
          value: 'WorkforceVarianceReport',
          label: 'Workforce Variance',
          category: 'WorkforceVarianceReport',
        },
      ],
    },
    {
      type: 'WorkflowCalendarReport',
      name: i18n.t('Report:Report.new_types_name.workflow_calendar'),
      categories: [
        {
          value: 'Operational',
          label: 'Operational',
        },
      ],
      subcategories: [
        {
          value: 'PayrollCalculation',
          label: 'Payroll Calculation',
          category: 'Operational',
        },
      ],
    },
    {
      type: 'OrgUnitCostsReport',
      name: i18n.t('Report:Report.new_types_name.org_unit_costs'),
      categories: [
        {
          value: 'Costs',
          label: 'Costs',
        },
      ],
      subcategories: [
        {
          value: 'businessUnit',
          label: 'Business Unit',
          category: 'Costs',
        },
        {
          value: 'costCenter',
          label: 'Cost Center',
          category: 'Costs',
        },
        {
          value: 'department',
          label: 'Department',
          category: 'Costs',
        },
        {
          value: 'OrgUnit',
          label: 'Org Unit Costs',
          category: 'Costs',
        },
      ],
    },
  ]
}
