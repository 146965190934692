import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import VendorRoute from './routes/Vendor'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/vendors',
  indexRoute: {
    component: withTranslationContext(
      [i18nNameSpaces.Vendor, i18nNameSpaces.Payrun, i18nNameSpaces.Employee, i18nNameSpaces.Payroll, i18nNameSpaces.Client],
      RouteView
    ),
  },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [VendorRoute(store)],
})
