import React from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import CheckboxDropdownOption from 'components/form/select/CheckboxOption'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DataProtectionPolicyForm = (props) => {
  const { t } = useTranslation()
  const { keyPeople } = props

  return (
    <Form {...props}>
      <div className='o-layout'>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='retentionPeriod'
              label={t('Global.label.retentionPeriod')}
              labelClassName='c-label u-text--small u-text--curious'
              className='c-input c-input--transparent'
              component={InputField}
              type='text'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='process'
              label={t('Global.label.process')}
              labelClassName='c-label u-text--small u-text--curious'
              className='c-input c-input--transparent'
              component={InputField}
              type='text'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='trigger'
              label={t('Global.label.trigger')}
              labelClassName='c-label u-text--small u-text--curious'
              className='c-input c-input--transparent'
              component={InputField}
              type='text'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='dataOwners'
              label={t('Global.label.dataOwners')}
              component={CustomSelectField}
              optionComponent={CheckboxDropdownOption}
              labelClassName='c-label u-text--small u-text--curious'
              className='c-custom-select--transparent'
              formGroupClassName='u-margin-none'
              placeholder={t('Global.select.placeholder')}
              multi
              options={keyPeople.map((p) => ({
                value: p.id,
                label: p.fullname,
              }))}
            />
          </div>
        </div>
      </div>
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button
            type='submit'
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
          u-padding-right u-margin-top-small'
          >
            {t('Global.button.save')}
          </button>
        </div>
      </div>
    </Form>
  )
}

DataProtectionPolicyForm.propTypes = {
  keyPeople: PropTypes.array,
}

export default DataProtectionPolicyForm
