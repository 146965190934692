import React, { useLayoutEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import PayrollListView from 'components/PayrollListView'
import Fetcher from 'containers/Fetcher'
import { fetchPayrolls, invalidatePayrolls, pinPayroll } from 'redux/actions/payrolls'
import { getCompaniesRef } from 'redux/selectors/company'
import { getCountriesByCompanies } from 'redux/selectors/country'
import { getFilteredPayrolls } from 'redux/selectors/payroll'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { createFilter } from 'utils/redux/filter'
import { sortByLabel } from 'utils/strings'
import { invalidateCompanyServicePivot } from 'redux/actions/companyServicePivot'
import ConfirmationModal from 'components/ConfirmationModal'
import { invalidatePayrollInstancesByStatus } from 'redux/actions/payrollInstances'
import { isCot } from 'redux/selectors/auth'
import { getVendorById } from 'redux/selectors/vendors'
import { i18nNameSpaces } from 'i18n/types'
import { useTranslation } from 'react-i18next'

const buildFilterName = (props) => {
  return props.isVendor ? `${props.payrunState}_company=${props.match.params.companyId}` : props.payrunState
}

const sort = [
  { name: 'country', order: 'asc' },
  { name: 'payroll', order: 'asc' },
]

const DEFAULT_LIMIT = 10
export const PayrollContainer = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])
  const [selectedCountry, setSelectedCountry] = useState(props.currentCountry)
  const [selectedPayroll, setSelectedPayrolls] = useState(null)
  const confirmModal = useRef(null)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    return () => {
      dispatch(invalidatePayrolls())
      dispatch(invalidateCompanyServicePivot())
    }
  }, [])

  const { payrolls, payrunState } = props
  const onCountryChange = (val) => {
    setSelectedCountry(val)
    props.onSubmit(val)
  }

  const onPinPayrollClick = (id) => {
    setSelectedPayrolls(id)
    confirmModal.current.showModal()
  }

  return (
    <>
      <PayrollListView
        selectedCountry={selectedCountry}
        onCountryChange={onCountryChange}
        payrolls={payrolls}
        payrunState={payrunState}
        onPinPayrollClick={onPinPayrollClick}
        {...props}
      />

      <ConfirmationModal
        ref={confirmModal}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => {
          return dispatch(pinPayroll(selectedPayroll, false, false)).then(() => {
            dispatch(invalidatePayrollInstancesByStatus('active'))
            dispatch(invalidatePayrollInstancesByStatus('previousWithExtraTasks'))
            setSelectedPayrolls(null)
            props.filterResults(props.pagination.currentPage - 1)
          })
        }}
      >
        <p>
          <em>
            <strong>{t('Payroll.modal.unpin_warning')}</strong>
          </em>
        </p>
        <p>{t('Payroll.modal.unpin_confirm')}</p>
      </ConfirmationModal>
    </>
  )
}

PayrollContainer.propTypes = {
  payrolls: PropTypes.array,
  payrunState: PropTypes.string,
  currentCountry: PropTypes.object,
  onSubmit: PropTypes.func,
  pagination: PropTypes.object,
  filterResults: PropTypes.func,
}

const _createFilter = ({ page = 0, limit = DEFAULT_LIMIT, filters = {}, state, props }) => {
  const filterName = buildFilterName(props)
  limit = limit !== undefined ? limit : state.payrolls.filters[filterName].params.limit
  page = page !== undefined ? page : state.payrolls.filters[filterName].params.offset / limit
  const isRecurring = props.payrunState === payrunStatesEnum.INACTIVE || null

  const company = props.match.params.companyId || null
  return createFilter(
    {
      offset: page * limit,
      limit,
      sort,
      company,
      isRecurring,
      storeKey: props.payrollKey ?? null,
      country: state.payrolls.filters[filterName]?.params?.country ?? null,
    },
    filterName
  )
}

const buildFilter = (filterName, limit, company, isRecurring, page = null, data = null, props = {}) => {
  let filterParams = {
    country: data?.value,
    limit,
    sort,
    company,
    isRecurring,
    storeKey: props.payrollKey ?? null,
  }

  if (page) filterParams['offset'] = page * limit

  return createFilter(filterParams, filterName)
}

const mapDispatchToProps = (dispatch, props) => {
  const filterName = buildFilterName(props)
  const limit = props.filters[filterName]?.params.limit || DEFAULT_LIMIT
  const company = props.match.params.companyId || null
  const isRecurring = props.payrunState === payrunStatesEnum.INACTIVE || null

  return {
    onSubmit: (data) =>
      dispatch((dispatch, getState) => {
        const filter = buildFilter(filterName, limit, company, isRecurring, null, data, props)

        return dispatch(fetchPayrolls({ filter, forceVendorTenantAPI: props.isVendor }))
      }),
    filterResults: (page) =>
      dispatch((dispatch, getState) => {
        const state = getState()
        let country = state.payrolls.filters[filterName]?.params?.country ?? null
        const filter = buildFilter(filterName, limit, company, isRecurring, page, { value: country }, props)

        return dispatch(fetchPayrolls({ filter, forceVendorTenantAPI: props.isVendor }))
      }),
  }
}

const mapStateToProps = (state, props) => {
  const filterName = buildFilterName(props)
  let payrolls = []
  try {
    payrolls = getFilteredPayrolls(state, { filter: filterName })
  } catch (er) {}

  const {
    totalCount,
    params: { offset, limit, country },
  } = state.payrolls.filters[filterName]

  const allCompanies = getCompaniesRef(state, props).map((c) => c.company)
  const allCompaniesCountries = getCountriesByCompanies(state, { companiesIds: allCompanies })
    .map((c) => ({ value: c.id, label: c.name, abbreviature: c.abbreviature }))
    .sort(sortByLabel)
  const vendorSchema = props.match.params.vendorId ? getVendorById(state, { vendorId: props.match.params.vendorId }).schema : null

  return {
    payrolls,
    vendorSchema,
    countries: allCompaniesCountries,
    isCotUser: isCot(state),
    currentCountry: allCompaniesCountries.find((c) => c.value === country),
    pagination: {
      pageNeighbours: 2,
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit + 1 || 1,
      hidePagination: Math.ceil(totalCount / limit) <= 1,
      limit,
      totalCount,
    },
    initialValues: {
      country: null,
    },
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(PayrollContainer)

export default Fetcher(Container, [
  {
    name: 'companies',
    params: [
      {
        _computed: {
          forceVendorTenantAPI: (state, props) => props.isVendor,
        },
      },
    ],
  },
  {
    name: 'payrolls',
    params: [
      {
        _computed: {
          filter: (state, props) => _createFilter({ page: 0, limit: DEFAULT_LIMIT, state, props }),
          forceVendorTenantAPI: (state, props) => props.isVendor,
        },
      },
    ],
    isForceFetching: true,
  },
  {
    name: 'currencies',
    params: [
      {
        _computed: {
          forceVendorTenantAPI: (state, props) => props.isVendor,
        },
      },
    ],
  },
  {
    name: 'countries',
    params: [
      {
        _computed: {
          forceVendorTenantAPI: (state, props) => props.isVendor,
        },
      },
    ],
  },
])
