import React from 'react'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const InnerWrapper = ({ ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.PrivacyPolicy])
  return (
    <InnerWrapperTabs
      tabs={[
        { name: t('PrivacySecurity.text.privacy_policy'), to: '/privacysecurity/privacy' },
        { name: t('PrivacySecurity.text.cookie_policy'), to: '/privacysecurity/cookie' },
      ]}
      {...props}
    />
  )
}

export default InnerWrapper
