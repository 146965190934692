import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import Modal from 'components/Modal'
import PayrollInstanceTaskEditContainer from '../containers/PayrollInstanceTaskEditContainer'
import PayrollInstanceTaskOwnersEditContainer from 'routes/Payruns/containers/PayrollInstanceTaskOwnersEditContainer'
import PayrollInstanceFilesContainer from '../containers/PayrollInstanceFilesContainer'
import PayrollInstanceContainer from '../containers/PayrollInstanceContainer'
import PayrollInstanceJoinersContainer from '../containers/PayrollInstanceJoinersContainer'
import includes from 'lodash/includes'
import PayrollInstanceModal from '../containers/PayrollInstanceModalContainer'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import Options from './Options'
import { isCot } from 'redux/selectors/auth'
import FilterButton from 'components/buttons/FilterButton'
import { withTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

class PayrollInstanceView extends React.Component {
  constructor (props) {
    super(props)
    this.filesRef = React.createRef()
    this.actionsRef = React.createRef()
    this.optionsRef = React.createRef()
    this.scopesRef = React.createRef()
    this.joinersRef = React.createRef()
    this.state = {
      payrollInstanceId: null,
      payrollInstanceTaskId: null,
      reversibleTaskId: null,
      companyId: null,
      isMultipleOwnersReadOnly: null,
      openedModal: null,
      showFilters: props.showFilters,
      isLocked: null,
      allButtonItems: PayrollTabModalOptionButtons.allButtonItems,
    }

    this.onActionsSubmit = this.onActionsSubmit.bind(this)
    this.onScopesSubmit = this.onScopesSubmit.bind(this)
  }

  componentDidMount () {
    if (includes(this.props.location.search, 'openFilesModal')) {
      this.onFilesButtonClick(parseInt(this.props.query.get('id'), 10))
    }
    if (includes(this.props.location.search, 'openActionsModal')) {
      this.onActionButtonClick(parseInt(this.props.query.get('id'), 10))
    }
    if (includes(this.props.location.search, 'openJoinersModal')) {
      this.onJoinersButtonClick(parseInt(this.props.query.get('id'), 10))
    }
  }

  // Here the Modals are nested in Fetcher->WrappedComponent.
  // TODO - find a better way of managing the refs and modals show / hiding
  showNestedModal (ref) {
    this[ref].current.wrappedComponentRef?.current.showModal()
  }

  // Here the Modals are nested in Fetcher->WrappedComponent.
  // TODO - find a better way of managing the refs and modals show / hiding
  hideNestedModal (ref) {
    this[ref].current.wrappedComponentRef?.current.hideModal()
  }

  onScopesSubmit (areAllTasksCompleted, options) {
    if (options?.isLastBeforeExtraStep) {
      this.hideNestedModal('actionsRef')
      this.scopesRef.current.hideModal()
      this.setState({ payrollInstanceId: null })
    }
    if (!areAllTasksCompleted) return

    this.scopesRef.current.hideModal()
  }

  onActionsSubmit ({ areAllStepsCompleted, isPaymentTaskCompleted, areCurrentStepTasksCompleted, isLastStepBeforeExtraStepsCompleted }) {
    const hasAllStepsCompleted = areAllStepsCompleted || (areCurrentStepTasksCompleted && isPaymentTaskCompleted) || isLastStepBeforeExtraStepsCompleted

    if (hasAllStepsCompleted) {
      // It's important to call `this.hideNestedModal('actions')` before resetting `payrollInstanceId` state,
      // because the modal's refs will be unmounted and there would be an error invoking the method.
      // In short `payrollInstanceId` will unmount the modals, these we try to hide here firstly.
      this.hideNestedModal('actionsRef')
      // We reset the `payrollInstanceId` here, because on closing an instance modal, it means there shouldn't be any
      // active instance id set in the state
      // Otherwise (if we kept it in the state) - when we complete an instance (`areAllStepsCompleted`),
      // an unnecessary API request is triggered for the already completed instance.
      this.setState({ payrollInstanceId: null })
    }
  }

  onActionButtonClick (payrollInstanceId) {
    this.setState({ payrollInstanceId, openedModal: 'actions' }, () => this.showNestedModal('actionsRef'))
  }

  onOptionsButtonClick (payrollInstance) {
    this.setState(
      {
        payrollInstanceId: payrollInstance.id,
        openedModal: 'options',
        isLocked: payrollInstance.newLockUnlock.isPayrollInstanceLocked,
      },
      () => this.showNestedModal('optionsRef')
    )
  }

  onFilesButtonClick (payrollInstanceId) {
    this.setState({ payrollInstanceId, openedModal: 'files' }, () => this.showNestedModal('filesRef'))
  }

  onJoinersButtonClick (payrollInstanceId) {
    this.setState({ payrollInstanceId, openedModal: 'joiners' }, () => this.showNestedModal('joinersRef'))
  }

  onPayrollInstanceTaskClick (payrollInstanceTaskId, isMultipleOwnersReadOnly) {
    this.setState({ payrollInstanceTaskId, isMultipleOwnersReadOnly }, () => this.scopesRef.current.showModal())
  }

  render () {
    const { payrollInstanceId, payrollInstanceTaskId, isMultipleOwnersReadOnly, openedModal } = this.state
    const { heading, isCot, location, buttonOptions, payrunState } = this.props

    const isAccordionLayout = payrunState === payrunStatesEnum.PREVIOUS || payrunState === payrunStatesEnum.INACTIVE
    const shouldShowPayAndTaxesButton = payrunState === payrunStatesEnum.ACTIVE || !this.state.isLocked
    const t = this.props.t

    return (
      <>
        {!isAccordionLayout && (
          <SectionHeading text={heading}>
            <FilterButton
              className='c-btn c-btn--curious float-right u-margin-bottom-small c-btn--small u-padding-left u-padding-right'
              onClick={() => this.setState({ showFilters: !this.state.showFilters })}
            />
          </SectionHeading>
        )}
        {payrollInstanceId && openedModal === 'actions' && (
          <PayrollInstanceModal
            ref={this.actionsRef}
            className='c-modal c-modal--half c-modal--overflow-y'
            payrollInstanceId={payrollInstanceId}
            renderHeading={(instance) => `${instance.companyName}, ${instance.countryName}, ${instance.payrollNameAndPeriod}`}
            renderSubHeading={(instance) => instance.currentStepName}
            data-testid='actions-modal'
          >
            <PayrollInstanceTaskEditContainer
              payrollInstanceId={payrollInstanceId}
              payrunState={payrunState}
              openFilesModal={() => this.onFilesButtonClick(payrollInstanceId)}
              onMultipleTaskClick={(payrollInstanceTaskId, isReadOnly) => this.onPayrollInstanceTaskClick(payrollInstanceTaskId, isReadOnly)}
              onSubmit={this.onActionsSubmit}
            />
          </PayrollInstanceModal>
        )}

        {payrollInstanceId && openedModal === 'options' && (
          <PayrollInstanceModal
            ref={this.optionsRef}
            className='c-modal c-modal--small'
            payrollInstanceId={payrollInstanceId}
            renderHeading={(instance) => `${instance.companyName}, ${instance.countryName}, ${instance.payrollNameAndPeriod}`}
          >
            {payrollInstanceId && (
              <Options
                availableOptions={buttonOptions || this.state.allButtonItems}
                payrollInstanceId={payrollInstanceId}
                isCot={isCot}
                locationPath={location.pathname}
                shouldShowPayAndTaxesButton={shouldShowPayAndTaxesButton}
              />
            )}
          </PayrollInstanceModal>
        )}

        {payrollInstanceId && openedModal === 'files' && (
          <PayrollInstanceModal
            ref={this.filesRef}
            className='c-modal c-modal--full c-modal--min-height--full c-modal--overflow-y c-modal-with-top-background'
            payrollInstanceId={payrollInstanceId}
            renderHeading={(instance) => `${instance.companyName}, ${instance.countryName}, ${instance.payrollNameAndPeriod}`}
          >
            {payrollInstanceId && <PayrollInstanceFilesContainer payrollInstanceId={payrollInstanceId} {...this.props} />}
          </PayrollInstanceModal>
        )}

        {payrollInstanceId && openedModal === 'joiners' && (
          <PayrollInstanceModal
            ref={this.joinersRef}
            className='c-modal c-modal-with-top-background'
            payrollInstanceId={payrollInstanceId}
            renderHeading={(instance) => `${instance.companyName}, ${instance.countryName}, ${instance.payrollNameAndPeriod}`}
          >
            {payrollInstanceId && <PayrollInstanceJoinersContainer payrollInstanceId={payrollInstanceId} />}
            <div className='u-text--center u-margin-top-large'>
              <button
                onClick={() => this.hideNestedModal('joinersRef')}
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
                data-testid={`modal-ok`}
              >
                {t('Global:Global.text.upper_ok')}
              </button>
            </div>
          </PayrollInstanceModal>
        )}

        <Modal
          ref={this.scopesRef}
          className='c-modal c-modal--half'
          modalHeading={isMultipleOwnersReadOnly ? t('Payrun.text.multiple_owners') : t('Payrun.text.update_multiple_owners_status')}
        >
          {payrollInstanceTaskId && (
            <PayrollInstanceTaskOwnersEditContainer
              payrollInstanceId={payrollInstanceId}
              payrollInstanceTaskId={payrollInstanceTaskId}
              isReadOnly={isMultipleOwnersReadOnly}
              onSubmit={this.onScopesSubmit}
            />
          )}
        </Modal>

        <PayrollInstanceContainer
          onOptionsButtonClick={(payrollInstanceId) => this.onOptionsButtonClick.bind(this, payrollInstanceId)}
          onActionButtonClick={(payrollInstanceId) => this.onActionButtonClick.bind(this, payrollInstanceId)}
          onFilesButtonClick={(payrollInstanceId) => this.onFilesButtonClick.bind(this, payrollInstanceId)}
          onJoinersButtonClick={(payrollInstanceId) => this.onJoinersButtonClick.bind(this, payrollInstanceId)}
          showFilters={this.state.showFilters}
          {...this.props}
        />
      </>
    )
  }
}

PayrollInstanceView.propTypes = {
  heading: PropTypes.string,
  isCot: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  buttonOptions: PropTypes.object,
  query: PropTypes.object,
  payrunState: PropTypes.string,
  showFilters: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => ({
  isCot: isCot(state),
})

export default withTranslation(i18nNameSpaces.Payrun)(connect(mapStateToProps, null, null, { forwardRef: true })(PayrollInstanceView))
