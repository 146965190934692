import React from 'react'
import ConfirmationForm from 'components/form/ConfirmationForm'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { detachTerms, mergeTerms } from 'redux/actions/employeeSystemUsers'
import { getEmployeeWithCurrencyAndTerms } from '../../../selectors/employeesORM'
import _ from 'lodash'
import Fetcher from 'containers/Fetcher'
import { confirmMessage, message } from 'components/payruns/Messages'
import { invalidateEmployeeTermPivot } from 'redux/actions/employeeTermPivot'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import VariableEditPayElementsContainer from 'components/VariableEditPayElementsContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const TermsFormEditContainer = ({ companyId, arePayelementsDisabled, paygroupEmployeeName, hasEmployeeLockedPayrollInstances, ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])

  return (
    <ConfirmationForm message={confirmMessage} {...props} showConfirmation={hasEmployeeLockedPayrollInstances}>
      {hasEmployeeLockedPayrollInstances && message}
      <VariableEditPayElementsContainer
        companyId={companyId}
        disabled={arePayelementsDisabled}
        disabledReason={t('Employee.context.disabled_reason', { paygroupEmployeeName })}
      />
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </ConfirmationForm>
  )
}

TermsFormEditContainer.propTypes = {
  companyId: PropTypes.number,
  arePayelementsDisabled: PropTypes.bool,
  paygroupEmployeeName: PropTypes.string,
  hasEmployeeLockedPayrollInstances: PropTypes.bool,
  onSubmit: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      employeeId: PropTypes.string,
    }),
  }),
}

export const getChangedFields = (state, formName, submittedData) => {
  const initialValues = state.form[formName].initial
  return Object.keys(submittedData)
    .filter((field) => !_.isEqual(initialValues[field], submittedData[field]))
    .reduce((accumulator, field) => {
      accumulator[field] = submittedData[field]
      return accumulator
    }, {})
}

const mapDispatchToProps = (
  dispatch,
  {
    onSubmit,
    match: {
      params: { employeeId },
    },
  }
) => {
  return {
    onSubmit: (data) =>
      dispatch((dispatch, getState) => {
        const changedFields = getChangedFields(getState(), 'employeeTermsEdit', data)
        const deletedVariableFields = Object.keys(changedFields)
          .filter((field) => _.startsWith(field, 'variable-term-') && !changedFields[field])
          .reduce((accumulator, field) => {
            accumulator[field] = data[field]
            return accumulator
          }, {})

        return Promise.all([
          dispatch(
            mergeTerms(employeeId, changedFields, {
              shouldFetch: false,
              shouldInvalidate: true,
            })
          ),
          ...(!_.isEmpty(deletedVariableFields)
            ? [
              dispatch(
                detachTerms(employeeId, deletedVariableFields, {
                  shouldFetch: false,
                  shouldInvalidate: true,
                })
              ),
            ]
            : []),
        ]).then(() => {
          dispatch(invalidateEmployeeTermPivot())
          onSubmit()
        })
      }),
    dispatch,
  }
}

const mapStateToProps = (state, props) => {
  const employee = getEmployeeWithCurrencyAndTerms(state, {
    employeeId: props.match.params.employeeId,
  })
  let initEmployeeTerms = {}
  employee.employeeCompanyCountryTerms.map((pivot) => {
    if (pivot.cct.type === 'fixed') {
      initEmployeeTerms['fixed-term-' + pivot.cct.id] = pivot.amount
    } else if (pivot.cct.type === 'variable') {
      initEmployeeTerms['variable-term-' + pivot.cct.id] = 'checked'
    }
  })
  return {
    companyId: employee.company,
    hasEmployeeLockedPayrollInstances: employee.hasLockedPayrollInstances,
    initialValues: {
      id: employee.id,
      ...initEmployeeTerms,
    },
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'employeeTermsEdit',
  })(TermsFormEditContainer)
)

export default Fetcher(Container, [
  'companies',
  'countries',
  'payrollPaygroupPivot',
  'currencies',
  'companyCountryTermPivot',
  createEmployeeFilter({ name: 'employeeTermPivot' }),
  createEmployeeFilter({ name: 'payrollEmployeePivot' }),
  createEmployeeFilter({ name: 'paygroupEmployeePivot' }),
  createEmployeeFilter({ name: 'annualSalaries' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' }),
])
