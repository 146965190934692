import React from 'react'

import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { canLoggedInUserEditDocumentByType } from 'containers/documents/utils/documents'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

export const generateDescriptionDocLink = (doc, { onDownload }) => {
  const [, label, file] = doc.description?.match(/(.+)\s'([^']+)'$/) || []

  if (!label || !file) {
    return null
  }

  return (
    <>
      <span>{label} </span>

      <span
        className='u-text--curious u-cursor--pointer u-word-break-all'
        onClick={() => {
          onDownload(doc.gtnFileId)
        }}
      >
        {file}
      </span>
    </>
  )
}

export const prepareGTNDocument = (doc) => ({
  id: doc.id,
  documentId: doc.id,
  documentName: doc.name,
  documentTenant: doc.documentTenant,
  documentVersion: doc.version,
})

export const getGTNImportTemplate = (
  doc,
  {
    isPayrollInstanceLocked,
    openReimportGTNModal,
    hasRightsToLockUnlock,
    openReimportGTNWhenPayrunIsUnlockedModal,
    openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal,
  }
) => (
  <button
    id={`gtn-reimport-${doc.id}`}
    onClick={() => {
      isPayrollInstanceLocked
        ? openReimportGTNModal(prepareGTNDocument(doc))
        : hasRightsToLockUnlock && payrunStatesEnum.ACTIVE
          ? openReimportGTNWhenPayrunIsUnlockedModal(prepareGTNDocument(doc))
          : openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal()
    }}
    className='c-btn c-btn--small c-btn--curious u-relative u-padding-left-small  u-padding-right-small u-padding-top-none u-padding-bottom-none'
  >
    <span className='icon icon--reimport-arrows' />
  </button>
)

export const DocumentActionsLayout = ({ children }) => <div className='d-flex jc--flex-end'>{children}</div>

export const DocumentsShareAction = ({ doc, handleClick }) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  return (
    <button
      onClick={() => handleClick(doc)}
      className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
      title={t('Global:Global.text.share')}
      data-testid={`share-button-${doc.id}`}
    >
      <span className='icon icon--share' />
    </button>
  )
}

export const DocumentsHistoryAction = ({ doc, handleHistoryClick }) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  return (
    <button
      onClick={() => handleHistoryClick(doc)}
      className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
      title={t('Global:Global.text.history')}
      data-testid={`history-button-${doc.id}`}
    >
      <span className='icon icon--history' />
    </button>
  )
}

export const DocumentsEditAction = ({ doc, handleEditClick }) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  return (
    <button
      onClick={() => handleEditClick(doc)}
      className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
      title={t('Global:Global.text.edit')}
    >
      <span className='icon icon--edit' />
    </button>
  )
}

export const DocumentsDeleteAction = ({ doc, handleTrashAction }) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  return (
    <button onClick={() => handleTrashAction(doc)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title={t('Global:Global.text.delete')}>
      <span className='icon icon--trash' />
    </button>
  )
}

export const PayrollDocOwnerAction = ({ doc, canLoggedInUserEditDocumentByType, handleHistoryClick, handleEditClick }) => (
  <>
    <DocumentsHistoryAction doc={doc} handleHistoryClick={handleHistoryClick} />
    {canLoggedInUserEditDocumentByType(doc, doc.extraData.isOwner) && <DocumentsEditAction doc={doc} handleEditClick={handleEditClick} />}
  </>
)

export const PayrollDocumentActionsRow = ({
  doc,
  isCot,
  userSpecialRightAllPayrunDocuments,
  canCreate,
  shouldShowShareButton,
  handleShareButtonClick,
  handleHistoryClick,
  handleEditClick,
  handleTrashAction,
}) => {
  return (
    <DocumentActionsLayout>
      {canCreate && shouldShowShareButton && <DocumentsShareAction doc={doc} handleClick={() => handleShareButtonClick(doc)} />}
      {(doc.extraData.isOwner || isCot || userSpecialRightAllPayrunDocuments) && (
        <PayrollDocOwnerAction
          doc={doc}
          canLoggedInUserEditDocumentByType={canLoggedInUserEditDocumentByType}
          handleHistoryClick={() => handleHistoryClick(doc)}
          handleEditClick={() => handleEditClick(doc)}
        />
      )}
      {doc?.extraData?.isDeletable && <DocumentsDeleteAction doc={doc} handleTrashAction={() => handleTrashAction(doc)} />}
    </DocumentActionsLayout>
  )
}

export const DocumentHeaderActions = ({ canCreate, handleClick }) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  return (
    <>
      {canCreate && (
        <button
          title={t('Global:Global.text.upload_file')}
          onClick={handleClick}
          className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-relative'
          data-testid='upload-button'
        >
          <span className='icon icon--upload' />
        </button>
      )}
    </>
  )
}

export const DocumentActionsRow = ({ doc, canCreate, hasHistoryAction, handleShareButtonClick, handleHistoryClick, handleEditClick, handleTrashAction }) => (
  <DocumentActionsLayout>
    {canCreate && <DocumentsShareAction doc={doc} handleClick={() => handleShareButtonClick(doc)} />}
    {hasHistoryAction && (
      <>
        <DocumentsHistoryAction doc={doc} handleHistoryClick={handleHistoryClick} />
        <DocumentsEditAction doc={doc} handleEditClick={handleEditClick} />
        {doc?.extraData?.isDeletable && <DocumentsDeleteAction doc={doc} handleTrashAction={() => handleTrashAction(doc)} />}
      </>
    )}
  </DocumentActionsLayout>
)

export const DocumentsDeleteModalText = ({ fileName }) => (
  <p>
    <Trans i18nKey='Global.modal.delete_confirmation' ns={i18nNameSpaces.Global} />
    <b>{fileName}</b>?
  </p>
)

DocumentsDeleteModalText.propTypes = {
  fileName: PropTypes.string.isRequired,
}

DocumentActionsRow.propTypes = {
  doc: PropTypes.object.isRequired,
  canCreate: PropTypes.bool,
  hasHistoryAction: PropTypes.bool,
  handleShareButtonClick: PropTypes.func.isRequired,
  handleHistoryClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleTrashAction: PropTypes.func.isRequired,
}

DocumentHeaderActions.propTypes = {
  canCreate: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
}

PayrollDocumentActionsRow.propTypes = {
  doc: PropTypes.object.isRequired,
  isCot: PropTypes.bool,
  userSpecialRightAllPayrunDocuments: PropTypes.bool,
  canCreate: PropTypes.bool,
  shouldShowShareButton: PropTypes.bool,
  handleShareButtonClick: PropTypes.func.isRequired,
  handleHistoryClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleTrashAction: PropTypes.func.isRequired,
}

PayrollDocOwnerAction.propTypes = {
  doc: PropTypes.object.isRequired,
  canLoggedInUserEditDocumentByType: PropTypes.func.isRequired,
  handleHistoryClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
}

DocumentsDeleteAction.propTypes = {
  doc: PropTypes.object.isRequired,
  handleTrashAction: PropTypes.func.isRequired,
}

DocumentsEditAction.propTypes = {
  doc: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
}

DocumentsHistoryAction.propTypes = {
  doc: PropTypes.object.isRequired,
  handleHistoryClick: PropTypes.func.isRequired,
}

DocumentsShareAction.propTypes = {
  doc: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
}

DocumentActionsLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

getGTNImportTemplate.propTypes = {
  doc: PropTypes.object.isRequired,
  isPayrollInstanceLocked: PropTypes.bool.isRequired,
  openReimportGTNModal: PropTypes.func.isRequired,
  hasRightsToLockUnlock: PropTypes.bool.isRequired,
  openReimportGTNWhenPayrunIsUnlockedModal: PropTypes.func.isRequired,
  openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal: PropTypes.func.isRequired,
}
