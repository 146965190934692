import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import CountryRoute from './routes/Country'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/countries',
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Country, RouteView) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [CountryRoute(store)],
})
