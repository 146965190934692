import React, { useCallback, useRef } from 'react'

import { ExportFormatType, generatePdfBySections, getDomSections } from './util'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export const ExportActionButton = ({ iconClass = 'icon--download', ...props }) => {
  const { t } = useTranslation()
  return (
    <button className='c-btn c-btn--small c-btn--curious u-margin-right-small' title={t('Global.text.download')} {...props}>
      <span className={`'icon ${iconClass}`} />
    </button>
  )
}

ExportActionButton.propTypes = {
  iconClass: PropTypes.string,
}

export const exportImgAsPdf = async ({ sectionsToPrint, getFileName, containerElement, pdfOptions = {}, imgOptions = {} }) => {
  const sections = getDomSections({
    sectionsToPrint,
    containerElement,
  })
  await generatePdfBySections({
    sections,
    getFileName,
    containerElement,
    pdfOptions,
    imgOptions: {
      format: ExportFormatType.Canvas,
      quality: 0.9,
      ...imgOptions,
    },
  })
}

export const Export = ({ sectionsToPrint, getFileName, children, format = ExportFormatType.Pdf, Actions }) => {
  const containerRef = useRef()

  const handleExport = useCallback(() => {
    if (format === ExportFormatType.Pdf) {
      return exportImgAsPdf({
        getFileName,
        containerElement: containerRef.current,
        sectionsToPrint,
      })
    }
  }, [format])

  return (
    <div>
      <Actions onClick={handleExport} />
      <div ref={containerRef}>{children}</div>
    </div>
  )
}

Export.proptypes = {
  children: PropTypes.node,
}
