import React from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import { download, downloadTemplate, importAccountsCreate } from 'redux/actions/generalLedgers'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.gla_clarifications.create_multiple_accounts')} />
      <p className='u-margin-bottom-small'>{t('Import.gla_clarifications.description')}</p>
      <ul className='u-margin-bottom-small'>
        <li>{t('Import.gla_clarifications.step1')}</li>
        <li>{t('Import.gla_clarifications.step2')}</li>
        <li>{t('Import.gla_clarifications.step3')}</li>
        <li>{t('Import.gla_clarifications.step4')}</li>
      </ul>
    </div>
  )
}

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <ImportFacade
      {...props}
      heading={t('Import.gla_heading.import_accounts')}
      entityName='generalLedgers'
      downloadAction={download}
      downloadTemplateAction={() => downloadTemplate()}
      importAction={({ file }) => importAccountsCreate(file)}
      TemplateClarifications={TemplateClarifications}
      hasFilters={false}
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading text={t('Import.gla_required_fields.heading')} />
          <p className='u-margin-bottom-tiny'>{t('Import.gla_required_fields.account_number', { lng: 'en' })}</p>
          <p className='u-margin-bottom-tiny'>{t('Import.gla_required_fields.account_type', { lng: 'en' })}</p>
        </div>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading fullWidth text={t('Import.gla_other_validations.heading')} />
          <p className='u-margin-bottom-tiny'>{t('Import.gla_other_validations.account_description', { lng: 'en' })}</p>
          <p className='u-margin-bottom-tiny'>{t('Import.gla_other_validations.status', { lng: 'en' })}</p>
        </div>
      </div>
    </ImportFacade>
  )
}
