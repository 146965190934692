import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { fetchDataProtectionFieldsIfNeeded } from 'redux/actions/dataProtectionFields'
import DataProtectionFieldsList from 'components/DataProtectionFieldsList'
import { getDataProtectionFieldsGroupedByCategory } from 'redux/selectors/dataProtectionFields'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const GlobalDataProtectionContainer = ({ dispatch, isFetching, groupedFields, onEdit }) => {
  const { t } = useTranslation([i18nNameSpaces.DataProtection])
  useEffect(() => {
    dispatch(fetchDataProtectionFieldsIfNeeded())
  }, [dispatch])

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <DataProtectionFieldsList
      title={t('DataProtection.text.global_data_protection')}
      onClick={(dataFieldId) => onEdit(dataFieldId)}
      groupedFields={groupedFields}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

const mapStateToProps = (state) => {
  const { dataProtectionFields } = state
  const entities = [dataProtectionFields]
  if (isFetching(entities)) return { isFetching: true }
  return {
    groupedFields: getDataProtectionFieldsGroupedByCategory(state),
  }
}

GlobalDataProtectionContainer.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  groupedFields: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDataProtectionContainer)
