import { getNewReportTypes } from 'redux/config/newReportTypes'

const ReportItemDetailBuilder = (type) => {
  const newTypes = getNewReportTypes()
  const getLockedInItems = () => newTypes[type]
  const lockedInItems = getLockedInItems()
  return {
    lockedInItems,
  }
}

export default ReportItemDetailBuilder
