import CoreLayout from 'layouts/PageLayout/PageLayout'
import CustomizeReportRoute from './routes/CustomizeReport'
import ReportDesignerRoute from './routes/ReportDesigner'
import RecentReportsRoute from './routes/RecentReports'
import SavedReportsRoute from './routes/SavedReports'
import ReportRoute from './routes/Report'
import InnerWrapper from './components/InnerWrapper'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/reporting',
  tabbedLayout: true,
  indexRoute: { component: withTranslationContext([i18nNameSpaces.Report, i18nNameSpaces.Employee], InnerWrapper) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [CustomizeReportRoute(store), ReportDesignerRoute(store), RecentReportsRoute(store), SavedReportsRoute(store), ReportRoute(store)],
})
