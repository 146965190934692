import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import { download, downloadTemplateUpdate, importPayTaxesUpdate } from 'redux/actions/companyCountryTermPivot'
import { useSelector } from 'react-redux'
import { getCompanyRef } from 'redux/selectors/company'
import Fetcher from 'containers/Fetcher'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = ({ isHQ = false }) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.template.heading')} />
      <p className='u-margin-bottom-small'>{t('Import.template.description')}</p>
      <ol className='u-margin-bottom-small'>
        <li>{t('Import.template.steps.download_template')}</li>
        <li data-testid='pay-taxes-header-details'>
          {t('Import.template.steps.not_editable', {
            editableFields: isHQ ? 'Country, Company, Global Name, Category + Subcategory' : 'Global Name, Category + Subcategory',
          })}
        </li>
        <li>{t('Import.template.steps.complete_data')}</li>
        <li>{t('Import.template.steps.import_template')}</li>
        <li>{t('Import.template.steps.wait_confirmation')}</li>
      </ol>
    </div>
  )
}
const Container = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  const company = useSelector((state) => getCompanyRef(state, { companyId: props.match.params.companyId }))
  return (
    <ImportFacade
      {...props}
      heading={t('Import.template.heading')}
      entityName='elements'
      downloadAction={download}
      downloadTemplateAction={() => downloadTemplateUpdate(props.match?.params?.companyId)}
      importAction={({ file }) => importPayTaxesUpdate(file, props.match?.params?.companyId)}
      TemplateClarifications={() => {
        return <TemplateClarifications isHQ={company.isHQ} />
      }}
      hasFilters={false}
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading text={t('Import.pt_elements.data_validations_heading')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.gl'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='gl' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.disbursable'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='disbursable' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.fixedVariable'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='fixedVariable' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.editableBy'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='editableBy' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.netValue'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='netValue' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.recurring'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='recurring' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.dataFormat'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='dataFormat' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.taxable'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='taxable' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.pt_elements.validations.taxOnly'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='taxOnly' className='u-weight--bold' />]}
            />
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}

Container.propTypes = {
  match: PropTypes.object,
}

export default Fetcher(Container, ['companies'])
