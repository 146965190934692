import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { getLoggedInUserId } from 'redux/selectors/auth'
import { fetchCotUsers } from 'redux/actions/cotUsers'
import { fetchVendorUsers, updateVendorUser } from 'redux/actions/vendorUsers'
import { fetchEmployeeSystemUsers, updateEmployeeSystemUser } from 'redux/actions/employeeSystemUsers'
import { tenantTypes } from 'utils/enums/tenantTypes'
import { createFilter } from 'utils/redux/filter'
import { getUserLocale } from 'redux/selectors/users'
import { isFetchingByCustomFilter } from 'utils/redux/fetching'

const filterName = 'userFilter'

export const updateAndRefetchEmployeeSystemUser = (payload, userId) => async (dispatch) => {
  try {
    // Dispatch the update action
    await dispatch(updateEmployeeSystemUser({ ...payload, id: userId }, { shouldFetch: false }))

    // Refetch the updated user data
    const filter = createFilter({ id: userId }, filterName)
    await dispatch(fetchEmployeeSystemUsers({ filter }))
  } catch (err) {
    console.error('Error updating or refetching employee user:', err)
  }
}

export const updateAndRefetchVendorUser = (payload, userId) => async (dispatch) => {
  try {
    // Dispatch the update action
    await dispatch(updateVendorUser(payload, userId, false))

    // Refetch the updated user data
    const filter = createFilter({ id: userId }, filterName)
    await dispatch(fetchVendorUsers({ filter }))
  } catch (err) {
    console.error('Error updating or refetching vendor user:', err)
  }
}

/**
 * A custom hook that fetches and manages user data based on the currently logged-in user's ID and tenant type.
 * It determines the necessary API calls and provides methods to handle user-specific data, including locale updates.
 *
 * @returns {Object} An object containing:
 * - `isFetching` (boolean): A flag indicating whether user data is currently being fetched.
 * - `locale` (string): The current locale of the user, defaulting to `DEFAULT_LOCALE` if not set.
 * - `onLocaleChange` (function): A function to handle locale changes for the user. It updates the user's locale based on the provided value.
 */
export const useUserData = () => {
  const dispatch = useDispatch()

  const userId = useSelector(getLoggedInUserId)
  const tenantType = useSelector((state) => state.config.tenantType)

  const isTenant = tenantType === tenantTypes.TENANT
  const isVendor = tenantType === tenantTypes.VENDOR
  const isCot = tenantType === tenantTypes.COS

  const filter = useMemo(() => createFilter({ id: userId }, filterName), [userId])

  useEffect(() => {
    if (!userId) return

    const fetchData = () => {
      if (isTenant) dispatch(fetchEmployeeSystemUsers({ filter }))
      if (isVendor) dispatch(fetchVendorUsers({ filter }))
      if (isCot) dispatch(fetchCotUsers({ filter }))
    }
    fetchData()
  }, [userId, tenantType, dispatch, filter])

  const userLocale = useSelector(getUserLocale)

  const onLocaleChange = (newLocale) => {
    const payload = { locale: newLocale.value }
    if (isTenant) dispatch(updateAndRefetchEmployeeSystemUser(payload, userId))
    if (isVendor) dispatch(updateAndRefetchVendorUser(payload, userId))
  }

  const isFetching = useSelector((state) => {
    if (isTenant) return isFetchingByCustomFilter(filterName, state.employeeSystemUsers)
    if (isVendor) return isFetchingByCustomFilter(filterName, state.vendorUsers)
    if (isCot) return isFetchingByCustomFilter(filterName, state.cotUsers)
  })

  return {
    isFetching,
    locale: userLocale,
    onLocaleChange,
    isCot,
  }
}
