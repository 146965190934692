import CoreLayout from 'layouts/PageLayout/PageLayout'
import ReportAnalyticsRoute from './routes/ReportAnalytics'
import InnerWrapper from 'layouts/InnerWrapper'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/reporting/analytics',
  skipChildren: true,
  exact: true,
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Report, InnerWrapper) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ReportAnalyticsRoute(store)],
})
