import React from 'react'
import PropTypes from 'prop-types'

import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { completionURIs } from 'redux/config/tasks'
import { creatableIdentifiers, tasksIdentifiers } from 'redux/config/tasksIdentifiers'
import { i18nNameSpaces } from 'i18n/types'

const identifiers = creatableIdentifiers.map((i) => ({
  value: tasksIdentifiers[i].code,
  label: tasksIdentifiers[i].name,
}))

const TaskCreate = (props) => {
  const { processes, steps, owners } = props
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div>
      <SectionHeading text={t('BusinessComponent.sectionHeading.create_action')} />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <Form showSubmitBtn btnText={t('Global:Global.button.submit')} {...props}>
            <div className='o-form-group'>
              <Field name='name' label={t('Global:Global.text.name')} component={InputField} type='text' className='c-input' labelClassName='c-label' />
            </div>
            <div className='o-form-group'>
              <Field
                name='identifier'
                label={t('BusinessComponent.field.identifier')}
                component={CustomSelectField}
                type='text'
                labelClassName='c-label'
                clearable={false}
                placeholder={t('Global:Global.select.placeholder')}
                options={identifiers}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='finishTaskLink'
                label={t('BusinessComponent.field.action_completion_link')}
                component={CustomSelectField}
                type='text'
                labelClassName='c-label'
                clearable={false}
                placeholder={t('BusinessComponent.placeholder.select')}
                options={completionURIs.map((uri) => ({
                  value: uri.value,
                  label: uri.uri,
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='owner'
                label={t('Global:Global.text.owner')}
                component={CustomSelectField}
                type='number'
                labelClassName='c-label'
                clearable={false}
                placeholder={t('BusinessComponent.placeholder.select_owner')}
                options={owners.map((owner) => ({
                  value: owner,
                  label: owner,
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='process'
                label={t('Global:Global.text.process')}
                component={CustomSelectField}
                labelClassName='c-label'
                clearable={false}
                placeholder={processes.length ? t('BusinessComponent.placeholder.select_process') : t('Global:Global.text.loading')}
                options={processes.map((process) => ({
                  value: process.id,
                  label: process.name,
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='step'
                label={t('Global:Global.button.step')}
                component={CustomSelectField}
                type='number'
                labelClassName='c-label'
                clearable={false}
                placeholder={t('BusinessComponent.placeholder.select_step')}
                options={steps.map((step) => ({
                  value: step.id,
                  label: step.name,
                }))}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

TaskCreate.propTypes = {
  processes: PropTypes.array,
  steps: PropTypes.array,
  owners: PropTypes.array,
}

export default reduxForm({
  form: 'task', // a unique identifier for this form
})(TaskCreate)
