import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { required } from 'utils/validations'
import FieldDatePicker from 'components/form/FieldDatePicker'
import AmountInputField from 'components/form/AmountInputField'
import { roundHalfValue } from 'utils/number'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const requiredSymbol = '*'

const LeaveFormFields = ({ initialValues }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const [startDate, setStartDate] = useState(initialValues.from)
  const [endDate, setEndDate] = useState(initialValues.to)
  const [lastDayOfWork, setLastDayOfWork] = useState(initialValues.lastDayOfWork)
  const [actualLastDayOfLeave, setActualLastDayOfLeave] = useState(initialValues.actualLastDayOfLeave)

  return (
    <div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label={t('Employee.leave_form.last_day_of_work')}
          labelClassName='c-label'
          className='c-input'
          name='lastDayOfWork'
          component={FieldDatePicker}
          selectsStart
          selected={lastDayOfWork}
          startDate={startDate}
          endDate={endDate}
          onChanged={(day) => setLastDayOfWork(day)}
          data-testid='lastDayOfWork-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label={t('Employee.leave_form.first_day_of_leave_required', {
            requiredSymbol,
          })}
          labelClassName='c-label'
          className='c-input'
          name='from'
          component={FieldDatePicker}
          selectsEnd
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChanged={(day) => setStartDate(day)}
          validate={required}
          minDate={lastDayOfWork}
          showDisabledMonthNavigation
          data-testid='firstDayOfLeave-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label={t('Employee.leave_form.estimated_last_day_of_leave_required', {
            requiredSymbol,
          })}
          labelClassName='c-label'
          className='c-input'
          name='to'
          component={FieldDatePicker}
          selectsEnd
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          onChanged={(day) => setEndDate(day)}
          validate={required}
          minDate={startDate}
          showDisabledMonthNavigation
          data-testid='estimatedLastDayOfLeave-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label={t('Employee.leave_form.actual_last_day_of_leave')}
          labelClassName='c-label'
          className='c-input'
          name='actualLastDayOfLeave'
          component={FieldDatePicker}
          selectsEnd
          selected={actualLastDayOfLeave}
          startDate={startDate}
          endDate={endDate}
          onChanged={(day) => setActualLastDayOfLeave(day)}
          minDate={startDate}
          showDisabledMonthNavigation
          data-testid='actualLastDayOfLeave-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          name='totalDays'
          label={t('Employee.leave_form.total_days')}
          labelClassName='c-label'
          className='c-input'
          formGroupClassName='u-margin-bottom-tiny'
          component={AmountInputField}
          format={(val) => {
            const modValue = Math.abs(val % 1)
            const hasCorrectFormat = modValue === 0 || modValue === 0.5
            if (hasCorrectFormat) return val
            return roundHalfValue(val)
          }}
          data-testid='total-days-wrapper'
        />
        <span className='u-text--small'>
          <em>{t('Employee.leave_form.total_days_hint')}</em>
        </span>
      </div>
    </div>
  )
}

LeaveFormFields.propTypes = {
  initialValues: PropTypes.object,
}

export default LeaveFormFields
