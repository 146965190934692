import { createSelector } from 'reselect'
import _ from 'lodash'
import {
  ROLE_ACCOUNTING,
  ROLE_ACCOUNTING_READ_ONLY,
  ROLE_CONTROLLING,
  ROLE_CONTROLLING_READ_ONLY,
  ROLE_COT,
  ROLE_COT_ADMIN,
  ROLE_COT_BOT,
  ROLE_COT_ENGINEER,
  ROLE_COT_OEM,
  ROLE_EMPLOYEE,
  ROLE_FINANCE_ADMIN,
  ROLE_FINANCE_ADMIN_READ_ONLY,
  ROLE_PAYROLL_ADMIN,
  ROLE_PAYROLL_ADMIN_READ_ONLY,
  ROLE_PAYROLL_ANALYST,
  ROLE_PAYROLL_ANALYST_READ_ONLY,
  ROLE_PAYROLL_DATA_ENTRY,
  ROLE_PAYROLL_DATA_ENTRY_READ_ONLY,
  ROLE_VENDOR,
  ROLE_VENDOR_ADMIN,
} from 'redux/config/auth'
import { getKURoleTypes } from 'redux/config/keyPeopleRoleTypes'

const getRoles = (state) => state.auth.roles
const getPermissions = (state) => state.auth.permissions
export const getLoggedInUserId = (state) => state.auth.userId

/**
 * Cot users can have four roles currently - ROLE_COT_ADMIN, ROLE_COT, ROLE_COT_BOT and ROLE_COT_ENGINEER, ROLE_COT_OEM.
 */
export const isCot = createSelector(
  getRoles,
  (roles) =>
    roles.includes(ROLE_COT_ADMIN) ||
    roles.includes(ROLE_COT) ||
    roles.includes(ROLE_COT_BOT) ||
    roles.includes(ROLE_COT_ENGINEER) ||
    roles.includes(ROLE_COT_OEM)
)

export const isCotButNotCotOem = createSelector(
  getRoles,
  (roles) => roles.includes(ROLE_COT_ADMIN) || roles.includes(ROLE_COT) || roles.includes(ROLE_COT_BOT) || roles.includes(ROLE_COT_ENGINEER)
)

export const isCotAdmin = createSelector(getRoles, (roles) => roles.includes(ROLE_COT_ADMIN))

export const isCotBot = createSelector(getRoles, (roles) => roles.includes(ROLE_COT_BOT))

export const isCotOrBotOrEngineer = createSelector(
  getRoles,
  (roles) => roles.includes(ROLE_COT) || roles.includes(ROLE_COT_OEM) || roles.includes(ROLE_COT_BOT) || roles.includes(ROLE_COT_ENGINEER)
)

export const isCotOrEngineerOrAdmin = createSelector(
  getRoles,
  (roles) => roles.includes(ROLE_COT) || roles.includes(ROLE_COT_OEM) || roles.includes(ROLE_COT_ENGINEER) || roles.includes(ROLE_COT_ADMIN)
)

export const isCotOem = createSelector(getRoles, (roles) => roles.includes(ROLE_COT_OEM))
/*
 * Since we have key people, who also can have role employee
 * this check means that the logged in user is
 * key person employee OR only employee
 * This check looks at the isEmployee flag
 */
export const isUser = createSelector(getRoles, (roles) => roles.includes(ROLE_EMPLOYEE))

export const isVendor = createSelector(getRoles, (roles) => roles.includes(ROLE_VENDOR))

export const isVendorAdmin = createSelector(getRoles, (roles) => roles.includes(ROLE_VENDOR_ADMIN))

export const isVendorInvolved = createSelector(getRoles, (roles) => roles.includes(ROLE_VENDOR_ADMIN) || roles.includes(ROLE_VENDOR))

export const isPayrollAdmin = createSelector(getRoles, (roles) => roles.includes(ROLE_PAYROLL_ADMIN) || roles.includes(ROLE_PAYROLL_ADMIN_READ_ONLY))

export const isPayrollAnalyst = createSelector(getRoles, (roles) => roles.includes(ROLE_PAYROLL_ANALYST) || roles.includes(ROLE_PAYROLL_ANALYST_READ_ONLY))

export const isPayrollDataEntry = createSelector(
  getRoles,
  (roles) => roles.includes(ROLE_PAYROLL_DATA_ENTRY) || roles.includes(ROLE_PAYROLL_DATA_ENTRY_READ_ONLY)
)

export const isFinanceAdmin = createSelector(getRoles, (roles) => roles.includes(ROLE_FINANCE_ADMIN) || roles.includes(ROLE_FINANCE_ADMIN_READ_ONLY))

export const isAccounting = createSelector(getRoles, (roles) => roles.includes(ROLE_ACCOUNTING) || roles.includes(ROLE_ACCOUNTING_READ_ONLY))

export const isControlling = createSelector(getRoles, (roles) => roles.includes(ROLE_CONTROLLING) || roles.includes(ROLE_CONTROLLING_READ_ONLY))

/*
 * Check to see if the logged in person is a KeyUser,
 * this check is different then the isUser one because it checks for roles,
 * and not the isEmployee flag
 */

export const isKeyUser = createSelector(getRoles, (roles) =>
  getKURoleTypes().find((keyPeopleRole) => roles.includes(keyPeopleRole.type) || roles.includes(keyPeopleRole.readOnlyType))
)

/*
 * Since we have key people, who also can have role employee
 * this check means that the logged in user is
 * ONLY employee
 */
export const isEmployeeOnly = createSelector(
  getRoles,
  (roles) =>
    roles.includes(ROLE_EMPLOYEE) &&
    !roles.includes(ROLE_PAYROLL_ADMIN) &&
    !roles.includes(ROLE_PAYROLL_ADMIN_READ_ONLY) &&
    !roles.includes(ROLE_PAYROLL_ANALYST) &&
    !roles.includes(ROLE_PAYROLL_ANALYST_READ_ONLY) &&
    !roles.includes(ROLE_PAYROLL_DATA_ENTRY) &&
    !roles.includes(ROLE_PAYROLL_DATA_ENTRY_READ_ONLY) &&
    !roles.includes(ROLE_FINANCE_ADMIN) &&
    !roles.includes(ROLE_FINANCE_ADMIN_READ_ONLY) &&
    !roles.includes(ROLE_ACCOUNTING) &&
    !roles.includes(ROLE_ACCOUNTING_READ_ONLY) &&
    !roles.includes(ROLE_CONTROLLING) &&
    !roles.includes(ROLE_CONTROLLING_READ_ONLY)
)

/**
 * Once we add read only key people we need to check if specific user
 * is with one of the read only roles, since they have only view permissions.
 */
export const isReadOnly = createSelector(
  getRoles,
  (roles) =>
    roles.includes(ROLE_PAYROLL_ADMIN_READ_ONLY) ||
    roles.includes(ROLE_PAYROLL_ANALYST_READ_ONLY) ||
    roles.includes(ROLE_PAYROLL_DATA_ENTRY_READ_ONLY) ||
    roles.includes(ROLE_FINANCE_ADMIN_READ_ONLY) ||
    roles.includes(ROLE_ACCOUNTING_READ_ONLY) ||
    roles.includes(ROLE_CONTROLLING_READ_ONLY)
)

/**
 * @link https://gitlab.com/dev-labs-bg/payslip-frontend/wikis/Authorization
 */
export const hasAccess = createSelector(getPermissions, (userPermissions) => (requiredPermissions) => {
  return _.intersection(requiredPermissions, userPermissions).length > 0
})
