import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import Select from 'react-select/lib/Select'
import VarianceDisplayCard from './VarianceDisplayCard'
import PayslipTable from 'components/table/PayslipTable'
import SwitchToggle from 'components/form/SwitchToggle'
import FilterButton from 'components/buttons/FilterButton'
import CommentaryControlBtns from './CommentaryControlBtns'
import { getMainFilters } from '../containers/payrollReconciliationTableConfig'
import { LeaveConfirmationModal, LeaveRouteConfirmationModal } from 'components/ReusableModals/LeaveConfirmationModal'
import Loader from 'components/Loader'
import { MagnifierSearchIcon } from 'components/icons/magnifierSearchIcon'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PayrollReconciliationView = ({
  mainOptions,
  title,
  tilesData,
  tableData,
  tableHeadings,
  noData,
  versionOptions,
  defaultOptionLeft,
  defaultOptionRight,
  onVersionSubmit,
  onToggleVariance,
  toggleValue,
  onToggleExcludeZeroes,
  excludeZeroesToggleValue,
  payrollInstanceId,
  downloadReport,
  inProgressPollingJobs,
  isLoadingJob,
  pagination,
  rehidrateData,
  onFilterChange,
  onUpdateGlobalData,
  onSave,
  hasChanges,
  isLoading,
  resetChangedData,
}) => {
  const [selectedOptionLeft, setSelectedOptionLeft] = useState(defaultOptionLeft)
  const [selectedOptionRight, setSelectedOptionRight] = useState(defaultOptionRight)
  const [isPollingHappening, setIsPollingHappening] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const [state, setState] = useState({
    isEdit: false,
    hasAllRowsExpanded: false,
  })

  const { t } = useTranslation([i18nNameSpaces.Payrun])

  const navigationLockedModalRef = useRef()

  const { isEdit, hasAllRowsExpanded } = state

  const hasInProgressJob = !isUndefined(inProgressPollingJobs.find((job) => job.entityId === payrollInstanceId))
  const toastDownloadButton = document.getElementById(`entity-${payrollInstanceId}`)
  const toastCloseDownloadButton = document.getElementById(`entity-${payrollInstanceId}-close`)
  const onToastDownload = () => {
    setIsPollingHappening(false)
  }

  useEffect(() => {
    toastDownloadButton?.addEventListener('click', onToastDownload)
    return () => {
      toastDownloadButton?.removeEventListener('click', onToastDownload)
    }
  }, [toastDownloadButton])

  useEffect(() => {
    toastCloseDownloadButton?.addEventListener('click', onToastDownload)
    return () => {
      toastCloseDownloadButton?.removeEventListener('click', onToastDownload)
    }
  }, [toastCloseDownloadButton])

  useEffect(() => {
    setIsPollingHappening(hasInProgressJob)
  }, [hasInProgressJob])

  const startDownloadReport = ({ onlyVariance = false, excludeZeroes = false }) => {
    if (isPollingHappening) return
    setIsPollingHappening(true)
    downloadReport({ versionOne: selectedOptionLeft.value, versionTwo: selectedOptionRight.value, onlyVariance, excludeZeroes, entityId: payrollInstanceId })
  }

  const onViewComments = () => {
    setState({
      ...state,
      isEdit: false,
      hasAllRowsExpanded: !hasAllRowsExpanded,
    })
  }

  const onEditPress = () => {
    setState({ ...state, isEdit: true })
  }

  const handleIfNecessaryLeaveModal = (cb) => {
    if (hasChanges) {
      navigationLockedModalRef?.current?.showModal()
      return
    }
    cb()
  }

  const closeEditAndComments = () => {
    resetChangedData()
    setState({
      ...state,
      isEdit: false,
      hasAllRowsExpanded: false,
    })
  }

  const onSaveCommentary = () => {
    onSave(selectedOptionLeft, selectedOptionRight, pagination.currentPage)
    setState({ ...state, hasAllRowsExpanded: false, isEdit: false })
  }

  const onCancelCommentaryEdit = () => {
    resetChangedData()
    setState({ ...state, isEdit: false })
  }

  const onDownloadPress = () => startDownloadReport({ onlyVariance: toggleValue, excludeZeroes: excludeZeroesToggleValue })

  const options = {
    noDataMessage: t('Global:Global.text.no_matching_records_found'),
    pageSize: pagination && pagination.limit,
    pageIndex: pagination && pagination.currentPage,
    pageCount: pagination && pagination.totalPages,
    onPageChange: ({ pageIndex, filters }) => {
      const isChangedFilter = pageIndex === pagination?.currentPage

      if (isChangedFilter && !filters.length) return

      let normalizedFilters = {}
      let mainOptionFilter
      let mainFiltersId

      filters.forEach((filter) => {
        mainOptionFilter = mainOptions[filter.id]
        mainFiltersId = getMainFilters(mainOptionFilter, filter.value)
        normalizedFilters[filter.id] = !isEmpty(mainFiltersId) ? filter.value?.concat(mainFiltersId) : filter.value
      })

      if (isChangedFilter) {
        onFilterChange({
          page: pageIndex,
          filters: normalizedFilters,
          selectedOptionLeft,
          selectedOptionRight,
        })
      } else {
        rehidrateData({
          page: pageIndex,
          filters: normalizedFilters,
          selectedOptionLeft,
          selectedOptionRight,
        })
      }
    },
  }
  if (isLoading) return <Loader />
  return (
    <>
      {isEdit && (
        <>
          <LeaveConfirmationModal modalRef={navigationLockedModalRef} onConfirmation={closeEditAndComments} />
          <LeaveRouteConfirmationModal isDirty={hasChanges} />
        </>
      )}
      <SectionHeading text={title} />
      <div className='page--payroll-version-filters'>
        <Select
          className='c-custom-select c-custom-select--min-width--235 u-margin-right'
          value={selectedOptionLeft}
          labelClassName='c-label'
          placeholder={t('Global:Global.placeholder.select_version')}
          options={versionOptions}
          clearable={false}
          onChange={(value) => setSelectedOptionLeft(value)}
        />
        <span className='u-margin-right u-weight--bold'>{t('Global:Global.text.versus_short_lower')}</span>
        <Select
          className='c-custom-select c-custom-select--min-width--235 u-margin-right'
          value={selectedOptionRight}
          labelClassName='c-label'
          placeholder={t('Global:Global.placeholder.select_version')}
          options={versionOptions.filter((option) => option.value !== selectedOptionLeft?.value)}
          clearable={false}
          onChange={(value) => setSelectedOptionRight(value)}
        />
        <button
          className={`c-btn c-btn--small c-btn--curious u-padding-left u-padding-right ${noData ? 'disabled' : ''}`}
          title={t('Global:Global.button.run')}
          onClick={() => onVersionSubmit(selectedOptionLeft, selectedOptionRight)}
          disabled={noData}
        >
          {t('Global:Global.button.run')}
        </button>
      </div>
      {!noData && (
        <div className='d-flex d-flex--wrap u-margin-top'>
          <VarianceDisplayCard
            icon='variance-value-logo'
            subtitle={t('Payrun.reconciliation.total_variance_by_value')}
            value={tilesData.variance}
            currency={tilesData.currency}
            classNames='u-margin-right-small'
          />
          <VarianceDisplayCard
            icon='variance-percentage-logo'
            subtitle={t('Payrun.reconciliation.total_variance_by_percentage')}
            value={tilesData.variance_p}
            shouldShowSuffix
            suffix='%'
            classNames='u-margin-right-small'
          />
          <VarianceDisplayCard
            icon='variance-elements-logo'
            subtitle={t('Payrun.reconciliation.total_elements_with_variance')}
            value={tilesData.totalElementsWithVariance}
          />
        </div>
      )}
      <div className='d-flex jc--space-between ai-center u-margin-top'>
        <div className='d-flex ai-center u-margin-top'>
          <SwitchToggle
            input={{
              value: excludeZeroesToggleValue,
              onChange: (value) => onToggleExcludeZeroes(value, selectedOptionLeft, selectedOptionRight),
            }}
            disabled={noData || isEdit}
          />
          <span className='u-margin-left-small u-margin-right'>{t('Global:Global.text.exclude_zeros')}</span>
          <SwitchToggle
            input={{
              value: toggleValue,
              onChange: (value) => onToggleVariance(value, selectedOptionLeft, selectedOptionRight),
            }}
            disabled={noData || isEdit}
          />
          <span className='u-margin-left-small u-margin-right'>{t('Payrun.reconciliation.variances_only')}</span>
        </div>

        <div className='d-flex ai-center u-margin-top'>
          <CommentaryControlBtns
            onToggleViewComments={() => (hasAllRowsExpanded ? handleIfNecessaryLeaveModal(onViewComments) : onViewComments())}
            hasAllRowsExpanded={hasAllRowsExpanded}
            onEdit={onEditPress}
            isEdit={isEdit}
            onCancel={onCancelCommentaryEdit}
            onSave={onSaveCommentary}
          />
          <button
            type='button'
            title={t('Payrun.reconciliation.download_action_title', {
              action: t('Global:Global.text.download'),
              type: toggleValue ? t('Payrun.reconciliation.variances_only') : t('Payrun.reconciliation.payroll_plus_ee_reconciliation'),
            })}
            disabled={noData || isPollingHappening || isLoadingJob || isEdit}
            className={`c-btn c-btn--small c-btn--curious u-margin-right-small ${isPollingHappening && 'disabled'}`}
            onClick={onDownloadPress}
          >
            <span className='icon icon--download' />
          </button>
          <FilterButton className='c-btn c-btn--curious c-btn--small' onClick={() => setShowFilters(!showFilters)} disabled={isEdit} />
        </div>
      </div>
      <PayslipTable
        data={tableData}
        hasAllRowsExpanded={hasAllRowsExpanded}
        expandableClass='row-expanded u-weight--normal'
        headings={tableHeadings}
        wrapperClassName='u-margin-top'
        editableOptions={{ inGlobalEditMode: isEdit, fieldErrors: [], onUpdateGlobalData }}
        showFilters={showFilters}
        showNoDataMessage={!noData}
        useCustomExpandedDisplay
        options={options}
        remote
        dynamicValues
        pagination
        totalCount={pagination && pagination.totalCount}
        paginationStateControl={{ blockPageChange: { needsPageLock: isEdit && hasChanges, onConfirm: closeEditAndComments } }}
      />
      {noData && (
        <div className='d-flex d-flex--column jc--center ai-center u-text--huge'>
          <MagnifierSearchIcon className='u-margin-top-huge' />
          <div className='u-text--large u-text--15px'>{t('Payrun.reconciliation.view_not_generated')}</div>
        </div>
      )}
    </>
  )
}

PayrollReconciliationView.propTypes = {
  mainOptions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  tilesData: PropTypes.object,
  tableData: PropTypes.array,
  tableHeadings: PropTypes.array,
  noData: PropTypes.bool,
  versionOptions: PropTypes.array.isRequired,
  defaultOptionLeft: PropTypes.object,
  defaultOptionRight: PropTypes.object,
  onVersionSubmit: PropTypes.func.isRequired,
  onToggleVariance: PropTypes.func.isRequired,
  toggleValue: PropTypes.bool.isRequired,
  onToggleExcludeZeroes: PropTypes.func.isRequired,
  excludeZeroesToggleValue: PropTypes.bool.isRequired,
  payrollInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  downloadReport: PropTypes.func.isRequired,
  inProgressPollingJobs: PropTypes.array.isRequired,
  isLoadingJob: PropTypes.bool,
  rehidrateData: PropTypes.func,
  onFilterChange: PropTypes.func,
  onUpdateGlobalData: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  resetChangedData: PropTypes.func.isRequired,
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      currentPage: PropTypes.number,
      totalPages: PropTypes.number,
    }),
  ]),
}

export default PayrollReconciliationView
