import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PropTypes from 'prop-types'
import { onChangeSubmit } from 'utils/form'
import FlagOption from 'components/form/select/FlagOption'
import FlagValue from 'components/form/select/FlagValue'
import ColorOption from 'components/form/select/ColorOption'
import ColorValue from 'components/form/select/ColorValue'
import { Authorization } from 'containers/Authorization'
import { getSelectionOptions } from 'utils/selectOptions'
import { sortByLabel } from 'utils/strings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const EventsFilters = (props) => {
  const {
    isFetching,
    companies,
    payrolls,
    payrollsProcesses,
    owners,
    handleSubmit,
    shouldMergePayrollsAndProcesses,
    shouldNotShowStatus = false,
    addedOptionsToOwnersSelect,
    shouldDisplay,
    resetFields,
    selectedPayroll,
    setCompany,
  } = props

  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const onSubmit = (fields) => {
    onChangeSubmit(handleSubmit)()
    if (fields) resetFields(fields)
  }

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>
  if (!shouldDisplay) return null

  let ownersOptions = owners.map((owner) => ({
    value: owner.id,
    label: owner.name,
  }))
  if (addedOptionsToOwnersSelect) ownersOptions = [...ownersOptions, ...addedOptionsToOwnersSelect]

  return (
    <form onSubmit={handleSubmit}>
      <div className='text-center u-relative u-float--right'>
        <Link
          to={`/toolbox/calendar/year/payruns/${selectedPayroll}`}
          className={`c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny u-margin-left-small u-padding-top-tiny u-padding-bottom-tiny ${
            !selectedPayroll && 'disabled'
          }`}
          onClick={(e) => {
            if (!selectedPayroll) {
              e.preventDefault()
            }
          }}
          data-testid={`calendar-button-${selectedPayroll}`}
          title={t('Global:Global.text.calendar')}
        >
          <span className='icon icon--calendar-year' />
        </Link>
      </div>
      {!shouldNotShowStatus && (
        <Field
          name='actionStatus'
          component={CustomSelectField}
          className='c-custom-select--small c-custom-select--min-width--normal'
          formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
          labelClassName='c-label'
          placeholder={t('BusinessComponent.select.action_status.placeholder')}
          clearable
          options={getSelectionOptions('statusesForActions').map((status) => ({
            value: status.id,
            label: status.name,
          }))}
          onChanged={() => onSubmit()}
        />
      )}
      <Authorization permissions={['TOOLBOX_OWNER_FIELD_VIEW']}>
        <Field
          name='owner'
          component={CustomSelectField}
          className='c-custom-select--small c-custom-select--min-width--normal'
          formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
          labelClassName='c-label'
          placeholder={t('Global:Global.text.owner')}
          clearable
          options={ownersOptions}
          onChanged={() => onSubmit()}
        />
      </Authorization>
      {!shouldMergePayrollsAndProcesses ? (
        <Field
          name='payroll'
          component={CustomSelectField}
          optionComponent={ColorOption}
          valueComponent={ColorValue}
          type='text'
          className='c-custom-select--small c-custom-select--min-width--large'
          formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
          labelClassName='c-label'
          placeholder={t('BusinessComponent.select.process_name.placeholder')}
          clearable
          options={payrolls.map((payroll) => ({
            value: payroll.id,
            label: payroll.name,
          }))}
          onChanged={(id) => {
            setCompany(id)
            onSubmit(['owner'])
          }}
        />
      ) : (
        <Field
          name='payrollsAndProcesses'
          component={CustomSelectField}
          optionComponent={ColorOption}
          valueComponent={ColorValue}
          type='text'
          className='c-custom-select--small c-custom-select--min-width--large'
          formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
          labelClassName='c-label'
          placeholder={t('BusinessComponent.select.process_name.placeholder')}
          clearable
          data-testid='payrolls-and-processes-button'
          options={payrollsProcesses}
          onChanged={(selected) => {
            setCompany(selected)
            onSubmit([])
          }}
        />
      )}
      <Field
        name='company'
        component={CustomSelectField}
        optionComponent={FlagOption}
        valueComponent={FlagValue}
        type='text'
        className='c-custom-select--small c-custom-select--min-width--large'
        formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
        labelClassName='c-label'
        placeholder={t('BusinessComponent.select.country_company.placeholder')}
        clearable
        options={companies
          .map((company) => ({
            value: company.id,
            label: company.name,
            abbreviature: company.country.abbreviature,
          }))
          .sort(sortByLabel)}
        onChanged={() => onSubmit(['payroll', 'companyProcess', 'payrollsAndProcesses'])}
      />
    </form>
  )
}

EventsFilters.propTypes = {
  shouldNotShowStatus: PropTypes.bool,
  isFetching: PropTypes.bool,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  payrollsProcesses: PropTypes.array,
  owners: PropTypes.array,
  handleSubmit: PropTypes.func,
  resetFields: PropTypes.func,
  shouldMergePayrollsAndProcesses: PropTypes.bool,
  setCompany: PropTypes.func,
  shouldDisplay: PropTypes.bool,
  addedOptionsToOwnersSelect: PropTypes.array,
  selectedPayroll: PropTypes.number,
}

export default EventsFilters
