import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { i18nNameSpaces } from 'i18n/types'
import MobileDetailsView from 'components/MobileDetailsView'
import PayslipTable from 'components/table/PayslipTable'
import SectionHeading from 'components/SectionHeading'
import AccordionContainer from 'containers/AccordionContainer'

const generateTableHeadings = (t) => [
  {
    accessor: 'payrollName',
    Header: t('Global:Global.text.payroll_name'),
    headingColClass: 'fixed-width--150',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'payPeriodDates',
    Header: t('Employee.time_and_attendance_table.heading.pay_period_dates'),
    headingColClass: 'fixed-width--150',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'payDates',
    Header: t('Global:Global.text.payDate'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'details',
    Header: t('Global:Global.text.details'),
    disableSortBy: true,
    disableFilters: true,
  },
]

const TimeAttendanceSummary = ({ employee, employeePI, isFetching }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const renderRows = (employee, payrollInstances) => {
    let rows = []
    payrollInstances.forEach((pi) => {
      const data = {}
      const payPeriod = `${moment(pi.fromDate.date).format('DD/MM/YY')} - ${moment(pi.toDate.date).format('DD/MM/YY')}`
      data['payrollName'] = pi.payrollName
      data['payPeriodDates'] = payPeriod
      data['payDates'] = moment(pi.payDate.date).format('DD/MM/YY')
      data['totalOvertime'] = pi.overtimeHours
      data['details'] = (
        <Link
          to={`/employees/${employee.id}/time-attendance/${pi.id}/change-details`}
          className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-small'
        >
          {t('Global:Global.text.view')}
        </Link>
      )
      rows.push(data)
    })
    return rows
  }

  const renderMobileRows = () => {
    let attn = []
    employeePI.map((pi, i) => {
      if (i > 0) attn.push({})
      attn.push(
        { [t('Global:Global.text.payroll_name')]: pi.payrollName },
        {
          [t('Employee.time_and_attendance_table.heading.pay_period_dates')]: `${moment(pi.fromDate.date).format('DD/MM/YY')} - ${moment(pi.toDate.date).format(
            'DD/MM/YY'
          )}`,
        },
        { [t('Global:Global.text.payDate')]: `${moment(pi.payDate.date).format('DD/MM/YY')}` }
      )
    })
    return attn
  }

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div>
      <div className='u-hide-on-mobile'>
        <SectionHeading text={t('Employee.time_and_attendance_section.summary_by_payrun')} />
        <PayslipTable
          trClassName='react-bs-table__cell--overflow-ellipsis'
          wrapper='o-table--tiny o-table--striped'
          headings={generateTableHeadings(t)}
          data={renderRows(employee, employeePI)}
        />
      </div>
      <div className='u-hide-on-desktop'>
        <AccordionContainer title={t('Employee.time_and_attendance_section.summary_by_payrun')} titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={renderMobileRows()} />
        </AccordionContainer>
      </div>
    </div>
  )
}

TimeAttendanceSummary.propTypes = {
  employee: PropTypes.object,
  employeePI: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default TimeAttendanceSummary
