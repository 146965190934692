export const resourcesTypes = {
  releases: {
    dbName: 'Release Documentation',
    text: 'Release Documentation',
  },
  trainings: {
    dbName: 'Training Materials',
    text: 'Training Materials',
  },
}

export const accessibleBy = {
  client: 'Client',
  vendor: 'Vendor',
  both: 'Both',
}

export const uploadForm = [
  {
    value: accessibleBy.client,
    label: 'ER',
  },
  {
    value: accessibleBy.vendor,
    label: 'ICP',
  },
  {
    value: accessibleBy.both,
    label: 'Both',
  },
]
