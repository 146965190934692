import { createSelector } from 'reselect'
import createSelectorORM from 'utils/createSelector'
import { isCot } from 'redux/selectors/auth'
import { getParentCompanies } from 'redux/selectors/parentCompanies'
import { getVendors } from 'redux/selectors/vendors'
import _ from 'lodash'
import { tenantTypes } from 'utils/enums/tenantTypes'

const getTenantId = (state, props) => parseInt(props.tenantId)

export const getParentCompaniesTenants = createSelectorORM(getParentCompanies, (session, companies) => {
  return _.sortBy(
    companies.map((company) => ({
      id: company.id,
      name: company.name,
      url: company.url,
      shortUrl: shortenUrl(company.url),
      type: 'company',
      schema: company.schema,
      referral: company.referral,
      vendorParentCompanies: company.vendorParentCompanies,
      locales: company.locales,
    })),
    ['name']
  )
})

/**
 * Get ParentCompanies, these have a contract with the current operating Vendor
 *
 * We have 2 cases:
 * 1. If the user is COT, the BE returns `vendorParentCompanies` property, which one includes all ParentCompany ids,
 * these the Vendor has contract with. Thanks to it - we can filter and return only the contracted ParentCompanies.
 * 2. If the user is NOT COT, then the BE automatically returns only the contracted ParentCompanies.
 *
 * TODO - Maybe it would be clearer and better if the BE gives us access to ParentCompanyVendor pivot model and
 * we can do the filtrating here on the FE via the ORM.
 */
export const getContactedParentCompanies = createSelector(getParentCompaniesTenants, isCot, (companies, isCot) => {
  if (isCot) return companies.filter((c) => c.vendorParentCompanies?.includes(c.id))

  return companies
})

export const getParentCompaniesTenantById = createSelectorORM(getParentCompaniesTenants, getTenantId, (session, tenants, tenantId) => {
  return tenants.filter((tenant) => tenant.id === tenantId)
})

export const getVendorsTenants = createSelectorORM(getVendors, (session, vendors) => {
  return _.sortBy(
    vendors.map((vendors) => ({
      id: vendors.id,
      name: vendors.name,
      url: vendors.url,
      shortUrl: shortenUrl(vendors.url),
      type: 'vendor',
      schema: vendors.schema,
      locales: vendors.locales?.map((locale) => ({ value: locale.id, name: locale.label })),
    })),
    ['name']
  )
})

export const getVendorsWithLocale = createSelectorORM(getVendors, (session, vendors) => {
  return _.sortBy(
    vendors.map((vendors) => ({
      id: vendors.id,
      name: vendors.name,
      locales: vendors.locales,
    })),
    ['name']
  )
})

const getCurrentTenant = (state) => state.tenants

export const isParentCompanyTenant = createSelector([getCurrentTenant], (tenant) => {
  return tenant.type === 'company'
})

export const isVendorTenant = createSelector([getCurrentTenant], (tenant) => {
  return tenant.type === 'vendor'
})

const shortenUrl = (url) => {
  return url.replace('/api/v1/', '').replace('.api.', '.')
}

/**
 * Selector to retrieve the locales of the tenant based on the tenant type.
 * It evaluates tenant type and returns the respective locales from the parent companies or vendors.
 *
 * @param {Object} state - The application state object.
 * @returns {Array} An array of locale strings for the tenant.
 */
export const getTenantLocales = createSelector(
  [(state) => state.config.tenantType, getParentCompanies, getVendorsWithLocale],
  (tenantType, parentCompanies, vendors) => {
    const parentCompany = parentCompanies?.[0]
    const vendor = vendors?.[0]

    if (tenantType === tenantTypes.TENANT && parentCompany) {
      return parentCompany?.locales || []
    }

    if (tenantType === tenantTypes.VENDOR && vendor) {
      return vendor?.locales || []
    }

    return []
  }
)
