import SectionHeading from 'components/SectionHeading'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select/lib/Select'
import PayslipTable from 'components/table/PayslipTable'
import SwitchToggle from 'components/form/SwitchToggle'
import { isUndefined } from 'lodash'
import DropdownButton from 'components/buttons/DropdownButton'
import { MagnifierSearchIcon } from 'components/icons/magnifierSearchIcon'
import { ArrowTooltip } from 'components/popover/Floater'
import { getBaseFloaterMiddleWare } from 'components/popover/floaterMiddlewares'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ElementReconciliationView = ({
  title,
  tableData,
  tableHeadings,
  excludeZeroes,
  onToggleExcludeZeroes,
  totals,
  noData,
  selectedVersionLeft,
  selectedVersionRight,
  selectedOptionLeft,
  selectedOptionRight,
  elementOptions,
  initialElement,
  onElementSelect,
  onToggleVariance,
  toggleValue,
  payrollInstanceId,
  downloadReport,
  inProgressPollingJobs,
  isLoadingJob,
  tableOptions,
}) => {
  const [selectedElement, setSelectedElement] = useState(initialElement)
  const [isPollingHappening, setIsPollingHappening] = useState(false)

  const { t } = useTranslation([i18nNameSpaces.Payrun])

  const hasInProgressJob = !isUndefined(inProgressPollingJobs.find((job) => job.entityId === payrollInstanceId))
  const toastDownloadButton = document.getElementById(`entity-${payrollInstanceId}`)
  const toastCloseDownloadButton = document.getElementById(`entity-${payrollInstanceId}-close`)

  const onToastDownload = () => {
    setIsPollingHappening(false)
  }

  useEffect(() => {
    toastDownloadButton?.addEventListener('click', onToastDownload)
    return () => {
      toastDownloadButton?.removeEventListener('click', onToastDownload)
    }
  }, [toastDownloadButton])

  useEffect(() => {
    toastCloseDownloadButton?.addEventListener('click', onToastDownload)
    return () => {
      toastCloseDownloadButton?.removeEventListener('click', onToastDownload)
    }
  }, [toastCloseDownloadButton])

  useEffect(() => {
    setIsPollingHappening(hasInProgressJob)
  }, [hasInProgressJob])

  const startDownloadReport = (onlyVariance = false, reportType) => {
    if (isPollingHappening) return
    setIsPollingHappening(true)
    const isElementReport = reportType === 'element'
    downloadReport(
      {
        versionOne: selectedOptionLeft.id,
        versionTwo: selectedOptionRight.id,
        filters: isElementReport
          ? {
            onlyVariance,
            excludeZeroes,
          }
          : {
            onlyVariance,
          },
        entityId: payrollInstanceId,
      },
      reportType
    )
  }
  const noDataMessage = noData ? t('Payrun.reconciliation.view_not_generated') : !selectedElement ? t('Payrun.reconciliation.element_not_selected') : null

  return (
    <>
      <SectionHeading text={title} />
      {!noData && (
        <div className='page--payroll-version-filters'>
          <span className='u-margin-right-small u-weight--bold'>{selectedVersionLeft}</span>
          <span className='u-margin-right-small'>{t('Global:Global.text.versus_short_lower')}</span>
          <span className='u-weight--bold'>{selectedVersionRight}</span>
        </div>
      )}
      <div className='d-flex jc--space-between ai-center u-margin-top'>
        <div className='d-flex ai-center'>
          <SwitchToggle
            input={{
              value: excludeZeroes,
              onChange: onToggleExcludeZeroes,
            }}
            disabled={noData}
          />
          <span className='u-margin-left-small u-margin-right'>{t('Global:Global.text.exclude_zeros')}</span>
          <SwitchToggle
            input={{
              value: toggleValue,
              onChange: onToggleVariance,
            }}
            disabled={noData}
          />
          <span className='u-margin-left-small u-margin-right-large'>{t('Payrun.reconciliation.variances_only')}</span>
        </div>
        <div className='d-flex ai-center'>
          <Select
            className='c-custom-select c-custom-select--min-width--250 u-margin-right'
            value={selectedElement}
            labelClassName='c-label'
            placeholder={t('Global:Global.placeholder.please_select')}
            clearable={false}
            options={elementOptions}
            onChange={(value) => {
              setSelectedElement(value)
              onElementSelect(value)
            }}
          />
          <DropdownButton title={t('Global:Global.text.download')} isDisabled={noData || isPollingHappening || isLoadingJob}>
            <li onClick={() => startDownloadReport(toggleValue, 'element')}>{t('Payrun.reconciliation.element_reconciliation')}</li>
            <ArrowTooltip
              withArrow={false}
              middleware={getBaseFloaterMiddleWare({ offsetValue: 6 })}
              hoverTriggerElement={<li onClick={() => startDownloadReport(toggleValue, 'employee')}>{t('Payrun.reconciliation.employee_reconciliation')}</li>}
            >
              <div className='generic-floater-box floater-size--s'>
                <p>
                  <span className='u-weight--bold'>{t('Global:Global.text.exclude_zeros')}</span>
                  {t('Payrun.reconciliation.toggle_not_apply')}
                </p>
              </div>
            </ArrowTooltip>
          </DropdownButton>
        </div>
      </div>
      <PayslipTable
        data={tableData}
        customLastRow={totals}
        headings={tableHeadings}
        wrapperClassName='u-margin-top'
        showNoDataMessage={!noData && !noDataMessage}
        totalCount={tableOptions?.totalCount}
        options={tableOptions}
        dynamicValues
        pagination
        remote
      />
      {noDataMessage && (
        <div className='d-flex d-flex--column jc--center ai-center u-text--huge'>
          <MagnifierSearchIcon className='u-margin-top-huge' />
          <div className='u-text--large u-text--15px'>{noDataMessage}</div>
        </div>
      )}
    </>
  )
}

ElementReconciliationView.propTypes = {
  title: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  tableHeadings: PropTypes.array.isRequired,
  excludeZeroes: PropTypes.bool.isRequired,
  onToggleExcludeZeroes: PropTypes.func.isRequired,
  totals: PropTypes.object,
  noData: PropTypes.bool,
  selectedVersionLeft: PropTypes.string,
  selectedVersionRight: PropTypes.string,
  selectedOptionLeft: PropTypes.object,
  selectedOptionRight: PropTypes.object,
  elementOptions: PropTypes.array.isRequired,
  initialElement: PropTypes.object,
  onElementSelect: PropTypes.func.isRequired,
  onToggleVariance: PropTypes.func.isRequired,
  toggleValue: PropTypes.bool.isRequired,
  payrollInstanceId: PropTypes.number.isRequired,
  downloadReport: PropTypes.func.isRequired,
  inProgressPollingJobs: PropTypes.array.isRequired,
  isLoadingJob: PropTypes.bool.isRequired,
  tableOptions: PropTypes.object,
}

export default ElementReconciliationView
