import i18n from 'i18next'
import { getMappedOneOfEnumSchema } from 'utils/fnkit/components/SchemaForm/transform'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { reportDesignerRequiredFields, reportDesignerSchemaFields } from './fields'

/**
 * Generates the schema for the report designer form.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Array<Object>} params.countries - The list of countries.
 * @param {Array<Object>} params.companies - The list of companies.
 * @param {Array<Object>} params.payrolls - The list of payrolls.
 * @param {Array<Object>} params.payruns - The list of payruns.
 * @param {Array<Object>} params.businessUnits - The list of business units.
 * @param {Array<Object>} params.costCenters - The list of cost centers.
 * @param {Array<Object>} params.departments - The list of departments.
 * @returns {Object} The schema for the report designer form.
 */
export const getReportDesignerSchema = ({ countries, companies, payrolls, payruns, businessUnits, costCenters, departments } = {}) => ({
  title: '',
  type: 'object',
  required: [reportDesignerSchemaFields.reportName, ...reportDesignerRequiredFields],
  properties: {
    [reportDesignerSchemaFields.reportName]: {
      type: 'string',
      title: i18n.t('Global:Global.text.name'),
      default: i18n.t('Report:Report.name.workforce_payrun_detail'),
    },
    [reportDesignerSchemaFields.fromDate]: {
      type: 'string',
      format: 'date',
      title: i18n.t('Global:Global.text.from_date'),
    },
    [reportDesignerSchemaFields.toDate]: {
      type: 'string',
      format: 'date',
      title: i18n.t('Global:Global.text.to_date'),
    },
    [reportDesignerSchemaFields.country]: {
      type: 'array',
      title: i18n.t('Global:Global.text.country'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(countries, 'name', 'id'),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.company]: {
      type: 'array',
      title: i18n.t('Global:Global.text.company'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(companies, 'name', 'id'),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.payroll]: {
      type: 'array',
      title: i18n.t('Global:Global.text.payroll'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(payrolls, 'name', 'id'),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.payrun]: {
      type: 'array',
      title: i18n.t('Global:Global.text.payrun'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(payruns, 'name', 'id'),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.typeOfEmploymentEngagement]: {
      type: 'array',
      title: i18n.t('Report:Report.field.type_of_employment_engagement'),
      items: {
        oneOf: [{ type: 'number' }, { type: 'string' }],
        ...getMappedOneOfEnumSchema(EmploymentStatusHelper.allStatusOptionsForSelectBox, 'label', 'value', { withNone: true }),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.businessUnit]: {
      type: 'array',
      title: i18n.t('Global:Global.text.business_unit'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(businessUnits, 'name', 'id', { withNone: true }),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.costCenter]: {
      type: 'array',
      title: i18n.t('Global:Global.text.cost_center'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(costCenters, 'name', 'id', { withNone: true }),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.department]: {
      type: 'array',
      title: i18n.t('Global:Global.text.department'),
      items: {
        type: 'number',
        ...getMappedOneOfEnumSchema(departments, 'name', 'id', { withNone: true }),
      },
      uniqueItems: true,
    },
    [reportDesignerSchemaFields.categories]: {
      type: 'array',
      title: ' ',
      items: {
        title: i18n.t('Global:Global.text.category'),
        type: 'object',
        properties: {
          label: { type: 'string', title: i18n.t('Global:Global.text.label') },
          value: { type: 'string', title: i18n.t('Global:Global.text.value') },
          options: {
            type: 'array',
            title: i18n.t('Global:Global.text.field'),
            items: {
              type: 'object',
              properties: {
                label: { type: 'string', title: i18n.t('Global:Global.text.label') },
                value: { type: 'string', title: i18n.t('Global:Global.text.value') },
                selected: { type: 'boolean', default: false },
              },
            },
          },
        },
      },
    },
  },
})
