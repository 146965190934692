import React, { useState } from 'react'
import SideBarChartWithFlags from '../../../../../components/charts/SideBarChartWithFlags'
import PropTypes from 'prop-types'
import Select from 'react-select/lib/Select'
import { sortTypes } from '../../../../../utils/enums/sort'
import { sortByProperty } from '../../../../../utils/sort'
import { useTranslation } from 'react-i18next'

export const SideBarMultiSelectorTemplate = ({ sideBarService, chartLogic, isExporting }) => {
  const chartConfig = chartLogic.SIDE_MULTI_SELECTOR_BARCHART
  const defaultAxis = chartConfig.defaultAxis
  const [axis, setAxis] = useState(defaultAxis)
  const [sortStatus, setSortStatus] = useState(sortTypes.desc)
  const { t } = useTranslation()

  const toggleSort = () => setSortStatus(sortStatus === sortTypes.desc ? sortTypes.asc : sortTypes.desc)
  const handleChange = (axisId, v) => {
    setAxis({ ...axis, [axisId]: v.value })
  }

  return (
    <div className='u-padding-top'>
      <div className='c-display-cards headcount-by-period shadow rounded border u-padding u-padding-bottom-large overflow-y'>
        <div className='d-flex jc--space-between'>
          <div className='d-flex'>
            {chartConfig.selectors.map((option, index) => {
              const axisId = option.id
              const options = chartLogic[option.optionsKey] || []
              const needsPadding = index !== 0
              return (
                <div className={`d-flex ai-center ${needsPadding ? 'ps-pl-[46]' : ''}`}>
                  <h2 className='u-margin-none u-text--xtra-huge u-weight--bold'>{option.title}</h2>
                  <div className={`ps-pl-2 m ${option.className ? option.className : 'u-min-width-200'}`}>
                    <Select
                      className='c-custom-select c-inline-select-dropdown'
                      value={axis[axisId]}
                      labelClassName='c-label'
                      placeholder={t('Global.select.placeholder')}
                      options={options}
                      clearable={false}
                      onChange={(v) => handleChange(axisId, v)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className='headcount-sort' onClick={toggleSort}>
            <span className={`icon icon--chart-sort-icon ${sortStatus === sortTypes.desc ? 'sort-opened' : ''}`} />
          </div>
        </div>
        <div className='ps-mt-[30]'>
          <SideBarChartWithFlags
            data={sideBarService
              .calculateDataBasedOnYAxis({
                disabledRecordByType: true,
                type: axis.xAxis,
                axis,
                withTotals: true,
              })
              .sort((a, b) => sortByProperty(a, b, { sortStatus, key: axis.yAxis }))}
            xKey={axis.xAxis}
            yKey={axis.yAxis}
            queryKey={axis.xAxis}
            isExporting={isExporting}
          />
        </div>
      </div>
    </div>
  )
}

SideBarMultiSelectorTemplate.propTypes = {
  sideBarService: PropTypes.object,
  chartLogic: PropTypes.object,
  isExporting: PropTypes.bool,
}
