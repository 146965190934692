import React from 'react'
import { payrunProgressStatusesEnum } from 'utils/enums/payrunStatuses'
import { ArrowTooltip } from 'components/popover/Floater'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ProgressStatus = (instance) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const defaultStatusConfig = {
    style: 'neutral',
    text: t('BusinessComponent.progress_status.notStarted'),
  }

  const statusConfigCases = {
    [payrunProgressStatusesEnum.OVERDUE]: {
      style: 'warning',
      text: t('BusinessComponent.progress_status.overdue'),
    },
    [payrunProgressStatusesEnum.PENDING]: {
      style: 'caution',
      text: t('BusinessComponent.progress_status.pending'),
    },
    [payrunProgressStatusesEnum.ON_TIME]: {
      style: 'safe',
      text: t('BusinessComponent.progress_status.onTime'),
    },
  }

  const statusConfig = statusConfigCases[instance.progressStatus] || defaultStatusConfig
  const showTooltip = instance.progressStatus === payrunProgressStatusesEnum.OVERDUE && instance.overdueTasks

  const statusLabel = (
    <div className={`c-status-label c-status-label--tiny c-status-label--${statusConfig.style} u-text--small`}>
      {showTooltip ? (
        <ArrowTooltip hoverTriggerElement={statusConfig.text} hoverDelayClose={0}>
          <div className='generic-floater-box floater-size--xs'>
            <p className='text-center u-text--small'>
              <span className='u-weight--bold'>{instance.overdueTasks}</span>
              <span className='u-weight--regular'>{t('BusinessComponent.progress_status.tooltip')}</span>
            </p>
          </div>
        </ArrowTooltip>
      ) : (
        statusConfig.text
      )}
    </div>
  )

  return statusLabel
}

export default ProgressStatus
