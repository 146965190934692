import React, { createContext, Suspense, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import i18n from 'i18next'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

import Loader from 'components/Loader'
import { useSelector } from 'react-redux'
import { getUserLocale } from 'redux/selectors/users'

export const LocaleSwitcherContext = createContext(null)

export const LocaleSwitcher = ({ children }) => {
  const userLocale = useSelector(getUserLocale)
  const [currentLang, setCurrentLang] = useState(userLocale)

  const registerDatePickerLocale = async (lang) => {
    try {
      const dateFNSLocale = lang === 'en' ? 'en-US' : lang
      const datePickerLocale = await import(`date-fns/locale/${dateFNSLocale}/index.js`)
      registerLocale(lang, datePickerLocale.default)
      setDefaultLocale(lang)
    } catch (error) {
      console.error('Failed to change language or register locale:', error)
    }
  }

  const changeLanguage = async (lang) => {
    try {
      await i18n.changeLanguage(lang)
      await registerDatePickerLocale(lang)
      setCurrentLang(lang)
    } catch (error) {
      console.error('Failed to change language', error)
    }
  }

  useEffect(() => {
    changeLanguage(userLocale)
  }, [userLocale])

  const context = useMemo(
    () => ({
      currentLang,
      changeLanguage,
    }),
    [currentLang]
  )

  return (
    <Suspense fallback={<Loader />}>
      <LocaleSwitcherContext.Provider value={context}>{children}</LocaleSwitcherContext.Provider>
    </Suspense>
  )
}

LocaleSwitcher.propTypes = {
  children: PropTypes.node.isRequired,
}
