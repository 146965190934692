import { titleTypes } from 'redux/config/DropDownOptions/titleOptions'
import { getMode2faOptions } from 'redux/config/DropDownOptions/mode2faOptions'
import i18n from 'i18next'

export const getStatusesForActions = () => [
  { id: 'all', name: i18n.t('Global:Global.text.all') },
  { id: 'overdue', name: i18n.t('Global:Global.actions.overdue') },
]

const selectionOptionsConfig = {
  statusesForActions: getStatusesForActions,
  title: titleTypes,
  mode2fa: getMode2faOptions(),
}

/**
 * A method that should be used to retrieve selection
 * options that are not currently coming back from the BE
 * This should help translations as we can change it in one place
 *
 * @param {string} type String containing the list you want
 * Current accepted values ['statusesForActions', 'title', 'mode2fa]
 * @returns An array of options for display in custom select box
 */
export const getSelectionOptions = (type) => {
  const options = selectionOptionsConfig[type]
  // If the selection option is a function, invoke it to get the options.
  return typeof options === 'function' ? options() : options || []
}

// get label for value from list of options
export const getLabelFromOptions = (options, value) => {
  return options.find((o) => o.value === value)?.label || ''
}

/**
 * Converts an object into a key-value pair with specified keys for the value and label fields.
 *
 * @function
 * @param {Object} item - The input object to be transformed.
 * @param {string} [valueKey='id'] - The key to extract the value property from the input object.
 * @param {string} [valueLabel='label'] - The key to extract the label property from the input object.
 * @returns {Object} An object containing a `value` and `label` property derived from the input object.
 *
 * @example
 * // Default usage with id and label as keys:
 * const item = { id: 1, label: 'Option 1' };
 * const result = formatToValueLabelPair(item);
 * console.log(result); // { value: 1, label: 'Option 1' }
 *
 * @example
 * // Custom keys for value and label:
 * const item = { code: 'A1', name: 'Option A1' };
 * const result = formatToValueLabelPair(item, 'code', 'name');
 * console.log(result); // { value: 'A1', label: 'Option A1' }
 *
 * @example
 * // Input object with missing keys:
 * const item = { id: 1 };
 * const result = formatToValueLabelPair(item);
 * console.log(result); // { value: 1, label: null }
 *
 */

export const formatToValueLabelPair = (item, valueKey = 'id', valueLabel = 'label') => ({
  value: item?.[valueKey] ?? null,
  label: item?.[valueLabel] ?? null,
})
