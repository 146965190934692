import { i18nNameSpaces } from 'i18n/types'

import React from 'react'
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'

const TimelineForm = ({ ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  return (
    <Form {...props}>
      <div className='o-layout u-text--center'>
        <div className='o-layout__item u-1/1 u-1/3@tablet'>
          <Field
            name='deadlineMonthly'
            label={t('Vendor.form.deadlineMonthly')}
            labelClassName='c-label'
            component={InputField}
            type='number'
            placeholder={t('Global.text.enter_value')}
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/3@tablet'>
          <Field
            name='deadlineFortnightly'
            label={t('Vendor.form.deadlineFortnightly')}
            labelClassName='c-label'
            component={InputField}
            type='number'
            placeholder={t('Global.text.enter_value')}
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/3@tablet'>
          <Field
            name='deadlineWeekly'
            label={t('Vendor.form.deadlineWeekly')}
            labelClassName='c-label'
            component={InputField}
            type='number'
            placeholder={t('Global.text.enter_value')}
          />
        </div>
        <div className='o-layout__item u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'>
            {t('Global.button.save')}
          </button>
        </div>
      </div>
    </Form>
  )
}

export default TimelineForm
