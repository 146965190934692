import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import SectionHeading from 'components/SectionHeading'
import { Link } from 'react-router-dom'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PayrollUpdatesList = ({ payrollInstances, isFetching }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const renderRows = () => {
    return payrollInstances.map((instance) => ({
      payrollName: instance.payrollName,
      fromDate: moment(instance.fromDate.date).format('DD/MM/YYYY'),
      toDate: moment(instance.toDate.date).format('DD/MM/YYYY'),
      payDate: moment(instance.payDate.date).format('DD/MM/YYYY'),
      file: (
        <div className='o-grid o-grid--middle o-grid--center'>
          <Link to={`/payruns/active/${instance.instanceId}/instance?employeeId=${instance.employeeId}`} className='icon--doc' data-testid='employee-payfile' />
        </div>
      ),
    }))
  }

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>
  if (payrollInstances.length === 0) {
    return (
      <div className='u-text-center'>
        <p>{t('Employee.payroll_updates_list_text.noPayrollUpdates')}</p>
      </div>
    )
  }
  return (
    <div>
      <SectionHeading text={t('Employee.payroll_updates_list_sectionHeading.payrollUpdates')} />
      <PayslipTable
        wrapperClassName='u-1/1'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        data-testid='employee-payroll-updates'
        headings={[
          {
            Header: t('Employee.payroll_updates_list_tableHeadings.payroll'),
            accessor: 'payrollName',
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: t('Employee.payroll_updates_list_tableHeadings.fromDate'),
            accessor: 'fromDate',
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: t('Employee.payroll_updates_list_tableHeadings.toDate'),
            accessor: 'toDate',
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: t('Employee.payroll_updates_list_tableHeadings.payDate'),
            accessor: 'payDate',
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: t('Employee.payroll_updates_list_tableHeadings.payrunFile'),
            classNames: 'text-center',
            accessor: 'file',
            disableSortBy: true,
            disableFilters: true,
          },
        ]}
        data={renderRows()}
      />
    </div>
  )
}

PayrollUpdatesList.propTypes = {
  payrollInstances: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default PayrollUpdatesList
