import RedirectContainer from './containers/RedirectContainer'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/vendors-redirect',
  exact: true,
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Vendor, RedirectContainer) },
  authProtected: true,
  wrappedComponent: RedirectContainer,
})
