import { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getDocuments, getFilteredDocsCount } from 'redux/selectors/document'
import { bulkUpdateDocument, fetchDocuments } from 'redux/actions/document'
import { createDocsPaginationFilter } from 'redux/filters/createDocumentFilter'
import { isFetchingByCustomFilter } from 'utils/redux/fetching'
import { isEmpty } from '../utils/fnkit/typeChecks'
import { getDocFilterNames, getStaticDocsTableOpts, normalizeDocumentTableFilters } from 'components/DocumentList/documentTableUtils'
import { showMessage } from 'redux/actions/modal'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

/**
 * Custom hook to manage document fetching and filtering for a payroll instance.
 *
 * @param {object} params - The parameters object.
 * @param {string} params.payrollInstanceId - Unique identifier for the payroll instance.
 * @param {boolean} params.needsFetchDocs - Flag indicating whether documents need to be fetched.
 * @param {string} params.filterName - Name of the filter to be applied.
 * @param {object} params.filters - Additional filters to be applied.
 * @returns {object} - An object containing document filters, documents array, fetching status, and a function to sync documents data.
 * @returns {Array<string>} return.docFilterNames - The names of the document filters.
 * @returns {Array<object>} return.documents - The fetched documents.
 * @returns {boolean} return.isFetching - Indicates if the documents are currently being fetched.
 * @returns {function} return.syncDocsData - Function to synchronize documents data.
 * @deprecated
 */
export const useDocument = ({ filterName }) => {
  const dispatch = useDispatch()
  const docFilterNames = ['typeClass', 'typeId']
  const documents = useSelector((state) => getDocuments(state, filterName))
  const syncDocsData = ({ filter }) => dispatch(fetchDocuments({ filter }))

  return {
    docFilterNames,
    documents: documents || [],
    syncDocsData,
  }
}

/**
 * Custom hook to manage and fetch filtered documents based on provided criteria.
 * This hook initializes with state for pagination and filter visibility, subscribes to ORM state for document data,
 * and dispatches actions to fetch documents as needed based on the filter and pagination changes.
 *
 * @param {Object} params - The parameters for fetching filtered documents.
 * @param {string} params.filterName - Unique identifier for the current filter setting in ORM State.
 * @param {Object} params.extraFilters - Additional filter criteria that do not for document types and/or specific page filter.
 * @param {Array.<string>} params.filterNames - List of filter names to include in document queries.
 * @param {Array.<Object>} params.tableHeadings - Table headings that determine which document filters to apply.
 * @param {number} params.typeId - Document type identifier, used in filtering documents.
 * @param {string} params.typeClass - Document class identifier, used for categorizing documents.
 * @returns {Object} Returns an object containing:
 *  - {boolean} showFilters - State to show or hide filters.
 *  - {Array} documents - An array of document data fetched based on the applied filters.
 *  - {number} totalCount - Total number of documents.
 *  - {Object} tableOptions - Table options and event handlers for the table component.
 *  - {boolean} isFetching - Flag indicating whether documents are currently being fetched.
 *  - {Function} handleFilterChange - Toggles the visibility of filters.
 *  - {Function} fetchData - Function to trigger fetching documents based on the current filter settings.
 */
export const useFilteredDocuments = ({ isVendorDocuments, filterName, extraFilters, filterNames, tableHeadings, typeId, typeClass, typeTenant }) => {
  const docFilterNames = [...filterNames, ...getDocFilterNames(tableHeadings)]
  /*
   * State declarations
   */
  const [showFilters, setShowFilters] = useState(false)
  const [pagination, setPagination] = useState({})

  /*
   * Selectors
   */
  const { documents, totalCount, params } = useSelector((state) => getFilteredDocsCount(state, filterName))
  const isFetching = useSelector((state) => isFetchingByCustomFilter(filterName, state.document))

  const dispatch = useDispatch()

  /**
   * State changes
   */
  const handleFilterChange = () => {
    setShowFilters(!showFilters)
  }
  const handleParamsChange = (newParams) => {
    const offset = newParams?.offset
    const limit = newParams?.limit
    setPagination({
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit,
      limit,
      totalCount,
    })
  }

  /*
   * Use Effects
   */
  useEffect(() => {
    if (!isEmpty(params)) {
      handleParamsChange(params)
    }
  }, [params])

  /**
   * Hook Actions
   */
  const fetchData = ({ filters, sort, offset, limit }) => {
    if (!isFetching) {
      const computedFilters = {
        ...normalizeDocumentTableFilters(filters),
        typeId,
        typeTenant,
        typeClass,
        ...extraFilters,
      }
      const filter = createDocsPaginationFilter(filterName, {
        isVendorDocuments,
        filters: computedFilters,
        sort,
        offset,
        limit,
        docFilterNames,
      })
      dispatch(fetchDocuments({ filter }))
    }
  }

  const resyncData = ({ pageSize, pageIndex, filters, sortBy }) => {
    const isChangedFilter = pageIndex === pagination?.currentPage
    const limit = pageSize
    const sort = sortBy?.map((obj) => ({
      name: obj.id,
      order: obj.desc ? 'desc' : 'asc',
    }))
    let fetcherProps = { filters, sort, offset: 0, limit }

    if (!isChangedFilter) {
      fetcherProps = { filters, sort, offset: pageIndex * pageSize, limit }
    }
    fetchData(fetcherProps)
  }

  const tableOptions = {
    ...getStaticDocsTableOpts(),
    showPageSizeSelector: totalCount > pagination?.limit,
    pageSize: pagination && pagination.limit,
    pageIndex: pagination && pagination.currentPage,
    pageCount: pagination && pagination.totalPages,
    onPageChange: resyncData,
    onSort: resyncData,
    filterDebounce: 240,
  }
  return {
    showFilters,
    documents,
    totalCount: totalCount || 0,
    tableOptions,
    isFetching,
    handleFilterChange,
    fetchData,
  }
}

/**
 * Function to manage the selection or deselection of a document.
 *
 * @param {object} params - The parameters object.
 * @param {Array<object>} params.selectedDocuments - Array of currently selected documents.
 * @param {object} params.row - The row data from which the document will be selected or deselected.
 * @param {boolean} params.isSelected - Flag indicating whether the document is being selected or deselected.
 * @returns {Array<object>} - The updated array of selected documents after adding or removing the specified document.
 */
const getSelectedDocuments = ({ selectedDocuments, row, isSelected }) => {
  let newSelectedDocs = [...selectedDocuments]
  const rowData = row.original
  if (isSelected) {
    newSelectedDocs.push(rowData)
    return newSelectedDocs
  }
  return newSelectedDocs.filter(({ id }) => id !== rowData.id)
}

/**
 * Custom hook to manage the selection and manipulation of documents
 *
 * @returns {object} - An object containing the selected documents,
 * a boolean indicating if any documents are selected, and functions
 * to handle document selection and addition.
 * @returns {Array<object>} return.selectedDocuments - The currently selected documents.
 * @returns {boolean} return.hasSelectedDocs - Indicates whether there are any selected documents.
 * @returns {function} return.onSelectDocument - Function to handle the selection of a document.
 * @returns {function} return.addSelectedDocuments - Function to add selected documents into the backend
 */
export const useSelectDocuments = () => {
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const hasSelectedDocs = selectedDocuments?.length > 0
  const dispatch = useDispatch()
  const { t } = useTranslation([i18nNameSpaces.Payrun])

  const resetSelectedDocuments = useCallback(() => {
    setSelectedDocuments([])
  }, dispatch)

  const onSelectDocument = (row, isSelected) => {
    const newSelectedDocs = getSelectedDocuments({
      selectedDocuments,
      row,
      isSelected,
    })
    setSelectedDocuments(newSelectedDocs)
  }

  const addSelectedDocuments = async () => {
    const docsToAdd = selectedDocuments?.reduce(
      (acc, nextDoc) => ({
        ...acc,
        [nextDoc.id]: { payrollSummary: true },
      }),
      {}
    )
    try {
      await dispatch(bulkUpdateDocument(docsToAdd, false))
    } catch (_) {
      dispatch(showMessage({ body: t('Global:Global.text.something_went_wrong') }))
    }
  }

  return {
    selectedDocuments,
    resetSelectedDocuments,
    hasSelectedDocs,
    onSelectDocument,
    addSelectedDocuments,
  }
}
