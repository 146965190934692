import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommentIcon } from 'components/icons/CommentIcon'
import { saveSummaryNotes } from 'redux/actions/summarynotes'
import { showMessage } from 'redux/actions/modal'
import { LeaveConfirmationModal, LeaveRouteConfirmationModal } from 'components/ReusableModals/LeaveConfirmationModal'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'

export const SummaryNotes = ({ isDirty, payrollInstanceId, tableChangedData, isEdit, isAllRowsExpanded, toggleShowComments, toggleEditMode, refreshState }) => {
  const navigationLockedModalRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const notesToSave = tableChangedData?.filter((note) => note.isDirty)
  const canEdit = useSelector((state) => !isFinanceAdmin(state) && !isAccounting(state))
  const showEditButton = isAllRowsExpanded && canEdit

  const handleIfInecessaryLeaveModal = (cb) => {
    if (isDirty) {
      navigationLockedModalRef?.current?.showModal()
      return
    }
    cb()
  }

  const handleSave = async () => {
    if (isDirty) {
      const savedNotesResponse = await dispatch(saveSummaryNotes(payrollInstanceId, notesToSave))
      if (savedNotesResponse?.hasErrors) {
        dispatch(showMessage({ body: t('Global:Global.text.something_went_wrong') }))
      }
    }
    refreshState()
  }

  return (
    <div className='d-flex'>
      {isEdit && (
        <>
          <LeaveConfirmationModal modalRef={navigationLockedModalRef} onConfirmation={refreshState} />
          <LeaveRouteConfirmationModal isDirty={isDirty} />
        </>
      )}
      <button
        className={`${btnClasses} d-flex u-margin-right-small`}
        onClick={() => {
          handleIfInecessaryLeaveModal(toggleShowComments)
        }}
      >
        <CommentIcon className='u-margin-right-small' />
        {isAllRowsExpanded ? t('Payrun.button.close_comments') : t('Payrun.button.view_comments')}
      </button>
      {showEditButton && (
        <button
          onClick={toggleEditMode}
          className={`${btnClasses} u-margin-right-small`}
          title={t('Global:Global.text.edit')}
          data-testid='versioning-comments-edit'
          disabled={isAllRowsExpanded && isEdit}
        >
          <span className='icon icon--edit' />
        </button>
      )}
      {isEdit && (
        <>
          <button
            onClick={() => {
              handleIfInecessaryLeaveModal(refreshState)
            }}
            className={`${btnClasses} u-margin-right-small`}
            title={t('Global:Global.button.cancel')}
            data-testid='versioning-cancel'
          >
            {t('Global:Global.button.cancel')}
          </button>
          <button onClick={handleSave} className={`${btnClasses} u-margin-right-small`} title={t('Global:Global.text.save')} data-testid='versioning-save'>
            {t('Global:Global.text.save')}
          </button>
        </>
      )}
    </div>
  )
}

SummaryNotes.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  payrollInstanceId: PropTypes.string.isRequired,
  tableChangedData: PropTypes.arrayOf(
    PropTypes.shape({
      isDirty: PropTypes.bool.isRequired,
      itemKey: PropTypes.string,
      itemType: PropTypes.string,
      body: PropTypes.string,
    })
  ),
  isEdit: PropTypes.bool.isRequired,
  isAllRowsExpanded: PropTypes.bool.isRequired,
  toggleShowComments: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  refreshState: PropTypes.func.isRequired,
}
