import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Board from 'react-trello'
import AssignmentCard from './AssignmentCard'
import AssignmentListOptions from './AssignmentListOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { CompletedIcon, InProgressIcon, NotStartedIcon } from 'components/icons/trelloIcons'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

let eventBus
const setEventBus = (handle) => {
  eventBus = handle
}

const MyLaneHeader = (props) => {
  return props.label
}

const iconTypes = Object.freeze({
  notStarted: 'not-started',
  inProgress: 'in-progress',
  completed: 'completed',
})

const getIcon = (label = iconTypes.notStarted) => {
  const icons = {
    [iconTypes.notStarted]: () => <NotStartedIcon className={`icon icon--${label} u-float--right`} />,
    [iconTypes.inProgress]: () => <InProgressIcon className={`icon icon--${label} u-float--right`} />,
    [iconTypes.completed]: () => <CompletedIcon className={`icon icon--${label} u-float--right`} />,
  }
  return icons[label] || icons[iconTypes.notStarted]
}

const AssignmentsList = (props) => {
  const [assignment, setAssignment] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [bulkArr, setBulkArr] = useState([])
  const [isChangingStatus, setIsChangingStatus] = useState(false)
  const confirmModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Toolbox])

  const renderAssignments = () => {
    return {
      lanes: props.groupedAssignments.lanes.map((lane) => {
        const createIcon = getIcon(lane.label)
        return {
          ...lane,
          label: (
            <header>
              <span className='lane-title u-padding-left-small'>{lane.title}</span>
              <span>
                {createIcon()}
                {lane.cards.length > 0 && (
                  <AssignmentListOptions
                    selectAll={() => selectAll(lane.cards.filter((c) => c.draggable).map((c) => c.id))}
                    deselectAll={() => deselectAll(lane.cards.map((c) => c.id))}
                  />
                )}
              </span>
            </header>
          ),
        }
      }),
    }
  }

  const showModal = () => {
    confirmModalRef.current.showModal()
  }

  const handleConfirm = () => {
    if (bulkArr.length > 1) {
      const assignmentsArr = bulkArr.map((a) => props.assignmentById(a))
      props.onUpdate(assignmentsArr, to)
    } else {
      props.onUpdate([assignment], to)
    }
    setIsChangingStatus(false)
  }

  const handleReject = () => {
    eventBus.publish({
      type: 'ADD_CARD',
      laneId: from,
      card: assignment,
    })
    eventBus.publish({ type: 'REMOVE_CARD', laneId: to, cardId: assignment.id })
    setIsChangingStatus(false)
  }

  const toggleSingleSelection = (cardId, allCards) => {
    let selectedCard = allCards.filter((c) => c.id === cardId).shift()
    if (!bulkArr.find((i) => i === cardId) && selectedCard?.draggable) {
      setBulkArr([...bulkArr, cardId])
    } else {
      setBulkArr(bulkArr.filter((i) => i !== cardId))
    }
  }

  const selectAll = (arr) => {
    setBulkArr([...bulkArr, ...arr])
  }

  const deselectAll = (arr) => {
    const newArr = bulkArr.filter((i) => !arr.includes(i))
    setBulkArr(newArr)
  }

  const buildConfirmationText = () => {
    if (bulkArr.length > 1) {
      return t('Toolbox.modal.confirmation_text_bulk', {
        count: bulkArr.length,
      })
    }
    return t('Toolbox.modal.confirmation_text_single')
  }

  const renderCard = (props) => {
    return <AssignmentCard bulkArr={bulkArr} {...props} />
  }

  let allCards = []
  props.groupedAssignments.lanes.forEach((lane) => {
    lane.cards.map((card) => {
      allCards.push(card)
      card['isSelected'] = false
      bulkArr.forEach((arr) => {
        if (arr === card.id && card.draggable) {
          card['isSelected'] = true
        }
      })
      return card
    })
  })

  return (
    <div style={{ width: '100%' }}>
      <Board
        components={{ LaneHeader: MyLaneHeader, Card: renderCard }}
        data={renderAssignments()}
        eventBusHandle={setEventBus}
        className={`c-draggableboard ${isChangingStatus ? 'u-half-opacity' : ''}`}
        customCardLayout
        handleDragStart={(_cardId, _laneId) => {}}
        handleDragEnd={(cardId, sourceLaneId, targetLaneId) => {
          const assignment = props.assignmentById(cardId)
          setAssignment(assignment)
          setFrom(sourceLaneId)
          setTo(targetLaneId)
          if (sourceLaneId !== targetLaneId) {
            showModal()
            setIsChangingStatus(true)
          }
        }}
        onCardClick={(cardId, metadata) => {
          toggleSingleSelection(cardId, allCards)
        }}
      />
      <ConfirmationModal
        ref={confirmModalRef}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={handleConfirm}
        onReject={handleReject}
      >
        <p>{buildConfirmationText()}</p>
      </ConfirmationModal>
    </div>
  )
}

AssignmentsList.propTypes = {
  groupedAssignments: PropTypes.object,
  assignmentById: PropTypes.func,
  onUpdate: PropTypes.func,
  isDraggable: PropTypes.bool,
}

export default AssignmentsList
