import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PayrollsPinButton = ({ pinned, onClick, id, canPinPayroll = true }) => {
  const pinnedCss = pinned ? 'pinned' : 'not-pinned'
  const canPinCss = canPinPayroll ? 'can-pin' : 'pinning-not-allowed'
  const { t } = useTranslation()
  return (
    <div className={`pin-icon hover-box-cell ${pinnedCss} ${canPinCss}`} onClick={onClick} data-testid={`pin-button-${id}`}>
      <span className='icon' />
      <div className='hover-box hover-box--pinned-payruns'>
        <div className='text-center u-text-u-weight--regular'>{pinned ? t('Global.text.unpin') : t('Global.text.pin')}</div>
      </div>
    </div>
  )
}

PayrollsPinButton.propTypes = {
  pinned: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  canPinPayroll: PropTypes.bool,
}

PayrollsPinButton.defaultProps = {
  canPinPayroll: true,
}
export default PayrollsPinButton
