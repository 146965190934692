import React from 'react'
import { NavLink } from 'react-router-dom'
import { Field } from 'redux-form'
import { Trans, useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import { emailValidation } from 'utils/validations'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const ForgottenPasswordView = (props) => {
  const { t } = useTranslation()

  return (
    <div className='c-login__content-forgotten-pass'>
      <div className='o-grid o-grid--center'>
        <Form
          showSubmitBtn
          btnText={t('Global.button.send')}
          colorClassBtn='c-btn--curious u-margin-top-none'
          btnFullWidth
          classNames='c-btn--rounded'
          {...props}
        >
          <div className='o-form-group u-margin-bottom-large'>
            <Field
              name='email'
              label={t('Global.input.label.email')}
              component={InputField}
              type='text'
              autoFocus
              placeholder={t('Global.input.placeholder.email')}
              className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
              labelClassName='c-label c-label--forgotten-pass'
              validate={emailValidation}
            />
          </div>
        </Form>
      </div>
      <div className='o-grid o-grid--center'>
        <div className='u-text--center u-text--medium'>
          <span>
            <Trans
              i18nKey='Global.link.back_to_login'
              ns={i18nNameSpaces.Global}
              components={[<NavLink key='toHome' to='/' className='u-text--small u-text--curious' />]}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

ForgottenPasswordView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ForgottenPasswordView
