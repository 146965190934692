import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import BusinessUnitsOwnersTable from './BusinessUnitsOwnersTable'
import { i18nNameSpaces } from 'i18n/types'

const MultiOwners = ({ payrollTaskName, buOwnersData, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  return (
    <div>
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <h2 className='u-text--center u-text--huge'>
            <span>
              {t('Payroll.modal.owners_for_action')} <span className='u-weight--bold'>{payrollTaskName}</span>
            </span>
          </h2>
        </div>
      </div>
      <Form {...rest}>
        <div className='o-layout u-text--center'>
          <div className='o-layout'>
            <div className='o-layout__item u-1/1'>
              <div className='o-layout__item u-1/1'>
                <BusinessUnitsOwnersTable
                  buOwnersData={buOwnersData}
                  ownersProps={{
                    placeholder: t('Payroll.table.placeholder_no_action_owner'),
                    disabled: true,
                    disabledReason: t('Payroll.table.disabled_reason_change_task_owner'),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

MultiOwners.propTypes = {
  payrollTaskName: PropTypes.string,
  buOwnersData: PropTypes.array,
}

export default MultiOwners
