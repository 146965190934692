import React, { useRef } from 'react'
import Modal from 'components/Modal'
import CompanyDetails from './CompanyDetails'
import VendorFormEditContainer from '../containers/VendorFormEditContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const CompanyDetailsView = (props) => {
  const editModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  const showModal = () => {
    if (editModalRef.current) {
      editModalRef.current.showModal()
    }
  }

  const hideModal = () => {
    if (editModalRef.current) {
      editModalRef.current.hideModal()
    }
  }

  return (
    <div>
      <Modal
        ref={editModalRef}
        className='c-modal c-modal--full c-modal--overflow-y'
        modalHeading={t('Vendor.text.edit_vendor')}
        data-testid='vendor-edit-modal'
      >
        <VendorFormEditContainer onSubmit={hideModal} {...props} />
      </Modal>
      <CompanyDetails onEdit={showModal} {...props} />
    </div>
  )
}

export default CompanyDetailsView
