import React from 'react'
import PropTypes from 'prop-types'
import { AVAILABLE_FORMATS_REPORT } from 'routes/Reporting/utils/reports'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

/**
 * A functional component that renders a button for viewing reports. The button will be disabled
 * if the report does not contain the Excel format as an available option.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.report - The report object containing details about the report, including available formats.
 * @param {string} [props.className] - An optional custom CSS class to apply to the button.
 * @param {Function} props.handleClick - A callback function to handle the button's click event.
 * @returns {JSX.Element} A styled button element for viewing the report.
 */
export const ReportViewButton = ({ report, className, handleClick }) => {
  const { t } = useTranslation([i18nNameSpaces.Report])
  const hasExcel = report?.availableFormats.includes(AVAILABLE_FORMATS_REPORT.EXCEL)
  return (
    <button
      className={className || 'c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'}
      title={!hasExcel ? t('Report.text.too_large_view') : t('Global:Global.text.view')}
      onClick={handleClick}
      disabled={!hasExcel}
    >
      <span className='icon icon--preview' />
    </button>
  )
}

ReportViewButton.propTypes = {
  report: PropTypes.shape({
    availableFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
}
