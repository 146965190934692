import Loader from 'components/Loader'
import React, { useContext } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import PropTypes from 'prop-types'
import DashboardTileHeader from './DashboardTileHeader'
import moment from 'moment'
import { Event, formats, WeeklyHeader } from 'components/calendar/CustomComponents'
import { useTranslation } from 'react-i18next'
import { LocaleSwitcherContext } from 'i18n/components/LocaleSwitcher'

const CalendarView = ({ tasks }) => {
  const { currentLang } = useContext(LocaleSwitcherContext)
  moment.locale(currentLang || 'en-GB')

  return (
    <div className='c-calendar__holder--border dashboard-calendar'>
      <BigCalendar
        defaultDate={new Date()}
        events={tasks}
        defaultView='week'
        toolbar
        popup
        formats={formats}
        localizer={momentLocalizer(moment)}
        components={{
          event: Event,
          toolbar: (toolbar) => <span>{null}</span>,
          week: {
            header: WeeklyHeader,
          },
        }}
      />
    </div>
  )
}

const CalendarActionDashboardView = (props) => {
  const { t } = useTranslation()
  return (
    <div data-testid='dashboard-calendar'>
      <DashboardTileHeader title={t('Global.text.this_week_actions')} onClick={props.onNavigatingToCalendar} />
      <div className='u-margin-left-small u-margin-right-small'>{!props.isFetching ? <CalendarView {...props} /> : <Loader />}</div>
    </div>
  )
}
CalendarActionDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigatingToCalendar: PropTypes.func,
}

CalendarView.propTypes = {
  tasks: PropTypes.array,
}

export default CalendarActionDashboardView
