import React from 'react'
import i18n from 'i18next'

import { isEmpty, sortedUniqBy, uniqBy } from 'lodash'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { varianceHover, variancePercentageHover, versioningCellFormatting, versioningElementName } from 'utils/tableDataFormatters'
import { getTermName } from 'routes/Payruns/utils/versioning'
import { elementSortingOrder } from '../utils/elements'
import { SelectColumnFilter } from 'components/table/filters'
import { sortByLabel } from 'utils/strings'
import { CommentIcon } from 'components/icons/CommentIcon'

export const reconciliationAccesors = Object.freeze({
  category: 'category',
  subcategory: 'subcategory',
  elementName: 'elementName',
  format: 'format',
  previous: 'previous',
  v1: 'v1',
  v2: 'v2',
  variance: 'variance',
  varianceP: 'variance_p',
  result: 'result',
})
// BE expected filter values, we need it to be strings because otherwise false gets interpreted as not having a filter by the table library
const zeroComparisonOptions = [
  { value: 'true', label: '= 0' },
  { value: 'false', label: '≠ 0' },
]

/**
 * Handling Duplicates and Filter Selection for Subcategory and ElementName:
 *
 * 1. Duplication Handling:
 *    - The options for subcategory and elementName, which might contain duplicates,
 *      are stored in the screen's state.
 *    - When generating the options list, each element is checked for duplicate names.
 *    - For duplicates, it is ensured that they do not have the same ID.
 *    - Information about duplicates (if any) is stored in a specific property
 *      for later reference. (filtersId)
 *
 * 2. Composing Filter Options:
 *    - The final list of options in the dropdown is composed by removing any duplicates.
 *    - This deduplication ensures that each option in the filter list is unique.
 *
 * 3. Filter Selection Logic:
 *    - When filters are selected, particularly for 'elementName' or 'subcategory',
 *      there's a matching process with the initial options list.
 *    - For each selected value, the corresponding item in the initial list is identified (mainOptions list).
 *    - A property containing the IDs (filtersId) of these items is used.
 *    - These IDs are then concatenated with any existing IDs in the filter to
 *      maintain a comprehensive list of all selected options.
 *
 */

const filterByUniq = (list = []) => uniqBy(list, 'label')
const getFiltersId = (value, label, list = []) => {
  let filtersId
  try {
    filtersId = list?.filter((item) => item.label === label && item.value !== value)?.map((item) => item.value)
  } catch (error) {
    filtersId = []
  }

  return filtersId
}

export const getMainFilters = (mainOptionFilter = [], filterValue = []) => {
  let filtersId = []
  try {
    if (mainOptionFilter) {
      filterValue.forEach((value) => {
        const optionWithFiltersId = mainOptionFilter.find((item) => item.value === value && !isEmpty(item?.filtersId))
        if (!isEmpty(optionWithFiltersId?.filtersId)) {
          filtersId = filtersId.concat(optionWithFiltersId.filtersId)
        }
      })
    }
  } catch (error) {
    filtersId = []
  }

  return filtersId
}

// FILTRATION
/**
 * Filtration and pagination are done on the BE side.
 * We are building custom dropdown options because business states we need to show items that will be in the table
 * BE is returning a dictionary with the items so we are using that to remap
 * We are also normalizing some of the filter names because of BE requirement
 * Value of the toggle switch (onlyVariance) is also sent as a filter because on the BE it behaves as a table filter
 */
const getOptions = (name, varianceData) => {
  let list = []
  if (!isEmpty(varianceData)) {
    list = Object.values(varianceData.termData)
      .concat(Object.values(varianceData.informationalElementsData), Object.values(varianceData.additionalData))
      .map((e) => {
        const elementName = getTermName({
          companyLocalName: e.companyLocalName,
          countryLocalName: e.countryLocalName,
          globalName: e.globalName,
          showElementCode: true,
          elementCode: e.elementCode,
        })
        // for informational elements and additional data, BE requires the object key to be the value payload for the filters
        const elementKey =
          Object.keys(varianceData.informationalElementsData).find((key) => varianceData.informationalElementsData[key] === e) ||
          Object.keys(varianceData.additionalData).find((key) => varianceData.additionalData[key] === e)

        return { label: e[name] || elementName || e.name, value: e.id || elementKey, ...e }
      })
  }

  const optionsConfig = {
    category: () =>
      list
        .map((e) => ({ label: e.label, value: e.categoryId || e.value, title: e.label }))
        .sort((a, b) => elementSortingOrder.indexOf(a.label) - elementSortingOrder.indexOf(b.label)),
    subcategory: () => {
      const subCategoryMappedList = list.map((e) => ({
        label: e.label,
        value: e.subcategoryId || e.value,
        title: e.label,
      }))
      return subCategoryMappedList
        .map((item) => ({
          ...item,
          filtersId: getFiltersId(item.value, item.label, subCategoryMappedList),
        }))
        .sort(sortByLabel)
    },
    elementName: () =>
      list
        .map((e) => ({
          ...e,
          title: e.label,
          filtersId: getFiltersId(e.value, e.label, list),
        }))
        .sort(sortByLabel),
    format: () =>
      filterByUniq(list)
        .sort(sortByLabel)
        .map((e) => ({ label: e.label, value: e.label, title: e.label })),
    previous: () => zeroComparisonOptions,
    v1: () => zeroComparisonOptions,
    v2: () => zeroComparisonOptions,
  }

  const options = optionsConfig[name] && !isEmpty(varianceData) ? sortedUniqBy(optionsConfig[name](), (o) => o.value) : []
  return options
}

const getHeadingForOption = (option, versionData) => {
  if (!option) return ''
  const data = versionData.find((v) => v.id === option.value)
  if (data) return `${data.type} v${data.version}`
  return ''
}

export const getMainOptions = (varianceData) => ({
  [reconciliationAccesors.subcategory]: getOptions(reconciliationAccesors.subcategory, varianceData),
  [reconciliationAccesors.elementName]: getOptions(reconciliationAccesors.elementName, varianceData),
})

export const generatePayrollReconciliationTableHeading = ({ varianceData, versionData, defaultOptionRight, defaultOptionLeft, mainOptions }) => [
  new TableHeading({
    accessor: reconciliationAccesors.category,
    heading: i18n.t('Global:Global.text.category'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    disableFilters: false,
    Cell: versioningCellFormatting,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'category',
      multi: true,
      options: getOptions('category', varianceData),
      className: 'custom-select-elipsis',
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.subcategory,
    heading: i18n.t('Global:Global.text.subcategory'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    Cell: versioningCellFormatting,
    disableFilters: false,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'subcategory',
      multi: true,
      options: filterByUniq(mainOptions[reconciliationAccesors.subcategory]),
      className: 'custom-select-elipsis',
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.elementName,
    heading: i18n.t('Global:Global.text.element'),
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    Cell: versioningElementName,
    disableFilters: false,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'elementName',
      multi: true,
      options: filterByUniq(mainOptions[reconciliationAccesors.elementName]),
      className: 'custom-select-elipsis',
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.format,
    heading: i18n.t('Global:Global.text.data_format'),
    className: 'text-center',
    columnClassName: 'text-center',
    disableSortBy: false,
    disableFilters: false,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'format',
      multi: true,
      options: getOptions('format', varianceData),
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.previous,
    heading: i18n.t('Payrun:Payrun.tableHeadings.previousPayrun'),
    className: 'text-right',
    columnClassName: 'text-right',
    disableSortBy: false,
    Cell: versioningCellFormatting,
    disableFilters: false,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'previous',
      options: getOptions('previous', varianceData),
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.v1,
    heading: getHeadingForOption(defaultOptionLeft, versionData),
    className: 'text-right',
    columnClassName: 'text-right',
    disableSortBy: !getHeadingForOption(defaultOptionLeft, versionData),
    Cell: versioningCellFormatting,
    disableFilters: false,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'v1',
      options: getOptions('v1', varianceData),
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.v2,
    heading: getHeadingForOption(defaultOptionRight, versionData),
    className: 'text-right',
    columnClassName: 'text-right',
    disableSortBy: !getHeadingForOption(defaultOptionRight, versionData),
    Cell: versioningCellFormatting,
    disableFilters: false,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'v2',
      options: getOptions('v2', varianceData),
    },
  }),
  new TableHeading({
    accessor: reconciliationAccesors.variance,
    heading: i18n.t('Payrun:Payrun.tableHeadings.variance'),
    className: 'text-right',
    columnClassName: 'text-right',
    disableSortBy: false,
    Cell: varianceHover,
  }),
  new TableHeading({
    accessor: reconciliationAccesors.varianceP,
    heading: `${i18n.t('Payrun:Payrun.tableHeadings.variance')} %`,
    className: 'text-right',
    columnClassName: 'text-right',
    disableSortBy: false,
    Cell: variancePercentageHover,
  }),
  new TableHeading({
    accessor: reconciliationAccesors.result,
    heading: i18n.t('Global:Global.text.result'),
    className: 'text-center',
    columnClassName: 'text-center',
    disableSortBy: false,
  }),
  new TableHeading({
    id: 'note',
    expander: true,
    setWidth: '150px',
    heading: i18n.t('Payrun:Payrun.commentary_headings.comments'),
    editable: true,
    inputType: 'textArea',
    focusableIndex: 0,
    charactersPerLine: 200,
    placeholder: i18n.t('Payrun.tableHeadings.commentPlaceholder'),
    Cell: ({ row }) => {
      const hasComment = !isEmpty(row?.original?.note)
      return row.canExpand ? (
        <div className='d-flex jc--center ai-center'>
          <CommentIcon className='u-relative' stroke='var(--ps-color-alternative)'>
            {hasComment && <span className='c-notification__indicator u-bg--supernova' />}
          </CommentIcon>
        </div>
      ) : null
    },
  }),
]
