import React from 'react'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default function PasswordPolicyMessage () {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div>
      <p className='u-text--normal'>
        <b>{t('Global:Global.text.note')}</b>: <i>{t('BusinessComponent.alert.securityReminder')}</i>
      </p>
      <p className='u-text--normal'>{t('BusinessComponent.alert.changePasswordPrompt')}</p>
    </div>
  )
}
