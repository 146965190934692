import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import AmountFormat from 'components/AmountFormat'
import Dropzone from 'react-dropzone'
import { trimString } from 'utils/strings'
import MobileDetailsView from 'components/MobileDetailsView'
import PayslipTable from 'components/table/PayslipTable'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { i18nNameSpaces } from 'i18n/types'

const PayslipsList = ({ isFetching, downloadFile, uploadFile, isCot, employmentStatus, isEmployeeOnly, payslipsFetched, payslips }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const [instanceId, setInstanceId] = useState(null)
  let dropzoneRef = null

  const onUpload = (id) => {
    setInstanceId(id)
    dropzoneRef.open()
  }

  const renderRows = () => {
    if (isEmpty(payslips)) return []
    return payslips.data.map((payslip) => ({
      payrollName: payslip.payrollName,
      fromDate: moment(payslip.fromDate.date).format('DD/MM/YYYY'),
      toDate: moment(payslip.toDate.date).format('DD/MM/YYYY'),
      grossPay: <AmountFormat amount={payslip.grossPay} />,
      netPay: <AmountFormat amount={payslip.netPay} />,
      payDate: moment(payslip.payDate.date).format('DD/MM/YYYY'),
      payrollpayslipsName: (
        <div>
          <button
            className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
            title={payslip.hasPayslipFile ? t('Global:Global.text.download') : t('Employee.button.not_uploaded_yet')}
            disabled={!payslip.hasPayslipFile}
            onClick={() => downloadFile(payslip.id)}
            data-testid={`payslip-${payslip.id}-download`}
          >
            <span className='icon icon--download' />
          </button>
          <Authorization permissions={['PAYSLIP_IMPORT']}>
            {isCot && (
              <button
                type='button'
                onClick={() => onUpload(payslip.id)}
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
                title={t('Global:Global.text.upload')}
              >
                <span className='icon icon--upload' />
              </button>
            )}
          </Authorization>
        </div>
      ),
    }))
  }

  const renderPayslipDetails = (payslip) => {
    const { fromDate, toDate, grossPay, netPay } = payslip
    return [
      { 'From Date': moment(fromDate.date).format('DD/MM/YYYY') },
      { 'To Date': moment(toDate.date).format('DD/MM/YYYY') },
      { 'Gross Pay': <AmountFormat amount={grossPay} key={payslip.id} /> },
      { 'Net Pay': <AmountFormat amount={netPay} key={payslip.id} /> },
    ]
  }

  const contractorChangeTitleStatuses = ['contractorcompany', 'contractoragency', 'contractorindividual']
  const isContractorChangeTitleStatus = employmentStatus && contractorChangeTitleStatuses.includes(trimString(employmentStatus))
  const sectionHeading = isContractorChangeTitleStatus ? t('Global:Global.text.invoices') : t('Global:Global.text.payslips')
  const headingPayslips = isContractorChangeTitleStatus ? t('Global:Global.text.invoices') : t('Global:Global.text.payroll')
  const headingDocuments = isContractorChangeTitleStatus ? t('Employee.subheader.my_invoices') : t('Employee.subheader.my_payslips')

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <SectionHeading text={`${sectionHeading}`} />
        <Dropzone
          onDrop={(files) => uploadFile(instanceId, files[0])}
          ref={(node) => {
            dropzoneRef = node
          }}
          className='u-relative'
        />
        <PayslipTable
          proportionsClassName='u-1/1'
          tableClassName='o-table--tiny o-table--striped'
          headings={[
            {
              Header: `${headingPayslips}`,
              accessor: 'payrollName',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: t('Global:Global.text.fromDate', {
                requiredSymbol: '',
              }),
              accessor: 'fromDate',
              setWidth: '220px',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: t('Global:Global.text.toDate', {
                requiredSymbol: '',
              }),
              accessor: 'toDate',
              setWidth: '220px',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: t('Employee.payroll_updates_list_tableHeadings.payDate'),
              accessor: 'payDate',
              setWidth: '220px',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: t('Employee.table.column.gross_pay'),
              accessor: 'grossPay',
              setWidth: '150px',
              classNames: 'text-center',
              columnClassName: 'text-center',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: t('Employee.table.column.net_pay'),
              accessor: 'netPay',
              setWidth: '150px',
              classNames: 'text-center',
              columnClassName: 'text-center',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: `${headingDocuments}`,
              accessor: 'payrollpayslipsName',
              classNames: 'text-right',
              columnClassName: 'text-right',
              setWidth: '200px',
              disableSortBy: true,
              disableFilters: true,
            },
          ]}
          data={renderRows()}
        />
      </div>
      {isEmployeeOnly && (
        <div className='u-hide-on-desktop'>
          <h1 className='u-text--huge u-weight--bold'>{headingDocuments}</h1>
          {!isEmpty(payslips) &&
            payslips.data?.map((payslip) => (
              <AccordionContainer
                key={payslip.id}
                rounded
                title={payslip.payrollName}
                titleColor='minsk'
                priority={3}
                subtitleKey={t('Employee.mobile.key.pay_date', {
                  colonSymbol: ':',
                })}
                subtitle={moment(payslip.payDate.date).format('DD/MM/YYYY')}
                mobileView
                fullWidth
                actionButtons={
                  <button
                    className='c-btn c-btn--small c-btn--curious u-margin-right-tiny rounded'
                    disabled={!payslip.hasPayslipFile}
                    onClick={() => downloadFile(payslip.id)}
                    data-testid={`payslip-mobile-${payslip.id}-download`}
                  >
                    <span className='icon icon--download' />
                  </button>
                }
              >
                <MobileDetailsView rows={renderPayslipDetails(payslip)} />
              </AccordionContainer>
            ))}
          {isEmpty(payslips) && payslipsFetched && (
            <div className='mobile-no-data-message--centered'>
              <span>{t('Global:Global.text.no_files')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

PayslipsList.propTypes = {
  isFetching: PropTypes.bool,
  downloadFile: PropTypes.func,
  uploadFile: PropTypes.func,
  isCot: PropTypes.bool,
  employmentStatus: PropTypes.string,
  isEmployeeOnly: PropTypes.bool,
  payslipsFetched: PropTypes.bool,
  payslips: PropTypes.object,
}

export default PayslipsList
