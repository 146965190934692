import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const ProcessCreate = (props) => {
  const { services } = props
  const { t } = useTranslation([i18nNameSpaces.Process])

  return (
    <div>
      <SectionHeading text={t('Process.heading.create_process')} />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <Form showSubmitBtn btnText={t('Global:Global.button.submit')} {...props}>
            <div className='o-form-group'>
              <Field name='name' label={t('Global:Global.text.name')} component={InputField} type='text' className='c-input' labelClassName='c-label' />
            </div>
            <div className='o-form-group'>
              <Field
                name='keyEvent'
                label={t('Global:Global.form.key_event')}
                component={InputField}
                type='text'
                className='c-input'
                labelClassName='c-label'
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='service'
                label={t('Global:Global.text.service')}
                component={CustomSelectField}
                labelClassName='c-label'
                placeholder={t('Global:Global.form.select_service')}
                clearable={false}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
ProcessCreate.propTypes = {
  services: PropTypes.array.isRequired,
}
export default reduxForm({
  form: 'process',
})(ProcessCreate)
