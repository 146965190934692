import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CommentIcon } from 'components/icons/CommentIcon'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-right-small'

const CommentaryControlBtns = ({ onToggleViewComments, hasAllRowsExpanded, onCancel, onSave, onEdit, isEdit }) => {
  const { t } = useTranslation()
  const hasAccess = useSelector((state) => !isFinanceAdmin(state) && !isAccounting(state))
  const showEditButton = hasAllRowsExpanded && hasAccess

  return (
    <>
      {/* TOGGLE COMMENTS BTN */}
      <button className={`${btnClasses} d-flex u-margin-right-small`} onClick={onToggleViewComments}>
        <CommentIcon className='u-margin-right-small' />
        {hasAllRowsExpanded ? t('Global.button.closeComments') : t('Global.button.viewComments')}
      </button>
      {/* EDIT BTN */}
      {showEditButton && (
        <button
          onClick={onEdit}
          className={`${btnClasses} u-margin-right-small`}
          title={t('Global:Global.text.edit')}
          data-testid='versioning-comments-edit'
          disabled={isEdit}
        >
          <span className='icon icon--edit' />
        </button>
      )}
      {isEdit && (
        <>
          {/* CANCEL BTN */}
          <button onClick={onCancel} className={btnClasses} title={t('Global.button.cancel')} data-testid='versioning-cancel'>
            {t('Global.button.cancel')}
          </button>
          {/* SAVE BTN */}
          <button onClick={onSave} className={btnClasses} title={t('Global.button.save')} data-testid='versioning-save'>
            {t('Global.button.save')}
          </button>
        </>
      )}
    </>
  )
}

export default CommentaryControlBtns
