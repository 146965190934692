import { ActionUtility } from 'utils/redux'
import { dateTransformer, normalizeDate } from 'redux/transformers/dateTransformer'
import { amountsTransformer } from 'redux/transformers/amountsTransformer'
import { percentageTransformer } from 'redux/transformers/percentageTransformer'
import { getFieldsKeyByPrefix } from 'redux/helpers/form'
import { invalidateBankAccount } from 'redux/actions/bankAccounts'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { resetPayfilesInitials } from './payfiles'
import { resetPayfileChangesInitials } from './payfileChanges'
import _, { isArray, isEmpty, omit } from 'lodash'
import moment from 'moment'
import { createFilter } from 'utils/redux/filter'
import { ACCEPTED_FORMAT_VALUES, ACCEPTED_TYPE_VALUES } from 'schemas/employeeFieldsSchema'
import getAddressFields from 'redux/config/employeeAddressFieldsConfig'
import { createNewEmployeeUserAddress } from './employeeUserAddresses'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { transformStringValueToBoolean } from 'redux/transformers/booleanTransformer'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEE_SYSTEM_USER_FETCH = 'EMPLOYEE_SYSTEM_USER_FETCH'
export const EMPLOYEE_SYSTEM_USER_RECEIVE = 'EMPLOYEE_SYSTEM_USER_RECEIVE'
export const EMPLOYEE_SYSTEM_USER_UPDATE = 'EMPLOYEE_SYSTEM_USER_UPDATE'
export const EMPLOYEE_SYSTEM_USER_CREATE = 'EMPLOYEE_SYSTEM_USER_CREATE'
export const EMPLOYEE_SYSTEM_USER_INVALIDATE = 'EMPLOYEE_SYSTEM_USER_INVALIDATE'
export const EMPLOYEE_SYSTEM_USER_TERMS_SYNC = 'EMPLOYEE_SYSTEM_USER_TERMS_SYNC'
export const EMPLOYEE_SYSTEM_USER_TERMS_MERGE = 'EMPLOYEE_SYSTEM_USER_TERMS_MERGE'
export const EMPLOYEE_SYSTEM_USER_TERMS_DETACH = 'EMPLOYEE_SYSTEM_USER_TERMS_DETACH'
export const EMPLOYEE_SYSTEM_USER_TERMS_ATTACH = 'EMPLOYEE_SYSTEM_USER_TERMS_ATTACH'
export const EMPLOYEE_SYSTEM_USER_FILTER = 'EMPLOYEE_SYSTEM_USER_FILTER'
export const EMPLOYEE_SYSTEM_USER_FILTERED = 'EMPLOYEE_SYSTEM_USER_FILTERED'
export const EMPLOYEE_SYSTEM_USER_RESET_FILTERS = 'EMPLOYEE_SYSTEM_USER_RESET_FILTERS'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEE_SYSTEM_USER_FETCH,
  receive: EMPLOYEE_SYSTEM_USER_RECEIVE,
  update: EMPLOYEE_SYSTEM_USER_UPDATE,
  create: EMPLOYEE_SYSTEM_USER_CREATE,
  invalidate: EMPLOYEE_SYSTEM_USER_INVALIDATE,
  syncTerms: EMPLOYEE_SYSTEM_USER_TERMS_SYNC,
  mergeTerms: EMPLOYEE_SYSTEM_USER_TERMS_MERGE,
  detachTerms: EMPLOYEE_SYSTEM_USER_TERMS_DETACH,
  attachTerms: EMPLOYEE_SYSTEM_USER_TERMS_ATTACH,
  filter: EMPLOYEE_SYSTEM_USER_FILTER,
  filtered: EMPLOYEE_SYSTEM_USER_FILTERED,
  resetFilters: EMPLOYEE_SYSTEM_USER_RESET_FILTERS,
}
const actionUtility = new ActionUtility(actionTypes, 'employeeSystemUsers', 'employeeusers', 'EmployeeSystemUser')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEmployeeSystemUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEmployeeSystemUsers = actionUtility.fetchEntities
export const invalidateEmployeeSystemUsers = actionUtility.invalidate
export const resetEmployeeSystemUsersFilters = actionUtility.resetEntityFilters
export const downloadTemplate = () => actionUtility.downloadEntity({ childUri: 'bulkimportcreate' })
export const importEmployeesCreate = (file) => (dispatch) =>
  dispatch(
    actionUtility.uploadEntity({
      file,
      childUri: 'bulkimportcreate',
      shouldHandleErrors: false,
    })
  ).then((response) => {
    dispatch(invalidateEmployeeSystemUsers())

    return response
  })

const buildTemplateParams = (payrollId, companyId) => {
  let params

  // If we send a `companyId` param we're going to download a template for all the employees in that company.
  // Otherwise we're getting a template for specific `payrollId`
  if (companyId) {
    params = `company=${companyId}`
  } else {
    params = `payroll=${payrollId}`
  }

  return params
}

const buildImportData = (payroll, company) => {
  // If we send a `companyId` param we're going to import employees for that company.
  // Otherwise we're going to import for specific `payrollId` only.
  return { ...(company ? { company } : { payroll }) }
}

const formatNumberOfChildren = (children) => {
  if (children !== '') return parseInt(children, 10)

  return 0
}

export const downloadTemplateUpdate = (payrollId, companyId) =>
  actionUtility.downloadEntity({ childUri: `bulkimportupdate?${buildTemplateParams(payrollId, companyId)}` })
export const importEmployeesUpdate = (payroll, company, file) => (dispatch) =>
  dispatch(
    actionUtility.uploadEntity({
      file,
      childUri: 'bulkimportupdate',
      shouldHandleErrors: false,
      data: buildImportData(payroll, company),
    })
  ).then((response) => {
    dispatch(invalidateEmployeeSystemUsers())
    dispatch(invalidateBankAccount())

    return response
  })

export const downloadBankAccountsTemplate = (payrollId, companyId) =>
  actionUtility.downloadEntity({ childUri: `bulkimportbankaccounts?${buildTemplateParams(payrollId, companyId)}` })
export const importBankAccounts = (payroll, company, file) => (dispatch) =>
  dispatch(
    actionUtility.uploadEntity({
      file,
      childUri: 'bulkimportbankaccounts',
      shouldHandleErrors: false,
      data: buildImportData(payroll, company),
    })
  ).then((response) => {
    dispatch(invalidateEmployeeSystemUsers())
    dispatch(invalidateBankAccount())

    return response
  })
export const download = (fullUrl) => actionUtility.downloadEntity({ fullUrl })

const handleFormattingExtraFields = (fields, data) => {
  const { properties } = fields

  Object.keys(data.extraFields).forEach((f) => {
    if (data.extraFields[f]) {
      const { type, format } = properties[f]
      let usedType = isArray(type) ? type.find((t) => Object.values(ACCEPTED_TYPE_VALUES).includes(t)) : type
      switch (usedType) {
      case ACCEPTED_TYPE_VALUES.NUMBER:
        data.extraFields[f] = parseFloat(data.extraFields[f])
        break
      case ACCEPTED_TYPE_VALUES.INTEGER:
        data.extraFields[f] = parseInt(data.extraFields[f], 10)
        break
      default:
        break
      }
      switch (format) {
      case ACCEPTED_FORMAT_VALUES.DATE:
        data.extraFields[f] = moment(data.extraFields[f], 'DD/MM/YYYY').format('YYYY-MM-DD')
        break
      }
    } else {
      if (properties[f].type) {
        if (properties[f].type === ACCEPTED_TYPE_VALUES.STRING) {
          data.extraFields[f] = ''
          if (properties[f].format && properties[f].format === ACCEPTED_FORMAT_VALUES.DATE) {
            data.extraFields[f] = ''
          }
        } else {
          data.extraFields[f] = null
        }
      } else {
        data.extraFields[f] = null
      }
    }
  })
  return data
}

const exclusions = [...getAddressFields().map((f) => f.field)]
export const createEmployeeSystemUser = (entity, fields, skipAddressCreate = false) => {
  const dateFields = ['dateOfBirth', 'startDate', 'originalHireDate', 'endDate', 'effectiveDate', 'dateIssue', 'confirmationDate', 'expiryDate']
  // For the next extra addresses, the BE call to update employee users
  // Does not expect to have the fields as they are named
  // So we extract them to handle the call further down with updates the extra addresses
  let addressFields = {}
  if (!skipAddressCreate) {
    Object.keys(entity).forEach((key) => {
      if (exclusions.includes(key)) {
        addressFields[key] = entity[key]
      }
    })
    if (isEmpty(addressFields)) {
      skipAddressCreate = true
    }
    entity = omit(entity, exclusions)
  }

  // set valid date
  let data = dateTransformer(entity, [
    'dateOfBirth',
    'startDate',
    'originalHireDate',
    'endDate',
    'effectiveDate',
    'dateIssue',
    'confirmationDate',
    'expiryDate',
  ])

  // Transform countries property value to array
  // when updating employee
  if (typeof data.countries === 'number') data.countries = [data.countries]

  // BE expects to be Number
  if (data.weeklyHours) {
    data.weeklyHours = parseFloat(data.weeklyHours)
  } else if (data.weeklyHours === '') {
    data.weeklyHours = null
  }

  dateFields.forEach((field) => {
    if (entity[field] === '') {
      entity[field] = null
    }
  })

  // BE expects to be Number
  if (data.monthlyHours) {
    data.monthlyHours = parseFloat(data.monthlyHours)
  } else if (data.monthlyHours === '') {
    data.monthlyHours = null
  }

  // BE expects to be Number
  if (data.numberOfChildren) {
    data.numberOfChildren = formatNumberOfChildren(data.numberOfChildren)
  } else if (data.numberOfChildren === '') {
    data.numberOfChildren = null
  }

  // BE expects boolean value
  if (data.hasCredentials) {
    let stringValue = data.hasCredentials.toString()
    data.hasCredentials = stringValue === 'true'
  }

  if (data.isSSOEnabled) {
    let stringValue = data.isSSOEnabled.toString()
    data.isSSOEnabled = stringValue === 'true'
  }

  // set valid amount
  const fixedTermsAmounts = getFieldsKeyByPrefix(entity, 'fixed-term-')
  data = amountsTransformer(data, [...fixedTermsAmounts, 'annualSalaryValue'])

  // Percentage transformer
  let percentageFields = ['employeePensionContributionsPercentage', 'employerPensionContributionsPercentage']
  data = percentageTransformer(data, percentageFields)

  // Build annual salary object
  if (data.annualSalaryValue) {
    data['annualSalary'] = {
      value: data.annualSalaryValue,
      effectiveDate: data.effectiveDate,
      reason: data.reason,
    }
  }

  // Make sure to remove/sanitize empty Deductions values (fields names, starting with 'deduction-').
  // Here's the use case, how this can occur as issue:
  // 1. We are opening the "Edit Recurring Deductions" modal.
  // 2. One of the Deductions, doesn't have any values.
  // 3. We decide to fill all the values (fromDate, toDate, value). All's good!
  // 4. But now - we change our mind and remove all the values.
  // 5. Now trying to submit the Form, the nullified Deduction will be still kept in the Store, having Null values
  // for Dates and Value fields.
  // 6. Because of 5., we should remove such empty Deductions from the request sent to the BE API.
  // Note: It's pretty enough to make the validation only by `validFrom` field.
  // That's correct, because `redux-form` validation will allow submitting the Deductions:
  // - either all the Deduction fields are filled (fromDate, toDate, value) and we won't do this sanitize process;
  // - or all the Deductions values are empty/removed, therefore if `validForm` is empty, then the rest values will be
  // empty too;

  Object.keys(data).forEach((key) => {
    if (_.startsWith(key, 'deduction-')) {
      const values = key.split('-')
      const objName = values[values.length - 1]
      values.pop()
      const newKey = values.join('-')
      data[newKey] = { [objName]: data[key], ...data[newKey] }
      delete data[key]
    }
  })

  if (data.extraFields) {
    data = handleFormattingExtraFields(fields, data)
  }

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(data, { shouldFetch: false, shouldInvalidate: false }))
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        // For the editing of KU employee profile, we want to skip the creation of the extra fields all together
        if (!skipAddressCreate) {
          addressFields['employeeUser'] = response.id
          dispatch(createNewEmployeeUserAddress(addressFields, false))
        }
        dispatch(mergeTerms(response.id, data, { shouldFetch: false, shouldInvalidate: false }))
        return response
      })
  }
}

/**
 * Update employee system user
 *
 * @param {Object} entity - the collected data
 * @param {Object} params - {
 * `dateFields` - here we pass the date fields which need to be transformed for the current action.
 * In case we do not pass date fields, there will be transformed all the default date fields.
 *
 * `shouldFetch` - Should fetch the entity, after the update. Please check `actionUtility.updateEntity`
 * doc for further details.
 * }
 *
 * @returns {Dispatch}
 */
export const updateEmployeeSystemUser = (entity, params = {}, shouldBuildAnnualSalary = false) => {
  const {
    dateFields = ['dateOfBirth', 'startDate', 'originalHireDate', 'endDate', 'effectiveDate', 'dateIssue', 'confirmationDate', 'expiryDate'],

    shouldFetch = true,
    shouldInvalidate = false,
    returnInlineErrors,
    fields = {},
  } = params

  dateFields.forEach((field) => {
    if (entity[field] === '') {
      entity[field] = null
    }
  })

  // Check for dates in entity and set valid date
  // * Without this check dates are sent as null
  let dateFieldsToTransform = dateFields.filter((field) => entity.hasOwnProperty(field))
  let data = dateTransformer(entity, dateFieldsToTransform)

  // Percentage transformer
  let percentageFields = ['employeePensionContributionsPercentage', 'employerPensionContributionsPercentage']
  data = percentageTransformer(data, percentageFields)

  // Transform countries property value to array
  // when updating employee
  if (typeof data.countries === 'number') data.countries = [data.countries]

  // set valid amount
  let fixedTermsAmounts = getFieldsKeyByPrefix(entity, 'fixed-term-')
  data = entity.annualSalaryValue ? amountsTransformer(data, [...fixedTermsAmounts, 'annualSalaryValue']) : data

  const id = data.id
  data = _.omit(data, ['id'])

  // set employment status
  const EmployeeStatus = new EmploymentStatusHelper(data.employmentStatus)

  // BE expects to be Number
  if (data.weeklyHours) {
    data.weeklyHours = parseFloat(data.weeklyHours)
  } else if (data.weeklyHours === '') {
    data.weeklyHours = null
  }

  if (data.contractorAddressCountry === '') {
    data.contractorAddressCountry = null
  }

  // BE expects to be Number
  if (data.monthlyHours) {
    data.monthlyHours = parseFloat(data.monthlyHours)
  } else if (data.monthlyHours === '') {
    data.monthlyHours = null
  }

  // BE expects to be Number
  if (data.numberOfChildren) {
    data.numberOfChildren = formatNumberOfChildren(data.numberOfChildren)
  } else if (data.numberOfChildren === '') {
    data.numberOfChildren = null
  }

  // BE expects a number or null
  if (data.age) {
    data.age = parseInt(data.age, 10)
  } else if (data.age === '') {
    data.age = null
  }

  // BE expects Number or null value
  // Send uniqueContractorId field with null value, if employment status is different than the ones,
  // which let the field to be visible on tab P&T or if uniqueContractorId field is set to empty value(empty string)
  if (EmployeeStatus.shouldShowUniqueContractorIdArea && data.uniqueContractorId === '') {
    data.uniqueContractorId = null
  }

  if (!EmployeeStatus.shouldShowUniqueContractorIdArea) {
    const { uniqueContractorId, ...rest } = data
    data = rest
  }

  // Build annual salary object
  if (shouldBuildAnnualSalary) {
    data['annualSalary'] = {
      value: data.annualSalaryValue,
      effectiveDate: data.effectiveDate,
      reason: data.reason,
      inherit: data.inherit,
    }
  }

  data['countryOfBirth'] = data.countryOfBirth !== '' ? data.countryOfBirth : null
  data['paymentType'] = data.paymentType !== '' ? data.paymentType : null

  // BE expects boolean value
  if (data.hasCredentials) {
    data.hasCredentials = transformStringValueToBoolean(data.hasCredentials, 'Active')
  }

  if (data.isSSOEnabled) {
    data.isSSOEnabled = transformStringValueToBoolean(data.isSSOEnabled, 'Active')
  }

  if (data.hasMoved) {
    data.hasMoved = transformStringValueToBoolean(data.hasMoved, 'yes')
  }

  // Send invoicePaymentCurrency field with null value, if employment
  // status is different than the ones, which let the field to be visible on tab P&T
  if (EmployeeStatus.shouldShowInvoicePaymentCurrencyArea && data.uniqueContractorId === '') {
    data.invoicePaymentCurrency = null
  }

  if (!EmployeeStatus.shouldShowInvoicePaymentCurrencyArea) {
    const { invoicePaymentCurrency, ...rest } = data
    data = rest
  }

  Object.keys(data).forEach((key) => {
    if (key.indexOf('.') === -1) return
    const values = key.split('.')
    const objName = values[values.length - 1]
    values.pop()
    const newKey = values.join('-')
    data[newKey] = { [objName]: data[key], ...data[newKey] }
    delete data[key]
  })

  if (data.extraFields) {
    data = handleFormattingExtraFields(fields, data)
  }

  return (dispatch) => {
    return dispatch(actionUtility.updateEntity(data, id, shouldFetch, shouldInvalidate, returnInlineErrors))
  }
}

/**
 * Update employee system user phone number
 *
 * @param {Object} entity - the collected data
 * @param {Object} params - {
 * `shouldFetch` - Should fetch the entity, after the update. Please check `actionUtility.updateEntity`
 * doc for further details.
 * }
 *
 * @returns {Dispatch}
 */
export const updateEmployeeSystemUserPhone = (entity) => {
  let data = _.omit(entity, ['id'])

  return (dispatch) => {
    return dispatch(actionUtility.updateEntity(data, entity.id))
  }
}

export const updateEmployeeEmergencyPerson = actionUtility.updateEntity
export const updateEmployeeContractorDetails = actionUtility.updateEntity

const mergeTermsAction = (entity) => ({
  type: actionTypes.mergeTerms,
  payload: entity,
})

/**
 * Send an api request to merge terms to employee
 */
export const mergeTerms = (employeeId, entity, params = {}) => {
  // Set valid amount
  const fixedTermsAmounts = getFieldsKeyByPrefix(entity, 'fixed-term-')
  const deductionsAmounts = getFieldsKeyByPrefix(entity, 'deduction-')

  let data = amountsTransformer(entity, fixedTermsAmounts)
  data = amountsTransformer(data, deductionsAmounts, 'amount')

  // Set valid dates
  data = dateTransformer(data, deductionsAmounts, 'validFrom')
  data = dateTransformer(data, deductionsAmounts, 'validTo')

  const normalized = Object.keys(data).reduce(
    (accumulator, key) => {
      if (_.startsWith(key, 'fixed-term-')) {
        const field = _.replace(key, /fixed-term-/g, '')
        accumulator.data[field] = {
          amount: data[key],
          ...accumulator.data[field],
        }
      } else if (_.startsWith(key, 'valid-from-fixed-term-')) {
        const field = key.replace('valid-from-fixed-term-', '')
        if (data[key]) {
          accumulator.data[field] = {
            validFrom: normalizeDate(data[key]),
            ...accumulator.data[field],
          }
        }
      } else if (_.startsWith(key, 'valid-to-fixed-term-')) {
        const field = key.replace('valid-to-fixed-term-', '')
        if (data[key]) {
          accumulator.data[field] = {
            validTo: normalizeDate(data[key]),
            ...accumulator.data[field],
          }
        }
      } else if (_.startsWith(key, 'variable-term-') && data[key]) {
        const field = _.replace(key, /variable-term-/g, '')

        accumulator.data[field] = {}
      } else if (_.startsWith(key, 'deduction-')) {
        const field = _.replace(key, /deduction-/g, '')

        const { validFrom, validTo, amount } = data[key]
        if (amount && validFrom) {
          accumulator.data[field] = { validFrom, validTo, amount }
        }
      }

      return accumulator
    },
    { data: {} }
  )

  return (dispatch) => {
    return dispatch(
      actionUtility.mergeEntities({
        ...params,
        entity: normalized,
        id: employeeId,
        childUri: 'companycountryterms',
        actionFunc: mergeTermsAction,
        handleErrors: errorsHandling.handleNestedNamedPivotFormErrors,
        fieldPrefix: 'deduction',
      })
    ).then(() => {
      dispatch(resetPayfileChangesInitials())
      dispatch(resetPayfilesInitials())
    })
  }
}

const attachTermsAction = (entity) => ({
  type: actionTypes.attachTerms,
  payload: entity,
})

/**
 * Send an api request to attach terms to employee
 */
export const attachTerms = (employeeId, entity, params = {}) => {
  // Set valid amount
  return (dispatch) => {
    return dispatch(
      actionUtility.attachEntities({
        ...params,
        entity: entity,
        id: employeeId,
        childUri: 'companycountryterms',
        actionFunc: attachTermsAction,
        handleErrors: errorsHandling.handleNestedNamedPivotFormErrors,
        fieldPrefix: 'deduction',
        returnInlineErrors: true,
      })
    )
  }
}

export const detachTerms = (employeeId, entity, params = {}) => {
  const normalized = Object.keys(entity).reduce((accumulator, key) => {
    const field = _.replace(key, /variable-term-/g, '')

    accumulator[field] = entity[key]

    return accumulator
  }, {})

  return (dispatch) => {
    return dispatch(
      actionUtility.detachEntities({
        ...params,
        entity: { data: normalized },
        id: employeeId,
        childUri: 'companycountryterms',
        actionFunc: mergeTermsAction,
      })
    )
  }
}

// Update user SSO status
export const updateEmployeeUserSSO = (data) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.syncEntities({
        entity: { isSSOEnabled: data.isUserSSOEnabled },
        id: data.userId,
        childUri: `switchsso`,
        actionFunc: () => ({ type: 'UPDATE_USER_SSO' }),
      })
    )
      .then(errorsHandling.handleFormErrors)
      .then((res) => {
        dispatch(fetchEmployeeSystemUsers({ filter: createFilter({ id: data.userId }) }))
        return { ...res, isSSOEnabled: data.isUserSSOEnabled }
      })
      .catch((error) => {
        return error
      })
  }
}

export const getEmployeeSystemUsersLocation = () => {
  return async (dispatch, getState, { api }) => {
    return api.fetch('employeeusers/locations')
  }
}

// [GET] /employeeusers/<id>/payslips
export const fetchEmployeePayslips = ({ employeeId }) => {
  return async (dispatch, getState, { api }) => {
    return api.fetch(`employeeusers/${employeeId}/payslips`)
  }
}
