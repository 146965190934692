import React from 'react'
import EmployeeMobileNavItem from './EmployeeMobileNavItem'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default function EmployeeMobileNav (props) {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <nav>
      <ul id='mobile-side-menu'>
        <li className='mobile-side-menu-header u-text--center'>
          <div className='mobile-payslip-logo c-logo c-logo--negative--full o-block' />
          <span>{t('BusinessComponent.mobileSideMenu.header.logoAlt')}</span>
        </li>
        {props.routes.map((route) => (
          <EmployeeMobileNavItem key={route.title} item={route} />
        ))}
      </ul>
    </nav>
  )
}
