import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import CheckboxField from 'components/form/CheckboxField'
import PayslipTable from 'components/table/PayslipTable'
import FilterButton from '../buttons/FilterButton'
import { SelectColumnFilter } from '../table/filters'
import { getKURoleTypes } from '../../redux/config/keyPeopleRoleTypes'

const ShareWithinCompany = ({
  keyPeopleWithinCompanyUsers,
  initialValues,
  isOwner,
  sharingActionText,
  onFilter,
  isFetching,
  pagination,
  showFilters,
  setShowFilters,
  initialFilters,
  initialSort,
}) => {
  const { t } = useTranslation()

  const onSortOrPageChange = ({ pageIndex, pageSize, filters, sortBy }) => {
    const isChangedFilter = pageIndex === pagination?.currentPage
    const page = isChangedFilter ? 0 : pageIndex
    if (!isFetching) {
      let normalizedFilters = {}
      filters.forEach((filter) => {
        normalizedFilters[filter.id] = filter
      })
      onFilter({
        page,
        limit: pageSize,
        filters: normalizedFilters,
        sort: sortBy,
      })
    }
  }
  const options = {
    noDataText: t('Global.text.no_matching_records_found'),
    onPageChange: onSortOrPageChange,
    onSort: onSortOrPageChange,
    pageCount: pagination?.totalPages,
    pageIndex: pagination?.currentPage,
    pageSize: 25,
    filterDebounce: 800,
  }
  return (
    <div data-testid='withinCompany-tabBody' className='position-relative'>
      <div className='d-flex justify-content-end'>
        <FilterButton
          className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom-small d-flex justify-content-end'
          onClick={() => setShowFilters(!showFilters)}
        >
          {t('Global.text.toggle_filters')}
        </FilterButton>
      </div>
      <PayslipTable
        data={keyPeopleWithinCompanyUsers.map((user) => ({
          ...user,
          share: (
            <Field
              type='checkbox'
              labelClassName='u-hidden'
              component={CheckboxField}
              className='control__indicator__input'
              indicatorClassName='control__indicator--center'
              formGroupClassName='u-margin-none'
              name={`${user.schema}-${user.id}`}
              disabled={initialValues[`${user.schema}-${user.id}`] && !isOwner}
              data-testid={`share-checkbox-${user.schema}-${user.id}`}
            />
          ),
        }))}
        headings={[
          { accessor: 'name', Header: t('Global.text.name'), disableSortBy: false, disableFilters: false },
          {
            accessor: 'roleTypeName',
            Header: t('Global.text.role'),
            disableSortBy: false,
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'roleTypeName',
              initialValue: initialFilters?.find((filter) => filter.id === 'roleTypeName')?.value,
              options: getKURoleTypes().map((roleType) => ({ value: roleType.type, label: roleType.name })),
            },
          },
          {
            accessor: 'share',
            Header: sharingActionText || t('Global:Global.text.share'),
            setWidth: '100px',
            classNames: 'text-center',
            columnClassName: 'text-center u-padding-right-tiny',
            disableSortBy: true,
            disableFilters: true,
          },
        ]}
        wrapperClassName='u-1/1 u-margin-bottom'
        options={options}
        trClassName='react-bs-table__cell--whitespace--normal'
        showFilters={showFilters}
        dynamicValues
        pagination
        remote
        remoteSorting
        skipPageReset
        initialFilters={initialFilters}
        initialSort={initialSort}
      />
    </div>
  )
}

ShareWithinCompany.propTypes = {
  keyPeopleWithinCompanyUsers: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string,
  onFilter: PropTypes.func,
  isFetching: PropTypes.bool,
  pagination: PropTypes.object,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  initialFilters: PropTypes.array,
  initialSort: PropTypes.array,
}

export default ShareWithinCompany
