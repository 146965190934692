import { getReportOptionsConfig } from 'configs/reports'
import i18n from 'i18next'

const mandatoryReadOnly = {
  type: true,
  name: true,
  category: true,
  subcategory: true,
}
/**
 * All reports getLegacyReportTypes
 */
const reportOptionsConfig = getReportOptionsConfig()

export const getNewReportTypes = () => ({
  [reportOptionsConfig.GLOBAL_WORK_HEADCOUNT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'All',
    subcategory: 'All',
    employeeStatus: 'all',
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    hasPayrollNoneOption: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.GLOBAL_JOINERS_HEADCOUNT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'Joiners',
    subcategory: 'Joiners',
    employeeStatus: 'all',
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    hasPayrollNoneOption: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.GLOBAL_LEAVERS_HEADCOUNT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'Leavers',
    subcategory: 'Leavers',
    employeeStatus: 'all',
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    hasPayrollNoneOption: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
    [reportOptionsConfig.WORKFORCE_HEADCOUNT.VALUE]: {
      type: 'EmployeeReport',
      name: i18n.t('Report:Report.new_types_name.employee_headcount'),
      category: 'All',
      subcategory: 'All',
      employeeStatus: 'all',
      showOnlyTotals: true,
      allCountriesSelected: false,
      allCompaniesSelected: false,
      allPayrollsSelected: false,
      readonlyFieldsObj: {
        ...mandatoryReadOnly,
        employeeStatus: true,
        showOnlyTotals: true,
      },
    },
  },
  [reportOptionsConfig.JOINERS_HEADCOUNT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'Joiners',
    subcategory: 'Joiners',
    employeeStatus: 'all',
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.LEAVERS_HEADCOUNT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'Leavers',
    subcategory: 'Leavers',
    employeeStatus: 'all',
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.WORKFORCE_DETAIL.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'All',
    subcategory: 'All',
    employeeStatus: 'all',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.JOINERS_DETAIL.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'Joiners',
    subcategory: 'Joiners',
    employeeStatus: 'all',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.LEAVERS_DETAIL.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'Leavers',
    subcategory: 'Leavers',
    employeeStatus: 'all',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'All',
    subcategory: 'All',
    employeeStatus: 'all',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.GLOBAL_WORKFLOW_AUDIT.VALUE]: {
    type: 'AuditTrailReport',
    name: i18n.t('Report:Report.new_types_name.audit_workflow'),
    category: 'Operational',
    subcategory: 'PayrollCalculation',
    employeeStatus: null,
    servicesSelection: ['Payroll Calculation'],
    showOnlyTotals: null,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allProcessOwnersSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      servicesLocked: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.WORKFLOW_AUDIT.VALUE]: {
    type: 'AuditTrailReport',
    name: i18n.t('Report:Report.new_types_name.audit_workflow'),
    category: 'Operational',
    subcategory: 'PayrollCalculation',
    employeeStatus: null,
    servicesSelection: ['Payroll Calculation'],
    showOnlyTotals: null,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      servicesLocked: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.PAYROLL_CHANGES_REPORT.VALUE]: {
    type: 'EmployeeDataChangesReport',
    name: i18n.t('Report:Report.new_types_name.employee_payroll_changes_report'),
    category: 'EmployeePayrollChanges',
    subcategory: 'EmployeePayrollChanges',
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY.VALUE]: {
    type: 'GlobalPayrunManagementReport',
    name: i18n.t('Report:Report.new_types_name.employee_payroll_changes_report'),
    category: 'GlobalPayrunManagement',
    subcategory: 'GlobalPayrunManagement',
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.PAYRUN_MANAGEMENT_REPORT.VALUE]: {
    type: 'GlobalPayrunManagementReport',
    name: i18n.t('Report:Report.new_types_name.employee_payroll_changes_report'),
    category: 'GlobalPayrunManagement',
    subcategory: 'GlobalPayrunManagement',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.PAYRUN_REPORT.VALUE]: {
    type: 'PayrollInstanceReport',
    name: i18n.t('Report:Report.new_types_name.payrun_report'),
    category: 'PayrollInstance',
    subcategory: 'PayrollInstance',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.VARIANCE_REPORT.VALUE]: {
    type: 'VarianceReport',
    name: i18n.t('Report:Report.new_types_name.variance_report'),
    category: 'VarianceReport',
    subcategory: 'VarianceReport',
    employeeStatus: null,
    showOnlyTotals: null,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
  [reportOptionsConfig.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'All',
    subcategory: 'All',
    employeeStatus: 'inactive',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_REPORT.VALUE]: {
    type: 'EmployeeReport',
    name: i18n.t('Report:Report.new_types_name.employee_headcount'),
    category: 'All',
    subcategory: 'All',
    employeeStatus: 'active',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
    },
  },
  [reportOptionsConfig.GLOBAL_TOTAL_EMPLOYER_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    subcategory: 'TotalEmployerCosts',
    combinedSubcategorySelection: ['Total employer costs'],
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.TOTAL_EMPLOYER_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    subcategory: 'TotalEmployerCosts',
    combinedSubcategorySelection: ['Total employer costs'],
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYER_COSTS_DETAIL.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    subcategory: 'TotalEmployerCosts',
    combinedSubcategorySelection: ['Total employer costs'],
    employeeStatus: null,
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.GLOBAL_PAY_ELEMENT_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Pay elements'],
    shouldShowElements: true,
    isPayAndTaxesReport: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.TOTAL_PAY_ELEMENT_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Pay elements'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.PAY_ELEMENTS_COSTS_DETAIL.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Pay elements'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employer Contributions'],
    shouldShowElements: true,
    isPayAndTaxesReport: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.TOTAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employer Contributions'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employer Contributions'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.GLOBAL_EMPLOYEE_DEDUCTION_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employee Deductions'],
    shouldShowElements: true,
    isPayAndTaxesReport: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.TOTAL_EMPLOYEE_DEDUCTION_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employee Deductions'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYEE_DEDUCTION_COSTS_DETAIL.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employee Deductions'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employee Net Deductions'],
    shouldShowElements: true,
    isPayAndTaxesReport: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employee Net Deductions'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Employee Net Deductions'],
    populateElementsSubCategoriesSelected: true,
    employeeStatus: null,
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      showOnlyTotals: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.CHANGE_LOG.VALUE]: {
    type: 'ChangeLogReport',
    name: i18n.t('Report:Report.new_types_name.change_log_report'),
    category: 'ChangeLogReport',
    subcategory: 'All',
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
  [reportOptionsConfig.CHANGE_LOG_PAYROLL_DATA.VALUE]: {
    type: 'ChangeLogReport',
    name: i18n.t('Report:Report.new_types_name.change_log_report'),
    category: 'ChangeLogReport',
    subcategory: 'PayAndTermsChanges',
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
  [reportOptionsConfig.CHANGE_LOG_GENERAL_UPDATES.VALUE]: {
    type: 'ChangeLogReport',
    name: i18n.t('Report:Report.new_types_name.change_log_report'),
    category: 'ChangeLogReport',
    subcategory: 'GeneralUpdates',
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
  [reportOptionsConfig.GLOBAL_PAY_AND_TAXES_REPORT.VALUE]: {
    type: 'GlobalPayAndTaxesReport',
    name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
    category: 'GlobalPayAndTaxes',
    subcategory: 'Terms',
    allCombinedSubcategorySelection: true,
    allElementsSelected: true,
    isPayAndTaxesReport: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.PAY_AND_TAXES_REPORT.VALUE]: {
    type: 'GlobalPayAndTaxesReport',
    name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
    category: 'GlobalPayAndTaxes',
    subcategory: 'Terms',
    allCombinedSubcategorySelection: true,
    isPayAndTaxesReport: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      combinedSubcategory: false,
    },
  },
  [reportOptionsConfig.PAY_ELEMENTS_LIST.VALUE]: {
    type: 'GlobalPayAndTaxesReport',
    name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
    category: 'GlobalPayAndTaxes',
    combinedSubcategorySelection: ['Pay elements'],
    isPayAndTaxesReport: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYER_CONTRIBUTIONS_LISTING.VALUE]: {
    type: 'GlobalPayAndTaxesReport',
    name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
    category: 'GlobalPayAndTaxes',
    combinedSubcategorySelection: ['Employer Contributions'],
    isPayAndTaxesReport: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYEE_DEDUCTIONS_LISTING.VALUE]: {
    type: 'GlobalPayAndTaxesReport',
    name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
    category: 'GlobalPayAndTaxes',
    combinedSubcategorySelection: ['Employee Deductions'],
    isPayAndTaxesReport: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.EMPLOYEE_NET_DEDUCTIONS_LISTING.VALUE]: {
    type: 'GlobalPayAndTaxesReport',
    name: i18n.t('Report:Report.new_types_name.global_pay_and_taxes_report'),
    category: 'GlobalPayAndTaxes',
    combinedSubcategorySelection: ['Employee Net Deductions'],
    isPayAndTaxesReport: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      employeeStatus: true,
      combinedSubcategory: true,
    },
  },
  [reportOptionsConfig.GLOBAL_PAYROLL_ELEMENT_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
    shouldShowElements: true,
    showOnlyTotals: true,
    allCountriesSelected: true,
    allCompaniesSelected: true,
    allPayrollsSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      showOnlyTotals: true,
      fieldsNotToReset: ['showOnlyTotals'],
      combinedSubcategory: false,
    },
  },
  [reportOptionsConfig.PAYROLL_ELEMENT_COSTS.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
    shouldShowElements: true,
    showOnlyTotals: true,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      showOnlyTotals: true,
      fieldsNotToReset: ['showOnlyTotals'],
      combinedSubcategory: false,
    },
  },
  [reportOptionsConfig.PAYROLL_ELEMENT_COSTS_DETAIL.VALUE]: {
    type: 'PayrollReport',
    hasPayollCurrencySelection: 'setCurrency',
    name: i18n.t('Report:Report.new_types_name.global_payroll_report'),
    category: 'Costs',
    combinedSubcategorySelection: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
    shouldShowElements: true,
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      showOnlyTotals: true,
      fieldsNotToReset: ['showOnlyTotals'],
      combinedSubcategory: false,
    },
  },
  [reportOptionsConfig.ROLES_AND_RIGHTS_CHANGE_LOG.VALUE]: {
    type: 'ChangeLogReport',
    name: i18n.t('Report:Report.new_types_name.change_log_report'),
    category: 'ChangeLogReport',
    subcategory: 'RolesAndRights',
    showOnlyTotals: false,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
  [reportOptionsConfig.GLOBAL_ACCESS_MANAGEMENT.VALUE]: {
    type: 'AccessRightsReport',
    name: i18n.t('Report:Report.new_types_name.access_management_report'),
    category: 'Operational',
    subcategory: 'PayrollCalculation',
    servicesSelection: ['Payroll Calculation'],
    allCountriesSelected: true,
    allCompaniesSelected: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      servicesLocked: true,
    },
  },
  [reportOptionsConfig.ACCESS_MANAGEMENT.VALUE]: {
    type: 'AccessRightsReport',
    name: i18n.t('Report:Report.new_types_name.access_management_report'),
    category: 'Operational',
    subcategory: 'PayrollCalculation',
    servicesSelection: ['Payroll Calculation'],
    allCountriesSelected: false,
    allCompaniesSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      servicesLocked: true,
    },
  },
  [reportOptionsConfig.PAYROLL_WORKFLOW_AUDIT.VALUE]: {
    type: 'AuditTrailReport',
    name: i18n.t('Report:Report.new_types_name.audit_workflow'),
    category: 'Operational',
    subcategory: 'PayrollCalculation',
    servicesSelection: ['Payroll Calculation'],
    isSinglePayroll: true,
    showOnlyTotals: null,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      servicesLocked: true,
    },
  },
  [reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE]: {
    type: 'CustomBuildReport',
    name: i18n.t('Report:Report.new_types_name.workforce_and_payrun_detail'),
    category: 'Workforce',
    subcategory: 'PayrunDetail',
  },
  [reportOptionsConfig.ORG_UNIT_VARIANCE.VALUE]: {
    type: 'OrgUnitVarianceReport',
    name: i18n.t('Report:Report.new_types_name.org_unit_variance_report'),
    category: 'OrgUnitVarianceReport',
    subcategory: 'OrgUnitVarianceReport',
    combinedSubcategorySelection: ['Department', 'Business Unit', 'Cost Center'],
    servicesSelection: ['Payroll Calculation'],
    allCombinedSubcategorySelection: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
  [reportOptionsConfig.WORKFLOW_CALENDAR.VALUE]: {
    type: 'WorkflowCalendarReport',
    name: i18n.t('Report:Report.new_types_name.workflow_calendar'),
    category: 'Operational',
    subcategory: 'PayrollCalculation',
    servicesSelection: ['Payroll Calculation'],
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      servicesLocked: true,
    },
  },
  [reportOptionsConfig.ORG_UNIT_COSTS.VALUE]: {
    type: 'OrgUnitCostsReport',
    name: i18n.t('Report:Report.new_types_name.org_unit_costs'),
    category: 'Costs',
    subcategory: 'OrgUnit',
    combinedSubcategorySelection: ['Department', 'Business Unit', 'Cost Center'],
    servicesSelection: ['Payroll Calculation'],
    allCombinedSubcategorySelection: true,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
      servicesLocked: true,
    },
  },
  [reportOptionsConfig.WORKFORCE_VARIANCE.VALUE]: {
    type: 'WorkforceVarianceReport',
    name: i18n.t('Report:Report.new_types_name.workforce_variance'),
    category: 'WorkforceVarianceReport',
    subcategory: 'WorkforceVarianceReport',
    employeeStatus: null,
    showOnlyTotals: null,
    allCountriesSelected: false,
    allCompaniesSelected: false,
    allPayrollsSelected: false,
    readonlyFieldsObj: {
      ...mandatoryReadOnly,
    },
  },
})
