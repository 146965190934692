import React from 'react'

import PropTypes from 'prop-types'

import SectionHeading from 'components/SectionHeading'
import MobileDetailsView from 'components/MobileDetailsView'
import AccordionContainer from 'containers/AccordionContainer'
import { formatDate } from 'utils/date'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getDocEmployeeActionButtons = ({ doc, onDownload, t }) => (
  <button
    className='c-btn c-btn--small c-btn--curious u-margin-right-tiny rounded'
    title={t('Global:Global.text.download')}
    onClick={() => onDownload(doc.id)}
    data-testid={`payslip-mobile-${doc.id}-download`}
  >
    <span className='icon icon--download' />
  </button>
)

export const DocumentEmployeeView = ({ documents, title, onDownload }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  return (
    <div className='u-hide-on-desktop'>
      <SectionHeading text={title} />
      {documents && documents.length ? (
        documents.map((doc) => (
          <AccordionContainer
            title={doc.name}
            titleColor='minsk'
            rounded
            fullWidth
            mobileView
            key={doc.id}
            actionButtons={getDocEmployeeActionButtons({ doc, onDownload, t })}
            subtitleKey={`${t('Global:Global.text.uploaded_on')}:`}
            subtitle={formatDate(doc.uploadedOn.date)}
          >
            <MobileDetailsView
              rows={[
                { [t('Global:Global.text.uploaded_by')]: `${doc.extraData.firstname} ${doc.extraData.surname}` },
                { Size: doc.size },
                { Description: doc.description },
              ]}
              textAreaIndex={2}
            />
          </AccordionContainer>
        ))
      ) : (
        <div className='mobile-no-data-message--centered'>
          <span>{t('Global:Global.text.no_files')}</span>
        </div>
      )}
    </div>
  )
}

DocumentEmployeeView.propTypes = {
  documents: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
}
