import React, { useEffect, useRef, useState } from 'react'
import KeyPeopleContainer from '../containers/KeyPeopleContainer'
import Modal from 'components/Modal'
import KeyPeopleFormCreateContainer from '../containers/KeyPeopleFormCreateContainer'
import KeyPeopleFormEditContainer from '../containers/KeyPeopleFormEditContainer'
import includes from 'lodash/includes'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { useTranslation } from 'react-i18next'

const RouteView = (props) => {
  const { location } = props
  const editModal = useRef(null)
  const createModal = useRef(null)
  const [employeeId, setEmployeeId] = useState(0)
  const [filters, setFilters] = useState({})
  const { t } = useTranslation()

  const updateFiltersState = (filters) => {
    if (isEmpty(filters)) {
      setFilters(filters)
    }
  }

  useEffect(() => {
    const { search } = location
    const query = new URLSearchParams(search)
    if (includes(search, 'openEditModal')) {
      setEmployeeId(query.get('employeeId'))
      editModal.current.showModal()
    }
  }, [location])

  return (
    <>
      <Modal ref={createModal} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Global.text.key_user_details')}>
        <KeyPeopleFormCreateContainer onCreate={() => createModal.current.hideModal()} filters={filters} />
      </Modal>
      <Modal ref={editModal} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Global.text.key_user_details')}>
        <KeyPeopleFormEditContainer employeeId={employeeId} onEdit={() => editModal.current.hideModal()} />
      </Modal>
      <KeyPeopleContainer
        onCreate={() => createModal.current.showModal()}
        onEdit={(employeeId) => {
          setEmployeeId(employeeId)
          editModal.current.showModal()
        }}
        setFilters={updateFiltersState}
        {...props}
      />
    </>
  )
}

RouteView.propTypes = {
  location: PropTypes.object,
}

export default Fetcher(RouteView, ['companies'])
