import React, { useRef, useState } from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import TermsContainer from '../containers/TermsContainer'
import TermsCreateContainer from '../containers/TermsCreateContainer'
import TermsEditContainer from '../containers/TermsEditContainer'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const [termId, setTermId] = useState(null)
  const editModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Term])

  return (
    <InnerWrapper>
      <TermsCreateContainer {...props} />
      <TermsContainer
        onEdit={(termId) => {
          setTermId(termId)
          editModalRef.current.showModal()
        }}
        {...props}
      />
      <Modal ref={editModalRef} className='c-modal' modalHeading={t('Term.text.edit_term')}>
        <TermsEditContainer termId={termId} onEdit={() => editModalRef.current.hideModal()} />
      </Modal>
    </InnerWrapper>
  )
}

export default RouteView
