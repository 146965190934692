import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import ChildReport from './ChildReport'
import ConfirmationModal from 'components/ConfirmationModal'
import { Authorization } from 'containers/Authorization'
import { DownloadReportButton } from 'routes/Reporting/components/DownloadReportButton'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ReportView = (props) => {
  const deleteReportRef = useRef(null)
  const { report, deleteReport, reportNameCategory, selectedReport = null, reportsData } = props

  const { t } = useTranslation([i18nNameSpaces.Report])

  return (
    <div key={report.id}>
      <ConfirmationModal
        ref={deleteReportRef}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => deleteReport(report.id, selectedReport)}
      >
        <p>{t('Report.text.delete_report_confirm')}</p>
      </ConfirmationModal>
      <div className='position-relative u-margin-top u-margin-bottom d-flex ai-center jc--space-between'>
        <DownloadReportButton
          report={report}
          dropdownButtonClass='dropdown-icon-button--top-aligned '
          buttonClass='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny u-margin-bottom'
        />
        <Authorization permissions={['REPORT_DELETE']}>
          {/* Delete report button */}
          <button
            type='button'
            className='c-btn c-btn--tiny c-btn--curious u-text--tiny '
            title={t('Global:Global.text.delete')}
            onClick={() => deleteReportRef.current.showModal()}
          >
            <span className='icon icon--trash' />
          </button>
        </Authorization>
      </div>

      {/* Report name */}
      {reportsData.length !== 1 ? <SectionHeading text={reportNameCategory || report.name} /> : null}

      {/* Report data */}
      {reportsData.length ? (
        reportsData.map((childReport, i) => {
          let headings = childReport.headings || childReport.table.headings

          if (childReport.otherSheets) {
            let subReportItems = []
            childReport.otherSheets.map((subReport) => {
              let subReportHeadings = subReport.headings || subReport.table.headings
              subReportItems.push(<ChildReport key={i + 1} headings={subReportHeadings} childReport={subReport} index={i + 1} />)
            })

            return (
              <div key={i + 1}>
                <ChildReport headings={headings} childReport={childReport} index={i} reportNameCategory={reportNameCategory} />

                {subReportItems}
              </div>
            )
          }

          return (
            <ChildReport
              key={i + 1}
              headings={headings}
              childReport={childReport}
              index={i}
              reportNameCategory={reportsData.length !== 1 ? childReport.name : reportNameCategory}
            />
          )
        })
      ) : (
        <div className='u-margin-bottom-small'>{t('Global:Global.noData')}</div>
      )}
    </div>
  )
}

ReportView.propTypes = {
  report: PropTypes.object,
  selectedReport: PropTypes.number,
  deleteReport: PropTypes.func,
  reportsData: PropTypes.array,
  reportNameCategory: PropTypes.string,
}

export default ReportView
