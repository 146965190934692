import React, { useContext } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import DayWrapper from '../containers/DayWrapper'
import moment from 'moment'
import PropTypes from 'prop-types'
import { CustomToolbar, DailyHeader, Event, EventDaily, formats, WeeklyHeader } from 'components/calendar/CustomComponents'
import Year from 'components/calendar/Year'
import { LocaleSwitcherContext } from 'i18n/components/LocaleSwitcher'

const Calendar = ({ tasks, onRangeChange }) => {
  const { currentLang } = useContext(LocaleSwitcherContext)
  moment.locale(currentLang || 'en-GB')
  return (
    <div>
      <div className='o-layout u-margin-bottom-small'>
        <div className='c-calendar__holder o-layout__item u-1/1'>
          <BigCalendar
            defaultDate={new Date()}
            events={tasks}
            localizer={momentLocalizer(moment)}
            toolbar
            popup
            formats={formats}
            components={{
              event: Event,
              toolbar: (toolbar) => CustomToolbar(toolbar, { onRangeChange }),
              dateCellWrapper: DayWrapper,
              week: {
                header: WeeklyHeader,
              },
              day: {
                header: DailyHeader,
                event: EventDaily,
              },
            }}
            views={{
              day: true,
              week: true,
              month: true,
              year: Year,
            }}
          />
        </div>
      </div>
    </div>
  )
}

Calendar.propTypes = {
  tasks: PropTypes.array.isRequired,
  onRangeChange: PropTypes.func,
}

export default Calendar
