import React from 'react'
import ImportFacade from '../../../containers/ImportFacade'
import { download, downloadTemplateUpdate } from 'redux/actions/employeeSystemUsers'
import SectionHeading from 'components/SectionHeading'
import employeeStatusEnum from 'utils/enums/employmentStatuses'
import { titleTypes } from 'redux/config/DropDownOptions/titleOptions'
import { createNewEmployeesBulkImportJob } from 'redux/actions/pollingJob'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.employee_update_template_instructions.heading')} />
      <p className='u-margin-bottom-small'>{t('Import.employee_update_template_instructions.description')}</p>
      <ul className='u-margin-bottom-small'>
        <li>{t('Import.employee_update_template_instructions.steps.select_company_and_payroll')}</li>
        <li>{t('Import.employee_update_template_instructions.steps.complete_data')}</li>
        <li>
          {t('Import.employee_update_template_instructions.steps.columns_A_H_no_update')}
          <b>{t('Global.common.cannot_be_updated')}</b>
        </li>
        <li>{t('Import.employee_update_template_instructions.steps.import_template')}</li>
        <li>{t('Import.employee_update_template_instructions.steps.wait_for_confirmation')}</li>
      </ul>
      <u>{t('Global.common.error_message')}</u>: {t('Import.employee_update_template_instructions.error_message')}
    </div>
  )
}

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <ImportFacade
      {...props}
      heading={t('Import.employee_bulk.heading')}
      entityName='employees'
      downloadAction={download}
      TemplateClarifications={TemplateClarifications}
      downloadTemplateAction={({ payrollId, companyId, shouldUseCompanyAsFilter }) => {
        if (!shouldUseCompanyAsFilter) companyId = null

        return downloadTemplateUpdate(payrollId, companyId)
      }}
      importAction={({ companyId, file }) => {
        return createNewEmployeesBulkImportJob(
          {
            company: companyId,
            entityId: companyId,
          },
          file
        )
      }}
      shouldIncludeAllPayrollsOption
      isAsync
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-text--normal'>
          <SectionHeading text={t('Import.text.data_validations')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.date_format'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.org_unit'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.title'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
              values={{
                value: titleTypes.map((t) => t.label).join(', '),
              }}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.marital_status'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_update_common_labels.email'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.mobile_phone'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.type_of_employment'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
              values={{
                value: employeeStatusEnum.ALL_IMPORT_LABELS.join(', '),
              }}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.visa_work_permit'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.employee_self_service'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.mode2FA'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.payment_type'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.full_time_part_time'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.national_insurance_letter'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.other_address_fields'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.type_of_address'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.country_of_birth'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.end_date'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}
