import React from 'react'

import { FitCard } from 'components/displays/FitCard'

import { SideBarBlockTemplate } from 'routes/ReportingAnalytics/routes/ReportAnalytics/templates/SideBarBlockTemplate'
import { SideBarMultiSelectorTemplate } from 'routes/ReportingAnalytics/routes/ReportAnalytics/templates/SideBarMultiSelectorTemplate'

const ANALYTICS_TILE_TEMPLATES = {
  AVG_ON_TIME_COMPLETION_COMPANY: ({ tileService, tileFallBackError, t }) => (
    <FitCard
      count={tileService.workflowTotalsCount.avgOnTimeCompletionCompany}
      icon='report-total-ee-net-deductions-logo'
      subtitle={t('Report.analytics.avg_completion_company')}
      fallback={tileFallBackError}
    />
  ),
  AVG_ON_TIME_COMPLETION_PAYROLL_PROVIDER: ({ tileService, tileFallBackError, t }) => (
    <FitCard
      count={tileService.workflowTotalsCount.avgOnTimeCompletionPayrollProvider}
      icon='report-total-pay-elements-logo'
      subtitle={t('Report.analytics.avg_completion_payroll_provider')}
      fallback={tileFallBackError}
    />
  ),
  AVG_ON_TIME_COMPLETION_GTN_VERSIONS: ({ tileService, tileFallBackError, t }) => (
    <FitCard
      count={tileService.workflowTotalsCount.avgOnTimeCompletionGtnVersions}
      icon='report-total-ee-deductions-logo'
      subtitle={t('Report.analytics.avg_gtn')}
      fallback={tileFallBackError}
    />
  ),
}

const ANALYTICS_SIDE_BAR_TEMPLATES = {
  NUMBER_OF_GTN_VERSIONS: (props) => <SideBarBlockTemplate {...props} />,
  NUMBER_OF_GTN_VERSIONS_BY_PAY_DATE: (props) => <SideBarBlockTemplate {...props} withSelect={false} sideBarQuery='payDate' />,
  NUMBER_OF_REVERSIONS: (props) => <SideBarMultiSelectorTemplate {...props} />,
}

const analyticTemplates = {
  tiles: ANALYTICS_TILE_TEMPLATES,
  sideBar: ANALYTICS_SIDE_BAR_TEMPLATES,
}

export const analyticsTemplateTypes = {
  tiles: 'tiles',
  sideBar: 'sideBar',
}

export const buildAnalyticsReport = (type, charts, props) => (
  <>
    {Object.keys(charts)?.map((chart) => {
      const template = analyticTemplates?.[type]?.[chart]
      return template ? template(props) : null
    })}
  </>
)
