import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

i18n
  /*
   *  Load translation using http -> see /public/locales, learn more: https://github.com/i18next/i18next-http-backend
   */
  .use(Backend)
  .use(initReactI18next)
  /**
   *  For all options read: https://www.i18next.com/overview/configuration-options
   **/
  .init({
    fallbackLng: 'en',
    /**
     *  Enable for debugging purposes during development
     **/
    debug: false,
    /*
     * Keep Empty to allow lazy load translation files for,
     * the namespace dynamically only when requested for the first time.
     */
    ns: [i18nNameSpaces.Global, i18nNameSpaces.BusinessComponents],

    /**
     * A property that determines the configuration for key separation.
     *
     * If `keySeparator` is set to `false`, the default behavior is to disable
     * or ignore any key separation logic within the application or function
     * relying on this property.
     */
    keySeparator: false,
    /**
     * Represents the default namespace used for internationalization.
     * This specifies the namespace to be used in translations to fetch the
     * appropriate language strings.
     *
     * @type {string}
     */
    defaultNS: i18nNameSpaces.Global,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    /**
     * Configuration object for interpolation settings used in a system, typically for internationalization (i18n).
     *
     * @property {boolean} escapeValue - Determines whether values will be escaped to prevent potential XSS attacks.
     *                                   If set to `false`, interpolation values are not escaped.
     */
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    // Docs: https://react.i18next.com/latest/i18next-instance
  })

export default i18n
