import React from 'react'
import ImportFacade from '../../../containers/ImportFacade'
import { download, downloadTemplate, importFxRatesCreate } from 'redux/actions/fxRates'
import SectionHeading from 'components/SectionHeading'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.gla_clarifications.create_multiple_accounts')} />
      <p className='u-margin-bottom-small'>{t('Import.gla_clarifications.description')}</p>
      <ul className='margin-bottom-small'>
        <li>{t('Import.gla_clarifications.step1')}</li>
        <li>{t('Import.gla_clarifications.step2')}</li>
        <li>{t('Import.gla_clarifications.step3')}</li>
        <li>{t('Import.gla_clarifications.step4')}</li>
      </ul>
    </div>
  )
}

const ImportFXRates = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <ImportFacade
      {...props}
      heading={t('Import.fx.heading')}
      entityName={t('Import.fx.entity_name')}
      downloadAction={download}
      downloadTemplateAction={() => downloadTemplate()}
      importAction={({ file }) => importFxRatesCreate(file)}
      TemplateClarifications={TemplateClarifications}
      hasFilters={false}
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading text={t('Import.fx.required_fields.heading')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.fx.required_fields.from_currency_with_desc'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='from-currency' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.fx.required_fields.to_currency_with_desc'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='to-currency' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.fx.required_fields.rate_with_desc'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='rate' className='u-weight--bold' />]}
            />
          </p>
        </div>

        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading fullWidth text={t('Import.fx.validations.heading')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.fx.validations.date_with_desc'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='date' className='u-weight--bold' />]}
            />
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}

export default ImportFXRates
