import React from 'react'
import PropTypes from 'prop-types'
import { fetchPayrolls } from 'redux/actions/payrolls'
import { selectTenant } from 'redux/actions/tenants'
import { useTranslation } from 'react-i18next'

const PayrollLink = ({
  resetTenant,
  tenant,
  isCotUser,
  isVendor,
  selectTenant: selectVendorTenant,
  payrollProcessId,
  history,
  companyId,
  dispatch,
  vendorId,
}) => {
  const { t } = useTranslation()

  const { id: tenantId } = tenant
  let pathToTenantWorkFlowDetails

  const handleClick = async () => {
    resetTenant()
    selectVendorTenant({
      url: tenant.url,
      schema: tenant.schema,
      shouldUse: true,
    })
    await dispatch(isVendor || isCotUser ? fetchPayrolls({ forceVendorTenantAPI: true }) : fetchPayrolls({})).then((res) => {
      if (isCotUser) dispatch(selectTenant({ url: tenant.url, type: tenant.type, id: tenantId }))

      try {
        pathToTenantWorkFlowDetails = `/companies/${companyId}/dates-processes/payroll/${payrollProcessId}`

        history.push(pathToTenantWorkFlowDetails)
      } catch (e) {
        const endPath = '/payruns/active'
        let baseVendor = `/vendors/${vendorId}/clients/${tenantId}/companies/`
        let pathToTenantPayrollInstances = endPath
        let pathToVendorPayrollInstances = `${baseVendor}/${endPath}`

        let path = isVendor ? pathToVendorPayrollInstances : pathToTenantPayrollInstances

        return history.push(path)
      }
    })
  }

  return (
    <span className='u-text--curious u-cursor--pointer' onClick={handleClick} data-testid='notification-link'>
      {t('Global.text.here')}
    </span>
  )
}

PayrollLink.propTypes = {
  resetTenant: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    url: PropTypes.string.isRequired,
    schema: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  isCotUser: PropTypes.bool.isRequired,
  isVendor: PropTypes.bool.isRequired,
  selectTenant: PropTypes.func.isRequired,
  payrollProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func.isRequired,
  vendorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default PayrollLink
