import { ROLE_ACCOUNTING } from 'redux/config/auth'
import { getKURoleTypes } from 'redux/config/keyPeopleRoleTypes'
import { buildFilterName } from 'redux/filters/commonFilters'
import { createFilter } from 'utils/redux/filter'
import { normalizeFiltersForBE, normalizePartialFilteringForBE } from 'utils/filters'
import { rolesAndRightsPartialSearchFieldEnabled, shareModalsFilterMapping } from 'utils/enums/filterMappingEnums'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { getCompanyIdByTypeClass } from 'redux/helpers/companyIdByTypeClass'

const initialSort = [
  {
    name: 'surname',
    order: 'asc',
  },
]

const defaultFilters = {
  isKeyPerson: 1,
  hasCredentials: true,
}

const filterKeys = {
  payroll: 'companyPayrollDocumentShare',
  company: 'companyDocumentShare',
  payrollInstance: 'payrollInstanceDocumentShare',
}
const roleTypes = getKURoleTypes()
  .filter((role) => role.type !== ROLE_ACCOUNTING)
  .map((role) => role.type)

export const getNormalizedFilters = (filters) => normalizeFiltersForBE(filters, shareModalsFilterMapping, true)

export const getPartialSearchFilters = (filters) =>
  normalizePartialFilteringForBE({
    filters,
    partialSearchFields: rolesAndRightsPartialSearchFieldEnabled,
  })

export const getSortParameters = (sort) =>
  sort?.reduce(
    (acc, e) => [
      ...acc,
      ...(e.id === 'name'
        ? [
          { name: 'firstname', order: e.desc ? 'desc' : 'asc' },
          { name: 'surname', order: e.desc ? 'desc' : 'asc' },
        ]
        : [{ name: shareModalsFilterMapping[e.id], order: e.desc ? 'desc' : 'asc' }]),
    ],
    []
  )

export const handleOnFilter = ({ page = 0, limit, filters, sort }, dispatch, getState, props) => {
  const { typeClass, payrollInstanceId, documentId } = props
  const state = getState()

  const companyId = getCompanyIdByTypeClass(typeClass, state, props)

  const normalizedFilters = getNormalizedFilters(filters)
  const partialSearchFilters = getPartialSearchFilters(filters)
  const sortParameters = getSortParameters(sort)

  dispatch(
    fetchEmployeeSystemUsers({
      filter: prepareEESystemUsersFilters({
        page,
        limit,
        state,
        filters: { ...normalizedFilters, partialSearchFields: partialSearchFilters },
        props: { typeClass, payrollInstanceId, companyId, documentId },
        sort: sortParameters,
      }),
      disableObsoleteFlow: true,
    })
  )
}

export const prepareEESystemUsersFilters = ({ page, limit, filters, state, props, sort = initialSort } = {}) => {
  const { typeClass, companyId, payrollInstanceId, documentId } = props

  const filterKey = filterKeys[typeClass]
  const filterName = buildFilterName({ companyId, payrollInstanceId, documentId })[filterKey]

  limit = limit ?? state.employeeSystemUsers.filters[filterName].params.limit
  page = page ?? state.employeeSystemUsers.filters[filterName].params.offset / limit
  filters = filters ?? state.employeeSystemUsers.filters[filterName].params

  if (companyId) {
    filters['accessableCompanies'] = companyId
  }

  if (typeClass === 'payroll' && !filters['roleType']) filters['roleType'] = roleTypes

  return createFilter(
    {
      ...defaultFilters,
      ...filters,
      offset: page * limit,
      limit,
      sort,
    },
    filterName
  )
}

export default () => ({
  name: 'employeeSystemUsers',
  isForceFetching: true,
  params: [
    {
      _computed: {
        filter: (state, props) => {
          const { clearFilters } = props
          const typeClass = props.typeClass
          const companyId = getCompanyIdByTypeClass(typeClass, state, props)

          const payrollInstanceId = props.payrollInstanceId
          const documentId = props.documentId
          const filterKey = filterKeys[typeClass]
          const filterName = buildFilterName({ companyId, payrollInstanceId, documentId })[filterKey]
          const isFetchedAtLeastOnce = state.employeeSystemUsers.filters[filterName]?.ids && !clearFilters

          if (isFetchedAtLeastOnce) {
            return prepareEESystemUsersFilters({
              state,
              props: { companyId, payrollInstanceId, typeClass, documentId },
            })
          }

          return prepareEESystemUsersFilters({
            page: 0,
            limit: 25,
            filters: defaultFilters,
            state,
            props: { companyId, payrollInstanceId, typeClass, documentId },
          })
        },
      },
      disableObsoleteFlow: true,
    },
  ],
})
