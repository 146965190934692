import i18n from 'i18next'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { required } from 'utils/validations'
import CheckboxOption from 'components/form/select/CheckboxOption'

export const getClientListFormFields = ({ systemAvailableLocales }) => ({
  details: {
    items: [
      {
        name: 'name',
        label: i18n.t('Global:Global.text.name'),
        labelClassName: 'c-label u-text--small u-text--curious',
        className: 'c-input c-input--transparent',
        component: InputField,
        type: 'text',
        disabled: true,
      },
      {
        name: 'url',
        label: i18n.t('Client:Client.table.headings.url'),
        labelClassName: 'c-label u-text--small u-text--curious',
        className: 'c-input c-input--transparent',
        component: InputField,
        type: 'text',
        disabled: true,
        validate: required,
      },
      {
        name: 'referral',
        label: i18n.t('Client:Client.table.headings.referral'),
        labelClassName: 'c-label u-text--small u-text--curious',
        className: 'c-input c-input--transparent',
        component: InputField,
        type: 'text',
        disabled: true,
      },
    ],
  },
  configuration: {
    title: i18n.t('Client:Client.text.tenant_config'),
    items: [
      {
        name: 'locales',
        label: i18n.t('Client:Client.table.headings.languages'),
        labelClassName: 'c-label u-text--small u-text--curious',
        className: 'c-custom-select c-custom-select--transparent',
        formGroupClassName: 'u-margin-none',
        component: CustomSelectField,
        placeholder: i18n.t('Global:Global.select.placeholder'),
        optionComponent: CheckboxOption,
        multi: true,
        options: systemAvailableLocales,
      },
    ],
  },
})
