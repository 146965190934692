import { payrunStatesEnum } from 'utils/enums/payrunStates'

export const types = {
  payrollInstance: {
    value: 'Payslip\\CoreBundle\\Entity\\PayrollInstance',
    shortValue: 'payrollInstance',
    label: 'PayrollInstance',
    filterName: 'PayrollInstance',
    confirmationMsg: 'Global:Global.file_upload.confirmation',
    confirmationMsgProcessOwnerAutoShared: 'File successfully uploaded.',
    shareFileMsg: 'Global:Global.file_upload.share.confirm',
    clientShareTabs: [
      {
        name: 'actionOwners',
        label: 'Global:Global.file_upload.share.tab.action_owners',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.within_company',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'outsideCompany',
        label: 'Global:Global.file_upload.share.tab.outside_company',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'sftp',
        label: 'Global:Global.file_upload.share.tab.sftp',
        showFilter: false,
        showSearch: false,
        hideOnNotes: true,
      },
    ],
    vendorShareTabs: [
      {
        name: 'actionOwners',
        label: 'Global:Global.file_upload.share.tab.action_owners',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'outsideCompany',
        label: 'Global:Global.file_upload.share.tab.other_client_users',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.colleagues',
        showFilter: true,
        showSearch: true,
      },
    ],
    documentTypesTabs: [
      {
        name: 'payrollInputsDocTypes',
        label: 'Inputs',
        documentsTableTitle: 'Input Files',
        access: [payrunStatesEnum.ACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS, payrunStatesEnum.INACTIVE],
      },
      {
        name: 'payrollGtnDocTypes',
        label: 'GTN',
        documentsTableTitle: 'GTN Files',
        access: [payrunStatesEnum.ACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS],
      },
      {
        name: 'payrollPayslipsTaxDocTypes',
        label: 'Payslips and Tax Documents',
        documentsTableTitle: 'Payslips and Tax Documents',
        access: [payrunStatesEnum.ACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS],
      },
      {
        name: 'payrollGlReportsDocTypes',
        label: 'GL',
        documentsTableTitle: 'GL Reports',
        access: [payrunStatesEnum.ACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS],
      },
      {
        name: 'payrollPaymentsDocTypes',
        label: 'Payments',
        documentsTableTitle: 'Payments',
        access: [payrunStatesEnum.ACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS],
      },
      {
        name: 'payrollOtherDocTypes',
        label: 'Other',
        documentsTableTitle: 'Other Documents',
        access: [payrunStatesEnum.ACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS, payrunStatesEnum.INACTIVE],
      },
    ],
  },
  payroll: {
    value: 'Payslip\\CoreBundle\\Entity\\Payroll',
    shortValue: 'payroll',
    label: 'Payroll',
    filterName: 'Payroll',
    confirmationMsg: 'File successfully uploaded and accessible by you.',
    confirmationMsgProcessOwnerAutoShared: 'File successfully uploaded and accessible by you and the process owner.',
    shareFileMsg: 'Global:Global.file_upload.share.confirm',
    clientShareTabs: [
      {
        name: 'actionOwners',
        label: 'Global:Global.file_upload.share.tab.action_owners',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.within_company',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'outsideCompany',
        label: 'Global:Global.file_upload.share.tab.outside_company',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'sftp',
        label: 'Global:Global.file_upload.share.tab.sftp',
        showFilter: false,
        showSearch: false,
      },
    ],
    vendorShareTabs: [
      {
        name: 'actionOwners',
        label: 'Global:Global.file_upload.share.tab.action_owners',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'outsideCompany',
        label: 'Global:Global.file_upload.share.tab.other_client_users',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.colleagues',
        showFilter: true,
        showSearch: true,
      },
    ],
  },
  company: {
    value: 'Payslip\\CoreBundle\\Entity\\Company',
    shortValue: 'company',
    label: 'Company',
    filterName: 'Company',
    confirmationMsg: 'Global:Global.file_upload.confirmation',
    shareFileMsg: 'Global:Global.file_upload.share.confirm',
    clientShareTabs: [
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.within_company',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'outsideCompany',
        label: 'Global:Global.file_upload.share.tab.outside_company',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'onboardingVendor',
        label: 'Global:Global.file_upload.share.tab.onboarding_vendor',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'offboardingVendor',
        label: 'Global:Global.file_upload.share.tab.offboarding_vendor',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'sftp',
        label: 'Global:Global.file_upload.share.tab.sftp',
        showFilter: false,
        showSearch: false,
      },
    ],
    vendorShareTabs: [
      {
        name: 'outsideCompany',
        label: 'Global:Global.file_upload.share.tab.client_users',
        showFilter: true,
        showSearch: true,
      },
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.colleagues',
        showFilter: true,
        showSearch: true,
      },
    ],
  },
  employee: {
    value: 'Payslip\\CoreBundle\\Entity\\EmployeeUser',
    shortValue: 'employee',
    label: 'EmployeeUser',
    filterName: 'EmployeeUser',
    confirmationMsg: 'File successfully uploaded and accessible by Key People.',
    shareFileMsg: 'Do you want to share the file with the employee?',
    clientShareTabs: [
      {
        name: 'withinCompany',
        label: 'Global:Global.file_upload.share.tab.employee',
        showFilter: false,
        showSearch: false,
      },
    ],
  },
}

export const typeClassesWithSftpSharing = ['payrollInstance', 'payroll', 'company']

export const getTabByTypeClass = ({ typeClass, tabName }) => types[typeClass]?.clientShareTabs?.find((t) => t.name === tabName)
export const getTabLabel = ({ typeClass, tabName, t }) => t(getTabByTypeClass({ typeClass, tabName })?.label)
