import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioFields from 'components/form/RadioFields'
import Form from 'components/form/Form'
import { batch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const DeleteEmployeeBankAccountView = (props) => {
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [filteredList, setFilteredList] = useState(props.bankAccounts)
  const { t } = useTranslation([i18nNameSpaces.Employee])

  useEffect(() => {
    const currentRecord = props.bankAccounts.find((bankAccount) => bankAccount.id === props.selectedId)
    const records = props.bankAccounts.filter((bankAccount) => bankAccount.id !== props.selectedId)
    batch(() => {
      setSelectedBankAccount(currentRecord)
      setFilteredList(records)
    })
    return () => {
      setFilteredList(null)
    }
  }, [])

  const onChange = (e) => props.onSelectChange(e.target.value)
  const showListOfItems = selectedBankAccount && selectedBankAccount.primaryBankAccount && filteredList.length >= 1

  return (
    <>
      <p>{t('Employee.modal.confirm_remove_bank_account')}</p>
      {showListOfItems && (
        <div>
          <p>{t('Employee.modal.please_select_bank_account')}</p>
          <div className='o-layout__item u-margin-bottom u-1/1 '>
            <div className='c-panel u-bg--white border-0 rounded u-padding-small'>
              <Form onSubmit={null}>
                <Field
                  component={RadioFields}
                  name='bankAccounts'
                  onChange={onChange}
                  options={filteredList.map((bankAccount) => {
                    const buildBankLabel = ({ bankName, accountNumber, iban, bankCountryName, currencyAbbr }) => {
                      let label = bankName || `${bankCountryName} ${currencyAbbr}`
                      label += accountNumber || iban ? ` - ${accountNumber || ''} ${iban || ''}` : ''
                      return label
                    }
                    return {
                      label: buildBankLabel(bankAccount),
                      value: bankAccount.id,
                      compareValue: bankAccount.id.toString(),
                    }
                  })}
                  header=''
                  headerClassName='u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='bank-accounts-list'
                />
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

DeleteEmployeeBankAccountView.propTypes = {
  bankAccounts: PropTypes.array,
  selectedId: PropTypes.number,
  onSelectChange: PropTypes.func,
}

export default reduxForm({
  // a unique name for the form
  form: 'DeleteEmployeeBankAccount',
})(DeleteEmployeeBankAccountView)
