import React, { useState } from 'react'
import FilterButton from 'components/buttons/FilterButton'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import { useSelector } from 'react-redux'
import { isCotOem } from 'redux/selectors/auth'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getColumnFilter = (t) => ({ type: 'TextFilter', delay: 1000, placeholder: t('Global:Global.text.filter') })

const SubCategoriesTable = ({ termSubCategories, onEdit }) => {
  const [showFilters, setShowFilters] = useState(false)
  const isCotOemUser = useSelector(isCotOem)
  const { t } = useTranslation([i18nNameSpaces.Term])

  const tableHeadings = [
    { accessor: 'name', Header: t('Global:Global.text.name'), filterOptions: getColumnFilter(t) },
    { accessor: 'category', Header: t('Global:Global.text.category'), filterOptions: getColumnFilter(t) },
  ]

  if (!isCotOemUser) {
    tableHeadings.push({
      accessor: 'action',
      Header: t('Global:Global.button.actions'),
      setWidth: 100,
      actionCell: true,
      disableFilters: true,
      disableSortBy: true,
    })
  }

  return (
    <div>
      <SectionHeading text={t('Term.text.sub_categories_list')} className='u-margin-top-large'>
        <FilterButton
          className='c-btn c-btn--small float-right c-btn--curious u-padding-left u-padding-right u-margin-bottom-small'
          onClick={() => setShowFilters(!showFilters)}
        />
      </SectionHeading>
      <PayslipTable
        data={termSubCategories.map((subCategory) => {
          return {
            ...subCategory,
            name: subCategory.name,
            category: subCategory.categoryRef.name,
            categoryId: subCategory.termCategory,
            action: isCotOemUser ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  onEdit(subCategory.id)
                }}
                className='c-btn c-btn--tiny c-btn--curious'
              >
                <span className='icon icon--edit' />
              </button>
            ),
          }
        })}
        wrapperClassName='table-text-full-length'
        showFilters={showFilters}
        headings={tableHeadings}
      />
    </div>
  )
}

SubCategoriesTable.propTypes = {
  termSubCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.shape({
        categoryRef: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
  onEdit: PropTypes.func,
}

export default SubCategoriesTable
