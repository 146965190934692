import { TableHeading } from 'utils/helperClasses/tableHeadings'
import i18n from 'i18next'
import React from 'react'
import { cellRenderAction, EditTableDataAction, JoinColItemsByCommaFormat } from 'utils/tableDataFormatters'

export const getVendorListTableConfig = ({ showActions, selectTenantSubmit, handleUpdateAction }) => {
  const tableConfig = [
    new TableHeading({
      accessor: 'id',
      className: 'text-left',
      columnClassName: 'text-left',
      heading: i18n.t('Client:Client.table.headings.id'),
      isKey: true,
      isVisible: false,
    }),
    new TableHeading({
      accessor: 'name',
      heading: i18n.t('Client:Client.table.headings.name'),
      className: 'text-left',
      columnClassName: 'u-text--curious u-cursor--pointer text-left',
      disableFilters: true,
      disableSortBy: false,
      Cell: ({
        row: {
          values: { id, name, url },
        },
      }) => (
        <div title={name} onClick={() => selectTenantSubmit(url, 'vendor', id)}>
          {name}
        </div>
      ),
    }),
    new TableHeading({
      accessor: 'url',
      heading: i18n.t('Client:Client.table.headings.url'),
      isVisible: false,
      disableFilters: true,
      disableSortBy: false,
      className: 'text-left',
      columnClassName: 'text-left',
    }),
    new TableHeading({
      accessor: 'shortUrl',
      heading: () => <span>{i18n.t('Client:Client.table.headings.url')}</span>,
      disableFilters: true,
      disableSortBy: false,
      className: 'text-left',
      columnClassName: 'text-left',
    }),
    new TableHeading({
      accessor: 'locales',
      heading: i18n.t('Client:Client.table.headings.languages'),
      disableFilters: true,
      disableSortBy: false,
      className: 'text-left',
      columnClassName: 'text-left',
      Cell: JoinColItemsByCommaFormat,
    }),
  ]
  if (showActions) {
    tableConfig.push(
      new TableHeading({
        accessor: 'action',
        heading: '',
        className: 'text-center',
        columnClassName: 'text-right',
        disableSortBy: true,
        disableFilters: true,
        Cell: cellRenderAction({ Actions: EditTableDataAction }),
        onClick: handleUpdateAction,
      })
    )
  }
  return tableConfig
}
