import React from 'react'
import PropTypes from 'prop-types'

import SectionHeading from 'components/SectionHeading'
import { tasksIdentifiers } from 'redux/config/tasksIdentifiers'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const Tasks = ({ tasks, isFetching }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>
  return (
    <div>
      <SectionHeading text={t('BusinessComponent.placeholder.actions_list')} className='u-margin-top-large' />

      {isFetching === true ? t('Global:Global.text.loading') : null}

      <PayslipTable
        data={tasks.map((task) => {
          let taskIdentifier = _.find(tasksIdentifiers, { code: task.identifier })
          return {
            id: task.id,
            name: task.name,
            identifier: taskIdentifier ? taskIdentifier.name : null,
            link: task.finishTaskLink,
            owner: task.owner,
            step: task.stepRef.name,
          }
        })}
        headings={[
          { accessor: 'name', Header: t('Global:Global.text.name'), disableSortBy: true },
          { accessor: 'identifier', Header: t('BusinessComponent.field.identifier'), disableSortBy: true },
          { accessor: 'link', Header: t('Global:Global.text.link'), disableSortBy: true },
          { accessor: 'owner', Header: t('Global:Global.text.owner'), disableSortBy: true },
          { accessor: 'step', Header: t('Global:Global.button.step'), disableSortBy: true },
        ]}
        wrapperClassName='u-1/1'
        trClassName='react-bs-table__cell--whitespace--normal'
      />
    </div>
  )
}
Tasks.propTypes = {
  tasks: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
}
export default Tasks
