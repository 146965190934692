import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { getQuery } from 'utils/query'
import { showMessage } from 'redux/actions/modal'
import { logout } from 'redux/actions/auth'
import { KeycloakCTX } from '../components/Keycloak/KeycloakProvider'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

/**
 * Reset the App's Redux Store to its initial state and logs out the User.
 *
 * Reset functionality is triggered when the User adds the following GET parameter in the URI: `?clear-state`.
 * If `ResetContainer` wraps the App's routing component (as it's now), then the GET parameter will be available
 * on any possible route / page.
 */

const ResetContainer = (props) => {
  const keycloak = useContext(KeycloakCTX)
  const { shouldReset, children, showMessage } = props
  const { t } = useTranslation()
  useEffect(() => {
    if (shouldReset) {
      logout(keycloak)
      window.location.href = '/'
      showMessage({
        title: t('Global:Global.message.app_data_cleared'),
        body: t('Global:Global.message.app_data_cleared_body'),
      })
    }
  })

  if (shouldReset) return <div />

  return children
}

ResetContainer.propTypes = {
  showMessage: PropTypes.func.isRequired,
  children: PropTypes.node,
  shouldReset: PropTypes.bool,
}
const mapDispatchToProps = { logout, showMessage }

const mapStateToProps = (state) => {
  const query = getQuery(window.location.search)
  // We should reset the app, if `clear-state` parameter exists in the query GET parameters.
  // Its existence is enough for our check. We don't care about the parameter's value.
  const shouldReset = query['clear-state'] !== undefined

  return { shouldReset }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetContainer)
