import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TermCategories = ({ categories }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <Field
      name='termCategory'
      label={t('Global:Global.text.category')}
      labelClassName='c-label'
      component={CustomSelectField}
      clearable={false}
      placeholder={categories.length ? t('BusinessComponent.text.select_category') : t('Global:Global.text.loading')}
      options={categories.map((category) => ({
        value: category.id,
        label: category.name,
      }))}
    />
  )
}

TermCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
}

export default TermCategories
