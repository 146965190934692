import React from 'react'
import PayrollContainer from 'containers/PayrollContainer'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const { addPayAndTaxesElementsButton } = PayrollTabModalOptionButtons

  return (
    <PayrollContainer
      payrunState={payrunStatesEnum.PREVIOUS}
      heading={t('Payrun.text.closedPayruns')}
      payrollKey='payrolls-for-closed'
      buttonOptions={addPayAndTaxesElementsButton}
      {...props}
    />
  )
}
