import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import TermCategories from 'components/TermCategories'
import { useTranslation } from 'react-i18next'

const FormFields = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <Form {...props} showSubmitBtn colorClassBtn={'c-btn--curious'} btnText={t('Global.button.submit')}>
          <Field name='name' label={t('Global.text.name')} labelClassName='c-label' component={InputField} type='text' />
          {props.categories && <TermCategories {...props} />}
        </Form>
      </div>
    </div>
  )
}

export default FormFields
