import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import DetachingFromPayrollContainer from '../containers/DetachingFromPayrollContainer'
import PayrollHeadingContainer from '../../../../../containers/PayrollHeadingContainer'
import useQuery from 'hooks/useQuery'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  return (
    <InnerWrapper>
      <PayrollHeadingContainer heading={t('Employee.heading.employees_unassigning_to_payroll')} {...props} />
      <EmployeesSearchBarContainer query={useQuery()} {...props} />
      <DetachingFromPayrollContainer enablePagination {...props} />
    </InnerWrapper>
  )
}
