import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import { CircleExclamationIcon } from 'components/icons/CircleIcon'
import { getShareSftpHeadings } from 'components/documents/helpers/shareTableConfig'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ShareSftp = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const data = [
    {
      location: 'SFTP',
      share: (
        <Field
          type='checkbox'
          labelClassName='u-hidden'
          component={CheckboxField}
          className='control__indicator__input'
          indicatorClassName='control__indicator--center'
          formGroupClassName='u-margin-none'
          name={'sftp'}
          disabled={props.initialValues.sftp === true}
        />
      ),
    },
  ]
  return (
    <>
      <div className='d-flex d-flex--row'>
        <CircleExclamationIcon color='#FFCA0A' />
        <p className='u-margin-left-tiny u-text--normal'>{t('BusinessComponent.share_labels.disclaimer')}</p>
      </div>
      <PayslipTable headings={getShareSftpHeadings()} data={data} />
    </>
  )
}

export default ShareSftp
