import React, { useRef } from 'react'
import SectionHeading from 'components/SectionHeading'
import ConfirmationModal from 'components/ConfirmationModal'
import { HistoryModal } from 'components/ReusableModals/HistoryModal'
import { generateResourcesTableHeadings } from '../utils/resourcesTableConfig'
import ResourcesUploadHeader from './ResourcesUploadHeader'
import ResourcesList from './ResourcesList'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const ResourcesView = ({
  isCotAdminUser,
  isCotUser,
  isCotOemUser,
  clientTablePermission,
  vendorTablePermission,
  clientTable,
  vendorTable,
  handleDownload,
  selectedRow,
  setSelectedRow,
  deleteFile,
  historyModalRef,
  historyData,
  getHistory,
  ...rest
}) => {
  // delete button and action required for table headings
  const deleteModalRef = useRef()

  const { t } = useTranslation([i18nNameSpaces.Resource])

  const deleteButton = ({ row, onClick }) => (
    <button onClick={() => onClick(row)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title={t('Global:Global.button.delete')}>
      <span className='icon icon--trash' />
    </button>
  )

  const deleteButtonAction = (row) => {
    setSelectedRow(row)
    deleteModalRef.current.showModal()
  }

  // history button and action required for table headings
  const historyButton = ({ row, onClick }) => (
    <button onClick={() => onClick(row)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title={t('Global:Global.text.history')}>
      <span className='icon icon--history' />
    </button>
  )

  const historyButtonAction = (row) => {
    setSelectedRow(row)
    getHistory(row.id)
  }

  // table headings
  const headings = generateResourcesTableHeadings({
    isCotAdminUser,
    isCotUser,
    isCotOemUser,
    handleDownload,
    deleteButton,
    deleteButtonAction,
    historyButton,
    historyButtonAction,
    t,
  })

  return (
    <>
      {isCotAdminUser && <ResourcesUploadHeader {...rest} />}
      {isCotUser && <SectionHeading text={t('Resource.table.er_portal')} />}
      {clientTablePermission && <ResourcesList headings={headings} {...clientTable} />}
      {isCotUser && <SectionHeading text={t('Resource.table.icp_portal')} />}
      {vendorTablePermission && <ResourcesList headings={headings} {...vendorTable} />}
      <ConfirmationModal ref={deleteModalRef} className='c-modal' modalHeading={t('Global:Global.modal.confirmation')} onConfirm={() => deleteFile()}>
        <p>
          <Trans
            i18nKey='Resource.text.delete_confirm'
            ns={i18nNameSpaces.Resource}
            values={{ fileName: selectedRow?.fileName }}
            components={[<span key='delete_confirmation' className='u-weight--bold' />]}
          />
        </p>
      </ConfirmationModal>
      <HistoryModal
        modalRef={historyModalRef}
        logs={historyData}
        heading={selectedRow?.fileName ? t('Resource.modal.filename_history', { filename: selectedRow.fileName }) : t('Resource.modal.file_history')}
      />
    </>
  )
}
ResourcesView.propTypes = {
  isCotAdminUser: PropTypes.bool.isRequired,
  isCotUser: PropTypes.bool.isRequired,
  isCotOemUser: PropTypes.bool.isRequired,
  clientTablePermission: PropTypes.bool,
  vendorTablePermission: PropTypes.bool,
  clientTable: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    setSort: PropTypes.func.isRequired,
  }).isRequired,
  vendorTable: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    setSort: PropTypes.func.isRequired,
  }).isRequired,
  handleDownload: PropTypes.func.isRequired,
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  historyModalRef: PropTypes.object.isRequired,
  historyData: PropTypes.array.isRequired,
  getHistory: PropTypes.func.isRequired,
}

export default ResourcesView
