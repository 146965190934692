import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { required } from 'utils/validations'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const requiredSymbol = '*'
const TermCategoriesAndSub = ({
  categories,
  selectedCategory,
  subCategories,
  fetchTermSubCategoriesIfNeeded,
  onChangeTermSubCategories,
  showIsRequired,
  categoryPlaceholder,
  subcategoryPlaceholder,
}) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div>
      <Field
        name='category'
        label={t('BusinessComponent.field.category', {
          requiredSymbol: showIsRequired ? requiredSymbol : '',
        })}
        labelClassName='c-label'
        component={CustomSelectField}
        onChange={(value) => fetchTermSubCategoriesIfNeeded(value)}
        clearable={false}
        validate={required}
        placeholder={categories.length ? categoryPlaceholder || t('BusinessComponent.text.select_category') : t('Global:Global.text.loading')}
        options={categories.map((category) => ({
          value: category.id,
          label: category.name,
        }))}
        data-testid='category'
      />

      {selectedCategory ? (
        <Field
          name='termSubcategory'
          label={t('BusinessComponent.field.subcategory', {
            requiredSymbol: showIsRequired ? requiredSymbol : '',
          })}
          labelClassName='c-label'
          component={CustomSelectField}
          onChange={onChangeTermSubCategories}
          clearable={false}
          validate={required}
          placeholder={subCategories.length ? subcategoryPlaceholder || t('BusinessComponent.text.select_category') : t('Global:Global.text.loading')}
          options={subCategories.map((category) => ({
            value: category.id,
            label: category.name,
          }))}
          data-testid='termSubcategory'
        />
      ) : null}
    </div>
  )
}

TermCategoriesAndSub.propTypes = {
  categories: PropTypes.array.isRequired,
  subCategories: PropTypes.array,
  selectedCategory: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  fetchTermSubCategoriesIfNeeded: PropTypes.func.isRequired,
  onChangeTermSubCategories: PropTypes.func,
  showIsRequired: PropTypes.bool,
  categoryPlaceholder: PropTypes.string,
  subcategoryPlaceholder: PropTypes.string,
}

export default TermCategoriesAndSub
