import React from 'react'

import { MagnifierSearchIcon } from './icons/magnifierSearchIcon'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const NoResultsView = ({ className = '', message }) => {
  const { t } = useTranslation()
  return (
    <div data-testid='no-results' className={`d-flex d-flex--column jc--center ai-center u-text--huge search-results-container ${className}`}>
      <MagnifierSearchIcon />
      <p className='search-results-text'>{message || t('Global.text.no_results_found')}</p>
    </div>
  )
}

export default NoResultsView

NoResultsView.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}
