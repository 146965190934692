import React, { useEffect } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'

import { getTenantSubdomain } from 'utils/tenant'
import { isMultitenancyEnabled } from 'utils/config'
import { getApiUrl, getBrandsConfigSettings, getSettings } from 'redux/actions/api'
import { showMessage } from 'redux/actions/modal'
import { Trans } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const InitialDataContainer = ({ subdomain, apiUrl, isConfigFetched, isMultitenancyEnabled, getApiUrl, getSettings, children }) => {
  const dispatch = useDispatch()
  const accessToken = useSelector((state) => state.auth.accessToken)

  useEffect(() => {
    const fetchData = async () => {
      if (isMultitenancyEnabled && subdomain) {
        await getApiUrl(subdomain)
        await getSettings()
      }
    }
    fetchData()

    window.onerror = (e) => {
      if (e && e.includes('ResizeObserver loop completed with undelivered notifications')) {
        return true
      }
      if (e && e.includes('ResizeObserver loop limit exceeded')) {
        return true
      }
      dispatch(
        showMessage({
          body: (
            <span>
              <Trans
                i18nKey='Global.error.something_went_wrong'
                ns={i18nNameSpaces.Global}
                components={[<span className='u-text--curious u-text--underline u-cursor--pointer' onClick={() => location.reload()} />]}
              />
            </span>
          ),
        })
      )
    }

    // Cleanup
    return () => {
      window.onerror = null
    }
  }, [isMultitenancyEnabled, subdomain, dispatch, getApiUrl, getSettings])

  useEffect(() => {
    if (accessToken) {
      const fetchBrandsConfig = async () => {
        if (isMultitenancyEnabled && subdomain) {
          console.log('requesting brands')
          await dispatch(
            getBrandsConfigSettings({
              accessToken,
            })
          )
        }
      }
      fetchBrandsConfig()
    }
  }, [accessToken])

  if (!isMultitenancyEnabled) return children

  if (!subdomain) return <Trans i18nKey='Global.message.no_subdomain' ns={i18nNameSpaces.Global} />

  if (apiUrl && isConfigFetched) return children

  return <div />
}

const mapDispatchToProps = (dispatch) => ({
  getApiUrl: async (subdomain) => dispatch(getApiUrl(subdomain)),
  getSettings: async () => dispatch(getSettings()),
})

const mapStateToProps = ({ config: { apiUrl, version } }) => ({
  subdomain: getTenantSubdomain(),
  apiUrl,
  isConfigFetched: version !== null,
  isMultitenancyEnabled,
})

export default connect(mapStateToProps, mapDispatchToProps)(InitialDataContainer)
