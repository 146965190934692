import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import VendorUsersList from '../components/VendorUsersList'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const VendorUsersView = (props) => {
  const { isFetching, onCreate, onEdit } = props
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div>
      <SectionHeading text={t('Vendor.text.users')}>
        <Authorization permissions={['VENDORUSER_CREATE']}>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <div className='u-float--right'>
              <button onClick={onCreate} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='create-vendor-button'>
                <span className='icon icon--plus' />
              </button>
            </div>
          </div>
        </Authorization>
      </SectionHeading>

      <VendorUsersList onClick={(vendorUserId) => onEdit(vendorUserId)} {...props} />
    </div>
  )
}

VendorUsersView.propTypes = {
  isFetching: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
}

export default VendorUsersView
