import RouteView from './components/RouteView'
import ProcessMapRoute from './routes/ProcessMap'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: 'dates-processes/payroll/:payrollProcessId',
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Payroll, RouteView) },
  childRoutes: [ProcessMapRoute(store)],
})
