import React from 'react'
import PropTypes from 'prop-types'
import DocumentsView from 'components/documents/DocumentsView'
import { useTranslation } from 'react-i18next'

export const RouteView = (props) => {
  const { t } = useTranslation()
  return <DocumentsView {...props} typeClass='employee' typeId={props.match.params.employeeId} title={t('Global.aside.documents')} />
}

RouteView.propTypes = {
  params: PropTypes.shape({
    employeeId: PropTypes.string,
  }),
}

export default RouteView
