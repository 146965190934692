import { i18nNameSpaces } from 'i18n/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PayslipTable from 'components/table/PayslipTable'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import { Authorization } from 'containers/Authorization'
import ConfirmationModal from 'components/ConfirmationModal'
import { getCotRoleType } from 'utils/roles'

const CotUsersList = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Cot])
  const [cotUserId, setCotUserId] = useState(null)
  const [cotUserName, setCotUserName] = useState(null)

  /**
   * Handle modal opening
   * @param modalRef reference to the modal that should be open
   * @param cotUserId cotUser ID that should be deleted
   * @param cotUserName cotUser name that should be displayed in the confirmation modal
   */
  const handleModalOpen = (modalRef, cotUserId, cotUserName) => {
    setCotUserId(cotUserId)
    setCotUserName(cotUserName)
    modalRef.showModal()
  }

  const renderRows = () => {
    const { cotUsers, isCotAdmin, loggedCotId } = props
    return cotUsers.map((cotUser) => {
      const deleteModalTitle = (cotUser.title ? cotUser.title + ' ' : '') + cotUser.firstname + ' ' + cotUser.surname

      const canEdit = !isCotAdmin ? loggedCotId === cotUser.id : true

      const tenantsList = cotUser.tenants
      const tenantsAccessAreas = tenantsList.map((t, i) => {
        return i !== tenantsList.length - 1 ? `${t}, ` : `${t}`
      })

      const cotRoleType = getCotRoleType(cotUser.roleType)

      return {
        name: cotUser.name,
        status: cotUser.hasCredentials ? t('Global:Global.text.active') : t('Global:Global.text.inactive'),
        position: cotUser.position,
        base: cotUser.country && (
          <div className='o-grid o-grid--medium o-grid--center'>
            <Flag flag={cotUser.country.abbreviature.toLowerCase()} title={cotUser.country.name} size='tiny' />
          </div>
        ),
        role: cotRoleType,
        accessAreas: tenantsAccessAreas,
        workEmail: cotUser.workEmail,
        mobilePhone: cotUser.mobilePhone,
        officePhone: cotUser.officePhone,
        action: (
          <div className='u-text--center'>
            {canEdit && (
              <Authorization permissions={['COTUSER_EDIT']}>
                <button onClick={() => props.onClick(cotUser.id)} className='c-btn c-btn--tiny c-btn--curious u-margin-right-tiny'>
                  <span className='icon icon--edit' title={t('Global:Global.text.edit')} data-testid={`cotEdit-button-${cotUser.id}`} />
                </button>
              </Authorization>
            )}
            <Authorization permissions={['COTUSER_DELETE']}>
              <button
                onClick={() => handleModalOpen(deleteModalRef, cotUser.id, deleteModalTitle)}
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
                title={t('Global:Global.button.delete')}
              >
                <span className='icon icon--trash' />
              </button>
            </Authorization>
          </div>
        ),
      }
    })
  }

  const deleteModalRef = React.createRef()

  return (
    <div className='u-relative'>
      <PayslipTable
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        wrapperClassName='u-1/1 react-bs-table--overflow-auto'
        headings={[
          {
            Header: t('Cot.user_list_tableHeaders.name'),
            accessor: 'name',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.status'),
            accessor: 'status',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.position'),
            accessor: 'Position',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.base'),
            accessor: 'base',
            classNames: 'text-center',
            columnClassName: 'u-overflow-visible',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.role'),
            accessor: 'role',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.accessArea'),
            accessor: 'accessAreas',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.email'),
            accessor: 'workEmail',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.mobilePhone'),
            accessor: 'mobilePhone',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.officePhone'),
            accessor: 'officePhone',
            disableSortBy: true,
          },
          {
            Header: t('Cot.user_list_tableHeaders.action'),
            accessor: 'action',
            actionCell: true,
            classNames: 'text-center',
            disableSortBy: true,
          },
        ]}
        data={renderRows()}
      />
      <ConfirmationModal
        ref={deleteModalRef}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => props.deleteCotUser(cotUserId)}
      >
        <p>{t('Cot.user_list_modal.confirmationMessage', { cotUserName })}</p>
      </ConfirmationModal>
    </div>
  )
}

CotUsersList.propTypes = {
  cotUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstname: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
      gender: PropTypes.string,
      workEmail: PropTypes.string.isRequired,
      title: PropTypes.string,
      roleType: PropTypes.string.isRequired,
      country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        abbreviature: PropTypes.string,
      }),
    })
  ),
  loggedCotId: PropTypes.number,
  isCotAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  deleteCotUser: PropTypes.func,
}

export default CotUsersList
