import React from 'react'
import SectionHeading from 'components/SectionHeading'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export const HomeView = ({ isCot, versions }) => {
  const { t } = useTranslation()

  return (
    <div className='c-content-wrapper'>
      <SectionHeading text={t('Global.heading.welcome')} />
      {isCot && <SectionHeading text={t('Global.heading.frontEndVersion', { version: versions.frontEnd })} />}
      {isCot && <SectionHeading text={t('Global.heading.backEndVersion', { version: versions.backEnd })} />}
      <div className='u-hidden-visually'>
        <h3>{t('Global.hidden.triggerErrorInstruction')}</h3>
        <p>
          <i>{t('Global.hidden.errorPurpose')}</i>
        </p>
        <button onClick={() => ''.map()}>{t('Global.button.triggerError')}</button>
      </div>
    </div>
  )
}

HomeView.propTypes = {
  isCot: PropTypes.bool,
  versions: PropTypes.shape({
    frontEnd: PropTypes.string,
    backEnd: PropTypes.string,
  }),
}

export default HomeView
