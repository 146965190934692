import allCountries from './allCountries'
import nationalities from 'redux/config/nationalities'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { inlineEndDateValidation, inlineStartDateValidation } from 'utils/validations'
import addressFields from './employeeAddressFieldsConfig'
import { getSelectionOptions } from 'utils/selectOptions'
import { yesNoBoolValuesOptions } from './DropDownOptions/confirmationOptions'
import { getMode2faEmailOption } from './DropDownOptions/mode2faOptions'
import i18n from 'i18next'

const requiredSymbol = '*'
export const taxClassFields = ['I', 'II', 'III', 'IV', 'V', 'VI', 'Pauschal Steuer 2%', 'Pauschal 25%']
export const employeeFieldsConfigAlt = ({
  isCreate = false,
  isEdit = false,
  isUserSSOEnabled = false,
  showUserSSOField = false,
  showMode2faFieldOnCreate = false,
  showMode2faFieldOnEdit = false,
  showInvoicePaymentCurrencyArea = false,
  isCotUser,
  personalExtraFields = [],
}) => {
  return [
    {
      category: 'personal',
      categoryName: 'Personal',
      fields: [
        ...personalExtraFields,
        // Ordered field in EE Personal tab including country specific fields
        {
          subCategory: 'personalDetails',
          field: 'title',
          name: i18n.t('Employee:Employee.config_alt_field.title'),
          component: 'dropdown',
          placeholder: i18n.t('Global:Global.select.placeholder'),
          options: getSelectionOptions('title'),
        },
        {
          subCategory: 'personalDetails',
          field: 'prefixTitle',
          name: i18n.t('Employee:Employee.config_alt_field.prefix_title'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'Netherlands'],
        },
        {
          subCategory: 'personalDetails',
          field: 'suffix',
          name: i18n.t('Employee:Employee.config_alt_field.suffix'),
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Netherlands'],
          options: [
            { value: 'I', label: 'I' },
            { value: 'II', label: 'II' },
            { value: 'III', label: 'III' },
            { value: 'JD', label: 'JD' },
            { value: 'JR', label: 'JR' },
            { value: 'MD', label: 'MD' },
            { value: 'PHD', label: 'PHD' },
            { value: 'SR', label: 'SR' },
          ],
        },
        {
          subCategory: 'personalDetails',
          field: 'firstname',
          name: i18n.t('Employee:Employee.config_alt_field.first_name', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'input',
        },
        {
          subCategory: 'personalDetails',
          field: 'surname',
          name: i18n.t('Employee:Employee.config_alt_field.surname', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'input',
        },
        {
          subCategory: 'personalDetails',
          field: 'otherSurname',
          name: i18n.t('Employee:Employee.config_alt_field.other_surname'),
          component: 'input',
        },
        {
          subCategory: 'personalDetails',
          field: 'nativeFirstNames',
          name: i18n.t('Employee:Employee.config_alt_field.local_first_name'),
          component: 'input',
          isCountrySpecific: true,
          countries: ['China', 'Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'nativeFamilyNames',
          name: i18n.t('Employee:Employee.config_alt_field.local_surname'),
          component: 'input',
          isCountrySpecific: true,
          countries: ['China', 'Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'otherLocalFirstName',
          name: i18n.t('Employee:Employee.config_alt_field.other_local_first_name'),
          component: 'input',
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'otherLocalSurname',
          name: i18n.t('Employee:Employee.config_alt_field.other_local_surname'),
          component: 'input',
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'initials',
          name: i18n.t('Employee:Employee.config_alt_field.initials'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Netherlands'],
        },
        // Biographical Details
        {
          subCategory: 'biographicalDetails',
          field: 'gender',
          name: i18n.t('Employee:Employee.config_alt_field.gender'),
          component: 'dropdown',
          placeholder: i18n.t('Global:Global.select.placeholder'),
          options: [
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
            { value: 'not stated', label: 'Not stated' },
            { value: 'other', label: 'Other' },
            { value: 'unknown', label: 'Unknown' },
          ],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'dateOfBirth',
          name: i18n.t('Employee:Employee.config_alt_field.date_of_birth'),
          component: 'date',
        },
        {
          subCategory: 'biographicalDetails',
          field: 'age',
          name: i18n.t('Employee:Employee.config_alt_field.age'),
          component: 'input',
          type: 'number',
          min: '0',
          isCountrySpecific: true,
          countries: ['United Arab Emirates'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'nationality',
          name: i18n.t('Employee:Employee.config_alt_field.nationality'),
          component: 'dropdown',
          options: nationalities,
        },
        {
          subCategory: 'biographicalDetails',
          field: 'citizenship',
          name: i18n.t('Employee:Employee.config_alt_field.citizenship'),
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['China', 'Singapore', 'Israel', 'Korea, Republic of', 'Italy'],
          options: [
            { value: 'Citizen', label: 'Citizen' },
            { value: 'Non-citizen', label: 'Non-citizen' },
          ],
          allOptions: [
            { value: 'Singapore Permanent Resident', label: 'Singapore Permanent Resident' },
            { value: 'Foreigner', label: 'Foreigner' },
            { value: 'Permanent Resident', label: 'Permanent Resident' },
            { value: 'Citizen', label: 'Citizen' },
            { value: 'Non-citizen', label: 'Non-citizen' },
          ],
          allOptionsCountries: ['Singapore'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'languagePreferences',
          name: i18n.t('Employee:Employee.config_alt_field.language_preferences'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'cityOfBirth',
          name: i18n.t('Employee:Employee.config_alt_field.city_of_birth'),
          component: 'input',
          showInPayrollInstance: false,
        },
        {
          subCategory: 'biographicalDetails',
          field: 'countryOfBirth',
          name: i18n.t('Employee:Employee.config_alt_field.country_of_birth'),
          component: 'dropdown',
          showInPayrollInstance: false,
          options: allCountries,
        },
        {
          subCategory: 'biographicalDetails',
          field: 'birthName',
          name: i18n.t('Employee:Employee.config_alt_field.birth_name'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'race',
          name: i18n.t('Employee:Employee.config_alt_field.race'),
          component: 'dropdown',
          isCountrySpecific: true,
          countries: ['Singapore', 'Malaysia'],
          options: [
            { value: 'Chinese', label: 'Chinese' },
            { value: 'Indian', label: 'Indian' },
            { value: 'Malay', label: 'Malay' },
            { value: 'Eurasian', label: 'Eurasian' },
            { value: 'Caucasian', label: 'Caucasian' },
            { value: 'Other', label: 'Other' },
          ],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'maritalStatus',
          name: i18n.t('Employee:Employee.config_alt_field.marital_status'),
          component: 'dropdown',
          placeholder: i18n.t('Global:Global.select.placeholder'),
          options: [
            { value: 'Single', label: 'Single' },
            { value: 'Married', label: 'Married' },
            { value: 'Free Union', label: 'Free Union' },
            { value: 'Domestic Partnership', label: 'Domestic Partnership' },
            { value: 'Civil Partnership', label: 'Civil Partnership' },
            { value: 'Cohabitation', label: 'Cohabitation' },
            { value: 'Civil Partnership Dissolved', label: 'Civil Partnership Dissolved' },
            { value: 'Civil Partner Deceased', label: 'Civil Partner Deceased' },
            { value: 'Separated', label: 'Separated' },
            { value: 'Judicially Separated', label: 'Judicially Separated' },
            { value: 'Divorced', label: 'Divorced' },
            { value: 'Widowed', label: 'Widowed' },
            { value: 'Pacsed', label: 'Pacsed' },
            { value: 'Not Reported', label: 'Not Reported' },
          ],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'numberOfChildren',
          name: i18n.t('Employee:Employee.config_alt_field.number_of_children'),
          component: 'input',
          showInPayrollInstance: false,
          type: 'number',
          min: '0',
          isCountrySpecific: true,
          countries: ['Costa Rica', 'Singapore'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'religion',
          name: i18n.t('Employee:Employee.config_alt_field.religion'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'Indonesia', 'Singapore'],
        },
        // Tax & ID Details
        {
          subCategory: 'taxDetails',
          field: 'taxNumber',
          name: i18n.t('Employee:Employee.config_alt_field.tax_number'),
          component: 'input',
        },
        {
          subCategory: 'taxDetails',
          field: 'federalTaxpayerRegistryCode',
          name: i18n.t('Employee:Employee.config_alt_field.federal_taxpayer_registry_code'),
          component: 'input',
          hideOnCreate: true,
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Mexico'],
        },
        {
          subCategory: 'taxDetails',
          field: 'generalPopulationRegistry',
          name: i18n.t('Employee:Employee.config_alt_field.general_population_registry'),
          component: 'input',
          hideOnEditView: true,
          isCountrySpecific: true,
          countries: ['Mexico'],
        },
        {
          subCategory: 'taxDetails',
          field: 'hecs',
          name: i18n.t('Employee:Employee.config_alt_field.hecs'),
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          isCountrySpecific: true,
          showInPayrollInstance: false,
          countries: ['Australia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'taxCode',
          name: i18n.t('Employee:Employee.config_alt_field.tax_code'),
          component: 'input',
          showInPayrollInstance: true,
          isCountrySpecific: true,
          countries: ['United Kingdom'],
        },
        {
          subCategory: 'taxDetails',
          field: 'niCategoryLetter',
          name: i18n.t('Employee:Employee.config_alt_field.ni_category_letter'),
          showInPayrollInstance: true,
          component: 'dropdown',
          options: ['A', 'A1', 'B', 'C', 'E', 'J', 'H', 'M', 'X', 'Z'],
          isCountrySpecific: true,
          countries: ['United Kingdom', 'Ireland'],
        },
        {
          subCategory: 'taxDetails',
          field: 'churchTax',
          name: i18n.t('Employee:Employee.config_alt_field.church_tax'),
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          countries: ['Germany'],
        },
        {
          subCategory: 'taxDetails',
          field: 'taxClass',
          name: i18n.t('Employee:Employee.config_alt_field.tax_class'),
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          options: taxClassFields,
          countries: ['Germany'],
        },
        {
          subCategory: 'taxDetails',
          field: 'identityType',
          name: i18n.t('Employee:Employee.config_alt_field.identity_type'),
          component: 'dropdown',
          options: [
            { value: 'NRIC (Blue)', label: 'NRIC (Blue)' },
            { value: 'NRIC (Pink)', label: 'NRIC (Pink)' },
            { value: 'Employment Pass', label: 'Employment Pass' },
            { value: 'S Pass', label: 'S Pass' },
            { value: 'Work Permit', label: 'Work Permit' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'fundType',
          name: i18n.t('Employee:Employee.config_alt_field.fund_type'),
          component: 'dropdown',
          options: [
            { value: 'CDAC', label: 'CDAC' },
            { value: 'MBMF', label: 'MBMF' },
            { value: 'SINDA', label: 'SINDA' },
            { value: 'ECF', label: 'ECF' },
            { value: 'Not Applicable', label: 'Not Applicable' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'cpfType',
          name: i18n.t('Employee:Employee.config_alt_field.cpf_type'),
          component: 'dropdown',
          options: [
            { value: 'Local', label: 'Local' },
            { value: 'PR', label: 'PR' },
            { value: 'Singaporean', label: 'Singaporean' },
            { value: 'Dependant', label: 'Dependant' },
            { value: 'Employment Pass', label: 'Employment Pass' },
            { value: 'Workpermit/SPASS', label: 'Workpermit/SPASS' },
            { value: 'Permanent Resident', label: 'Permanent Resident' },
            { value: 'Permanent Resident(Graduated/Full Rates)', label: 'Permanent Resident(Graduated/Full Rates)' },
            { value: 'Vacational Trainee', label: 'Vacational Trainee' },
            { value: 'Not Applicable', label: 'Not Applicable' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'dateIssue',
          name: i18n.t('Employee:Employee.config_alt_field.date_issue'),
          component: 'date',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'expiryDate',
          name: i18n.t('Employee:Employee.config_alt_field.expiry_date'),
          component: 'date',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialSecurityTAJNumber',
          name: i18n.t('Employee:Employee.config_alt_field.social_security_taj_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Hungary'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialInsuranceNumber',
          name: i18n.t('Employee:Employee.config_alt_field.social_insurance_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'Uganda', 'Senegal', 'Ghana', 'Ivory Coast', 'United Arab Emirates', 'Austria', 'Switzerland', 'State of Qatar'],
        },
        {
          subCategory: 'taxDetails',
          field: 'irasType',
          name: i18n.t('Employee:Employee.config_alt_field.iras_type'),
          component: 'dropdown',
          options: [
            { value: 'NRIC', label: 'NRIC' },
            { value: 'FIN', label: 'FIN' },
            { value: 'Non Application for SG TAX', label: 'Non Application for SG TAX' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'bpjsKetenagakerjaan',
          name: i18n.t('Employee:Employee.config_alt_field.bpjs_ketenagakerjaan'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Indonesia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'bpjsKesehatan',
          name: i18n.t('Employee:Employee.config_alt_field.bpjs_kesehatan'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Indonesia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'alienIdentificationNumber',
          name: i18n.t('Employee:Employee.config_alt_field.alien_identification_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore', 'Spain', 'United Arab Emirates', 'Korea, Republic of'],
        },
        {
          subCategory: 'taxDetails',
          field: 'ctpsNumber',
          name: i18n.t('Employee:Employee.config_alt_field.ctps_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'pisNumber',
          name: i18n.t('Employee:Employee.config_alt_field.pis_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialSecurityId',
          name: i18n.t('Employee:Employee.config_alt_field.social_security_id'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: [
            'Costa Rica',
            'Germany',
            'Thailand',
            'Spain',
            'France',
            'Portugal',
            'Malaysia',
            'Denmark',
            'Norway',
            'United Kingdom',
            'Switzerland',
            'Ireland',
            'Belgium',
            'Sweden',
            'Netherlands',
            'Italy',
            'Austria',
            'Czechia',
            'Finland',
            'Turkey',
            'Brazil',
            'Greece',
            'Morocco',
            'Lithuania',
          ],
        },
        {
          subCategory: 'taxDetails',
          field: 'epfNumber',
          name: i18n.t('Employee:Employee.config_alt_field.epf_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['India'],
        },
        {
          subCategory: 'taxDetails',
          field: 'permanentAccountNumber',
          name: i18n.t('Employee:Employee.config_alt_field.permanent_account_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['India'],
        },
        {
          subCategory: 'taxDetails',
          field: 'universalAccountNumber',
          name: i18n.t('Employee:Employee.config_alt_field.universal_account_number'),
          tab: 'Job & Org',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['India'],
        },
        {
          subCategory: 'taxDetails',
          field: 'employmentInsuranceNumber',
          name: i18n.t('Employee:Employee.config_alt_field.employment_insurance_number'),
          tab: 'Job & Org',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'taxDetails',
          field: 'residentCardNumber',
          name: i18n.t('Employee:Employee.config_alt_field.resident_card_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'taxDetails',
          field: 'welfarePensionNumber',
          name: i18n.t('Employee:Employee.config_alt_field.welfare_pension_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'taxDetails',
          field: 'idCardNumber',
          name: i18n.t('Employee:Employee.config_alt_field.id_card_number'),
          component: 'input',
          isCountrySpecific: true,
          countries: [
            'Peru',
            'Costa Rica',
            'Israel',
            'China',
            'Indonesia',
            'Hungary',
            'Thailand',
            'Belgium',
            'United Arab Emirates',
            'Poland',
            'Turkey',
            'Czechia',
            'Greece',
            'Slovakia',
          ],
        },
        {
          subCategory: 'taxDetails',
          field: 'rgNumber',
          name: i18n.t('Employee:Employee.config_alt_field.rg_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil', 'Greece'],
        },
        {
          subCategory: 'taxDetails',
          field: 'nci',
          name: i18n.t('Employee:Employee.config_alt_field.national_civil_identification_nci'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'crnm',
          name: i18n.t('Employee:Employee.config_alt_field.national_migration_registration_card_crnm'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'voterRegistrationNumber',
          name: i18n.t('Employee:Employee.config_alt_field.voter_registration_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'lifeInsurance',
          name: i18n.t('Employee:Employee.config_alt_field.life_insurance'),
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialSecurityNumber',
          name: i18n.t('Employee:Employee.config_alt_field.imss'),
          component: 'input',
          showInPayrollInstance: false,
          hideOnEditView: true,
          isCountrySpecific: true,
          countries: ['Mexico'],
        },
        {
          subCategory: 'taxDetails',
          field: 'pensionBank',
          name: i18n.t('Employee:Employee.config_alt_field.pension_bank'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Australia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'insuranceNumber',
          name: i18n.t('Employee:Employee.config_alt_field.insurance_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'United Kingdom', 'Russian Federation', 'Belgium'],
        },
        {
          subCategory: 'taxDetails',
          field: 'dependentLifeInsurance',
          name: i18n.t('Employee:Employee.config_alt_field.dependent_life_insurance'),
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'extendedHealthCare',
          name: i18n.t('Employee:Employee.config_alt_field.extended_health_care'),
          component: 'dropdown',
          options: [
            { value: 'Single', label: 'Single' },
            { value: 'Family', label: 'Family' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'accidentalDeathAndDismembermentInsurance',
          name: i18n.t('Employee:Employee.config_alt_field.accidental_death_and_dismemberment_insurance'),
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'asbMembershipNumber',
          name: i18n.t('Employee:Employee.config_alt_field.asb_membership_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'nationalIdentityNumberNew',
          name: i18n.t('Employee:Employee.config_alt_field.national_identity_number_new'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'nationalIdentityNumberOld',
          name: i18n.t('Employee:Employee.config_alt_field.national_identity_number_old'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'pnbNumber',
          name: i18n.t('Employee:Employee.config_alt_field.pnb_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'providentFundNumber',
          name: i18n.t('Employee:Employee.config_alt_field.provident_fund_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'tabungHajiNumber',
          name: i18n.t('Employee:Employee.config_alt_field.tabung_haji_number'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'zemisNumber',
          name: i18n.t('Employee:Employee.config_alt_field.zemis_number'),
          component: 'input',
          showInPayrollInstance: true,
          isCountrySpecific: true,
          countries: ['Switzerland'],
        },
        {
          subCategory: 'taxDetails',
          field: 'foreignerCode',
          name: i18n.t('Employee:Employee.config_alt_field.foreigner_code'),
          component: 'dropdown',
          showInPayrollInstance: true,
          isCountrySpecific: true,
          countries: ['Switzerland'],
          options: [
            { value: 'Temporary resident (B)', label: 'Temporary resident (B)' },
            { value: 'Permanent resident (C)', label: 'Permanent resident (C)' },
            {
              value: 'Gainfully employed spouse and diplomat (Ci)',
              label: 'Gainfully employed spouse and diplomat (Ci)',
            },
            { value: 'Provisionally admitted (F)', label: 'Provisionally admitted (F)' },
            { value: 'Cross-border commuter (G)', label: 'Cross-border commuter (G)' },
            { value: 'Short-term resident (L)', label: 'Short-term resident (L)' },
          ],
        },
        // Contact Details Section fields
        {
          subCategory: 'contactDetails',
          field: 'personalEmail',
          name: i18n.t('Employee:Employee.config_alt_field.personal_email'),
          component: 'email',
        },
        {
          subCategory: 'contactDetails',
          field: 'workEmail',
          name: i18n.t('Employee:Employee.config_alt_field.work_email', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'email',
        },
        {
          subCategory: 'contactDetails',
          field: 'addresses',
          name: i18n.t('Employee:Employee.config_alt_field.addresses'),
          isVisibleOn: [pageUIStatesEnum.CREATE],
          component: 'object',
          fields: addressFields,
        },
        {
          subCategory: 'contactDetails',
          field: 'officePhone',
          name: i18n.t('Employee:Employee.config_alt_field.office_phone'),
          component: 'phone',
        },
        {
          subCategory: 'contactDetails',
          field: 'mobilePhone',
          name: i18n.t('Employee:Employee.config_alt_field.mobile_phone'),
          component: 'phone',
        },
        {
          subCategory: 'contactDetails',
          field: 'skype',
          name: i18n.t('Employee:Employee.config_alt_field.skype'),
          component: 'input',
        },
        // Bank Details
        {
          subCategory: 'bankDetails',
          field: 'payrollCountry',
          name: i18n.t('Employee:Employee.config_alt_field.payroll_country', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          options: [],
          disabled: true,
        },
        {
          subCategory: 'bankDetails',
          field: 'company',
          name: i18n.t('Employee:Employee.config_alt_field.company', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          options: [],
          disabled: true,
        },
        {
          subCategory: 'bankDetails',
          field: 'bankCountry',
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          name: i18n.t('Employee:Employee.config_alt_field.bank_country', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'currency',
          name: i18n.t('Employee:Employee.config_alt_field.currency', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          options: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'bankName',
          name: i18n.t('Employee:Employee.config_alt_field.bank_name'),
          component: 'input',
        },
        {
          subCategory: 'bankDetails',
          field: 'bankAddress',
          name: i18n.t('Employee:Employee.config_alt_field.bank_address'),
          component: 'input',
        },
        {
          subCategory: 'bankDetails',
          field: 'accountHolder',
          name: i18n.t('Employee:Employee.config_alt_field.account_holder_name'),
          component: 'input',
        },
        {
          subCategory: 'bankDetails',
          field: 'typeOfAccount',
          name: i18n.t('Employee:Employee.config_alt_field.type_of_account'),
          component: 'dropdown',
          options: [
            { value: 'Saving', label: 'Saving' },
            { value: 'Checking', label: 'Checking' },
            { value: 'Current', label: 'Current' },
            { value: 'Other', label: 'Other' },
          ],
          isCountrySpecific: false,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'primaryBankAccount',
          name: i18n.t('Employee:Employee.config_alt_field.primary_bank_account'),
          component: 'dropdown',
          options: yesNoBoolValuesOptions,
        },
        {
          subCategory: 'bankDetails',
          field: 'paymentType',
          name: i18n.t('Employee:Employee.config_alt_field.payment_type'),
          component: 'dropdown',
          options: [
            { value: 'Default', label: 'Default' },
            { value: 'Manual', label: 'Manual' },
          ],
        },
        {
          subCategory: 'bankDetails',
          field: 'swiftBICCode',
          name: i18n.t('Employee:Employee.config_alt_field.swift_bic_code'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'iban',
          name: i18n.t('Employee:Employee.config_alt_field.iban'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'accountNumber',
          name: i18n.t('Employee:Employee.config_alt_field.account_number'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'sortCode',
          name: i18n.t('Employee:Employee.config_alt_field.sort_code'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'bsbNumber',
          name: i18n.t('Employee:Employee.config_alt_field.bsb_number'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'financialInstitutionNumber',
          name: i18n.t('Employee:Employee.config_alt_field.financial_institution_number'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'bankBranchNumber',
          name: i18n.t('Employee:Employee.config_alt_field.bank_branch_number'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'abaRoutingNumber',
          name: i18n.t('Employee:Employee.config_alt_field.aba_routing'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'clabe',
          name: i18n.t('Employee:Employee.config_alt_field.clabe'),
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'description',
          name: i18n.t('Employee:Employee.config_alt_field.description'),
          component: 'input',
        },
        // Emergency Person Details Section fields
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencyFirstname',
          name: i18n.t('Employee:Employee.config_alt_field.first_name', {
            requiredSymbol: '',
          }),
          component: 'input',
        },
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencySurname',
          name: i18n.t('Employee:Employee.config_alt_field.surname', {
            requiredSymbol: '',
          }),
          component: 'input',
        },
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencyMobilePhone',
          name: i18n.t('Employee:Employee.config_alt_field.mobile_phone'),
          component: 'phone',
        },
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencyRelationship',
          name: i18n.t('Employee:Employee.config_alt_field.relationship'),
          component: 'input',
        },
        // Contractors Details Section fields
        {
          subCategory: 'contractorDetails',
          field: 'contractorCompanyName',
          name: i18n.t('Employee:Employee.config_alt_field.contractors_company_name'),
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'agencyCompanyName',
          name: i18n.t('Employee:Employee.config_alt_field.agency_company_name'),
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorAddressLineOne',
          name: i18n.t('Employee:Employee.config_alt_field.address_line_one'),
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorAddressLineTwo',
          name: i18n.t('Employee:Employee.config_alt_field.address_line_two'),
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorCity',
          name: i18n.t('Employee:Employee.config_alt_field.city'),
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorPostCode',
          name: i18n.t('Employee:Employee.config_alt_field.post_code'),
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorAddressCountry',
          name: i18n.t('Employee:Employee.config_alt_field.address_country'),
          component: 'dropdown',
          options: allCountries,
        },
        {
          subCategory: 'contractorDetails',
          field: 'vatRegistrationNumber',
          name: i18n.t('Employee:Employee.config_alt_field.vat_registration_number'),
          component: 'input',
        },
      ],
    },
    {
      category: 'job-and-org',
      categoryName: 'Job & Org',
      fields: [
        // Job Profile fields
        {
          subCategory: 'jobProfile',
          field: 'employeeId',
          name: i18n.t('Employee:Employee.config_alt_field.employee_id'),
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'otherEmployeeId',
          name: i18n.t('Employee:Employee.config_alt_field.other_employee_id'),
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'uniqueContractorId',
          name: i18n.t('EmployeeEmployee.config_alt_field.unique_contractor_id'),
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'position',
          name: i18n.t('Employee:Employee.config_alt_field.position'),
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'workLevel',
          name: i18n.t('Employee:Employee.config_alt_field.work_level'),
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'weeklyHours',
          name: i18n.t('Employee:Employee.config_alt_field.weekly_hours'),
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'monthlyHours',
          name: i18n.t('Employee:Employee.config_alt_field.monthly_hours'),
          component: 'input',
        },

        {
          subCategory: 'jobProfile',
          field: 'workWeekGroup',
          name: i18n.t('Employee:Employee.config_alt_field.work_week_group'),
          component: 'dropdown',
          showInPayrollInstance: false,
          options: [
            { value: '5 Days', label: '5 Days' },
            { value: '6 Days', label: '6 Days' },
          ],
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'jobProfile',
          field: 'payScaleLevel',
          name: i18n.t('Employee:Employee.config_alt_field.pay_scale_level'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany'],
        },
        {
          subCategory: 'jobProfile',
          field: 'payScaleGroup',
          name: i18n.t('Employee:Employee.config_alt_field.pay_scale_group'),
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany'],
        },
        {
          subCategory: 'jobProfile',
          field: 'paymentType',
          name: i18n.t('Employee:Employee.config_alt_field.payment_type'),
          component: 'dropdown',
          options: [
            { value: 'Salary', label: 'Salary' },
            { value: 'Hourly', label: 'Hourly' },
            { value: 'Invoice', label: 'Invoice' },
          ],
        },
        {
          subCategory: 'jobProfile',
          field: 'hourlyRate',
          name: i18n.t('Employee:Employee.config_alt_field.hourly_rate'),
          component: 'input',
        },
        // Organization Details fields
        {
          subCategory: 'organizationDetails',
          field: 'countries',
          name: i18n.t('Employee:Employee.config_alt_field.country', {
            requiredSymbol: isEdit || isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          options: allCountries,
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          hideOnEdit: true,
        },
        {
          subCategory: 'organizationDetails',
          field: 'company',
          name: i18n.t('Employee:Employee.config_alt_field.company', {
            requiredSymbol: isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          options: [],
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          hideOnEdit: true,
        },
        {
          subCategory: 'organizationDetails',
          field: 'paymentCurrency',
          name: i18n.t('Employee:Employee.config_alt_field.payment_currency', {
            requiredSymbol: isCreate ? requiredSymbol : '',
          }),
          component: 'dropdown',
          options: [],
          onChangeUpdateFieldOptions: [
            { name: 'payroll', subCategory: 'organizationDetails' },
            { name: 'paygroup', subCategory: 'organizationDetails' },
          ],
          // eslint-disable-next-line max-len
          disabledReason:
            'The employee is already part of a Payroll or Paygroup. If you want to change this, please remove them first from the payroll or paygroup.',
        },
        {
          subCategory: 'organizationDetails',
          field: 'invoicePaymentCurrency',
          name: i18n.t('Employee:Employee.config_alt_field.invoice_payment_currency'),
          component: 'dropdown',
          options: [],
          isCountrySpecific: true,
          countries: ['United Kingdom', 'Netherlands', 'Sweden', 'France', 'United Arab Emirates'],
        },
        {
          subCategory: 'organizationDetails',
          field: 'payroll',
          name: i18n.t('Employee:Employee.config_alt_field.payroll'),
          component: 'dropdown',
          options: [],
          optionsDependency: true,
          // eslint-disable-next-line max-len
          disabledReason:
            'The employee is already part of a Payroll or Paygroup. If you want to change this, please remove them first from the payroll or paygroup.',
        },
        {
          subCategory: 'organizationDetails',
          field: 'paygroup',
          name: i18n.t('Employee:Employee.config_alt_field.paygroup'),
          component: 'dropdown',
          options: [],
          optionsDependency: true,
          // eslint-disable-next-line max-len
          disabledReason:
            'The employee is already part of a Payroll or Paygroup. If you want to change this, please remove them first from the payroll or paygroup.',
        },
        {
          subCategory: 'organizationDetails',
          field: 'department',
          name: i18n.t('Employee:Employee.config_alt_field.department'),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'businessUnit',
          name: i18n.t('Employee:Employee.config_alt_field.business_unit'),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'costCenter',
          name: i18n.t('Employee:Employee.config_alt_field.cost_center'),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'officeLocation',
          name: i18n.t('Employee:Employee.config_alt_field.office_location'),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'subsidiary',
          name: i18n.t('Employee:Employee.config_alt_field.subsidiary'),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'project',
          name: i18n.t('Employee:Employee.config_alt_field.project'),
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'managementPandL',
          name: i18n.t('Employee:Employee.config_alt_field.management_p_and_l'),
          component: 'input',
        },
        {
          subCategory: 'organizationDetails',
          field: 'managerId',
          name: i18n.t('Employee:Employee.config_alt_field.manager_id'),
          component: 'input',
        },
        {
          subCategory: 'organizationDetails',
          field: 'resetUserPassword',
          name: i18n.t('Employee:Employee.config_alt_field.reset_user_password'),
          component: 'button',
          hideOnCreate: true,
        },
        {
          subCategory: 'organizationDetails',
          field: 'makeKeyUser',
          name: i18n.t('Employee:Employee.config_alt_field.make_key_user'),
          component: 'authorizedButton',
          hideOnCreate: true,
        },
        // Employee Self service
        {
          subCategory: 'employeeSelfService',
          field: 'hasCredentials',
          name: i18n.t('Employee:Employee.config_alt_field.has_credentials'),
          component: 'dropdown',
          options: [
            { value: 'true', label: i18n.t('Employee:Employee.config_alt_field.active') },
            { value: 'false', label: i18n.t('Employee:Employee.config_alt_field.inactive') },
          ],
        },
        {
          subCategory: 'employeeSelfService',
          field: 'isSSOEnabled',
          name: i18n.t('Employee:Employee.config_alt_field.sso'),
          isVisible: showUserSSOField,
          hideOnCreate: true,
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
        },
        {
          subCategory: 'employeeSelfService',
          field: 'username',
          name: i18n.t('Employee:Employee.config_alt_field.username'),
          component: 'input',
        },
        {
          subCategory: 'employeeSelfService',
          field: 'mode2fa',
          name: i18n.t('Employee:Employee.config_alt_field.mode_2fa'),
          isVisible: isCreate ? showMode2faFieldOnCreate : showMode2faFieldOnEdit,
          component: 'dropdown',
          options: getSelectionOptions('mode2fa'),
          allOptions: [...getSelectionOptions('mode2fa'), getMode2faEmailOption()],
        },
        // Employment Details fields
        {
          subCategory: 'employmentDetails',
          field: 'employmentStatus',
          name: i18n.t('Employee:Employee.config_alt_field.employment_status'),
          component: 'dropdown',
          options: [...EmploymentStatusHelper.allStatusOptionsForSelectBox],
        },
        {
          subCategory: 'employmentDetails',
          field: 'employmentCondition',
          name: i18n.t('Employee:Employee.config_alt_field.employment_condition'),
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Active', label: 'Active' },
            { value: 'Discarded', label: 'Discarded' },
            { value: 'Dormant', label: 'Dormant' },
            { value: 'On Leave', label: 'On Leave' },
            { value: 'Retired', label: 'Retired' },
            { value: 'Terminated', label: 'Terminated' },
            { value: 'Unpaid Leave', label: 'Unpaid Leave' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'workType',
          name: i18n.t('Employee:Employee.config_alt_field.work_type'),
          component: 'dropdown',
          options: [
            { value: 'Full Time', label: 'Full Time' },
            { value: 'Part Time', label: 'Part Time' },
            { value: 'Variable', label: 'Variable' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'typeOfContract',
          name: i18n.t('Employee:Employee.config_alt_field.type_of_contract'),
          component: 'dropdown',
          options: ['Germany', 'Denmark', 'Netherlands', 'Sweden', 'Norway', 'Finland'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'confirmationDate',
          name: i18n.t('Employee:Employee.config_alt_field.confirmation_date'),
          showInPayrollInstance: false,
          component: 'date',
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'visaWorkPermit',
          name: i18n.t('Employee:Employee.config_alt_field.visa_work_permit'),
          component: 'dropdown',
          options: [
            { value: 'Applicable', label: 'Applicable' },
            { value: 'Not applicable', label: 'Not applicable' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'workAuthorizationType',
          name: i18n.t('Employee:Employee.config_alt_field.work_authorization_type'),
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Employment Pass', label: 'Employment Pass' },
            { value: 'Permanent Resident', label: 'Permanent Resident' },
            { value: 'Work Permit', label: 'Work Permit' },
          ],
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'fwlSpassCode',
          name: i18n.t('Employee:Employee.config_alt_field.fwl_spass_code'),
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Basic Tier Skilled', label: 'Basic Tier Skilled' },
            { value: 'SPASS Basic Tier', label: 'SPASS Basic Tier' },
          ],
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'contractorTempAgency',
          name: i18n.t('Employee:Employee.config_alt_field.contractor_temp_agency'),
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'occupationalStatusCode',
          name: i18n.t('Employee:Employee.config_alt_field.occupational_status_code'),
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'tradeUnion',
          name: i18n.t('Employee:Employee.config_alt_field.trade_union'),
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'companyLink',
          accessLayers: {
            edit: ['EMPLOYEEUSER_NORMAL_COMPANYLINK_EDIT'],
            create: null,
            view: null,
            delete: null,
          },
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: { permissionLayers: ['EMPLOYEEUSER_NORMAL_COMPANYLINK_EDIT'] },
          },
          name: i18n.t('Employee:Employee.config_alt_field.company_link'),
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'collectiveBargainingItaly',
          name: i18n.t('Employee:Employee.config_alt_field.collective_bargaining_italy'),
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Dirigente', label: 'Dirigente' },
            { value: 'Employee', label: 'Employee' },
            { value: 'Quadro', label: 'Quadro' },
            { value: 'Other', label: 'Other' },
          ],
          isCountrySpecific: true,
          countries: ['Italy'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'collectiveBargainingFrance',
          name: i18n.t('Employee:Employee.config_alt_field.collective_bargaining_france'),
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Cadre 10', label: 'Cadre 10' },
            { value: 'Cadre 100', label: 'Cadre 100' },
            { value: 'Cadre 105', label: 'Cadre 105' },
            { value: 'Cadre 115', label: 'Cadre 115' },
            { value: 'Cadre 130', label: 'Cadre 130' },
            { value: 'Cadre 150', label: 'Cadre 150' },
            { value: 'Cadre 170', label: 'Cadre 170' },
            { value: 'Cadre 210', label: 'Cadre 210' },
            { value: 'Cadre 270', label: 'Cadre 270' },
            { value: 'Cadre 95', label: 'Cadre 95' },
            { value: 'ETAM 200', label: 'ETAM 200' },
            { value: 'ETAM 210', label: 'ETAM 210' },
            { value: 'ETAM 220', label: 'ETAM 220' },
            { value: 'ETAM 230', label: 'ETAM 230' },
            { value: 'ETAM 240', label: 'ETAM 240' },
            { value: 'ETAM 250', label: 'ETAM 250' },
            { value: 'ETAM 275', label: 'ETAM 275' },
            { value: 'ETAM 310', label: 'ETAM 310' },
            { value: 'ETAM 355', label: 'ETAM 355' },
            { value: 'ETAM 400', label: 'ETAM 400' },
            { value: 'ETAM 450', label: 'ETAM 450' },
            { value: 'ETAM 500', label: 'ETAM 500' },
            { value: 'Other', label: 'Other' },
          ],
          isCountrySpecific: true,
          countries: ['France'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'hireReason',
          name: i18n.t('Employee:Employee.config_alt_field.hire_reason'),
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'rehire',
          name: i18n.t('Employee:Employee.config_alt_field.rehire'),
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'originalHireDate',
          name: i18n.t('Employee:Employee.config_alt_field.original_hire_date'),
          component: 'date',
        },
        {
          subCategory: 'employmentDetails',
          field: 'startDate',
          name: i18n.t('Employee:Employee.config_alt_field.start_date', {
            requiredSymbol: isCreate ? requiredSymbol : '',
          }),
          component: 'date',
          validation: {
            errorFn: inlineStartDateValidation,
            compare: 'endDate',
          },
        },
        {
          subCategory: 'employmentDetails',
          field: 'endDate',
          name: i18n.t('Employee:Employee.config_alt_field.end_date'),
          validation: {
            errorFn: inlineEndDateValidation,
            compare: 'startDate',
          },
          component: 'date',
        },
        {
          subCategory: 'employmentDetails',
          field: 'terminationReason',
          name: i18n.t('Employee:Employee.config_alt_field.termination_reason'),
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'hasMoved',
          name: i18n.t('Employee:Employee.config_alt_field.has_moved'),
          isVisible: !isCreate && isCotUser,
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
        },
      ],
    },
    {
      category: 'pay-and-taxes',
      categoryName: i18n.t('Employee:Employee.config_pay_and_taxes.category_name'),
      fields: [
        {
          field: 'employmentStatus',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.employment_status'),
        },
        {
          field: 'paymentCurrency',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.payment_currency'),
        },
        {
          field: 'annualSalary',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.annual_salary'),
        },
        {
          field: 'employeePensionContributionsPercentage',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.employee_pension_contributions_percentage'),
        },
        {
          field: 'employerPensionContributionsPercentage',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.employer_pension_contributions_percentage'),
        },
        {
          field: 'payElements',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.pay_elements'),
        },
        {
          field: 'employerContributions',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.employer_contributions'),
        },
        {
          field: 'employeeDeductions',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.employee_deductions'),
        },
        {
          field: 'timeAndAttendance',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.time_and_attendance'),
        },
        {
          field: 'overtime',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.overtime'),
        },
        {
          field: 'employeeNetDeductions',
          name: i18n.t('Employee:Employee.config_pay_and_taxes.employee_net_deductions'),
        },
      ],
    },
  ]
}
