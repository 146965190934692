import React from 'react'
import moment from 'moment'
import AssignmentsDateFilters from '../components/AssignmentsDateFilters'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { fetchAssignments } from 'redux/actions/assignments'
import { buildFilters } from 'utils/filters'
import { createFilter } from 'utils/redux/filter'
import i18n from 'i18next'

class AssignmentsDateFiltersContainer extends React.Component {
  componentDidMount () {
    const { shouldFilterInitially, handleSubmit } = this.props

    if (shouldFilterInitially) handleSubmit()
  }

  render () {
    return <AssignmentsDateFilters {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    onSubmit: (data) => {
      const filters = buildFilters(data)
      let defaultFilters = {
        ...filters,
        notInactive: true,
      }
      return dispatch(
        fetchAssignments({
          filter: createFilter(defaultFilters, 'assignments'),
          disableObsoleteFlow: true,
        })
      )
    },
    changeView: (view) => dispatch(change('filterTasks', 'view', view)),
    changeDate: (date) => dispatch(change('filterTasks', 'fromDate', date)),
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const selector = formValueSelector('filterTasks')
  const values = selector(state, 'fromDate', 'view', 'actionStatus')
  if (values.actionStatus && values.actionStatus === 'overdue') {
    return {
      headerText: i18n.t('Global:Global.actions.overdue_actions'),
      shouldShowDateFilters: false,
      fromDate: moment.unix(0),
      toDate: moment().endOf('day'),
      view: 'year',
    }
  }

  if (values.fromDate) {
    if ((!values.actionStatus || values.actionStatus !== 'overdue') && moment(values.fromDate).unix() === 0) {
      delete values.fromDate
    }
  }

  return {
    shouldShowDateFilters: true,
    fromDate: moment(values.fromDate).toDate() || moment().toDate(),
    view: values.view === 'year' ? 'month' : values.view || 'month',
  }
}

AssignmentsDateFiltersContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  shouldFilterInitially: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'filterTasks',
  })(AssignmentsDateFiltersContainer)
)
