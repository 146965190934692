import { i18nNameSpaces } from 'i18n/types'
import React from 'react'
import PropTypes from 'prop-types'

import FixedPayElementsContainer from 'containers/FixedPayElementsContainer'
import VariablePayElementsContainer from 'containers/VariablePayElementsContainer'
import { useTranslation } from 'react-i18next'

const PayElements = ({ companyId, disabled, disabledReason, className = 'u-1/1 u-1/2@desktop', itemsClassName }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const props = { companyId, disabled, disabledReason, className: itemsClassName }

  return (
    <div className='o-layout'>
      <div className={`o-layout__item ${className}`}>
        <h4 className='u-text--huge u-margin-bottom-small'>{t('Business<Component.layout.fixedPayHeading')}</h4>
        {companyId && <FixedPayElementsContainer {...props} />}
      </div>
      <div className={`o-layout__item ${className}`}>
        <h4 className='u-text--huge u-margin-bottom-small'>{t('BusinessComponent.layout.variablePayHeading')}</h4>
        {companyId && <VariablePayElementsContainer {...props} />}
      </div>
    </div>
  )
}

PayElements.propTypes = {
  companyId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  className: PropTypes.string,
  itemsClassName: PropTypes.string,
}

export default PayElements
