import getAddressFields from 'redux/config/employeeAddressFieldsConfig'
import { isEmpty } from 'utils/fnkit/typeChecks'
import i18n from 'i18next'

/**
 * Fixed Subcategories hardcoded keys
 */
export const fixedSubcategoryKeys = {
  personalDetails: 'personalDetails',
  biographicalDetails: 'biographicalDetails',
  contactDetails: 'contactDetails',
  addressDetails: 'addressDetails',
  bankDetails: 'bankDetails',
  emergencyPersonDetails: 'emergencyPersonDetails',
  taxDetails: 'taxDetails',
  contractorDetails: 'contractorDetails',
  employmentDetails: 'employmentDetails',
  jobProfile: 'jobProfile',
  organizationDetails: 'organizationDetails',
  employeeSelfService: 'employeeSelfService',
  annualSalary: 'annualSalary',
  payElements: 'payElements',
  employeeDeductions: 'employeeDeductions',
  employeeNetDeductions: 'employeeNetDeductions',
  employerContributions: 'employerContributions',
  informationalElements: 'informationalElements',
}

/**
 * Generates a fixed set of subcategory labels for reporting purposes.
 *
 * @function
 * @returns {Object} An object containing key-value pairs of subcategory identifiers and their corresponding labels.
 */
export const getFixedSubCategoriesLabels = () => ({
  personalDetails: i18n.t('Report:Report.category_field.personal_details'),
  biographicalDetails: i18n.t('Report:Report.category_field.biographical_details'),
  contactDetails: i18n.t('Report:Report.category_field.contact_details'),
  addressDetails: i18n.t('Report:Report.category_field.address_details'),
  bankDetails: i18n.t('Report:Report.category_field.bank_details'),
  emergencyPersonDetails: i18n.t('Report:Report.category_field.emergency_person_details'),
  taxDetails: i18n.t('Report:Report.category_field.tax_details'),
  contractorDetails: i18n.t('Report:Report.category_field.contractor_details'),
  employmentDetails: i18n.t('Report:Report.category_field.employment_details'),
  jobProfile: i18n.t('Report:Report.category_field.job_profile'),
  organizationDetails: i18n.t('Report:Report.category_field.organization_details'),
  employeeSelfService: i18n.t('Report:Report.category_field.employee_self_service'),
  annualSalary: i18n.t('Report:Report.category_field.annual_salary'),
  payElements: i18n.t('Report:Report.category_field.pay_elements'),
  employeeDeductions: i18n.t('Report:Report.category_field.employee_deductions'),
  employeeNetDeductions: i18n.t('Report:Report.category_field.employee_net_deductions'),
  employerContributions: i18n.t('Report:Report.category_field.employer_contributions'),
  informationalElements: i18n.t('Report:Report.category_field.informational_elements'),
})
/**
 * subcategories divided by fronted section
 */
export const fixedSubcategoriesBySection = {
  personal: [
    fixedSubcategoryKeys.personalDetails,
    fixedSubcategoryKeys.biographicalDetails,
    fixedSubcategoryKeys.contactDetails,
    fixedSubcategoryKeys.bankDetails,
    fixedSubcategoryKeys.emergencyPersonDetails,
    fixedSubcategoryKeys.taxDetails,
    fixedSubcategoryKeys.contractorDetails,
  ],
  'job-and-org': [
    fixedSubcategoryKeys.employmentDetails,
    fixedSubcategoryKeys.jobProfile,
    fixedSubcategoryKeys.organizationDetails,
    fixedSubcategoryKeys.employeeSelfService,
  ],
}

export const getFixedInformationalElementsArray = () => [
  { field: 'netPay', name: i18n.t('Employee:Employee.config_alt_field.net_pay') },
  { field: 'totalEmployerCost', name: i18n.t('Employee:Employee.config_alt_field.total_employer_cost') },
  { field: 'netIncome', name: i18n.t('Employee:Employee.config_alt_field.net_income') },
  { field: 'totalTaxableIncome', name: i18n.t('Employee:Employee.config_alt_field.total_taxable_income') },
  { field: 'grossPay', name: i18n.t('Employee:Employee.config_alt_field.gross_pay') },
]

export const getFixedAnnualSalaryFields = () => [
  { field: 'value', name: i18n.t('Employee:Employee.pay_and_taxes.field.annualSalary') },
  { field: 'effectiveDate', name: i18n.t('Employee:Employee.pay_and_taxes.field.effectiveDate') },
]

export const fixedDesignerReportTermCategories = [
  {
    id: 1,
    key: fixedSubcategoryKeys.payElements,
  },
  {
    id: 2,
    key: fixedSubcategoryKeys.employerContributions,
  },
  {
    id: 3,
    key: fixedSubcategoryKeys.employeeDeductions,
  },
  {
    id: 9,
    key: fixedSubcategoryKeys.employeeNetDeductions,
  },
]

export const getFixedSubcategories = () => ({
  [fixedSubcategoryKeys.addressDetails]: getAddressFields(),
  [fixedSubcategoryKeys.annualSalary]: getFixedAnnualSalaryFields(),
  [fixedSubcategoryKeys.informationalElements]: getFixedInformationalElementsArray(),
})

export const fixedPayrollInstanceFields = ['otherEmployeeId', 'firstname', 'surname', 'employmentStatus', 'managementPandL']
export const fixedOrgUnitFields = ['paygroup', 'businessUnit', 'costCenter', 'department', 'officeLocation', 'subsidiary', 'project']

export const fixedCategoriesEntity = {
  [fixedSubcategoryKeys.addressDetails]: 'address',
  [fixedSubcategoryKeys.bankDetails]: 'bankaccount',
  [fixedSubcategoryKeys.payElements]: 'companycountryterm',
  [fixedSubcategoryKeys.employerContributions]: 'companycountryterm',
  [fixedSubcategoryKeys.employeeDeductions]: 'companycountryterm',
  [fixedSubcategoryKeys.employeeNetDeductions]: 'companycountryterm',
  [fixedSubcategoryKeys.informationalElements]: 'payrollinstanceemployee',
  [fixedSubcategoryKeys.annualSalary]: 'annualsalary',
}
export const extrafieldEntityKey = 'extrafield'
export const fixedExcludedCategories = {
  [fixedSubcategoryKeys.bankDetails]: ['payrollCountry', 'company'],
  [fixedSubcategoryKeys.organizationDetails]: ['makeKeyUser', 'countries', 'company', 'payroll', 'resetUserPassword'],
  [fixedSubcategoryKeys.jobProfile]: ['employeeId'],
  [fixedSubcategoryKeys.employmentDetails]: ['hasMoved'],
}

export const getFixedExtraPersonalFields = () => [
  {
    subCategory: 'bankDetails',
    field: 'bankCode',
    name: i18n.t('Employee:Employee.config_alt_field.bank_code'),
  },
  {
    subCategory: 'bankDetails',
    field: 'clearingNumber',
    name: i18n.t('Employee:Employee.config_alt_field.clearing_number'),
  },
]

/**
 * Filters the provided fields array based on specified subcategories.
 *
 * @param {Array} fields - The array of field objects to filter.
 * @param {Array} subcategories - The array of subcategories to be considered for filtering.
 * @returns {Array} - An array of filtered field objects whose subCategory is in the subcategories list
 *                    and that contain a valid 'name' property.
 */
const getFilteredFields = (fields, subcategories) =>
  fields.filter(({ subCategory, name, field }) => subcategories.includes(subCategory) && name && !fixedExcludedCategories[subCategory]?.includes(field))
/**
 * Transforms an array of field objects into an object where keys are sub-categories and values are arrays
 * of mapped fields.
 *
 * @param {Array} fields - An array of field objects to be mapped.
 * @param {Function} fieldsMapper - A function that receives a field object and returns a mapped value.
 * @returns {Object} An object with sub-categories as keys and arrays of mapped fields as values.
 */
const mapFields = (fields, fieldsMapper) =>
  fields.reduce((acc, { subCategory, name, field }) => {
    acc[subCategory] = [...(acc[subCategory] || []), fieldsMapper({ name, field })]
    return acc
  }, {})

/**
 * Generates an object containing fixed and filtered fields based on the provided configuration and fieldsMapper.
 *
 * @param {Array} config - The configuration array containing category and fields details.
 * @param {Function} fieldsMapper - A function to map the name and field to a specific structure.
 *
 * @returns {Object} An object where keys are subcategories and values are arrays of mapped fields.
 */

export const generateFixedFilteredFields = (config, fieldsMapper) => {
  if (isEmpty(config)) return {}
  return config.reduce((categories, { category, fields }) => {
    const subcategories = fixedSubcategoriesBySection[category] || []
    const filteredFields = getFilteredFields(fields, subcategories)
    const mappedFields = mapFields(filteredFields, fieldsMapper)
    return { ...categories, ...mappedFields }
  }, {})
}
