import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import FilterButton from 'components/buttons/FilterButton'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getColumnFilter = (t) => ({
  type: 'TextFilter',
  delay: 1000,
  placeholder: t('Global:Global.text.filter'),
})

const TermsTable = ({ isFetching, terms, onEdit }) => {
  const [showFilters, setShowFilters] = useState(false)
  const { t } = useTranslation([i18nNameSpaces.Term])

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div>
      <SectionHeading text={t('Term.text.term_list')} className='u-margin-top-large'>
        <FilterButton
          className='c-btn c-btn--small float-right c-btn--curious u-padding-left u-padding-right u-margin-bottom-small'
          onClick={() => setShowFilters(!showFilters)}
        >
          {t('Global:Global.text.filter')}
        </FilterButton>
      </SectionHeading>
      <PayslipTable
        data={terms.map((term) => ({
          ...term,
          category: term.categoryRef.name,
          subcategory: term.termSubcategoryRef.name,
          action: (
            <button onClick={() => onEdit(term.id)} className='c-btn c-btn--tiny c-btn--curious'>
              <span className='icon icon--edit' />
            </button>
          ),
        }))}
        wrapperClassName='table-text-full-length'
        showFilters={showFilters}
        pagination
        options={{
          noDataMessage: t('Term.text.no_terms'),
          pageIndex: 0,
          pageSize: 25,
          showPageSizeSelector: terms.length > 25,
        }}
        headings={[
          {
            accessor: 'id',
            Header: t('Global:Global.text.id'),
            isKey: true,
            filterOptions: getColumnFilter(t),
          },
          {
            accessor: 'name',
            Header: t('Global:Global.text.name'),
            filterOptions: getColumnFilter(t),
          },
          {
            accessor: 'definition',
            Header: t('Term.text.definition'),
            filterOptions: getColumnFilter(t),
          },
          {
            accessor: 'category',
            Header: t('Global:Global.text.category'),
            filterOptions: getColumnFilter(t),
          },
          {
            accessor: 'subcategory',
            Header: t('Global:Global.text.subcategory'),
            filterOptions: getColumnFilter(t),
          },
          {
            accessor: 'action',
            Header: t('Global:Global.text.edit'),
            actionCell: true,
            disableFilters: true,
            disableSortBy: true,
            setWidth: '100px',
          },
        ]}
      />
    </div>
  )
}

TermsTable.propTypes = {
  isFetching: PropTypes.bool,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      definition: PropTypes.string,
      categoryRef: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      termSubcategoryRef: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    })
  ),
  onEdit: PropTypes.func.isRequired,
}

export default TermsTable
