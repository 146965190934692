import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { DropdownIconButton } from 'components/buttons/DropdownIconButton'
import { ArrowTooltip } from 'components/popover/Floater'
import { getBaseFloaterMiddleWare } from 'components/popover/floaterMiddlewares'
import { downloadReport } from 'redux/actions/reports'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const FIXED_EXCEL_FORMAT = 'excel'

/**
 * Component representing an item related to Excel download functionality.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isTooLarge - Indicates if the Excel file is too large to be downloaded.
 * @param {Function} props.handleClick - Function to handle click events for downloading the Excel file.
 */
const ExcelItem = ({ isTooLarge, handleClick, t }) =>
  isTooLarge ? (
    <ArrowTooltip
      withArrow={false}
      middleware={getBaseFloaterMiddleWare({ offsetValue: 6 })}
      hoverTriggerElement={
        <div>
          <span className='disabled-greyed'>{t('Global:Global.text.excel')}</span>
        </div>
      }
    >
      <div className='generic-floater-box floater-size--fit-height'>{t('Report.text.too_large_xl')}</div>
    </ArrowTooltip>
  ) : (
    <span onClick={() => handleClick(FIXED_EXCEL_FORMAT)}>{t('Global:Global.text.excel')}</span>
  )
ExcelItem.propTypes = {
  isTooLarge: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}

/**
 * Button component for downloading reports in various formats.
 *
 * This component provides a dropdown button that allows the user to download
 * a report in multiple available formats. It ensures that the download for
 * a specific fixed Excel format (denoted by `FIXED_EXCEL_FORMAT`) is handled
 * separately to indicate if the format is disabled or enabled for selection.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.report - The report object containing information about the available formats and report details.
 *
 * @returns {JSX.Element} The rendered button component which includes a dropdown menu for selecting the desired report format.
 */
export const DownloadReportButton = ({ report, dropdownButtonClass, buttonClass }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation([i18nNameSpaces.Report])
  const availableFormats = report?.availableFormats || []
  const hasExcel = availableFormats.includes(FIXED_EXCEL_FORMAT)
  const extendedFormats = availableFormats.filter((format) => format !== FIXED_EXCEL_FORMAT)

  const handleClick = (format) => {
    dispatch(downloadReport(report.id, `export?format=${format}`))
  }

  return (
    <DropdownIconButton iconClass='icon--download' buttonClass={buttonClass} dropdownButtonClass={dropdownButtonClass}>
      <ExcelItem isTooLarge={!hasExcel} handleClick={handleClick} t={t} />
      {extendedFormats.map((format) => (
        <span key={`${format}`} onClick={() => handleClick(format)}>
          {t(`Global:Global.text.${format}`)}
        </span>
      ))}
    </DropdownIconButton>
  )
}

DownloadReportButton.propTypes = {
  report: PropTypes.object.isRequired,
  dropdownButtonClass: PropTypes.string,
  buttonClass: PropTypes.string,
}
