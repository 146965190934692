import DatesProcessesView from './components/DatesProcessesView'
import ProcessRoute from './routes/Process'
import PayrollRoute from './routes/Payroll'

export default (store) => ({
  path: 'dates-processes',
  tabbedRoute: true,
  indexRoute: { component: DatesProcessesView },
  childRoutes: [PayrollRoute(store), ProcessRoute(store)],
})
