import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ServicesTreeDecorator from 'components/ServicesTreeDecorator'
import ProcessTree from 'components/ProcessTree'
import Modal from 'components/Modal'
import { i18nNameSpaces } from 'i18n/types'

const PayrollProcessTree = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const createModalRef = useRef(null)

  const showCreateModal = () => {
    createModalRef.current.showModal()
  }

  const onStepCreate = () => {
    createModalRef.current.hideModal()
    props.onStepCreate(props.payrollProcessId)
  }

  const onTaskCreate = () => {
    createModalRef.current.hideModal()
    props.onTaskCreate(props.payrollProcessId)
  }

  const {
    tree,
    payrollProcessId,
    isFetching,
    onProcessEdit,
    onTaskEdit,
    onStepEdit,
    onMultiView,
    isCot,
    isShare,
    addBtnTitle,
    isEditDisabled,
    initialValues,
    isOwner,
  } = props

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div>
      <Modal ref={createModalRef} className='c-modal c-modal--small c-modal--overflow-y' modalHeading={t('BusinessComponent.modal.heading')}>
        <div className='u-text--center u-1/1'>
          <button onClick={onStepCreate} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-right-small'>
            {t('Global:Global.button.step')}
          </button>
          <button onClick={onTaskCreate} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'>
            {t('Global:Global.button.action')}
          </button>
        </div>
      </Modal>
      <ServicesTreeDecorator steps={tree.steps}>
        <ProcessTree
          processId={payrollProcessId}
          isPayrollProcess={tree.isPayrollProcess}
          name={tree.name}
          owner={tree.owner}
          onProcessEdit={onProcessEdit}
          onTaskEdit={onTaskEdit}
          onStepEdit={onStepEdit}
          onCreate={showCreateModal}
          payDate={tree.payDate}
          keyEvent={tree.keyEvent}
          isCot={isCot}
          isShare={isShare}
          onMultiView={onMultiView}
          addBtnTitle={addBtnTitle}
          isEditDisabled={isEditDisabled}
          initialValues={initialValues}
          isOwner={isOwner}
        />
      </ServicesTreeDecorator>
    </div>
  )
}

PayrollProcessTree.propTypes = {
  tree: PropTypes.object,
  payrollProcessId: PropTypes.number,
  isFetching: PropTypes.bool,
  onProcessEdit: PropTypes.func,
  onTaskEdit: PropTypes.func,
  onMultiView: PropTypes.func,
  isCot: PropTypes.bool,
  isShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  onStepCreate: PropTypes.func,
  onTaskCreate: PropTypes.func,
  onStepEdit: PropTypes.func,
  addBtnTitle: PropTypes.string,
  isEditDisabled: PropTypes.bool,
  initialValues: PropTypes.object,
}

export default PayrollProcessTree
