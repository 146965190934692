import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { makeScaleValidation } from 'utils/validations'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FlagOption from 'components/form/select/FlagOption'
import FlagValue from 'components/form/select/FlagValue'
import { sortByName } from 'utils/strings'

import { i18nNameSpaces } from 'i18n/types'

const scaleError = (scale) => i18n.t('Global:Global.error.scale_reporting_rate', { scale })
const scaleValidations = makeScaleValidation(6, scaleError)
const requiredSymbol = '*'

const CompanyFormFields = ({ isCot, isEdit, currencies, countries, types, HQCompanies, isHQRelationToHQ, onRelationToHQChange, timezones }) => {
  const { t } = useTranslation([i18nNameSpaces.Company])
  const currenciesOptions = currencies.map((c) => ({
    value: c.id,
    label: c.abbreviature,
  }))

  return (
    <>
      <div className='o-layout o-grid--strech'>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='name'
              label={t('Company.field.company_name', {
                requiredSymbol,
              })}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              data-testid='name'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='address'
              label={t('Company.field.address')}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              data-testid='address'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='registrationNumber'
              label={t('Company.field.registration_number', {
                requiredSymbol,
              })}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='country'
              label={t('Company.field.entity_country', {
                requiredSymbol,
              })}
              component={CustomSelectField}
              optionComponent={FlagOption}
              valueComponent={FlagValue}
              options={countries.sort(sortByName).map((c) => ({
                value: c.id,
                label: c.name,
                abbreviature: c.abbreviature,
              }))}
              placeholder={t('Global:Global.select.placeholder')}
              className='c-custom-select--transparent'
              disabled={isEdit}
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='registrationAddress'
              label={t('Company.field.registered_address')}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='website'
              label={t('Company.field.website', { requiredSymbol })}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='hq'
              label={t('Company.field.hq_company')}
              component={CustomSelectField}
              optionComponent={FlagOption}
              valueComponent={FlagValue}
              options={HQCompanies.map((c) => ({
                value: c.id,
                label: c.name,
                abbreviature: c.country.abbreviature,
              }))}
              disabled={isHQRelationToHQ}
              disabledReason={t('Company.disabled_reason.hq_relation')}
              placeholder={t('Global:Global.select.placeholder')}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='relationToHQ'
              label={t('Company.field.connection_to_hq', { requiredSymbol })}
              component={CustomSelectField}
              options={types}
              placeholder={t('Global:Global.select.placeholder')}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              onChanged={onRelationToHQChange}
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='currency'
              label={t('Company.field.company_currency', { requiredSymbol })}
              component={CustomSelectField}
              options={currenciesOptions}
              placeholder={t('Global:Global.select.placeholder')}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              data-testid='currency'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='reportingCurrency'
              label={t('Company.field.reporting_currency')}
              component={CustomSelectField}
              options={currenciesOptions}
              placeholder={t('Global:Global.select.placeholder')}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='reportingRate'
              label={t('Company.field.reporting_rate')}
              component={InputField}
              type='number'
              step={0.000001}
              validate={scaleValidations}
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='timezone'
              label={t('Company.field.timezone', { requiredSymbol })}
              component={CustomSelectField}
              options={timezones.map((timezone) => ({
                value: timezone.id,
                label: timezone.zone,
              }))}
              placeholder={t('Global:Global.select.placeholder')}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='directors'
              label={t('Company.field.directors')}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='code'
              label={t('Company.field.code')}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='vatRegistrationNumber'
              label={t('Company.field.vat_reg_number')}
              component={InputField}
              type='text'
              className='c-input c-input--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
      </div>
      <div className='o-layout u-padding-top-small u-padding-bottom-small'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </>
  )
}

CompanyFormFields.propTypes = {
  isEdit: PropTypes.bool,
  isCot: PropTypes.bool,
  isHQRelationToHQ: PropTypes.bool,
  onRelationToHQChange: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      abbreviature: PropTypes.string.isRequired,
    })
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  HQCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      zone: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default CompanyFormFields
