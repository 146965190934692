import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

export const Back = ({ onBackNavigate }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const backText = t('Global:Global.button.back')

  return (
    <div
      onClick={onBackNavigate || history.goBack}
      data-testid='back-button'
      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom'
      title={backText}
    >
      <i className='icon--back' /> {backText}
    </div>
  )
}

Back.propTypes = {
  onBackNavigate: PropTypes.func,
}
export default Back
