import createSelector from 'utils/createSelector'
import { tenantTypes } from 'utils/enums/tenantTypes'

const getUserId = (state, props) => props.userId
const getTenantType = (state, props) => props.tenantType
const DEFAULT_LOCALE = 'en'

export const getUserProfileByUserIdAndTenantType = createSelector(
  getUserId,
  getTenantType,
  ({ EmployeeSystemUser, VendorUser, CotUser }, userId, tenantType) => {
    let user = null

    if (tenantType === tenantTypes.TENANT) {
      user = EmployeeSystemUser.withId(userId)
    }
    if (tenantType === tenantTypes.VENDOR) {
      user = VendorUser.withId(userId)
    }
    if (tenantType === tenantTypes.COS) {
      user = CotUser.withId(userId)
    }

    return user?.ref
  }
)

export const getUserLocale = (state) => {
  const userId = state.auth.userId
  const tenantType = state.config.tenantType
  const user = getUserProfileByUserIdAndTenantType(state, { userId, tenantType })
  return user?.locale || DEFAULT_LOCALE
}
