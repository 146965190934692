import React from 'react'

import CheckboxWrapper from 'components/form/CheckboxWrapper'
import { CheckIconAlt } from 'components/icons/CheckIcons'
import { platformColors } from 'utils/colors'
import { sortStrings } from 'utils/fnkit/sort'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const DualSelectorItem = ({ selected, onSelectItem, showCheckIcon, ...item }) => (
  <div
    className={`d-flex ai-center jc--space-between dual-selector-widget--item ${selected ? 'dual-selector-widget--item--selected' : ''}`}
    onClick={() => onSelectItem(item.value)}
  >
    {item.label}
    {showCheckIcon && <CheckIconAlt color={platformColors.midGreen} width='19' height='19' />}
  </div>
)

DualSelectorItem.propTypes = {
  selected: PropTypes.bool,
  onSelectItem: PropTypes.func,
  showCheckIcon: PropTypes.bool,
}

export const DualSelectorOption = ({ orderType, options = [], onChangeOption }) => {
  const { t } = useTranslation()
  const sortedOptions = sortStrings(options, orderType)

  return (
    <>
      {isEmpty(options) && <div className='d-flex ai-center jc--center dual-selector-widget--full'>{t('Global.text.no_fields_available')}</div>}
      {sortedOptions.map((opt, index) => (
        <div key={`dual-right-box_${opt.value}-${index}`} className='schema-form--item'>
          <CheckboxWrapper label={opt.label}>
            <input name={opt.value} type='checkbox' value={opt.value} className='control__indicator__input' checked={opt?.selected} onChange={onChangeOption} />
          </CheckboxWrapper>
        </div>
      ))}
    </>
  )
}

DualSelectorOption.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
  onChangeOption: PropTypes.func,
  orderType: PropTypes.string,
}
