import React from 'react'
import SectionHeading from 'components/SectionHeading'
import FormFields from './FormFields'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const SubCategoryCreate = ({ ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.Term])
  return (
    <div>
      <SectionHeading text={t('Term.text.create_subcategory')} />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <FormFields {...props} />
        </div>
      </div>
    </div>
  )
}

export default SubCategoryCreate
