import { i18nNameSpaces } from 'i18n/types'
import React from 'react'
import PropTypes from 'prop-types'
import StepperConfirmationModal from './StepperConfirmationModal'
import Loader from './Loader'
import { useTranslation } from 'react-i18next'

const ProcessingSSOModal = React.forwardRef((props, ref) => {
  const { isSSOSubmitting, isUserSSOEnabled, onModalStateChange, isEmployeeUser = false, errors = [] } = props

  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const hasErrors = errors?.length > 0

  return (
    <StepperConfirmationModal
      ref={ref}
      className={`c-modal ${isSSOSubmitting && !hasErrors ? 'hide-modal-close' : ''}`}
      disableBackdropClose={isSSOSubmitting}
      onHide={() => isEmployeeUser && onModalStateChange({ showSubmitSSOConfirmModal: false })}
      modalHeading={hasErrors ? t('BusinessComponent.modal.error_heading') : ''}
    >
      {hasErrors && (
        <div className='u-text--center u-text--normal u-weight--regular'>
          {errors.map((e, i) => (
            <p key={i} className='u-text--large u-margin-bottom-small'>
              {e}
            </p>
          ))}
        </div>
      )}
      {!hasErrors && (
        <div className='u-text--center u-text--normal u-weight--regular'>
          {isSSOSubmitting && (
            <>
              <Loader />
              <div>{isUserSSOEnabled ? t('BusinessComponent.modal.disabling_sso') : t('BusinessComponent.modal.enabling_sso')}</div>
            </>
          )}
          {!isSSOSubmitting && (
            <h6 className='u-margin-top-tiny'>
              {isUserSSOEnabled ? t('BusinessComponent.modal.success_message_enabled') : t('BusinessComponent.modal.success_message_disabled')}
            </h6>
          )}
        </div>
      )}
    </StepperConfirmationModal>
  )
})

ProcessingSSOModal.propTypes = {
  isSSOSubmitting: PropTypes.bool,
  isUserSSOEnabled: PropTypes.bool,
  onModalStateChange: PropTypes.func,
  isEmployeeUser: PropTypes.bool,
  errors: PropTypes.array,
}

export default ProcessingSSOModal
