import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { isNumber } from 'lodash'
import Modal from 'components/Modal'
import Fetcher from 'containers/Fetcher'
import DocumentsViewTable from 'components/documents/DocumentsViewTable'
import PayrollDocumentsTable from 'components/documents/PayrollDocumentsTable'
import { createFilter } from 'utils/redux/filter'
import DocumentUploadForm from 'components/documents/DocumentUploadForm'
import { hasAccess, isCot, isCotBot, isCotOrEngineerOrAdmin, isKeyUser, isVendorInvolved } from 'redux/selectors/auth'
import { createDocsPaginationFilter, createDynamicDocumentFilterName } from 'redux/filters/createDocumentFilter'
import { getPayrollById, getPayrollsByCompany } from 'redux/selectors/payroll'
import { deleteDocument, downloadDocument, fetchDocuments, invalidateDocuments, updateDocument, uploadDocument } from 'redux/actions/document'
import { clearMessage, showMessage } from 'redux/actions/modal'
import DocumentClassificationUploadForm from 'components/documents/DocumentClassificationUploadForm'
import { change, formValueSelector, reduxForm, reset, stopAsyncValidation } from 'redux-form'
import { getTabsByUploadedDocumentTypes, getUploadedDocumentTypesObjects, setDocumentTypes } from 'utils/documentTypes'
import { getModalHeading } from 'utils/modal'
import PropTypes from 'prop-types'
import { excludeForFuturePayruns, isShareAllowed } from 'containers/documents/utils/documents'
import { getDocumentTypes } from 'redux/selectors/documentTypes'
import { getCotUser } from 'redux/selectors/cotUsers'
import { docTypesFilesToExcludeWhenReadOnly } from 'utils/enums/documentTypes'
import { fetchDocumentTypes } from 'redux/actions/documentTypes'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import i18n from 'i18next'

const PAYROLL_DOC_FILTER_NAMES = ['typeClass', 'typeId', 'typeTenant', 'documentTypes']

const DOCUMENTS_FILTER_NAMES = ['typeClass', 'typeId', 'typeTenant']

const DocumentListContainer = ({ hasDocumentClassification, dispatch, modalHeading, isPayrollInstance, isVendorRestricted, ...props }) => {
  const uploadModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const handleModalOpen = () => {
    if (uploadModalRef.current) {
      uploadModalRef.current.showModal()
    }
  }

  return (
    <>
      <Modal
        ref={uploadModalRef}
        className='c-modal'
        modalHeading={modalHeading || ''}
        modalSubHeading={t('BusinessComponent.text.choose_doc_type')}
        onHide={() => dispatch(reset('documentUpload'))}
      >
        {hasDocumentClassification ? <DocumentClassificationUploadForm {...props} /> : <DocumentUploadForm {...props} disabled={isVendorRestricted} />}
      </Modal>
      {isPayrollInstance ? (
        <PayrollDocumentsTable filterNames={PAYROLL_DOC_FILTER_NAMES} onUpload={handleModalOpen} {...props} />
      ) : (
        <DocumentsViewTable filterNames={DOCUMENTS_FILTER_NAMES} title={t('Global:Global.aside.documents')} isVendor onUpload={handleModalOpen} {...props} />
      )}
    </>
  )
}

const mapStateToProps = (state, props) => {
  const {
    match: { params },
    payrollInstanceId,
    typeClass,
    isVendorRestricted,
    tab,
  } = props
  const isCotUser = isCot(state)
  const isCotBotUser = isCotBot(state)
  const isKU = isKeyUser(state)
  const isVendorUser = isVendorInvolved(state)

  const cotUserSpecialRights = isCotUser && getCotUser(state, { userId: state.auth.userId }).cotSpecialRight

  const selector = formValueSelector('documentUpload')
  const values = selector(state, 'documentType', 'payrollId', 'documentTypes', 'version')
  const isPayrollInstance = isNumber(payrollInstanceId)
  const regex = new RegExp(`^[1-9]{1}[0-9]*$`)
  const isGtnTab = tab?.name === 'payrollGtnDocTypes' || null

  let shouldRunIsCotUserExcludes = isCotUser
  // Remove option/s from document types dropdown, when uploading file on payrun level in Vendor mode
  let documentTypesToExclude = [
    'inputs',
    'gtnMappingReport',
    'payslipUploadReport',
    'taxDocumentUploadReport',
    'glMapping',
    'inputsValidationReport',
    'mipiInputs',
    'dataMigrationValidationReport',
    'payslipsValidationReport',
    'payslipsHcmReport',
    'taxDocsValidationReport',
    'taxDocsHcmReport',
  ]
  const extraFilesToExclude = !isCotUser ? docTypesFilesToExcludeWhenReadOnly.upload : []

  // Only CotBot user should be able to upload Additional Inputs and Beneficiary file document type
  if (!isCotBotUser) documentTypesToExclude.push('additionalInputs', 'beneficiaryFile', 'dataMigration')

  const documentTypesToExcludeForFuturePayruns =
    isPayrollInstance &&
    excludeForFuturePayruns({
      docTypes: ['inputsAutoSaved', 'mipiInputs', 'gtn', 'gtnAutoSaved', 'payslips', 'tax', 'bankFile', 'glMapping', 'glReports'],
      payrunState: props.instance.status,
      allowInputs: isCotUser,
    })

  if (documentTypesToExcludeForFuturePayruns) {
    shouldRunIsCotUserExcludes = false
    documentTypesToExclude = documentTypesToExclude.concat(documentTypesToExcludeForFuturePayruns)
  }

  const documentTypes = setDocumentTypes(
    getDocumentTypes(state),
    [...documentTypesToExclude, ...extraFilesToExclude],
    shouldRunIsCotUserExcludes,
    isCotBotUser,
    isCotOrEngineerOrAdmin,
    cotUserSpecialRights
  )

  return {
    extraFilters: {
      documentTypes: props?.documentTypes,
      allDocTypes: props?.allDocTypes,
    },
    initialValues: isVendorRestricted ? { documentType: 'Company' } : {},
    documentTypes: documentTypes,
    isCot: isCotUser,
    isCotBot: isCotBotUser,
    hasAccess: hasAccess(state),
    canCreate: hasAccess(state)(['DOCUMENT_CREATE']),
    isPayrollInstance,
    showCategoryPayrollName: !isPayrollInstance,
    hasDocumentClassification: isPayrollInstance,
    isPayroll: values.documentType === 'Payroll',
    showSubmitBtn:
      values.documentType === 'Company' ||
      (values.documentType === 'Payroll' && values.payrollId) ||
      (isNumber(payrollInstanceId) && values.documentTypes && values.documentTypes.length && values.version > 0 && regex.test(values.version)),
    payrolls: getPayrollsByCompany(state, { companyId: params.companyId }),
    modalHeading: getModalHeading(state, payrollInstanceId, typeClass, params.companyId),
    isGtnTab,
    tabName: tab?.name,
    isKU,
    isVendorUser,
  }
}

const dispatchDocFetch = ({ filterName, isVendorDocuments, docFilterNames, dispatch, filters }) => {
  dispatch(
    fetchDocuments({
      filter: createDocsPaginationFilter(filterName, { filters, docFilterNames, isVendorDocuments }),
    })
  )
}

const mapDispatchToProps = (dispatch, props) => {
  const {
    match: { params },
    payrollInstanceId,
    typeTenant,
    typeClass,
    tabIndex: currentTab,
    onFileUploaded,
  } = props

  return {
    fetchInitialDocs: () => {
      const filterName = createDynamicDocumentFilterName([props?.typeId, props?.typeClass])
      dispatchDocFetch({
        dispatch,
        filterName,
        docFilterNames: DOCUMENTS_FILTER_NAMES,
        isVendorDocuments: props?.isVendorDocuments,
        filters: props,
      })
    },
    fetchInitialPayrollDocuments: () => {
      const filterName = createDynamicDocumentFilterName([props?.typeId, props?.tab?.name])
      dispatchDocFetch({ dispatch, filterName, docFilterNames: PAYROLL_DOC_FILTER_NAMES, filters: props })
    },
    uploadFile: (file) => {
      dispatch((dispatch, getState) => {
        const state = getState()
        const selector = formValueSelector('documentUpload')
        const values = selector(state, 'documentType', 'payrollId', 'documentTypes', 'version')
        const typeId = values.documentType === 'Company' ? params.companyId : values.payrollId
        const isCotUser = isCot(state)
        const data = {
          typeClass: payrollInstanceId ? 'PayrollInstance' : values.documentType,
          typeId: payrollInstanceId || typeId,
          typeTenant,
          ...(payrollInstanceId
            ? {
              version: values.version,
              ...Object.keys(values.documentTypes).reduce((acc, key) => {
                acc[`documentTypes[${key}]`] = values.documentTypes[key]
                return acc
              }, {}),
            }
            : {}),
          uploadingMessage: getModalHeading(state, payrollInstanceId, typeClass, params.companyId),
        }
        dispatch(uploadDocument(file, data))
          .then(({ data }) => {
            const payroll = values?.payrollId ? getPayrollById(state, { payrollId: values.payrollId }) : null
            const payrollName = payroll?.name
            if (isShareAllowed(data, isCotUser)) {
              props.openConfirmShareModal(
                data.id,
                data.documentId,
                data.documentTenant,
                data.name,
                data.typeClass,
                data.typeId,
                payrollName,
                data.documentTypes
              )
            }
            dispatch(invalidateDocuments())
            dispatch(fetchDocumentTypes())
            dispatch(reset('documentUpload'))
            dispatch(clearMessage())
            if (Array.isArray(data?.documentTypes)) {
              const allDocumentsTypes = getDocumentTypes(state)
              const documentTypesIndexes = [...data.documentTypes]
              const uploadedDocumentTypesObjects = getUploadedDocumentTypesObjects(documentTypesIndexes, allDocumentsTypes)
              const tabsByUploadedDocumentTypes = getTabsByUploadedDocumentTypes(uploadedDocumentTypesObjects, props.instance?.status || null)
              const isUploadedFileTypeIncludedInCurrentTab = tabsByUploadedDocumentTypes.includes(currentTab)
              // If we are on tab `GTN` and upload a file with a several types: `GTN`,
              // `Payslips`, and `Inputs` for example, we should keep the user on the same tab,
              // since one of the types of the uploaded file is same as the current tab.
              // Another case would be, if we are on tab `Inputs` and the user upload a file with
              // types `Payslips` and `GTN`. In this case we should redirect the user to
              // the `Payslips and Tax Documents` tab, because this is the first selected type
              // of the uploaded document and none of the types of the documnets matches
              // the current tab type.
              onFileUploaded(isUploadedFileTypeIncludedInCurrentTab ? currentTab : tabsByUploadedDocumentTypes.shift())
            }
          })
          .catch((error) => {
            if (error.errors?.hasOwnProperty('version') || error.errors?.hasOwnProperty('documentTypes')) {
              dispatch(clearMessage())
              dispatch(stopAsyncValidation('documentUpload', error.errors))
              return
            }

            dispatch(showMessage({ body: i18n.t('Global.message.something_wen_wrong_body') }))
          })
      })
    },
    onDelete: (id) => {
      dispatch(deleteDocument(id, false, true))
      dispatch(fetchDocumentTypes())
    },
    onEdit: (data) => dispatch(updateDocument({ description: data.value }, data.id, false, true)),
    resetForm: () => dispatch(change('documentUpload', 'payrollId', null)),
    onDownload: (id) => dispatch(downloadDocument(id)),
    dispatch,
  }
}

DocumentListContainer.propTypes = {
  hasDocumentClassification: PropTypes.bool,
  dispatch: PropTypes.func,
  modalHeading: PropTypes.string,
  isPayrollInstance: PropTypes.bool,
  isVendorRestricted: PropTypes.bool,
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'documentUpload',
    enableReinitialize: true,
  })(DocumentListContainer)
)

const PayrollDocumentsFetcher = Fetcher(Container, [
  {
    name: 'payrolls',
    params: [
      {
        forceVendorTenantAPI: true,
        _computed: { filter: (state, props) => createFilter({ company: props.match.params.companyId }) },
      },
    ],
  },
  { name: 'companies', params: [{ forceVendorTenantAPI: true }] },
  'parentCompanies',
  'documentTypes',
])

const DocumentsFetcher = Fetcher(Container, [
  {
    name: 'payrolls',
    params: [
      {
        forceVendorTenantAPI: true,
        _computed: { filter: (state, props) => createFilter({ company: props.match.params.companyId }) },
      },
    ],
  },
  { name: 'companies', params: [{ forceVendorTenantAPI: true }] },
  'parentCompanies',
  'documentTypes',
])

export default (props) => {
  // eslint-disable-next-line react/prop-types
  if (props.typeClass === 'payrollInstance') {
    return <PayrollDocumentsFetcher {...props} />
  }

  return <DocumentsFetcher {...props} />
}
