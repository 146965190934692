import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'

import { useDispatch } from 'react-redux'

import Loader from 'components/Loader'
import { fetchPayrollInstanceTotals } from 'redux/actions/payrollInstances'
import SwitchToggle from 'components/form/SwitchToggle'
import { PayrollTotalsContent } from '../components/PayrollTotalsContent'
import { generateTableHeadings, getViewByOpts } from '../totalsTableConfig'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { SummaryNotes } from '../components/SummaryNotes'
import { fetchSummaryNotes } from 'redux/actions/summarynotes'
import { getTotalsTableInitialState, totalsTableStateReducer, totalsTableStateTypes } from '../utils/totalsTableStateReducer'
import { PayrollSummaryContext, summaryViewToggleIds } from '../PayrollSummaryContext'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const SummaryTotalsTable = (props) => {
  const { payrollInstanceId } = props
  const viewByOpts = getViewByOpts()
  const { t } = useTranslation([i18nNameSpaces.Payrun])

  const dispatch = useDispatch()
  const [state, dispatchStateChange] = useReducer(totalsTableStateReducer, getTotalsTableInitialState({ viewByOpts }))

  const {
    viewBy,
    isAllRowsExpanded,
    disbursableOnly,
    isLoading,
    summaryNotes,
    data,
    dataWithoutSubcategories,
    isEdit,
    editableTableState,
    needsRefetch,
    tableChangedData,
    isDirty,
  } = state
  const { setGlobalUpdateMode } = useContext(PayrollSummaryContext)

  useEffect(() => {
    setGlobalUpdateMode(summaryViewToggleIds.totalsTable, isEdit)
  }, [isEdit])

  const onToggleDisbursable = () =>
    dispatchStateChange({
      type: totalsTableStateTypes.onToggleDisbursable,
    })

  const fetchTotalsData = async (disbursable) => {
    const res = await dispatch(fetchPayrollInstanceTotals(payrollInstanceId, { disbursable }))
    const summaryNotes = await dispatch(fetchSummaryNotes(payrollInstanceId))
    const payload = {
      data: res,
      summaryNotes: summaryNotes?.data,
    }

    dispatchStateChange({
      type: totalsTableStateTypes.onFetchData,
      payload,
    })
  }

  useEffect(() => {
    if (needsRefetch) {
      fetchTotalsData(disbursableOnly)
    }
  }, [needsRefetch])

  useEffect(() => {
    fetchTotalsData(disbursableOnly)
  }, [payrollInstanceId, disbursableOnly])

  if (isLoading) {
    return <Loader />
  }

  const handleViewBy = (value) =>
    dispatchStateChange({
      type: totalsTableStateTypes.onViewBy,
      payload: value,
    })
  const toggleShowComments = () =>
    dispatchStateChange({
      type: totalsTableStateTypes.onToggleShowComments,
    })
  const toggleEditMode = () =>
    dispatchStateChange({
      type: totalsTableStateTypes.onToggleEdit,
    })
  const onUpdateGlobalData = (rowIndex, columnId, value) => {
    dispatchStateChange({
      type: totalsTableStateTypes.onEditData,
      payload: { rowIndex, columnId, value },
    })
  }
  const refreshState = () =>
    dispatchStateChange({
      type: totalsTableStateTypes.onResetState,
    })

  return (
    <div>
      <div className='d-flex ai-center jc--space-between u-margin-top'>
        <div className='d-flex jc--flex-start'>
          <h2 className='u-margin-none u-text--huge u-weight--bold'>{t('Payrun.text.view_by')}</h2>
          <Select
            name='viewBy'
            value={viewBy}
            clearable={false}
            searchable={false}
            disabled={isEdit}
            component={CustomSelectField}
            type='text'
            className={`margin-left--8px c-custom-select--small c-custom-select--min-width--large ${isEdit ? 'disabled-greyed' : ''}`}
            formGroupClassName='u-margin-none'
            labelClassName='c-label'
            placeholder={t('Payrun.text.view_by')}
            options={viewByOpts}
            onChange={handleViewBy}
          />
        </div>
        <div className='d-flex ai-center'>
          <SwitchToggle
            disabled={isEdit}
            input={{
              value: disbursableOnly,
              onChange: onToggleDisbursable,
            }}
          />
          <span className='u-margin-left-small u-margin-right-large'>{t('Payrun.text.disbursable_only')}</span>
          <SummaryNotes
            isDirty={isDirty}
            payrollInstanceId={payrollInstanceId}
            tableChangedData={tableChangedData}
            isEdit={isEdit}
            isAllRowsExpanded={isAllRowsExpanded}
            toggleShowComments={toggleShowComments}
            toggleEditMode={toggleEditMode}
            refreshState={refreshState}
          />
        </div>
      </div>

      <PayrollTotalsContent
        isEdit={isEdit}
        editableTableState={editableTableState}
        dataWithoutSubcategories={dataWithoutSubcategories}
        summaryNotes={summaryNotes}
        className='u-margin-top'
        isAllRowsExpanded={isAllRowsExpanded}
        data={data}
        tableHeadings={generateTableHeadings({ viewBy, isEdit })}
        viewBy={viewBy}
        onUpdateGlobalData={onUpdateGlobalData}
      />
    </div>
  )
}

SummaryTotalsTable.propTypes = {
  payrollInstanceId: PropTypes.string.isRequired,
}
