// ------------------------------------
// Constants
// ------------------------------------
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS'
export const RECEIVE_BRANDS_CONFIG_SETTINGS = 'RECEIVE_BRANDS_CONFIG_SETTINGS'
export const RECEIVE_SYSTEM_LOCALES = 'RECEIVE_SYSTEM_LOCALES'

// ------------------------------------
// Actions
// ------------------------------------

export const receiveSettingsAction = (config) => ({
  type: RECEIVE_SETTINGS,
  payload: config,
})

export const receiveBrandsConfigSettings = (brandsConfig) => ({
  type: RECEIVE_BRANDS_CONFIG_SETTINGS,
  payload: brandsConfig,
})

export const receiveSystemLocales = (payload) => ({
  type: RECEIVE_SYSTEM_LOCALES,
  payload,
})

// ------------------------------------
// Thunk
// ------------------------------------
export const getApiUrl = (subdomain) => {
  return (dispatch, getState, { api }) => {
    const isFetched = getState().config.apiUrl

    // If we already fetched the API URL, don't invoke the API call again.
    if (isFetched) return

    // IF it's COT, get the COT API URL from the config, because in the beginning we will have exactly 1 cot access.
    // By contrast, we will have many Clients and Vendors, because of this we fetch their API URL address dynamically.

    // TODO - error handling
    return api
      .post('settings/tenant', {
        payload: { tenant: subdomain },
        forceDefaultAPI: true,
      })
      .then(({ url, ...rest }) => {
        // Here we need to convert `url` to `apiUrl`, because some time ago we have different reducer handling
        // `apiUrl` receiving action and from this previous point of time our app have worked with `apiUrl`.
        // In order to not break something, here we make sure to continue working with `apiUrl`, instead of `url`.
        dispatch(receiveSettingsAction({ apiUrl: url, ...rest }))
      })
  }
}

export const getSettings = () => {
  return (dispatch, getState, { api }) => {
    return api.fetch('settings/config').then((response) => {
      dispatch(receiveSettingsAction(response))
    })
  }
}

export const getBrandsConfigSettings = (params) => {
  return (dispatch, _, { api }) => {
    return api.fetch('settings/whitelabel', params).then((response) => {
      dispatch(receiveBrandsConfigSettings(response?.data))
    })
  }
}

export const getSystemAvailableLocales = (params) => {
  return (dispatch, _, { api }) => {
    return api.fetch('locales', params).then((response) => {
      dispatch(receiveSystemLocales(response))
    })
  }
}
