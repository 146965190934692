export const i18nNameSpaces = {
  Global: 'Global',
  BusinessComponents: 'BusinessComponents',
  Client: 'Client',
  Company: 'Company',
  Cot: 'Cot',
  Country: 'Country',
  Dashboard: 'Dashboard',
  DataProtection: 'DataProtection',
  Employee: 'Employee',
  Import: 'Import',
  Notification: 'Notification',
  Payrun: 'Payrun',
  Payroll: 'Payroll',
  PrivacyPolicy: 'PrivacyPolicy',
  Process: 'Process',
  Report: 'Report',
  Resource: 'Resource',
  Service: 'Service',
  Setting: 'Setting',
  Toolbox: 'Toolbox',
  Term: 'Term',
  Vendor: 'Vendor',
}
