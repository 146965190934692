import i18n from 'i18next'

export const getMode2faOptions = () => [
  { value: 'totp', label: i18n.t('Global.option.totp') },
  { value: 'sms', label: i18n.t('Global.option.sms') },
]

export const getMode2faEmailOption = () => ({
  value: 'email',
  label: i18n.t('Global.option.email'),
})
