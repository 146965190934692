import React from 'react'
import RecentReportsContainer from '../containers/RecentReportsContainer'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import { getReportGroupingLabels } from 'utils/enums/reportGroupings'

export default (props) => {
  const reportGroupLabels = getReportGroupingLabels()
  const hrReports = new ReportItemBuilder(reportGroupLabels.HR)
  const financeReports = new ReportItemBuilder(reportGroupLabels.FINANCE)
  const payrollReports = new ReportItemBuilder(reportGroupLabels.PAYROLL)
  const controlAndGovernanceReports = new ReportItemBuilder(reportGroupLabels.CONTROL_AND_GOVERNANCE)

  const reports = [
    { items: hrReports, icon: { name: 'hr-report', bgColor: 'pink' } },
    { items: financeReports, icon: { name: 'finance-report', bgColor: 'light-green' } },
    { items: payrollReports, icon: { name: 'payroll-report', bgColor: 'blue' } },
    { items: controlAndGovernanceReports, icon: { name: 'control-governance-report', bgColor: 'orange' } },
  ]

  return <RecentReportsContainer reportItems={reports} {...props} />
}
