import React from 'react'
import PropTypes from 'prop-types'

import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { ConfirmButton, RejectButton } from 'components/buttons'
import { useTranslation } from 'react-i18next'

export const DeleteConfirmationModal = ({ modalRef, confirmButtonText, rejectButtonText, deleteModalText, onConfirmation, onReject, hideReject }) => {
  const { t } = useTranslation()
  const handleReject = () => {
    onReject?.()
    modalRef?.current?.hideModal()
  }
  return (
    <BaseConfirmationModal ref={modalRef} className='c-modal' modalHeading={t('Global.modal.confirmation')}>
      {deleteModalText || <p>{t('Global.modal.delete_confirmation')}</p>}
      <ConfirmButton buttonText={confirmButtonText} onClick={onConfirmation} />
      {!hideReject && <RejectButton buttonText={rejectButtonText} onClick={handleReject} />}
    </BaseConfirmationModal>
  )
}

DeleteConfirmationModal.propTypes = {
  modalRef: PropTypes.shape({
    current: PropTypes.shape({
      hideModal: PropTypes.func.isRequired,
    }),
  }).isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  rejectButtonText: PropTypes.string,
  deleteModalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onConfirmation: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  hideReject: PropTypes.bool,
}
