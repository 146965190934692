import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import DocumentsView from 'components/documents/DocumentsView'
import { PADLOCK_ICONS_TYPES, PadlockIcon } from 'components/PadlockIcons'
import ConfirmationModal from 'components/ConfirmationModal'
import Modal from 'components/Modal'
import StepperConfirmationModal from 'components/StepperConfirmationModal'
import { batch } from 'react-redux'
import Loader from 'components/Loader'
import PayrunFileModalButtons from './PayrunFileModalButtons'
import { getLockUnlockPayloadString } from 'utils/sections/payruns/lockUnlock'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const PayrollInstanceFileView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const [lockingInProcess, setLockingInProcess] = useState(false)
  const [stepperProcessFinished, setStepperProcessFinished] = useState(false)

  const {
    payrollInstance,
    downloadArchive,
    hasAccess,
    createReport,
    location,
    triggerGlReportCreation,
    canCreateGlReport,
    onHandleSubmit,
    isCot,
    modalState,
    onModalStateChange,
    onZeroTouchSubmit,
    hasUserLockUnlockPermission,
    isLastGtnMappingReportSuccessful,
    isPayrollInstanceLocked,
    hasRightsGTNReimportButton,
    docId,
    docName,
    docVersion,
    isPayrollInstanceOnStepToLockUnlock,
    areModalButtonsEnabled = false,
    disablePayrunTimeAttendanceDownload,
    userHasAccessToChangeLogReport,
  } = props

  const unlockConfirmationModal = useRef(null)
  const lockConfirmationModal = useRef(null)
  const confirmUnlockAlertModal = useRef(null)
  const confirmLockAlertModal = useRef(null)
  const blockedUnlockAlertModal = useRef(null)
  const permissionsAlertModal = useRef(null)
  const reimportGTNConfirmation = useRef(null)
  const reimportGTNConfirmationWithPayrunLocked = useRef(null)
  const successLockWithReimportGTN = useRef(null)

  useEffect(() => {
    if (modalState?.showConfirmUnlock) {
      return confirmUnlockAlertModal.current.showModal()
    }

    if (modalState?.showConfirmLock) {
      return confirmLockAlertModal.current.showModal()
    }

    if (modalState.reimportGTNConfirmationWithPayrunLocked) {
      return reimportGTNConfirmationWithPayrunLocked.current.showModal()
    }

    if (modalState.reimportGTNConfirmationModal) {
      return reimportGTNConfirmation.current.showModal()
    }

    if (modalState.successLockWithGTNReimport && props.payrunState === payrunStatesEnum.ACTIVE) {
      return successLockWithReimportGTN.current.showModal()
    }
  }, [])

  const handleTimedLockingProcess = (source, modalStateChange) => {
    setLockingInProcess(true)
    onHandleSubmit({
      id: payrollInstance.id,
      kuLockUnlockPayrun: getLockUnlockPayloadString(payrollInstance.newLockUnlock.isPayrollInstanceLocked),
    }).then((res) => {
      source.current?.hideModal()
      if (!res.hasError) {
        onModalStateChange(modalStateChange)
      }
      if (res.hasError) {
        blockedUnlockAlertModal.current.showModal()
      }
      batch(() => {
        setLockingInProcess(false)
        setStepperProcessFinished(true)
      })
    })
  }

  const getModalHeading = () => {
    if (payrollInstance) {
      const { companyName, countryName, payrollName, period } = payrollInstance

      return `${companyName}, ${countryName}, ${payrollName}, ${period}`
    }

    return ''
  }

  const togglePayrollInstanceLockUnlock = () => {
    if (!hasUserLockUnlockPermission && !isCot) {
      return permissionsAlertModal.current.showModal()
    }

    if (!isPayrollInstanceOnStepToLockUnlock) {
      return blockedUnlockAlertModal.current.showModal()
    }

    if (isPayrollInstanceLocked) {
      return unlockConfirmationModal.current.showModal()
    }

    lockConfirmationModal.current.showModal()
  }

  let blockedLockUnlockMessage = t('Payrun.modal.payrun_cannot_be_lock_unlock_at_this_stage')

  if (payrollInstance) {
    blockedLockUnlockMessage = payrollInstance.newLockUnlock.isPayrollInstanceLocked
      ? t('Payrun.modal.payrun_cannot_be_unlocked_at_this_stage')
      : t('Payrun.modal.payrun_cannot_be_locked_at_this_stage')
  }

  return (
    <>
      <div className='o-layout'>
        {areModalButtonsEnabled && (
          <PayrunFileModalButtons
            payrollInstance={payrollInstance}
            togglePayrollInstanceLockUnlock={togglePayrollInstanceLockUnlock}
            isPayrollInstanceLocked={isPayrollInstanceLocked}
            hasAccess={hasAccess}
            location={location}
            downloadArchive={downloadArchive}
            createReport={createReport}
            triggerGlReportCreation={triggerGlReportCreation}
            disablePayrunTimeAttendanceDownload={disablePayrunTimeAttendanceDownload}
            canCreateGlReport={canCreateGlReport}
            userHasAccessToChangeLogReport={userHasAccessToChangeLogReport}
          />
        )}
        <ConfirmationModal
          ref={unlockConfirmationModal}
          className='c-modal u-text--valhalla'
          modalHeading={getModalHeading()}
          onConfirm={() => {
            onHandleSubmit({
              id: payrollInstance.id,
              kuLockUnlockPayrun: getLockUnlockPayloadString(payrollInstance.newLockUnlock.isPayrollInstanceLocked),
            }).then(
              (res) =>
                !res.hasError &&
                onModalStateChange({
                  showConfirmLock: false,
                  showConfirmUnlock: true,
                  heading: getModalHeading(),
                })
            )
          }}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBig} />
            </p>
            <p>{t('Payrun.modal.payrun_locked_unlock_question', { payrunName: payrollInstance?.payrollName || t('Global:Global.text.payrun') })}</p>
          </div>
        </ConfirmationModal>

        <StepperConfirmationModal
          ref={lockConfirmationModal}
          className={`c-modal u-text--valhalla ${lockingInProcess ? 'hide-modal-close' : ''}`}
          modalHeading={getModalHeading()}
          hideNextStep={lockingInProcess || stepperProcessFinished}
          disableBackdropClose={lockingInProcess}
          onNextStep={() => {
            const { hasGtnMappingReport, isLastGtnMappingReportSuccessful } = payrollInstance?.newLockUnlock || {}
            const showConfirmLock = !hasGtnMappingReport || (hasGtnMappingReport && isLastGtnMappingReportSuccessful)
            const stateChange = {
              showConfirmLock: !hasRightsGTNReimportButton || showConfirmLock,
              heading: getModalHeading(),
              successLockWithGTNReimport: hasRightsGTNReimportButton && !showConfirmLock,
              documentId: docId,
              documentName: docName,
              documentVersion: docVersion,
            }
            handleTimedLockingProcess(lockConfirmationModal, stateChange)
          }}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            {lockingInProcess && (
              <>
                <Loader />
                <div>{t('Payrun.modal.locking_in_progress')}</div>
              </>
            )}
            {!lockingInProcess && !stepperProcessFinished && (
              <>
                <p className='u-padding-left'>
                  <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockUnlockedBig} />
                </p>
                <p>{t('Payrun.modal.payrun_unlocked_lock_question', { payrunName: payrollInstance?.payrollName || t('Global.text.payrun') })}</p>
              </>
            )}
          </div>
        </StepperConfirmationModal>

        <ConfirmationModal
          ref={successLockWithReimportGTN}
          className='c-modal u-text--valhalla'
          modalHeading={getModalHeading() || modalState.modalHeading}
          onHide={() =>
            onModalStateChange({
              successLockWithGTNReimport: false,
              heading: '',
            })
          }
          onConfirm={() => {
            onZeroTouchSubmit({ id: modalState.documentId }).then((res) => res.success && reimportGTNConfirmation.current.showModal())
          }}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBig} />
            </p>
            <p>
              {t('Payrun.modal.payrun_locked_success_reimport_question')}
              <span className='u-weight--medium'>
                {' '}
                {modalState.documentName}
                {modalState.documentVersion ? `_V${modalState.documentVersion}` : ''}
              </span>{' '}
              ?
            </p>
          </div>
        </ConfirmationModal>

        <Modal
          ref={confirmUnlockAlertModal}
          className='c-modal u-text--valhalla'
          modalHeading={getModalHeading() || modalState.modalHeading}
          onHide={() => onModalStateChange({ showConfirmLock: false, showConfirmUnlock: false, heading: '' })}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p className='u-padding-left'>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockUnlockedBig} />
            </p>
            <p>{t('Payrun.modal.success_unlock')}</p>

            <button
              onClick={() => {
                confirmUnlockAlertModal.current.hideModal()
                onModalStateChange({ showConfirmLock: false, showConfirmUnlock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          ref={confirmLockAlertModal}
          className='c-modal u-text--valhalla'
          modalHeading={getModalHeading() || modalState.modalHeading}
          onHide={() => onModalStateChange({ showConfirmLock: false, showConfirmUnlock: false, heading: '' })}
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBig} />
            </p>
            <p>{t('Payrun.modal.success_lock')}</p>

            <button
              onClick={() => confirmLockAlertModal.current.hideModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal ref={blockedUnlockAlertModal} className='c-modal u-text--valhalla' modalHeading={getModalHeading()}>
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p className={isPayrollInstanceLocked ? '' : 'u-padding-left'}>
              <PadlockIcon
                type={
                  payrollInstance?.newLockUnlock.isPayrollInstanceLocked
                    ? PADLOCK_ICONS_TYPES.padlockLockedBigDisabled
                    : PADLOCK_ICONS_TYPES.padlockUnlockedBigDisabled
                }
              />
            </p>
            <p>{blockedLockUnlockMessage}</p>

            <button
              onClick={() => {
                blockedUnlockAlertModal.current.hideModal()
                onModalStateChange({ showConfirmLock: false, showConfirmUnlock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal ref={permissionsAlertModal} className='c-modal u-text--valhalla' modalHeading={getModalHeading()}>
          <div className='u-text--center u-text--normal u-weight--regular'>
            <p>
              <PadlockIcon type={PADLOCK_ICONS_TYPES.padlockLockedBigDisabled} />
            </p>
            <p>{t('Payrun.modal.not_authorized_to_unlock')}</p>

            <button
              onClick={() => {
                permissionsAlertModal.current.hideModal()
                onModalStateChange({ showConfirmLock: false, showConfirmUnlock: false, heading: '' })
              }}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          ref={reimportGTNConfirmation}
          className='c-modal u-text--valhalla'
          modalHeading={t('Payrun.modal.gtn_mapping_and_import_in_progress')}
          onHide={() =>
            onModalStateChange({
              showConfirmLock: false,
              showConfirmUnlock: false,
              heading: '',
              reimportGTNConfirmationModal: false,
              successLockWithGTNReimport: false,
            })
          }
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <div className='u-relative u-margin-bottom zt zt-animation' />
            <p>{t('Payrun.modal.gtn_produced')}</p>

            <button
              onClick={() => reimportGTNConfirmation.current.hideModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          ref={reimportGTNConfirmationWithPayrunLocked}
          className='c-modal u-text--valhalla'
          modalHeading={t('Payrun.modal.file_locked_gtn_and_import_in_progress')}
          onHide={() =>
            onModalStateChange({
              showConfirmLock: false,
              showConfirmUnlock: false,
              heading: '',
              reimportGTNConfirmationWithPayrunLocked: false,
              successLockWithGTNReimport: false,
            })
          }
        >
          <div className='u-text--center u-text--normal u-weight--regular'>
            <div className='u-relative u-margin-bottom zt zt-animation' />
            <p>{t('Payrun.modal.gtn_produced')}</p>

            <button
              onClick={() => reimportGTNConfirmationWithPayrunLocked.current.hideModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`closeModal`}
            >
              OK
            </button>
          </div>
        </Modal>
      </div>

      <DocumentsView
        typeClass='payrollInstance'
        typeId={payrollInstance.id}
        payrollInstanceId={payrollInstance.id}
        title={t('Payrun.text.other_files')}
        companyName={payrollInstance.companyName}
        countryName={payrollInstance.countryName}
        payrollName={payrollInstance.payrollName}
        currentStep={payrollInstance.currentStep.name}
        hasUserLockUnlockPermission={hasUserLockUnlockPermission}
        isPayrollInstanceLocked={isPayrollInstanceLocked}
        isLastGtnMappingReportSuccessful={isLastGtnMappingReportSuccessful}
        {...props}
      />
    </>
  )
}

PayrollInstanceFileView.propTypes = {
  isCot: PropTypes.bool,
  hasAccess: PropTypes.bool,
  canCreateGlReport: PropTypes.bool,
  hasGtnMappingReport: PropTypes.bool,
  areModalButtonsEnabled: PropTypes.bool,
  isPayrollInstanceLocked: PropTypes.bool,
  hasRightsGTNReimportButton: PropTypes.bool,
  hasUserLockUnlockPermission: PropTypes.bool,
  isLastGtnMappingReportSuccessful: PropTypes.bool,
  isPayrollInstanceOnStepToLockUnlock: PropTypes.bool,
  createReport: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  downloadArchive: PropTypes.func,
  onZeroTouchSubmit: PropTypes.func,
  onModalStateChange: PropTypes.func,
  triggerGlReportCreation: PropTypes.func,
  docId: PropTypes.number,
  docVersion: PropTypes.number,
  history: PropTypes.object,
  location: PropTypes.object,
  payrollInstance: PropTypes.object,
  modalState: PropTypes.object,
  docName: PropTypes.string,
  disablePayrunTimeAttendanceDownload: PropTypes.bool,
  userHasAccessToChangeLogReport: PropTypes.bool,
  payrunState: PropTypes.string,
}

export default PayrollInstanceFileView
