import React, { useState } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { pull, uniq } from 'lodash'
import moment from 'moment'
import PayslipTable from 'components/table/PayslipTable'
import FilterButton from 'components/buttons/FilterButton'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PredefinedRateForm = ({
  selected,
  fxRates,
  customRatesFieldsNames,
  currencies,
  setReportingCurrencyDetails,
  resetFormFields,
  isFieldDisabled,
  disabledReason,
  onHide,
  shouldShowCheckboxOptions,
}) => {
  const [predefinedFxRateIds, setPredefinedFxRateIds] = useState([])
  const [showFilters, setShowFilters] = useState(false)

  const { t } = useTranslation([i18nNameSpaces.Report])

  const onRowSelectCheckbox = (row) => {
    const rateIds = [...predefinedFxRateIds]
    if (!row.isSelected) {
      rateIds.push(row.original.id)
    } else {
      pull(rateIds, row.original.id)
    }
    setPredefinedFxRateIds(rateIds)
  }

  const onRowSelectRadio = (row) => {
    if (!row.isSelected) {
      setPredefinedFxRateIds([row.original.id])
    } else {
      setPredefinedFxRateIds([])
    }
  }

  const setUnselectable = (row) => {
    const selectedFromCurrency = uniq(predefinedFxRateIds.map((id) => fxRates.find((rate) => rate.id === id).fromCurrency.id))

    const disabledRates = fxRates
      .filter((rate) => selectedFromCurrency.includes(rate.fromCurrency.id) && !predefinedFxRateIds.includes(rate.id))
      .map((rate) => rate.id)

    return disabledRates.includes(row.original.id)
  }

  const { payrollCurrency, toCurrency } = selected

  // Checkbox logic
  const isCheckbox = shouldShowCheckboxOptions

  const shouldShowSaveButton =
    predefinedFxRateIds.length === payrollCurrency.length || (payrollCurrency.includes(toCurrency) && predefinedFxRateIds.length === payrollCurrency.length - 1)

  const options = {
    noDataText: t('Report.text.no_predefined_fx_rates'),
  }

  const selectRowProp = {
    type: isCheckbox ? 'checkbox' : 'radio',
    clickToSelect: true,
    onClick: (row, isSelected) => (isCheckbox ? onRowSelectCheckbox(row, isSelected) : onRowSelectRadio(row, isSelected)),
    columnWidth: '40px',
    bgColor: '#b9adca',
    isUnselectable: isCheckbox ? (row) => setUnselectable(row) : [],
  }

  return (
    <div className='o-layout__item u-1/1' data-testid='predefinedRate-form'>
      <SectionHeading text={t('Report.modal.predefined_rate')} />
      <div className='c-panel u-margin-top u-margin-bottom u-bg--white border-1-valhalla rounded u-padding-small'>
        <Field
          name='toCurrency'
          label={t('Report.field.choose_currency')}
          component={CustomSelectField}
          className='c-custom-select--transparent'
          labelClassName='c-label u-text--small u-text--curious'
          formGroupClassName='u-margin-none'
          placeholder={t('Global:Global.select.placeholder')}
          disabled={isFieldDisabled}
          disabledReason={disabledReason}
          options={currencies.map((currency) => ({
            value: currency.id,
            label: currency.abbreviature,
          }))}
          onChanged={(value) => {
            setPredefinedFxRateIds([])
            const fieldsToReset = ['fxRate', 'rate', ...customRatesFieldsNames]
            resetFormFields(value, fieldsToReset)
          }}
          data-testid='toCurrency'
        />
      </div>
      {toCurrency && (
        <div>
          <div className='d-flex jc--space-between'>
            <p className='u-margin-bottom-none'>{t('Report.text.add_predefined_rate')}</p>
            <div>
              <FilterButton
                className='c-btn c-btn--small c-btn--curious u-padding-left float-right u-padding-right'
                onClick={() => setShowFilters(!showFilters)}
              />
            </div>
          </div>
          <PayslipTable
            tableElementClassName='table--layout-auto'
            data={fxRates.map((fxRate) => ({
              id: fxRate.id,
              date: moment(fxRate.date.date).format('DD/MM/YYYY'),
              fromCurrency: fxRate.fromCurrency.abbreviature,
              toCurrency: fxRate.toCurrency.abbreviature,
              rate: fxRate.rate,
              notes: fxRate.notes,
            }))}
            headings={[
              { accessor: 'date', Header: t('Report.payslipTable.header.date') },
              {
                accessor: 'fromCurrency',
                Header: t('Report.payslipTable.header.from_currency'),
              },
              { accessor: 'toCurrency', Header: t('Report.payslipTable.header.to_currency') },
              {
                accessor: 'rate',
                Header: t('Report.payslipTable.header.rate'),
                dataFormatter: 'cellWithDataTestId',
                formatExtraData: { testPrefix: 'fxRate' },
              },
              {
                accessor: 'notes',
                Header: t('Report.payslipTable.header.notes'),
                disableSortBy: true,
                disableFilters: true,
              },
            ]}
            wrapperClassName='u-margin-top-small u-margin-bottom u-1/1 react-bs-table--overflow-auto'
            trClassName='react-bs-table__cell--whitespace--normal'
            rowSelection={selectRowProp}
            showFilters={showFilters}
            options={options}
          />
        </div>
      )}
      {shouldShowSaveButton && (
        <div className='u-text--center'>
          <button
            type='button'
            onClick={() => {
              setReportingCurrencyDetails(predefinedFxRateIds)
              onHide()
            }}
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            data-testid='save-button'
          >
            {t('Global:Global.button.save')}
          </button>
        </div>
      )}
    </div>
  )
}

PredefinedRateForm.propTypes = {
  selected: PropTypes.object,
  fxRates: PropTypes.array,
  customRatesFieldsNames: PropTypes.array,
  currencies: PropTypes.array,
  setReportingCurrencyDetails: PropTypes.func,
  resetFormFields: PropTypes.func,
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  onHide: PropTypes.func,
  shouldShowCheckboxOptions: PropTypes.bool,
}

export default PredefinedRateForm
