import useQuery from 'hooks/useQuery'
import React from 'react'
import PayrollInstanceView from 'routes/Payruns/components/PayrollInstanceView'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const { actionOwnersButtonItem, addPayAndTaxesElementsButton } = PayrollTabModalOptionButtons

  return (
    <PayrollInstanceView
      payrunState='previousWithExtraTasks'
      query={useQuery()}
      heading={t('Payrun.text.postPayrollSteps')}
      buttonOptions={{ ...actionOwnersButtonItem, ...addPayAndTaxesElementsButton }}
      {...props}
    />
  )
}
