/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { owners } from 'redux/config/tasks'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import CheckboxWrapper from 'components/form/CheckboxWrapper'
import PayslipTable from './table/PayslipTable'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const InactiveUser = ({ text }) => <span className='u-text--italic u-text--small'>{text}</span>

InactiveUser.propTypes = {
  text: PropTypes.string.isRequired,
}

const ProcessTree = (props) => {
  const {
    typeClass,
    isShare,
    sharingActionText = 'Share',
    initialValues,
    isOwner,
    rows,
    name,
    owner,
    processId,
    isPayrollProcess,
    onProcessEdit,
    onTaskEdit,
    onStepEdit,
    onCreate,
    payDate,
    keyEvent,
    isCot,
    isEditDisabled,
    hideProcessOwner,
    addBtnTitle,
    isReadOnlyMode,
    hideProcessDetailsAsVendor,
  } = props
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const buildColumns = () => {
    const headings = []

    if (typeClass !== 'vendor') {
      headings.push(
        {
          accessor: 'stepName',
          Header: t('BusinessComponent.table.step_name'),
          disableSortBy: true,
          disableFilters: true,
        },
        { accessor: 'stepAction', Header: '', disableSortBy: true, disableFilters: true },
        {
          accessor: 'taskName',
          Header: t('BusinessComponent.table.task_name'),
          disableSortBy: true,
          disableFilters: true,
        }
      )
    }

    headings.push({
      accessor: 'owner',
      Header: t('BusinessComponent.table.action_owner'),
      setWidth: '160px',
      disableSortBy: true,
      classNames: 'white-space--unset',
      disableFilters: true,
    })

    if (isShare) {
      headings.push({
        accessor: t('BusinessComponent.table.share_action_text'),
        Header: sharingActionText,
        classNames: 'text-center',
        columnClassName: 'u-text--center',
        disableSortBy: true,
        disableFilters: true,
      })
    }

    if (!isShare) {
      headings.push(
        {
          accessor: 'taskDeadline',
          Header: t('BusinessComponent.table.timelines'),
          classNames: 'text-center',
          columnClassName: 'text-center',
          formatExtraData: { isInheritColor: true },
          disableSortBy: true,
          disableFilters: true,
        },
        {
          accessor: 'action',
          Header: '',
          disableSortBy: true,
          disableFilters: true,
        }
      )
    }

    return headings
  }
  const getTaskOwner = (row) => {
    // Here we have two cases:
    // 1. Task global owner is not Company, Payslip or Payroll Partner,
    // 2. The owner is Payslip or Payroll Partner and it's task is active
    if (
      ![owners.company, owners.cot, owners.vendor].includes(row.taskGlobalOwner) ||
      ([owners.cot, owners.vendor].includes(row.taskGlobalOwner) && row.taskActive)
    ) {
      return row.taskGlobalOwner
    }

    // Task global owner is company, the task has no key people for owner and the task is active
    if (!row.taskOwner.fullname && !row.taskOwner.multi && row.taskActive) return <span className='u-text--italic'>{t('Global:Global.text.company')}</span>

    // Task global owner is company, the task has no key people for owner and the task is not active
    if (!row.taskOwner.fullname && !row.taskOwner.multi && !row.taskActive) {
      let owner = 'Not needed'
      if (row.taskGlobalOwner === owners.cot) return `${owner} (${owners.cot})`

      if (row.taskGlobalOwner === owners.vendor) return `${owner} (${owners.vendor})`

      if (row.taskGlobalOwner === owners.company) return `${owner} (${owners.company})`

      return owner
    }

    // Task global owner is company
    // taskableType is "BusinessUnit"
    // We have to show the multi label and it has to be clickable
    if (row.taskableType === 'BusinessUnit' && !isShare) {
      return (
        <span onClick={() => props.onMultiView(row.taskId, true)} className='u-text--curious u-cursor--pointer'>
          {row.taskOwner.multi}
        </span>
      )
    }

    // Share mode on files section
    // Task global owner is company
    // taskableType is "BusinessUnit"
    // We have to show the task owner of every single business unit
    if (row.taskableType === 'BusinessUnit' && isShare) {
      return (
        <div>
          {row.taskOwner.multiOwners.map((owner) => {
            return owner.hasCredentials ? (
              <div className='u-margin-bottom-tiny'>{owner.fullname}</div>
            ) : (
              <div>
                <InactiveUser text={t('Global:Global.text.inactive_user')} />
              </div>
            )
          })}
        </div>
      )
    }

    // In any other case display just the task owner full name
    return row.taskOwner.hasCredentials ? row.taskOwner.fullname : <InactiveUser text={t('Global:Global.text.inactive_user')} />
  }
  const getTaskOwnerShareCheckbox = (row) => {
    // The task has single taskOwner and the owner has credentials
    if (row.taskOwner && row.taskOwner.id && row.taskOwner.hasCredentials) {
      return (
        <Field
          type='checkbox'
          labelClassName='u-hidden'
          component={CheckboxField}
          className='control__indicator__input'
          indicatorClassName='control__indicator--center'
          formGroupClassName='u-margin-none'
          name={`${row.taskOwner.schema}-${row.taskOwner.id}`}
          disabled={initialValues[`${row.taskOwner.schema}-${row.taskOwner.id}`] && !isOwner}
          data-testid={`share-checkbox-${row.taskOwner.schema}-${row.taskOwner.id}`}
        />
      )
    }

    // The task has multi taskOwner-s
    if (row.taskOwner.multiOwners && row.taskOwner.multiOwners.length) {
      return (
        <div>
          {row.taskOwner.multiOwners.map((owner) => {
            // In the case the `owner` doesn't have credentials, we imitate and still render `CheckboxWrapper` layout,
            // in order too keep the checkboxes aligned alongside to owner's names.
            // Otherwise the checkboxes will be displaced.
            return owner.hasCredentials ? (
              <Field
                type='checkbox'
                labelClassName='u-hidden'
                component={CheckboxField}
                className='control__indicator__input'
                indicatorClassName='control__indicator--center'
                formGroupClassName='u-margin-bottom-tiny'
                name={`${owner.schema}-${owner.id}`}
                disabled={initialValues[`${row.taskOwner.schema}-${row.taskOwner.id}`] && !isOwner}
                data-testid={`share-checkbox-${row.taskOwner.schema}-${row.taskOwner.id}`}
              />
            ) : (
              <div className='o-form-group u-relative u-margin-bottom-tiny u-zero-opacity'>
                <CheckboxWrapper />
              </div>
            )
          })}
        </div>
      )
    }

    return null
  }
  return (
    <>
      {!hideProcessDetailsAsVendor && !isShare && (
        <div className='o-layout'>
          <div className='o-layout__item u-1/1 u-margin-bottom-small'>
            <Trans
              i18nKey='BusinessComponent.label.process_name'
              ns={i18nNameSpaces.BusinessComponents}
              values={{ name }}
              components={[<span key='process_name' className='u-weight--bold' />]}
            />
          </div>
          <div className='o-layout__item u-1/1 u-margin-bottom-small'>
            <Trans
              i18nKey='BusinessComponent.label.process_owner'
              ns={i18nNameSpaces.BusinessComponents}
              values={{ owner: owner ? owner.fullname : null }}
              components={[<span key='process_owner' className='u-weight--bold' />]}
            />
            {!isEditDisabled && !isReadOnlyMode ? (
              <button
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-left-small'
                title={t('Global:Global.text.edit')}
                onClick={() => onProcessEdit(processId, 'editOwner')}
              >
                <span className='icon icon--edit' data-testid='processOwner-edit' />
              </button>
            ) : null}
          </div>
          <div className='o-layout__item u-1/1 u-margin-bottom-small'>
            <Trans
              i18nKey='BusinessComponent.label.process_key_event'
              ns={i18nNameSpaces.BusinessComponents}
              values={{ keyEvent }}
              components={[<span key='process_key_event' className='u-weight--bold' />]}
            />
          </div>
          {isPayrollProcess ? (
            <div className='o-layout__item u-1/1 u-margin-bottom-small'>
              <Trans
                i18nKey='BusinessComponent.label.process_event_date'
                ns={i18nNameSpaces.BusinessComponents}
                values={{ payDate }}
                components={[<span key='process_event_date' className='u-weight--bold' />]}
              />
            </div>
          ) : null}
        </div>
      )}
      <div className='o-layout'>
        <div className={!isShare ? 'o-layout__item u-1/1 u-2/3@desktop' : 'o-layout__item u-1/1'}>
          {!isShare && isPayrollProcess && !isEditDisabled && (
            <div className='u-text--right'>
              <div
                onClick={onCreate}
                className='c-btn c-btn--tiny u-text--tiny u-cursor--pointer c-btn--curious u-margin-bottom-tiny'
                style={{ marginRight: 8 }}
                title={addBtnTitle || t('Global:Global.text.add')}
                data-testid='create'
              >
                <span className='icon icon--plus' />
              </div>
            </div>
          )}
          {isShare && owner && (
            <PayslipTable
              data={
                !hideProcessOwner
                  ? [
                    {
                      id: owner.id,
                      name: owner.hasCredentials ? owner.fullname : <InactiveUser text={t('Global:Global.text.inactive_user')} />,
                      share: owner.hasCredentials ? (
                        <Field
                          type='checkbox'
                          labelClassName='u-hidden'
                          component={CheckboxField}
                          className='control__indicator__input'
                          indicatorClassName='control__indicator--center'
                          formGroupClassName='u-margin-none forced-padding-right--8px'
                          name={`${owner.schema}-${owner.id}`}
                          disabled={initialValues[`${owner.schema}-${owner.id}`] && !isOwner}
                          data-testid={`share-checkbox-${owner.schema}-${owner.id}`}
                        />
                      ) : null,
                    },
                  ]
                  : []
              }
              headings={[
                {
                  accessor: 'name',
                  Header: t('BusinessComponent.text.process_owner'),
                  disableSortBy: true,
                  disableFilters: true,
                },
                {
                  accessor: 'share',
                  Header: sharingActionText,
                  classNames: 'text-right',
                  columnClassName: 'u-text--right',
                  disableSortBy: true,
                  disableFilters: true,
                },
              ]}
              wrapperClassName='u-1/1 u-margin-bottom'
              trClassName='react-bs-table__cell--whitespace--normal'
            />
          )}

          <PayslipTable
            data={rows.map((row) => {
              const isEditableRow =
                (isCot || ((row.taskGlobalOwner === owners.company || row.taskGlobalOwner === owners.vendor) && !isEditDisabled)) && !isReadOnlyMode
              return {
                id: row.id,
                stepName: row.stepName,
                stepAction:
                  row.stepIsMoveable && !isEditDisabled ? (
                    <button
                      className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'
                      title={t('Global:Global.text.edit')}
                      onClick={() => onStepEdit(row.stepId)}
                      data-testid={`step-${row.id}-edit`}
                    >
                      <span className='icon icon--edit' />
                    </button>
                  ) : null,
                taskName: row.taskName,
                owner: getTaskOwner(row),
                ...(isShare ? { share: getTaskOwnerShareCheckbox(row) } : {}),
                taskDeadline: row.taskDeadline,
                action:
                  !hideProcessDetailsAsVendor && row.taskName && isEditableRow ? (
                    <button
                      className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'
                      title={t('Global:Global.text.edit')}
                      onClick={() => onTaskEdit(row.taskId)}
                      data-testid={`task-${row.taskId}-edit`}
                    >
                      <span className='icon icon--edit' />
                    </button>
                  ) : null,
              }
            })}
            headings={buildColumns()}
            tableElementClassName='u-margin-bottom-large table--layout-auto'
            wrapperClassName='u-1/1'
            data-testid='process-tree-table'
          />
        </div>
      </div>
    </>
  )
}

ProcessTree.propTypes = {
  typeClass: PropTypes.string,
  processId: PropTypes.number,
  isPayrollProcess: PropTypes.bool,
  isCot: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  name: PropTypes.string,
  owner: PropTypes.object,
  payDate: PropTypes.string,
  keyEvent: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string,
      taskName: PropTypes.string,
      taskOwner: PropTypes.object,
      taskDeadline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  onTaskEdit: PropTypes.func,
  onMultiView: PropTypes.func,
  onProcessEdit: PropTypes.func,
  isReadOnlyMode: PropTypes.bool,
  isShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  initialValues: PropTypes.object,
  sharingActionText: PropTypes.string,
  onStepEdit: PropTypes.func,
  onCreate: PropTypes.func,
  hideProcessOwner: PropTypes.bool,
  addBtnTitle: PropTypes.string,
  hideProcessDetailsAsVendor: PropTypes.bool,
}

export default ProcessTree
