import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import PropTypes from 'prop-types'
import { clearMessage, showMessage } from 'redux/actions/modal'
import ImportFile from '../components/ImportFile'
import Fetcher from 'containers/Fetcher'
import { errorToString } from '../../../utils/apiErrors'
import { getPayrollRef } from '../../../redux/selectors/payroll'
import i18n from 'i18next'

const mapStateToProps = (state, { payrollId, shouldUseCompanyAsFilter }) => ({
  showConfirmation: payrollId && !shouldUseCompanyAsFilter ? getPayrollRef(state, { payrollId }).hasLockedPayrollInstances : false,
})
const mapDispatchToProps = (
  dispatch,
  { hasFilters, payrollId, companyId, shouldIncludeAllPayrollsOption, shouldUseCompanyAsFilter, importAction, downloadAction }
) => {
  return {
    getFile: (link) => dispatch(downloadAction(link)),
    importFile: (file) => {
      return dispatch(importAction({ payrollId, companyId, file, shouldUseCompanyAsFilter })).then((response) => {
        if (!response.errors) {
          dispatch(
            showMessage({
              body: i18n.t('Global:Global.message.file_uploaded'),
            })
          )
        }

        if (response.errorsCommon) {
          // When importing a file, we rely on `errors` field, in case of a wrong imported data.
          // However, just in case, we'll fallback to our default form validation, in order to handle use-cases
          // where `errorsCommon` are returned too.
          dispatch(
            showMessage({
              body: errorToString(new SubmissionError({ _error: response.errorsCommon }), false),
            })
          )
        }

        if (response.errors && response.errors.file) {
          dispatch(
            showMessage({
              body: response.errors.file,
            })
          )
        }
        if (response.errors && !response.errors.file) {
          dispatch(clearMessage())
        }
        return response
      })
    },
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(ImportFile)

Container.propTypes = {
  payrollId: PropTypes.number,
  importAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func,
  entityName: PropTypes.string.isRequired,
  shouldUseCompanyAsFilter: PropTypes.bool,
}
export default Fetcher(Container, ['payrolls'])
