import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchAccessAreaPivotIfNeeded } from 'redux/actions/accessAreaPivot'
import { fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { getAccessibleCompaniesRef } from 'redux/selectors/company'
import Logo from '../Logo'
import MainNav from '../MainNav'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router'
import { setActiveMenuByRoute } from 'utils/router'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { useTranslation } from 'react-i18next'

const Aside = (props) => {
  const {
    selectAside,
    asideMode,
    fetchCompaniesIfNeeded,
    fetchAccessAreaPivotIfNeeded,
    fetchParentCompaniesIfNeeded,
    isUser,
    userId,
    hasAccess,
    userHasAccessToPICC,
    controlCenterUrl,
    hasAccessToResources,
  } = props
  const match = useRouteMatch()
  const { t } = useTranslation()

  const renderItems = () => {
    let items = [
      {
        title: t('Global:Global.aside.home'),
        name: 'home',
        path: '/dashboard',
        isHome: true,
      },
      {
        title: t('Global:Global.aside.company'),
        name: 'company',
        path: '/companies',
      },
      {
        title: t('Global:Global.aside.people'),
        name: 'people',
        path: '/employees',
        exact: setActiveMenuByRoute({ match, userId }),
      },
      {
        title: t('Global:Global.aside.payroll'),
        name: 'payroll',
        path: '/payruns/active',
        isActive: () => setActiveMenuByRoute({ match, path: '/payruns' }),
      },
      {
        title: t('Global:Global.aside.toolbox'),
        name: 'toolbox',
        path: '/toolbox',
        isLink: false,
        children: [
          { title: t('Global:Global.aside.calendar'), name: 'calendar', path: '/toolbox/calendar', sub: true },
          { title: t('Global:Global.aside.actions'), name: 'actions', path: '/toolbox/actions', sub: true },
          { title: t('Global:Global.aside.alerts'), name: 'alerts', path: '/toolbox/alerts', sub: true },
        ],
      },
    ]

    if (hasAccess(['REPORTING_VIEW'])) {
      items.push({
        title: t('Global:Global.aside.reporting'),
        name: 'reporting',
        path: '/reporting/customize-report',
      })
    }

    if (userHasAccessToPICC) {
      items.push({
        title: t('Global:Global.aside.picc'),
        name: 'control-center',
        path: controlCenterUrl,
        isExternal: true,
      })
    }

    if (hasAccessToResources) {
      items.push({
        title: t('Global:Global.aside.resources'),
        name: 'resources',
        path: '/resources/releases',
      })
    }

    if (isUser) {
      items.push({
        title: t('Global:Global.aside.my_employee_profile'),
        name: 'profile',
        path: `/employees/${userId}/personal`,
        isHome: true,
        isActive: () => setActiveMenuByRoute({ match, userId }),
      })
    }

    return items
  }

  useEffect(() => {
    fetchCompaniesIfNeeded()
    fetchAccessAreaPivotIfNeeded()
    fetchParentCompaniesIfNeeded()
  }, [])

  return (
    <aside className='o-aside'>
      <Logo />

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav items={renderItems()} {...props} />
    </aside>
  )
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => ({
  fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
  fetchAccessAreaPivotIfNeeded: () => dispatch(fetchAccessAreaPivotIfNeeded()),
  fetchParentCompaniesIfNeeded: () => dispatch(fetchParentCompaniesIfNeeded()),
})

// map to state
const mapStateToProps = (state, props) => {
  if (isFetching([state.companies, state.accessAreaPivot, state.parentCompanies])) {
    return { isFetching: true }
  }

  return {
    companies: getAccessibleCompaniesRef(state, props),
  }
}

Aside.propTypes = {
  isFetching: PropTypes.bool,
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isUser: PropTypes.bool,
  userId: PropTypes.number,
  hasAccess: PropTypes.func,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchAccessAreaPivotIfNeeded: PropTypes.func,
  fetchParentCompaniesIfNeeded: PropTypes.func,
  history: PropTypes.object,
  userHasAccessToPICC: PropTypes.bool,
  controlCenterUrl: PropTypes.string,
  hasAccessToResources: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
