import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'redux-form'
import PasswordInputField from 'components/form/PasswordInputField'
import { Link } from 'react-router-dom'
import Form from 'components/form/Form'
import PasswordPolicyMessage from 'components/auth/PasswordPolicyMessage'

export const ChangePasswordView = (props) => {
  const { t } = useTranslation()

  return (
    <div className='u-margin-bottom-large'>
      <div className='o-grid o-grid--center'>
        <h5>
          <b>{t('Global.heading.password_security')}</b>
        </h5>
        {props.initialValues.showLegacyPasswordReason ? (
          <p className='u-text--normal'>{t('Global.text.account_security_priority')}</p>
        ) : (
          <p className='u-text--normal u-text-left'>{t('Global.text.password_expired')}</p>
        )}
        <p className='u-text--normal'>{t('Global.text.password_creation_required')}</p>
        <PasswordPolicyMessage />
      </div>
      <div className='o-grid o-grid--center'>
        <div>
          <Form showSubmitBtn btnText={t('Global.button.send')} colorClassBtn='c-btn--curious' btnFullWidth classNames='c-btn--rounded' {...props}>
            <div className='o-form-group'>
              <Field
                name='old'
                label={t('Global.input_label.current_password')}
                component={PasswordInputField}
                type='password'
                autoFocus
                className='c-input c-input--alt c-input--rounded u-margin-bottom-tiny'
                labelClassName='c-label u-text--curious'
                passwordField
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='password'
                label={t('Global.input_label.new_password')}
                component={PasswordInputField}
                type='password'
                className='c-input c-input--alt c-input--rounded u-margin-bottom-tiny'
                labelClassName='c-label u-text--curious'
                passwordField
                showPasswordStrengthBox
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='passwordConfirmation'
                label={t('Global.input_label.password_confirmation')}
                component={PasswordInputField}
                type='password'
                className='c-input c-input--alt c-input--rounded u-margin-bottom-tiny'
                labelClassName='c-label u-text--curious'
                passwordField
              />
            </div>
          </Form>
          <div className='o-layout o-grid o-grid--center u-margin-top-small'>
            <Link to='/forgotten-password' className='u-text--small u-text--curious'>
              {t('Global.link.forgotten_password')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

ChangePasswordView.propTypes = {
  hidePasswordInputs: PropTypes.bool,
  initialValues: PropTypes.shape({
    showLegacyPasswordReason: PropTypes.bool,
  }),
}

export default ChangePasswordView
