import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import LoginImage from './LoginImage'

export const LoginWrapper = ({ children }) => {
  return (
    <div className='c-login-wrapper'>
      <LoginImage />
      <div className='c-login__content'>
        <div>
          <NavLink to='/' className='c-logo c-logo--full c-logo--full--large o-block u-center' />
        </div>
        {children}
      </div>
    </div>
  )
}

LoginWrapper.propTypes = {
  children: PropTypes.node,
}

export default LoginWrapper
