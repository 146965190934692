import React, { useRef, useState } from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import Modal from 'components/Modal'
import CotUserListContainer from '../containers/CotUserListContainer'
import CotUserFormEditContainer from '../containers/CotUserFormEditContainer'
import CotUserFormCreateContainer from '../containers/CotUserFormCreateContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const [cotUserId, setCotUserId] = useState(null)
  const createModalRef = useRef(null)
  const editModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Cot])
  return (
    <InnerWrapper>
      <Modal ref={createModalRef} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Cot.modal.heading.add_user')}>
        <CotUserFormCreateContainer onCreate={() => createModalRef.current.hideModal()} {...props} />
      </Modal>
      <Modal ref={editModalRef} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Cot.modal.heading.edit_user_info')}>
        <CotUserFormEditContainer cotUserId={cotUserId} onEdit={() => editModalRef.current.hideModal()} />
      </Modal>
      <CotUserListContainer
        onCreate={() => createModalRef.current.showModal()}
        onEdit={(id) => {
          setCotUserId(id)
          editModalRef.current.showModal()
        }}
        {...props}
      />
    </InnerWrapper>
  )
}

export default RouteView
