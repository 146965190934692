import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import Modal from 'components/Modal'
import DataProtectionPolicyEditContainer from '../containers/DataProtectionPolicyEditContainer'
import RevisionFieldsContainer from '../containers/RevisionFieldsContainer'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const DataProtectionPolicyList = (props) => {
  const { t } = useTranslation([i18nNameSpaces.DataProtection])
  const [revisionId, setRevisionId] = useState(null)
  const { data, revisions } = props
  const revisionsCount = revisions.length
  const editDataProtectionPolicyModal = useRef(null)
  const dpfRevisionPreviewModal = useRef(null)

  return (
    <div>
      <div className='o-layout'>
        <Authorization permissions={['COMPANYDATAPROTECTIONFIELDREVISION_EDIT']}>
          <div className='o-layout--right o-layout__item u-1/1'>
            <div
              onClick={() => editDataProtectionPolicyModal?.current?.showModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom'
              title={t('Global:Global.text.edit')}
            >
              <span className='icon icon--edit' />
            </div>
          </div>
        </Authorization>
      </div>
      <Modal ref={editDataProtectionPolicyModal} className='c-modal c-modal--half' modalHeading={t('DataProtection.modal.editHeading')}>
        <DataProtectionPolicyEditContainer data={data} onEdit={() => editDataProtectionPolicyModal?.current?.hideModal()} {...props} />
      </Modal>
      <Modal ref={dpfRevisionPreviewModal} className='c-modal c-modal--half c-modal--overflow-y' modalHeading={t('DataProtection.modal.fieldsHistoryHeading')}>
        <RevisionFieldsContainer revisionId={revisionId} {...props} />
      </Modal>
      <PayslipTable
        data={[
          {
            field: (
              <div className='o-layout o-layout--middle'>
                <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.table.retentionPeriod')}</div>
                <div className='u-text--normal o-layout__item u-2/3'>{data.retentionPeriod}</div>
              </div>
            ),
          },
          {
            field: (
              <div className='o-layout o-layout--middle'>
                <div className='u-text--normal o-layout__item u-1/3'>{t('Global.label.process')}</div>
                <div className='u-text--normal o-layout__item u-2/3'>{data.process}</div>
              </div>
            ),
          },
          {
            field: (
              <div className='o-layout o-layout--middle'>
                <div className='u-text--normal o-layout__item u-1/3'>{t('Global.label.trigger')}</div>
                <div className='u-text--normal o-layout__item u-2/3'>{data.trigger}</div>
              </div>
            ),
          },
          {
            field: (
              <div className='o-layout o-layout--middle'>
                <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.table.dataOwners')}</div>
                <div className='u-text--normal o-layout__item u-2/3'>{data.dataOwners.join(', ')}</div>
              </div>
            ),
          },
          {
            field: (
              <div className='o-layout o-layout--middle'>
                <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.table.dataFieldsRegister')}</div>
                <div className='u-text--normal o-layout__item u-2/3'>
                  {revisions.map((rev, index) => {
                    const postfix = revisionsCount !== index + 1 ? ', ' : ''
                    return (
                      <span
                        key={rev.id}
                        className='u-cursor--pointer u-margin-bottom-tiny u-text--curious'
                        onClick={() => {
                          setRevisionId(rev.id)
                          dpfRevisionPreviewModal?.current?.showModal()
                        }}
                      >
                        {`${rev.label}${postfix}`}
                      </span>
                    )
                  })}
                </div>
              </div>
            ),
          },
        ]}
        headings={[
          {
            accessor: 'field',
            Header: t('DataProtection.table.heading'),
            isKey: true,
            classNames: 'text-center',
            disableSortBy: true,
            disableFilters: true,
          },
        ]}
        wrapperClassName='u-margin-bottom u-1/1 table-text-full-length'
      />
    </div>
  )
}

DataProtectionPolicyList.propTypes = {
  data: PropTypes.object,
  revisions: PropTypes.array,
}

export default DataProtectionPolicyList
