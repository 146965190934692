/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PrimaryButton } from 'components/buttons/index'
import { Authorization } from 'containers/Authorization'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'
import { useDispatch } from 'react-redux'
import { destroy } from 'redux-form'

const Option = ({ title, locationPath, payrollInstanceId, onClick = () => null, pathSuffix }) => {
  return (
    <div className='o-layout__item u-1/1 u-margin-bottom-small'>
      <Link to={`${locationPath}/${payrollInstanceId}/${pathSuffix}`} onClick={onClick}>
        <PrimaryButton classes='c-btn--full' data-testid={title}>
          {title}
        </PrimaryButton>
      </Link>
    </div>
  )
}

const Options = ({ availableOptions: availableOptionsProps, shouldShowPayAndTaxesButton, ...props }) => {
  const availableOptions = PayrollTabModalOptionButtons.allButtonItems
  const dispatch = useDispatch()
  return (
    <div className='o-layout u-text--center'>
      {availableOptions.payDateButton && <Option title={availableOptions.payDateButton} pathSuffix='change-details' {...props} />}

      {availableOptions.actionOwnersButton && <Option title={availableOptions.actionOwnersButton} pathSuffix='change-details' {...props} />}

      {availableOptions.addMorePeopleButton && (
        <Authorization permissions={['PAYROLLINSTANCEEMPLOYEE_CREATE']}>
          <Option
            title={availableOptions.addMorePeopleButton}
            onClick={() => dispatch(destroy('payrollInstanceAttachEmployees'))}
            pathSuffix='add-employees'
            {...props}
          />
        </Authorization>
      )}

      {availableOptions.removePeopleButton && (
        <Authorization permissions={['PAYROLLINSTANCEEMPLOYEE_DELETE']}>
          <Option
            title={availableOptions.removePeopleButton}
            onClick={() => dispatch(destroy('payrollInstanceDetachEmployees'))}
            pathSuffix='remove-employees'
            {...props}
          />
        </Authorization>
      )}

      {availableOptions.addPayAndTaxesElementsButton && shouldShowPayAndTaxesButton && (
        <Authorization permissions={['PAYROLLINSTANCECOUNTRYTERM_CREATE']}>
          <Option title={availableOptions.addPayAndTaxesElementsButton} pathSuffix='add-pay-and-taxes-elements' {...props} />
        </Authorization>
      )}

      {availableOptions.removeVariableElementsButton && (
        <Authorization permissions={['PAYROLLINSTANCECOUNTRYTERM_CREATE']}>
          <Option title={availableOptions.removeVariableElementsButton} pathSuffix='remove-variable-elements' {...props} />
        </Authorization>
      )}
    </div>
  )
}

Options.propTypes = {
  payrollInstanceId: PropTypes.number.isRequired,
  locationPath: PropTypes.string.isRequired,
  availableOptions: PropTypes.object,
}

Option.propTypes = {
  locationPath: PropTypes.string.isRequired,
  pathSuffix: PropTypes.string.isRequired,
  payrollInstanceId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default Options
