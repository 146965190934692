import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PayslipTable from 'components/table/PayslipTable'
import { useParams } from 'react-router-dom'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import FilterButton from 'components/buttons/FilterButton'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { useTranslation } from 'react-i18next'

const initialSort = [
  {
    id: 'firstname',
    desc: false,
  },
  {
    id: 'surname',
    desc: false,
  },
]

const KeyPeopleTable = ({ data, headings, pagination, onFilter, onCreate, isFetching }) => {
  const [showFilters, setShowFilters] = useState(false)
  const params = useParams()
  const { t } = useTranslation()

  const onSortOrPageChange = ({ pageSize, pageIndex, filters, sortBy }) => {
    const isChangedFilter = pageIndex === pagination?.currentPage
    const page = isChangedFilter ? 0 : pageIndex

    if (!isFetching) {
      let normalizedFilters = {}
      filters.forEach((filter) => {
        normalizedFilters[filter.id] = filter
      })

      onFilter({
        page,
        limit: pageSize || pagination?.limit,
        filters: normalizedFilters,
        companyId: params.companyId,
        sort: isEmpty(sortBy) ? initialSort : sortBy,
      })
    }
  }

  const options = {
    noDataMessage: t('Global.text.no_matching_records_found'),
    pageSize: pagination?.limit || 25,
    sizePerPageList: [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ],
    initialPageSize: 25,
    pageIndex: 0,
    showPageSizeSelector: true,
    pageCount: pagination?.totalPages,
    onPageChange: onSortOrPageChange,
    onSort: onSortOrPageChange,
  }
  return (
    <>
      <SectionHeading text={t('Global.text.key_people')}>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <div className='u-float--right'>
            <Authorization permissions={['KEYPEOPLE_CREATE']}>
              <button onClick={() => onCreate()} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='keyperson-create'>
                <span className='icon icon--plus' />
              </button>
            </Authorization>
            <FilterButton
              className='c-btn c-btn--small c-btn--curious u-padding-left u-margin-left-tiny float-right u-padding-right'
              onClick={() => setShowFilters(!showFilters)}
            />
          </div>
        </div>
      </SectionHeading>
      <PayslipTable
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        wrapperClassName='u-1/1 react-bs-table--overflow-auto'
        options={options}
        data={data}
        headings={headings}
        showFilters={showFilters}
        pagination
        remote
        remoteSorting
        dynamicValues
        showNoDataMessage={false}
      />
    </>
  )
}

KeyPeopleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headings: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}
export default KeyPeopleTable
