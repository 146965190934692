import React from 'react'
import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { uploadArchive } from 'redux/actions/payfiles'
import PayrollInstanceList from '../components/PayrollInstanceList'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'
import { isCot } from 'redux/selectors/auth'
import { createFilter } from 'utils/redux/filter'
import { getFilteredPayrollInstances } from 'redux/selectors/payrollInstance'
import { fetchPayrollInstances } from 'redux/actions/payrollInstances'
import { getPayrollsByCompany, getPayrollsByCompanyWithActiveOrLockedPayrollInstances } from 'redux/selectors/payroll'
import { getCountriesByCompanies } from 'redux/selectors/country'
import Loader from 'components/Loader'
import { getFuturePayrunsReportToFilterDate } from 'utils/calendarYearDates'
import { sortByLabel } from 'utils/strings'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { createNewExportArchiveDownloadJob } from 'redux/actions/pollingJob'
import { getNonCompletedPayFileExportArchiveJobs } from 'redux/selectors/pollingJobs'
import { startToastFileGeneration } from 'redux/actions/toasts'
import ToastLoadingViews from 'components/ToastLoadingViews'
import { toast } from 'react-toastify'
import i18n from 'i18next'

const hasInitialPayrunFilter = (payrunState) => [payrunStatesEnum.INACTIVE].includes(payrunState)

const buildFilterName = (props) => {
  return hasInitialPayrunFilter(props.payrunState)
    ? `${props.payrunState}&company=${props.match.params.companyId}&payroll=${props.payrollId}`
    : `${props.payrunState}&company=${props.match.params.companyId}`
}
// On the FE the filters have one name, while on the BE is another one.
// We can unify the names later, because our goal is to refactor only the COS Alerts,
// without refactoring the Client/Vendor alerts, these are using the same names too.
const filtersMapping = {
  type: 'payrollInstanceName',
  status: 'createdAt',
  payrollInstances: 'payroll',
  reportToDate: 'reportToDate',
}

const _createFilter = ({ page = 0, limit, filters, state, props } = {}) => {
  const { payrunState } = props
  const filterName = buildFilterName(props)
  // Payroll instances on the future payruns tab should show only up to 2024.
  // This is currently hardcoded here to show all instances for 2023.
  const futurePayrunsEndDate = props.payrunState === payrunStatesEnum.INACTIVE ? getFuturePayrunsReportToFilterDate : null

  const payrollId = hasInitialPayrunFilter ? props.payrollId : null

  limit = limit !== undefined ? limit : state.payrollInstances.filters[filterName].params.limit
  page = page !== undefined ? page : state.payrollInstances.filters[filterName].params.offset / limit
  filters = filters !== undefined ? filters : state.payrollInstances.filters[filterName] ? state.payrollInstances.filters[filterName].params : null

  const sort = [
    { name: 'country', order: 'asc' },
    { name: 'name', order: 'asc' },
    { name: 'fromDate', order: 'desc' },
  ]

  const sortFuturePayruns = [
    { name: 'country', order: 'asc' },
    { name: 'name', order: 'asc' },
    { name: 'fromDate', order: 'asc' },
  ]
  return createFilter(
    {
      payroll: payrollId,
      ...createFilterByPayrunStateAndCompany(props),
      ...filters,
      sort: payrunState !== payrunStatesEnum.INACTIVE ? sort : sortFuturePayruns,
      reportToDate: futurePayrunsEndDate,
      offset: page * limit,
      limit,
    },
    filterName
  )
}

const mapStateToProps = (state, props) => {
  const isActive = props.payrunState !== 'previous'
  const filter = props.filters.payrollInstances
  let filterName = buildFilterName(props)
  const isFetchedAtLeastOnce = state.payrollInstances.filters[filterName] && state.payrollInstances.filters[filterName].ids

  if (!isFetchedAtLeastOnce) return { isInitialFetching: true }
  let companyId = props.match.params.companyId

  const {
    totalCount,
    params: { offset, limit },
  } = state.payrollInstances.filters[filter.name]

  const filteredPayrollInstances = getFilteredPayrollInstances(state, {
    filter: filter.name,
  })

  let payrolls =
    props.payrunState === 'previous' ? getPayrollsByCompany(state, { companyId }) : getPayrollsByCompanyWithActiveOrLockedPayrollInstances(state, { companyId })

  const payrollsArrayFilterData = payrolls.map((p) => {
    return {
      value: p.id,
      label: p.name,
    }
  })

  const allCompaniesByPayrolls = [...new Set(payrolls.map((p) => p.company))]

  const countriesArrayFilterData = getCountriesByCompanies(state, {
    companiesIds: allCompaniesByPayrolls,
  }).map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })
  const inProgressPollingJobs = getNonCompletedPayFileExportArchiveJobs(state)

  return {
    isActive,
    payrollInstances: filteredPayrollInstances,
    payrolls: payrollsArrayFilterData,
    countries: countriesArrayFilterData.sort(sortByLabel),
    disabledPayFileExportButton: (id) => inProgressPollingJobs.find((job) => job.entityId === parseInt(id, 10)),
    showImportColumn: isCot(state) && isActive,
    filter: filter,
    isFetching: state.payrollInstances.filters[filterName].isFetching,
    pagination: {
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit,
      limit,
      totalCount: totalCount,
    },
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    uploadFile: (id, file) => {
      dispatch(uploadArchive(id, file, { forceVendorTenantAPI: true }))
        .then(() => {
          dispatch(
            showMessage({
              body: i18n.t('Global:Global.message.file_uploaded'),
            })
          )
        })
        .catch((error) =>
          dispatch(
            showMessage({
              body: errorToString(error),
            })
          )
        )
    },
    downloadFile: (id) => {
      dispatch(createNewExportArchiveDownloadJob(id, { forceVendorTenantAPI: true })).then((job) => {
        dispatch(startToastFileGeneration(job.id))
        toast.loading(<ToastLoadingViews job={job} />, { toastId: job.id, autoClose: false, closeOnClick: false })
      })
    },
    onFilter: ({ page = 0, limit, filters }) =>
      dispatch((dispatch, getState) => {
        dispatch(
          fetchPayrollInstances({
            forceVendorTenantAPI: true,
            filter: _createFilter({
              page,
              limit,
              // Normalize query filter parameters (their names must match the BE API)
              ...(filters && {
                filters: Object.keys(filters).reduce((normalized, filterName) => {
                  // Set the correct filter name, expected by the BE API
                  const name = filtersMapping[filterName] || filterName

                  // Extract the filter's value
                  normalized[name] = filters[filterName].value
                  return normalized
                }, {}),
              }),
              state: getState(),
              props,
            }),
            disableObsoleteFlow: true,
          })
        )
      }),
    dispatch,
  }
}

const createFilterByPayrunStateAndCompany = (props) => ({
  payrunState: props.payrunState,
  company: props.match.params.companyId,
})

const ConditionalLoader = (Component, conditionalProp) => (props) => (props[conditionalProp] ? <Loader /> : <Component {...props} />)

const Component = connect(mapStateToProps, mapDispatchToProps)(ConditionalLoader(PayrollInstanceList, 'isInitialFetching'))

export default Fetcher(
  Component,
  [
    'companies',
    {
      name: 'payrolls',
      params: [
        {
          forceVendorTenantAPI: true,
          _computed: { filter: (state, props) => createFilter({ company: props.match.params.companyId }) },
        },
      ],
    },
    {
      name: 'countries',
      params: [
        {
          forceVendorTenantAPI: true,
        },
      ],
    },
    {
      name: 'payrollInstances',
      isForceFetching: true,
      params: [
        {
          forceVendorTenantAPI: true,
          _computed: {
            filter: (state, props) => {
              let filterName = buildFilterName(props)
              const isFetchedAtLeastOnce = state.payrollInstances.filters[filterName] && !!state.payrollInstances.filters[filterName].ids
              // If payrollInstances are already fetched (for example in the case the payrollInstances are invalidated),
              // we don't pass any filter params and we expect/delegate `_createFilter` to refetch the payrollInstances
              // using the filter parameters already cached in the Store/state.
              if (isFetchedAtLeastOnce) return _createFilter({ state, props })

              // On initial loading (payrollInstances are not fetched at least once),
              // then we predefine the filter parameters
              return _createFilter({
                page: 0,
                limit: props.payrunState === payrunStatesEnum.INACTIVE ? 10 : 20,
                filters: {},
                state,
                props,
              })
            },
          },
          disableObsoleteFlow: true,
        },
      ],
    },
  ],
  { renderWhileFetching: true, showLoader: false }
)
