import loadable from '@loadable/component'
import PersonalRoute from './routes/Personal'
import JobOrgRoute from './routes/JobOrg'
import PayTaxesRoute from './routes/PayTaxes'
import PayrollUpdatesRoute from './routes/PayrollUpdates'
import TimeAttendanceRoute from './routes/TimeAttendance'
import PayslipsRoute from './routes/Payslips'
import DocumentsRoute from './routes/Documents'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

const EmployeeRouteLoadable = loadable(() => import('./containers/InnerWrapperContainer'))

export default (store) => ({
  path: ':employeeId',
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Employee, EmployeeRouteLoadable) },
  wrappedComponent: CoreLayout,
  authProtected: true,
  tabbedLayout: true,
  childRoutes: [
    PersonalRoute(store),
    JobOrgRoute(store),
    PayTaxesRoute(store),
    TimeAttendanceRoute(store),
    PayrollUpdatesRoute(store),
    PayslipsRoute(store),
    DocumentsRoute(store),
  ],
})
