import React from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { ConfirmButton, RejectButton } from 'components/buttons/'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const RemoveService = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  if (props.isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div>
      <p>
        <Trans
          i18nKey='Vendor.text.are_you_want_remove_service'
          ns={i18nNameSpaces.Vendor}
          values={{
            serviceName: props.serviceName,
          }}
          components={[<span className='u-weight--medium' />]}
        />
      </p>
      <Form {...props}>
        <ConfirmButton type='submit' />
        <RejectButton onClick={props.onReject} />
      </Form>
    </div>
  )
}

RemoveService.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  // What to happen on rejected (confirmation) deletion
  onReject: PropTypes.func.isRequired,
}

export default RemoveService
