import React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import PropTypes from 'prop-types'

import AlertsDashboardContainer from '../containers/AlertsDashboardContainer'
import CalendarActionDashboardContainer from '../containers/CalendarActionDashboardContainer'
import OverdueActionsDashboardContainer from '../containers/OverdueActionsDashboardContainer'
import PayrollDashboardContainer from '../containers/PayrollDashboardContainer'
import RecentReportsDashboardContainer from '../containers/RecentReportsDashboardContainer'

import { i18nNameSpaces } from 'i18n/types'

const ResponsiveGridLayout = WidthProvider(Responsive)

const EmptyItem = () => {
  const { t } = useTranslation([i18nNameSpaces.Dashboard])
  return <div>{t('Dashboard.text.no_item_provided')}</div>
}

const calendarDateValues = {
  fromDate: moment().startOf('week').add(1, 'days'),
  toDate: moment().endOf('week').add(1, 'days'),
}

const actionDateValues = {
  fromDate: moment().startOf('year'),
  view: 'year',
  toDate: moment().endOf('day'),
}

const DashboardItem = ({ item, ...rest }) => {
  let dashboardItem = <EmptyItem />
  switch (item.i) {
  case 'alerts':
    dashboardItem = <AlertsDashboardContainer {...rest} />
    break
  case 'recentReports':
    dashboardItem = <RecentReportsDashboardContainer {...rest} />
    break
  case 'payroll':
    dashboardItem = <PayrollDashboardContainer payrunState='active' {...rest} />
    break
  case 'calendar':
    dashboardItem = <CalendarActionDashboardContainer filtersValues={calendarDateValues} {...rest} />
    break
  case 'overdueActions':
    dashboardItem = <OverdueActionsDashboardContainer filtersValues={actionDateValues} {...rest} />
    break
  default:
    dashboardItem = <EmptyItem />
    break
  }
  return dashboardItem
}

export const Dashboard = ({ dashboardURL, dashboardItems, onLayoutChange, onSaveLayout, layout, isCot, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Dashboard])

  const styleSm = {}
  if (dashboardItems.length < 1) {
    return <div data-testid='no-dashboard-setup'>{t('Dashboard.general.dashboardUnavailable')}</div>
  }

  return (
    <div>
      {
        <div className='text-right u-margin-left c-fab-button'>
          <div onClick={() => onSaveLayout(layout)} className='c-btn c-btn--small u-padding-tiny c-btn--curious' title={t('Dashboard.button.saveYourLayout')}>
            <span className='icon icon--save'>{t('Global:Global.button.save')}</span>
          </div>
        </div>
      }
      <ResponsiveGridLayout
        className='layout'
        layouts={{ lg: dashboardItems }}
        onLayoutChange={onLayoutChange}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 4, sm: 4, xs: 4, xxs: 2 }}
      >
        {dashboardItems.map((item) => {
          return (
            <div key={item.i} style={styleSm} className='u-bg--white u-border-radius--xsm border-1-valhalla rounded'>
              <div style={{ height: '100%', overflowY: 'auto' }}>
                <DashboardItem item={item} key={item.i} {...rest} />
              </div>
            </div>
          )
        })}
      </ResponsiveGridLayout>
    </div>
  )
}

Dashboard.propTypes = {
  dashboardURL: PropTypes.bool,
  dashboardItems: PropTypes.array,
  onLayoutChange: PropTypes.func,
  onSaveLayout: PropTypes.func,
  layout: PropTypes.array,
  isCot: PropTypes.bool,
}

export default Dashboard
