import i18n from 'i18next'

export const getShareSftpHeadings = () => [
  {
    accessor: 'location',
    Header: i18n.t('BusinessComponents:BusinessComponent.share_table_headings.location'),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    accessor: 'share',
    Header: i18n.t('BusinessComponents:BusinessComponent.share_table_headings.share'),
    disableFilters: true,
    disableSortBy: true,
    setWidth: '70px',
    classNames: 'text-right',
    columnClassName: 'u-cursor--pointer text-right',
  },
]
