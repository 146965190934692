import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hasAccess } from 'redux/selectors/auth'
import { useTranslation } from 'react-i18next'

/**
 * Mechanisms for protecting UI parts, according to user's permissions.
 *
 * Please refer to project WIKI documentation for further details:
 * @link - https://gitlab.com/dev-labs-bg/payslip-frontend/wikis/Authorization
 */

const noMsg = null

/**
 * Container component, that allows child props rendering,
 * only if the user has the needed permissions
 *
 * @example - Usage:
 *
 * ```
 *   import { Authorization } from 'containers/Authorization'
 *
 *   // It will render child props `<div>Protected area</div>`
 *   // only if currently logged in user has `COMPANIES_READ` permission
 *   <Authorization permissions={['COMPANIES_READ']} />
 *    <div>Protected area</div>
 *   </Authorization>
 * ```
 */
const Auth = ({ hasAccess, showMsg, children }) => {
  const { t } = useTranslation()
  if (!hasAccess && !showMsg) return noMsg
  if (!hasAccess) return <span>{t('Global.error.not_access_rights')}</span>
  return children
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  // Required user's permissions,
  // that are needed to render the protected child props
  permissions: PropTypes.array.isRequired,
  showMsg: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
  hasAccess: hasAccess(state)(props.permissions),
})

export const Authorization = connect(mapStateToProps)(Auth)
