import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import { InactiveUser } from 'components/ProcessTree'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ShareActionOwners = ({ processOwner, actionOwners, initialValues, isOwner, sharingActionText = 'Share' }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div data-testid='actionOwners-tabBody'>
      <PayslipTable
        data={processOwner.map((user) => ({
          ...user,
          fullname: user.hasCredentials ? user.fullname : <InactiveUser />,
          share: user.hasCredentials ? (
            <Field
              type='checkbox'
              labelClassName='u-hidden'
              component={CheckboxField}
              className='control__indicator__input'
              formGroupClassName='u-margin-none'
              name={`${user.extraData.tenant}-${user.extraData.id}`}
              data-testid={`share-checkbox-${user.extraData.tenant}-${user.extraData.id}`}
              indicatorClassName='control__indicator--center'
              disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
            />
          ) : null,
        }))}
        headings={[
          {
            accessor: 'fullname',
            Header: t('BusinessComponent.text.process_owner'),
            disableSortBy: true,
          },
          {
            accessor: 'share',
            Header: t('Global:Global.text.share'),
            setWidth: '100px',
            classNames: 'text-center',
            columnClassName: 'u-text--center',
            disableFilters: true,
            disableSortBy: true,
          },
        ]}
        wrapperClassName='u-1/1 u-margin-bottom'
        options={{
          noDataText: t('BusinessComponent.table.no_process_owner_found'),
        }}
        trClassName='react-bs-table__cell--whitespace--normal'
      />
      <PayslipTable
        data={actionOwners.map((user) => ({
          ...user,
          fullname: user.hasCredentials ? user.fullname : <InactiveUser />,
          share: user.hasCredentials ? (
            <Field
              type='checkbox'
              labelClassName='u-hidden'
              component={CheckboxField}
              className='control__indicator__input'
              data-testid={`share-checkbox-${user.extraData.tenant}-${user.extraData.id}`}
              formGroupClassName='u-margin-none'
              name={`${user.extraData.tenant}-${user.extraData.id}`}
              disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
              indicatorClassName='control__indicator--center'
            />
          ) : null,
        }))}
        headings={[
          {
            accessor: 'fullname',
            Header: t('BusinessComponent.table.action_owner'),
            disableSortBy: true,
          },
          {
            accessor: 'share',
            Header: t('Global:Global.text.share'),
            setWidth: '100px',
            classNames: 'text-center',
            columnClassName: 'u-text--center',
            disableFilters: true,
            disableSortBy: true,
          },
        ]}
        wrapperClassName='u-1/1 u-margin-bottom'
        options={{
          noDataText: t('BusinessComponent.table.no_action_owners_found'),
        }}
        trClassName='react-bs-table__cell--whitespace--normal'
      />
    </div>
  )
}

ShareActionOwners.propTypes = {
  actionOwners: PropTypes.array,
  processOwner: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string,
}

export default ShareActionOwners
