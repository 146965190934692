/* eslint-disable no-unused-expressions */
import React from 'react'
import moment from 'moment'
import i18n from 'i18next'

import EmployeeCustomFieldComponent from 'routes/Employees/routes/Employee/components/EmployeeCustomFieldComponent'
import { trimString } from './strings'
import { getUnpositionedFields, positionExtraFields, positionUnpositionedFields } from './extraFieldsHelpers'
import fieldComponentsEnum from './enums/fieldComponentsEnum'
import fieldSubCategoriesEnum from './enums/employees/fieldSubCategoriesEnum'
import pageUIStatesEnum from './enums/pageUIStatesEnums'
import { getLabelFromOptions } from './selectOptions'
import { ACCEPTED_FORMAT_VALUES } from 'schemas/employeeFieldsSchema'
import { dateFormatEnums } from './enums/dateFormatEnums'
import { formatDate } from './date'

const getEmployeeTypeOfContractOptions = (country) => {
  let countries = ['New Zealand', 'Denmark', 'Netherlands', 'Sweden', 'Norway', 'Finland']

  const defaultTypeOfContractOptions = [
    { value: 'Permanent', label: 'Permanent' },
    { value: 'Temporary', label: 'Temporary' },
    { value: 'Regular', label: 'Regular' },
    { value: 'Fixed Term Contract', label: 'Fixed Term Contract' },
    { value: 'Fixed Term Employee', label: 'Fixed Term Employee' },
    { value: 'Freelancer', label: 'Freelancer' },
    { value: 'Intern', label: 'Intern' },
    { value: 'Traineeship', label: 'Traineeship' },
    { value: 'Working Student', label: 'Working Student' },
    { value: 'Apprentice', label: 'Apprentice' },
    { value: 'Casual', label: 'Casual' },
    { value: 'Retired / Pensioner', label: 'Retired / Pensioner' },
    { value: 'Other', label: 'Other' },
    { value: 'Part Time Contract', label: 'Part Time Contract' },
    { value: 'Internship', label: 'Internship' },
    { value: 'B2B (Service Contract)', label: 'B2B (Service Contract)' },
    { value: 'Service Contract', label: 'Service Contract' },
    { value: '5 Days Contract', label: '5 Days Contract' },
    { value: '218 Days Contract', label: '218 Days Contract' },
    { value: 'Zero Hour Contracts', label: 'Zero Hour Contracts' },
    { value: 'Indefinite Duration', label: 'Indefinite Duration' },
    { value: 'Unlimited', label: 'Unlimited' },
    { value: 'Flexi-job', label: 'Flexi-job' },
    { value: 'Fixed Discontinuous', label: 'Fixed Discontinuous' },
  ]

  if (country === 'Germany') {
    return [
      ...defaultTypeOfContractOptions,
      { value: 'Job-Creating Program', label: 'Job-Creating Program' },
      { value: 'Occupational Training', label: 'Occupational Training' },
      { value: 'Tenured', label: 'Tenured' },
      { value: 'Tenure Eligible', label: 'Tenure Eligible' },
      { value: 'Non Tenured', label: 'Non Tenured' },
      { value: 'Temporary Help', label: 'Temporary Help' },
      { value: 'Premium Wage', label: 'Premium Wage' },
      { value: 'Other Contractual Relationship', label: 'Other Contractual Relationship' },
      { value: 'Tariff Salary', label: 'Tariff Salary' },
      { value: 'Tariff Salary JCS', label: 'Tariff Salary JCS' },
      { value: 'Tariff Apprenticeship Remuneration', label: 'Tariff Apprenticeship Remuneration' },
      { value: 'Tariff Monthly Payment', label: 'Tariff Monthly Payment' },
      { value: 'Tariff Hourly Payment', label: 'Tariff Hourly Payment' },
      { value: 'Company Remuneration', label: 'Company Remuneration' },
      { value: 'Early Retirement', label: 'Early Retirement' },
      { value: 'Non-Tariff Hourly Payment', label: 'Non-Tariff Hourly Payment' },
      { value: 'Non-Tariff Salary', label: 'Non-Tariff Salary' },
      { value: 'Heritage', label: 'Heritage' },
      { value: 'Fee/Licences', label: 'Fee/Licences' },
      { value: 'Payment by Result', label: 'Payment by Result' },
      { value: 'Pension (minimum contribution base)', label: 'Pension (minimum contribution base)' },
      { value: 'Pension (general contribution determination)', label: 'Pension (general contribution determination)' },
      { value: 'Intern Remuneration', label: 'Intern Remuneration' },
      { value: 'OSTE Bachelor & Master', label: 'OSTE Bachelor & Master' },
      { value: 'OSTE General Manager and Higher', label: 'OSTE General Manager and Higher' },
    ]
  }

  if (countries.includes(country)) {
    return [
      ...defaultTypeOfContractOptions,
      { value: 'Job-Creating Program', label: 'Job-Creating Program' },
      { value: 'Occupational Training', label: 'Occupational Training' },
      { value: 'Tenured', label: 'Tenured' },
      { value: 'Tenure Eligible', label: 'Tenure Eligible' },
      { value: 'Non Tenured', label: 'Non Tenured' },
    ]
  }

  return defaultTypeOfContractOptions
}

/**
 * Get employee fields by filtering all employee fields with the relevant section (ie. 'personal'), and
 * subSection (ie. 'personalDetails', contactDetails..)
 * @param {Array} fields - all employee fields
 * @param {Object} employee - all employee fields
 * @param {String} section - section
 * @param {String} subSection - subsection
 * @param {Boolean} showUniqueContractorIDField
 * @param {Array} businessUnits
 * @param {Array} costCenters
 * @param {Array} departments
 * @param {Array} subsidiaries
 * @param {Array} officeLocations
 * @param {Array} subsidiaries
 * @param {Array} projects
 * @param {Array} countries
 * @param {Array} currencies
 * @param {Array} companies
 * @param {Boolean} isEdit
 * @param {Boolean} showVatRegistrationNumber
 * @param {Boolean} isContractorCompany
 * @param {Boolean} isContractorAgency
 *
 * @example:
 *
 * ```
 * getFieldsBySection(fields, 'personal', 'taxDetails')
 * ```
 *
 * @return {Array}
 */
export const getFieldsBySection = ({
  fields,
  employee = null,
  section,
  subSection,
  showUniqueContractorIDField = false,
  showInvoicePaymentCurrencyArea = false,
  businessUnits,
  costCenters,
  departments,
  officeLocations,
  subsidiaries,
  projects,
  countries,
  currencies,
  companies,
  payrolls,
  paygroups,
  areChildEntitiesDisabled,
  isEdit = false,
  isCreate = false,
  isEmployeeOnly,
  isCotUser,
  showVatRegistrationNumber = employee?.isContractorAgency || employee?.isContractorCompany,
  isContractorCompany,
  isContractorAgency,
  isPaymentTypeHourly,
  resetUserPassword,
  bankAccountCountries,
  componentInclusion = null,
  externalFieldsInclusions = null,
  makeKeyPerson,
  extraFields = {},
}) => {
  const category = fields.find((cat) => cat.category === section)
  if (!category) {
    return []
  }

  let extraFieldsResp = []
  // Here the filter method should always return array with one object,
  // for that reason we are taking the first item in the array
  let allFields = category.fields.filter((field) => field.subCategory === subSection)

  if (extraFields.properties) {
    extraFieldsResp = positionExtraFields(extraFieldsResp, extraFields, section, subSection)
  }
  const isPersonalDetailsSubSection = subSection === 'personalDetails'
  const isPersonalBiographicalSubSection = subSection === 'biographicalDetails'
  const employeeCountry = employee.companyCountry?.name || employee.companyRef?.countryRef?.name
  const fieldsWithValueTypeNumber = ['monthlyHours', 'hourlyRate']
  const companyUnits = ['businessUnit', 'costCenter', 'department', 'officeLocation', 'subsidiary', 'project']
  const buttons = ['button', 'authorizedButton']

  const dateFields = ['startDate', 'originalHireDate', 'confirmationDate', 'dateIssue', 'endDate', 'expiryDate', 'dateOfBirth']
  let fieldWork
  if (extraFieldsResp.length) {
    fieldWork = getUnpositionedFields(extraFieldsResp, allFields)
    allFields = positionUnpositionedFields(fieldWork.unpositionedFields, fieldWork.allFields)
  }

  return allFields
    .filter((item) => {
      if (componentInclusion) {
        return externalFieldsInclusions.includes(item.field)
      }
      // Any Fields that are listed as objects, we don't want
      // to display in the normal view mode
      if (item.component === fieldComponentsEnum.OBJECT && !componentInclusion) {
        return false
      }

      // This check has to be this high on the list because a field can be both country specific and have another condition
      if (item.isCountrySpecific) {
        if (item.countries?.includes(employeeCountry) && item.field === 'invoicePaymentCurrency') {
          return showInvoicePaymentCurrencyArea
        }
        return item.countries?.includes(employeeCountry)
      }

      if (item.field === 'uniqueContractorId') {
        return showUniqueContractorIDField
      }

      if (item.field === 'invoicePaymentCurrency') {
        return showInvoicePaymentCurrencyArea
      }

      if (item.field === 'hourlyRate') {
        return isPaymentTypeHourly
      }

      if (item.field === 'contractorCompanyName') {
        return isContractorCompany
      }

      if (item.field === 'agencyCompanyName') {
        return isContractorAgency
      }

      if (item.field === 'vatRegistrationNumber') {
        return showVatRegistrationNumber
      }

      if (item.field === 'resetUserPassword') {
        return employee.hasCredentials && (isEdit || isCreate)
      }

      if (item.field === 'makeKeyUser') {
        return isEmployeeOnly && (isEdit || isCreate)
      }

      if (item.field === 'username') {
        return employee.hasCredentials
      }

      if (buttons.includes(item.component)) {
        return false
      }

      if (item.hideOnEdit && isEdit) {
        return false
      }

      if (item.field === 'isSSOEnabled' && !item.isVisible) {
        return false
      }

      if (item.field === 'mode2fa' && !item.isVisible) {
        return false
      }

      if (item.field === 'hasMoved' && !item.isVisible) {
        return false
      }

      if (item.isCountrySpecific) {
        return item.countries?.includes(employeeCountry)
      }

      return true
    })
    .map((item) => {
      if (isPersonalBiographicalSubSection) {
        if (item.field === 'citizenship') {
          item.options = employeeCountry === 'Singapore' ? item.allOptions : item.options
        }
      }

      if (item.schemaEnabled) {
        item.value = ''
        if (employee.extraFields[item.fieldTypeName]) {
          if (item.format === ACCEPTED_FORMAT_VALUES.DATE) {
            item.value = formatDate(employee.extraFields[item.fieldTypeName], dateFormatEnums.DefaultDateFormatWithoutTime)
            return item
          }
          item.value = employee.extraFields[item.fieldTypeName]
          return item
        }
      }
      // We are adding `value` property to the existing object because we need
      // to populate a value for the field if there is any
      if (item.isCountrySpecific && item.countries && item.countries.includes(employeeCountry)) {
        // Check if the field is a date field
        const newItem = {
          ...item,
          value: employee[item.field] || ' ',
        }

        if (dateFields.includes(item.field)) {
          newItem.value = employee[`${item.field}`] ? moment(employee[`${item.field}`]?.date).format('DD/MM/YYYY') : ' '
        }

        if (newItem.field === 'invoicePaymentCurrency') {
          newItem.options = currencies
          return newItem
        }

        return newItem
      }

      // If it is `date` field and it is not country specific
      if (dateFields.includes(item.field) && !item.isCountrySpecific) {
        item.value = employee[`${item.field}`] ? moment(employee[`${item.field}`]?.date).format('DD/MM/YYYY') : ' '
        return item
      }

      if (item.field === 'countries') {
        item.value = employee.companyRef?.countryRef?.name
        item.options = countries
        return item
      }

      if (item.field === 'company') {
        item.value = employee.companyRef.name
        item.options = companies
        return item
      }

      if (item.field === 'payrollCountry') {
        item.options = countries
        return item
      }

      if (item.field === 'bankCountry') {
        item.options = bankAccountCountries
        return item
      }

      if (item.field === 'currency') {
        item.options = currencies
        return item
      }

      if (item.field === 'paymentCurrency') {
        item.value = employee.paymentCurrency?.id
        item.options = currencies
        item.canModifyFields = { [pageUIStatesEnum.EDIT]: !areChildEntitiesDisabled }
        return item
      }

      if (item.field === 'payroll') {
        item.value = employee.payrolls?.find((p) => p)?.id || employee.payrollName
        item.options = payrolls
        item.canModifyFields = { [pageUIStatesEnum.EDIT]: !areChildEntitiesDisabled }
        return item
      }

      if (item.field === 'paygroup') {
        item.value = employee.paygroups?.find((p) => p)?.id || null
        item.options = paygroups
        item.canModifyFields = { [pageUIStatesEnum.EDIT]: !areChildEntitiesDisabled }
        return item
      }

      if (item.field === 'hasCredentials') {
        item.value = employee[item.field] === true ? i18n.t('Global.text.active') : i18n.t('Global.text.inactive')
        return item
      }

      if (item.field === 'mode2fa') {
        item.options = isCotUser ? item.allOptions : item.options
      }

      if (item.field === 'resetUserPassword') {
        item.onClick = resetUserPassword
        return item
      }

      if (item.field === 'makeKeyUser') {
        item.onClick = makeKeyPerson
        return item
      }

      if (item.field === 'isSSOEnabled') {
        item.value = employee[item.field] === true ? i18n.t('Global.text.enabled') : i18n.t('Global.text.disabled')
        return item
      }

      if (item.field === 'hasMoved') {
        item.value = employee[item.field] === true ? i18n.t('Global.button.yes') : i18n.t('Global.text.no')
        return item
      }

      // Here we are receiving number from the BE and convert it to string
      if (fieldsWithValueTypeNumber.includes(item.field)) {
        item.value = typeof employee[item.field] === 'number' ? employee[item.field].toString() : ' '
        return item
      }

      if (companyUnits.includes(item.field)) {
        switch (item.field) {
        case 'businessUnit':
          item.value = employee[item.field]?.name || ' '
          item.options = businessUnits
          break
        case 'costCenter':
          item.value = employee[item.field]?.name || ' '
          item.options = costCenters
          break
        case 'department':
          item.value = employee[item.field]?.name || ' '
          item.options = departments
          break
        case 'officeLocation':
          item.value = employee[item.field]?.name || ' '
          item.options = officeLocations
          break
        case 'subsidiary':
          item.value = employee[item.field]?.name || ' '
          item.options = subsidiaries
          break
        case 'project':
          item.value = employee[item.field]?.name || ' '
          item.options = projects
          break
        default:
          break
        }
        return item
      }

      if (item.field === 'typeOfContract') {
        const defaultContractOptions = getEmployeeTypeOfContractOptions(employeeCountry)
        item.value = employee[item.field] || ' '
        item.options = defaultContractOptions

        return item
      }

      if (isPersonalDetailsSubSection || isPersonalBiographicalSubSection) {
        if (!item.isCountrySpecific) {
          item.value = item.field === 'dateOfBirth' ? moment(employee.dateOfBirth?.date).format('DD/MM/YYYY') || ' ' : employee[item.field] || ' '
          return item
        }
      }

      if (!item.isCountrySpecific && !item.schemaEnabled) {
        item.value = typeof employee[item.field] !== 'string' ? ' ' : employee[item.field] || ' '
        return item
      }

      return item
    })
}

export const createEmployeeFields = ({
  fields,
  section,
  subSection,
  secondarySubSection,
  showUniqueContractorIDField = false,
  showInvoicePaymentCurrencyArea = false,
  businessUnits,
  costCenters,
  departments,
  officeLocations,
  subsidiaries,
  projects,
  countries,
  currencies,
  includePayroll,
  includePaygroup,
  country: selectedCountry,
  companies,
  isEdit,
  isCotUser,
  showVatRegistrationNumber,
  isContractorCompany,
  isContractorAgency,
  isPaymentTypeHourly,
  bankAccountCountries,
  extraFields = {},
}) => {
  const category = fields.find((cat) => cat.category === section)

  if (!category) {
    return []
  }

  let extraFieldsResp = []
  // Here the filter method should always return array with one object,
  // for that reason we are taking the first item in the array
  let allFields = category.fields.filter((field) => {
    if (secondarySubSection) {
      return field.subCategory === subSection || field.subCategory === secondarySubSection
    }
    return field.subCategory === subSection
  })

  if (extraFields.properties) {
    extraFieldsResp = positionExtraFields(extraFieldsResp, extraFields, section, subSection)
  }

  const isPersonalDetailsSubSection = subSection === fieldSubCategoriesEnum.PERSONAL_DETAILS
  const isPersonalBiographicalSubSection = subSection === fieldSubCategoriesEnum.BIOGRAPHICAL_DETAILS
  const companyUnits = ['businessUnit', 'costCenter', 'department', 'officeLocation', 'subsidiary', 'project']
  const buttons = ['button', 'authorizedButton']

  let fieldWork
  if (extraFieldsResp.length) {
    fieldWork = getUnpositionedFields(extraFieldsResp, allFields)
    allFields = positionUnpositionedFields(fieldWork.unpositionedFields, fieldWork.allFields)
  }

  // // Expose nested fields
  allFields.forEach((field, i) => {
    if (field.component === fieldComponentsEnum.OBJECT) {
      allFields.splice(i, 0, ...field.fields)
    }
  })
  return allFields
    .filter((item) => {
      if (item.isCountrySpecific) {
        if (item.countries?.includes(selectedCountry) && item.field === 'invoicePaymentCurrency') {
          return showInvoicePaymentCurrencyArea
        }
        return item.countries?.includes(selectedCountry)
      }

      if (item.field === 'uniqueContractorId') {
        return showUniqueContractorIDField
      }

      if (item.field === 'hourlyRate') {
        return isPaymentTypeHourly
      }

      if (item.field === 'payroll' && !includePayroll) {
        return false
      }

      if (item.field === 'paygroup' && !includePaygroup) {
        return false
      }

      if (item.field === 'contractorCompanyName') {
        return isContractorCompany
      }

      if (item.field === 'agencyCompanyName') {
        return isContractorAgency
      }

      if (item.field === 'vatRegistrationNumber') {
        return showVatRegistrationNumber
      }

      if (item.field === 'resetUserPassword') {
        return false
      }

      if (item.field === 'makeKeyUser') {
        return false
      }

      if (item.field === 'isSSOEnabled') {
        return false
      }

      if (item.field === 'mode2fa' && !item.isVisible) {
        return false
      }

      if (item.field === 'hasMoved' && !item.isVisible) {
        return false
      }

      if (item.field === 'username') {
        return true
      }

      if (buttons.includes(item.component)) {
        return false
      }

      if (item.component === fieldComponentsEnum.OBJECT) {
        return false
      }

      if (item.hideOnCreate) {
        // return false
      }

      return true
    })
    .map((item) => {
      if (item.field === 'countries') {
        item.options = countries
        return item
      }

      if (item.field === 'company') {
        item.options = companies
        return item
      }

      if (item.field === 'bankCountry') {
        item.options = bankAccountCountries
        return item
      }

      if (item.field === 'currency') {
        item.options = currencies
        return item
      }

      if (item.field === 'paymentCurrency') {
        item.options = currencies
        return item
      }

      if (item.field === 'invoicePaymentCurrency') {
        item.options = currencies
        return item
      }

      if (item.field === 'hasCredentials') {
        return item
      }

      if (item.field === 'mode2fa') {
        item.options = isCotUser ? item.allOptions : item.options
      }

      if (item.field === 'citizenship') {
        item.options = selectedCountry === 'Singapore' ? item.allOptions : item.options
        return item
      }

      if (item.field === 'payrollCountry') {
        item.options = countries
        return item
      }

      if (companyUnits.includes(item.field)) {
        switch (item.field) {
        case 'businessUnit':
          item.options = businessUnits
          break
        case 'costCenter':
          item.options = costCenters
          break
        case 'department':
          item.options = departments
          break
        case 'officeLocation':
          item.options = officeLocations
          break
        case 'subsidiary':
          item.options = subsidiaries
          break
        case 'project':
          item.options = projects
          break
        default:
          break
        }
        return item
      }

      if (item.field === 'typeOfContract') {
        const defaultContractOptions = getEmployeeTypeOfContractOptions(selectedCountry)
        item.options = defaultContractOptions
        return item
      }

      if (isPersonalDetailsSubSection || isPersonalBiographicalSubSection) {
        if (!item.isCountrySpecific) {
          return item
        }
      }

      if (item.isCountrySpecific && item.countries && item.countries.includes(selectedCountry)) {
        return item
      }

      return item
    })
}

/**
 * Get array with simple key value pairs, out of array full with objects with more items
 * @param {Array} arr - array with objects
 * @param {String} key -
 * @param {String} value -
 *
 * @example:
 * ```
 * arr = [
 *   {
 *     name: Amelia ,
 *     surname: Schulz,
 *     age: 22
 *   },
 *   {
 *     name: George,
 *     surname: Petrov,
 *     age: 24
 *   },
 * ]
 * extractKeyValuePairFromArray(fields, 'surname', 'age')
 * output: [
 *  { Schulz: 22 },
 *  { Petrov: 24 }
 * ]
 * ```
 *
 * @return {Array}
 */
export const extractKeyValuePairFromArray = (arr, key = 'name', value = 'value') =>
  arr.reduce((acc, curr) => {
    if (curr && curr[key]) {
      acc.push({
        [curr[key]]: curr[value],
      })
    }

    return acc
  }, [])

// Render employee fields for create and edit functionality
export const renderFields = (field, setCountry, mode) => {
  const specificField = field.component === 'button' || field.component === 'authorizedButton'
  const hasAccessLayers = mode && field.accessLayers && field.accessLayers[mode]
  if (hasAccessLayers) return <EmployeeCustomFieldComponent key={field['name']} field={field} accessingMode={mode} />
  return (
    <div key={field['name']} className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
      {specificField ? (
        <EmployeeCustomFieldComponent field={field} />
      ) : (
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <EmployeeCustomFieldComponent field={field} setCountry={setCountry} accessingMode={mode} />
        </div>
      )}
    </div>
  )
}

// Render employee fields for inline edit functionality
export const renderInlineEditFields = (fields, ownerId = {}) => {
  return fields.map((field) => ({
    title: field.name,
    value: field.value,
    metaData: { ...field, ownerId },
  }))
}

// Render table fields
export const renderTableFields = (fields) => {
  return fields.map((field) => ({
    name: (
      <div className='o-layout'>
        <div className='o-layout__item u-2/3 field-name u-padding-right-small u-text--overflow-wrap'>
          <span className='u-text--normal'>{field.name}</span>
        </div>
        <div
          className='o-layout__item u-1/3 field-value u-weight--regular
       u-padding-left-none u-text--overflow-wrap'
          data-testid={field?.field}
        >
          <span className='u-text--normal'>{field.value}</span>
        </div>
      </div>
    ),
  }))
}

// Get item name by id, from array of objects
export const getItemNameById = (items, id) => {
  let filtered = items.filter((item) => item.id === id)
  return filtered.length ? filtered[0].name : ''
}

/**
 * Get employee contractor type
 * @param {Object} employee - all employee fields
 * @param {Boolean} withNameAndValue - flag to check what type of object will be returned
 *
 * @example:
 *
 * ```
 * getContractorNameField({ employee, withNameAndValue})
 * ```
 *
 * @return {Array}
 */
export const getContractorNameField = ({ employee, withNameAndValue = false }) => {
  const isContractorCompany = employee.isContractorCompany
  const isContractorAgency = employee.isContractorAgency

  if (isContractorCompany) {
    return withNameAndValue
      ? { name: 'Contractors Company Name', value: employee.contractorCompanyName }
      : { 'Contractors Company Name': employee.contractorCompanyName }
  }

  if (isContractorAgency) {
    return withNameAndValue
      ? {
        name: 'Agency Company Name',
        value: employee.agencyCompanyName,
      }
      : { 'Agency Company Name': employee.agencyCompanyName }
  }

  return undefined
}

export const isSelectedRoleTypeRestrictedToBeGO = (roleType) => {
  const selectedRole = trimString(roleType)
  const rolesWithRestricteToBeGO = ['rolepayrolldataentry', 'rolefinanceadmin', 'roleaccounting']
  return rolesWithRestricteToBeGO.includes(selectedRole)
}

export const mapEmployeeFieldsForMobile = ({ fields, currencies, payrolls, paygroups }) =>
  fields.map((f) => {
    switch (f.field) {
    case 'payroll':
      f.value = getLabelFromOptions(payrolls, f.value)
      break
    case 'paygroup':
      f.value = getLabelFromOptions(paygroups, f.value)
      break
    case 'paymentCurrency':
      f.value = getLabelFromOptions(currencies, f.value)
      break
    case 'invoicePaymentCurrency':
      f.value = getLabelFromOptions(currencies, f.value)
      break
    default:
      break
    }
    return f
  })
