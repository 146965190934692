import React, { useRef, useState } from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import SubCategoriesListContainer from '../containers/SubCategoriesListContainer'
import SubCategoryCreateContainer from '../containers/SubCategoryCreateContainer'
import SubCategoriesEditContainer from '../containers/SubCategoriesEditContainer'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = () => {
  const [subCategoryId, setSubCategoryId] = useState(null)
  const editModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Term])

  return (
    <InnerWrapper>
      <SubCategoryCreateContainer />
      <SubCategoriesListContainer
        onEdit={(subCategoryId) => {
          setSubCategoryId(subCategoryId)
          editModalRef.current.showModal()
        }}
      />
      <Modal ref={editModalRef} className='c-modal' modalHeading={t('Term.text.edit_subcategory')}>
        {subCategoryId && <SubCategoriesEditContainer subCategoryId={subCategoryId} onEdit={() => editModalRef.current.hideModal()} />}
      </Modal>
    </InnerWrapper>
  )
}

export default RouteView
