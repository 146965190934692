import React, { useEffect, useRef, useState } from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import { getVendorListTableConfig } from 'routes/Vendors/components/vendorListTableConfig'
import { useDispatch, useSelector } from 'react-redux'
import { isCotAdmin } from 'redux/selectors/auth'
import { ConfigurationModal } from 'components/configuration/ConfigurationModal'
import { getVendorFieldsConfig } from 'routes/Vendors/components/vendorFieldsConfig'
import { getSystemAvailableLocales } from 'redux/actions/api'
import { updateVendor } from 'redux/actions/vendors'
import { isEmpty } from 'utils/fnkit/typeChecks'

const VendorsList = ({ vendors, selectTenantSubmit }) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])
  const dispatch = useDispatch()
  const systemAvailableLocales = useSelector((state) =>
    state.config.systemAvailableLocales?.map((lang) => ({
      label: lang.label,
      value: lang.id,
    }))
  )

  const showActions = useSelector(isCotAdmin)
  const vendorModalRef = useRef(null)
  const [selectedTenant, setSelectedTenant] = useState()

  const configurationModalLabels = {
    heading: t('Client:Client.text.details'),
  }

  const fetchSystemLocales = async () => {
    await dispatch(getSystemAvailableLocales())
  }

  useEffect(() => {
    fetchSystemLocales().catch(console.error)
  }, [dispatch])

  const renderData = (vendors) => {
    return vendors.map((vendor) => {
      return {
        id: vendor.id,
        name: vendor.name,
        url: vendor.url,
        shortUrl: vendor.shortUrl,
        locales: vendor.locales,
      }
    })
  }
  const options = {
    searchPosition: 'left',
    noDataText: t('Global:Global.text.no_matching_records_found'),
  }

  const handleUpdateAction = (vendor) => {
    const newSelection = { ...vendor, locales: vendor.locales?.reduce((acc, l) => [...acc, l.value], []) }
    setSelectedTenant(newSelection)
    vendorModalRef.current?.showModal()
  }
  const handleSubmit = (data) => {
    const { id, locales, ...vendor } = data
    vendor.locales = isEmpty(locales) ? ['en'] : locales
    dispatch(updateVendor(vendor, id))
  }

  return (
    <div>
      <SectionHeading text={t('Vendor.heading.vendors_list')} />
      <ConfigurationModal
        modalRef={vendorModalRef}
        formName={`configuration-modal-form`}
        labels={configurationModalLabels}
        fields={getVendorFieldsConfig({ systemAvailableLocales })}
        initialValues={selectedTenant}
        onSubmit={handleSubmit}
      />
      <PayslipTable
        data={renderData(vendors)}
        headings={getVendorListTableConfig({
          showActions,
          handleUpdateAction,
          selectTenantSubmit,
        })}
        wrapperClassName='u-1/1@tablet '
        trClassName='react-bs-table__cell--overflow-ellipsis'
        options={options}
        search
      />
    </div>
  )
}

VendorsList.propTypes = {
  vendors: PropTypes.array,
  selectTenantSubmit: PropTypes.func,
}

export default VendorsList
