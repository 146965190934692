import React from 'react'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const Legend = () => {
  const { t } = useTranslation([i18nNameSpaces.Toolbox])
  return (
    <div className='o-grid calendar-yearly u-margin-top u-margin-bottom'>
      <div className='d-flex'>
        <span className='calendar-yearly-legend-dot' />
        <span>{t('Toolbox.calendar.holidays')}</span>
      </div>
      <div className='d-flex u-margin-left-huge'>
        <span className='calendar-yearly-legend-dot--inputs' />
        <span>{t('Toolbox.calendar.inputs_cutoff')}</span>
      </div>
      <div className='d-flex u-margin-left-huge'>
        <span className='calendar-yearly-legend-dot--gtn' />
        <span>{t('Toolbox.calendar.gtn_cutoff')}</span>
      </div>
      <div className='d-flex u-margin-left-huge'>
        <span className='calendar-yearly-legend-dot--paydate' />
        <span>{t('Toolbox.calendar.pay_date')}</span>
      </div>
    </div>
  )
}

export default Legend
