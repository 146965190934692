import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const CostingTable = ({ ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  return (
    <PayslipTable
      data={props.rows.map((row) => {
        return {
          services: (
            <div>
              {row.name}
              <span
                onClick={() => props.onClick('removeService', props.countryId, row.id, row.name)}
                className='icon--ex u-margin-left-tiny u-margin-top-tiny'
                title={t('Vendor.text.remove_service')}
              />
            </div>
          ),
          serviceElement: <div />,
          serviceType: <div>{/* Type */}</div>,
          icpCost: <div>{/* 6 */}</div>,
          clientPrice: <div>{/* 10 */}</div>,
          margin: <div>{/* 4 */}</div>,
          minThreshold: <div>{/* 50 */}</div>,
          employeesThreshold: <div>{/* 1 to 10 */}</div>,
          currency: <div>{/* EUR */}</div>,
          actions: <div />,
        }
      })}
      headings={[
        {
          Header: <div className='o-grid o-grid--between'>{t('Global:Global.text.services')}</div>,
          accessor: 'services',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: (
            <div className='o-grid o-grid--between'>
              {t('Vendor.text.service_element')}
              {props.rows.length && !props.shouldHideServiceButton ? (
                <button
                  onClick={() => props.onClick('addServiceElement', props.countryId)}
                  className='c-btn c-btn--tiny u-bg--transparent u-text--tiny u-pading-none u-float--right'
                  title={t('Vendor.text.add_service_element')}
                >
                  <span className='icon icon--plus' />
                </button>
              ) : null}
            </div>
          ),
          accessor: 'serviceElement',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Vendor.text.service_cost_type'),
          accessor: 'serviceType',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Vendor.text.icp_cost'),
          accessor: 'icpCost',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Vendor.text.client_price'),
          accessor: 'clientPrice',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Vendor.text.margin'),
          accessor: 'margin',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Vendor.text.min_threshold'),
          accessor: 'minThreshold',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Vendor.text.employees_threshold'),
          accessor: 'employeesThreshold',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Global:Global.text.currency'),
          accessor: 'currency',
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t('Global:Global.button.actions'),
          accessor: 'actions',
          disableFilters: true,
          disableSortBy: true,
        },
      ]}
      wrapperClassName='u-1/1'
      trClassName='react-bs-table__cell--whitespace--normal'
    />
  )
}
export default CostingTable
