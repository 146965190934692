import React from 'react'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const InnerWrapper = ({ vendorId, tenantId, companyId, showDocuments, showExtraPayrunsTab, isVendorRestricted = true, ...props }) => {
  const path = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}/details`
  const { t } = useTranslation([i18nNameSpaces.Payrun])

  if (isVendorRestricted) {
    return (
      <InnerWrapperTabs
        tabs={[
          showDocuments && {
            name: t('Global:Global.aside.documents'),
            to: `${path}/payruns/documents`,
            'data-testid': 'documents-tab',
          },
        ]}
        removeWrapper
        {...props}
      />
    )
  }

  return (
    <InnerWrapperTabs
      tabs={[
        { name: t('Payrun.text.prePayrollSteps'), to: `${path}/payruns/active` },
        ...(showExtraPayrunsTab ? [{ name: t('Payrun.text.postPayrollSteps'), to: `${path}/payruns/extra` }] : []),
        {
          name: t('Payrun.text.closedPayruns'),
          to: `${path}/payruns/previous`,
          'data-testid': 'closedPayrollInstances-tab',
        },
        { name: t('Payrun.text.futurePayruns'), to: `${path}/payruns/inactive`, 'data-testid': 'futurePayruns-tab' },
        showDocuments && {
          name: t('Global:Global.aside.documents'),
          to: `${path}/payruns/documents`,
          'data-testid': 'documents-tab',
        },
      ]}
      removeWrapper
      {...props}
    />
  )
}

InnerWrapper.propTypes = {
  vendorId: PropTypes.string,
  tenantId: PropTypes.string,
  companyId: PropTypes.string,
  showDocuments: PropTypes.bool,
  showExtraPayrunsTab: PropTypes.bool,
  location: PropTypes.object,
  isVendorRestricted: PropTypes.bool,
}

export default InnerWrapper
