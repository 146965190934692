import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { getCompaniesRef } from 'redux/selectors/company'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import { isMultitenancyEnabled } from 'utils/config'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'

const Aside = (props) => {
  const { t } = useTranslation()
  const { history, asideMode, selectAside, fetchCompaniesIfNeeded } = props

  useEffect(() => {
    fetchCompaniesIfNeeded()
  }, [])

  return (
    <aside className='o-aside'>
      <Logo />
      {isMultitenancyEnabled && <TenantSelectorContainer history={history} />}
      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} aria-label={t('Global.aside.handleSelectAside')} />
      <MainNav
        asideMode={asideMode}
        items={[
          { title: t('Global.aside.home'), name: 'home', path: '/home', isHome: true },
          { title: t('Global.aside.company'), name: 'company', path: '/companies' },
          { title: t('Global.aside.people'), name: 'people', path: '/employees' },
          { title: t('Global.aside.payroll'), name: 'payroll', path: '/payruns/active' },
          {
            title: t('Global.aside.toolbox'),
            name: 'toolbox',
            path: '/toolbox',
            isLink: false,
            children: [
              { title: t('Global.aside.calendar'), name: 'calendar', path: '/toolbox/calendar', sub: true },
              { title: t('Global.aside.actions'), name: 'actions', path: '/toolbox/actions', sub: true },
              { title: t('Global.aside.alerts'), name: 'alerts', path: '/toolbox/alerts', sub: true },
            ],
          },
          {
            title: t('Global.aside.admin'),
            name: 'admin',
            isLink: false,
            children: [
              { title: t('Global.aside.countries'), name: 'countries', path: '/countries', sub: true },
              { title: t('Global.aside.globalTerms'), name: 'global-terms', path: '/terms', sub: true },
              { title: t('Global.aside.clients'), name: 'clients', path: '/clients', sub: true },
              {
                title: t('Global.aside.globalServices'),
                name: 'global-services',
                path: '/services',
                sub: true,
                children: [
                  { title: t('Global.aside.processes'), name: 'processes', path: '/processes', sub: true },
                  { title: t('Global.aside.steps'), name: 'steps', path: '/steps', sub: true },
                  { title: t('Global.aside.actions'), name: 'actions', path: '/actions', sub: true },
                ],
              },
            ],
          },
          { title: t('Global.aside.vendors'), name: 'vendors', path: '/vendors' },
          { title: t('Global.aside.users'), name: 'users', path: '/users' },
        ]}
        {...props}
      />
    </aside>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
})

// Map state to props
const mapStateToProps = (state, props) => {
  if (isFetching([state.companies])) return { isFetching: true }
  return {
    companies: getCompaniesRef(state, props),
  }
}

Aside.propTypes = {
  isFetching: PropTypes.bool,
  history: PropTypes.object,
  fetchCompaniesIfNeeded: PropTypes.func,
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
