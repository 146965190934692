import React from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import SwitchToggle from 'components/form/SwitchToggle'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const DataFieldForm = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <Form {...props}>
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='name'
            label={t('BusinessComponent.form.dateProtectionFieldLabel')}
            labelClassName='c-label u-text--small'
            component={InputField}
            type='text'
            disabled
            disabledReason={t('BusinessComponent.form.dateProtectionFieldDisabledReason')}
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='isSensitive'
            label={t('BusinessComponent.form.isSensitiveDataLabel', {
              requiredSymbol: '*',
            })}
            component={SwitchToggle}
            labelClassName='c-label u-text--small'
          />
        </div>
      </div>
      <div className='o-layout u-padding'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </Form>
  )
}

DataFieldForm.propTypes = {
  isFetching: PropTypes.bool,
}

export default DataFieldForm
