import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import AmountFormat from 'components/AmountFormat'
import MobileDetailsView from 'components/MobileDetailsView'
import VariablePayElements from './VariablePayElements'
import Deductions from './Deductions'
import SectionHeading from 'components/SectionHeading'
import { getTermDisplayName } from 'utils/sections/payAndTaxes/employee'
import AnnualSalaryTableView from './AnnualSalaryTableView'
import AddBtnMessage from '../../Personal/components/AddBtnMessage'
import TermsFormEditContainer from '../containers/TermsFormEditContainer'
import FixedPayEditableElements from './FixedPayEditableElements'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getMobilePayTaxesViews = (t) => [
  {
    key: 'jobOrg',
    title: t('Employee.tabs.job_and_org'),
    color: 'minsk',
    isSingleColumn: false,
    isMultiRow: false,
  },
  {
    key: 'fixedPayElements',
    title: t('Employee.pay_and_taxes.label.recurringDeductions'),
    color: 'minsk',
    isSingleColumn: false,
    isMultiRow: true,
  },
  {
    key: 'variablePayElements',
    title: t('Employee.job_org_heading.employment_details'),
    color: 'minsk',
    isSingleColumn: true,
    isMultiRow: true,
  },
  {
    key: 'employeeDeductions',
    title: t('Employee.payroll_updates_list_tableHeadings.payroll'),
    color: 'minsk',
    isSingleColumn: false,
    isMultiRow: true,
  },
  {
    key: 'employeeNetDeductions',
    title: t('Employee.text.net_deductions'),
    color: 'minsk',
    isSingleColumn: false,
    isMultiRow: true,
  },
  {
    key: 'employeeContributions',
    title: t('Employee.table.column.gross_pay'),
    color: 'minsk',
    isSingleColumn: false,
    isMultiRow: true,
  },
]
const DeductionViews = ({ isEmployeeOnly = false, inEditMode, inAddMode, onUpdateGlobalDataForSave, fieldErrors, onValueChange, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const { employerDeductions, employeeDeductions, futureEmployeeDeductions, employeeNetDeductions, futureEmployerDeductions, futureEmployeeNetDeductions } =
    rest.data

  return (
    <div>
      {!isEmployeeOnly && (
        <Deductions
          title={t('Employee.deductions.employer_contributions')}
          dataKey='employerDeductions'
          futureDataKey='futureEmployerDeductions'
          deductions={employerDeductions}
          futureDeductions={futureEmployerDeductions}
          inAddMode={inAddMode}
          inEditMode={inEditMode}
          onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
          fieldErrors={fieldErrors}
          onValueChange={onValueChange}
        />
      )}
      <Deductions
        title={t('Employee.deductions.employee_deductions')}
        dataKey='employeeDeductions'
        futureDataKey='futureEmployeeDeductions'
        deductions={employeeDeductions}
        futureDeductions={futureEmployeeDeductions}
        inAddMode={inAddMode}
        inEditMode={inEditMode}
        onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
        fieldErrors={fieldErrors}
        onValueChange={onValueChange}
      />
      <Deductions
        title={t('Employee.text.net_deductions')}
        dataKey='employeeNetDeductions'
        futureDataKey='futureEmployeeNetDeductions'
        deductions={employeeNetDeductions}
        futureDeductions={futureEmployeeNetDeductions}
        inAddMode={inAddMode}
        inEditMode={inEditMode}
        onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
        fieldErrors={fieldErrors}
        onValueChange={onValueChange}
      />
      {isEmployeeOnly && (
        <Deductions
          title={t('Employee.deductions.employer_contributions')}
          dataKey='employerDeductions'
          futureDataKey='futureEmployerDeductions'
          deductions={employerDeductions}
          futureDeductions={futureEmployerDeductions}
          inAddMode={inAddMode}
          inEditMode={inEditMode}
          onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
          fieldErrors={fieldErrors}
          onValueChange={onValueChange}
        />
      )}
    </div>
  )
}
DeductionViews.propTypes = {
  employerDeductions: PropTypes.array,
  employeeDeductions: PropTypes.array,
  employeeNetDeductions: PropTypes.array,
  isEmployeeOnly: PropTypes.bool,
  inEditMode: PropTypes.bool,
  inAddMode: PropTypes.bool,
  onUpdateGlobalDataForSave: PropTypes.func,
  fieldErrors: PropTypes.array,
  onValueChange: PropTypes.func,
}

const PayTaxesView = (props) => {
  const modalEditTermsRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const renderMobileJobOrgRows = () => {
    const { employee, employeeAnnualSalary, employeeCustomFields, effectiveDate } = props
    let rows = []
    let excludedRows = ['invoicePaymentCurrency']
    rows.push({
      [t('Employee.text.annual_salary')]: <AmountFormat key={'annual'} amount={parseFloat(employeeAnnualSalary)} />,
    })
    rows.push({ [t('Employee.text.annual_salary_effective_date')]: effectiveDate })
    employeeCustomFields
      .filter((field) => !excludedRows.includes(field.name))
      .forEach((field) => rows.push({ [getPrintableName(field.name)]: employee[field.name] }))
    return rows
  }

  const renderMobileFixedPayElRows = () => {
    const { fixedTerms, futureFixedTerms } = props.data
    let rows = []
    fixedTerms.forEach((term) => {
      const expandedRows = futureFixedTerms.find((ft) => ft.countryTermId === term.id) ?? null
      const { globalName, localName, name, amount, id } = term
      const showAndNamesMatch = term.shouldOnlyUseGlobalName && globalName === localName
      const hasLocalName = localName === null || localName === ''
      rows.push({
        [showAndNamesMatch || hasLocalName ? globalName : `${globalName} / ${localName || name}`]: {
          validFrom: term.validFrom,
          validTo: term.validTo,
          amount: <AmountFormat key={id} amount={amount} />,
          expandedItems:
            expandedRows &&
            [expandedRows].map((item, idx) => {
              return {
                ...item,
                amount: <AmountFormat key={`${item.amount}-${item.idx}`} amount={item.amount} />,
              }
            }),
        },
      })
    })
    return rows
  }

  const renderMobileVariablePayElRows = () => {
    const { variableTerms } = props
    let rows = []
    variableTerms.forEach((term, i) => {
      rows.push(
        (term.shouldOnlyUseGlobalName && term.globalName === term.companyCountryTermLocalName) ||
          term.companyCountryTermLocalName === null ||
          term.companyCountryTermLocalName === ''
          ? term.globalName
          : `${term.globalName} / ${term.companyCountryTermLocalName || term.localName}`
      )
    })
    return rows
  }

  const renderDeductionsRows = (deductions, futureDeductions) => {
    let rows = []
    deductions.forEach((term) => {
      const expandedRows = futureDeductions.find((ft) => ft.countryTermId === term.countryTermId) ?? null
      rows.push({
        [getTermDisplayName(term)]: {
          validFrom: term.validFrom,
          validTo: term.validTo,
          amount: <AmountFormat key={term.id} amount={term.amount} />,
          expandedItems:
            expandedRows &&
            [expandedRows].map((item, idx) => {
              return {
                ...item,
                amount: <AmountFormat key={idx} amount={item.amount} />,
              }
            }),
        },
      })
    })
    return rows
  }

  const getPrintableName = (fieldName) => {
    const { employeeCustomFields } = props
    return employeeCustomFields.filter((field) => field.name === fieldName).map((field) => field.printableName)
  }

  const getRowsForView = (type) => {
    if (type === 'jobOrg') return renderMobileJobOrgRows()
    if (type === 'fixedPayElements') return renderMobileFixedPayElRows()
    if (type === 'variablePayElements') return renderMobileVariablePayElRows()
    if (type === 'employeeDeductions') return renderDeductionsRows(props.data.employeeDeductions, props.data.futureEmployeeDeductions)
    if (type === 'employeeNetDeductions') return renderDeductionsRows(props.data.employeeNetDeductions, props.data.futureEmployeeNetDeductions)
    if (type === 'employeeContributions') return renderDeductionsRows(props.data.employerDeductions, props.data.futureEmployerDeductions)
    return []
  }

  const onAddVariableElements = () => {
    modalEditTermsRef.current.showModal()
  }

  const { employee, data, onUpdateGlobalDataForSave, fieldErrors, onValueChange } = props
  const { isFetching, hasEmployeeActiveInstances, hasEmployeeLockedInstances, inlineMode, showConfirmation } = props
  const { disableNonGlobalButtons, isEmployeeOnly, inAddMode, inEditMode } = props
  const { onAnnualSalaryHistoryBtnClick, setInlineMode, employeeAnnualSalary } = props

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <div className='page--pay-taxes-view-container'>
          <div className='o-layout'>
            <div className='o-layout__item page--pay-taxes-view-container-layout'>
              <AnnualSalaryTableView
                salary={employeeAnnualSalary}
                onAnnualSalaryHistoryBtnClick={onAnnualSalaryHistoryBtnClick}
                employeeId={employee.id}
                inlineMode={inlineMode}
                setInlineMode={setInlineMode}
                disableSalaryInlineButtons={disableNonGlobalButtons}
                showConfirmation={showConfirmation}
                hasEmployeeLockedInstances={hasEmployeeLockedInstances}
                hasEmployeeActiveInstances={hasEmployeeActiveInstances}
                hideInlineButtons={isEmployeeOnly}
              />
              <>
                <FixedPayEditableElements
                  dataKey='fixedTerms'
                  futureDataKey='futureFixedTerms'
                  fixedTerms={data.fixedTerms}
                  futureFixedTerms={data.futureFixedTerms}
                  isEmployeeOnly={isEmployeeOnly}
                  inAddMode={inAddMode}
                  inEditMode={inEditMode}
                  onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
                  fieldErrors={fieldErrors}
                  onValueChange={onValueChange}
                />
                <VariablePayElements variableTerms={props.variableTerms} />
                <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
                  <div className='u-weight--regular u-text--normal text-right u-margin-bottom u-margin-top'>
                    <AddBtnMessage
                      onClick={() => onAddVariableElements()}
                      isDisabled={disableNonGlobalButtons || inlineMode}
                      text={t('Employee.button.addVariable_elements')}
                      testId='add-variable-elements-btn'
                      btnClasses='c-btn--small u-padding-left u-padding-right u-margin-left-small'
                    />
                  </div>
                </Authorization>
              </>
            </div>
            <div className='o-layout__item u-1/1@tablet u-1/2@desktop'>
              <DeductionViews
                data={data}
                isEmployeeOnly={isEmployeeOnly}
                inAddMode={inAddMode}
                inEditMode={inEditMode}
                onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
                fieldErrors={fieldErrors}
                onValueChange={onValueChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        ref={modalEditTermsRef}
        className='c-modal c-modal--half c-modal--overflow-y'
        modalHeading={t('Employee.text.choose_variable_pay_el')}
        data-testid='terms-edit-modal'
      >
        <TermsFormEditContainer onSubmit={() => null} {...props} />
      </Modal>
      {isEmployeeOnly && (
        <div className='u-hide-on-desktop'>
          <SectionHeading text={t('Global:Global.aside.payAndTaxes')} />
          {getMobilePayTaxesViews(t).map((view) => (
            <AccordionContainer key={view.key} title={view.title} titleColor={view.color} fullWidth rounded>
              <MobileDetailsView rows={getRowsForView(view.key)} isSingleColumn={view.isSingleColumn} isMultiRow={view.isMultiRow} />
            </AccordionContainer>
          ))}
        </div>
      )}
    </>
  )
}
PayTaxesView.propTypes = {
  isFetching: PropTypes.bool,
  employerDeductions: PropTypes.array,
  employeeDeductions: PropTypes.array,
  employeeNetDeductions: PropTypes.array,
  onEdit: PropTypes.func,
  isEmployeeOnly: PropTypes.bool,
  isEditDeductionsButtonVisible: PropTypes.bool,
  onJobAndOrgEdit: PropTypes.func,
  onTermsEdit: PropTypes.func,
  onDeductionsEdit: PropTypes.func,
  employee: PropTypes.object,
  employeePayrolls: PropTypes.array,
  employeePaygroups: PropTypes.array,
  employeeAnnualSalary: PropTypes.number,
  EmployeeStatus: PropTypes.object,
  employeeCustomFields: PropTypes.array,
  effectiveDate: PropTypes.string,
  fixedTerms: PropTypes.array,
  inEditMode: PropTypes.bool,
  inAddMode: PropTypes.bool,
  variableTerms: PropTypes.array,
  data: PropTypes.object,
  onUpdateGlobalDataForSave: PropTypes.func,
  fieldErrors: PropTypes.array,
  onValueChange: PropTypes.func,
  hasEmployeeActiveInstances: PropTypes.bool,
  hasEmployeeLockedInstances: PropTypes.bool,
  inlineMode: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  disableNonGlobalButtons: PropTypes.bool,
  onAnnualSalaryHistoryBtnClick: PropTypes.func,
  setInlineMode: PropTypes.func,
}

export default PayTaxesView
