import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import EmployeeRoute from './routes/Employee'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/employees',
  exact: true,
  indexRoute: { component: withTranslationContext([i18nNameSpaces.Employee, i18nNameSpaces.Import], RouteView) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [EmployeeRoute(store)],
})
