import ConfirmationModal from 'components/ConfirmationModal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { i18nNameSpaces } from 'i18n/types'

export const GTNValidationModals = ({
  overrideGtnValidation,
  overrideValidationModalRef,
  shareConfirmationModalRef,
  documentId,
  onConfirmShare,
  onRejectShare,
}) => {
  const onOverrideConfirm = async () => {
    const res = await overrideGtnValidation(documentId)
    if (res.data) shareConfirmationModalRef.current?.showModal()
  }
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <>
      <ConfirmationModal
        ref={overrideValidationModalRef}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={onOverrideConfirm}
      >
        <p>{t('BusinessComponent.gtn_validation.override.confirm1')}</p>
        <p>{t('BusinessComponent.gtn_validation.override.confirm2')}</p>
      </ConfirmationModal>

      <ConfirmationModal
        ref={shareConfirmationModalRef}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={onConfirmShare}
        onReject={onRejectShare}
      >
        <p>{t('BusinessComponent.gtn_validation.share.confirm')}</p>
      </ConfirmationModal>
    </>
  )
}

GTNValidationModals.propTypes = {
  overrideGtnValidation: PropTypes.func,
  overrideValidationModalRef: PropTypes.object,
  shareConfirmationModalRef: PropTypes.object,
  documentId: PropTypes.string,
  onConfirmShare: PropTypes.func,
  onRejectShare: PropTypes.func,
}
