import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import { CellNumberFormat } from 'utils/tableDataFormatters'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
const classNames = require('classnames')

const ChildReport = (props) => {
  const [expandReport, setExpandReport] = useState(props.index === 0)

  const { t } = useTranslation([i18nNameSpaces.Report])

  const getArrowClasses = () => {
    return classNames({
      'c-btn--unset-default-styles icon--arrow u-float--left u-margin-left-tiny u-margin-top-tiny': true,
      active: expandReport,
    })
  }

  const toggleReport = () => {
    setExpandReport(!expandReport)
  }

  const { childReport, headings, reportNameCategory = null, isFetching } = props

  const options = {
    pageSize: 25,
    pageIndex: 0,
    showPageSizeSelector: childReport.data.length >= 25,
  }

  // Apply props on headings based on flags
  const normalizedHeadings = headings.map((heading) => {
    let structure = { ...heading }
    if (structure.hidden) {
      structure['isVisible'] = false
    }
    if (structure.name === 'changeId') {
      structure['isKey'] = true
      structure['isVisible'] = false
    }
    let classNamesForColumn = ''
    let headerClassNames = ''
    if (structure.alignment === 'center') {
      classNamesForColumn = 'text-left'
    }
    if (structure.isNumber || structure.type === 'decimal') {
      structure['Cell'] = CellNumberFormat
      classNamesForColumn = 'text-right u-margin-right'
      headerClassNames = 'text-right'
    }
    if (structure.name === 'payrun') {
      classNamesForColumn = 'u-text--overflow-ellipsis--nowrap'
    }

    // Structure the data for the new table
    structure['accessor'] = structure.name
    structure['Header'] = structure.text
    structure['disableSortBy'] = false
    structure['setWidth'] = structure.name === 'systemEmployeeId' ? '50px' : '150px'
    structure['rowSpan'] = structure.rowspan
    structure['colSpan'] = structure.colspan
    structure['classNames'] = headerClassNames
    structure['columnClassName'] = classNamesForColumn
    structure['isVisible'] = !structure.hidden

    return structure
  })
  const tableHeadings = {
    headings: normalizedHeadings.filter((heading) => !heading.isKey && heading.isVisible),
  }

  return (
    <div>
      {/* Report heading */}
      <SectionHeading text={reportNameCategory || childReport.name}>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <div className='u-float--right'>
            <button type='button' onClick={toggleReport} className={getArrowClasses()} />
          </div>
        </div>
      </SectionHeading>

      {expandReport && (
        <div>
          {/* Additional info before table */}
          {childReport.info && childReport.info.length && (
            <div className='u-margin-bottom'>
              {childReport.info.map((i) => {
                return (
                  <p key={i.value} className='u-margin-none'>
                    {i.text + ': ' + i.value}
                  </p>
                )
              })}
            </div>
          )}
          {childReport.data.length ? (
            <div className='u-relative'>
              {/* Table */}
              <PayslipTable
                data={childReport.data}
                options={options}
                pagination
                {...tableHeadings}
                wrapperClassName={classNames({
                  'u-1/1 react-bs-table--overflow-auto': true,
                  'u-half-opacity': isFetching,
                  'react-bs-table': true,
                })}
                trClassName={(row, rowIndex) => 'react-bs-table__cell--overflow-ellipsis'}
                modifierClasses={classNames({
                  'react-bs-container-body': true,
                  'u-overflow-y-hidden': true,
                })}
                tableElementClassName='table--layout-auto'
              />
            </div>
          ) : (
            <div className='u-margin-bottom-small'>{t('Global:Global.noData')}</div>
          )}
        </div>
      )}
    </div>
  )
}

ChildReport.propTypes = {
  childReport: PropTypes.object,
  headings: PropTypes.array,
  reportNameCategory: PropTypes.string,
  index: PropTypes.number,
  isFetching: PropTypes.bool,
}

export default ChildReport
