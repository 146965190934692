import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { HEADER_ICONS_TYPES, HeaderIcon } from 'components/header/HeaderIcons'
import { i18nNameSpaces } from 'i18n/types'

const NavBarAlertsWidget = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className='c-nav-bar c-nav-bar--widget c-nav-bar--widget--supernova u-margin-right-tiny u-padding-bottom-tiny'>
      {renderButton(props)}
      {renderNotifications(props, t)}
    </div>
  )
}

const renderButton = ({ hasUnread, onNavigatingToAlerts }) => (
  <span onClick={onNavigatingToAlerts}>
    <div className='o-block o-block--center'>
      <div className='nav-icon--tiny o-block'>
        {hasUnread && <span className='c-nav-bar__indicator u-bg--supernova' />}
        <HeaderIcon type={HEADER_ICONS_TYPES.bell} />
      </div>
    </div>
  </span>
)

const renderNotifications = ({ notifications, onNavigatingToAlerts }, t) => (
  <div className='c-nav-bar__dropdown c-nav-bar__dropdown--fixed-width'>
    <ul className='c-nav u-margin-left-none u-margin-bottom-none'>
      {notifications ? (
        notifications.map((notification) => (
          <li key={notification.id} className='o-list-bare'>
            <span onClick={onNavigatingToAlerts}>
              <div className='o-grid o-grid--middle o-layout o-layout--small'>
                <div className='o-layout__item u-1/6 u-padding-none'>
                  <HeaderIcon type={HEADER_ICONS_TYPES.bell} height={15} width={15} />
                </div>
                <div className={notification.isRead ? 'o-layout__item  u-3/4' : 'u-3/4 o-layout__item u-weight--normal line-height--15'}>
                  <span className='o-block o-block--left u-text--tiny line-height--15'>{notification.createdAt}</span>
                  <span className='o-block o-block--left u-text--small line-height--15'>
                    {t('Global.text.payroll_name')}: {notification.payrollName}
                  </span>
                  <span className='o-block o-block--left u-text--small line-height--15'>
                    {t('Global.text.title')}: {notification.title}
                  </span>
                </div>
              </div>
            </span>
          </li>
        ))
      ) : (
        <li className='o-list-bare'>
          <p>
            <span className='o-block o-block--center u-weight--bold u-text--small u-padding-small'>{t('Global.text.no_notifications')}</span>
          </p>
        </li>
      )}
      {notifications && (
        <li className='o-list-bare no-bg'>
          <button
            onClick={onNavigatingToAlerts}
            className='c-btn c-btn--curious u-text--small u-padding-top-tiny
            u-padding-bottom-tiny u-padding-left-large u-padding-right-large
             d-block u-margin--auto'
          >
            {t('Global:Global.text.see_all_alerts')}
          </button>
        </li>
      )}
    </ul>
  </div>
)

const commonProps = {
  notifications: PropTypes.array,
  onNavigatingToAlerts: PropTypes.func,
  linkToAlerts: PropTypes.string,
  isCot: PropTypes.bool,
  setCOTAndRedirect: PropTypes.func,
  hasUnread: PropTypes.bool,
}
renderNotifications.propTypes = commonProps
renderButton.propTypes = commonProps

export default NavBarAlertsWidget
