import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from 'mobile-device-detect'

import PropTypes from 'prop-types'
import AsideContainer from '../../containers/AsideContainer'
import HeaderContainer from '../../containers/header/HeaderContainer'
import { useHistory, useLocation } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import { isEmployeeOnly } from 'redux/selectors/auth'
import { logos } from 'utils/config'
import { getTenantSubdomain } from 'utils/tenant'
import { useTranslation } from 'react-i18next'

const windowThreshold = 1280

const HamburgerMenu = ({ openMobileSideMenu }) => {
  return (
    <div id='hamburger-menu' onClick={openMobileSideMenu}>
      <span className='rounded' />
      <span className='rounded' />
      <span className='rounded' />
    </div>
  )
}
const CloseHamburgerMenu = ({ openMobileSideMenu }) => {
  return (
    <div id='close-hamburger-menu' onClick={openMobileSideMenu}>
      <span className='rounded' />
      <span className='rounded' />
    </div>
  )
}

const PayslipLogo = () => {
  return <div className='mobile-navbar-logo c-logo c-logo--full u-hide-on-desktop' />
}

const CompanyLogo = ({ logo }) => (
  <div className='mobile-navbar-logo mobile-navbar-logo--center u-hide-on-desktop d-grid w-100 d-grid--cols-3'>
    <div />
    <div>
      <div className='mobile-image--sizer'>
        <img src={logo} />
      </div>
    </div>
    <div />
  </div>
)

function PageLayout (props) {
  let location = useLocation()
  let history = useHistory()
  const [isMobileOpened, setIsMobileOpened] = useState(false)
  const { t } = useTranslation()

  const pageLayoutProps = useSelector((state) => {
    let logoToUse = null
    if (isEmployeeOnly(state) && logos) {
      logoToUse = logos[getTenantSubdomain()] ? logos[getTenantSubdomain()] : null
    }
    return {
      asideMode: state.aside.collapsed,
      isEmployeeOnly: isEmployeeOnly(state),
      logo: logoToUse,
    }
  })
  props = { ...props, ...pageLayoutProps }
  const checkDimensions = () => {
    if (window.innerWidth > windowThreshold && isMobileOpened) {
      setIsMobileOpened(false)
    }
  }

  const closeSideMenuOnNavigation = () => {
    setIsMobileOpened(false)
  }

  const openMobileSideMenu = () => {
    setIsMobileOpened(!isMobileOpened)
  }

  useEffect(() => {
    window.addEventListener('resize', checkDimensions)
    window.addEventListener('closeSideMenu', closeSideMenuOnNavigation)
    return function cleanup () {
      window.removeEventListener('resize', checkDimensions)
      window.removeEventListener('closeSideMenu', closeSideMenuOnNavigation)
    }
  })

  const isMobileClasses = isMobile ? 'is-mobile' : ''
  const asideModeClasses = props.asideMode ? 'o-wrapper--full o-aside--collapsed' : 'o-wrapper--full'

  let classList = `${isMobileClasses} ${asideModeClasses}`

  if (props.isEmployeeOnly) {
    classList += isMobileOpened ? ' mobile-menu--opened ' : ''
  }

  return (
    <div id={props.isEmployeeOnly ? 'employee-self-service' : ''} className={classList}>
      <AsideContainer history={history} />

      <section className='o-page-wrapper'>
        <HeaderContainer {...props} query={useQuery()} location={location} />
        {props.children}
      </section>

      {props.isEmployeeOnly && props.logo && <CompanyLogo logo={props.logo} />}
      {props.isEmployeeOnly && (
        <React.Fragment>
          <PayslipLogo />
          <div className='is-mobile powered-by-payslip'>
            <div>{t('Global.nav.poweredBy')}</div>
            <div className='payslip-logo' />
          </div>
          <HamburgerMenu openMobileSideMenu={openMobileSideMenu} />
          <CloseHamburgerMenu openMobileSideMenu={openMobileSideMenu} />
        </React.Fragment>
      )}
    </div>
  )
}

const commonProps = {
  openMobileSideMenu: PropTypes.func,
}

CloseHamburgerMenu.propTypes = commonProps
HamburgerMenu.propTypes = commonProps
PageLayout.propTypes = {
  children: PropTypes.node,
  isEmployeeOnly: PropTypes.bool,
  logo: PropTypes.object,
  asideMode: PropTypes.bool,
}

CompanyLogo.propTypes = {
  logo: PropTypes.any,
}

export default PageLayout
