import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

export const Processes = ({ processes, isFetching }) => {
  const { t } = useTranslation([i18nNameSpaces.Process])
  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>
  return (
    <div>
      <SectionHeading text={t('Process.text.list')} className='u-margin-top-large' />
      {isFetching === true ? t('Global:Global.text.loading') : null}
      <PayslipTable
        wrapperClassName='u-1/1@tablet u-1/2@desktop'
        headings={[
          {
            accessor: 'name',
            Header: t('Global:Global.text.name'),
            disableSortBy: true,
          },
          {
            accessor: 'keyEvent',
            Header: t('Process.form.key_event'),
            disableSortBy: true,
          },
          {
            accessor: 'serviceRef',
            Header: t('Global:Global.text.service'),
            disableSortBy: true,
          },
        ]}
        data={processes.map((process) => ({
          name: process.name,
          keyEvent: process.keyEvent,
          serviceRef: process.serviceRef.name,
        }))}
      />
    </div>
  )
}
Processes.propTypes = {
  processes: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
}

export default Processes
