import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from './table/PayslipTable'
import { i18nNameSpaces } from 'i18n/types'

export const ServicesTree = ({ rows, heading }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div>
      <SectionHeading text={heading || t('BusinessComponent.servicesTree.heading')} className='u-margin-top-large' />
      <PayslipTable
        data={rows.map((row) => ({
          id: row.id,
          name: row.serviceName,
          type: row.serviceType,
          processes: row.processName,
          keyProcessEvent: row.processKeyEvent,
          steps: row.stepName,
          actions: row.taskName,
          actionOwner: row.taskOwner,
          actionCompletionLink: row.finishTaskLink,
        }))}
        headings={[
          { accessor: 'name', Header: t('Global:Global.text.name'), disableSortBy: true },
          { accessor: 'type', Header: t('Global:Global.text.type'), disableSortBy: true },
          { accessor: 'processes', Header: t('BusinessComponent.servicesTree.table.processes'), disableSortBy: true },
          {
            accessor: 'keyProcessEvent',
            Header: t('BusinessComponent.servicesTree.table.keyProcessEvent'),
            disableSortBy: true,
          },
          { accessor: 'steps', Header: t('BusinessComponent.servicesTree.table.steps'), disableSortBy: true },
          { accessor: 'actions', Header: t('BusinessComponent.servicesTree.table.actions'), disableSortBy: true },
          {
            accessor: 'actionOwner',
            Header: t('BusinessComponent.table.action_owner'),
            disableSortBy: true,
          },
          {
            accessor: 'actionCompletionLink',
            Header: t('BusinessComponent.servicesTree.table.actionCompletionLink'),
            disableSortBy: true,
          },
        ]}
        wrapperClassName='u-margin-bottom u-1/1'
        trBodyClassName='o-payslip-table__cell--whitespace--normal'
      />
    </div>
  )
}

ServicesTree.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      serviceName: PropTypes.string,
      serviceType: PropTypes.string,
      processName: PropTypes.string,
      processKeyEvent: PropTypes.string,
      stepName: PropTypes.string,
      taskName: PropTypes.string,
      taskOwner: PropTypes.string,
      finishTaskLink: PropTypes.string,
    })
  ).isRequired,
  heading: PropTypes.string,
}

export default ServicesTree
