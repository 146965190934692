import React, { useContext, useEffect } from 'react'
import EmployeeMobileNav from 'components/EmployeeMobileNav'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { createFilter } from 'utils/redux/filter'
import Logo from '../Logo'
import MainNav from '../MainNav'
import { getEmployeeById } from 'routes/Payruns/routes/Instance/selectors/employeesORM'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { fetchEmployeeSystemUsersIfNeeded } from 'redux/actions/employeeSystemUsers'
import PropTypes from 'prop-types'
import { KeycloakCTX } from 'components/Keycloak/KeycloakProvider'
import { useTranslation } from 'react-i18next'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'

const event = new Event('closeSideMenu')

const Aside = (props) => {
  const { t } = useTranslation()
  const keycloak = useContext(KeycloakCTX)

  useEffect(() => {
    const { dispatch, employeeFilter } = props
    dispatch(fetchEmployeeSystemUsersIfNeeded({ filter: employeeFilter }))
    dispatch(fetchParentCompaniesIfNeeded())
  }, [])

  const { isFetching, EmployeeStatus, userId, logout, selectAside, asideMode } = props

  if (isFetching) return <div>{t('Global.text.loading')}</div>

  const tabPayslips = EmployeeStatus.isContractorChangeTitleStatus ? t('Global.aside.myInvoices') : t('Global.aside.myPayslips')
  const tabDocuments = EmployeeStatus.isContractorChangeTitleStatus ? t('Global.aside.documents') : t('Global.aside.taxDocuments')

  const mobileRoutes = [
    {
      title: `${tabPayslips}`,
      name: 'home',
      path: `/employees/${userId}/payslips`,
      onClick: () => window.dispatchEvent(event),
      isHome: true,
    },
    {
      title: t('Global.aside.personal'),
      name: 'home',
      path: `/employees/${userId}/personal`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: t('Global.aside.jobAndOrg'),
      name: 'home',
      path: `/employees/${userId}/job-and-org`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: t('Global.aside.payAndTaxes'),
      name: 'home',
      path: `/employees/${userId}/pay-taxes`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: t('Global.aside.timeAndAttendance'),
      name: 'home',
      path: `/employees/${userId}/time-attendance`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: `${tabDocuments}`,
      name: 'home',
      path: `/employees/${userId}/documents`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: t('Global.aside.settings'),
      name: 'home',
      path: `/settings`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: t('Global.aside.logout'),
      name: 'home',
      onClick: () => logout(keycloak),
    },
  ]

  return (
    <>
      <span id='desktop-employee-header-view'>
        <aside id='employee-self-service-menu' className='o-aside'>
          <Logo />

          <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} aria-label={t('Global.aside.handleSelectAside')} />

          <MainNav
            items={[
              {
                title: t('Global.aside.home'),
                name: 'home',
                path: `/employees/${userId}/payslips`,
                isHome: true,
              },
            ]}
            {...props}
          />
        </aside>
      </span>

      <span id='mobile-employee-header-view'>
        <EmployeeMobileNav routes={mobileRoutes} />
      </span>
    </>
  )
}

// Dispatch actions on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    dispatch,
  }
}

// Map state to props
const mapStateToProps = (state, props) => {
  const { employeeSystemUsers } = state
  const employeeFilter = createFilter({ id: props.userId })
  const entities = [{ ref: employeeSystemUsers, filter: employeeFilter }]

  if (isFetching(entities)) return { isFetching: true, employeeFilter }

  const employee = getEmployeeById(state, { employeeId: props.userId })
  const { employmentStatus } = employee
  const EmployeeStatus = new EmploymentStatusHelper(employmentStatus)

  return {
    EmployeeStatus,
  }
}

Aside.propTypes = {
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  employeeFilter: PropTypes.object,
  EmployeeStatus: PropTypes.object,
  userId: PropTypes.number,
  logout: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
