import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { SummaryAdditionalItems } from '../components/SummaryAdditionalITems'
import { getAdditionalItemsTableHeadings } from '../additionalItemsTableConfig'
import { ADDITIONAL_ITEMS_INITIAL_STATE, additionalItemsStateReducer, additionalItemsStateTypes } from '../utils/additionalItemsStateReducer'
import { AdditionalItemsActions } from '../components/AdditionalItemsActions'
import Loader from 'components/Loader'

import { deleteAdditionalItem, fetchAdditionalItems } from 'redux/actions/additionalItems'
import { createFilter } from 'utils/redux/filter'
import { createAdditionalItemsFilterNameById } from 'redux/filters/additionalItemsFilter'
import { getAdditionalItems } from 'redux/selectors/additionalItems'
import { HistoryModal } from 'components/ReusableModals/HistoryModal'
import { DeleteConfirmationModal } from 'components/ReusableModals/DeleteConfirmationModal'
import { isEmpty } from 'lodash'
import { PayrollSummaryContext, summaryViewToggleIds } from '../PayrollSummaryContext'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const SummaryAdditionalItemsContainer = ({ payrollInstance }) => {
  const dispatch = useDispatch()
  const payrollInstanceId = payrollInstance.id
  const { t } = useTranslation([i18nNameSpaces.Payrun])

  // State for handle the delete and history modal and only update this part of the DOM
  const [clickedRow, setClickedRow] = useState()
  const deleteModalRef = useRef()
  const historyModalRef = useRef()

  const filterName = createAdditionalItemsFilterNameById(payrollInstanceId)
  const [state, dispatchStateChange] = useReducer(additionalItemsStateReducer, ADDITIONAL_ITEMS_INITIAL_STATE)

  const additionalITems = useSelector((state) => getAdditionalItems(state, filterName))

  const { mode, data, needsRefetch, hasItems, tableChangedData, isDirty, isLoading, editableTableState } = state

  const { setGlobalUpdateMode } = useContext(PayrollSummaryContext)

  useEffect(() => {
    setGlobalUpdateMode(summaryViewToggleIds.additionalItems, !!mode)
  }, [mode])

  const fetchData = async () => {
    await dispatch(
      fetchAdditionalItems({
        filter: createFilter(
          {
            payrollInstance: payrollInstanceId,
          },
          filterName
        ),
      })
    )
  }

  useEffect(() => {
    if (needsRefetch) {
      fetchData()
    }
  }, [needsRefetch])

  useEffect(() => {
    if (additionalITems) {
      dispatchStateChange({
        type: additionalItemsStateTypes.onFetchData,
        payload: additionalITems,
      })
    }
  }, [additionalITems])

  if (isLoading) {
    return <Loader />
  }

  const onUpdateGlobalData = (rowIndex, columnId, value) =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onEditData,
      payload: { rowIndex, columnId, value: `${value}` },
    })

  const toggleMode = (payload) =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onToggleMode,
      payload,
    })

  const handleDelete = (row) => {
    setClickedRow(row)
    deleteModalRef?.current?.showModal()
  }
  const handleHistory = (row) => {
    setClickedRow(row)
    historyModalRef?.current?.showModal()
  }

  const notifyErrors = (payload) =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onHasErrors,
      payload,
    })

  const refreshState = () =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onResetState,
    })

  const deleteConfirmation = async () => {
    await dispatch(deleteAdditionalItem(clickedRow.id))
    refreshState()
  }

  const tableHeadings = getAdditionalItemsTableHeadings({ handleDelete, handleHistory })
  const hasHistory = !isEmpty(clickedRow?.history)
  const historyHeading = hasHistory ? `${clickedRow.item} ${t('Global:Global.text.history')}` : t('Payrun.text.non_additional_items_history')

  return (
    <div className='rounded border u-padding-small'>
      <HistoryModal modalRef={historyModalRef} heading={historyHeading} logs={clickedRow?.history} />
      <DeleteConfirmationModal modalRef={deleteModalRef} onConfirmation={deleteConfirmation} />
      <AdditionalItemsActions
        payrollInstance={payrollInstance}
        mode={mode}
        isDirty={isDirty}
        hasItems={hasItems}
        toggleMode={toggleMode}
        tableChangedData={tableChangedData}
        notifyErrors={notifyErrors}
        refreshState={refreshState}
      />
      <SummaryAdditionalItems data={data} tableHeadings={tableHeadings} editableTableState={editableTableState} onUpdateGlobalData={onUpdateGlobalData} />
    </div>
  )
}

SummaryAdditionalItemsContainer.propTypes = {
  payrollInstance: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
}
