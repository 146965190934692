import { i18nNameSpaces } from 'i18n/types'
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next' // Import useTranslation
import PayslipTable from 'components/table/PayslipTable'
import { flag, processLink } from 'utils/tableDataFormatters'

const CompanyServicesList = ({ operationalServices, setupServices, isTableRepresentation }) => {
  const { t } = useTranslation([i18nNameSpaces.Company])
  const [processes, setProcesses] = useState([])
  const [isOperational, setIsOperational] = useState(null)
  const modalRef = useRef(null)

  const handleModalOpen = (modalRef) => {
    modalRef.current.showModal()
  }

  const handleServiceClick = (processes) => {
    setProcesses(processes)
    handleModalOpen(modalRef)
  }

  const renderServices = (services, isOperational) => {
    return services.map((service) => ({
      services: (
        <div
          key={service.name}
          onClick={() => {
            setIsOperational(isOperational)
            handleServiceClick(service.processes)
          }}
        >
          <span>{service.name}</span>
          <span
            className='u-text--curious u-cursor--pointer u-float--right
              u-weight--medium u-padding-left-small u-padding-right-small'
            data-testid='payroll-calculation'
          >
            {t('Company.text.payroll_calculation')}: {service.processesCount}
          </span>
        </div>
      ),
    }))
  }

  const renderProcesses = () => {
    if (isTableRepresentation) return renderProcessesAsTable()
    return renderProcessesAsList()
  }

  const renderProcessLink = ({ name, id, uri, company }) => {
    return (
      <Link key={id} to={`/companies/${company.id}/dates-processes/${uri}/${id}`} className='o-block o-block--left u-text--curious u-text--overflow-ellipsis'>
        {name}
      </Link>
    )
  }

  const renderProcessesAsTable = () => {
    return (
      <PayslipTable
        data={processes.map((process) => ({
          id: process.id,
          country: process.country.abbreviature,
          company: process.company.name,
          payroll: {
            name: process.name,
            id: process.id,
            uri: process.uri,
            company: process.company,
          },
        }))}
        headings={[
          {
            accessor: 'country',
            Header: t('Global:Global.text.country'),
            disableFilters: true,
            Cell: flag,
            setWidth: '120px',
          },
          { accessor: 'company', Header: t('Global:Global.text.company'), disableFilters: true },
          {
            accessor: 'payroll',
            Header: isOperational ? t('Global:Global.text.payroll') : t('Global:Global.text.process'),
            disableFilters: true,
            Cell: processLink,
          },
        ]}
        className='u-1/1'
      />
    )
  }

  const renderProcessesAsList = () => {
    return processes.map((process) => renderProcessLink(process)).reverse()
  }

  return (
    <>
      <Modal ref={modalRef} className='c-modal c-modal--half c-modal--overflow-y' modalHeading={t('Global:Global.text.go_to')}>
        <div className='o-layout'>
          <div className='o-layout__item u-1/1 u-1/1@tablet u-1/1@desktop'>{renderProcesses()}</div>
        </div>
      </Modal>
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>
          <PayslipTable
            wrapperClassName='u-1/1'
            headings={[
              {
                accessor: 'services',
                Header: t('Company.table.headings.operational_services'),
                disableSortBy: true,
                classNames: 'u-text--center',
                disableFilters: true,
              },
            ]}
            data={renderServices(operationalServices, true)}
          />
        </div>
        {setupServices.length > 0 && (
          <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>
            <PayslipTable
              wrapperClassName='u-1/1'
              headings={[
                {
                  accessor: 'services',
                  Header: t('Company.table.headings.setup_services'),
                  disableSortBy: true,
                  classNames: 'u-text--center',
                  disableFilters: true,
                },
              ]}
              data={renderServices(setupServices, false)}
            />
          </div>
        )}
      </div>
    </>
  )
}

CompanyServicesList.propTypes = {
  operationalServices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      processes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          uri: PropTypes.string,
        })
      ),
      processesCount: PropTypes.number.isRequired,
    })
  ),
  setupServices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      processes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          uri: PropTypes.string,
        })
      ),
      processesCount: PropTypes.number.isRequired,
    })
  ),
  isTableRepresentation: PropTypes.bool,
}

export default CompanyServicesList
