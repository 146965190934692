import React from 'react'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import { download, downloadTemplateUpdate, importAccountsUpdate } from 'redux/actions/generalLedgers'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.gla_clarifications.create_multiple_accounts')} />
      <p className='u-margin-bottom-small'>{t('Import.gla_clarifications.description')}</p>
      <ul className='u-margin-bottom-small'>
        <li>{t('Import.gla_clarifications.step1')}</li>
        <li>{t('Import.gla_clarifications.step2')}</li>
        <li>{t('Import.gla_clarifications.step3')}</li>
        <li>{t('Import.gla_clarifications.step4')}</li>
      </ul>
    </div>
  )
}

const UpdateAccountsFacade = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  return (
    <ImportFacade
      {...props}
      heading={t('Import.accounts_update.heading')}
      entityName='generalLedgers'
      downloadAction={download}
      downloadTemplateAction={() => downloadTemplateUpdate()}
      importAction={({ file }) => importAccountsUpdate(file)}
      TemplateClarifications={TemplateClarifications}
      hasFilters={false}
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading text={t('Import.accounts_update.data_validations_heading')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.accounts_update.data_validations_status'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='status' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.accounts_update.data_validations_account_type'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='account-type' className='u-weight--bold' />]}
            />
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}
export default UpdateAccountsFacade
