import React from 'react'
import PropTypes from 'prop-types'
import { types } from 'redux/config/reversions'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getReversionTypeLabel = (type) => types.find((t) => t.value === type).label

export const ReversedFlag = ({ type, reason, isCompleted, instanceId }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <span
      className={`${isCompleted ? 'icon--reversion--green' : 'icon--reversion--red'}`}
      title={`${isCompleted ? t('BusinessComponent.text.reversion_complete') : `${getReversionTypeLabel(type)} / ${reason}`}`}
      data-testid={`reversedFlagId-${instanceId}`}
    />
  )
}

ReversedFlag.propTypes = {
  type: PropTypes.string,
  reason: PropTypes.string,
  isCompleted: PropTypes.bool,
  instanceId: PropTypes.number,
}

export default ReversedFlag
