import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import FieldsGroup from 'components/FieldsGroup'
import { Authorization } from 'containers/Authorization'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const GlobalDataProtectionList = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const { title, groupedFields, showAddBtn, onCreate } = props

  return (
    <div>
      <div className='o-layout o-layout--tiny u-margin-bottom-small'>
        <div className='o-layout__item u-1/1 u-1/1@tablet u-2/3@desktop'>
          <SectionHeading text={title}>
            {showAddBtn && (
              <Authorization permissions={['COUNTRYDATAPROTECTIONFIELD_EDIT']}>
                <div className='o-layout__item u-1/1 u-1/2@tablet'>
                  <div className='u-float--right'>
                    <button
                      title={t('BusinessComponent.globalDataProtectionList.button.add')}
                      onClick={() => onCreate()}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
                    >
                      <span className='icon icon--plus' />
                    </button>
                  </div>
                </div>
              </Authorization>
            )}
          </SectionHeading>
        </div>
      </div>
      {groupedFields.map((group, i) => {
        return <FieldsGroup key={group.category} category={group.category} categoryName={group.categoryName} fields={group.fields} index={i} {...props} />
      })}
    </div>
  )
}

GlobalDataProtectionList.propTypes = {
  title: PropTypes.string,
  groupedFields: PropTypes.array,
  showAddBtn: PropTypes.bool,
  onCreate: PropTypes.func,
}

export default GlobalDataProtectionList
