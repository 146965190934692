import React from 'react'
import { Trans } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const confirmMessage = (
  <span>
    <Trans
      i18nKey='BusinessComponent.modal.locked_payrun_notice_line_1'
      ns={i18nNameSpaces.BusinessComponents}
      values={{
        requiredSymbol: '',
      }}
    />
    <br />
    <br />
    <Trans i18nKey='BusinessComponent.modal.locked_payrun_notice_line_2' ns={i18nNameSpaces.BusinessComponents} />
  </span>
)
export const message = (
  <p className='u-text--italic u-text--center u-text--small'>
    <Trans
      i18nKey='BusinessComponent.modal.locked_payrun_notice_line_1'
      ns={i18nNameSpaces.BusinessComponents}
      values={{
        requiredSymbol: '*',
      }}
    />
    <br />
    <Trans i18nKey='BusinessComponent.modal.locked_payrun_notice_line_2' ns={i18nNameSpaces.BusinessComponents} />
  </p>
)

export const confirmLockedPayrunMessage = (
  <span>
    <Trans
      i18nKey='BusinessComponent.modal.locked_payrun_notice_line_1'
      ns={i18nNameSpaces.BusinessComponents}
      values={{
        requiredSymbol: '',
      }}
    />
    <br />
    <br />
    <Trans i18nKey='BusinessComponent.modal.locked_payrun_notice_line_unlock' ns={i18nNameSpaces.BusinessComponents} />
  </span>
)
