import Loader from 'components/Loader'
import React from 'react'
import PropTypes from 'prop-types'
import DashboardTileHeader from './DashboardTileHeader'
import EmptyListNotice from './EmptyListNotice'
import AssignmentCard from 'routes/Toolbox/routes/Actions/components/AssignmentCard'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const OverdueActionsListItem = ({ item }) => {
  return (
    <article>
      <AssignmentCard key={item.id} bulkArr={[]} {...item} />
    </article>
  )
}

const OverdueActionsList = ({ items }) => {
  const { t } = useTranslation([i18nNameSpaces.Dashboard])
  let newItems = [...items]
  if (newItems.length < 1) return <EmptyListNotice text={t('Dashboard.overdue_actions_notice.noOverdueActions')} />
  if (newItems.length > 3) newItems.pop()
  return (
    <ul className='o-list--striped list-unstyled u-margin-none'>
      {newItems.map((item) => (
        <OverdueActionsListItem key={item.id} item={item} />
      ))}
    </ul>
  )
}

const OverDueTitleInfo = ({ count = 0 }) => {
  const { t } = useTranslation([i18nNameSpaces.Dashboard])
  return (
    <>
      <span data-testid='section-header-title u-text--huge' title={t('Dashboard.overdue_actions_tooltip.overdueActions')}>
        {t('Dashboard.overdue_actions_title.overdueActions')}
      </span>
      {count > 0 && (
        <span className='u-text--mandy u-margin-left-small u-margin-right-small'>
          {count > 3 ? <span title={t('Dashboard.overdue_actions_tooltip.additionalOverdueActions')}>{t('Global:Global.text.moreThanThree')}</span> : count}
        </span>
      )}
    </>
  )
}

function OverdueActionsDashboardView (props) {
  const { t } = useTranslation([i18nNameSpaces.Dashboard])
  const itemCount = !props.isFetching ? props.overdueAssignments.length : 0
  const hasItems = itemCount ? 'has-items' : 'no-items'
  return (
    <div data-testid='dashboard-overdue-actions'>
      <DashboardTileHeader title={<OverDueTitleInfo count={itemCount} />} onClick={props.onNavigateToActions} buttonText={t('Global:Global.text.view')} />
      <div className={`c-draggableboard c-draggableboard-background--white dashboard-tile-container ${hasItems}`}>
        {!props.isFetching ? <OverdueActionsList items={props.overdueAssignments || []} {...props} /> : <Loader />}
      </div>
    </div>
  )
}

OverdueActionsDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigateToActions: PropTypes.func,
  overdueAssignments: PropTypes.array,
}
OverDueTitleInfo.propTypes = {
  count: PropTypes.number,
}
OverdueActionsListItem.propTypes = {
  item: PropTypes.object,
}

export default OverdueActionsDashboardView
