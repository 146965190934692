import React, { useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import PayslipTable from 'components/table/PayslipTable'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Loader } from 'components/Loader'
import ConfirmationModal from 'components/ConfirmationModal'
import { Trans, useTranslation } from 'react-i18next'
import { isNil } from 'utils/fnkit/typeChecks'
import { i18nNameSpaces } from 'i18n/types'

const getErrorsHeading = (t) => [
  {
    accessor: 'row',
    Header: t('Import.file_global.row'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'field',
    Header: t('Import.file_global.field'),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'error',
    Header: t('Import.file_global.error'),
    disableSortBy: true,
    disableFilters: true,
  },
]

const ImportFile = ({
  importFile,
  entityName,
  showConfirmation,
  url,
  errors: initialErrors,
  failure: initialFailure,
  success: initialSuccess,
  total: initialTotal,
  shouldDownloadDirectly,
  getFile,
}) => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  const [link, setLink] = useState(url)
  const [errors, setErrors] = useState(initialErrors)
  const [failure, setFailure] = useState(initialFailure)
  const [success, setSuccess] = useState(initialSuccess)
  const [total, setTotal] = useState(initialTotal)
  const [isLoading, setIsLoading] = useState(false)
  const dropzoneRef = useRef(null)
  const confirmUploadRef = useRef(null)

  const getDefaultState = () => ({
    link: url,
    errors: initialErrors,
    failure: initialFailure,
    success: initialSuccess,
    total: initialTotal,
    isLoading: false,
  })

  const onUpload = () => {
    dropzoneRef.current.open()
  }

  const renderErrors = (errors) => {
    let rows = []
    _.forOwn(errors, (er, line) => {
      _.forOwn(er, (e, field) => {
        rows.push({
          row: line,
          field,
          error: e,
        })
      })
    })
    return rows
  }

  const renderLink = () => {
    if (!shouldDownloadDirectly) {
      return (
        <span onClick={() => getFile(link)} className='u-text--curious u-cursor--pointer'>
          {t('Import.file_feedback.file.link')}
        </span>
      )
    }
    return (
      <a href={link} className='u-text--curious u-cursor--pointer'>
        {t('Import.file_feedback.file.link')}
      </a>
    )
  }

  return (
    <div className='u-margin-bottom'>
      <ConfirmationModal ref={confirmUploadRef} className='c-modal' modalHeading={t('Import.file_modal.heading.confirmation')} onConfirm={onUpload}>
        <p>{t('Import.file_modal.confirm.text')}</p>
      </ConfirmationModal>
      <Dropzone
        onDrop={(files) => {
          setIsLoading(true)
          setErrors(null)
          setLink(null)
          importFile(files[0]).then((error) => {
            setIsLoading(false)
            if (error.data && error.data.url) setLink(error.data.url)
            if (error.errors) setErrors(error.errors)
            if (error.additionalInfo) {
              setFailure(error.additionalInfo.failure)
              setSuccess(error.additionalInfo.success)
              setTotal(error.additionalInfo.total)
            }
            if (!error.errors) {
              const defaultState = getDefaultState()
              setLink(defaultState.link)
              setErrors(defaultState.errors)
              setFailure(defaultState.failure)
              setSuccess(defaultState.success)
              setTotal(defaultState.total)
              setIsLoading(defaultState.isLoading)
            }
          })
        }}
        ref={dropzoneRef}
        className='u-relative'
      />
      <button
        type='button'
        onClick={() => (showConfirmation ? confirmUploadRef.current.showModal() : onUpload())}
        className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
        title={t('Import.file_button.upload.file')}
        data-testid='upload-button'
      >
        <span className='icon icon--upload' />
      </button>
      {isLoading && <Loader />}
      {!isNil(total) && (
        <div className='u-margin-top'>
          <p>
            <Trans
              i18nKey='Import.file_feedback.summary'
              ns={i18nNameSpaces.Import}
              values={{
                total,
                entityName,
                success,
              }}
              components={[<span key='import_file_total' className='u-weight--bold' />, <span key='import_file_success' className='u-weight--bold' />]}
            />
            {failure > 0 && (
              <Trans
                i18nKey='Import.file_feedback.summary_failure'
                ns={i18nNameSpaces.Import}
                values={{
                  failure,
                }}
                components={[<span key='import_file_failure' className='u-weight--bold' />, renderLink()]}
              />
            )}
          </p>
        </div>
      )}
      {errors && (
        <PayslipTable
          wrapperClassName='u-1/1'
          tableElementClassName='u-margin-bottom-large table--layout-auto'
          headings={getErrorsHeading(t)}
          data={renderErrors(errors)}
        />
      )}
    </div>
  )
}

ImportFile.propTypes = {
  importFile: PropTypes.func,
  entityName: PropTypes.string,
  showConfirmation: PropTypes.bool,
  url: PropTypes.string,
  errors: PropTypes.object,
  failure: PropTypes.number,
  success: PropTypes.number,
  total: PropTypes.number,
  shouldDownloadDirectly: PropTypes.bool,
  getFile: PropTypes.func,
}

export default ImportFile
