import React from 'react'
import PropTypes from 'prop-types'
import { change, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import BusinessUnitsOwnersTable from './BusinessUnitsOwnersTable'
import { i18nNameSpaces } from 'i18n/types'

const MultiEditForm = ({ payrollTaskName, owners, onOwnerChange, disableDeadline, buOwnersData, isMAOEnabled, dispatch, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  return (
    <>
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <h2 className='u-text--center u-text--huge'>
            <span className='u-weight--bold'>{t('Payroll.modal.choose_owners_action', { payrollTaskName })}</span>
          </h2>
        </div>
      </div>
      <Form showSubmitBtn {...rest}>
        <div className='o-layout u-text--center'>
          <div className='o-layout__item u-1/1'>
            <Field
              name='deadline'
              label={t('Payroll.form.timeline_label')}
              labelClassName='c-label'
              component={InputField}
              type='number'
              disabled={disableDeadline}
            />
          </div>
          <div className='o-layout__item u-1/1'>
            <BusinessUnitsOwnersTable buOwnersData={buOwnersData} />
          </div>
          <div className='o-layout__item u-text--center u-1/1'>
            <span onClick={() => dispatch(change('payrollTaskEdit', 'taskableType', 'Company'))} className='u-text--small u-text--curious u-cursor--pointer'>
              {t('Payroll.table.switch_to_single_owner')}
            </span>
          </div>
        </div>
      </Form>
    </>
  )
}

MultiEditForm.propTypes = {
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  payrollTaskName: PropTypes.string,
  disableDeadline: PropTypes.bool,
  onOwnerChange: PropTypes.func,
  buOwnersData: PropTypes.array,
  isMAOEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default MultiEditForm
