import React from 'react'
import { Field } from 'redux-form'
import AmountInputField from './form/AmountInputField'
import FieldDatePicker from './form/FieldDatePicker'
import PayslipTable from './table/PayslipTable'
import { amountTransformer } from 'redux/transformers/amountsTransformer'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getTermId = (str, formFieldName) => formFieldName.replace(str, '')
const hasAnAmount = (id, values) => !!values[`deduction-${id}-amount`]
const hasValidFromDate = (id, values) => !!values[`deduction-${id}-validFrom`]
const hasValidToDate = (id, values) => !!values[`deduction-${id}-validTo`]
const getValidFromDate = (id, values) => values[`deduction-${id}-validFrom`]

const getValidationValueRequired = (t) => (value, values, props, formFieldName) => {
  let termId = getTermId('deduction-', formFieldName)
  termId = getTermId('-amount', termId)

  const hasValidFrom = hasValidFromDate(termId, values)
  const hasValidTo = hasValidToDate(termId, values)
  if ((hasValidFrom || hasValidTo) && !value) {
    return t('BusinessComponent.error.not_blank_value')
  }

  return undefined
}
const getFromDateValidation = (t) => (value, values, props, formFieldName) => {
  let termId = getTermId('deduction-', formFieldName)
  termId = getTermId('-validFrom', termId)
  const hasAmount = hasAnAmount(termId, values)
  if (hasAmount && !value) {
    return t('BusinessComponent.error.from_date_is_required')
  }

  return undefined
}

const getToDateValidation = (t) => (value, values, props, formFieldName) => {
  let termId = getTermId('deduction-', formFieldName)
  termId = getTermId('-validTo', termId)
  const hasAmount = hasAnAmount(termId, values)
  const hasValidFrom = hasValidFromDate(termId, values)
  const fromDate = getValidFromDate(termId, values)
  if (hasAmount && hasValidFrom && value && fromDate > value) {
    return t('BusinessComponent.error.date_should_be_the_same_or_after_from_Date')
  }

  return undefined
}

const RecurringDeductionsTable = ({ tableGrid, deductions, initialValues: { disabledFields = [] } = {} }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div className='o-layout '>
      <div className={`o-layout__item ${tableGrid}`}>
        <PayslipTable
          headings={[
            {
              accessor: 'companyCountryTermId',
              Header: '',
              isKey: true,
              isVisible: false,
              disableSortBy: true,
              disableFilters: true,
            },
            { accessor: 'fullName', Header: t('Global:Global.text.name'), disableSortBy: true, disableFilters: true },
            {
              accessor: 'validFrom',
              Header: t('Global:Global.text.from_date'),
              disableSortBy: true,
              disableFilters: true,
              columnClassName: 'disabled-rounded-input-border',
            },
            {
              accessor: 'validTo',
              Header: t('Global:Global.text.to_date'),
              disableSortBy: true,
              disableFilters: true,
              columnClassName: 'disabled-rounded-input-border',
            },
            {
              accessor: 'amount',
              Header: t('Global:Global.text.value'),
              disableSortBy: true,
              disableFilters: true,
              columnClassName: 'disabled-rounded-input-border',
            },
          ]}
          data={deductions.map((item) => ({
            ...item,
            validFrom: (
              <Field
                className='c-input c-input--transparent u-border-bottom--minsk'
                name={`deduction-${item.companyCountryTermId}-validFrom`}
                component={FieldDatePicker}
                formGroupClassName='u-margin-none'
                disabled={disabledFields.includes(`deduction-${item.companyCountryTermId}`)}
                validate={[getFromDateValidation(t)]}
              />
            ),
            validTo: (
              <Field
                className='c-input c-input--transparent u-border-bottom--minsk'
                name={`deduction-${item.companyCountryTermId}-validTo`}
                component={FieldDatePicker}
                formGroupClassName='u-margin-none'
                validate={[getToDateValidation(t)]}
              />
            ),
            amount: (
              <Field
                name={`deduction-${item.companyCountryTermId}-amount`}
                component={AmountInputField}
                parse={amountTransformer}
                placeholder={t('Global:Global.text.enter_value')}
                className='c-input c-input--transparent u-border-bottom--minsk'
                formGroupClassName='u-margin-none'
                validate={[getValidationValueRequired(t)]}
              />
            ),
          }))}
          wrapperClassName='u-margin-bottom u-1/1'
          trClassName='u-cursor--pointer'
          modifierClasses='react-bs-container-body'
          tableElementClassName='table--layout-auto'
          options={{
            noDataMessage: t('Global:Global.text.no_matching_records_found'),
          }}
        />
      </div>
    </div>
  )
}

RecurringDeductionsTable.propTypes = {
  tableGrid: PropTypes.any,
  deductions: PropTypes.any,
  initialValues: PropTypes.any,
}

export default RecurringDeductionsTable
