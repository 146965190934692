import React from 'react'

import i18n from 'i18next'

import { compile } from '../utils/templates'
import { types } from 'redux/config/documents'
import PayrunLink from './notifications/PayrunLink'
import PayrollLink from './notifications/PayrollLink'
import NotificationExtraLinkBuilder from 'utils/helperClasses/NotificationExtraLinkBuilder'
import { docTypeIDs, docTypeIDsRestrictedForCOTUsers } from 'utils/enums/documentTypes'
import { isEmpty } from '../utils/fnkit/typeChecks'

const timelineNavigationNotifications = ['PaydateChangedOnPayrunNotification', 'PaydateChangedOnPayrollNotification']
const actionTimelineNavigationNotifications = ['ActionTimelineChangedOnPayrollNotification', 'ActionTimelineChangedOnPayrunNotification']

// <Important note>
// This feature needs more business and design time. It was developed in hurry in the last days of the year,
// and there are many use-cases these needed to be discussed and planned better.
// Here (`buildNotification`) we handle some Notification use-cases, but again,
// we need more architectural time, in order to develop the Notifications in more flexible and scalable way.

// In 2020, we will delete all the notification on the back-end.
// Then we'll have new notifications structure and markup and we will reuse this HOC.
// For now - we just reuse Notifications building in these functions.
// Also we have started handling a specific Notification markup with `renderNewDocumentNotification`,
// but we have found out several not considered at all use-cases, and we put `renderNewDocumentNotification` on hold.
const renderNewDocumentNotification = ({ userName, fileName, users }) => {
  const renderUsers = (users) => {
    return users
      .map((user) => (
        <span>
          {i18n.t('Global.notification.forTenant', {
            userName: user.name,
            tenant: user.tenant,
          })}
        </span>
      ))
      .reduce((prev, curr) => [prev, ', ', curr])
  }

  return (
    <span>
      {i18n.t('Global.notification.FileSharedWith', {
        userName,
        fileName,
        users: renderUsers(users),
      })}
    </span>
  )
}

const renderDocument = ({ userName, fileName, fullLink, uploadedFor, downloadDocument }) => {
  return (
    <span>
      {i18n.t('Global.notification.FileSharedWithYou', {
        userName,
        fileName,
        uploadedFor,
        downloadLink: renderDownloadLink(downloadDocument, fullLink),
      })}
    </span>
  )
}

const renderDownloadLink = (downloadDocument, fullLink, text = i18n.t('Global.notification.DownloadFileText')) => (
  <span className='u-text--curious u-cursor--pointer' onClick={() => downloadDocument(fullLink)}>
    {text}
  </span>
)

const getNotificationCompiledBody = (
  notificationName,
  notification,
  downloadDocument,
  payrunLink,
  additionalText,
  isGTNFileSuccessfullyImported,
  isCotOrEngineerOrAdminUser,
  isVendor,
  cotUserSpecialRights
) => {
  if (notificationName === 'DocumentSharedNotification') {
    return (
      <div className={notification?.isRead ? 'notification-is-read' : ''}>
        {renderDocument({
          ...notification.extraData,
          downloadDocument,
        })}
      </div>
    )
  }

  if (notificationName === 'DocumentSharedNotificationCOT' && notification.typeClass === types.employee.value) {
    return (
      <div className={notification?.isRead ? 'notification-is-read' : ''}>
        {renderNewDocumentNotification(notification.template)}
        {renderDownloadLink(downloadDocument, notification.fullLink)}.
      </div>
    )
  }

  if (notificationName === 'DocumentSharedNotificationCOT' && notification.typeClass !== types.employee.value) {
    return <div className={notification?.isRead ? 'notification-is-read' : ''}>{renderNewDocumentNotification(notification.template)}</div>
  }

  if (
    (notificationName === 'DocumentGTNReportSharedNotificationCOT' || notificationName === 'DocumentGTNReportSharedNotificationER') &&
    isGTNFileSuccessfullyImported
  ) {
    const compiledBody = compile(notification.body, notification.extraData)
    return (
      <span className={notification?.isRead ? 'notification-is-read' : ''}>
        {compiledBody} {payrunLink} {additionalText}
      </span>
    )
  }

  if (
    (notificationName === 'DocumentGTNReportSharedNotificationCOT' || notificationName === 'DocumentGTNReportSharedNotificationER') &&
    !isGTNFileSuccessfullyImported
  ) {
    const compiledBody = compile(notification.body, notification.extraData)
    return (
      <span className={notification?.isRead ? 'notification-is-read' : ''}>
        {compiledBody} {additionalText} {renderDownloadLink(downloadDocument, notification.fullLink, i18n.t('Global.notification.DownloadFileText'))}
      </span>
    )
  }

  if (notificationName === 'Document') {
    const compiledBody = compile(notification.body, notification.extraData)
    // Certain document types shouldn't be available for COT Users to download.
    // Only COT bot should have download rights
    // This restriction takes out the "Download file" link for those file types
    // Because of the Wrapped component structure, using the canLoggedInUserDownloadDocumentByType
    // utility breaks the rule of hooks. To avoid refactoring the whole notificationsHOC, the logic is applied here
    // BE is not sending documentTypes in extra data for every notification, and we only need them for the restricted ones
    if (isCotOrEngineerOrAdminUser && notification.extraData.documentTypes) {
      const noUserRightForBankFile = notification.extraData.documentTypes.includes(docTypeIDs.BANK_FILE) && !cotUserSpecialRights?.bankFile
      const noUserRightForBeneficiaryFile = notification.extraData.documentTypes.includes(docTypeIDs.BENEFICIARY_FILE) && !cotUserSpecialRights?.beneficiaryFile
      const noUserRightForDataMigration = notification.extraData.documentTypes.includes(docTypeIDs.DATA_MIGRATION) && !cotUserSpecialRights?.dataMigration
      const fileIsRestrictedForCOT =
        notification.extraData.documentTypes && docTypeIDsRestrictedForCOTUsers.some((docTypeId) => notification.extraData.documentTypes.includes(docTypeId))

      if (noUserRightForBankFile || noUserRightForBeneficiaryFile || noUserRightForDataMigration || fileIsRestrictedForCOT) {
        return <div>{compiledBody}</div>
      }
    }

    return (
      <div className={notification?.isRead ? 'notification-is-read' : ''}>
        {compiledBody} {renderDownloadLink(downloadDocument, notification.fullLink)}.
      </div>
    )
  }
}

const buildNotification = (notification, downloadDocument, onRead, isCotUser, isCotOrEngineerOrAdminUser, isVendor, vendorId, cotUserSpecialRights, props) => {
  const { tenants } = props
  const getTenant = (tenants, { extraData }) => tenants.find((tenant) => tenant.name === extraData.client)
  const tenant = getTenant(tenants, notification)
  const { companyId } = notification.extraData
  const payrollInstanceId = notification.extraData.payrollInstanceId || null
  const payrollProcessId = notification.extraData.payrollProcessId || null

  // GTN Mapping Report notifications
  const isDocumentGTNReportSharedNotificationER = notification.notificationName && notification.notificationName === 'DocumentGTNReportSharedNotificationER'
  const isDocumentGTNReportSharedNotificationCOT = notification.notificationName && notification.notificationName === 'DocumentGTNReportSharedNotificationCOT'
  const isGTNFileSuccessfullyImported = notification.extraData.isSuccessful || null

  const notificationLinkBuilder = new NotificationExtraLinkBuilder({
    notification,
    notificationType: notification.notificationName,
    isVendor,
    isCotUser,
    isGTNFileSuccessfullyImported,
  })
  const isTimelineNavigation = timelineNavigationNotifications.includes(notification.notificationName)
  const isActionTimelineNavigation = actionTimelineNavigationNotifications.includes(notification.notificationName)
  const additionalText = notificationLinkBuilder.linkAdditionalText

  const payrunLink = payrollInstanceId ? (
    <PayrunLink
      isCotUser={isCotUser}
      tenant={tenant}
      companyId={companyId}
      isTimelineNavigation={isTimelineNavigation}
      isActionTimelineNavigation={isActionTimelineNavigation}
      payrollInstanceId={payrollInstanceId}
      isDocumentGTNReportSharedNotificationER={isDocumentGTNReportSharedNotificationER}
      isDocumentGTNReportSharedNotificationCOT={isDocumentGTNReportSharedNotificationCOT}
      {...props}
    />
  ) : null

  const payrollLink =
    payrollProcessId && isActionTimelineNavigation && !isVendor ? (
      <PayrollLink tenant={tenant} isCotUser={isCotUser} companyId={companyId} payrollProcessId={payrollProcessId} {...props} />
    ) : null
  let compiledBody = null

  // Shared notification, for employee document
  if (notification.notificationName === 'DocumentSharedNotification' && notification.typeClass === types.employee.value) {
    compiledBody = getNotificationCompiledBody('DocumentSharedNotification', notification, downloadDocument)
  } else if (
    notification.isTemplateNewFormat &&
    notification.notificationName === 'DocumentSharedNotificationCOT' &&
    notification.typeClass === types.employee.value
  ) {
    // Shared notification, for employee document, to COT.
    compiledBody = getNotificationCompiledBody('DocumentSharedNotificationCOT', notification, downloadDocument)
  } else if (
    notification.isTemplateNewFormat &&
    notification.notificationName === 'DocumentSharedNotificationCOT' &&
    notification.typeClass !== types.employee.value
  ) {
    // Shared notification, to COT, for any other document (different of Employee document)
    compiledBody = getNotificationCompiledBody('DocumentSharedNotificationCOT', notification, downloadDocument)
  } else if (notification.notificationName === 'DocumentGTNReportSharedNotification') {
    compiledBody = getNotificationCompiledBody('DocumentGTNReportSharedNotification', notification, downloadDocument)
  } else if (notification.notificationName === 'DocumentGTNReportSharedNotificationER') {
    compiledBody = getNotificationCompiledBody(
      'DocumentGTNReportSharedNotificationER',
      notification,
      downloadDocument,
      payrunLink,
      additionalText,
      isGTNFileSuccessfullyImported
    )
  } else if (notification.notificationName === 'DocumentGTNReportSharedNotificationCOT') {
    compiledBody = getNotificationCompiledBody(
      'DocumentGTNReportSharedNotificationCOT',
      notification,
      downloadDocument,
      payrunLink,
      additionalText,
      isGTNFileSuccessfullyImported
    )
  } else if (isEmpty(notification.extraData?.fullLink) && notification.notificationName === 'GTNValidationErrorNotification') {
    compiledBody = <span className={notification?.isRead ? 'notification-is-read' : ''}>{compile(notification.body, notification.extraData)}</span>
  } else if (!notification.isTemplateNewFormat && notification.type === 'document') {
    // For all other type of documents and use-cases.
    compiledBody = getNotificationCompiledBody(
      'Document',
      notification,
      downloadDocument,
      payrunLink,
      additionalText,
      isGTNFileSuccessfullyImported,
      isCotOrEngineerOrAdminUser,
      isVendor,
      cotUserSpecialRights
    )
  }

  // Some of the older notifications may not have payrollInstanceId property,
  // for that reason we are not showing the link for redirect
  let notificationText = (
    <span className={notification?.isRead ? 'notification-is-read' : ''}>
      {notification.body} {additionalText} {(payrollInstanceId && payrunLink) || (payrollProcessId && payrollLink)}
    </span>
  )

  if (isTimelineNavigation || isActionTimelineNavigation) {
    notificationText = (
      <span className={notification?.isRead ? 'notification-is-read' : ''}>
        {notification.body} {additionalText} {(payrollInstanceId && payrunLink) || (payrollProcessId && payrollLink)}
      </span>
    )
  }

  return {
    ...notification,
    body: compiledBody || notificationText,
    countryName: {
      name: notification.countryName,
      abbr: notification.countryAbbreviature.toLowerCase(),
    },
    status: {
      id: notification.id,
      createdAt: notification.createdAt,
      isRead: notification.isRead,
      onRead: (notificationId) => onRead(notificationId),
    },
  }
}
const buildNotifications = (props) => {
  const { notifications, downloadDocument, onRead, isCotUser, isCotOrEngineerOrAdminUser, isVendor, vendorId, cotUserSpecialRights } = props
  return notifications.map((n) =>
    buildNotification(n, downloadDocument, onRead, isCotUser, isCotOrEngineerOrAdminUser, isVendor, vendorId, cotUserSpecialRights, props)
  )
}

const NotificationsHOC = (WrappedComponent) => (props) => <WrappedComponent {...props} notifications={buildNotifications(props)} />

export default NotificationsHOC
