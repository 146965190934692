import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import Modal from 'components/Modal'
import PayslipTable from 'components/table/PayslipTable'
import { generateLinkDocsHeadings, getLinkDocOpts, getLinkDocSelectedRowProps } from './helpers/linkDocTableConfig'
import FilterButton from 'components/buttons/FilterButton'
import { downloadDocument } from 'redux/actions/document'
import { useDocument, useSelectDocuments } from 'hooks/useDocument'
import { createFilter } from 'utils/redux/filter'
import { createDocumentFilterNameById, prepareFilters } from 'redux/filters/createDocumentFilter'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const LinkDocumentModal = ({ payrollInstanceId, heading, documentModalRef }) => {
  const dispatch = useDispatch()
  const [showFilters, setShowFilters] = useState(false)
  const { t } = useTranslation(i18nNameSpaces.Payrun)

  const { docFilterNames, documents, syncDocsData } = useDocument({
    payrollInstanceId,
    filterName: createDocumentFilterNameById(payrollInstanceId),
  })
  const { addSelectedDocuments, hasSelectedDocs, onSelectDocument, resetSelectedDocuments } = useSelectDocuments()
  const data = (documents || [])?.filter((doc) => !doc.payrollSummary)

  const handleDownloadDocument = useCallback((id) => dispatch(downloadDocument(id)), [dispatch])

  const tableHeadings = generateLinkDocsHeadings({
    handleDownloadDocument,
  })
  const tableOptions = getLinkDocOpts(data)
  const selectRowProp = getLinkDocSelectedRowProps({
    onClick: onSelectDocument,
  })
  const linkSupportingDocs = async () => {
    await addSelectedDocuments()

    // Get latest docuemnts refetch
    const computedFilters = prepareFilters({
      filterNames: docFilterNames,
      props: {
        typeClass: 'payrollInstance',
        typeId: payrollInstanceId,
        payrollSummary: true,
      },
    })
    await syncDocsData({
      filter: createFilter(computedFilters, createDocumentFilterNameById(payrollInstanceId)),
    })
    documentModalRef?.current?.hideModal()
  }

  return (
    <Modal
      ref={documentModalRef}
      className='c-modal c-modal--full  c-modal--min-height--full c-modal--overflow-y c-modal-with-top-background'
      modalHeading={heading}
      onHide={resetSelectedDocuments}
    >
      <div className='u-margin-top-section'>
        <div className='u-float--right u-margin-top-neg-large'>
          <FilterButton
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left u-relative'
            onClick={() => setShowFilters(!showFilters)}
          />
        </div>
        <div className='d-flex d-flex--col jc--space-between'>
          <PayslipTable data={data} headings={tableHeadings} options={tableOptions} showFilters={showFilters} rowSelection={selectRowProp} pagination />
          <div className='o-layout__item u-text--center u-1/1'>
            <button
              className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
              disabled={!hasSelectedDocs}
              onClick={linkSupportingDocs}
            >
              {t('Global:Global.button.add')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

LinkDocumentModal.propTypes = {
  payrollInstanceId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  documentModalRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
}
