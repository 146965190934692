import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formValueSelector, getFormValues } from 'redux-form'
import { hasAccess, isAccounting, isCot, isFinanceAdmin } from 'redux/selectors/auth'
import { isFetching } from 'utils/redux/fetching'
import PayrollInstanceView from '../components/PayrollInstanceNew'
import { resetPayfilesInitials, updatePayfile, uploadArchive } from 'redux/actions/payfiles'
import { isEmpty, omit } from 'lodash'
import { buildFieldName } from '../selectors/payfile'
import { invalidatePayrollInstanceTask, mergePayrollInstanceTasks } from 'redux/actions/payrollInstanceTask'
import { createFilter } from 'utils/redux/filter'
import { showMessage } from 'redux/actions/modal'
import { getQuery } from 'utils/query'
import Fetcher from 'containers/Fetcher'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { setStatus } from 'utils/payrollInstanceStatuses'
import { startToastFileGeneration } from 'redux/actions/toasts'
import ToastLoadingViews from 'components/ToastLoadingViews'
import { toast } from 'react-toastify'
import { getNonCompletedEmptyPayFileExportJobs, getNonCompletedPayFileExportChangesJobs, getNonCompletedPayFileExportJobs } from 'redux/selectors/pollingJobs'
import { errorToString } from 'utils/apiErrors'
import Raven from 'redux/middlewares/raven'
import { resetPayfileChangesInitials } from 'redux/actions/payfileChanges'
import i18n from 'i18next'

// Pagination: How many items per page to be filtered and visible
const limit = 40
let currentData
/**
 * The main idea of this thunk function is to wrap the `filterAction`,
 * and to add additional filter parameters
 *
 * @param {Number} id
 * @param {Number} offset
 * @param {Number} limit
 * @param {Function} filterAction
 */
const filter =
  ({ id, offset, limit, filterAction, search = '' }) =>
    (dispatch, getState) => {
    // It's possible to filter the Payrun by employee, passed by query parameter
      const { employeeId } = getQuery(window.location.search)

      let params = { filters: { id, offset, limit } }
      params.filters['search'] = search

      // Filter by employee too, if `employeeId` query parameter is passed
      if (employeeId) params.filters.employee = [employeeId]

      return dispatch(filterAction(params))
    }

/**
 * Reusable Container for both Payrun and EmptyPayrun models
 *
 * Both models have the same flow of representing and updating data.
 * Because of this we create such Container abstraction.
 * Please refer to PropTypes declaration, in order to check what props have to be passed.
 */
class PayrunContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { id, filterAction, dispatch } = this.props
    dispatch(resetPayfileChangesInitials())
    dispatch(resetPayfilesInitials())
    dispatch(filter({ id, offset: 0, limit, filterAction }))
  }

  render () {
    const { isFetching, hasData, isImporting } = this.props
    if (isFetching && !hasData) return <div>Loading...</div>
    currentData = this.props.payfile
    // TODO - this may be removed, after we fix the invalidation issue after importing / uploading.
    // The invalidation issue is as follows:
    // 1. We do an import.
    // 2. The values are imported and we refetch / filter the entity.
    // 3. But after refetching, the old values are still shown, rather than showing the refetched values.
    if (isImporting) return <div>Importing ...</div>
    return <PayrollInstanceView {...this.props} />
  }
}

const mapStateToProps = (
  state,
  {
    match: { params },
    entityName,
    getEntitySelector,
    getEntityInitialsSelector,
    isAuthorizedEnabled = true,
    isSaveEnabled = true,
    isImportEnabled = true,
    isDownloadEnabled = true,
    location,
  }
) => {
  // By default we rely on `isFetching` flow.
  // For example: only if the data is fetched, then show the Component. While fetching the data show the Loader.
  // But here the case is different. Here's our goal:
  // First time fetching the data - we want to show the Loader, without mounting the child components.
  // Once the data is fetched - we stop showing the Loader and load the child components.
  // Next time we do a filtration, we keep showing the child components, without unmounting them, because of
  // performance reasons.
  // That's the reason we do short-circuit in the case we don't have any data.
  const isFiltering = isFetching([state[entityName]], true)
  const id = params.payrollInstanceId
  const payfile = getEntitySelector(state, { payrollInstanceId: id })
  const hasData = !isEmpty(payfile)
  const payrollInstanceStatus = getPayrollInstanceRef(state, { payrollInstanceId: id }).status
  const payrollInstanceTabStatus = setStatus(payrollInstanceStatus)

  if (!hasData) {
    return { isFetching: true, id, hasData }
  }
  const entitySelector = getEntityInitialsSelector(state, { payrollInstanceId: id })
  const formsValues = getFormValues('payrollInstanceForm')(state)
  const selector = formValueSelector('payrollInstanceForm')
  const currentSearch = selector(state, 'search')
  let callback = location.pathname?.indexOf('instance') !== -1 ? getNonCompletedPayFileExportJobs : getNonCompletedPayFileExportChangesJobs

  if (location.pathname?.indexOf('input-changes-file') !== -1) {
    callback = getNonCompletedEmptyPayFileExportJobs
  }
  const inProgressPollingJobs = callback(state)

  const jobForPayfile = inProgressPollingJobs.find((job) => job.entityId === parseInt(id, 10))
  return {
    id,
    payfile,
    disabledPayFileExportButton: jobForPayfile,
    currentSearch,
    initialValues: {
      ...entitySelector,
      ...formsValues,
    },
    isFetching: isFiltering,
    isImporting: state[entityName].isImporting,
    hasData,
    query: getQuery,
    pagination: {
      totalPages: Math.ceil(payfile.totalCount / limit),
      currentPage: state[entityName].filtersLegacy.offset / limit,
      limit,
    },
    // Even we define which features to be enabled with these flags, we also need to perform a permissions check too.
    isSaveEnabled: isSaveEnabled && hasAccess(state)(['PAYROLLINSTANCE_EDIT']),
    isAuthorizedEnabled: isAuthorizedEnabled && hasAccess(state)(['AUTHORIZEBUTTON_VIEW']),
    isImportEnabled,
    isDownloadEnabled,
    isCotUser: isCot(state),
    payrollInstanceTabStatus,
    hasAccessToVersioning: !isFinanceAdmin(state) && !isAccounting(state),
  }
}
let delaySearch
const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { payrollInstanceId },
    },
    history,
    filterAction,
    downloadAction,
    downloadDirectly,
    skipToastMessage = false,
    onSuccessfulUpload,
    entityName,
    onSuccessfulSubmit,
    importAction,
    getLastSavedChanges,
    setLastSavedChanges,
  },
  ...rest
) => {
  return {
    filter: (page) => dispatch(filter({ id: payrollInstanceId, offset: page * limit, limit, filterAction })),
    updateTask: (id) =>
      dispatch((dispatch, getState) => {
        const state = getState()

        return dispatch(
          mergePayrollInstanceTasks(
            {
              [id]: { status: 'completed' },
            },
            { shouldNormalize: false, shouldRefetch: false }
          )
        ).then(() => {
          dispatch(invalidatePayrollInstanceTask())

          return dispatch(
            filter({
              id: payrollInstanceId,
              offset: (state[entityName].filtersLegacy.offset / limit) * limit,
              limit,
              filterAction,
            })
          ).then(() => {
            dispatch(
              showMessage({
                body: i18n.t('Global.message.authorized_body'),
              })
            )
          })
        })
      }),
    uploadFile: (id, file) => {
      return dispatch((dispatch, getState) => {
        if (skipToastMessage) {
          return dispatch(uploadArchive(id, file))
            .then(() => onSuccessfulUpload({ history, payrollInstanceId, dispatch }))
            .catch((error) => {
              // If it's not a network request or any other that prevent the request from completing,
              // then we have a server-side or our custom error message and we show it here.
              // If we don't have `errors` then our main handler already shown
              // a message and we don't have to overwrite it here.
              // This is a corner case, because of the reason described below.
              if (error.errors) {
                return dispatch(
                  showMessage({
                    body: errorToString(error),
                  })
                )
              }

              // In the case we don't have `error.errors`, it means that the promises `then` chains don't executes
              // and only `catch` handler is invoked.
              // `fetch` documentation says that the promise will only reject on network failure or
              // if anything prevented the request from completing.
              // @url: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
              // Generally server-side errors should be monitored on the server, not here on FE.
              // But because it's hard to be tackled on back-end, we decided to hot fix and monitor it here.
              // The Raven will send the needed details as current page (payroll instance id), logged in user and etc.
              const message = 'TIMEOUT: Importing a Payfile causes a server side error (most probably a timeout error)'
              Raven.captureException(message)
            })
        }
        if (!skipToastMessage) {
          return dispatch((dispatch, getState) => {
            return dispatch(importAction(payrollInstanceId, file)).then((job) => {
              if (!skipToastMessage) {
                dispatch(startToastFileGeneration(job.id))
                toast.loading(<ToastLoadingViews job={job} />, {
                  toastId: job.id,
                  autoClose: false,
                  closeOnClick: false,
                })
              }
            })
          })
        }
      })
    },
    downloadPayFile: (id) =>
      dispatch((dispatch) => {
        const { employeeId: employee } = getQuery(location.search)
        // In the case the Payrun file is filtered by a single employee,
        // then we are filtering the downloaded file records by the employee too.
        const actionParams = { entityId: id, ...(employee ? { filters: { employee } } : {}) }
        return dispatch(downloadAction(actionParams)).then((job) => {
          dispatch(startToastFileGeneration(job.id))
          toast.loading(<ToastLoadingViews job={job} />, { toastId: job.id, autoClose: false, closeOnClick: false })
        })
      }),
    checkIfIsDirty: (_) =>
      dispatch((dispatch, getState) => {
        const state = getState()
        const data = getFormValues('payrollInstanceForm')(state)
        const initialValues = state[entityName].initialValues[payrollInstanceId].reduce((accumulator, row) => {
          Object.keys(row).forEach((field) => {
            const fieldName = buildFieldName(row.id, field)
            accumulator[fieldName] = row[field]
          })
          return accumulator
        }, {})
        // Here we will keep only the changed data
        const changes = Object.keys(omit(data, 'search')).filter((key) => initialValues[key] !== data[key])

        const lastSavedChanges = getLastSavedChanges()
        const payfileData = currentData?.data
        if (lastSavedChanges) {
          if (lastSavedChanges === data) return false
        } else if (changes.length > 0) {
          const hasChanges = changes.filter((change) => {
            const fieldData = data[change]
            const { fieldName, PIEmployeeId } = JSON.parse(change)
            const payfileRecord = payfileData.find((d) => d.id === PIEmployeeId)
            if (fieldName in payfileRecord) {
              return payfileRecord[fieldName] !== fieldData
            }
          })
          if (hasChanges.length === 0) {
            return false
          }
        }
        return changes.length > 0
      }),
    onSearch: (data) =>
      dispatch((dispatch, getState) => {
        clearTimeout(delaySearch)
        const selector = formValueSelector('payrollInstanceForm')
        const searchCriteria = selector(getState(), 'search')
        delaySearch = setTimeout(
          () =>
            dispatch(
              filter({
                id: payrollInstanceId,
                offset: 0,
                limit,
                filterAction,
                search: searchCriteria,
              })
            ),
          700
        )
      }),
    onSubmit: (data) =>
      dispatch((dispatch, getState) => {
        const state = getState()
        // TODO - after save, have to clear the initial values, because they are obsoleted
        const initialValues = state[entityName].initialValues[payrollInstanceId].reduce((accumulator, row) => {
          Object.keys(row).forEach((field) => {
            const fieldName = buildFieldName(row.id, field)
            accumulator[fieldName] = row[field]
          })

          return accumulator
        }, {})
        setLastSavedChanges(data)
        // Here we will keep only the changed data
        const changedFields = Object.keys(omit(data, 'search')).filter((key) => initialValues[key] !== data[key])

        const fieldsById = changedFields.reduce((accumulator, field) => {
          const normalized = JSON.parse(field)

          if (!accumulator[normalized.PIEmployeeId]) accumulator[normalized.PIEmployeeId] = {}

          accumulator[normalized.PIEmployeeId][normalized.fieldName] = data[field]

          return accumulator
        }, {})

        let normalized = Object.keys(fieldsById).map((id) => ({ ...fieldsById[id], id: parseInt(id) }))
        // Redux seems to be really greedy with this one.
        // It isn't a changable fields, so removing it from the payload
        normalized = normalized.map((n) => omit(n, 'endDate'))
        return dispatch(updatePayfile(normalized, payrollInstanceId, false)).then(() => {
          dispatch(
            filter({
              id: payrollInstanceId,
              offset: (state[entityName].filtersLegacy.offset / limit) * limit,
              limit,
              filterAction,
            })
          ).then(() => {
            return onSuccessfulSubmit({
              history,
              payrollInstanceId,
              dispatch,
              changedFields,
              formName: 'payrollInstanceForm',
            })
          })
        })
      }),
    dispatch,
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(PayrunContainer)

Container.propTypes = {
  // The Store's entity name, for example `payfiles, emptyPayfiles`
  entityName: PropTypes.string.isRequired,
  filterAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func.isRequired,
  downloadDirectly: PropTypes.func.isRequired,
  // The selector, that will return the Payrun ORM model
  getEntitySelector: PropTypes.func.isRequired,
  // The selector, what will return all Payrun's employees initial values,
  // according to the current applied filtration and pagination criteria.
  getEntityInitialsSelector: PropTypes.func.isRequired,
  onSuccessfulSubmit: PropTypes.func,
  // Some of PayrunContainer's Clients (the components which uses PayrunContainer),
  // should not visualize some of the features, as saving, importing and etc./
  // Because of this we have such flags.
  isSaveEnabled: PropTypes.bool,
  isAuthorizedEnabled: PropTypes.bool,
  isImportEnabled: PropTypes.bool,
}

PayrunContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dispatch: PropTypes.func,
  filterAction: PropTypes.func,
  importAction: PropTypes.func,
  isFetching: PropTypes.bool,
  hasData: PropTypes.bool,
  payfile: PropTypes.object,
  isImporting: PropTypes.bool,
}

export default Fetcher(Container, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { match }) => {
            return createFilter({
              id: match.params.payrollInstanceId,
            })
          },
        },
        disableObsoleteFlow: true,
      },
    ],
  },
])
