import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import CotUsersList from '../components/CotUsersList'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const CotUsersView = ({ onCreate, onEdit, downloadReport, ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.Cot])
  return (
    <div>
      <SectionHeading text={t('Cot.section_heading.title')}>
        <Authorization permissions={['COTUSER_CREATE']}>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <div className='u-float--right'>
              <button
                type='button'
                onClick={() => downloadReport()}
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-right-small'
                title={t('Global:Global.text.download')}
                data-testid='download'
              >
                <span className='icon icon--download' />
              </button>
              <button title={t('Cot.button.create_user')} onClick={onCreate} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'>
                <span className='icon icon--plus' data-testid='cotCreate-button' />
              </button>
            </div>
          </div>
        </Authorization>
      </SectionHeading>
      <CotUsersList onClick={(cotUserId) => onEdit(cotUserId)} {...props} />
    </div>
  )
}

CotUsersView.propTypes = {
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  downloadReport: PropTypes.func,
}

export default CotUsersView
