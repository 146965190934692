import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const DropdownIconButton = ({ buttonClass, dropdownButtonClass = '', iconClass, children }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const defaultButtonClass = 'c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }
  const { t } = useTranslation()

  return (
    <div className='dropdown-icon-container'>
      <button type='button' className={`${!buttonClass ? defaultButtonClass : buttonClass}`} title={t('Global.text.download')} onClick={handleButtonClick}>
        <span className={`icon ${iconClass}`} />
      </button>
      {isDropdownOpen && (
        <div className={`dropdown-icon-button ${dropdownButtonClass}`} onMouseLeave={handleButtonClick}>
          {children}
        </div>
      )}
    </div>
  )
}

DropdownIconButton.propTypes = {
  dropdownButtonClass: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttonClass: PropTypes.string,
}
