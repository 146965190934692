import React from 'react'
import PropTypes from 'prop-types'

export const BreakLine = ({ key }) => (
  <span key={key}>
    <br />
  </span>
)

BreakLine.propTypes = {
  key: PropTypes.string,
}
