import React, { forwardRef, useState } from 'react'

import DatePicker from 'react-datepicker'
import { useAsyncDebounce } from 'react-table'

import ColumnSelectFilter from 'components/table/ColumnSelectFilter'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { formatDate } from 'utils/date'
import { useTranslation } from 'react-i18next'

export const DefaultColumnFilter = ({ column: { filterValue, setFilter, filterOptions } }) => {
  const [value, setValue] = useState(filterValue)
  const { t } = useTranslation()
  const onChange = useAsyncDebounce((value) => {
    setFilter(value)
  }, 200)
  const placeholderText = filterOptions && filterOptions.placeholder ? filterOptions.placeholder : t('Global:Global.text.filter')
  return (
    <input
      className='filter text-filter form-control'
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value || undefined)
        onChange(e.target.value || undefined)
      }}
      placeholder={placeholderText}
    />
  )
}

export const SelectColumnFilter = ({ column: { setFilter, dropDownOptions, filterValue } }) => {
  const className = dropDownOptions?.className || ''

  return (
    <ColumnSelectFilter
      className={className}
      filterHandler={setFilter}
      name={dropDownOptions.name}
      options={dropDownOptions.options}
      multi={dropDownOptions.multi}
      initialValue={dropDownOptions.initialValue}
      filterValue={filterValue}
    />
  )
}

const CustomInput = forwardRef((props) => <input className='filter text-filter form-control' {...props} readOnly />)

export const DatePickerColumnFilter = ({ column: { setFilter, datePickerOptions } }) => {
  const [value, setValue] = useState(null)

  const handleChange = (newDate) => {
    const filterDate = newDate ? formatDate(newDate, dateFormatEnums.AcceptedByBackend) : undefined
    setValue(newDate)
    setFilter(filterDate)
  }
  return (
    <div className='datepicker-column-filter'>
      <DatePicker
        {...datePickerOptions}
        onChange={handleChange}
        className='c-input normal-font-weight'
        selected={value}
        dateFormat={dateFormatEnums.DefaultDateFormatWithoutTime}
        autoComplete='off'
        placeholderText={datePickerOptions?.placeholder ?? null}
        customInput={<CustomInput />}
      />
    </div>
  )
}
