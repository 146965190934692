import React from 'react'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import { createFilter } from 'utils/redux/filter'
import { toastEntityTypesEnum } from 'utils/enums/toastEntityTypesEnum'
import { useDispatch, useSelector } from 'react-redux'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { toast } from 'react-toastify'
import { isCot } from 'redux/selectors/auth'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PayrunFileToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.error_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_payrun_file.file_name')}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const PayrunVarianceToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.error_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_reconciliation.file_name')}
      </span>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const ElementVarianceToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-element-variance-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.error_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_element_reconciliation.file_name')}
      </span>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const PayrunFileImportToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.error_import_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_payrun_file.file_name')}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const ReportCreateFailedToast = ({ job }) => {
  const builder = new ReportItemBuilder()
  const feCategoryId = job.filters.feCategoryId
  const reportName = builder.getReportNameCategory(feCategoryId)
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.error_prefix')} <strong>{reportName}</strong>
        {t('BusinessComponent.toast_reports.report')}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(job.id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

export const GenericFailedToast = ({ id, name }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.error_prefix')}
        <strong>{name} </strong>
        {t('BusinessComponent.toast_reports.report')}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

export const UnexpectedErrorToast = ({ id }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>{t('BusinessComponent.toast.unexpected_error')}</span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}
export const EmployeeImportFailedToast = ({ id }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>{t('BusinessComponent.toast_employees.import_failed')}</span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const FetchedPayfileExportView = Fetcher(PayrunFileToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedPayfileImportView = Fetcher(PayrunFileImportToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedPayrunVarianceView = Fetcher(PayrunVarianceToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedElementVarianceView = Fetcher(ElementVarianceToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const ToastFailedViews = ({ job, cb, baseApiUsage }) => {
  let View = null
  const state = useSelector((state) => state)
  const isCotUser = useSelector((state) => isCot(state))
  if (!state.tenants.url && isCotUser) {
    View = <UnexpectedErrorToast id={job.id} baseApiUsage={baseApiUsage} />
  }
  if (state.tenants.url || !isCotUser) {
    if (job.type === toastEntityTypesEnum.PAYFILE_EXPORT_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.PAYFILE_CHANGES_EXPORT_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.EMPTY_PAYFILE_EXPORT_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.PAYFILE_EXPORT_ARCHIVE_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.PAYFILE_IMPORT_JOB) {
      View = <FetchedPayfileImportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.VARIANCE_EXPORT_JOB) {
      View = <FetchedPayrunVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.ELEMENT_VARIANCE_EXPORT_JOB) {
      View = <FetchedElementVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.REPORT_CREATE_JOB) {
      View = <ReportCreateFailedToast job={job} />
    }
    if (job.type === toastEntityTypesEnum.EMPLOYEE_BULK_CREATE_JOB || job.type === toastEntityTypesEnum.EMPLOYEE_BULK_UPDATE_JOB) {
      View = <EmployeeImportFailedToast id={job.id} name={job.name} />
    }
  }
  return View
}

ToastFailedViews.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.any.isRequired,
    name: PropTypes.string,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
}

PayrunFileToast.propTypes = {
  payrollInstance: PropTypes.shape({
    shortName: PropTypes.string.isRequired,
  }).isRequired,
}

ReportCreateFailedToast.propTypes = {
  job: PropTypes.shape({
    filters: PropTypes.shape({
      feCategoryId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

GenericFailedToast.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

UnexpectedErrorToast.propTypes = {
  id: PropTypes.string.isRequired,
}

EmployeeImportFailedToast.propTypes = {
  id: PropTypes.string.isRequired,
}

export default ToastFailedViews
