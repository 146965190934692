import { t } from 'i18next'

/**
 * Build share button label based on type of shared items
 *
 * @param {String} type
 * @returns {String}
 */
export const buildShareBtnLabel = (type) => {
  return ['Note', 'PayrollInstanceTask'].includes(type) ? t('Global:Global.button.notify') : t('Global:Global.button.save_sharing_preferences')
}
