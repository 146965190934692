import React from 'react'
import Flag from 'components/Flag'
import { Link } from 'react-router-dom'
import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const classNames = require('classnames')

export const AssignmentCard = ({
  id,
  name,
  serviceName,
  processName,
  shortProcessName,
  stepName,
  onClick,
  owner,
  ownerInitials,
  status,
  flag,
  deadline,
  completionDate,
  finishTaskLink,
  company,
  country,
  bulkArr,
  draggable,
  overdueDays,
  extraData,
}) => {
  const { t } = useTranslation([i18nNameSpaces.Toolbox])
  const lockPayrollInstanceToUpdateTask = extraData?.lockPayrollInstanceToUpdateTask
  return (
    <div
      className={classNames({
        'c-card': true,
        'c-card--selected': bulkArr.find((i) => i === id),
        'u-cursor--not-allowed': !draggable,
        'is-frozen': lockPayrollInstanceToUpdateTask,
      })}
      aria-label={`${lockPayrollInstanceToUpdateTask ? t('Toolbox.actions.lock_payroll_instance_to_update_task') : ''}`}
    >
      <div className='o-media o-grid o-grid--middle u-padding-tiny u-text--valhalla' onClick={onClick}>
        <div className='o-media__img u-margin-right-small'>
          <Flag flag={flag} size='small' classes='u-margin-tiny' title={`${country} - ${company}`} />
        </div>
        <div className='o-media__body u-padding-right-small u-1/2'>
          <span className='o-block o-block--left u-text--normal' title={`${country} - ${company}`}>
            {serviceName}
          </span>
          {finishTaskLink ? (
            <Link
              to={finishTaskLink}
              className='o-block--inline-block o-block--left
             u-text--normal u-weight--bold u-text--curious line-height--17'
            >
              {name}
            </Link>
          ) : (
            <span>{name}</span>
          )}
          <span className='o-block o-block--left u-text--normal line-height--17 u-weight--medium'>{stepName}</span>
          <span className='o-block o-block--left u-text--small u-weight--regular u-float--left u-margin-right-large line-height--17'>{deadline}</span>
          <span className='o-block o-block--left u-text--small u-weight--regular u-float--left line-height--17'>{completionDate}</span>
        </div>
        <div className='c-card__info'>
          <span className='o-block o-block--left u-text--normal u-weight--bold' title={owner}>
            {owner !== 'Company' ? ownerInitials : null}
          </span>
          <span className='o-block o-block--left u-text--normal u-weight--medium' title={processName}>
            {shortProcessName}
          </span>
          <span className='o-block o-block--left u-text--normal' title={upperFirst(status)}>
            {status === 'reverted' ? <span className='u-text--white u-bg--mandy u-padding-left-tiny u-padding-right-tiny'>!</span> : null}
          </span>
          {overdueDays && (
            <span className='o-block o-block--left u-text--normal'>
              <span className='u-text--mandy'>{overdueDays}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

AssignmentCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  serviceName: PropTypes.string,
  processName: PropTypes.string,
  shortProcessName: PropTypes.string,
  stepName: PropTypes.string,
  owner: PropTypes.string,
  ownerInitials: PropTypes.string,
  status: PropTypes.string,
  flag: PropTypes.string,
  deadline: PropTypes.string,
  completionDate: PropTypes.string,
  finishTaskLink: PropTypes.string,
  company: PropTypes.string,
  country: PropTypes.string,
  bulkArr: PropTypes.array,
  draggable: PropTypes.bool,
  overdueDays: PropTypes.string,
  onClick: PropTypes.func,
  extraData: PropTypes.shape({
    lockPayrollInstanceToUpdateTask: PropTypes.bool,
  }),
}

export default AssignmentCard
