import React, { useCallback, useRef, useState } from 'react'
import { change, Field } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Form from 'components/form/Form'
import CheckboxField from 'components/form/CheckboxField'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import { emailValidation, phoneValidation, required } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import CheckboxOption from 'components/form/select/CheckboxOption'
import { getSelectionOptions } from 'utils/selectOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { sortByLabel } from 'utils/strings'
import { isRoleCotBot, isRoleCotUser } from 'utils/roles'
import SectionHeading from 'components/SectionHeading'
import { getMode2faEmailOption } from 'redux/config/DropDownOptions/mode2faOptions'
import { ROLE_COT_BOT, ROLE_COT_OEM } from 'redux/config/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const REPORT_DESIGNER_UNCHECKED_ROLES = [ROLE_COT_OEM, ROLE_COT_BOT]

const requiredSymbol = '*'
const CotUserForm = (props) => {
  const {
    formName,
    resetForm,
    countries,
    hasCredentials,
    resetUserPassword,
    workEmail,
    isMobilePhoneFieldDisabled,
    timezones,
    parentCompaniesTenants,
    cotRoleTypes,
    roleType,
    isPristine,
    isCotAdmin,
    isSSOEnabled,
  } = props

  const dispatch = useDispatch()
  const confirmUserPasswordReset = useRef(null)
  const [cotUserEmail, setCotUserEmail] = useState(null)
  const handleRoleChange = useCallback(
    (roleType) => {
      const isCheckedRole = !REPORT_DESIGNER_UNCHECKED_ROLES.includes(roleType)
      resetForm?.()
      dispatch(change(formName, 'reportDesigner', isCheckedRole))
    },
    [resetForm]
  )
  const { t } = useTranslation([i18nNameSpaces.Cot])

  return (
    <>
      <Form {...props} pristine={isPristine}>
        <div className='o-layout'>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='firstname'
                label={t('Cot.user_form.firstname', { requiredSymbol })}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={required}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='surname'
                label={t('Cot.user_form.lastname', { requiredSymbol })}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={required}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='title'
                label={t('Cot.user_form.title')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                data-testid='title'
                options={getSelectionOptions('title')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='country'
                label={t('Cot.user_form.country')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={t('Global:Global.select.placeholder')}
                options={countries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))
                  .sort(sortByLabel)}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='timezone'
                label={t('Cot.user_form.timezone')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={t('Global:Global.select.placeholder')}
                options={timezones.map((timezone) => ({
                  value: timezone.id,
                  label: timezone.zone,
                }))}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='position'
                label={t('Cot.user_form.position')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='department'
                label={t('Cot.user_form.department')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workEmail'
                label={t('Cot.user_form.workEmail', { requiredSymbol })}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='personalEmail'
                label={t('Cot.user_form.personal_email')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='office-phone-wrapper'>
              <Field
                name='officePhone'
                label={t('Cot.user_form.office_phone')}
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='mobile-phone-wrapper'>
              <Field
                name='mobilePhone'
                label={t('Cot.user_form.mobile_phone')}
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
                disabled={isMobilePhoneFieldDisabled}
                disabledReason={t('Cot.user_form.disabled_reason')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='skype'
                label={t('Cot.user_form.skype')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='gender'
                label={t('Cot.user_form.gender')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={[
                  { value: 'male', label: t('Global:Global.gender.male') },
                  { value: 'female', label: t('Global:Global.gender.female') },
                  { value: 'not stated', label: t('Global:Global.gender.not_stated') },
                ]}
              />
            </div>
          </div>
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='hasCredentials'
                label={t('Cot.user_form.account_status')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={[
                  { value: true, label: t('Global:Global.text.active') },
                  { value: false, label: t('Global:Global.text.inactive') },
                ]}
              />
            </div>
          </div>
          {hasCredentials && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='username'
                  label={t('Cot.user_form.username')}
                  component={InputField}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                />
              </div>
            </div>
          )}
          {!isSSOEnabled && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='mode2fa'
                  label={t('Cot.user_form.mode2fa')}
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder={t('Global:Global.select.placeholder')}
                  options={[...getSelectionOptions('mode2fa'), getMode2faEmailOption()]}
                />
              </div>
            </div>
          )}
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='receivesNotifications'
                label={t('Cot.user_form.receives_notifications')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                clearable={false}
                options={[
                  { value: 'yes', label: t('Global:Global.button.yes') },
                  { value: 'no', label: t('Global:Global.text.no') },
                ]}
              />
            </div>
          </div>
          {hasCredentials && !isSSOEnabled && (
            <Authorization permissions={['COTUSER_EDIT']}>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-padding-top u-padding-bottom o-grid o-grid--medium'>
                  <div
                    onClick={() => {
                      setCotUserEmail({ email: workEmail })
                      confirmUserPasswordReset.current.showModal()
                    }}
                    className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
                    data-testid='reset-user-password'
                  >
                    {t('Cot.text.reset_password')}
                  </div>
                </div>
              </div>
            </Authorization>
          )}
        </div>
        {isCotAdmin && (
          <div>
            <div className='o-layout'>
              <div className='o-layout__item u-text--center u-1/1'>
                <h2 className='u-text--center u-text--huge u-weight--bold'>{t('Cot.user_form.choose_rights')}</h2>
              </div>
            </div>
            <div className='o-layout'>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    name='roleType'
                    label={t('Cot.user_form.role', { requiredSymbol })}
                    labelClassName='c-label u-text--small u-text--curious'
                    className='c-custom-select--transparent c-custom-select--specific-normal'
                    formGroupClassName='u-margin-none'
                    component={CustomSelectField}
                    placeholder={t('Global:Global.select.placeholder')}
                    options={cotRoleTypes.map((roleType) => ({
                      value: roleType.type,
                      label: roleType.name,
                    }))}
                    validate={required}
                    onChanged={handleRoleChange}
                    data-testid='role-wrapper'
                  />
                </div>
              </div>
              {isRoleCotUser(roleType) && (
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      name='accessAreaParentCompany'
                      label={t('Cot.user_form.access_areas', { requiredSymbol })}
                      labelClassName='c-label u-text--small u-text--curious'
                      className='c-custom-select--transparent c-custom-select--specific-normal'
                      formGroupClassName='u-margin-none'
                      component={CustomSelectField}
                      optionComponent={CheckboxOption}
                      placeholder={t('Global:Global.select.placeholder')}
                      options={parentCompaniesTenants.map((tenant) => ({
                        value: tenant.id,
                        label: tenant.name,
                      }))}
                      validate={required}
                      multi
                      data-testid='accessAreaParentCompany-wrapper'
                    />
                  </div>
                </div>
              )}
            </div>
            {!isRoleCotBot(roleType) && (
              <>
                <div className='u-text--center u-padding-top'>
                  <SectionHeading text={t('Cot.user_form.special_rights')} tagFullWidth tagTestId='heading-special-rights' />
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop u-padding-left-none'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='beneficiaryFile'
                      label={t('Cot.user_form.beneficiary_file')}
                      labelClassName='c-label u-text--small u-text--curious'
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='beneficiary-file'
                    />
                  </div>
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='bankFile'
                      label={t('Cot.user_form.bank_file')}
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='bank-file'
                    />
                  </div>
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='dataMigration'
                      label={t('Cot.user_form.data_migration')}
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='data-migration'
                    />
                  </div>
                </div>
                <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      type='checkbox'
                      name='reportDesigner'
                      label={t('Cot.user_form.report_designer')}
                      formGroupClassName='u-margin-none'
                      component={CheckboxField}
                      className='control__indicator__input'
                      indicatorClassName='control__indicator'
                      data-testid='reportDesigner'
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <Authorization permissions={['COTUSER_EDIT', 'COTUSER_DELETE']}>
          <div className='o-layout'>
            <div className='o-layout__item u-text--center u-1/1'>
              <button
                type='submit'
                className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
              u-padding-right u-margin-top-small'
                disabled={isPristine}
                data-testid='submit'
              >
                {t('Global:Global.text.save')}
              </button>
            </div>
          </div>
        </Authorization>
      </Form>

      <ConfirmationModal
        ref={confirmUserPasswordReset}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => resetUserPassword(cotUserEmail)}
      >
        <p>{t('Cot.modal.reset_passwor')}</p>
      </ConfirmationModal>
    </>
  )
}

CotUserForm.propTypes = {
  formName: PropTypes.string.isRequired,
  isCotAdmin: PropTypes.bool,
  resetForm: PropTypes.func,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      zone: PropTypes.string.isRequired,
    })
  ),
  parentCompaniesTenants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  cotRoleTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  roleType: PropTypes.string,
  hasCredentials: PropTypes.bool,
  resetUserPassword: PropTypes.func,
  workEmail: PropTypes.string,
  isMobilePhoneFieldDisabled: PropTypes.bool,
  isPristine: PropTypes.bool,
}

export default CotUserForm
