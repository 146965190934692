// Translation keys object
import { i18nNameSpaces } from 'i18n/types'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PayslipTable from 'components/table/PayslipTable'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'

const BusinessUnitsOwnersTable = ({ buOwnersData, ownersProps = {} }) => {
  const { t } = useTranslation([i18nNameSpaces.Company])

  return (
    <PayslipTable
      headings={[
        {
          accessor: 'businessUnit',
          Header: t('Company.text.business_unit_name'),
          disableSortBy: true,
          disableFilters: true,
          columnClassName: 'text-left',
        },
        {
          accessor: 'actionOwners',
          Header: t('Global.text.action_owner'),
          disableSortBy: true,
          disableFilters: true,
          columnClassName: 'u-overflow-visible text-center',
        },
      ]}
      data={buOwnersData.map((d) => ({
        id: d.id,
        businessUnit: d.businessUnit,
        actionOwners: (
          <Field
            className='c-custom-select--transparent'
            name={`actionOwnerBu-${d.id}`}
            formGroupClassName='u-margin-none'
            component={CustomSelectField}
            placeholder={t('Global.select.placeholder')}
            options={d.actionOwners.map((owner) => ({
              value: owner.id,
              label: owner.name,
            }))}
            {...ownersProps}
          />
        ),
      }))}
      modifierClasses='react-bs-container-body'
      wrapperClassName='u-1/1 u-margin-top u-margin-bottom'
      tableElementClassName='table--layout-auto'
      trClassName='react-bs-table__cell--whitespace--normal'
    />
  )
}

BusinessUnitsOwnersTable.propTypes = {
  buOwnersData: PropTypes.array.isRequired,
  ownersProps: PropTypes.object,
}

export default BusinessUnitsOwnersTable
