import { getReportGroupingDescription, getReportGroupingLabels } from '../enums/reportGroupings'
import { getReportOptionsConfig } from 'configs/reports'
import ReportItemDetailBuilder from './ReportItemDetailBuilder'

class ReportItemBuilder {
  constructor (type = null) {
    this.type = type
    this.reports = []
    this.mappedReports = {}
    this.reportGroupLabels = getReportGroupingLabels()
    this.reportGroupDescriptions = getReportGroupingDescription()
    this.reportsOptionsConfig = getReportOptionsConfig()

    this.buildReportItems()
    this.buildMappedReports()
  }

  buildReportItems = () => {
    switch (this.type) {
    case this.reportGroupLabels.HR:
      this.reports = [
        this.reportsOptionsConfig.GLOBAL_WORK_HEADCOUNT,
        this.reportsOptionsConfig.GLOBAL_JOINERS_HEADCOUNT,
        this.reportsOptionsConfig.GLOBAL_LEAVERS_HEADCOUNT,
        this.reportsOptionsConfig.WORKFORCE_HEADCOUNT,
        this.reportsOptionsConfig.JOINERS_HEADCOUNT,
        this.reportsOptionsConfig.LEAVERS_HEADCOUNT,
        this.reportsOptionsConfig.WORKFORCE_DETAIL,
        this.reportsOptionsConfig.JOINERS_DETAIL,
        this.reportsOptionsConfig.LEAVERS_DETAIL,
        this.reportsOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT,
        this.reportsOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_REPORT,
        this.reportsOptionsConfig.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT,
      ]
      break
    case this.reportGroupLabels.FINANCE:
      this.reports = [
        this.reportsOptionsConfig.GLOBAL_TOTAL_EMPLOYER_COSTS,
        this.reportsOptionsConfig.GLOBAL_PAYROLL_ELEMENT_COSTS,
        this.reportsOptionsConfig.GLOBAL_PAY_ELEMENT_COSTS,
        this.reportsOptionsConfig.GLOBAL_EMPLOYEE_DEDUCTION_COSTS,
        this.reportsOptionsConfig.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS,
        this.reportsOptionsConfig.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS,
        this.reportsOptionsConfig.TOTAL_EMPLOYER_COSTS,
        this.reportsOptionsConfig.PAYROLL_ELEMENT_COSTS,
        this.reportsOptionsConfig.TOTAL_PAY_ELEMENT_COSTS,
        this.reportsOptionsConfig.TOTAL_EMPLOYEE_DEDUCTION_COSTS,
        this.reportsOptionsConfig.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS,
        this.reportsOptionsConfig.TOTAL_EMPLOYER_CONTRIBUTION_COSTS,
        this.reportsOptionsConfig.EMPLOYER_COSTS_DETAIL,
        this.reportsOptionsConfig.PAYROLL_ELEMENT_COSTS_DETAIL,
        this.reportsOptionsConfig.PAY_ELEMENTS_COSTS_DETAIL,
        this.reportsOptionsConfig.EMPLOYEE_DEDUCTION_COSTS_DETAIL,
        this.reportsOptionsConfig.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL,
        this.reportsOptionsConfig.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL,
        this.reportsOptionsConfig.ORG_UNIT_COSTS,
        this.reportsOptionsConfig.VARIANCE_REPORT,
        this.reportsOptionsConfig.ORG_UNIT_VARIANCE,
      ]
      break
    case this.reportGroupLabels.PAYROLL:
      this.reports = [
        this.reportsOptionsConfig.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY,
        this.reportsOptionsConfig.PAYRUN_MANAGEMENT_REPORT,
        this.reportsOptionsConfig.PAYRUN_REPORT,
        this.reportsOptionsConfig.PAYROLL_CHANGES_REPORT,
        this.reportsOptionsConfig.WORKFORCE_VARIANCE,
      ]
      break
    case this.reportGroupLabels.CONTROL_AND_GOVERNANCE:
      this.reports = [
        this.reportsOptionsConfig.GLOBAL_WORKFLOW_AUDIT,
        this.reportsOptionsConfig.GLOBAL_PAY_AND_TAXES_REPORT,
        this.reportsOptionsConfig.GLOBAL_ACCESS_MANAGEMENT,
        this.reportsOptionsConfig.WORKFLOW_CALENDAR,
        this.reportsOptionsConfig.WORKFLOW_AUDIT,
        this.reportsOptionsConfig.PAYROLL_WORKFLOW_AUDIT,
        this.reportsOptionsConfig.ACCESS_MANAGEMENT,
        this.reportsOptionsConfig.CHANGE_LOG,
        this.reportsOptionsConfig.CHANGE_LOG_PAYROLL_DATA,
        this.reportsOptionsConfig.CHANGE_LOG_GENERAL_UPDATES,
        this.reportsOptionsConfig.ROLES_AND_RIGHTS_CHANGE_LOG,
        this.reportsOptionsConfig.PAY_AND_TAXES_REPORT,
        this.reportsOptionsConfig.PAY_ELEMENTS_LIST,
        this.reportsOptionsConfig.EMPLOYEE_DEDUCTIONS_LISTING,
        this.reportsOptionsConfig.EMPLOYEE_NET_DEDUCTIONS_LISTING,
        this.reportsOptionsConfig.EMPLOYER_CONTRIBUTIONS_LISTING,
      ]
      break
    default:
      break
    }
  }

  get reportTypes () {
    return this.reports
  }

  get title () {
    let title = ''
    switch (this.type) {
    case this.reportGroupLabels.HR:
      title = this.reportGroupLabels.HR
      break
    case this.reportGroupLabels.FINANCE:
      title = this.reportGroupLabels.FINANCE
      break
    case this.reportGroupLabels.PAYROLL:
      title = this.reportGroupLabels.PAYROLL
      break
    case this.reportGroupLabels.CONTROL_AND_GOVERNANCE:
      title = this.reportGroupLabels.CONTROL_AND_GOVERNANCE
      break
    default:
      break
    }
    return title
  }

  get description () {
    let description = ''
    switch (this.type) {
    case this.reportGroupLabels.HR:
      description = this.reportGroupDescriptions.HR
      break
    case this.reportGroupLabels.FINANCE:
      description = this.reportGroupDescriptions.FINANCE
      break
    case this.reportGroupLabels.PAYROLL:
      description = this.reportGroupDescriptions.PAYROLL
      break
    case this.reportGroupLabels.CONTROL_AND_GOVERNANCE:
      description = this.reportGroupDescriptions.CONTROL_AND_GOVERNANCE
      break
    default:
      break
    }
    return description
  }

  /**
   * Method to build up the reverse list when needing to find a reports category
   */
  buildMappedReports = () => {
    this.mappedReports[this.reportGroupLabels.REPORT_DESIGNER] = [this.reportsOptionsConfig.CUSTOM_BUILD_REPORT.VALUE]
    this.mappedReports[this.reportGroupLabels.HR] = [
      this.reportsOptionsConfig.GLOBAL_WORK_HEADCOUNT.VALUE,
      this.reportsOptionsConfig.GLOBAL_JOINERS_HEADCOUNT.VALUE,
      this.reportsOptionsConfig.GLOBAL_LEAVERS_HEADCOUNT.VALUE,
      this.reportsOptionsConfig.WORKFORCE_HEADCOUNT.VALUE,
      this.reportsOptionsConfig.JOINERS_HEADCOUNT.VALUE,
      this.reportsOptionsConfig.LEAVERS_HEADCOUNT.VALUE,
      this.reportsOptionsConfig.WORKFORCE_DETAIL.VALUE,
      this.reportsOptionsConfig.JOINERS_DETAIL.VALUE,
      this.reportsOptionsConfig.LEAVERS_DETAIL.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_REPORT.VALUE,
    ]
    this.mappedReports[this.reportGroupLabels.FINANCE] = [
      this.reportsOptionsConfig.ORG_UNIT_VARIANCE.VALUE,
      this.reportsOptionsConfig.ORG_UNIT_COSTS.VALUE,
      this.reportsOptionsConfig.VARIANCE_REPORT.VALUE,
      this.reportsOptionsConfig.GLOBAL_TOTAL_EMPLOYER_COSTS.VALUE,
      this.reportsOptionsConfig.TOTAL_EMPLOYER_COSTS.VALUE,
      this.reportsOptionsConfig.EMPLOYER_COSTS_DETAIL.VALUE,
      this.reportsOptionsConfig.GLOBAL_PAY_ELEMENT_COSTS.VALUE,
      this.reportsOptionsConfig.TOTAL_PAY_ELEMENT_COSTS.VALUE,
      this.reportsOptionsConfig.PAY_ELEMENTS_COSTS_DETAIL.VALUE,
      this.reportsOptionsConfig.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE,
      this.reportsOptionsConfig.TOTAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE,
      this.reportsOptionsConfig.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL.VALUE,
      this.reportsOptionsConfig.GLOBAL_EMPLOYEE_DEDUCTION_COSTS.VALUE,
      this.reportsOptionsConfig.TOTAL_EMPLOYEE_DEDUCTION_COSTS.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_DEDUCTION_COSTS_DETAIL.VALUE,
      this.reportsOptionsConfig.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE,
      this.reportsOptionsConfig.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL.VALUE,
      this.reportsOptionsConfig.GLOBAL_PAYROLL_ELEMENT_COSTS.VALUE,
      this.reportsOptionsConfig.PAYROLL_ELEMENT_COSTS.VALUE,
      this.reportsOptionsConfig.PAYROLL_ELEMENT_COSTS_DETAIL.VALUE,
    ]
    this.mappedReports[this.reportGroupLabels.PAYROLL] = [
      this.reportsOptionsConfig.PAYROLL_CHANGES_REPORT.VALUE,
      this.reportsOptionsConfig.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY.VALUE,
      this.reportsOptionsConfig.PAYRUN_MANAGEMENT_REPORT.VALUE,
      this.reportsOptionsConfig.PAYRUN_REPORT.VALUE,
      this.reportsOptionsConfig.WORKFORCE_VARIANCE.VALUE,
    ]
    this.mappedReports[this.reportGroupLabels.CONTROL_AND_GOVERNANCE] = [
      this.reportsOptionsConfig.GLOBAL_WORKFLOW_AUDIT.VALUE,
      this.reportsOptionsConfig.WORKFLOW_CALENDAR.VALUE,
      this.reportsOptionsConfig.WORKFLOW_AUDIT.VALUE,
      this.reportsOptionsConfig.PAYROLL_WORKFLOW_AUDIT.VALUE,
      this.reportsOptionsConfig.CHANGE_LOG.VALUE,
      this.reportsOptionsConfig.CHANGE_LOG_PAYROLL_DATA.VALUE,
      this.reportsOptionsConfig.CHANGE_LOG_GENERAL_UPDATES.VALUE,
      this.reportsOptionsConfig.ROLES_AND_RIGHTS_CHANGE_LOG.VALUE,
      this.reportsOptionsConfig.GLOBAL_PAY_AND_TAXES_REPORT.VALUE,
      this.reportsOptionsConfig.PAY_AND_TAXES_REPORT.VALUE,
      this.reportsOptionsConfig.PAY_ELEMENTS_LIST.VALUE,
      this.reportsOptionsConfig.EMPLOYER_CONTRIBUTIONS_LISTING.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_DEDUCTIONS_LISTING.VALUE,
      this.reportsOptionsConfig.EMPLOYEE_NET_DEDUCTIONS_LISTING.VALUE,
      this.reportsOptionsConfig.GLOBAL_ACCESS_MANAGEMENT.VALUE,
      this.reportsOptionsConfig.ACCESS_MANAGEMENT.VALUE,
    ]
  }

  getLockedReportTypes = (reportType) => {
    if (!reportType) return []

    const reportLockedItemBuilder = new ReportItemDetailBuilder(reportType)
    return reportLockedItemBuilder.lockedInItems
  }

  /**
   * Method to retrieve which category the report has been sorted into
   * @param {reportType} reportType int
   * @returns The mapped report category name based on the report type
   */
  getReportTypeCategory = (reportType) => {
    for (const [key, value] of Object.entries(this.mappedReports)) {
      if (value.includes(reportType)) {
        return key
      }
    }
  }

  /**
   * Method to retrieve the display name for any given report
   * @param {reportType} reportType int
   * @returns The FE label name for the report
   */
  getReportNameCategory = (reportType) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(this.reportsOptionsConfig)) {
      if (value.VALUE === reportType) {
        return value.LABEL
      }
    }
  }

  /**
   * Retrieves the metadata for a specific report type.
   *
   * @param {string} reportType - The type of the report to retrieve metadata for.
   * @returns {Object} An object containing the key, label, description, and value of the report if found, otherwise an empty object.
   */
  getReportMetadata = (reportType) =>
    Object.entries(this.reportsOptionsConfig).reduce((acc, [key, data]) => {
      if (data.VALUE === reportType) {
        acc = {
          key,
          label: data.LABEL,
          description: data.DESCRIPTION,
          value: data.VALUE,
        }
      }
      return acc
    }, {})

  getReportGraphsByCategoryId = (reportType) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(this.reportsOptionsConfig)) {
      if (value.VALUE === reportType) {
        return value.REPORT_CHARTS
      }
    }
  }

  getReportGraphsLogicByCategoryId = (reportType) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(this.reportsOptionsConfig)) {
      if (value.VALUE === reportType) {
        return value.CHART_LOGIC
      }
    }
  }
}

export default ReportItemBuilder
