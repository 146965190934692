import React from 'react'
import { useTranslation } from 'react-i18next'
import PayslipTable from 'components/table/PayslipTable'
import PropTypes from 'prop-types'
import { i18nNameSpaces } from 'i18n/types'

const VariablePayElements = ({ variableTerms }) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])

  /**
   * Build rows for variable pay elements
   * @param {Array} variableTerms
   * @return {Array} [ row ]
   */
  const renderRows = (variableTerms) => {
    return variableTerms.map((term) => {
      return {
        title:
          (term.shouldOnlyUseGlobalName && term.globalName === term.companyCountryTermLocalName) ||
          term.companyCountryTermLocalName === null ||
          term.companyCountryTermLocalName === ''
            ? term.globalName
            : `${term.globalName} / ${term.companyCountryTermLocalName || term.localName}`,
      }
    })
  }

  return (
    <PayslipTable
      proportionsClassName='u-1/1'
      tableClassName='o-table--tiny o-table--striped'
      headingColSpan='2'
      headings={[
        {
          Header: t('Employee.table.variable_pay_elements'),
          classNames: 'u-text--center',
          title: '',
          disableSortBy: true,
          accessor: 'title',
          columns: [
            {
              Header: t('Global:Global.text.title'),
              accessor: 'title',
              disableSortBy: true,
              classNames: 'd-none',
            },
            {
              Header: t('Global:Global.text.value'),
              accessor: 'value',
              disableSortBy: true,
              classNames: 'd-none',
            },
          ],
        },
      ]}
      data={renderRows(variableTerms)}
    />
  )
}

VariablePayElements.propTypes = {
  variableTerms: PropTypes.array.isRequired,
}

export default VariablePayElements
