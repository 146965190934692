import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SectionHeading from 'components/SectionHeading'
import useEmployeeCustomFields from 'hooks/useEmployeeCustomFields'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import { everybodySSOString } from 'redux/config/ssoAccessStrings'
import { retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'
import { getTenantSSOStatusObject } from 'redux/helpers/getTenantSSOStatusObject'
import { createEmployeeFields, getFieldsBySection, renderFields } from 'utils/employee'
import { crudLayers } from 'utils/enums/crudLayers'
import { i18nNameSpaces } from 'i18n/types'

function EmployeeJobOrgFormFields ({
  employee,
  isPaymentTypeHourly,
  isEmployeeOnly,
  isEdit,
  costCenters,
  currencies,
  businessUnits,
  departments,
  officeLocations,
  subsidiaries,
  projects,
  hasCredentials,
  isCotUser,
  countries,
  includeCompany,
  companies,
  resetUserPassword,
  history,
  selectedCountryName,
  selectedCompany,
  form,
  includePayroll,
  includePaygroup,
  EmployeeStatus,
}) {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const isEmployeeCreateForm = form === 'employeeCreate'
  const showUniqueContractorIDField = EmployeeStatus.shouldShowUniqueContractorIdArea
  const showInvoicePaymentCurrencyArea = EmployeeStatus.shouldShowInvoicePaymentCurrencyArea
  const [country, setCountry] = React.useState(selectedCountryName)
  const { fields } = useEmployeeCustomFields(retrieveCompanyCustomFields, selectedCompany, [selectedCompany])
  const uri = isEdit && `/companies/${employee.company.id}/roles-rights?action=openEditModal&employeeId=${employee.id}`
  const handleMakeKeyPerson = () => history.push(uri)
  const handleResetUserPass = () => resetUserPassword({ email: employee.workEmail })
  const { defaultSSOEnabledFor, isTenantSSOEnabled } = useSelector((state) => getTenantSSOStatusObject(state))
  const showMode2faFieldOnCreate = isTenantSSOEnabled ? defaultSSOEnabledFor !== everybodySSOString : true
  const getEmployeeJobProfileFields = () =>
    getFieldsBySection({
      fields: employeeFieldsConfigAlt({ isEdit: true }),
      employee,
      section: 'job-and-org',
      subSection: 'jobProfile',
      isPaymentTypeHourly,
      showUniqueContractorIDField,
    }).map((field) => renderFields(field))
  const getEmployeeOrganizationFields = () =>
    getFieldsBySection({
      fields: employeeFieldsConfigAlt({ isEdit: true }),
      section: 'job-and-org',
      subSection: 'organizationDetails',
      employee,
      currencies,
      showUniqueContractorIDField,
      showInvoicePaymentCurrencyArea,
      businessUnits,
      costCenters,
      departments,
      officeLocations,
      subsidiaries,
      projects,
      isEdit,
      includeCompany,
      countries,
      companies,
      hasCredentials,
      isEmployeeOnly,
      resetUserPassword: handleResetUserPass,
      makeKeyPerson: handleMakeKeyPerson,
    }).map((field) => renderFields(field))
  const getEmployeeEmploymentFields = (mode) =>
    getFieldsBySection({
      fields: employeeFieldsConfigAlt({ isEdit: true }),
      employee,
      section: 'job-and-org',
      subSection: 'employmentDetails',
      hasCredentials,
    }).map((field) => renderFields(field, null, mode))
  const getEmployeeSelfServiceFields = () =>
    getFieldsBySection({
      fields: employeeFieldsConfigAlt({ isEdit: true }),
      employee,
      section: 'job-and-org',
      subSection: 'employeeSelfService',
      hasCredentials,
      isCotUser,
    }).map((field) => renderFields(field))
  return (
    <>
      {isEmployeeCreateForm ? (
        <>
          <SectionHeading text={t('Employee.job_org_heading.job_profile')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>
            {createEmployeeFields({
              fields: employeeFieldsConfigAlt({ isCreate: true }),
              section: 'job-and-org',
              subSection: 'jobProfile',
              country,
              companies,
              countries,
              showUniqueContractorIDField,
              isPaymentTypeHourly,
              extraFields: fields,
            }).map((field) => renderFields(field, setCountry))}
          </div>
          <SectionHeading text={t('Employee.job_org_heading.organization_details')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>
            {createEmployeeFields({
              fields: employeeFieldsConfigAlt({ isCreate: true }),
              section: 'job-and-org',
              subSection: 'organizationDetails',
              country,
              companies,
              countries,
              currencies,
              includePayroll,
              includePaygroup,
              businessUnits,
              costCenters,
              departments,
              officeLocations,
              subsidiaries,
              projects,
              showInvoicePaymentCurrencyArea,
              extraFields: fields,
            }).map((field) => renderFields(field, setCountry))}
          </div>
          <SectionHeading text={t('Employee.job_org_heading.employment_details')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>
            {createEmployeeFields({
              fields: employeeFieldsConfigAlt({ isCreate: true }),
              section: 'job-and-org',
              subSection: 'employmentDetails',
              country,
              companies,
              countries,
              extraFields: fields,
            }).map((field) => renderFields(field, setCountry, crudLayers.CREATE))}
          </div>
          <SectionHeading text={t('Employee.job_org_heading.employee_self_service')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>
            {createEmployeeFields({
              fields: employeeFieldsConfigAlt({ isCreate: true, showMode2faFieldOnCreate }),
              section: 'job-and-org',
              subSection: 'employeeSelfService',
              country,
              companies,
              countries,
              extraFields: fields,
              isCotUser,
            }).map((field) => renderFields(field, setCountry))}
          </div>
        </>
      ) : (
        <>
          <SectionHeading text={t('Employee.job_org_heading.job_profile')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>{getEmployeeJobProfileFields()}</div>
          <SectionHeading text={t('Employee.job_org_heading.organization_details')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>{getEmployeeOrganizationFields()}</div>
          <SectionHeading text={t('Employee.job_org_heading.employment_details')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>{getEmployeeEmploymentFields(isEdit ? crudLayers.EDIT : null)}</div>
          <SectionHeading text={t('Employee.job_org_heading.employee_self_service')} priority={4} bolded={false} />
          <div className='o-layout o-grid--strech'>{getEmployeeSelfServiceFields()}</div>
        </>
      )}
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </>
  )
}

EmployeeJobOrgFormFields.propTypes = {
  employee: PropTypes.object,
  includeCompany: PropTypes.bool,
  countries: PropTypes.array,
  companies: PropTypes.array,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  departments: PropTypes.array,
  officeLocations: PropTypes.array,
  subsidiaries: PropTypes.array,
  projects: PropTypes.array,
  hasCredentials: PropTypes.bool,
  resetUserPassword: PropTypes.func,
  isPaymentTypeHourly: PropTypes.bool,
  isEmployeeOnly: PropTypes.bool,
  isEdit: PropTypes.bool,
  history: PropTypes.object,
  selectedCountryName: PropTypes.string,
  EmployeeStatus: PropTypes.object,
  form: PropTypes.string,
  currencies: PropTypes.array,
  includePayroll: PropTypes.bool,
  includePaygroup: PropTypes.bool,
  selectedCompany: PropTypes.number,
  isCotUser: PropTypes.bool,
}

export default EmployeeJobOrgFormFields
