import React from 'react'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const VariablePayElements = ({ variableTerms, isFetching, disabled, disabledReason, className = 'u-1/1 u-1/2@tablet' }) => {
  const { t } = useTranslation()
  if (isFetching) return <div>{t('Global.text.loading')}</div>
  return (
    <div className='o-layout o-grid--strech'>
      {variableTerms.map((term, index) => {
        return (
          <div key={index} className={`o-layout__item u-margin-bottom ${className}`}>
            <div
              className='c-panel o-grid o-grid--middle min-height-72 u-bg--white border-1-valhalla rounded
        u-padding-left-small u-padding-right-small'
            >
              <Field
                type='checkbox'
                component={CheckboxField}
                className='control__indicator__input'
                label={term.fullName}
                name={`variable-term-${term.companyCountryTermId}`}
                disabled={disabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

VariablePayElements.propTypes = {
  variableTerms: PropTypes.array,
  isFetching: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  className: PropTypes.string,
}

export default VariablePayElements
