import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import CheckboxField from 'components/form/CheckboxField'
import PayslipTable from 'components/table/PayslipTable'
import { i18nNameSpaces } from 'i18n/types'

const ShareOnboardingVendor = ({ onboardingVendorUsers, initialValues, isOwner, sharingActionText }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div data-testid='onboardingVendor-tabBody'>
      <PayslipTable
        headings={[
          { accessor: 'id', Header: t('Global:Global.text.id'), isKey: true, isVisible: false },
          { accessor: 'fullname', Header: t('Global:Global.text.name'), disableSortBy: true },
          {
            accessor: 'share',
            Header: sharingActionText || t('Global:Global.text.share'),
            setWidth: '200px',
            classNames: 'text-center',
            columnClassName: 'text-center u-padding-right-tiny',
            disableSortBy: true,
          },
        ]}
        data={onboardingVendorUsers.map((user) => ({
          ...user,
          share: (
            <Field
              type='checkbox'
              labelClassName='u-hidden'
              component={CheckboxField}
              className='control__indicator__input'
              indicatorClassName='control__indicator--center'
              formGroupClassName='u-margin-none'
              name={`${user.extraData.tenant}-${user.extraData.id}`}
              disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
              data-testid={`share-checkbox-${user.extraData.tenant}-${user.extraData.id}`}
            />
          ),
        }))}
        wrapperClassName='u-1/1 u-margin-bottom'
        options={{ noDataText: t('BusinessComponent.table.no_users_to_share') }}
        trClassName='react-bs-table__cell--whitespace--normal'
      />
    </div>
  )
}

ShareOnboardingVendor.propTypes = {
  onboardingVendorUsers: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string,
}

export default ShareOnboardingVendor
