import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { useTranslation } from 'react-i18next'

const RejectButton = ({ buttonText, ...rest }) => {
  const { t } = useTranslation()
  const defaultText = buttonText || t('Global.text.no')
  return (
    <Button className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-small' data-testid='reject-button' {...rest}>
      {defaultText}
    </Button>
  )
}

RejectButton.propTypes = {
  buttonText: PropTypes.string,
}

export default RejectButton
