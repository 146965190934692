import React, { useRef, useState } from 'react'
import Form from 'components/form/Form'
import ConfirmationModal from 'components/ConfirmationModal'
import { useTranslation } from 'react-i18next'

const ConfirmationForm = (props) => {
  const { showConfirmation, message, submitOnReject, handleSubmit, onSubmit, children } = props

  const [proxy, setProxy] = useState(null)
  const confirmationModalRef = useRef()
  const { t } = useTranslation()

  return (
    <div>
      <ConfirmationModal
        ref={confirmationModalRef}
        className='c-modal'
        modalHeading={t('Global.modal.confirmation')}
        onConfirm={() => {
          const submitter = handleSubmit((data) => onSubmit(data))
          submitter(proxy)
        }}
        onReject={() => {
          if (!submitOnReject) return
          const submitter = handleSubmit((data) => onSubmit(data))
          submitter(proxy)
        }}
      >
        <p>{message}</p>
      </ConfirmationModal>
      <Form
        {...props}
        handleSubmit={(proxyEvent) => {
          if (showConfirmation) {
            proxyEvent.preventDefault()
            proxyEvent.persist()
            setProxy(proxyEvent)
            confirmationModalRef.current.showModal()
          } else {
            const submitter = handleSubmit((data) => onSubmit(data))
            submitter(proxyEvent)
          }
        }}
      >
        {children}
      </Form>
    </div>
  )
}

export default ConfirmationForm
