import React from 'react'
import i18n from 'i18next'
import EditableCell from 'components/table/cell/EditableCell'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { CellNumberFormat } from 'utils/tableDataFormatters'

const btnClassName = 'c-btn c-btn--tiny c-btn--curious u-text--tiny'

export const getAdditionalItemsTableHeadings = ({ handleDelete, handleHistory }) => {
  return [
    new TableHeading({
      accessor: 'item',
      heading: i18n.t('Payrun:Payrun.tableHeadings.item'),
      className: 'text-left',
      columnClassName: 'text-left',
      editable: true,
      Cell: EditableCell,
      inputType: 'input',
      disableSortBy: true,
      charactersPerLine: 110,
      requiredField: true,
      focusableIndex: 0,
    }),
    new TableHeading({
      accessor: 'currentPayrunValue',
      heading: i18n.t('Payrun:Payrun.tableHeadings.currentPayrun'),
      className: 'text-right',
      columnClassName: 'text-right',
      editable: true,
      Cell: EditableCell,
      inputType: 'amount',
      disableSortBy: true,
      requiredField: true,
    }),
    new TableHeading({
      accessor: 'previousPayrunValue',
      heading: i18n.t('Payrun:Payrun.tableHeadings.previousPayrun'),
      className: 'text-right',
      columnClassName: 'text-right',
      editable: true,
      Cell: EditableCell,
      inputType: 'amount',
      disableSortBy: true,
      requiredField: true,
    }),
    new TableHeading({
      accessor: 'variance',
      heading: i18n.t('Payrun:Payrun.tableHeadings.variance'),
      className: 'text-right',
      columnClassName: 'text-right',
      editable: false,
      Cell: CellNumberFormat,
      disableSortBy: true,
    }),
    new TableHeading({
      id: 'comment',
      heading: '',
      editable: true,
      inputType: 'textArea',
      charactersPerLine: 180,
      disableSortBy: true,
      placeholder: i18n.t('Payrun:Payrun.tableHeadings.commentPlaceholder'),
      Cell: ({ row }) => {
        const original = row?.original
        const isNew = original?.isNew
        return (
          <>
            {!isNew && (
              <div className='d-flex jc--flex-end ai-center'>
                <button onClick={() => handleHistory(row.original)} className={`${btnClassName}`} title={i18n.t('Global:Global.text.history')}>
                  <span className='icon icon--history' />
                </button>
                {original?.isTheLoggedUserTheOwner && (
                  <button
                    onClick={() => handleDelete(row.original)}
                    className={`${btnClassName} u-margin-left-tiny`}
                    title={i18n.t('Global:Global.text.remove')}
                    data-testid='additional-items-remove'
                  >
                    <span className='icon icon--trash' />
                  </button>
                )}
              </div>
            )}
          </>
        )
      },
    }),
  ]
}
