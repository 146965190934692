import React, { useRef, useState } from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import { emailValidation, phoneValidation } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import ProcessingSSOModal from 'components/ProcessingSSOModal'
import { getSelectionOptions } from 'utils/selectOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { sortByLabel } from 'utils/strings'
import { yesNoBoolValuesOptions } from 'redux/config/DropDownOptions/confirmationOptions'
import { VendorAccessControlForm } from './VendorAccessControlForm'
import { getMode2faEmailOption } from 'redux/config/DropDownOptions/mode2faOptions'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const VendorUserForm = (props) => {
  const [state, setState] = useState({
    isSSOSubmitting: false,
    isVendorSSOEnabled: props.isVendorSSOEnabled,
    submitErrors: [],
    vendorUserEmail: null,
  })
  const submittingSSOModal = useRef(null)
  const confirmUserPasswordReset = useRef(null)

  const { t } = useTranslation([i18nNameSpaces.Vendor])

  const {
    isFetching,
    globalCountries,
    hasCredentials,
    isCot,
    isVendorAdmin,
    isEdit,
    isCreate,
    resetUserPassword,
    workEmail,
    isMobilePhoneFieldDisabled,
    timezones,
    receivesNotifications,
    receivesEmailNotifications,
    showUserSSOField = false,
    showUserSSOButton = false,
    vendorUserId,
    isVendorSSOEnabled,
    handleSSOclick,
    showMode2faFieldOnCreate,
    showMode2faFieldOnEdit,
  } = props
  const canModifyAdminContent = isVendorAdmin || isCot
  const showPasswordResetBtn = !(state.isVendorSSOEnabled || isVendorSSOEnabled)
  const isUserSSOEnabled = state.isVendorSSOEnabled || isVendorSSOEnabled
  const showMode2faField = isCreate ? showMode2faFieldOnCreate : showMode2faFieldOnEdit
  if (isFetching) return <div>Loading...</div>

  const requiredSymbol = '*'

  return (
    <>
      <ProcessingSSOModal isSSOSubmitting={state.isSSOSubmitting} isUserSSOEnabled={isUserSSOEnabled} ref={submittingSSOModal} errors={state.submitErrors} />

      <Form {...props}>
        <div className='o-layout'>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='firstname'
                label={t('Vendor.field.first_name_required', { requiredSymbol })}
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                data-testid='firstname'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='surname'
                label={t('Vendor.field.last_name_required', { requiredSymbol })}
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                data-testid='surname'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='title'
                label={t('Vendor.field.title')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={getSelectionOptions('title')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='gender'
                label={t('Vendor.field.gender')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={[
                  { value: 'male', label: t('Global:Global.gender.male') },
                  { value: 'female', label: t('Global:Global.gender.female') },
                  { value: 'not stated', label: t('Global:Global.gender.not_stated') },
                ]}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='country'
                label={t('Global:Global.text.country')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={t('Global:Global.select.placeholder')}
                options={globalCountries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))
                  .sort(sortByLabel)}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='timezone'
                label={t('Global:Global.text.timezone')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={t('Global:Global.select.placeholder')}
                options={timezones.map((timezone) => ({
                  value: timezone.id,
                  label: timezone.zone,
                }))}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='position'
                label={t('Vendor.field.position')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workLocation'
                label={t('Vendor.field.work_location')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workEmail'
                label={t('Vendor.field.work_email_required', { requiredSymbol })}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
                data-testid='workEmail'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='personalEmail'
                label={t('Vendor.field.personal_email')}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='mobile-phone-wrapper'>
              <Field
                name='mobilePhone'
                label={t('Vendor.field.mobile_phone')}
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
                disabled={isMobilePhoneFieldDisabled}
                disabledReason={t('Global:Global.text.go_to_settings')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='hasCredentials'
                label={t('Vendor.field.account_status')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={[
                  { value: true, label: t('Global.text.active') },
                  { value: false, label: t('Global.text.inactive') },
                ]}
                data-testid='hasCredentials-wrapper'
              />
            </div>
          </div>
          {showMode2faField && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='mode2fa'
                  label={t('Vendor.field.2fa_mode', { requiredSymbol })}
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder={t('Global:Global.select.placeholder')}
                  options={isCot ? [...getSelectionOptions('mode2fa'), getMode2faEmailOption()] : getSelectionOptions('mode2fa')}
                />
              </div>
            </div>
          )}
          {showUserSSOField && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='isSSOEnabled'
                  label={t('Vendor.field.sso')}
                  component={InputField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder={t('Global:Global.select.placeholder')}
                  disabled
                  data-testid='isSSOEnabled-wrapper'
                />
              </div>
            </div>
          )}
          {hasCredentials && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='username'
                  label={t('Vendor.field.username')}
                  component={InputField}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                />
              </div>
            </div>
          )}
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workflowNotifications'
                label={t('Vendor.field.workflow_notifications')}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={
                  isEdit && !canModifyAdminContent && !receivesNotifications && !receivesEmailNotifications
                    ? t('Vendor.text.no_alerts_and_emails')
                    : t('Global:Global.select.placeholder')
                }
                title={t('Vendor.text.alerts_message')}
                clearable={false}
                options={[
                  {
                    value: JSON.stringify({ receivesNotifications: true, receivesEmailNotifications: true }),
                    label: t('Global:Global.alerts.in_system_and_emails'),
                  },
                  {
                    value: JSON.stringify({ receivesNotifications: true, receivesEmailNotifications: false }),
                    label: t('Global:Global.alerts.in_system'),
                  },
                  ...(canModifyAdminContent
                    ? [
                      {
                        value: JSON.stringify({ receivesNotifications: false, receivesEmailNotifications: false }),
                        label: t('Global:Global.alerts.no_alerts_and_emails'),
                      },
                    ]
                    : []),
                ]}
                data-testid='workflowNotifications-wrapper'
              />
            </div>
          </div>
          {canModifyAdminContent && (
            <>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    name='includeInCommunications'
                    label={t('Vendor.field.include_in_comms')}
                    component={CustomSelectField}
                    labelClassName='c-label u-text--small u-text--curious'
                    className='c-custom-select--transparent'
                    formGroupClassName='u-margin-none'
                    clearable={false}
                    placeholder={t('Global:Global.select.placeholder')}
                    options={yesNoBoolValuesOptions}
                    data-testid='includeInCommunications-wrapper'
                  />
                </div>
              </div>
              <div className='u-margin-top-section'>
                <VendorAccessControlForm {...props} />
              </div>
            </>
          )}
          {hasCredentials && (
            <Authorization permissions={['VENDORUSER_EDIT']}>
              <div className='o-layout__item u-margin-bottom'>
                <div className='c-panel o-grid o-grid--medium ai-center'>
                  {showPasswordResetBtn && (
                    <button
                      onClick={() => {
                        setState({ ...state, vendorUserEmail: workEmail })
                        confirmUserPasswordReset.current.showModal()
                      }}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-small u-margin-right u-margin-left-none'
                      data-testid='reset-user-password'
                    >
                      {t('Global:Global.button.reset_user_password')}
                    </button>
                  )}
                  {showUserSSOButton && (
                    <div className='c-panel u-margin-small u-margin-right u-margin-left-none u-padding-none'>
                      <button
                        type='button'
                        onClick={() => {
                          setState({ ...state, isSSOSubmitting: true })
                          submittingSSOModal.current.showModal()
                          handleSSOclick({
                            userId: vendorUserId,
                            isUserSSOEnabled: !isVendorSSOEnabled,
                          }).then((res) => {
                            if (res?.status) {
                              return setState({
                                ...state,
                                isVendorSSOEnabled: res.isVendorSSOEnabled,
                                isSSOSubmitting: false,
                              })
                            }
                            setState({
                              ...state,
                              isSSOSubmitting: false,
                              submitErrors: res.errors._error,
                            })
                          })
                        }}
                        className={`c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right
                        ${state.isSSOSubmitting ? 'pe-none' : ''}`}
                        data-testid='sso-button'
                      >
                        {state.isVendorSSOEnabled || isVendorSSOEnabled ? t('Global:Global.button.disable_sso') : t('Global:Global.button.enable_sso')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Authorization>
          )}
        </div>
        <Authorization permissions={['VENDORUSER_EDIT', 'VENDORUSER_DELETE']}>
          <div className='o-layout'>
            <div className='o-layout__item u-text--center u-1/1'>
              <button
                type='submit'
                className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
              u-padding-right u-margin-top-small'
                data-testid='submit'
              >
                {t('Global:Global.button.save')}
              </button>
            </div>
          </div>
        </Authorization>
      </Form>

      <ConfirmationModal
        ref={confirmUserPasswordReset}
        className='c-modal'
        modalHeading={t('Global:Global.modal.confirmation')}
        onConfirm={() => resetUserPassword({ email: state.vendorUserEmail })}
      >
        <p>{t('Global:Global.text.reset_password_confirm')}</p>
      </ConfirmationModal>
    </>
  )
}

VendorUserForm.propTypes = {
  isEdit: PropTypes.bool,
  resetField: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  globalCountries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  hasCredentials: PropTypes.bool,
  resetUserPassword: PropTypes.func,
  workEmail: PropTypes.string,
  isCot: PropTypes.bool,
  isVendorAdmin: PropTypes.bool,
  receivesNotifications: PropTypes.bool,
  receivesEmailNotifications: PropTypes.bool,
  isFetching: PropTypes.bool,
  isMobilePhoneFieldDisabled: PropTypes.bool,
  timezones: PropTypes.array,
  showUserSSOField: PropTypes.bool,
  showUserSSOButton: PropTypes.bool,
  isVendorSSOEnabled: PropTypes.bool,
  vendorUserId: PropTypes.number,
  handleSSOclick: PropTypes.func,
  parentCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      schema: PropTypes.string.isRequired,
    })
  ),
  externalCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      accessable: PropTypes.bool.isRequired,
      companyId: PropTypes.number.isRequired,
      country: PropTypes.number.isRequired,
      tenant: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  submittingSSOModal: PropTypes.objectOf(PropTypes.any),
  confirmUserPasswordReset: PropTypes.objectOf(PropTypes.any),
  canModifyAdminContent: PropTypes.bool,
  showPasswordResetBtn: PropTypes.bool,
  showMode2faField: PropTypes.bool,
  showMode2faFieldOnEdit: PropTypes.bool,
  showMode2faFieldOnCreate: PropTypes.bool,
  isCreate: PropTypes.bool,
}

export default VendorUserForm
