import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ReferralsFormFields = ({ ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <Form showSubmitBtn btnText={t('Global:Global.button.submit')} {...props}>
      <div className='o-layout__item u-1/1'>
        <div className='c-panel u-padding-small'>
          <Field
            name='name'
            label={t('BusinessComponent.text.referral')}
            labelClassName='c-label'
            component={CustomSelectField}
            formGroupClassName='u-margin-none'
            placeholder={t('Global:Global.select.placeholder')}
            data-testid='referrals-wrapper'
            options={[
              {
                value: 'referral-1',
                label: t('BusinessComponent.select.referral_1'),
              },
              {
                value: 'referral-2',
                label: t('BusinessComponent.select.referral_2'),
              },
              {
                value: 'other',
                label: t('BusinessComponent.form.type_of_account_Other'),
              },
            ]}
          />
        </div>
      </div>
      <div className='o-layout__item u-1/1'>
        <div className='c-panel u-padding-small'>
          <Field
            name='deactivateAccount'
            label={t('BusinessComponent.text.deactivate_in')}
            labelClassName='c-label'
            component={CustomSelectField}
            formGroupClassName='u-margin-none'
            placeholder={t('Global:Global.select.placeholder')}
            data-testid='referrals-wrapper'
            options={[
              {
                value: '3',
                label: t('BusinessComponent.select.three_months'),
              },
              {
                value: '6',
                label: t('BusinessComponent.select.six_months'),
              },
              {
                value: 'other',
                label: t('BusinessComponent.form.type_of_account_Other'),
              },
            ]}
          />
        </div>
      </div>
    </Form>
  )
}

export default ReferralsFormFields
