import React from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ServiceElementForm = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  return (
    <div className='o-layout'>
      <form>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field name='serviceElement' label={t('Vendor.text.service_element')} labelClassName='c-label' component={InputField} type='text' />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='type'
            label={t('Vendor.text.service_cost_type')}
            labelClassName='c-label'
            component={CustomSelectField}
            placeholder={t('Global:Global.button.save')} // Placeholder reusing Global text "Save"
            clearable={false}
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field name='icpCost' label={t('Vendor.text.icp_cost')} labelClassName='c-label' component={InputField} type='number' />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field name='clientPrice' label={t('Vendor.text.clientPrice')} labelClassName='c-label' component={InputField} type='number' />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field name='margin' label={t('Vendor.text.margin')} labelClassName='c-label' component={InputField} type='number' />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field name='minThreshold' label={t('Vendor.text.min_threshold')} labelClassName='c-label' component={InputField} type='number' />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name={t('Vendor.text.employees_threshold')}
            label={t('Vendor.text.employees_threshold')}
            labelClassName='c-label'
            component={InputField}
            type='number'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='currency'
            label={t('Global:Global.text.currency')}
            labelClassName='c-label'
            component={CustomSelectField}
            placeholder={t('Global:Global.button.save')} // Placeholder reusing Global text "Save"
            clearable={false}
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
            ]}
          />
        </div>
        <div className='o-layout__item u-text--center u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'serviceElementForm',
})(ServiceElementForm)
