import React, { useState } from 'react'
import PropTypes from 'prop-types'
import NotificationHOC from 'components/NotificationsHOC'
import { SelectColumnFilter } from 'components/table/filters'
import PayslipTable from 'components/table/PayslipTable'
import { country, icon, status } from 'utils/tableDataFormatters'
import SectionHeading from 'components/SectionHeading'
import FilterButton from 'components/buttons/FilterButton'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

let classNames = require('classnames')

const getColumnFilter = (t) => ({ type: 'TextFilter', delay: 1000, placeholder: t('Global:Global.text.filter') })

const NotificationList = ({ isFetching, pagination, onFilter, tenants, countries, notifications }) => {
  const [showFilters, setShowFilters] = useState(false)
  const { t } = useTranslation([i18nNameSpaces.Toolbox])

  const trClassFormat = ({ original }) => {
    return !original.isRead ? 'u-weight--medium' : ''
  }

  const onPageChange = ({ pageSize, pageIndex, filters }) => {
    if (!isFetching) {
      let normalizedFilters = {}
      filters.forEach((filter) => {
        normalizedFilters[filter.id] = filter
      })
      onFilter({
        page: pageIndex,
        limit: pageSize,
        filters: normalizedFilters,
      })
    }
  }

  const options = {
    noDataText: t('Global:Global.text.no_matching_records_found'),
    pageSize: pagination?.limit,
    sizePerPageList: [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ],
    pageIndex: pagination?.currentPage,
    pageCount: pagination?.totalPages,
    showPageSizeSelector: pagination?.totalCount >= 25,
    onPageChange: onPageChange,
    onSizePerPageList: (limit) => onFilter({ limit }),
    onFilterChange: (filters) => onFilter({ filters }),
  }

  const clientOptions = tenants.filter((tenant) => tenant.type !== 'vendor')

  return (
    <div className='u-relative'>
      <SectionHeading text={t('Global:Global.aside.alerts')} fullWidth>
        <FilterButton className='c-btn c-btn--small c-btn--curious u-padding-left float-right u-padding-right' onClick={() => setShowFilters(!showFilters)} />
      </SectionHeading>
      <PayslipTable
        data={notifications}
        headings={[
          {
            accessor: 'type',
            Header: t('Global:Global.text.icon'),
            setWidth: '120px',
            Cell: icon,
            disableSortBy: true,
          },
          {
            accessor: 'client',
            Header: t('Global:Global.text.client'),
            setWidth: '120px',
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'client',
              options: clientOptions
                .filter((option) => option.name !== 'Vendors')
                .map((tenant) => ({
                  value: tenant.schema,
                  label: tenant.name,
                  disabled: tenant.disabled,
                  className: classNames({
                    'option--as-group-title': tenant.disabled,
                  }),
                })),
              multi: true,
            },
            disableSortBy: true,
          },
          {
            accessor: 'countryName',
            Header: t('Global:Global.text.country'),
            setWidth: '130px',
            Cell: country,
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'countryName',
              options: countries.map((c) => ({
                value: c.id,
                label: c.name,
              })),
              multi: true,
            },
            disableSortBy: true,
          },
          {
            accessor: 'payrollName',
            Header: t('Global:Global.text.payroll_name'),
            setWidth: '300px',
            disableSortBy: true,
          },
          {
            accessor: 'period',
            Header: t('Toolbox.text.pay_period'),
            setWidth: '200px',
            disableSortBy: true,
          },
          {
            accessor: 'body',
            Header: t('Global:Global.text.notification'),
            columnClassName: 'u-word-break-word',
            disableSortBy: true,
          },
          {
            accessor: 'status',
            Header: t('Global:Global.text.status'),
            setWidth: '170px',
            Cell: status,
            filterOptions: getColumnFilter(t),
            disableSortBy: true,
          },
        ]}
        trBodyClassName={trClassFormat}
        options={options}
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        wrapperClassName={classNames({
          'u-1/1 react-bs-table--overflow-auto': true,
          'u-half-opacity': isFetching,
        })}
        pagination
        remote
        showFilters={showFilters}
        totalCount={pagination?.totalCount}
      />
    </div>
  )
}

NotificationList.propTypes = {
  notifications: PropTypes.array,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      schema: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFilter: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalCount: PropTypes.number,
  }),
  isFetching: PropTypes.bool,
}

export default NotificationHOC(NotificationList)
