import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'

export const getFormErrors = createSelector(
  (state) => state.form.employeeCreate.submitErrors,
  (errors) => {
    return Object.getOwnPropertyNames(errors)
  }
)

/**
 * Validates an array of data objects for common pre-request errors.
 * @param {Array} dataToSave - The array of data objects to validate.
 * @param {string} mandatoryField - The name of the mandatory field property to check in each data object.
 * @returns {Object} An object containing the validation result.
 */

export const validateCommonPreRequestErrors = (dataToSave, mandatoryField = '', t) => {
  let result

  try {
    const isArray = Array.isArray(dataToSave)
    if (isArray) {
      const errors = dataToSave.reduce((acc, item) => {
        const field = item[mandatoryField] || ''
        if (isEmpty(field.trim())) {
          acc.push({
            id: item.id,
            [mandatoryField]: [t('Global:Global.error.blank_value')],
          })
        }
        return acc
      }, [])
      result = {
        isValid: isEmpty(errors),
        errors,
      }
    }
  } catch (error) {
    console.error('Error in validateCommonPreRequestErrors:', error)
    result = {
      isValid: false,
      errors: [],
    }
  }

  return result
}
