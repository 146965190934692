/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAsyncDebounce, useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { isEmpty, isUndefined } from 'lodash'
import { PayslipTablePagination } from './pagination'
import { DefaultColumnFilter } from './filters'
import { PayslipTableSearch } from './search'
import { PayslipTableBody } from './body'
import { PayslipTableHeader } from './header'
import { buildSelectionHooks } from './selection'
import { useFirstRender } from 'hooks/useFirstRender'
import { useTranslation } from 'react-i18next'

const classNames = require('classnames')
const ShownFilterContext = createContext(false)

const EmptyRow = ({ options: { noDataMessage } = {}, specialEmptyRowSpan, totalNumberOfColumns, showNoDataMessage = true }) => {
  const { t } = useTranslation()
  return (
    <tbody className='o-table-td--tiny'>
      <tr className='no-hover'>
        {!specialEmptyRowSpan && <td className=''> {/* Need an empty item to account for the hover border td */} </td>}
        {showNoDataMessage && (
          <td id='no-element-data' className='text-center no-data-padding' colSpan={specialEmptyRowSpan || totalNumberOfColumns}>
            {noDataMessage || t('Global.text.currently_no_data')}
          </td>
        )}
      </tr>
    </tbody>
  )
}

const PayslipTable = ({
  hasAllRowsExpanded,
  data,
  headings,
  trClassName,
  useCustomExpandedDisplay,
  tableElementClassName,
  wrapperClassName,
  modifierClasses,
  trBodyClassName,
  showFilters = false,
  onDataChangeRedrawHeaders = false,
  search,
  pagination,
  paginationStateControl = {},
  dynamicValues = false,
  options = {},
  remote = false,
  remoteSorting = false,
  rowSelection = null,
  customLastRow = null,
  LastPageChild = null,
  enableSortReset = true,
  autoResetExpanded = true,
  enableFilterReset = true,
  trBodyActionBar = null,
  specialEmptyRowSpan = false,
  showNoDataMessage = true,
  overrideRowDependency = false,
  editableOptions = {
    inGlobalEditMode: false,
    inAddMode: false,
    onUpdateGlobalData: () => null,
    onCancelInlineAdd: () => null,
    onSaveInline: () => null,
    fieldErrors: [],
    onValueChange: () => null,
    focusableColumn: undefined,
    needsValueUpdate: undefined,
  },
  skipPageReset = false,
  isFetching,
  expandableClass,
  initialFilters,
  initialSort,
  ...rest
}) => {
  const updateHeadersOnData = remote && onDataChangeRedrawHeaders ? [data] : []
  const columns = useMemo(() => headings, [updateHeadersOnData])
  const tableContainerRef = useRef(null)
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), [])
  const updateMemoOn = dynamicValues ? [data] : []
  const firstRender = useFirstRender()
  const { inGlobalEditMode, inAddMode, focusableColumn, needsValueUpdate, onUpdateGlobalData, onCancelInlineAdd, onSaveInline, onValueChange, fieldErrors } =
    editableOptions
  const memoData = useMemo(() => data, updateMemoOn)
  data = remote ? data : memoData

  let initialState = {
    hiddenColumns: columns.map((col) => {
      if (!isUndefined(col.isVisible) && !col.isVisible) {
        return col.accessor
      }
    }),
  }

  if (pagination) {
    const { pageSize, pageIndex, initialPageSize } = options
    initialState = { pageSize, pageIndex, ...paginationStateControl }
  }

  if (initialFilters) {
    initialState.filters = initialFilters
  }

  if (initialSort) {
    initialState.sortBy = initialSort
  }

  const useTableOptions = {
    columns,
    data,
    defaultColumn,
    inGlobalEditMode,
    inAddMode,
    onUpdateGlobalData,
    onCancelInlineAdd,
    onSaveInline,
    onValueChange,
    needsValueUpdate,
    focusableColumn,
    fieldErrors,
    initialState: initialState,
    manualFilters: remote || false,
    autoResetSelectedRows: !rowSelection,
    autoResetPage: !skipPageReset,
    autoResetSortBy: enableSortReset,
    autoResetFilters: enableFilterReset,
    autoResetExpanded: autoResetExpanded,
  }

  if (remote) {
    useTableOptions['manualPagination'] = true
    useTableOptions['pageCount'] = options.pageCount
  }
  if (remoteSorting) {
    useTableOptions['manualSortBy'] = true
  }

  const filterDebounce = options?.filterDebounce || 300
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    page,
    setAllFilters,
    preGlobalFilteredRows,
    state: { pageIndex, pageSize, filters, globalFilter, sortBy },
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsExpanded,
  } = useTable(useTableOptions, useFilters, useGlobalFilter, useSortBy, useExpanded, usePagination, useRowSelect, (hooks) => {
    if (rowSelection) {
      const selectionHook = buildSelectionHooks(rowSelection)
      hooks.visibleColumns.push((columns) => [selectionHook, ...columns])
    }
  })
  const onFetchDataDebouncedForFilter = useAsyncDebounce(options.onPageChange, filterDebounce)
  useEffect(() => {
    if (!firstRender && remote) {
      onFetchDataDebouncedForFilter({ pageIndex, pageSize, filters, sortBy })
    }
  }, [onFetchDataDebouncedForFilter, filters])

  useEffect(() => {
    if (typeof hasAllRowsExpanded === 'boolean' && !isEmpty(data)) {
      toggleAllRowsExpanded(hasAllRowsExpanded)
    }
  }, [hasAllRowsExpanded, data])

  useEffect(() => {
    if (!firstRender && remote) {
      options.onPageChange({ pageIndex, pageSize, filters, sortBy })
    }
  }, [pageIndex, pageSize])

  useEffect(() => {
    if (!showFilters && !firstRender) {
      setAllFilters([])
    }
  }, [showFilters])

  useEffect(() => {
    if (!firstRender && remoteSorting) {
      options.onSort({ pageIndex, pageSize, filters, sortBy })
    }
  }, [sortBy])

  const tableClassNames = 'o-table o-table--small o-table-td--tiny o-payslip-table-container'
  const totalNumberOfRows = pagination ? page.length : rows.length
  const totalNumberOfColumns = columns.filter((column) => isUndefined(column.isVisible))

  const isLastPage = pagination ? pageIndex === pageCount - 1 : true

  const defaultSizePerPages = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ]
  const sizePerPageList = options.sizePerPageList ? options.sizePerPageList : defaultSizePerPages
  const showPageSizeSelector = options.showPageSizeSelector ? options.showPageSizeSelector : false
  const initialPageSize = options?.initialPageSize || null
  const paginationOptions = {
    canPreviousPage,
    canNextPage,
    pageIndex,
    pageOptions,
    pageSize,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    sizePerPageList,
    showPageSizeSelector,
    paginationStateControl,
    initialPageSize,
  }

  return (
    <ShownFilterContext.Provider value={showFilters}>
      <div ref={tableContainerRef} className='u-relative' data-testid={rest['data-testid']}>
        <div className={`${tableClassNames} ${wrapperClassName} `}>
          {search && (
            <div className='o-payslip-table-tool-bar'>
              <PayslipTableSearch preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            </div>
          )}
          <div className={`o-payslip-table ${modifierClasses || ''}`}>
            <table
              {...getTableProps({
                className: `table table-hover table-bordered ${tableElementClassName}`,
              })}
              data-testid={rest['data-testid']}
            >
              <PayslipTableHeader headerGroups={headerGroups} trClassName={trClassName} showFilters={showFilters} ShownFilterContext={ShownFilterContext} />
              {totalNumberOfRows > 0 ? (
                <PayslipTableBody
                  expandableClass={expandableClass}
                  getTableBodyProps={getTableBodyProps}
                  trBodyClassName={trBodyClassName}
                  useCustomExpandedDisplay={useCustomExpandedDisplay}
                  prepareRow={prepareRow}
                  customLastRow={customLastRow}
                  isLastPage={isLastPage}
                  trBodyActionBar={trBodyActionBar}
                  rows={pagination ? page : rows}
                  editableOptions={editableOptions}
                  LastPageChild={LastPageChild}
                  overrideRowDependency={overrideRowDependency}
                  isFetching={isFetching}
                />
              ) : (
                <EmptyRow
                  options={options}
                  specialEmptyRowSpan={specialEmptyRowSpan}
                  totalNumberOfColumns={rowSelection ? totalNumberOfColumns.length + 1 : totalNumberOfColumns.length}
                  showNoDataMessage={showNoDataMessage}
                />
              )}
            </table>
          </div>
          {pagination && <PayslipTablePagination options={paginationOptions} />}
          {isLastPage && LastPageChild ? <LastPageChild /> : null}
        </div>
      </div>
    </ShownFilterContext.Provider>
  )
}

PayslipTable.propTypes = {
  hasAllRowsExpanded: PropTypes.bool,
  data: PropTypes.array.isRequired,
  showFilters: PropTypes.bool,
  autoResetExpanded: PropTypes.bool,
  headings: PropTypes.array.isRequired,
  placeholderText: PropTypes.string,
  wrapperClassName: PropTypes.string,
  tableElementClassName: PropTypes.string,
  trClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  columnClassName: PropTypes.string,
  modifierClasses: PropTypes.string,
  trBodyClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  options: PropTypes.object,
  pagination: PropTypes.bool,
  useCustomExpandedDisplay: PropTypes.bool,
  search: PropTypes.bool,
  showPageSizeSelector: PropTypes.bool,
  dynamicValues: PropTypes.bool,
  remote: PropTypes.bool,
  goToPage: PropTypes.func,
  isFetching: PropTypes.bool,
  skipPageReset: PropTypes.bool,
  specialEmptyRowSpan: PropTypes.bool,
  enableSortReset: PropTypes.bool,
  enableFilterReset: PropTypes.bool,
  onDataChangeRedrawHeaders: PropTypes.bool,
  overrideRowDependency: PropTypes.bool,
  rowSelection: PropTypes.any,
  trBodyActionBar: PropTypes.object,
  customLastRow: PropTypes.shape({
    insertAfterRow: PropTypes.number,
    data: PropTypes.array,
  }),
  editableOptions: PropTypes.shape({
    inGlobalEditMode: PropTypes.bool,
    inAddMode: PropTypes.bool,
    onUpdateGlobalData: PropTypes.func,
    onCancelInlineAdd: PropTypes.func,
    onSaveInline: PropTypes.func,
    fieldErrors: PropTypes.array,
  }),
  paginationStateControl: PropTypes.object,
  expandableClass: PropTypes.string,
}

EmptyRow.propTypes = {
  options: PropTypes.object,
  totalNumberOfColumns: PropTypes.number,
}

export default PayslipTable
