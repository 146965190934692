import { getKURoleTypes } from 'redux/config/keyPeopleRoleTypes'
import { getBusinessUnitsWithCompanyWithCountry } from 'redux/selectors/businessUnits'
import { getAccessibleCompanies } from 'redux/selectors/company'
import { sortByLabel } from 'utils/strings'

export const getRolesAndRightsTableOptions = ({ state, isFetching }) => {
  const options = {
    businessUnits: [],
    status: [],
    roleTypes: [],
    accessAreas: [],
  }
  if (isFetching) return options

  const businessUnitsWithCompany = getBusinessUnitsWithCompanyWithCountry(state)
  const companies = getAccessibleCompanies(state)
  const buildBusinessUnitOptions = () => {
    const companiesList = companies.reduce((acc, company) => {
      if (company.businessUnits.length) {
        acc.push({
          value: company.businessUnits.toString(),
          label: `All ${company.name} BUs`,
        })
      }
      return acc
    }, [])
    const businessUnitsList = Array.from(
      new Set(
        businessUnitsWithCompany.map((bu) => ({
          value: bu.id.toString(),
          label: bu.name,
        }))
      )
    )
    return [...businessUnitsList, ...companiesList].sort(sortByLabel)
  }

  options.businessUnits = buildBusinessUnitOptions()
  options.status = [
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Inactive' },
  ]
  options.roleTypes = getKURoleTypes().map((roleType) => ({ value: roleType.type, label: roleType.name }))
  options.accessAreas = companies
    .map((company) => ({
      value: company.id,
      label: company.name,
    }))
    .sort(sortByLabel)

  return options
}
