import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reset, formValueSelector } from 'redux-form'
import { fetchResourcesIfNeeded, downloadResource, deleteResource, uploadResource, invalidateResources } from 'redux/actions/resources'
import { fetchResourceLogs } from 'redux/actions/resourceLogs'
import { isParentCompanyTenant, isVendorTenant } from 'redux/selectors/tenants'
import { showMessage, clearMessage } from 'redux/actions/modal'
import { getFilteredResources } from 'redux/selectors/resources'
import { getFilteredResourceLogs } from 'redux/selectors/resourceLogs'
import { isCotAdmin, isCot, isCotOem, isKeyUser, isVendorInvolved } from 'redux/selectors/auth'
import { createResourcesFilterName, prepareResourcesFilters, prepareResourceLogsFilters } from 'redux/filters/resourcesFilter'
import { isFetchingByCustomFilter } from 'utils/redux/fetching'
import { accessibleBy } from '../utils/resourcesOptions'
import { getTenantSubdomain } from 'utils/subbranding'
import { getParsedDomain } from 'utils/query'
import ResourcesView from '../components/ResourcesView'
import { i18nNameSpaces } from 'i18n/types'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const ResourcesContainer = ({ resourceType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(i18nNameSpaces.Resource)
  // access states
  const isLoggedInAsCotAdminUser = useSelector((state) => isCotAdmin(state))
  const isLoggedInAsCotUser = useSelector((state) => isCot(state))
  const isLoggedInAsCotOemUser = useSelector((state) => isCotOem(state))
  const isLoggedInAsKeyUser = useSelector((state) => isKeyUser(state))
  const isLoggedInAsVendorUser = useSelector((state) => isVendorInvolved(state))
  const hasParentCompanyTenant = useSelector((state) => isParentCompanyTenant(state))
  const hasVendorTenant = useSelector((state) => isVendorTenant(state))

  // user permissions
  const isCotAdminUser = isLoggedInAsCotAdminUser && !hasParentCompanyTenant && !hasVendorTenant
  const isCotUser = isLoggedInAsCotUser && !hasParentCompanyTenant && !hasVendorTenant
  const isCotViewingClient = isLoggedInAsCotUser && hasParentCompanyTenant
  const isCotViewingVendor = isLoggedInAsCotUser && hasVendorTenant

  // table permissions
  const clientTablePermission = isCotUser || isCotViewingClient || isLoggedInAsKeyUser
  const vendorTablePermission = isCotUser || isCotViewingVendor || isLoggedInAsVendorUser

  // set initial states
  const initialRow = null
  const limit = clientTablePermission && vendorTablePermission ? 10 : 20
  const initialOffset = 0
  const initialSort = [
    {
      name: 'uploadedOn',
      order: 'desc',
    },
  ]
  const [selectedRow, setSelectedRow] = useState(initialRow)
  const [clientOffset, setClientOffset] = useState(initialOffset)
  const [vendorOffset, setVendorOffset] = useState(initialOffset)
  const [clientSort, setClientSort] = useState(initialSort)
  const [vendorSort, setVendorSort] = useState(initialSort)

  // reset initial states
  const resetInitialStates = () => {
    setSelectedRow(initialRow)
    setClientOffset(initialOffset)
    setVendorOffset(initialOffset)
    setClientSort(initialSort)
    setVendorSort(initialSort)
  }

  // filters
  const clientFilterName = createResourcesFilterName(resourceType, accessibleBy.client, clientSort, clientOffset, limit)
  const vendorFilterName = createResourcesFilterName(resourceType, accessibleBy.vendor, vendorSort, vendorOffset, limit)
  const clientFilters = prepareResourcesFilters(
    resourceType,
    { accessibleBy: [accessibleBy.client, accessibleBy.both] },
    clientSort,
    clientOffset,
    limit,
    clientFilterName
  )
  const vendorFilters = prepareResourcesFilters(
    resourceType,
    { accessibleBy: [accessibleBy.vendor, accessibleBy.both] },
    vendorSort,
    vendorOffset,
    limit,
    vendorFilterName
  )

  // pagination
  const createPagination = (totalCount, offset, setOffset) => {
    return {
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit,
      limit,
      totalCount: totalCount,
      onPageChange: setOffset,
    }
  }
  const clientTotalCount = useSelector((state) => state.resources?.filters[clientFilterName]?.totalCount || limit)
  const vendorTotalCount = useSelector((state) => state.resources?.filters[vendorFilterName]?.totalCount || limit)
  const clientPagination = createPagination(clientTotalCount, clientOffset, setClientOffset)
  const vendorPagination = createPagination(vendorTotalCount, vendorOffset, setVendorOffset)

  // data
  const clientDataIsFetching = useSelector((state) => isFetchingByCustomFilter(clientFilterName, state.resources))
  const vendorDataIsFetching = useSelector((state) => isFetchingByCustomFilter(vendorFilterName, state.resources))
  const clientData = useSelector((state) => getFilteredResources(state, clientFilterName))
  const vendorData = useSelector((state) => getFilteredResources(state, vendorFilterName))

  useEffect(() => {
    if (clientTablePermission) {
      dispatch(fetchResourcesIfNeeded(clientFilters))
    }
    if (vendorTablePermission) {
      dispatch(fetchResourcesIfNeeded(vendorFilters))
    }
  }, [clientOffset, vendorOffset, clientSort, vendorSort])

  // download
  const handleDownload = (id) => {
    dispatch(downloadResource(id))
  }

  // delete
  const deleteFile = () => {
    dispatch(deleteResource(selectedRow.id, false, true))
      .then(() => {
        resetInitialStates()
      })
      .catch(() => {
        dispatch(showMessage({ body: t('Global.text.something_went_wrong') }))
      })
  }

  // upload
  const uploadModalRef = useRef()
  const selector = formValueSelector('resourcesUploadForm')
  const fieldValue = useSelector((state) => selector(state, 'accessibleBy'))
  const uploadFile = (file) => {
    const data = {
      accessibleBy: fieldValue,
      'resourceTypes[0]': resourceType.dbName,
    }
    dispatch(uploadResource(file, data))
      .then(() => {
        uploadModalRef.current.hideModal()
        dispatch(reset('resourcesUploadForm'))
        dispatch(invalidateResources())
        resetInitialStates()
        dispatch(clearMessage())
      })
      .catch(() => {
        dispatch(showMessage({ body: t('Global.text.something_went_wrong') }))
      })
  }

  // history logs
  const historyModalRef = useRef()
  const tenants = useSelector((state) => state.tenants)
  const tenantDomain = getParsedDomain(tenants.url)
  const tenantSubdomain = getTenantSubdomain(tenantDomain)

  const getHistory = (id) => {
    dispatch(fetchResourceLogs(prepareResourceLogsFilters(id, tenantSubdomain)))
      .then(() => {
        historyModalRef.current.showModal()
      })
      .catch(() => {
        dispatch(showMessage({ body: t('Global.text.something_went_wrong') }))
      })
  }
  const historyData = useSelector((state) => getFilteredResourceLogs(state)) || {}
  const props = {
    isCotAdminUser,
    isCotUser,
    isCotOemUser: isLoggedInAsCotOemUser,
    clientTablePermission,
    vendorTablePermission,
    clientTable: {
      data: clientData,
      isFetching: clientDataIsFetching,
      pagination: clientPagination,
      setSort: setClientSort,
    },
    vendorTable: {
      data: vendorData,
      isFetching: vendorDataIsFetching,
      pagination: vendorPagination,
      setSort: setVendorSort,
    },
    handleDownload,
    selectedRow,
    setSelectedRow,
    deleteFile,
    historyModalRef,
    historyData,
    getHistory,
    uploadModalRef,
    uploadFile,
    showSelectFile: fieldValue,
  }

  return <ResourcesView {...props} />
}
ResourcesContainer.propTypes = {
  resourceType: PropTypes.shape({
    dbName: PropTypes.string.isRequired,
  }).isRequired,
}

export default ResourcesContainer
