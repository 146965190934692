import React from 'react'
import SectionHeading from 'components/SectionHeading'
import ChangePhoneNumberStepOneContainer from '../containers/ChangePhoneNumberStepOneContainer'
import ChangePhoneNumberStepTwoContainer from '../containers/ChangePhoneNumberStepTwoContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ChangePhoneNumberView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  return (
    <div>
      <SectionHeading text={t('BusinessComponent.input.change_phone_number')} />
      <ChangePhoneNumberStepOneContainer {...props} />
      <ChangePhoneNumberStepTwoContainer {...props} />
    </div>
  )
}

export default ChangePhoneNumberView
