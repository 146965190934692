import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createSanitizedReport } from 'redux/actions/pollingJob'
import { getCompletedReportCreateJobs } from 'redux/selectors/pollingJobs'
import { showMessage } from 'redux/actions/modal'
import { deleteReport, downloadReport, fetchReportsIfNeeded, resetReports, setLastRunReportType, updateReport } from 'redux/actions/reports'
import { errorToString } from 'utils/apiErrors'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

/**
 * Use Reporting Actions , hook to create, delete and handle the report states
 * @returns {Object} The redux actions to the differents states changes in the report
 */
export const useReportingActions = () => {
  const dispatch = useDispatch()
  const [createReportJobId, setCreateReportJobId] = useState()
  const job = useSelector((state) => {
    return createReportJobId && getCompletedReportCreateJobs(state).find((j) => j.id === createReportJobId)
  })
  const { t } = useTranslation([i18nNameSpaces.Report])

  useEffect(() => {
    if (job?.entityId) {
      dispatch(fetchReportsIfNeeded())
    }
  }, [job])

  const createReport = async (data) => {
    const response = await dispatch(createSanitizedReport(data))
    await dispatch(resetReports())

    if (response?.id) {
      setCreateReportJobId(response.id)
    }
  }

  const handleDeleteReport = async (id, cb) => {
    try {
      await dispatch(deleteReport(id))
      dispatch(setLastRunReportType({}))
      dispatch(
        showMessage({
          body: t('Report.msg.successfully_deleted'),
        })
      )
    } catch (error) {
      dispatch(
        showMessage({
          body: errorToString(error),
        })
      )
    }
    cb()
  }

  const handleSaveReport = async (report) => {
    await dispatch(updateReport(report, true, true))
    dispatch(showMessage({ body: t('Report.msg.report_saved') }))
  }
  const handleDownloadReport = (id) => dispatch(downloadReport(id))

  return {
    createReport,
    deleteReport: handleDeleteReport,
    onSaveReport: handleSaveReport,
    downloadReport: handleDownloadReport,
  }
}
