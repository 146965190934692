import i18n from 'i18next'

import { SelectColumnFilter } from 'components/table/filters'
import { keyUserStatus } from 'utils/enumObjects'
import { employeeDetailsHoverBox, enumObjectDataFormatterPayslipTable, keyPeopleBases, keyPeopleBusinessUnits } from 'utils/tableDataFormatters'

const keyUsersAccessors = {
  id: 'id',
  name: 'name',
  status: 'status',
  position: 'position',
  base: 'base',
  role: 'role',
  accessArea: 'accessArea',
  businessUnits: 'businessUnits',
  action: 'action',
}

export const getTableHeadings = ({ options, baseOptions, showActions, isCotUser }) => [
  ...(isCotUser
    ? [
      {
        accessor: keyUsersAccessors.id,
        Header: i18n.t('Global:Global.text.id'),
        setWidth: '70px',
      },
    ]
    : []),
  {
    accessor: keyUsersAccessors.name,
    Header: i18n.t('Global:Global.text.name'),
    disableTitleTag: true,
    setWidth: '100px',
    Cell: employeeDetailsHoverBox,
  },
  {
    accessor: keyUsersAccessors.status,
    Header: i18n.t('Global:Global.text.status'),
    setWidth: '70px',
    Cell: enumObjectDataFormatterPayslipTable,
    formatExtraData: keyUserStatus,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: keyUsersAccessors.status,
      options: options.status,
    },
    dataSort: true,
  },
  {
    accessor: keyUsersAccessors.position,
    Header: i18n.t('Global:Global.text.position'),
    setWidth: '90px',
    dataSort: true,
  },
  {
    accessor: keyUsersAccessors.base,
    Header: i18n.t('Global:Global.text.base'),
    columnClassName: 'text-center',
    classNames: 'text-center',
    setWidth: '100px',
    Cell: keyPeopleBases,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: keyUsersAccessors.base,
      options: baseOptions,
    },
    dataSort: true,
  },
  {
    accessor: keyUsersAccessors.role,
    Header: i18n.t('Global:Global.text.role'),
    setWidth: '90px',
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: 'roleTypes',
      options: options.roleTypes,
    },
    dataSort: true,
  },
  {
    accessor: keyUsersAccessors.accessArea,
    Header: i18n.t('Global:Global.text.access_area'),
    setWidth: '220px',
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: keyUsersAccessors.accessArea,
      options: options.accessAreas,
    },
    disableSortBy: true,
  },
  {
    accessor: keyUsersAccessors.businessUnits,
    Header: i18n.t('Company:Company.key_user_field_labels.businessUnits', {
      requiredSymbol: '',
    }),
    setWidth: '220px',
    Cell: keyPeopleBusinessUnits,
    Filter: SelectColumnFilter,
    dropDownOptions: {
      name: keyUsersAccessors.businessUnits,
      options: options.businessUnits,
    },
    disableSortBy: true,
  },
  {
    accessor: keyUsersAccessors.action,
    Header: i18n.t('Global:Global.button.action'),
    setWidth: '80px',
    classNames: 'text-right',
    isVisible: showActions,
    disableFilters: true,
    disableSortBy: true,
  },
]
