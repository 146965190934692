import React from 'react'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import { createFilter } from 'utils/redux/filter'
import { toastEntityTypesEnum } from 'utils/enums/toastEntityTypesEnum'
import { useDispatch, useSelector } from 'react-redux'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { toast } from 'react-toastify'
import { isCot, isVendorInvolved } from 'redux/selectors/auth'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import { downloadReport } from 'redux/actions/reports'
import { flagAsRead, setStartPolling } from 'redux/actions/pollingJob'
import { BulkImportToast } from 'components/toasts/BulkImportToast'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PayrunFileToast = ({ job, cb, baseApiUsage, t }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small' style={{ maxWidth: '180px' }}>
        {t('BusinessComponent.toast.the_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_payrun_file.ready')}
      </span>
      <button
        className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-left-small u-margin-right-tiny u-margin-bottom-tiny'
        title={t('Global:Global.text.download')}
        onClick={() => {
          onCompleteToast()
        }}
      >
        {t('Global:Global.text.download')}
      </button>
      <div className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const PayrunFileImportToast = ({ job, cb, baseApiUsage, t }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.the_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_payrun_file.uploaded')}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const PayrunVarianceToast = ({ job, cb, baseApiUsage, t }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small' style={{ maxWidth: '180px' }}>
        {t('BusinessComponent.toast.the_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_reconciliation.ready')}
      </span>
      <button
        id={`entity-${job.entityId}`}
        className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-left-small u-margin-right-tiny u-margin-bottom-tiny'
        title={t('Global:Global.text.download')}
        onClick={() => {
          onCompleteToast()
        }}
      >
        {t('Global:Global.text.download')}
      </button>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const ElementVarianceToast = ({ job, cb, baseApiUsage, t }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small' style={{ maxWidth: '180px' }}>
        {t('BusinessComponent.toast.the_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_element_reconciliation.ready')}
      </span>
      <button
        id={`entity-${job.entityId}`}
        className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-left-small u-margin-right-tiny u-margin-bottom-tiny'
        title={t('Global:Global.text.download')}
        onClick={() => {
          onCompleteToast()
        }}
      >
        {t('Global:Global.text.download')}
      </button>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const EmployeeVarianceToast = ({ job, cb, baseApiUsage, t }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small' style={{ maxWidth: '180px' }}>
        {t('BusinessComponent.toast.the_prefix')}
        <strong>{payrollInstance.shortName}</strong> {t('BusinessComponent.toast_element_reconciliation.alternate_ready')}
      </span>
      <button
        id={`entity-${job.entityId}`}
        className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-left-small u-margin-right-tiny u-margin-bottom-tiny'
        title={t('Global:Global.text.download')}
        onClick={() => {
          onCompleteToast()
        }}
      >
        {t('Global:Global.text.download')}
      </button>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const ReportCompletedToast = ({ job, cb, baseApiUsage, t }) => {
  const dispatch = useDispatch()

  const builder = new ReportItemBuilder()
  const feCategoryId = job.filters.feCategoryId
  const reportName = builder.getReportNameCategory(feCategoryId)

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {t('BusinessComponent.toast.your_prefix')}
        <strong>{reportName}</strong> {t('BusinessComponent.toast_reports.ready')}
      </span>
      <div
        className='position-absolute u-absolute--top-right'
        onClick={(_) => {
          onCompleteToast(true)
        }}
      >
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const CotReportCompletedToast = ({ job, baseApiUsage, t }) => {
  const dispatch = useDispatch()

  const onCompleteToast = (justFlagAsRead) => {
    // Stop polling while we are downloading
    dispatch(setStartPolling({ shouldStartPolling: false }))

    if (justFlagAsRead) {
      dispatch(flagAsRead(job.id, job, baseApiUsage))
    } else {
      dispatch(downloadReport(job.entityId)).then(() => {
        dispatch(flagAsRead(job.id, job, baseApiUsage))
      })
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small' style={{ maxWidth: '180px' }}>
        {t('BusinessComponent.toast_reports.report_is_ready')}
      </span>
      <button
        id={`entity-${job.entityId}`}
        className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-left-small u-margin-right-tiny u-margin-bottom-tiny'
        title={t('Global:Global.text.download')}
        onClick={() => {
          onCompleteToast()
        }}
      >
        {t('Global:Global.text.download')}
      </button>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const FetchedPayfileExportView = Fetcher(PayrunFileToast, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, completed: true }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedPayfileImportView = Fetcher(PayrunFileImportToast, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedPayrunVarianceView = Fetcher(PayrunVarianceToast, [
  {
    name: 'payrollInstances',
    isForceFetching: false,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, completed: true }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedElementVarianceView = Fetcher(ElementVarianceToast, [
  {
    name: 'payrollInstances',
    isForceFetching: false,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, completed: true }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedEmployeeVarianceView = Fetcher(EmployeeVarianceToast, [
  {
    name: 'payrollInstances',
    isForceFetching: false,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, completed: true }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const ToastCompletedViews = ({ job, cb, baseApiUsage }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  let View = null
  const state = useSelector((state) => state)
  const isAuthenticated = state.auth.accessToken
  const isCotUser = useSelector((state) => isCot(state))
  const isVendorUser = useSelector((state) => isVendorInvolved(state))
  const vendorTenant = useSelector((state) => state.vendorTenant)
  if (isVendorUser && !vendorTenant.url) {
    return View
  }
  if (isCotUser && window.location.pathname.indexOf('vendors') !== -1 && !vendorTenant.url) {
    return View
  }
  if (window.location.pathname.indexOf('pure') !== -1) {
    return View
  }
  if (!state.tenants.url && isCotUser && job.type === toastEntityTypesEnum.REPORT_CREATE_JOB) {
    View = <CotReportCompletedToast job={job} cb={downloadReport} baseApiUsage={baseApiUsage} />
  }

  if ((state.tenants.url || !isCotUser) && isAuthenticated) {
    switch (job.type) {
    case toastEntityTypesEnum.PAYFILE_EXPORT_JOB:
    case toastEntityTypesEnum.PAYFILE_CHANGES_EXPORT_JOB:
    case toastEntityTypesEnum.EMPTY_PAYFILE_EXPORT_JOB:
    case toastEntityTypesEnum.PAYFILE_EXPORT_ARCHIVE_JOB:
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} t={t} />
      break
    case toastEntityTypesEnum.PAYFILE_IMPORT_JOB:
      View = <FetchedPayfileImportView job={job} cb={cb} baseApiUsage={baseApiUsage} t={t} />
      break
    case toastEntityTypesEnum.VARIANCE_EXPORT_JOB:
      View = <FetchedPayrunVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} t={t} />
      break
    case toastEntityTypesEnum.ELEMENT_VARIANCE_EXPORT_JOB:
      View = <FetchedElementVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} t={t} />
      break
    case toastEntityTypesEnum.EMPLOYEE_VARIANCE_EXPORT_JOB:
      View = <FetchedEmployeeVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} t={t} />
      break
    case toastEntityTypesEnum.REPORT_CREATE_JOB:
      View = <ReportCompletedToast job={job} baseApiUsage={baseApiUsage} cb={cb} t={t} />
      break
    case toastEntityTypesEnum.EMPLOYEE_BULK_CREATE_JOB:
      View = (
        <BulkImportToast
          job={job}
          cb={cb}
          baseApiUsage={baseApiUsage}
          toastMessages={{
            withErrors: t('BusinessComponent.toast_employees.create_import_with_errors'),
            successful: t('BusinessComponent.toast_employees.create_import_successful'),
            seeResults: t('BusinessComponent.toast.see_results'),
          }}
          seeResultsPath='/import/employees'
          checkForErrors
          t={t}
        />
      )
      break
    case toastEntityTypesEnum.EMPLOYEE_BULK_UPDATE_JOB:
      View = (
        <BulkImportToast
          job={job}
          cb={cb}
          baseApiUsage={baseApiUsage}
          toastMessages={{
            withErrors: t('BusinessComponent.toast_employees.update_import_with_errors'),
            successful: t('BusinessComponent.toast_employees.update_import_successful'),
            seeResults: t('BusinessComponent.toast.see_results'),
          }}
          seeResultsPath='/import/employees-bulk'
          checkForErrors
          t={t}
        />
      )
      break
    default:
      break
    }
  }
  return View
}

// PropTypes Validation for PayrunFileToast
PayrunFileToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for PayrunFileImportToast
PayrunFileImportToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for PayrunVarianceToast
PayrunVarianceToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for ElementVarianceToast
ElementVarianceToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for EmployeeVarianceToast
EmployeeVarianceToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for ReportCompletedToast
ReportCompletedToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for CotReportCompletedToast
CotReportCompletedToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  baseApiUsage: PropTypes.object,
}

// PropTypes Validation for BulkImportToast
BulkImportToast.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
  toastMessages: PropTypes.shape({
    withErrors: PropTypes.string.isRequired,
    successful: PropTypes.string.isRequired,
    seeResults: PropTypes.string.isRequired,
  }).isRequired,
  seeResultsPath: PropTypes.string.isRequired,
  checkForErrors: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

// PropTypes Validation for ToastCompletedViews
ToastCompletedViews.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    entityId: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(toastEntityTypesEnum)).isRequired,
  }).isRequired,
  cb: PropTypes.func,
  baseApiUsage: PropTypes.object,
}
export default ToastCompletedViews
