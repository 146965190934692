import React from 'react'
import Back from 'components/Back'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = () => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])
  return (
    <div>
      <Back />
      <img src='/img/maps/process-map-3x.png' className='u-img--fluid' alt={t('Payroll.text.processMap')} />
    </div>
  )
}

export default RouteView
