import React from 'react'
import CheckboxField from 'components/form/CheckboxField'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'

const AssignCountryServices = ({ isFetching, handleSubmit, countryServices }) => {
  const { t } = useTranslation()

  const renderHeaders = () => {
    return [
      {
        Header: t('Global.text.service'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('Global.text.type'),
        accessor: 'serviceType',
        disableSortBy: true,
      },
      {
        Header: t('Global.text.assign'),
        accessor: 'assign',
        disableSortBy: true,
      },
    ]
  }

  const renderRows = (countryServices) => {
    return countryServices.map((countryService) => {
      return {
        name: countryService.service.name,
        serviceType: _.capitalize(countryService.service.type),
        assign: (
          <Field
            type='checkbox'
            component={CheckboxField}
            formGroupClassName='u-margin-none'
            className='control__indicator__input'
            label=''
            name={`id-${countryService.id}`}
          />
        ),
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className='o-layout'>
        <div className='o-layout__item'>
          <PayslipTable wrapperClassName='u-1/1' tableElementClassName='u-margin-bottom-small' headings={renderHeaders()} data={renderRows(countryServices)} />
        </div>
        <div className='o-layout__item u-text--center u-1/1'>
          <button className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'>{t('Global.button.save')}</button>
        </div>
      </div>
    </Form>
  )
}

AssignCountryServices.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  countryServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      service: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
      }),
    })
  ),
}

export default AssignCountryServices
