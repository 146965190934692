import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { createFilter } from 'utils/redux/filter'
import PropTypes from 'prop-types'
import { deleteReport, downloadReport, fetchReportsIfNeeded, setLastRunReportType } from 'redux/actions/reports'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchPayrollsIfNeeded } from 'redux/actions/payrolls'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { fetchDepartmentsIfNeeded } from 'redux/actions/departments'
import { fetchEmployeeSystemUsersIfNeeded } from 'redux/actions/employeeSystemUsers'
import { fetchTermCategoriesIfNeeded } from 'redux/actions/termCategories'
import { fetchTermSubCategoriesIfNeeded } from 'redux/actions/termSubCategories'
import { fetchTermsIfNeeded } from 'routes/Terms/modules/actions'
import { getSavedReports } from '../../../selectors/reports'
import SavedReportsList from '../components/SavedReportsList'
import { getLegacyReportTypes } from 'redux/config/reports'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import i18n from 'i18next'

class SavedReportsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch, employeeFilter } = this.props

    dispatch(fetchReportsIfNeeded())
    dispatch(fetchCurrenciesIfNeeded())
    dispatch(fetchCountriesIfNeeded())
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchPayrollsIfNeeded())
    dispatch(fetchBusinessUnitsIfNeeded())
    dispatch(fetchCostCentersIfNeeded())
    dispatch(fetchDepartmentsIfNeeded())
    dispatch(fetchEmployeeSystemUsersIfNeeded({ filter: employeeFilter }))
    dispatch(fetchTermCategoriesIfNeeded())
    dispatch(fetchTermSubCategoriesIfNeeded())
    dispatch(fetchTermsIfNeeded())
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <SavedReportsList {...this.props} />
  }
}

const mapStateToProps = (state, { reportItems }) => {
  const {
    reports,
    currencies,
    countries,
    companies,
    payrolls,
    businessUnits,
    costCenters,
    departments,
    employeeSystemUsers,
    termCategories,
    termSubCategories,
    terms,
  } = state
  const types = getLegacyReportTypes()

  const employeeFilter = createFilter({ isKeyPerson: 1 })
  const entities = [
    reports,
    currencies,
    countries,
    companies,
    payrolls,
    businessUnits,
    costCenters,
    departments,
    { ref: employeeSystemUsers, filter: employeeFilter },
    termCategories,
    termSubCategories,
    terms,
  ]

  if (isFetching(entities)) return { isFetching: true, employeeFilter }
  const savedReports = getSavedReports(state)
  const builder = new ReportItemBuilder()

  savedReports.map((rep) => {
    rep['foundType'] = types.find((type) => type.type === rep.type)
    let cat = rep['foundType'].categories.find((cat) => cat.value === rep.category).label
    let subCat = rep['foundType'].subcategories.find((subcat) => subcat.value === rep.subcategory).label
    if (subCat === 'Terms') subCat = rep.filters.termCategoryNames.join(', ')
    rep['cat'] = cat
    rep['subCat'] = subCat
    rep['name'] = rep.feCategoryId ? builder.getReportNameCategory(rep.feCategoryId) : rep['foundType'].name
    rep['categoryName'] = rep.feCategoryId ? builder.getReportTypeCategory(rep.feCategoryId) : null
    return rep
  })

  return {
    reports: savedReports,
    types,
    employeeFilter,
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return {
    dispatch,
    downloadReport: (id) => dispatch(downloadReport(id)),
    deleteReport: (id) =>
      dispatch(deleteReport(id))
        .then(() => {
          dispatch(setLastRunReportType({}))
          dispatch(
            showMessage({
              body: i18n.t('Global:Global.message.deleted_body'),
            })
          )
        })
        .catch((error) =>
          dispatch(
            showMessage({
              body: errorToString(error),
            })
          )
        ),
  }
}

SavedReportsContainer.propTypes = {
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedReportsContainer)
