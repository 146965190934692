import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import VendorUsersListContainer from '../containers/VendorUsersListContainer'
import VendorUserFormCreateContainer from '../containers/VendorUserFormCreateContainer'
import VendorUserFormEditContainer from '../containers/VendorUserFormEditContainer'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'
import { getExternalCompanies } from 'redux/selectors/externalCompanies'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = ({ dispatch, ...props }) => {
  const [vendorUserId, setVendorUserId] = useState(null)
  const createModalRef = useRef(null)
  const editModalRef = useRef(null)
  const externalCompanies = useSelector((state) => getExternalCompanies(state))
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  useEffect(() => {
    dispatch(getWhiteLabeledBrandStyles())
  }, [dispatch])

  const handleEditModalOpen = (id) => {
    setVendorUserId(id)
    editModalRef.current.showModal()
  }

  return (
    <div>
      <Modal ref={createModalRef} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Vendor.text.add_vendor')}>
        <VendorUserFormCreateContainer onCreate={() => createModalRef.current.hideModal()} {...props} externalCompanies={externalCompanies} />
      </Modal>

      <Modal ref={editModalRef} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Vendor.text.edit_vendor_info')}>
        <VendorUserFormEditContainer
          vendorUserId={vendorUserId}
          onEdit={() => editModalRef.current.hideModal()}
          {...props}
          externalCompanies={externalCompanies}
        />
      </Modal>

      <VendorUsersListContainer onCreate={() => createModalRef.current.showModal()} onEdit={handleEditModalOpen} {...props} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

RouteView.propTypes = {
  dispatch: PropTypes.func,
}

const Container = connect(null, mapDispatchToProps)(RouteView)
export default Container
