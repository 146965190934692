// Translation Keys
import { i18nNameSpaces } from 'i18n/types'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import EmergencyPersonFields from 'components/EmergencyPersonFields'
import ContractorFields from 'components/ContractorFields'
import { createEmployeeFields, renderFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import fieldSubCategoriesEnum from 'utils/enums/employees/fieldSubCategoriesEnum'
import useEmployeeCustomFields from 'hooks/useEmployeeCustomFields'
import { retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'

const EmployeePersonalFormFields = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const { selectedCountryName, EmployeeStatus, selectedCompany } = props
  const [isEmergencyPersonFieldsVisible, setIsEmergencyPersonFieldsVisible] = React.useState(false)
  const [country, setCountry] = React.useState(selectedCountryName)
  const { fields } = useEmployeeCustomFields(retrieveCompanyCustomFields, selectedCompany, [selectedCompany])

  return (
    <>
      <SectionHeading text={t('Employee.personal_form_sectionHeading.personalDetails')} priority='3' />
      <div className='o-layout o-grid--strech'>
        {createEmployeeFields({
          fields: employeeFieldsConfigAlt({ isCreate: true }),
          section: 'personal',
          subSection: fieldSubCategoriesEnum.PERSONAL_DETAILS,
          country,
          extraFields: fields,
        }).map((field) => renderFields(field, setCountry))}
      </div>

      <SectionHeading text={t('Employee.personal_form_sectionHeading.biographicalDetails')} priority='3' />
      <div className='o-layout o-grid--strech'>
        {createEmployeeFields({
          fields: employeeFieldsConfigAlt({ isCreate: true }),
          section: 'personal',
          subSection: fieldSubCategoriesEnum.BIOGRAPHICAL_DETAILS,
          country,
          extraFields: fields,
        }).map((field) => renderFields(field, setCountry))}
      </div>

      <SectionHeading text={t('Employee.personal_form_sectionHeading.taxDetails')} priority='3' />
      <div className='o-layout o-grid--strech'>
        {createEmployeeFields({
          fields: employeeFieldsConfigAlt({ isCreate: true }),
          section: 'personal',
          subSection: fieldSubCategoriesEnum.TAX_DETAILS,
          country,
          extraFields: fields,
        }).map((field) => renderFields(field, setCountry))}
      </div>

      <SectionHeading text={t('Employee.personal_form_sectionHeading.contactDetails')} priority='3' />
      <div className='o-layout o-grid--strech'>
        {createEmployeeFields({
          fields: employeeFieldsConfigAlt({ isCreate: true }),
          section: 'personal',
          subSection: fieldSubCategoriesEnum.CONTACT_DETAILS,
          secondarySubSection: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
          country,
          extraFields: fields,
        }).map((field) => renderFields(field, setCountry))}
      </div>

      {EmployeeStatus.shouldShowContractArea && (
        <div>
          <SectionHeading text={t(`Employee.personal_form_sectionHeading.contractDetails.${EmployeeStatus.sectionHeadingKey}`)} priority='3' />
          <ContractorFields {...props} />
        </div>
      )}

      <SectionHeading text={t('Employee.personal_form_sectionHeading.emergencyPersonContactDetails')} priority='3' />
      <div className='o-layout'>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
            {!isEmergencyPersonFieldsVisible && (
              <p className='u-text--normal u-padding-top-small'>
                {t('Employee.personal_form.text.addEmergencyPerson', {
                  clickHere: (
                    <span
                      onClick={() => setIsEmergencyPersonFieldsVisible(!isEmergencyPersonFieldsVisible)}
                      className='u-text--curious u-cursor--pointer'
                      data-testid='bank-account-create'
                    >
                      {t('Global:Global.text.clickHere')}
                    </span>
                  ),
                })}
              </p>
            )}
          </Authorization>
        </div>
      </div>
      {isEmergencyPersonFieldsVisible && <EmergencyPersonFields {...props} />}

      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </>
  )
}

EmployeePersonalFormFields.propTypes = {
  EmployeeStatus: PropTypes.object,
  selectedCompany: PropTypes.number,
  selectedCountryName: PropTypes.string,
}

export default EmployeePersonalFormFields
