import React from 'react'
import { useTranslation } from 'react-i18next'

import ImportFacade from '../../../containers/ImportFacade'
import { downloadEmployeeTermsTemplate, downloadEmployeeTermsWrongImportedFile } from 'redux/actions/employeeTermPivot'
import { importEmployeeTerms } from 'redux/actions/employeeTermPivotEnhanced'
import { showMessage } from 'redux/actions/modal'
import SectionHeading from 'components/SectionHeading'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading fullWidth text={t('Import.terms_clarifications.heading')} />
      <p className='u-margin-bottom-small'>{t('Import.terms_clarifications.description')}</p>
      <ul className='margin-bottom-small'>
        <li>{t('Import.terms_clarifications.list.item1')}</li>
        <li>{t('Import.terms_clarifications.list.item2')}</li>
        <li>{t('Import.terms_clarifications.list.item3')}</li>
        <li>{t('Import.terms_clarifications.list.item4')}</li>
      </ul>
      <u>{t('Import.terms_clarifications.error_message.prefix')}</u>: {t('Import.terms_clarifications.error_message.description')}
    </div>
  )
}

const RouteView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <ImportFacade
      {...props}
      heading={t('Import.terms_heading.import_employee_terms')}
      templateSubHeading={t('Import.terms_templateSubHeading.warning')}
      TemplateClarifications={TemplateClarifications}
      entityName='employee terms'
      downloadTemplateAction={({ payrollId }) =>
        (dispatch) =>
          dispatch(downloadEmployeeTermsTemplate(payrollId)).then(() =>
            dispatch(
              showMessage({
                body: t('Import.terms_notification.message1'),
              })
            )
          )
      }
      downloadAction={downloadEmployeeTermsWrongImportedFile}
      importAction={({ payrollId, file }) => importEmployeeTerms(payrollId, file)}
    />
  )
}
export default RouteView
