import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ErrorView = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='o-layout o-grid o-grid--center'>
        <div className='o-layout__item u-text--center u-1/1 u-1/2@tablet u-1/3@desktop'>
          <div className='u-text--enormous u-margin-top-huge'>404</div>
          <div className='u-text--huge u-uppercase'>{t('Global.error.page_not_found')}</div>
          <NavLink to='/' className='c-btn c-btn--small c-btn--curious u-margin-top-large'>
            {t('Global.error.home_button')}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default ErrorView
