import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import Dropzone from 'react-dropzone'
import InputField from 'components/form/InputField'
import { makeMinimumScaleValidation, required } from 'utils/validations'
import CheckboxOption from 'components/form/select/CheckboxOption'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const minValueValidation = makeMinimumScaleValidation(0)

export const DocumentClassificationUploadForm = (props) => {
  const { disabled, disabledReason, showSubmitBtn, uploadFile, resetForm, documentTypes } = props

  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const dropzoneRef = useRef()

  return (
    <div className='o-layout'>
      <Form>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='documentTypes'
              label={t('BusinessComponent.form.documentType')}
              component={CustomSelectField}
              optionComponent={CheckboxOption}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              disabled={disabled}
              disabledReason={disabledReason}
              onChange={resetForm}
              options={documentTypes.map((type) => ({ value: type.id, label: type.text }))}
              validate={required}
              multi
              data-testid='documentTypes-wrapper'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-top u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='version'
              label={t('Global:Global.text.version')}
              labelClassName='c-label'
              component={InputField}
              type='number'
              validate={minValueValidation}
              data-testid='version'
            />
          </div>
        </div>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-padding-small'>
            <p className='u-text--small u-text--center'>{t('BusinessComponent.text.uploadFileWarning')}</p>
          </div>
        </div>
        {showSubmitBtn && (
          <div className='o-layout__item u-text--center u-margin-top u-1/1 u-1/1@tablet'>
            <button
              type='button'
              className='c-btn c-btn--small c-btn--curious u-text--small u-margin-left-tiny u-align-self--center'
              onClick={() => dropzoneRef.current?.open()}
              data-testid='select-file'
            >
              {t('Global:Global.select.selectFile')}
            </button>
          </div>
        )}
      </Form>
      <Dropzone onDrop={(files) => uploadFile(files[0])} ref={dropzoneRef} className='u-relative' />
    </div>
  )
}

DocumentClassificationUploadForm.propTypes = {
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showSubmitBtn: PropTypes.bool,
  uploadFile: PropTypes.func,
  resetForm: PropTypes.func,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
}

export default DocumentClassificationUploadForm
