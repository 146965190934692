import React from 'react'
import { ChangeLanguageView } from '../components/ChangeLanguageView'
import { useUserData } from 'hooks/useUserData'
import Loader from 'components/Loader'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import { useSelector } from 'react-redux'
import { getTenantLocales } from 'redux/selectors/tenants'
import { formatToValueLabelPair } from 'utils/selectOptions'

export const ChangeLanguageContainer = () => {
  const { locale, onLocaleChange, isFetching, isCot } = useUserData()
  const { t } = useTranslation(i18nNameSpaces.Setting)

  const tenantLocales = useSelector(getTenantLocales)

  // This feature currently does not include Cot users
  if (isCot) return null

  if (isFetching) return <Loader />

  const options = tenantLocales.map((locale) => formatToValueLabelPair(locale, 'id', 'label'))

  const isDisabled = options.length <= 1 // Disable if there's only one option

  return (
    <ChangeLanguageView
      onChange={onLocaleChange}
      options={options}
      value={locale}
      isDisabled={isDisabled}
      disabledTooltip={isDisabled ? t('Setting.user.language_change_disabled_tooltip') : ''}
      t={t}
    />
  )
}
