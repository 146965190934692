import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isFetching, isFetchingByCustomFilter } from 'utils/redux/fetching'
import { deleteVendorUser, fetchVendorUsersIfNeeded } from 'redux/actions/vendorUsers'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { getVendorAAsByLoggedUserPermissions, getVendorUsers } from 'redux/selectors/vendorUsers'
import VendorUsersView from '../components/VendorUsersView'
import { fetchExternalCompanies } from 'redux/actions/externalCompanies'
import { showMessage } from 'redux/actions/modal'
import { withTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const VendorUsersListContainer = (props) => {
  const { dispatch } = props
  const fetchData = async () => {
    await dispatch(fetchExternalCompanies())
    dispatch(fetchVendorsIfNeeded())
    dispatch(fetchVendorUsersIfNeeded())
    dispatch(fetchCountriesIfNeeded())
  }

  useEffect(() => {
    fetchData()
  }, [dispatch])

  return <VendorUsersView {...props} />
}

VendorUsersListContainer.propTypes = {
  dispatch: PropTypes.func,
}

const mapDispatchToProps = (dispatch, { t }) => {
  return {
    deleteVendorUser: (id) =>
      dispatch(deleteVendorUser(id)).catch((e) => {
        dispatch(
          showMessage({
            title: t('Vendor.error.cannot_delete_user'),
            body: Object.values(e.errors),
          })
        )
      }),
    dispatch,
  }
}

const mapStateToProps = (state) => {
  const { vendors, vendorUsers, countries, externalCompanies } = state
  const entities = [vendors, vendorUsers, countries]
  const isFetchingExternalCompanies = isFetchingByCustomFilter('/', externalCompanies)

  if (isFetching(entities) || isFetchingExternalCompanies) return { isFetching: true }

  return {
    vendorUsers: getVendorUsers(state),
    vendorAccessAreas: getVendorAAsByLoggedUserPermissions(state),
  }
}

export default withTranslation(i18nNameSpaces.Vendor)(connect(mapStateToProps, mapDispatchToProps)(VendorUsersListContainer))
