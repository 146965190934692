import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { useTranslation } from 'react-i18next'

const ConfirmButton = ({ buttonText, disabled = false, ...rest }) => {
  const { t } = useTranslation()
  const defaultText = buttonText || t('Global.button.yes')
  return (
    <Button
      disabled={disabled}
      className={`c-btn c-btn--small c-btn--curious u-padding-left u-padding-right ${disabled ? 'disabled' : ''}`}
      data-testid='confirm-button'
      {...rest}
    >
      {defaultText}
    </Button>
  )
}

ConfirmButton.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ConfirmButton
