import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'

const ClientsList = ({ clients, selectTenant, resetTenant, vendorId, history }) => {
  const { t } = useTranslation()

  const renderData = (clients) => {
    return clients.map((client) => ({
      id: client.id,
      url: client.url,
      name: <span data-testid={`client-${client.id}`}>{client.name}</span>,
      schema: client.schema,
    }))
  }

  const formatNameColumn = ({
    row: {
      values: { id, name, url, schema },
    },
  }) => {
    return (
      <div
        onClick={() => {
          resetTenant()
          selectTenant({ url: url, schema: schema, shouldUse: true })
          history.push(`/vendors/${vendorId}/clients/${id}/companies`)
        }}
      >
        {name}
      </div>
    )
  }

  const options = {
    searchPosition: 'left',
    noDataText: t('Global.text.no_matching_records_found'),
  }

  return (
    <>
      <SectionHeading text={t('Global.heading.clients_list')} />
      <PayslipTable
        data={renderData(clients)}
        headings={[
          { accessor: 'id', Header: t('Global.text.id'), isKey: true, isVisible: false },
          { accessor: 'url', Header: t('Global.text.url'), isVisible: false, disableFilters: true },
          { accessor: 'schema', Header: t('Global.text.schema'), isVisible: false, disableFilters: true },
          {
            accessor: 'name',
            Header: t('Global.text.name'),
            columnClassName: 'u-text--curious',
            Cell: formatNameColumn,
            disableFilters: true,
          },
        ]}
        wrapperClassName='u-1/1 u-1/2@tablet u-1/3@desktop'
        trClassName='react-bs-table__cell--overflow-ellipsis u-cursor--pointer'
        trBodyClassName='u-cursor--pointer'
        options={options}
        search
      />
    </>
  )
}

ClientsList.propTypes = {
  clients: PropTypes.array.isRequired,
  selectTenant: PropTypes.func.isRequired,
  resetTenant: PropTypes.func.isRequired,
  vendorId: PropTypes.number.isRequired,
  history: PropTypes.object,
}

export default ClientsList
