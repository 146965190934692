import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Field, reduxForm } from 'redux-form'

import FieldDatePicker from 'components/form/FieldDatePicker'

let classNames = require('classnames')

/**
 * Color schemes for highlighting week ranges
 */
const curious = 'curious'
const inherit = 'inherit'

const getViewBtnsClassNames = (currentView, view) => {
  return classNames({
    'c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-left-small u-text--valhalla': true,
    active: currentView === view,
  })
}

const highlightDatePickerWeek = (date, view) => {
  const color = view === 'week' ? curious : inherit

  const newDate = moment(date)
  const dateNumber = newDate.date()
  const dateWeekDay = newDate.day()
  const dateMonth = newDate.month()
  const dateYear = newDate.year()
  let firstWeekDay

  if (dateWeekDay) {
    firstWeekDay = dateNumber - dateWeekDay
  } else {
    firstWeekDay = dateNumber - 7
  }

  const firstWeekDayDate = moment().year(dateYear).month(dateMonth).date(firstWeekDay)

  return [
    {
      [`react-datepicker__day--highlighted-${color}`]: [
        moment(firstWeekDayDate).add(1, 'days').toDate(),
        moment(firstWeekDayDate).add(2, 'days').toDate(),
        moment(firstWeekDayDate).add(3, 'days').toDate(),
        moment(firstWeekDayDate).add(4, 'days').toDate(),
        moment(firstWeekDayDate).add(5, 'days').toDate(),
        moment(firstWeekDayDate).add(6, 'days').toDate(),
        moment(firstWeekDayDate).add(7, 'days').toDate(),
      ],
    },
  ]
}

const CalendarToolbar = (props) => {
  const { date, change, onChanged, label, goToBack, goToNext, goToCurrent, goToDayView, goToWeekView, goToMonthView, view } = props
  const [datePickerStartDate, setDatePickerStartDate] = useState(moment(date).toDate())
  const { t } = useTranslation()

  useEffect(() => {
    if (!moment(datePickerStartDate).isSame(date, 'day')) {
      setDatePickerStartDate(moment(date).toDate())
      change('calendarDatepicker', date)
    }
  }, [date, datePickerStartDate, change])

  const handleDatePickerChange = (selectedDate) => {
    setDatePickerStartDate(selectedDate)
    onChanged(selectedDate)
  }

  return (
    <div className={view === 'week' ? 'c-calendar__toolbar--week' : null}>
      <div className='o-layout u-margin-bottom'>
        <div className='o-layout__item u-padding-bottom-tiny u-1/1'>
          <div
            onClick={goToCurrent}
            // eslint-disable-next-line max-len
            className='c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-right-small u-margin-bottom-tiny u-text--valhalla'
          >
            {t('Global.calendar.today')}
          </div>
          <div className='o-grid--inline-flex o-grid--middle'>
            <div className='u-cursor--pointer' style={{ height: '100%', width: '20px' }} onClick={goToBack}>
              <span className='icon--arrow left icon--arrow--hover-left' />
            </div>
            <div className='u-text--small u-padding-left-small u-padding-right-small u-relative'>
              {label}
              <Field
                className='c-calendar__datepicker'
                name='calendarDatepicker'
                component={FieldDatePicker}
                formGroupClassName='u-margin-none u-height-0'
                labelClassName='c-label u-text--valhalla'
                highlightDates={highlightDatePickerWeek(datePickerStartDate, view)}
                onChanged={handleDatePickerChange}
                todayButton={t('Global.calendar.today')}
                selected={datePickerStartDate}
                showWeekNumbers
              />
            </div>
            <div className='u-cursor--pointer' style={{ height: '100%', width: '20px' }} onClick={goToNext}>
              <span className='icon--arrow right icon--arrow--hover-right' />
            </div>
          </div>
          <div onClick={goToDayView} className={getViewBtnsClassNames(view, 'day')}>
            {t('Global.calendar.day')}
          </div>
          <div onClick={goToWeekView} className={getViewBtnsClassNames(view, 'week')}>
            {t('Global.calendar.week')}
          </div>
          <div onClick={goToMonthView} className={getViewBtnsClassNames(view, 'month')}>
            {t('Global.calendar.month')}
          </div>
        </div>
      </div>
    </div>
  )
}

CalendarToolbar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  change: PropTypes.func.isRequired,
  onChanged: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  goToBack: PropTypes.func.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToCurrent: PropTypes.func.isRequired,
  goToDayView: PropTypes.func.isRequired,
  goToWeekView: PropTypes.func.isRequired,
  goToMonthView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
}

export default reduxForm({
  form: 'calendarPicker',
})(CalendarToolbar)
