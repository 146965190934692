import React, { useRef, useState } from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import Modal from 'components/Modal'
import GlobalDataProtectionContainer from '../containers/GlobalDataProtectionContainer'
import GlobalDataProtectionEditContainer from '../containers/GlobalDataProtectionEditContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const [dataFieldId, setDataFieldId] = useState(null)
  const editModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.DataProtection])
  return (
    <InnerWrapper>
      <Modal ref={editModalRef} className='c-modal c-modal--half c-modal--overflow-y' modalHeading={t('DataProtection.text.edit_data_protection_field')}>
        <GlobalDataProtectionEditContainer dataFieldId={dataFieldId} onEdit={() => editModalRef.current.hideModal()} />
      </Modal>
      <GlobalDataProtectionContainer
        onEdit={(dataFieldId) => {
          setDataFieldId(dataFieldId)
          editModalRef.current.showModal()
        }}
        {...props}
      />
    </InnerWrapper>
  )
}

export default RouteView
