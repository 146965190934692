import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const DataProtectionResourcesList = () => {
  const { t } = useTranslation([i18nNameSpaces.DataProtection])

  return (
    <PayslipTable
      data={[
        {
          field: (
            <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.field.personalDataDefinition.title')}</div>
              <div className='u-text--normal o-layout__item u-2/3'>{t('DataProtection.field.personalDataDefinition.description')}</div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.field.disclaimer.title')}</div>
              <div className='u-text--normal o-layout__item u-2/3'>{t('DataProtection.field.disclaimer.description')}</div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.field.article29.title')}</div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a href={t('DataProtection.field.article29.link')} target='blank_' className='u-text--curious'>
                  {t('DataProtection.field.article29.link')}
                </a>
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.field.bindingCorporateRules.title')}</div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a href={t('DataProtection.field.bindingCorporateRules.link')} target='blank_' className='u-text--curious'>
                  {t('DataProtection.field.bindingCorporateRules.link')}
                </a>
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.field.bcrClosedCompanies.title')}</div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a href={t('DataProtection.field.bcrClosedCompanies.link')} target='blank_' className='u-text--curious'>
                  {t('DataProtection.field.bcrClosedCompanies.link')}
                </a>
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>{t('DataProtection.field.privacyShield.title')}</div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a href={t('DataProtection.field.privacyShield.link')} target='blank_' className='u-text--curious'>
                  {t('DataProtection.field.privacyShield.link')}
                </a>
              </div>
            </div>
          ),
        },
      ]}
      headings={[
        {
          accessor: 'field',
          Header: t('DataProtection.headings.title'),
          isKey: true,
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        },
      ]}
      wrapperClassName='u-margin-bottom u-1/1'
      trClassName='react-bs-table__cell--whitespace--normal'
    />
  )
}

export default DataProtectionResourcesList
