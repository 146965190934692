import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/dashboard',
  exact: true,
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Dashboard, RouteView) },
  authProtected: true,
  wrappedComponent: CoreLayout,
})
