import React from 'react'
import EmployeeSearchListContainer from '../containers/EmployeeSearchListContainer'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import InnerWrapper from 'layouts/InnerWrapper'
import SectionHeading from 'components/SectionHeading'
import useQuery from 'hooks/useQuery'
import { useTranslation } from 'react-i18next'

export default (props) => {
  const query = useQuery()
  const { t } = useTranslation()
  return (
    <InnerWrapper>
      <SectionHeading text={t('Global.aside.people')} />
      <EmployeesSearchBarContainer showAddBtn enablePagination {...props} query={query} />
      <EmployeeSearchListContainer enablePagination {...props} query={query} />
    </InnerWrapper>
  )
}
