import React, { memo } from 'react'

import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { ConfirmButton } from 'components/buttons'
import HistoryList from 'components/documents/HistoryList'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const HistoryModal = memo(({ modalRef, className, heading, logs }) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const onConfirmation = () => modalRef?.current?.hideModal()
  const headingsOverrides = {
    className: 'text-left',
    columnClassName: 'text-left',
  }
  return (
    <BaseConfirmationModal ref={modalRef} className={`c-modal c-modal--overflow-y ${className || ''}`} modalHeading={heading}>
      <HistoryList logs={logs} headingsOverrides={headingsOverrides} />
      <ConfirmButton buttonText={t('Global:Global.text.upper_ok')} onClick={onConfirmation} />
    </BaseConfirmationModal>
  )
})
