import React, { useRef } from 'react'
import { Field, reduxForm } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Dropzone from 'react-dropzone'
import { uploadForm } from '../utils/resourcesOptions'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const formName = 'resourcesUploadForm'

const ResourcesUploadForm = ({ showSelectFile, uploadFile }) => {
  const dropzoneRef = useRef()
  const { t } = useTranslation([i18nNameSpaces.Resource])

  return (
    <div className='o-layout'>
      <Form>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='accessibleBy'
              label={t('Resource.form.portal_required', { requiredSymbol: '*' })}
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              options={uploadForm}
            />
          </div>
        </div>
        {showSelectFile && (
          <div className='o-layout__item u-text--center u-margin-top u-1/1 u-1/1@tablet'>
            <button
              type='button'
              className='c-btn c-btn--small c-btn--curious u-text--small u-margin-left-tiny u-align-self--center'
              onClick={() => dropzoneRef.current?.open()}
            >
              {t('Global:Global.select.selectFile')}
            </button>
          </div>
        )}
      </Form>
      <Dropzone
        onDrop={(files) => uploadFile(files[0])}
        // eslint-disable-next-line no-return-assign
        ref={dropzoneRef}
        className='u-relative'
      />
    </div>
  )
}

export default reduxForm({
  form: formName,
})(ResourcesUploadForm)
