import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import AccordionContainer from 'containers/AccordionContainer'

import BankAccountContainer from 'containers/BankAccountContainer'
import BankAccountFormCreateContainer from 'containers/BankAccountFormCreateContainer'
import ContractorContainer from 'containers/ContractorContainer'
import EmergencyPersonContainer from 'containers/EmergencyPersonContainer'
import Modal from 'components/Modal'
import MobileDetailsView from 'components/MobileDetailsView'
import SectionHeading from 'components/SectionHeading'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import { extractKeyValuePairFromArray, getContractorNameField, getFieldsBySection } from 'utils/employee'
import PayslipTable from 'components/table/PayslipTable'
import { EditableCellDataEmployeeFieldsFormatter } from 'components/table/cell/EditableCellDataEmployeeFieldsFormatter'
import EmployeeFieldsWithAdd from './EmployeeFieldsWithAdd'
import AddNewEmployeeAddressContainer from '../containers/AddNewEmployeeAddressContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getDefaultNestedColumns = (t) => [
  {
    Header: t('Global:Global.text.title'),
    accessor: 'title',
    disableSortBy: true,
    classNames: 'd-none',
  },
  {
    Header: 'Global.text.value',
    accessor: 'value',
    disableSortBy: true,
    cellClassName: 'w-100 u-border--mischka',
    classNames: 'd-none',
    editable: true,
    Cell: (props) => {
      return <EditableCellDataEmployeeFieldsFormatter {...props} />
    },
  },
]
const GlobalErrorMessage = ({ text }) => <div className='text-center u-text--normal error'>{text}</div>

function PersonalView (props) {
  const {
    isFetching,
    EmployeeStatus,
    isEmployeeOnly,
    employee,
    inEditMode,
    onUpdateGlobalDataForSave,
    fieldErrors,
    onDeleteBankAccount,
    onDeleteEmployeeAddress,
    bankAccounts,
    employeeAddresses,
    extraFields,
    onValueChange,
    globalAddressErrors,
    globalAddressTooManyErrors,
    globalBankAccountErrors,
    globalBankAccountTooManyErrors,
    data: { personalDetails, biographicalDetails, employeeUsersAddressDetails, taxDetails, contactDetails, bankDetails },
  } = props
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const modalCreateBankAccount = useRef(null)
  const modalCreateEmployeeAddress = useRef(null)

  const getEmployeePersonalFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    isEmployeeOnly: isEmployeeOnly,
    subSection: 'personalDetails',
    extraFields,
  })

  const getEmployeeBiographicalFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'biographicalDetails',
    extraFields,
  })

  const getEmployeeTaxFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'taxDetails',
    extraFields,
  })

  const getEmployeeContactFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'contactDetails',
    extraFields,
  })

  const getEmployeeContractorsFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'contractorDetails',
    extraFields,
  })

  const getEmployeeEmergencyPersonFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'emergencyPersonDetails',
    extraFields,
  })

  if (isFetching) return <div>Loading...</div>
  getEmployeeBiographicalFields.forEach((fields) => {
    if (fields.field === 'gender') {
      const value = fields.options.find((f) => f.value === fields.value)
      fields.value = value?.label || ''
    }
  })

  const editableOptions = {
    inGlobalEditMode: inEditMode,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
    fieldErrors: fieldErrors,
    onValueChange: onValueChange,
  }

  const mapAddressesForMobile = (addr) => {
    let address = []
    const field = employeeUsersAddressDetails[addr.id]
    field.forEach(({ title, value, metaData }) => {
      if (metaData.field === 'isPrimary') {
        value = value ? t('Global:Global.button.yes') : t('Global:Global.text.no')
      }

      address.push({
        name: title,
        value: value,
      })
    })
    return address
  }

  return (
    <>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile o-layout' : 'o-layout'}>
        <div className='o-layout__item u-1/1 u-1/3@tablet' data-testid='personal-details'>
          <PayslipTable
            data={personalDetails}
            dynamicValues
            data-testid={'personal-details-table'}
            editableOptions={editableOptions}
            headings={[
              {
                accessor: 'name',
                Header: t('Employee.text.personal_details'),
                disableSortBy: true,
                disableFilters: true,
                classNames: 'u-text--center',
                isKey: true,
                columns: getDefaultNestedColumns(t),
              },
            ]}
            wrapperClassName='u-margin-bottom u-1/1'
          />

          <PayslipTable
            data={contactDetails}
            dynamicValues
            editableOptions={editableOptions}
            headings={[
              {
                accessor: 'name',
                Header: t('Employee.personal_form_sectionHeading.contactDetails'),
                disableSortBy: true,
                disableFilters: true,
                classNames: 'u-text--center',
                isKey: true,
                columns: getDefaultNestedColumns(t),
              },
            ]}
            wrapperClassName='u-margin-bottom u-1/1'
          />
          <EmergencyPersonContainer editableOptions={editableOptions} defaultNestedColumns={getDefaultNestedColumns(t)} {...props} />
          {EmployeeStatus.shouldShowContractArea && (
            <div className='u-1/1'>
              <ContractorContainer editableOptions={editableOptions} defaultNestedColumns={getDefaultNestedColumns(t)} {...props} />
            </div>
          )}
        </div>

        <div className='o-layout__item u-1/1 u-1/3@tablet' data-testid='biographical-details'>
          <PayslipTable
            data={biographicalDetails}
            dynamicValues
            editableOptions={editableOptions}
            data-testid={'biographical-details-table'}
            headings={[
              {
                accessor: 'name',
                Header: t('Employee.personal_form_sectionHeading.biographicalDetails'),
                disableSortBy: true,
                disableFilters: true,
                classNames: 'u-text--center',
                isKey: true,
                columns: getDefaultNestedColumns(t),
              },
            ]}
            wrapperClassName='u-margin-bottom u-1/1'
          />
          <div data-testid='employee-user-address-details'>
            {globalAddressErrors && <GlobalErrorMessage text={t('Employee.error.one_primary_address_required')} />}
            {globalAddressTooManyErrors && <GlobalErrorMessage text={t('Employee.error.only_one_primary_address_allowed')} />}
            <EmployeeFieldsWithAdd
              identifier='employee-user-address'
              header={t('Employee.text.address_details')}
              headerKey='type'
              onDeleteClick={(id) => {
                if (!props.inEditMode) onDeleteEmployeeAddress(id)
              }}
              onAddClick={() => modalCreateEmployeeAddress.current.showModal()}
              addText={t('Employee.text.add_new_address')}
              permissions={{
                create: ['EMPLOYEEUSERADDRESS_CREATE'],
                delete: ['EMPLOYEEUSERADDRESS_DELETE'],
                edit: ['EMPLOYEEUSERADDRESS_EDIT'],
              }}
              editableOptions={editableOptions}
              inEditMode={inEditMode}
              items={employeeAddresses}
              data={employeeUsersAddressDetails}
              headings={[
                {
                  accessor: 'name',
                  Header: t('Employee.text.address_details'),
                  classNames: 'text-center',
                  disableFilters: true,
                  disableSortBy: true,
                  isKey: true,
                  columns: getDefaultNestedColumns(t),
                },
              ]}
            />
          </div>
        </div>

        <div className='o-layout__item u-1/1 u-1/3@tablet' data-testid='tax-and-id-details'>
          <PayslipTable
            data={taxDetails}
            dynamicValues
            editableOptions={editableOptions}
            headings={[
              {
                accessor: 'name',
                Header: t('Employee.personal_form_sectionHeading.taxDetails'),
                disableSortBy: true,
                disableFilters: true,
                classNames: 'u-text--center',
                isKey: true,
                columns: getDefaultNestedColumns(t),
              },
            ]}
            wrapperClassName='u-margin-bottom u-1/1'
          />
          <div data-testid='bank-account-details'>
            {globalBankAccountErrors && <GlobalErrorMessage text={t('Employee.text.one_bank_account_must_be_primary')} />}
            {globalBankAccountTooManyErrors && <GlobalErrorMessage text={t('Employee.text.only_one_bank_account_can_be_primary')} />}
            <EmployeeFieldsWithAdd
              identifier='bank-account'
              header={t('Employee.text.bank_details')}
              headerKey='bankCountryName'
              onDeleteClick={(id) => {
                if (!props.inEditMode) onDeleteBankAccount(id)
              }}
              onAddClick={() => modalCreateBankAccount.current.showModal()}
              addText={t('Employee.button.add_new_bank_account')}
              permissions={{
                create: ['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT'],
                delete: ['BANKACCOUNT_CREATE'],
                edit: ['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT'],
              }}
              editableOptions={editableOptions}
              inEditMode={inEditMode}
              items={bankAccounts}
              data={bankDetails}
              headings={[
                {
                  accessor: 'name',
                  Header: t('Employee.text.bank_details'),
                  classNames: 'text-center',
                  disableFilters: true,
                  disableSortBy: true,
                  isKey: true,
                  columns: getDefaultNestedColumns(t),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Modal
        ref={modalCreateBankAccount}
        className='c-modal c-modal--full'
        modalHeading={t('Employee.text.bank_account')}
        data-testid='bank-account-create-modal'
      >
        <BankAccountFormCreateContainer onSubmit={() => (modalCreateBankAccount?.current ? modalCreateBankAccount.current.hideModal() : null)} {...props} />
      </Modal>
      <Modal
        ref={modalCreateEmployeeAddress}
        className='c-modal c-modal--full'
        modalHeading={t('Employee.text.add_new_address')}
        data-testid='employee-address-create-modal'
      >
        <AddNewEmployeeAddressContainer
          onSubmit={() => (modalCreateEmployeeAddress?.current ? modalCreateEmployeeAddress.current.hideModal() : null)}
          {...props}
        />
      </Modal>
      {isEmployeeOnly && (
        <div className='u-hide-on-desktop'>
          <SectionHeading text={t('Global:Global.aside.personal')} />
          <AccordionContainer title={t('Employee.text.personal_details')} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeePersonalFields)} />
          </AccordionContainer>
          <AccordionContainer title={t('Employee.personal_form_sectionHeading.biographicalDetails')} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeBiographicalFields)} />
          </AccordionContainer>
          <AccordionContainer title={t('Employee.personal_form_sectionHeading.contactDetails')} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeContactFields)} />
          </AccordionContainer>
          <AccordionContainer title={t('Employee.text.address_details')} titleColor='minsk' fullWidth rounded>
            {employeeAddresses.map((addr) => (
              <MobileDetailsView rows={extractKeyValuePairFromArray(mapAddressesForMobile(addr))} temp />
            ))}
          </AccordionContainer>
          <AccordionContainer title={t('Employee.personal_form_sectionHeading.taxDetails')} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeTaxFields)} />
          </AccordionContainer>
          <BankAccountContainer {...props} />
          {EmployeeStatus.shouldShowContractArea && (
            <AccordionContainer title={EmployeeStatus.sectionHeading} titleColor='minsk' fullWidth rounded>
              <MobileDetailsView
                rows={extractKeyValuePairFromArray([
                  getContractorNameField({
                    employee,
                    withNameAndValue: true,
                  }),
                  ...getEmployeeContractorsFields,
                ])}
              />
            </AccordionContainer>
          )}
          <AccordionContainer title={t('Employee.text.emergency_person_details')} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeEmergencyPersonFields)} />
          </AccordionContainer>
        </div>
      )}
    </>
  )
}

GlobalErrorMessage.propTypes = {
  text: PropTypes.string,
}
PersonalView.propTypes = {
  isFetching: PropTypes.bool,
  employeeCustomFields: PropTypes.array,
  employeeAddresses: PropTypes.array,
  EmployeeStatus: PropTypes.object,
  employee: PropTypes.object,
  isEmployeeOnly: PropTypes.bool,
  fieldErrors: PropTypes.array,
  inEditMode: PropTypes.bool,
  data: PropTypes.object,
  bankAccounts: PropTypes.array,
  onUpdateGlobalDataForSave: PropTypes.func,
  onDeleteBankAccount: PropTypes.func,
  onDeleteEmployeeAddress: PropTypes.func,
  text: PropTypes.string,
  btnClasses: PropTypes.string,
  extraFields: PropTypes.object,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  globalAddressErrors: PropTypes.bool,
  globalAddressTooManyErrors: PropTypes.bool,
}

export default PersonalView
