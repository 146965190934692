import React, { useRef } from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import CompaniesContainer from '../containers/CompaniesContainer'
import CompanyFormCreateContainer from '../containers/CompanyFormCreateContainer'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Company])
  const createModalRef = useRef(null)

  return (
    <InnerWrapper>
      <Modal ref={createModalRef} className='c-modal c-modal--full c-modal--overflow-y' modalHeading={t('Company.modal.create_company')}>
        <CompanyFormCreateContainer onSubmit={() => createModalRef.current.hideModal()} {...props} />
      </Modal>
      <CompaniesContainer onCreate={() => createModalRef.current.showModal()} {...props} />
    </InnerWrapper>
  )
}

export default RouteView
