import React from 'react'
import { useTranslation } from 'react-i18next'
import ImportFacade from '../../../containers/ImportFacade'
import { download, downloadBankAccountsTemplate, importBankAccounts } from 'redux/actions/employeeSystemUsers'
import SectionHeading from 'components/SectionHeading'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.template_clarifications.heading')} />
      <p className='u-margin-bottom-small'>{t('Import.template_clarifications.description')}</p>
      <ul className='margin-bottom-small'>
        <li>{t('Import.template_clarifications.steps_1')}</li>
        <li>{t('Import.template_clarifications.steps_2')}</li>
        <li>{t('Import.template_clarifications.steps_3')}</li>
        <li>{t('Import.template_clarifications.steps_4')}</li>
        <li>{t('Import.template_clarifications.steps_5')}</li>
      </ul>
      <u>{t('Import.template_clarifications.error_message')}</u>
    </div>
  )
}

const RouteView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <ImportFacade
      {...props}
      heading={t('Import.route_view.heading')}
      entityName='bank accounts'
      TemplateClarifications={TemplateClarifications}
      downloadTemplateAction={({ payrollId, companyId, shouldUseCompanyAsFilter }) => {
        if (!shouldUseCompanyAsFilter) companyId = null
        return downloadBankAccountsTemplate(payrollId, companyId)
      }}
      downloadAction={download}
      importAction={({ payrollId, companyId, shouldUseCompanyAsFilter, file }) => {
        if (!shouldUseCompanyAsFilter) companyId = null
        return importBankAccounts(payrollId, companyId, file)
      }}
      shouldIncludeAllPayrollsOption
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-text--normal'>
          <SectionHeading text={t('Import.text.data_validations')} />
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>{t('Import.route_view.replace_new_desc', { lng: 'en' })}</span>
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>{t('Import.route_view.currency_desc', { lng: 'en' })}</span>
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>{t('Import.route_view.bank_account_country_desc', { lng: 'en' })}</span>
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>{t('Import.route_view.country_specific_fields_desc', { lng: 'en' })}</span>
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}

export default RouteView
