import React from 'react'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import AttachingToPaygroupContainer from '../containers/AttachingToPaygroupContainer'
import PaygroupHeadingContainer from '../../../../../containers/PaygroupHeadingContainer'
import InnerWrapper from 'layouts/InnerWrapper'
import useQuery from 'hooks/useQuery'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Employee])
  return (
    <InnerWrapper>
      <PaygroupHeadingContainer heading={t('Employee.heading.employees_assigning_to_paygroup')} {...props} />
      <EmployeesSearchBarContainer query={useQuery()} {...props} />
      <AttachingToPaygroupContainer {...props} />
    </InnerWrapper>
  )
}
