import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { createRegexForUppercaseAndDot } from 'utils/RegexBuilder'
import { workflowStepsEnum } from 'utils/enums/workflowSteps'
import { payrunStatusesEnum } from 'utils/enums/payrunStatuses'
import { useTranslation } from 'react-i18next'

const ApprovalButton = ({ payrollInstance, payrollInstanceId }) => {
  const history = useHistory()
  const finalReviewStepRegex = createRegexForUppercaseAndDot(workflowStepsEnum.FINAL_REVIEW_AND_AUTHORIZATION)
  const payslipStepRegex = createRegexForUppercaseAndDot(workflowStepsEnum.PAYSLIPS)
  const invoicesStepRegex = createRegexForUppercaseAndDot(workflowStepsEnum.INVOICES)
  const approvedStatuses = [payrunStatusesEnum.COMPLETED, payrunStatusesEnum.COMPLETED_WITH_EXTRA_TASKS, payrunStatusesEnum.REOPENED]
  const isOnFinalReviewStep = finalReviewStepRegex.test(payrollInstance.currentStepName)
  const isOnPayslipsStep = payslipStepRegex.test(payrollInstance.currentStepName)
  const isOnInvoicesStep = invoicesStepRegex.test(payrollInstance.currentStepName)
  const isApprovedStatus = approvedStatuses.includes(payrollInstance.status)
  const { t } = useTranslation()

  // If the current step of the payroll instance is not 'Final Review and Authorization' then button should be disabled
  const approvalButtonDisabled = !isOnFinalReviewStep

  // Default text of button is 'approve'
  let approvalButtonText = t('Global.text.approve')

  // If the current step of the payroll instance is 'Payslips' or 'Invoices' the text of the button should be 'approved'.
  // Or if the payroll instance status is 'completed', 'completed-with-extra-tasks' or 'reopened', the text of the button should be 'approved'
  if (isOnPayslipsStep || isOnInvoicesStep || isApprovedStatus) {
    approvalButtonText = t('Global.text.approved')
  }

  return (
    <button
      onClick={() => history.push(`/payruns/active?action=openActionsModal&id=${payrollInstanceId}`)}
      className='c-btn c-btn--small c-btn--curious u-padding-left u-margin-right-tiny float-right u-padding-right'
      disabled={approvalButtonDisabled}
    >
      {approvalButtonText}
    </button>
  )
}

export default ApprovalButton

ApprovalButton.propTypes = {
  payrollInstance: PropTypes.shape({
    currentStepName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  payrollInstanceId: PropTypes.string.isRequired,
}
