import i18n from 'i18next'

import { DatePickerColumnFilter, SelectColumnFilter } from 'components/table/filters'
import { subTypesFilterClasses } from 'redux/filters/createDocumentFilter'
import { documentTypeOptionsWithIds } from 'utils/enums/documentTypes'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { cellRenderAction, dateTimePayslipTable, DownloadGTNFile, DownloadOnPayslipTable } from 'utils/tableDataFormatters'
import { gtnValidationStatusEnums } from 'utils/enums/gtnValidationEnums'
import { getDocumentTypesByTabName, omitDocTypeIdsForICP, omitDocTypeIdsForKU } from 'utils/documentTypes'

const getDocumentGtnImportStatusOptions = () => [
  { value: 'null', label: i18n.t('Global.text.none') },
  { value: 'Successful', label: i18n.t('Global.text.successful') },
  { value: 'Unsuccessful', label: i18n.t('Global.text.unsuccessful') },
]

const getGtnValidationStatusOptions = () => [
  { value: gtnValidationStatusEnums.pendingValidation, label: gtnValidationStatusEnums.pendingValidation },
  { value: gtnValidationStatusEnums.validated, label: gtnValidationStatusEnums.validated },
  { value: gtnValidationStatusEnums.overwritten, label: gtnValidationStatusEnums.overwritten },
  { value: gtnValidationStatusEnums.failedValidation, label: gtnValidationStatusEnums.failedValidation },
  { value: gtnValidationStatusEnums.error, label: gtnValidationStatusEnums.error },
  { value: gtnValidationStatusEnums.notApplicable, label: gtnValidationStatusEnums.notApplicable },
]

const getDocumentTypeOptions = ({ tabName, isKU = false, isVendorUser = false }) => {
  if (!tabName) return []
  const docTypes = getDocumentTypesByTabName(tabName)

  return documentTypeOptionsWithIds
    .filter((option) => docTypes.includes(option.label))
    .filter((doctype) => {
      if (isKU) return !omitDocTypeIdsForKU.includes(doctype.value)
      if (isVendorUser) return !omitDocTypeIdsForICP.includes(doctype.value)
      return true
    })
}

export const documentTableHeadingTypes = Object.freeze({
  name: 'name',
  description: 'description',
  documentType: 'documentType',
  version: 'version',
  uploadedOn: 'uploadedOn',
  uploadedBy: 'uploadedBy',
  action: 'action',
  importStatus: 'importStatus',
  glStatus: 'glStatus',
  inputsValidationReportStatus: 'inputsValidationReportStatus',
  gtnReImport: 'gtnReimport',
  category: 'category',
  payrollName: 'payrollName',
  gtnValidationStatus: 'gtnValidationStatus',
})

export const getStaticDocsTableOpts = () => ({
  noDataMessage: i18n.t('Global.text.no_matching_records_found'),
  sizePerPageList: [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ],
  showPageSizeSelector: true,
})

const createFullHeadingList = ({ labels, handleDownloadDocument, DocumentActions, onOverrideClick = null, tabName, isKU, isVendorUser }) => {
  const isGTNTab = tabName === 'payrollGtnDocTypes'
  return [
    new TableHeading({
      accessor: documentTableHeadingTypes.name,
      heading: i18n.t('Global.text.name'),
      className: 'text-left',
      columnClassName: 'text-left',
      setWidth: '280px',
      Cell: isGTNTab ? DownloadGTNFile : DownloadOnPayslipTable,
      onClick: handleDownloadDocument,
      onOverrideClick,
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.description,
      heading: i18n.t('Global.text.description'),
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
      setWidth: '140px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.documentType,
      heading: i18n.t('Global.text.type'),
      className: 'text-left',
      columnClassName: 'text-left',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: 'docType',
        options: getDocumentTypeOptions({ tabName, isKU, isVendorUser }),
      },
      disableSortBy: false,
      disableFilters: false,
      setWidth: '110px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.version,
      heading: labels?.[documentTableHeadingTypes.version] || i18n.t('Global.text.version_clean'),
      className: 'text-center',
      setWidth: labels?.[documentTableHeadingTypes.version] ? '128px' : '90px',
      columnClassName: 'text-center',
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.gtnValidationStatus,
      heading: i18n.t('Global.text.gtn_validation_status'),
      className: 'u-text--center',
      columnClassName: 'u-text--center',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.gtnValidationStatus,
        options: getGtnValidationStatusOptions(),
      },
      disableSortBy: false,
      disableFilters: false,
      setWidth: '135px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.category,
      heading: i18n.t('Global.text.category'),
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.category,
        options: [
          { value: subTypesFilterClasses.company, label: i18n.t('Global.text.company') },
          { value: subTypesFilterClasses.payroll, label: i18n.t('Global.text.payroll') },
        ],
      },
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.payrollName,
      heading: i18n.t('Global.text.payroll_name'),
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.importStatus,
      heading: i18n.t('Global.text.gtnImportStatus'),
      setWidth: '120px',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.importStatus,
        options: getDocumentGtnImportStatusOptions(),
      },
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.glStatus,
      heading: i18n.t('Global.text.status'),
      setWidth: '120px',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.glStatus,
        options: [
          { value: 'Balanced', label: i18n.t('Global.text.balanced') },
          { value: 'Not Balanced', label: i18n.t('Global.text.notBalanced') },
        ],
      },
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.inputsValidationReportStatus,
      heading: i18n.t('Global.text.status'),
      setWidth: '120px',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.inputsValidationReportStatus,
        options: [
          { value: 'Blocked', label: i18n.t('Global.status.blocked') },
          { value: 'Error', label: i18n.t('Global.status.error') },
          { value: 'Success', label: i18n.t('Global.status.success') },
          { value: 'Warning', label: i18n.t('Global.status.warning') },
        ],
      },
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.uploadedOn,
      heading: i18n.t('Global.text.uploaded_on'),
      className: 'text-left',
      columnClassName: 'text-left',
      Cell: dateTimePayslipTable,
      Filter: DatePickerColumnFilter,
      disableSortBy: false,
      disableFilters: false,
      setWidth: '150px',
      datePickerOptions: {
        isClearable: true,
      },
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.uploadedBy,
      heading: i18n.t('Global.text.uploaded_by'),
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
      setWidth: '150px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.gtnReImport,
      heading: i18n.t('Global.text.gtnImport'),
      headerAlign: 'left',
      setWidth: '50px',
      classNames: 'u-text--center',
      columnClassName: 'u-text--center',
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.action,
      heading: '',
      className: 'text-center',
      columnClassName: 'text-right',
      disableSortBy: true,
      Cell: cellRenderAction({ Actions: DocumentActions }),
      setWidth: '180px',
    }),
  ]
}

export const buildDocumentTableHeadings = (omittedColumns = [], options = {}) =>
  createFullHeadingList(options)?.filter(({ accessor }) => !omittedColumns.includes(accessor))

export const normalizeDocumentTableFilters = (filters = []) =>
  filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.id]: filter.value,
    }),
    {}
  )

export const getDocFilterNames = (tableHeadings = []) =>
  tableHeadings.reduce((acc, heading) => {
    if (!heading?.disableFilters) {
      acc.push(heading.accessor)
    }
    return acc
  }, [])
