import React from 'react'
import { Field } from 'redux-form'
import AmountInputField from 'components/form/AmountInputField'
import PropTypes from 'prop-types'
import FieldDatePicker from './form/FieldDatePicker'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const getTermId = (str, formFieldName) => formFieldName.replace(str, '')
const hasAnAmount = (id, values) => !!values[`fixed-term-${id}`]
const hasValidFromDate = (id, values) => !!values[`valid-from-fixed-term-${id}`]
const hasValidToDate = (id, values) => !!values[`valid-to-fixed-term-${id}`]
const getValidFromDate = (id, values) => values[`valid-from-fixed-term-${id}`]

const getValidationValueRequired = (t) => (value, values, props, formFieldName) => {
  const termId = getTermId('fixed-term-', formFieldName)
  const hasValidFrom = hasValidFromDate(termId, values)
  const hasValidTo = hasValidToDate(termId, values)
  if ((hasValidFrom || hasValidTo) && !value) {
    return t('BusinessComponent.error.not_blank_value')
  }

  return undefined
}
const getFromDateValidation = (t) => (value, values, props, formFieldName) => {
  const termId = getTermId('valid-from-fixed-term-', formFieldName)
  const hasAmount = hasAnAmount(termId, values)
  if (hasAmount && !value) {
    return t('BusinessComponent.error.from_date_is_required')
  }

  return undefined
}

const getValidation = (t) => (value, values, props, formFieldName) => {
  const termId = getTermId('valid-to-fixed-term-', formFieldName)
  const hasAmount = hasAnAmount(termId, values)
  const hasValidFrom = hasValidFromDate(termId, values)
  const fromDate = getValidFromDate(termId, values)
  if (hasAmount && hasValidFrom && value && fromDate > value) {
    return t('BusinessComponent.error.date_should_be_the_same_or_after_from_Date')
  }

  return undefined
}

const FixedPayElements = ({ isFetching, fixedTerms, disabled, disabledReason }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>
  return (
    <>
      {fixedTerms.map((term, index) => (
        <div key={`${term.companyCountryTermId}-${term.fullName}`} className='o-layout o-grid--strech'>
          <div>
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={`fixed-term-${term.companyCountryTermId}`}
                  label={term.fullName}
                  component={AmountInputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  disabled={disabled}
                  disabledReason={disabledReason}
                  data-testid={`term-field-${term.localName.toLowerCase()}`}
                  validate={[getValidationValueRequired(t)]}
                />
              </div>
            </div>
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={`valid-from-fixed-term-${term.companyCountryTermId}`}
                  label={t('Global:Global.text.fromDate', {
                    requiredSymbol: '',
                  })}
                  component={FieldDatePicker}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  validate={[getFromDateValidation(t)]}
                />
              </div>
            </div>
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={`valid-to-fixed-term-${term.companyCountryTermId}`}
                  label={t('Global:Global.text.toDate', {
                    requiredSymbol: '',
                  })}
                  component={FieldDatePicker}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  validate={[getValidation(t)]}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

FixedPayElements.propTypes = {
  isFetching: PropTypes.bool,
  fixedTerms: PropTypes.array,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
}

export default FixedPayElements
