import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import MainNavItem from './MainNavItem'
import { KeycloakCTX } from './Keycloak/KeycloakProvider'
import { useTranslation } from 'react-i18next'

const MainNav = ({ items, logout, asideMode }) => {
  const { t } = useTranslation()
  const keycloak = useContext(KeycloakCTX)

  const renderItems = (item) => (
    <MainNavItem key={item.title} dataTestId={`aside-${item.title}`} {...item}>
      {item.children ? <ul className='sub-menu u-margin-left-none'>{item.children.map((child) => renderItems(child))}</ul> : null}
    </MainNavItem>
  )

  const handleLogout = () => {
    logout(keycloak)
  }

  return (
    <nav>
      <ul className='c-nav'>
        {items.map((item) => renderItems(item))}
        {logout && (
          <li className='logout no-children' onClick={handleLogout}>
            <div className='o-block o-block--left u-cursor--pointer'>
              <span className='nav-icon nav-icon--logout-aside u-float--left o-block u-margin-right-small u-margin-left-tiny' />
              <span className='nav-label'>{t('Global.nav.logout')}</span>
            </div>
          </li>
        )}
        <div className='powered-by-payslip'>
          <div>{!asideMode ? t('Global.nav.poweredBy') : t('Global.nav.pb')}</div>
          <div className={`${!asideMode ? 'payslip-logo' : 'short-logo'}`} />
        </div>
      </ul>
    </nav>
  )
}

MainNav.propTypes = {
  items: PropTypes.array,
  logout: PropTypes.func,
}

export default MainNav
