/* eslint-disable max-len */
export const cookiePolicy = {
  sectionA: {
    p1:
      'The platform (“us”, “we”, or “our”) uses cookies on the application domains (the "Service"), that ' +
      'include payslip.com, its subdomains and other possible environment domains serving the ' +
      'application.',
    p2: 'Our Cookie Policy explains what cookies are, how we use cookies, your choices regarding cookies and further information about cookies.',
  },
  sectionB: {
    heading: 'WHAT ARE COOKIES?',
    p1:
      'Cookies are small pieces of text that may be stored on your computer or mobile device that ' +
      'contain data related to a website you visit. It may allow a website to recognize you, “remember” ' +
      'your actions or preferences over a period of time and make your next visit easier and the ' +
      'Service more useful to you, or it may contain data related to the function or delivery of the site.',
    p2:
      'Cookies can be set by the owner of the website or in some cases by third party services the ' +
      'website owner allows to present other information, run content or provide other functionality ' +
      'such as analytics.',
    p3: 'Cookies can be “persistent” or “session” cookies.',
  },
  sectionC: {
    heading: 'HOW DO WE USE COOKIES?',
    p1: 'When you use and access the Service, we may place a number of cookies files on your computer or mobile device.',
    p2:
      'We use cookies only for functionality that is strictly necessary for services that are explicitly ' +
      'requested by the user for their session or within the maximum of 7 days. These cookies are ' +
      'used to deliver the basic functions of the platform, i.e. to allow pages to remember technical ' +
      'changes or selections you may make between pages.',
    p3: 'We use a limited number of third-party cookies to ensure essential functionality of the platform.',
    p4: 'This is a list of all cookies placed on your computer or mobile device when you access the Service:',
  },
  sectionD: {
    heading: 'WHAT ARE YOUR CHOICES REGARDING COOKIES?',
    p1: 'If you’d like to instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.',
    p2:
      'Please note, however, that if you delete cookies or refuse to accept them, you might not be able ' +
      'to use all of the features we offer and some of our pages might not display properly. ',
  },
  table: {
    headings: {
      heading1: 'Cookie Name',
      heading2: 'Placed by',
      heading3: 'Purpose',
      heading4: 'Functionality',
      heading5: 'Expiration',
    },
    data: {
      row1: {
        name: 'AUTH_SESSION_ID\nAUTH_SESSION_ID_LEGACY',
        placed: 'Payslip Authentication (auth.payslip.com)',
        purpose: 'Functional',
        functionality: 'To manage user sessions',
        expiration: 'After session is closed',
      },
      row2: {
        name: 'KEYCLOAK_IDENTITY\nKEYCLOAK_IDENTITY_LEGACY',
        placed: 'Payslip Authentication (auth.payslip.com)',
        purpose: 'Functional',
        functionality: 'To manage user sessions',
        expiration: 'After session is closed',
      },
      row3: {
        name: 'KEYCLOAK_SESSION\nKEYCLOAK_SESSION_LEGACY',
        placed: 'Payslip Authentication (auth.payslip.com)',
        purpose: 'Functional',
        functionality: 'To manage user sessions',
        expiration: '1 day',
      },
      row4: {
        name: 'AWSALB',
        placed: 'Amazon Application Load Balancer',
        purpose: 'Functional',
        functionality: 'To provide load balancing functionality',
        expiration: '7 days',
      },
      row5: {
        name: 'AWSALBCORS',
        placed: 'Amazon Application Load Balancer',
        purpose: 'Functional',
        functionality: 'To provide load balancing functionality',
        expiration: '7 days',
      },
    },
  },
  sectionE: {
    heading: 'CHANGES TO OUR COOKIE POLICY',
    p1: 'We keep this cookie policy under regular review and place any updates on this web page. This cookie policy was last updated on 18/04/2024.',
  },
}
