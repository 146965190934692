import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import CompanyRoute from './routes/Company'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

export default (store) => ({
  path: '/companies',
  exact: true,
  indexRoute: { component: withTranslationContext([i18nNameSpaces.Company, i18nNameSpaces.Employee], RouteView) },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [CompanyRoute(store)],
})
