import React from 'react'
import { createRoot } from 'react-dom/client'

import store from './store/store'
import './styles/main.scss'
import './utils/polyfills'
import Raven from 'redux/middlewares/raven'
import registerStore from 'cypress-redux/registerStore'
import './i18n'

// Setup Cypress
registerStore(store.store)

if (__PROD__) {
  // Enable Sentry reporting tool for common runtime errors
  // It's important to initialize it in the entry point of the app,
  // in order to handle the errors in the very beginning.
  Raven.install()
}

// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root')
let root

let render = () => {
  root = createRoot(MOUNT_NODE)
  const App = require('./components/App').default
  const routes = require('./routes/index').default(store.store)

  root.render(<App store={store.store} routes={routes} />)
}

// Development Tools
// ------------------------------------
if (__DEV__) {
  if (module.hot) {
    const renderApp = render
    const renderError = (error) => {
      root = createRoot(MOUNT_NODE)
      const RedBox = require('redbox-react').default
      root.render(<RedBox error={error} />)
    }

    render = () => {
      try {
        renderApp()
      } catch (e) {
        console.error(e)
        renderError(e)
      }
    }

    // Setup hot module replacement
    module.hot.accept(['./components/App', './routes/index'], () => {
      setImmediate(() => {
        root.unmount()
        render()
      })
    })
  }
}

// Let's Go!
// ------------------------------------
if (!__TEST__) render()
