import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect, useSelector } from 'react-redux'
import { getUser } from 'redux/selectors/employees'
import { isCot } from 'redux/selectors/auth'
import { fetchReports } from 'redux/actions/reports'

import { isEmpty } from 'utils/fnkit/typeChecks'

import AccordionContainer from 'containers/AccordionContainer'
import ReportingFiltersContainer from '../containers/ReportingFiltersContainer'
import ReportTypeAvatar from 'routes/Reporting/routes/Report/components/ReportTypeAvatar'
import ReportAccordionHeader from '../../Report/components/ReportAccordionHeader'
import { ReportAccordionView } from '../components/ReportAccordionView'
import { getAllowedReportingSections, getAllowedReportItems } from 'routes/Reporting/utils/reportAccessManagement'

const ReportAccordionSection = (props) => {
  const initialSectionState = {
    showReportFilters: false,
    reportTitle: [],
    reportSubTitle: props.reportDetails.description,
    selectedReport: null,
  }
  const [sectionState, setSectionState] = useState(initialSectionState)
  const { showReportFilters, selectedReport, reportTitle, reportSubTitle } = sectionState
  const defaultReport = props?.defaultReport
  const isExpanded = props?.isExpanded
  const isDefaultSection = props?.isDefaultSection

  useEffect(() => {
    setSectionState({
      ...sectionState,
      reportSubTitle: props.reportDetails.description,
    })
  }, [props.reportDetails])

  /*
   * Navigation events
   */
  const navigateToReportFilters = (label, value, description = '') => {
    setSectionState({
      showReportFilters: true,
      selectedReport: value,
      reportTitle: [label],
      reportSubTitle: description,
    })
  }

  const navigateToParent = () => {
    setSectionState(initialSectionState)
  }

  /*
   * Effects
   */
  useEffect(() => {
    const isDifferentReport = selectedReport !== defaultReport?.value
    if (!isEmpty(defaultReport) && isDifferentReport && isDefaultSection) {
      navigateToReportFilters(defaultReport.label, defaultReport.value, defaultReport.description)
    }
  }, [])

  return (
    <AccordionContainer
      dataTestId={props.reportDetails.title}
      useShadow
      isExpanded={isExpanded}
      titleComponent={
        <ReportAccordionHeader
          textSize='xtra-huge'
          weight='bold'
          textColor='minsk'
          childPaths={reportTitle}
          title={props.reportDetails.title}
          onClick={navigateToParent}
        />
      }
      icon={
        <ReportTypeAvatar
          classList={`rounded-full u-bg--${props.icon.bgColor} u-margin-right-small`}
          icon={`${props.icon.name}-avatar-logo`}
          height={'63px'}
          width={'63px'}
        />
      }
      headerDisplayModifiers={'ai-center'}
      title={props.reportDetails.title}
      subtitle={reportSubTitle}
      subtitleviewLevelCSS='u-padding-left'
    >
      {!showReportFilters ? (
        <ReportAccordionView report={props.reportDetails} navigateToReportFilters={navigateToReportFilters} showReportFilters={showReportFilters} />
      ) : (
        <div className='u-padding-left-small'>
          <ReportingFiltersContainer
            {...props}
            selectedReport={selectedReport}
            mostRecentRun={props.reportDetails}
            navigateToParentOnClick={navigateToParent}
            form={`reportingFilters-${selectedReport}`}
          />
          <br />
        </div>
      )}
    </AccordionContainer>
  )
}

ReportAccordionSection.propTypes = {
  defaultReport: PropTypes.object,
  reportDetails: PropTypes.object,
  icon: PropTypes.shape({
    name: PropTypes.string,
    bgColor: PropTypes.string,
  }),
}

const ReportsAccordionContainer = (props) => {
  const { filteredReports = [], dispatch } = props
  const userPermissions = useSelector((state) => state.auth.permissions)
  const sectionPermissions = getAllowedReportingSections(userPermissions)
  const filteredSectionReports = isEmpty(sectionPermissions) ? filteredReports : filteredReports?.filter((r) => sectionPermissions.includes(r.id))
  const filteredReportItems = getAllowedReportItems(filteredSectionReports, userPermissions)

  useEffect(() => {
    dispatch(fetchReports())
  }, [dispatch])

  return (
    <div className='reports-accordion-container'>
      {filteredReportItems.map((report, i) => (
        <ReportAccordionSection
          key={i}
          categoryType={report?.categoryType}
          reportDetails={report.items}
          icon={report.icon}
          {...props}
          isExpanded={report?.isExpanded}
          isDefaultSection={report?.isDefaultSection}
        />
      ))}
    </div>
  )
}

ReportsAccordionContainer.propTypes = {
  defaultReport: PropTypes.object,
  reports: PropTypes.array.isRequired,
  report: PropTypes.object,
  navigateToReportFilters: PropTypes.func,
  filteredReports: PropTypes.array,
}

const mapStateToProps = (state, props) => {
  let userHasAccessToChangeLogReport = false
  let filteredReports = []
  try {
    const user = !isCot(state) && getUser(state, { userId: state.auth.userId })
    const userHasAccessToChangeLogReport = isCot(state) || user.specialRight.accessChangeLogReport
    filteredReports = props.reports.map((report) => {
      if (report.items.type === 'Control & Governance Reports') {
        report.items.reports = report.items.reports.filter((item) => {
          if (item.ENABLED) {
            return item.ENABLED('accessChangeLogReport', userHasAccessToChangeLogReport)
          }
          return true
        })
      }
      return report
    })
  } catch (e) {}

  return {
    userHasAccessToChangeLogReport,
    filteredReports,
  }
}

export default connect(mapStateToProps, null)(ReportsAccordionContainer)
