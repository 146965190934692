import React, { useState } from 'react'
import { change } from 'redux-form'
import PropTypes from 'prop-types'
import PredefinedRateFormContainer from '../containers/PredefinedRateFormContainer'
import CustomRateForm from './CustomRateForm'
import PrimaryButton from 'components/buttons/Primary'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const ReportingCurrencyForm = (props) => {
  const [selectedMode, setSelectedMode] = useState(null)

  const { t } = useTranslation([i18nNameSpaces.Report])

  const { customRatesFieldsNames, resetFormFields, dispatch, form } = props

  return (
    <div className='o-layout '>
      <div className='o-layout__item u-text--center u-1/1'>
        <PrimaryButton
          onClick={() => {
            setSelectedMode('predefined')
            // Reset toCurrency and rate fields
            dispatch(change(form, 'toCurrency', null))
            dispatch(change(form, 'rate', null))
            const fieldsToReset = ['fxRate', 'rate', ...customRatesFieldsNames]
            resetFormFields(null, fieldsToReset)
          }}
          data-testid='predefinedRate'
        >
          {t('Report.modal.predefined_rate')}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            setSelectedMode('custom')
            const fieldsToReset = ['fxRate', 'rate']
            resetFormFields(null, fieldsToReset)
          }}
          classes='u-margin-left-tiny'
          data-testid='customRate'
        >
          {t('Report.button.custom_rate')}
        </PrimaryButton>
      </div>
      {selectedMode === 'predefined' ? <PredefinedRateFormContainer {...props} /> : null}
      {selectedMode === 'custom' ? <CustomRateForm {...props} /> : null}
    </div>
  )
}

ReportingCurrencyForm.propTypes = {
  dispatch: PropTypes.func,
  customRatesFieldsNames: PropTypes.array,
  resetFormFields: PropTypes.func,
  form: PropTypes.string,
}

export default ReportingCurrencyForm
