import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import { Field, reduxForm } from 'redux-form'
import Form from 'components/form/Form'
import SectionHeading from 'components/SectionHeading'

const ConfigurationForm = ({ modalRef, fields, labels, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Modal
      ref={modalRef}
      className='c-modal c-modal--full  c-modal--min-height--tiny c-modal--overflow-y c-modal-with-top-background'
      modalHeading={labels.heading}
    >
      <Form showSubmitBtn btnText={t('Global:Global.button.save')} {...rest}>
        <div className='o-layout'>
          {fields.details?.items.map((field) => (
            <div key={field.name} className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>{<Field {...field} />}</div>
            </div>
          ))}
          {fields?.configuration?.items && (
            <>
              <div className='d-flex jc--center w-full'>
                <SectionHeading text={fields.configuration.title} />
              </div>
              {fields.configuration.items.map((field) => (
                <div key={field.name} className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>{<Field {...field} />}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </Form>
    </Modal>
  )
}

ConfigurationForm.propTypes = {
  modalRef: PropTypes.object,
  initialValues: PropTypes.object,
  labels: PropTypes.object,
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export const ConfigurationModal = reduxForm({
  form: 'ConfigurationModalForm',
  enableReinitialize: true,
})(ConfigurationForm)
