import React from 'react'
import Modal from 'components/Modal'
import SectionHeading from 'components/SectionHeading'
import ResourcesUploadForm from 'routes/Resources/components/ResourcesUploadForm'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const ResourcesUploadHeader = ({ uploadModalRef, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Resource])
  return (
    <>
      <Modal ref={uploadModalRef} className='c-modal' modalHeading={t('Resource.modal.document_upload')}>
        <ResourcesUploadForm {...rest} />
      </Modal>
      <SectionHeading>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <div className='u-float--right'>
            <button
              title={t('Global:Global.text.upload_file')}
              onClick={() => uploadModalRef.current.showModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            >
              <span className='icon icon--upload' />
            </button>
          </div>
        </div>
      </SectionHeading>
    </>
  )
}

ResourcesUploadHeader.propTypes = {
  uploadModalRef: PropTypes.shape({
    current: PropTypes.shape({
      showModal: PropTypes.func.isRequired,
    }),
  }).isRequired,
}

export default ResourcesUploadHeader
