import { i18nNameSpaces } from 'i18n/types'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const RequestCodeView = ({ gridClassNames = '', textClassNames = 'u-text--small', onClick = null }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className={`o-grid o-grid--center ${gridClassNames}`}>
      <div className={`u-text--center ${textClassNames}`}>
        <span>
          {t('BusinessComponent.requestCodeView.notGettingCode')}
          <span data-testid='request-new-code' onClick={onClick} className='u-text--curious u-cursor--pointer'>
            &nbsp;{t('BusinessComponent.requestCodeView.requestNewCode')}
          </span>
        </span>
      </div>
    </div>
  )
}

RequestCodeView.propTypes = {
  onClick: PropTypes.func,
  textClassNames: PropTypes.string,
  gridClassNames: PropTypes.string,
}

export default RequestCodeView
