import React from 'react'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const HolidayItem = ({ holiday }) => {
  const { i18n } = useTranslation(i18nNameSpaces.Toolbox)

  return (
    <div className='d-flex'>
      <span className='calendar-yearly-holiday-date'>
        <Moment format='DD MMM' locale={i18n.language}>
          {holiday.date.date}
        </Moment>
      </span>
      <span className='u-margin-left-small'>{holiday.name}</span>
    </div>
  )
}

HolidayItem.propTypes = {
  holiday: PropTypes.object,
}

const CalendarHolidayList = ({ holidays = [] }) => {
  const rowCount = holidays && Math.ceil(holidays.length / 3)
  const style = {
    gridTemplateRows: `repeat(${rowCount || 1}, auto)`,
  }

  return (
    <div className='calendar-yearly calendar-yearly-holiday-list u-margin-bottom' style={style} data-testid='holidays-grid'>
      {holidays.map((h) => (
        <HolidayItem holiday={h} key={h.id} />
      ))}
    </div>
  )
}

CalendarHolidayList.propTypes = {
  holidays: PropTypes.array,
}

export default CalendarHolidayList
