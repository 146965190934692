import React from 'react'
import PayrollInstanceView from 'routes/Payruns/components/PayrollInstanceView'
import useQuery from 'hooks/useQuery'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export default (props) => {
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  return <PayrollInstanceView payrunState='active' query={useQuery()} showCount showProgress showStatus heading={t('Payrun.text.prePayrollSteps')} {...props} />
}
