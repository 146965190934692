import React from 'react'
import { Field } from 'redux-form'
import FieldDatePicker from 'components/form/FieldDatePicker'
import InputField from 'components/form/InputField'
import moment from 'moment'
import Moment from 'components/Moment'
import { onChangeSubmit } from 'utils/form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { i18nNameSpaces } from 'i18n/types'
import { useTranslation } from 'react-i18next'

let classNames = require('classnames')

const ActionsDateFilters = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Toolbox])

  const handleChangeView = (view) => {
    props.changeView(view)
    onChangeSubmit(props.handleSubmit)()
  }

  const getViewBtnsClassNames = (view) => {
    return classNames({
      'c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-left-small': true,
      active: props.view === view,
    })
  }

  const handleDatePickerChange = (date) => {
    onChangeSubmit(props.handleSubmit)()
  }

  const getLabel = () => {
    const { view, fromDate } = props

    const formats = {
      day: 'MMMM YYYY, Do dddd',
      month: 'MMMM YYYY',
    }

    const format = formats[view]
    return <Moment format={format}>{fromDate.toString()}</Moment>
  }

  /**
   * Go to current day
   */
  const goToCurrent = () => {
    props.changeDate(moment())
    onChangeSubmit(props.handleSubmit)()
  }

  /**
   * Go to prev month/day
   */
  const goToBack = () => {
    const { view, fromDate } = props

    let date

    if (view === 'month') {
      date = moment(fromDate).subtract(1, 'month')
    }
    if (view === 'day') {
      date = moment(fromDate).subtract(1, 'day')
    }

    props.changeDate(date)
    onChangeSubmit(props.handleSubmit)()
  }

  /**
   * Go to next month/day
   */
  const goToNext = () => {
    const { view, fromDate } = props

    let date

    if (view === 'month') {
      date = moment(fromDate).add(1, 'month')
    }
    if (view === 'day') {
      date = moment(fromDate).add(1, 'day')
    }

    props.changeDate(date)
    onChangeSubmit(props.handleSubmit)()
  }
  if (props.isFetching) return <div>{t('Global:Global.text.loading')}</div>
  const { handleSubmit, shouldShowDateFilters, initialValues } = props
  if (!shouldShowDateFilters) {
    return (
      <div>
        <SectionHeading text={props.headerText || initialValues.headerText} />
      </div>
    )
  }
  return (
    <div>
      <button
        type='button'
        onClick={goToCurrent}
        className='c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-right-small u-margin-bottom-tiny'
      >
        {t('Global:Global.calendar.today')}
      </button>
      <div className='o-grid--inline-flex o-grid--middle'>
        <button type='button' onClick={goToBack} className='c-btn--unset-default-styles icon--arrow left' />
        <form onSubmit={handleSubmit} className='u-text--small u-padding-left-small u-padding-right-small u-relative'>
          {getLabel()}
          <Field
            className='c-calendar__datepicker'
            name='fromDate'
            component={FieldDatePicker}
            formGroupClassName='u-margin-none u-height-0'
            labelClassName='c-label'
            onChanged={handleDatePickerChange}
            todayButton={t('Global:Global.calendar.today')}
          />
          <Field name='view' type='hidden' component={InputField} formGroupClassName='u-margin-none' labelClassName='c-label' />
        </form>
        <button type='button' onClick={goToNext} className='c-btn--unset-default-styles icon--arrow right' />
      </div>
      <button type='button' onClick={() => handleChangeView('day')} className={getViewBtnsClassNames('day')}>
        {t('Global:Global.calendar.day')}
      </button>
      <button type='button' onClick={() => handleChangeView('month')} className={getViewBtnsClassNames('month')}>
        {t('Global:Global.calendar.month')}
      </button>
    </div>
  )
}

ActionsDateFilters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  fromDate: PropTypes.object.isRequired,
  shouldShowDateFilters: PropTypes.bool,
  headerText: PropTypes.string,
  isFetching: PropTypes.bool,
  initialValues: PropTypes.object,
}

export default ActionsDateFilters
