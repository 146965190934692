import React from 'react'
import PropTypes from 'prop-types'
import DocumentsViewContainer from '../containers/DocumentsViewContainer'
import { useTranslation } from 'react-i18next'

export const RouteView = (props) => {
  const { t } = useTranslation()
  return <DocumentsViewContainer title={t('Global.aside.documents')} typeClass='company' typeId={props.match.params.companyId} {...props} />
}

RouteView.propTypes = {
  params: PropTypes.shape({
    companyId: PropTypes.string,
  }),
}

export default RouteView
