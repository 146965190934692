import React from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { createEmployeeFields, getFieldsBySection, renderFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import { Field } from 'redux-form'
import InputField from './form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { sortByLabel } from 'utils/strings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const CompanyBankAccountCreateForm = (props) => {
  const { countries, companies, currencies, bankAccountTypes, country, company, bankCountry, disabled, customFields } = props
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const requiredSymbol = '*'
  return (
    <Form {...props}>
      <div className='o-layout'>
        <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='country'
              label={t('BusinessComponent.form.payroll_country', {
                requiredSymbol,
              })}
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              disabled={disabled}
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              data-testid='country-wrapper'
            />
          </div>
        </div>
        {country ? (
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='company'
                label={t('BusinessComponent.form.company', {
                  requiredSymbol,
                })}
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                disabled={disabled}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                data-testid='company-wrapper'
              />
            </div>
          </div>
        ) : null}
        {country && company ? (
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='bankCountry'
                label={t('BusinessComponent.form.bank_country', {
                  requiredSymbol,
                })}
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={bankAccountTypes.map((type) => ({
                  value: type['country_abbr'],
                  label: type.country.name,
                }))}
                data-testid='bankCountry-wrapper'
              />
            </div>
          </div>
        ) : null}
        {country && company && bankCountry ? (
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='currency'
                label={t('BusinessComponent.form.currency', {
                  requiredSymbol,
                })}
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                data-testid='currency-wrapper'
              />
            </div>
          </div>
        ) : null}
        {customFields ? (
          <div>
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid='bankName-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='bankName'
                  label={t('BusinessComponent.form.bank_name')}
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='bankName'
                />
              </div>
            </div>
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid='bankAddress-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='bankAddress'
                  label={t('BusinessComponent.form.bank_address')}
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='bankAddress'
                />
              </div>
            </div>
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='typeOfAccount'
                  label={t('BusinessComponent.form.type_of_account')}
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder={t('Global:Global.select.placeholder')}
                  options={[
                    { value: 'Saving', label: t('BusinessComponent.form.type_of_account_Saving') },
                    { value: 'Checking', label: t('BusinessComponent.form.type_of_account_Checking') },
                    { value: 'Current', label: t('BusinessComponent.form.type_of_account_Current') },
                    { value: 'Other', label: t('BusinessComponent.form.type_of_account_Other') },
                  ].map((opt) => ({
                    value: opt.value,
                    label: opt.label,
                  }))}
                  data-testid='type-of-account-wrapper'
                />
              </div>
            </div>
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid='accountHolder-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='accountHolder'
                  label={t('BusinessComponent.form.account_holder')}
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='accountHolder'
                />
              </div>
            </div>
            {customFields.map((field) => {
              return (
                <div key={field.name} className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid={`${field.name}-wrapper`}>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      name={field.name}
                      label={field['printable_name']}
                      component={InputField}
                      type='text'
                      className='c-input c-input--transparent'
                      labelClassName='c-label u-text--small u-text--curious'
                      formGroupClassName='u-margin-none'
                      data-testid={`${field.name}`}
                    />
                  </div>
                </div>
              )
            })}
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid='description-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='description'
                  label={t('Global:Global.text.description')}
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='description'
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className='o-layout__item u-text--center u-1/1'>
          <button className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </Form>
  )
}

const EmployeeBankAccountCreate = (props) => {
  const { countries, companies, currencies, bankAccountTypes, country, customFields, form, employee } = props
  const [selectedCountry, setSelectedCountry] = React.useState(country)
  const isFormCreate = form === 'bankAccountCreate'
  const bankAccountCountries = bankAccountTypes.map((type) => ({
    value: type['country_abbr'],
    label: type.country.name,
  }))
  const { t } = useTranslation()

  return (
    <Form {...props}>
      <div className='o-layout o-grid--strech'>
        {isFormCreate
          ? createEmployeeFields({
            fields: employeeFieldsConfigAlt({ isCreate: true }),
            section: 'personal',
            subSection: 'bankDetails',
            country: selectedCountry,
            countries,
            companies,
            currencies,
            bankAccountCountries,
          }).map((field) => renderFields(field, setSelectedCountry))
          : getFieldsBySection({
            fields: employeeFieldsConfigAlt({ isEdit: true }),
            section: 'personal',
            subSection: 'bankDetails',
            country: selectedCountry,
            employee,
            countries,
            companies,
            currencies,
            bankAccountCountries,
          }).map((field) => renderFields(field, setSelectedCountry))}

        {customFields?.map((field) => {
          return (
            <div key={field.name} className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid={`${field.name}-wrapper`}>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={field.name}
                  label={field['printable_name']}
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid={`${field.name}`}
                />
              </div>
            </div>
          )
        })}

        <div className='o-layout__item u-text--center u-1/1'>
          <button className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            {t('Global:Global.button.save')}
          </button>
        </div>
      </div>
    </Form>
  )
}

function BankAccountForm (props) {
  const { isCompanyBankCreate = false } = props
  const Component = isCompanyBankCreate ? <CompanyBankAccountCreateForm {...props} /> : <EmployeeBankAccountCreate {...props} />
  return Component
}

const definedProps = {
  countries: PropTypes.array,
  companies: PropTypes.array,
  currencies: PropTypes.array,
  bankAccountTypes: PropTypes.array,
  customFields: PropTypes.array,
  country: PropTypes.number,
  form: PropTypes.string,
  employee: PropTypes.object,
  company: PropTypes.number,
  bankCountry: PropTypes.string,
  disabled: PropTypes.bool,
}

EmployeeBankAccountCreate.propTypes = definedProps
CompanyBankAccountCreateForm.propTypes = definedProps

export default BankAccountForm
