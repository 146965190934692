import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayslipTable from 'components/table/PayslipTable'
import { i18nNameSpaces } from 'i18n/types'

const PayrollStepForm = ({ steps, stepsOptions, ...rest }) => {
  const { t } = useTranslation([i18nNameSpaces.Payroll])

  return (
    <div>
      <Form {...rest}>
        <div className='o-layout u-text--center'>
          <div className='o-layout__item u-1/1'>
            <Field name='name' label={t('Global:Global.text.name')} labelClassName='c-label' component={InputField} type='text' />
          </div>
          <div className='o-layout__item u-1/3'>
            <Field
              name='position'
              label={t('Payroll.form.choose_position')}
              component={CustomSelectField}
              labelClassName='c-label'
              className='u-text--left'
              placeholder={t('Global:Global.select.placeholder')}
              options={stepsOptions}
            />
          </div>
          <div className='page--payroll-step-form o-layout__item u-2/3 u-margin-top'>
            <PayslipTable
              data={steps}
              dynamicValues
              headings={[
                {
                  accessor: 'alphabeticName',
                  Header: t('Payroll.table.current_steps'),
                  columnClassName: 'text-left',
                  disableSortBy: true,
                  disableFilters: true,
                  Cell: ({
                    cell: {
                      value,
                      row: { original },
                    },
                  }) => {
                    return (
                      <div title={value} className={original.isModified ? 'u-weight--bold' : ''}>
                        {value}
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
          <div className='o-layout__item u-text--center u-1/1'>
            <button
              type='submit'
              className='c-btn c-btn--submit c-btn--small c-btn--curious
                   u-padding-left u-padding-right u-margin-top-small'
            >
              {t('Global:Global.button.save')}
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

PayrollStepForm.propTypes = {
  steps: PropTypes.array.isRequired,
  stepsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default PayrollStepForm
