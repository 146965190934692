import i18n from 'i18next'

import fieldSubCategoriesEnum from 'utils/enums/employees/fieldSubCategoriesEnum'
import fieldComponentsEnum from 'utils/enums/fieldComponentsEnum'
import { employeeAddressType } from './DropDownOptions/addressTypeOptions'
import { allCountriesOptions } from './DropDownOptions/allCountriesOptions'
import { yesNoBoolValuesOptions } from './DropDownOptions/confirmationOptions'

const getAddressFields = () => [
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'isPrimary',
    name: i18n.t('Employee:Employee.config_alt_field.primary_address'),
    component: fieldComponentsEnum.DROPDOWN,
    options: yesNoBoolValuesOptions,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'type',
    name: i18n.t('Employee:Employee.config_alt_field.type'),
    component: fieldComponentsEnum.DROPDOWN,
    options: employeeAddressType,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'addressLine1',
    name: i18n.t('Employee:Employee.config_alt_field.address_line_one'),
    component: fieldComponentsEnum.INPUT,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'addressLine2',
    name: i18n.t('Employee:Employee.config_alt_field.address_line_two'),
    component: fieldComponentsEnum.INPUT,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'addressLine3',
    name: i18n.t('Employee:Employee.config_alt_field.address_line_three'),
    component: fieldComponentsEnum.INPUT,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'city',
    name: i18n.t('Employee:Employee.config_alt_field.city_town'),
    component: fieldComponentsEnum.INPUT,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'district',
    name: i18n.t('Employee:Employee.config_alt_field.county_borough_district'),
    component: fieldComponentsEnum.INPUT,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'zipCode',
    name: i18n.t('Employee:Employee.config_alt_field.post_code'),
    component: fieldComponentsEnum.INPUT,
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'country',
    name: i18n.t('Employee:Employee.config_alt_field.address_country'),
    component: fieldComponentsEnum.DROPDOWN,
    options: allCountriesOptions,
  },
]

export default getAddressFields
