import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { sortByLabel } from 'utils/strings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const Filter = (props) => {
  const { companies, companyId, payrolls } = props
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <>
      <SectionHeading text={t('Import.section.choose_company')} />
      <Form {...props}>
        <div className='o-layout'>
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='company'
                label={t('Import.form.label.company')}
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder={t('Global:Global.select.placeholder')}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                data-testid='company-wrapper'
              />
            </div>
          </div>
          {companyId && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='payroll'
                  label={t('Import.form.label.payroll')}
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder={t('Global:Global.select.placeholder')}
                  options={payrolls}
                  data-testid='payroll-wrapper'
                />
              </div>
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

Filter.propTypes = {
  companies: PropTypes.array,
  companyId: PropTypes.number,
  payrolls: PropTypes.array,
}

export default Filter
