import React from 'react'
import i18n from 'i18next'

import { CommentIcon } from 'components/icons/CommentIcon'
import { isEmpty } from 'lodash'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { buildSelectOpt } from 'utils/options'
import { conditionalBoldValue } from 'utils/tableDataFormatters'

const needsBoldValidation = (row) => isEmpty(row?.subcategory)

export const generateTableHeadings = ({ viewBy }) => {
  const extraHeadings = []
  if (viewBy?.value === 'subcategory') {
    extraHeadings.push(
      new TableHeading({
        accessor: 'subcategory',
        heading: i18n.t('Global:Global.text.subcategory'),
        className: 'text-left',
        columnClassName: 'text-left',
        Cell: conditionalBoldValue(needsBoldValidation),
        disableSortBy: false,
      })
    )
  }

  return [
    new TableHeading({
      accessor: 'category',
      heading: i18n.t('Global:Global.text.category'),
      className: 'text-left',
      columnClassName: 'text-left',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    ...extraHeadings,
    new TableHeading({
      accessor: 'currentTotal',
      heading: i18n.t('Payrun:Payrun.tableHeadings.currentPayrun'),
      className: 'text-right',
      columnClassName: 'text-right',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'previousTotal',
      heading: i18n.t('Payrun:Payrun.tableHeadings.previousPayrun'),
      className: 'text-right',
      columnClassName: 'text-right',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'variance',
      heading: i18n.t('Payrun:Payrun.tableHeadings.variance'),
      className: 'text-right',
      columnClassName: 'text-right',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    new TableHeading({
      id: 'body',
      expander: true,
      heading: i18n.t('Payrun:Payrun.commentary_headings.comments'),
      editable: true,
      inputType: 'textArea',
      charactersPerLine: 180,
      focusableIndex: 0,
      disableSortBy: false,
      placeholder: i18n.t('Payrun:Payrun.tableHeadings.commentPlaceholder'),
      Cell: ({ row }) => {
        const hasComment = !isEmpty(row?.original?.body)
        return row.canExpand ? (
          <div className='d-flex jc--center ai-center'>
            <CommentIcon className='u-relative' stroke='var(--ps-color-alternative)'>
              {hasComment && <span className='c-notification__indicator u-bg--supernova' />}
            </CommentIcon>
          </div>
        ) : null
      },
    }),
  ]
}

export const getViewByOpts = () =>
  buildSelectOpt([
    {
      id: 'category',
      name: i18n.t('Global:Global.text.category'),
    },
    {
      id: 'subcategory',
      name: i18n.t('Global:Global.text.subcategory'),
    },
  ])
