import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import i18n from 'i18next'

import Loader from 'components/Loader'

/**
 * Component is a higher-order React functional component that wraps another component
 * and ensures the necessary i18n namespaces are loaded before rendering. It manages
 * the translation context readiness state and renders a loader until the required
 * namespaces are available.
 *
 * @param {Object} param0 - The props object containing the following properties:
 * @param {string|string[]} param0.ns - The namespace(s) required for the i18n translations.
 * @param {React.ComponentType} param0.WrappedComponent - The React component to be rendered once namespaces are ready.
 * @param {Object} param0.props - The props to be passed down to the WrappedComponent.
 *
 * @returns {React.Element} Returns the WrappedComponent with translation context applied or a loader if the namespaces are not ready.
 */
const Component = ({ ns, WrappedComponent, props }) => {
  const [tReady, setTReady] = useState()

  useEffect(() => {
    i18n.loadNamespaces(ns).then(() => {
      setTReady(true)
    })
  }, [ns])

  return tReady ? <WrappedComponent {...props} /> : <Loader />
}

Component.propTypes = {
  ns: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  WrappedComponent: PropTypes.elementType.isRequired,
  props: PropTypes.object,
}
/**
 * Higher-order function for wrapping a React component with translation context.
 *
 * @param {string|string[]} ns - The namespace or array of namespaces to be used for translations.
 * @param {React.ComponentType} WrappedComponent - The component to be wrapped with translation context.
 * @returns {Function} A component function returning the WrappedComponent with the provided translation namespace(s) and props.
 */
export const withTranslationContext = (ns, WrappedComponent) => {
  return (props) => <Component props={props} ns={ns} WrappedComponent={WrappedComponent} />
}
