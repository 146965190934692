import React, { useRef, useState } from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import { LeaveConfirmationModal } from 'components/ReusableModals/LeaveConfirmationModal'
import { useTranslation } from 'react-i18next'

const PaginationListPage = ({ gotoPage, currentPageIndex, listIndex, paginationStateControl }) => {
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(listIndex)
  const navigationLockedModalRef = useRef(null)

  const isActive = currentPage === currentPageIndex ? ' active' : ''
  const displayIndex = (listIndex += 1)

  const onGoToPage = () => {
    if (paginationStateControl) {
      const { resetWhileInAddMode, blockPageChange } = paginationStateControl

      if (resetWhileInAddMode?.mode) resetWhileInAddMode.callback()

      if (blockPageChange?.needsPageLock) {
        navigationLockedModalRef?.current?.showModal()
        return
      }
    }
    gotoPage(currentPage)
  }

  const onConfirmNavigation = () => {
    navigationLockedModalRef?.current?.hideModal()
    paginationStateControl?.blockPageChange?.onConfirm()
    gotoPage(currentPage)
  }
  return (
    <>
      <LeaveConfirmationModal modalRef={navigationLockedModalRef} onConfirmation={onConfirmNavigation} />
      <button className={`c-btn--unset-default-styles page-item${isActive}`} type='button' title={displayIndex} onClick={onGoToPage}>
        <span className={`page-link${isActive ? ' u-text--white' : ''}`}>{displayIndex}</span>
      </button>
    </>
  )
}

const actionTypesIcon = {
  previousPage: '&lt;',
  nextPage: '&gt;',
  firstPage: '&lt;&lt;',
  lastPage: '&gt;&gt;',
}

const HandlePaginationClick = ({ onClick, title, actionType }) => {
  return (
    <button className='c-btn--unset-default-styles page-item' type='button' title={title} onClick={() => onClick()}>
      <span className='page-link' dangerouslySetInnerHTML={{ __html: actionTypesIcon[actionType] }} />
    </button>
  )
}

const PaginationPageSelector = ({ list, value, onChange }) => {
  const { t } = useTranslation()
  return (
    <div className='' style={{ marginLeft: '15px', width: '120px' }}>
      <ReactSelect
        className='c-custom-select'
        placeholder={t('Global:Global.select.placeholder')}
        options={list}
        value={value}
        onChange={onChange}
        clearable={false}
      />
    </div>
  )
}

export const PayslipTablePagination = ({
  options: {
    canPreviousPage,
    canNextPage,
    pageIndex,
    previousPage,
    nextPage,
    pageOptions,
    pageSize = 25,
    initialPageSize = 25,
    pageCount = 1,
    gotoPage,
    setPageSize,
    sizePerPageList,
    showPageSizeSelector,
    paginationStateControl,
  },
}) => {
  const totalPages = new Array(pageCount).fill().map((_, i) => i)
  const justification = showPageSizeSelector ? 'justify-content-between' : 'justify-content-end'
  const [pageSizes, setPageSizes] = useState(initialPageSize)

  const { t } = useTranslation()
  const navigationLockedModalRef = useRef(null)
  let buttonActionCb = null

  const onChangeHandler = (selected) => {
    const value = selected ? selected.value : pageSize
    setPageSize(Number(value))
    setPageSizes(Number(value))
  }

  const shouldLockPage = (cb) => {
    const { blockPageChange } = paginationStateControl
    if (blockPageChange?.needsPageLock) {
      buttonActionCb = cb
      navigationLockedModalRef?.current?.showModal()
      return true
    }
  }

  const onPreviousPage = (args) => {
    if (paginationStateControl) {
      const { resetWhileInAddMode } = paginationStateControl

      if (resetWhileInAddMode?.mode) resetWhileInAddMode.callback()

      if (shouldLockPage(previousPage)) return
    }
    previousPage(args)
  }

  const onNextPage = (args) => {
    if (paginationStateControl) {
      const { resetWhileInAddMode } = paginationStateControl

      if (resetWhileInAddMode?.mode) resetWhileInAddMode.callback()

      if (shouldLockPage(nextPage)) return
    }
    nextPage(args)
  }

  const onFirstPage = () => {
    const navigateToPage = () => gotoPage(0)
    return shouldLockPage(navigateToPage) ? null : navigateToPage()
  }

  const onLastPage = () => {
    const navigateToPage = () => gotoPage(totalPages.length - 1)
    return shouldLockPage(navigateToPage) ? null : navigateToPage()
  }

  const boundPages = pageIndex + 1 < 5 ? totalPages.slice(0, 5) : totalPages.slice(pageIndex - 2, pageIndex + 3)
  const shouldShowChevronFirst = pageIndex >= 4
  const shouldShowChevronLast = pageIndex < totalPages.length - 3

  const onConfirmNavigation = () => {
    navigationLockedModalRef?.current?.hideModal()
    paginationStateControl?.blockPageChange?.onConfirm()
    buttonActionCb()
  }

  const getActionTypeTitle = (actionType) => {
    const translatedTitles = {
      previousPage: t('Global:Global.pagination.previous_page'),
      nextPage: t('Global:Global.pagination.next_page'),
      firstPage: t('Global:Global.pagination.first_page'),
      lastPage: t('Global:Global.pagination.last_page'),
    }
    return translatedTitles[actionType]
  }
  return (
    <div className='o-payslip-table-pagination u-margin-bottom '>
      <LeaveConfirmationModal modalRef={navigationLockedModalRef} onConfirmation={onConfirmNavigation} />
      <div className={`row ${justification}`} style={{ marginTop: '15px' }}>
        {showPageSizeSelector && <PaginationPageSelector list={sizePerPageList} value={pageSizes} onChange={onChangeHandler} />}
        {pageCount > 1 && (
          <div className=''>
            <div className='c-custom-pagination u-float--right'>
              <div>
                <ul className='pagination'>
                  {shouldShowChevronFirst && <HandlePaginationClick onClick={onFirstPage} actionType='firstPage' title={getActionTypeTitle('firstPage')} />}
                  {canPreviousPage && <HandlePaginationClick onClick={onPreviousPage} actionType='previousPage' title={getActionTypeTitle('previousPage')} />}
                  {boundPages.map((bp, index) => (
                    <PaginationListPage
                      key={`page-${bp}`}
                      listIndex={bp}
                      gotoPage={gotoPage}
                      paginationStateControl={paginationStateControl}
                      currentPageIndex={pageIndex}
                    />
                  ))}
                  {canNextPage && <HandlePaginationClick onClick={onNextPage} actionType='nextPage' title={getActionTypeTitle('nextPage')} />}
                  {shouldShowChevronLast && <HandlePaginationClick onClick={onLastPage} actionType='lastPage' title={getActionTypeTitle('lastPage')} />}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

PaginationListPage.propTypes = {
  gotoPage: PropTypes.func,
  currentPageIndex: PropTypes.number,
  listIndex: PropTypes.number,
  paginationStateControl: PropTypes.object,
}

PayslipTablePagination.propTypes = {
  options: PropTypes.object,
}

HandlePaginationClick.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  actionType: PropTypes.string,
}

PaginationPageSelector.propTypes = {
  list: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
}
