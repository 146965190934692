import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import Fetcher from 'containers/Fetcher'
import { getAccessibleCompanies, getSortedAccessibleCompanies } from 'redux/selectors/company'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import CompanyList from 'components/companies/CompanyList'
import HeadQuarter from 'components/companies/HeadQuarter'
import { logos } from 'utils/config'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const CompaniesContainer = (props) => {
  const { onCreate } = props
  const { t } = useTranslation([i18nNameSpaces.Company])

  return (
    <div>
      <div className='o-layout u-relative'>
        <div className='o-layout__item u-margin-bottom u-text--center u-1/1'>
          <HeadQuarter {...props} />
          <Authorization permissions={['COMPANY_CREATE']}>
            <div
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-absolute--top-right'
              title={t('Global.button.add')}
              onClick={onCreate}
            >
              <span className='icon icon--plus' />
            </div>
          </Authorization>
        </div>
      </div>
      <CompanyList {...props} />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { countries, parentCompanies } = state
  const parentCompaniesData = parentCompanies.allIds.map((id) => state.parentCompanies.byIds[id])
  const tenant = state.tenants.id ? getParentCompanyById(state, { parentCompanyId: state.tenants.id }) : parentCompaniesData[0]
  const logo = logos ? logos[tenant.schema] : null
  const accessibleCompanies = getAccessibleCompanies(state)
  return {
    countries: countries.allIds.map((id) => countries.byIds[id]),
    companies: getSortedAccessibleCompanies(accessibleCompanies),
    parentCompanies: parentCompaniesData,
    tenantName: tenant.name,
    logo,
  }
}

CompaniesContainer.propTypes = {
  onCreate: PropTypes.func,
}

const Container = connect(mapStateToProps)(CompaniesContainer)
export default Fetcher(Container, ['companies', 'countries', 'parentCompanies', 'accessAreaPivot'])
