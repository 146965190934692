import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import PayTaxesView from '../components/PayTaxesView'
import Modal from 'components/Modal'
import { batch, useDispatch } from 'react-redux'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import usePageUIState from 'hooks/usePageUIState'
import { InlineTableActionButtons } from 'components/buttons/InlineTableActionButtons'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'
import ConfirmationModal from 'components/ConfirmationModal'
import AnnualSalaryListContainer from './AnnualSalaryListContainer'
import { getPayTaxesFieldsForAdd } from '../utility'
import { attachTerms } from 'redux/actions/employeeSystemUsers'
import { mergeEmployeeTerms } from 'redux/actions/employeeTermPivot'
import { amountTransformer } from 'redux/transformers/amountsTransformer'
import { normalizeDate } from 'redux/transformers/dateTransformer'
import { showMessage } from 'redux/actions/modal'
import strings from 'strings'
import { confirmLockedPayrunMessage } from 'components/payruns/Messages'
import { resetPayfileChangesInitials } from 'redux/actions/payfileChanges'
import { resetPayfilesInitials } from 'redux/actions/payfiles'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'

const PayTaxesViewContainer = (props) => {
  const { isFetching, isEmployeeOnly, employee, employeePayrolls, showFixedTermConfirmation } = props
  const { employeeDeductions, employeeNetDeductions, employerDeductions, fixedTerms, futureFixedTerms } = props
  const { futureEmployeeDeductions, futureEmployerDeductions, futureEmployeeNetDeductions } = props
  const addData = getPayTaxesFieldsForAdd(employeeDeductions, employeeNetDeductions, employerDeductions, fixedTerms)
  const stateData = {
    employeeDeductions: employeeDeductions,
    futureEmployeeDeductions: futureEmployeeDeductions,
    employeeNetDeductions: employeeNetDeductions,
    futureEmployeeNetDeductions: futureEmployeeNetDeductions,
    employerDeductions: employerDeductions,
    futureEmployerDeductions: futureEmployerDeductions,
    fixedTerms: fixedTerms,
    futureFixedTerms: futureFixedTerms,
  }

  const dispatch = useDispatch()
  const confirmationModal = useRef(null)
  const confirmCancelModal = useRef(null)
  const historyModal = useRef(null)
  const [data, setData] = useState(stateData)
  const [isUpdating, setIsUpdating] = useState(false)
  const [inlineMode, setInlineMode] = useState(false)
  const [fieldErrors, setFieldErrors] = useState([])
  const { setPageUIState, inEditMode, inAddMode } = usePageUIState({})
  const { t } = useTranslation([i18nNameSpaces.Employee])

  const onSalaryHistory = () => {
    historyModal.current.showModal()
  }

  const showConfirmMessage = () => {
    confirmationModal.current.showModal()
  }

  // if employeePayrolls is empty, but employee.payrollName is not, we can assume the key user doesn't have access to their payroll
  // in this case the edit button has to be removed because any kind of editing will fail on the backend
  const canEdit = !(employeePayrolls.length === 0 && employee.payrollName)

  const generatePayloadForSave = () => {
    const payloadValues = Object.values(data).flat()

    const changedFields = payloadValues.filter((val) => val.isDirty)
    const payload = { data: {} }
    changedFields.forEach((field) => {
      const fieldId = inAddMode ? field.addServerId || field.countryTermId : field.employeeTermId
      // If all values are empty we don't want to send anything
      if (inAddMode && !field.amount && !field.validTo && !field.validFrom) {
        return
      }
      payload.data[fieldId] = {
        amount: field.amount ? amountTransformer(field.amount) : field.amount,
        validTo: field.validTo ? normalizeDate(field.validTo) : null,
        validFrom: field.validFrom ? normalizeDate(field.validFrom) : null,
      }
    })
    return payload
  }

  const onConfirmationOfSave = () => {
    let payload = generatePayloadForSave()
    const dispatchMethod = inAddMode ? attachTerms : mergeEmployeeTerms

    dispatch(
      dispatchMethod(employee.id, payload, {
        shouldFetch: false,
        shouldInvalidate: true,
      })
    ).then((resp) => {
      if (resp.errors) {
        batch(() => {
          setFieldErrors([resp.errors])
          setIsUpdating(false)
        })
      } else {
        if (inAddMode) {
          dispatch(resetPayfileChangesInitials())
          dispatch(resetPayfilesInitials())
        }
        // Not really the best solution right now, but I cant track down
        // why the terms isn't properly being invalidated, except for on a page refresh
        // I suspect it's something to do with syncTerms details, but not entirely sure
        window.location.reload()
        dispatch(showMessage({ body: strings.successfulChangeUpdate }))
      }
    })
  }

  const onSave = () => {
    if (!isUpdating) setIsUpdating(true)

    if (showFixedTermConfirmation) {
      showConfirmMessage()
      return
    }
    onConfirmationOfSave()
  }

  const onEdit = () => {
    setPageUIState(pageUIStatesEnum.EDIT)
  }

  const onAdd = () => {
    setData(addData)
    setPageUIState(pageUIStatesEnum.ADD)
  }

  const onCancel = () => {
    setData(stateData)
    setPageUIState(pageUIStatesEnum.VIEW)
    setFieldErrors([])
  }

  const onValueChange = () => {
    setFieldErrors([])
  }

  const onUpdateGlobalDataForSave = (rowIndex, columnId, value, { metaData }) => {
    setData((old) => {
      if (old[metaData.subCategory]?.length) {
        return {
          ...old,
          [metaData.subCategory]: old[metaData.subCategory].map((row, index) => {
            if (metaData.subCategory.indexOf('future') !== -1) {
              if (row.employeeTermId === metaData.mappedErrorFieldId) {
                const oldRecord = old[metaData.subCategory].find((term) => term.employeeTermId === metaData.mappedErrorFieldId)
                return {
                  ...oldRecord,
                  [columnId]: value,
                  isDirty: true,
                }
              }
              return row
            } else {
              if (index === rowIndex) {
                return {
                  ...old[metaData.subCategory][rowIndex],
                  [columnId]: value,
                  isDirty: true,
                }
              }
              return row
            }
          }),
        }
      }
    })
  }

  if (isFetching) return <div>Loading...</div>
  let showCancelButtons = inEditMode || inAddMode
  if (inlineMode) {
    showCancelButtons = false
  }

  return (
    <>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile ' : null}>
        <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT', 'EMPLOYEEUSER_CREATE', 'EMPLOYEEUSER_NORMAL_CREATE']}>
          <SectionHeading>
            <div className='o-layout__item u-1/1 u-1/2@desktop'>
              <div className='u-float--right'>
                {showCancelButtons && <InlineTableActionButtons onCancel={confirmCancelModal.current.showModal} onSave={onSave} isUpdating={isUpdating} />}
                {canEdit && (
                  <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
                    <div
                      className={`${btnClasses} ${inEditMode || inlineMode || inAddMode ? 'disabled' : ''}`}
                      onClick={onEdit}
                      disabled={inEditMode || inlineMode || inAddMode}
                      data-testid='employee-edit'
                    >
                      <span className='icon icon--edit' />
                    </div>
                  </Authorization>
                )}
                <Authorization permissions={['EMPLOYEEUSER_CREATE', 'EMPLOYEEUSER_NORMAL_CREATE']}>
                  <div
                    className={`${btnClasses} ${inEditMode || inlineMode || inAddMode ? 'disabled' : ''}`}
                    onClick={onAdd}
                    disabled={inEditMode || inlineMode || inAddMode}
                    data-testid='employee-create'
                  >
                    <span className='icon icon--plus' />
                  </div>
                </Authorization>
              </div>
            </div>
          </SectionHeading>
        </Authorization>
      </div>
      <PayTaxesView
        onAnnualSalaryHistoryBtnClick={onSalaryHistory}
        inlineMode={inlineMode}
        setInlineMode={setInlineMode}
        inEditMode={inEditMode}
        inAddMode={inAddMode}
        disableNonGlobalButtons={inEditMode || inAddMode}
        fieldErrors={fieldErrors}
        onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
        onValueChange={onValueChange}
        data={data}
        {...props}
      />
      <ConfirmationModal ref={confirmCancelModal} className='c-modal' modalHeading={t('Global.modal.confirmation')} onConfirm={() => onCancel()}>
        <p>{t('Employee.modal.cancel_all_changes')}</p>
      </ConfirmationModal>

      <Modal
        ref={historyModal}
        className='c-modal c-modal--half'
        modalHeading={t('Employee.text.annual_salary_history')}
        data-testid='annual-salary-history-modal'
      >
        <AnnualSalaryListContainer {...props} />
      </Modal>
      <ConfirmationModal
        ref={confirmationModal}
        className='c-modal'
        hideReject
        confirmButtonText={t('Global.text.ok_pascal_case')}
        modalHeading={t('Global.modal.confirmation')}
        onConfirm={() => {
          onConfirmationOfSave()
        }}
      >
        <p>{confirmLockedPayrunMessage}</p>
      </ConfirmationModal>
    </>
  )
}

PayTaxesViewContainer.propTypes = {
  isFetching: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  showFixedTermConfirmation: PropTypes.bool,
  isEmployeeOnly: PropTypes.bool,
  employeeDeductions: PropTypes.array,
  employeeNetDeductions: PropTypes.array,
  employerDeductions: PropTypes.array,
  fixedTerms: PropTypes.array,
  futureFixedTerms: PropTypes.array,
  futureEmployeeDeductions: PropTypes.array,
  futureEmployerDeductions: PropTypes.array,
  futureEmployeeNetDeductions: PropTypes.array,
  employee: PropTypes.obj,
}

export default PayTaxesViewContainer
