import loadable from '@loadable/component'
import { withTranslationContext } from 'i18n/components/withTranslationContext'
import { i18nNameSpaces } from 'i18n/types'

const PayrollTabViewRouteLoadable = loadable(() => import('./components/PayrollTabView'))

export default (store) => ({
  path: 'payroll',
  tabbedRoute: true,
  indexRoute: { component: withTranslationContext(i18nNameSpaces.Payroll, PayrollTabViewRouteLoadable) },
})
