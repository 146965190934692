import React from 'react'
import PropTypes from 'prop-types'

import { i18nNameSpaces } from 'i18n/types'
import { useTranslation } from 'react-i18next'

const PasswordStrengthBox = (props) => {
  const { showPasswordStrengthBox = false, isSelected, hasNumber, hasCapitalLetter, hasSpecialSymbol, hasMinimumLength, hasNoAdjacentRepeatingChars } = props
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  const passwordRules = [
    {
      name: 'oneNumber',
      validation: hasNumber,
      label: t('BusinessComponent.passwordStrengthBox.oneNumber'),
    },
    {
      name: 'oneCapitalLetter',
      validation: hasCapitalLetter,
      label: t('BusinessComponent.passwordStrengthBox.oneCapitalLetter'),
    },
    {
      name: 'oneSpecialSymbol',
      validation: hasSpecialSymbol,
      label: t('BusinessComponent.passwordStrengthBox.oneSpecialSymbol'),
    },
    {
      name: 'tenCharacters',
      validation: hasMinimumLength,
      label: t('BusinessComponent.passwordStrengthBox.tenCharacters'),
    },
    {
      name: 'noAdjacentRepeating',
      validation: hasNoAdjacentRepeatingChars,
      label: t('BusinessComponent.passwordStrengthBox.noAdjacentRepeating'),
    },
  ]

  return (
    showPasswordStrengthBox && (
      <div className={`password__strength ${isSelected ? 'active' : ''}`}>
        <span className='u-text--small'>
          <strong>{t('BusinessComponent.passwordStrengthBox.header')}</strong>
        </span>
        <ul>
          {passwordRules.map((rule) => (
            <li key={rule.name} className={`${rule.validation ? 'fulfilled' : ''}`}>
              {rule.label}
            </li>
          ))}
        </ul>
      </div>
    )
  )
}
PasswordStrengthBox.propTypes = {
  showPasswordStrengthBox: PropTypes.bool,
  isSelected: PropTypes.bool,
  hasNumber: PropTypes.bool,
  hasCapitalLetter: PropTypes.bool,
  hasSpecialSymbol: PropTypes.bool,
  hasMinimumLength: PropTypes.bool,
  hasNoAdjacentRepeatingChars: PropTypes.bool,
}
export default PasswordStrengthBox
