import React, { useEffect, useRef, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { ConfirmButton, RejectButton } from 'components/buttons'
import { useTranslation } from 'react-i18next'

export const LeaveConfirmationModal = ({ modalRef, confirmButtonText, rejectButtonText, onConfirmation, onReject, hideReject }) => {
  const { t } = useTranslation()
  const handleReject = () => {
    onReject?.()
    modalRef?.current?.hideModal()
  }
  return (
    <BaseConfirmationModal ref={modalRef} className='c-modal' modalHeading={t('Global.modal.confirmation')}>
      <p>{t('Global.modal.leave_confirmation')}</p>
      <ConfirmButton buttonText={confirmButtonText} onClick={onConfirmation} />
      {!hideReject && <RejectButton buttonText={rejectButtonText} onClick={handleReject} />}
    </BaseConfirmationModal>
  )
}

export const LeaveRouteConfirmationModal = ({ isDirty, ...props }) => {
  const modalRef = useRef()
  const history = useHistory()
  const [nextLocationPath, setNextLocationPath] = useState()
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleBlockedNavigation = (nextLocation, _) => {
    if (isDirty && !confirmedNavigation) {
      modalRef?.current?.showModal()
      setNextLocationPath(nextLocation?.pathname)
      return false
    }
    return true
  }

  const onConfirmation = () => {
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && nextLocationPath) {
      history.push(nextLocationPath)
    }
  }, [confirmedNavigation])

  return (
    <>
      <Prompt when message={handleBlockedNavigation} />
      <LeaveConfirmationModal modalRef={modalRef} onConfirmation={onConfirmation} {...props} />
    </>
  )
}

LeaveConfirmationModal.propTypes = {
  modalRef: PropTypes.shape({
    current: PropTypes.shape({
      showModal: PropTypes.func,
      hideModal: PropTypes.func,
    }),
  }),
  confirmButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  onConfirmation: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  hideReject: PropTypes.bool,
}

LeaveRouteConfirmationModal.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  modalRef: PropTypes.shape({
    current: PropTypes.shape({
      showModal: PropTypes.func,
      hideModal: PropTypes.func,
    }),
  }),
  confirmButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  onConfirmation: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  hideReject: PropTypes.bool,
}
