import React from 'react'
import { connect } from 'react-redux'
import { isCot } from 'redux/selectors/auth'
import { change } from 'redux-form'
import AssignmentsFiltersContainer from '../containers/AssignmentsFiltersContainer'
import AssignmentsDateFiltersContainer from '../containers/AssignmentsDateFiltersContainer'
import AssignmentsListContainer from '../containers/AssignmentsListContainer'
import Filter from 'containers/EventsAssignmentFilterDataHOC'
import moment from 'moment'
import InnerWrapper from 'layouts/InnerWrapper'
import { isEmpty } from 'lodash'
import useQuery from 'hooks/useQuery'
import i18n from 'i18next'

const buildInitialValues = (props, query) => {
  let values = { fromDate: moment().toDate(), view: 'month', shouldShowDateFilters: true, actionStatus: 'all' }
  const status = query.get('status')

  if (!isEmpty(status)) {
    values.actionStatus = status === 'overdue' ? 'overdue' : null
    values.view = status === 'overdue'
    values.shouldShowDateFilters = false
    values.headerText = i18n.t('Global:Global.actions.overdue_actions')
    values.fromDate = moment.unix(0)
    values.view = 'year'
    values.toDate = moment().endOf('day').toDate()
    values.status = ['in-progress', 'not-started', 'reverted']
    values.sort = [{ name: 'deadline', order: 'asc' }]
  } else {
    moment().startOf('month')
    props.dispatch(change('filterTasks', 'fromDate', moment().startOf('month').toDate()))
  }
  if (props.owner) {
    values.owner = props.owner
    props.dispatch(change('filterTasks', 'owner', props.owner))
  }

  return values
}

const RouteView = (props) => {
  const query = useQuery()
  const initialValues = buildInitialValues(props, query)
  const AssignmentsFilter = Filter(AssignmentsFiltersContainer, { isAsync: false, initialValues })
  return (
    <InnerWrapper>
      <>
        <div className='o-layout o-layout--middle u-margin-bottom-small'>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <AssignmentsDateFiltersContainer shouldShowDateFilters={initialValues.shouldShowDateFilters} initialValues={initialValues} {...props} />
          </div>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <AssignmentsFilter shouldFilterInitially shouldMergePayrollsAndProcesses />
          </div>
        </div>

        <AssignmentsListContainer {...props} initialFilterValues={initialValues} />
      </>
    </InnerWrapper>
  )
}
const mapDispatchToProps = (dispatch, state) => {
  return {
    dispatch,
  }
}
function mapStateToProps (state) {
  return { owner: !isCot(state) ? state.auth.userId : null }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteView)
