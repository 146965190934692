import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import CostingContainer from '../containers/CostingContainer'
import AddServiceFormContainer from '../containers/AddServiceFormContainer'
import RemoveServiceFormContainer from '../containers/RemoveServiceFormContainer'
import AddServiceElementFormContainer from '../containers/AddServiceElementFormContainer'
import EditServiceElementFormContainer from '../containers/EditServiceElementFormContainer'
import Modal from 'components/Modal'
import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const [action, setAction] = useState('addService')
  const [countryId, setCountryId] = useState(null)
  const [serviceId, setServiceId] = useState(null)
  const [serviceName, setServiceName] = useState('')
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  const modalAddEditRef = useRef(null)
  const modalConfirmationRef = useRef(null)

  useEffect(() => {
    const { dispatch } = props
    dispatch(getWhiteLabeledBrandStyles())
  }, [props])

  const handleModalOpen = (modalRef) => {
    if (modalRef && modalRef.showModal) {
      modalRef.showModal()
    }
  }

  const hideModal = () => {
    if (modalAddEditRef.current && modalAddEditRef.current.hideModal) {
      modalAddEditRef.current.hideModal()
    }
    setAction('addService')
  }

  const hideConfirmationModal = () => {
    if (modalConfirmationRef.current && modalConfirmationRef.current.hideModal) {
      modalConfirmationRef.current.hideModal()
    }
    setAction('addService')
  }

  const handleEditCreateClick = (action, countryId, serviceId, serviceName) => {
    setAction(action)
    setCountryId(countryId)
    setServiceId(serviceId)
    setServiceName(serviceName)
    handleModalOpen(action === 'removeService' ? modalConfirmationRef.current : modalAddEditRef.current)
  }

  return (
    <div>
      <SectionHeading>
        <Authorization permissions={['VENDORCOUNTRYSERVICE_CREATE']}>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <div className='u-float--right'>
              <button
                title={t('Vendor.text.add_country')}
                onClick={() => {
                  setCountryId(null)
                  handleModalOpen(modalAddEditRef.current)
                }}
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              >
                {t('Vendor.text.add_country')}
              </button>
            </div>
          </div>
        </Authorization>
      </SectionHeading>
      <CostingContainer {...props} onClick={handleEditCreateClick} />
      <Modal ref={modalAddEditRef} className='c-modal' modalHeading={t('Vendor.text.update_service_list')}>
        {action === 'addService' ? <AddServiceFormContainer onSubmit={hideModal} {...props} countryId={countryId} /> : null}
        {action === 'addServiceElement' ? <AddServiceElementFormContainer onSubmit={hideModal} {...props} /> : null}
        {action === 'editServiceElement' ? <EditServiceElementFormContainer onSubmit={hideModal} {...props} /> : null}
      </Modal>
      <BaseConfirmationModal ref={modalConfirmationRef} className='c-modal' modalHeading={t('Global:Global.modal.confirmation')}>
        <RemoveServiceFormContainer
          {...props}
          countryId={countryId}
          serviceId={serviceId}
          serviceName={serviceName}
          onSubmit={hideConfirmationModal}
          onReject={hideConfirmationModal}
        />
      </BaseConfirmationModal>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

RouteView.propTypes = {
  dispatch: PropTypes.func,
}

const Container = connect(null, mapDispatchToProps)(RouteView)
export default Container
