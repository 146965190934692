import i18n from 'i18next'

import {
  ROLE_ACCOUNTING,
  ROLE_ACCOUNTING_READ_ONLY,
  ROLE_CONTROLLING,
  ROLE_CONTROLLING_READ_ONLY,
  ROLE_FINANCE_ADMIN,
  ROLE_FINANCE_ADMIN_READ_ONLY,
  ROLE_PAYROLL_ADMIN,
  ROLE_PAYROLL_ADMIN_READ_ONLY,
  ROLE_PAYROLL_ANALYST,
  ROLE_PAYROLL_ANALYST_READ_ONLY,
  ROLE_PAYROLL_DATA_ENTRY,
  ROLE_PAYROLL_DATA_ENTRY_READ_ONLY,
} from 'redux/config/auth'

export const getKURoleTypes = () => [
  {
    name: i18n.t('BusinessComponents:BusinessComponent.key_user_role.payroll_admin'),
    type: ROLE_PAYROLL_ADMIN,
    readOnlyType: ROLE_PAYROLL_ADMIN_READ_ONLY,
  },
  {
    name: i18n.t('BusinessComponents:BusinessComponent.key_user_role.payroll_analyst'),
    type: ROLE_PAYROLL_ANALYST,
    readOnlyType: ROLE_PAYROLL_ANALYST_READ_ONLY,
  },
  {
    name: i18n.t('BusinessComponents:BusinessComponent.key_user_role.payroll_data_entry'),
    type: ROLE_PAYROLL_DATA_ENTRY,
    readOnlyType: ROLE_PAYROLL_DATA_ENTRY_READ_ONLY,
  },
  {
    name: i18n.t('BusinessComponents:BusinessComponent.key_user_role.finance_admin'),
    type: ROLE_FINANCE_ADMIN,
    readOnlyType: ROLE_FINANCE_ADMIN_READ_ONLY,
  },
  {
    name: i18n.t('BusinessComponents:BusinessComponent.key_user_role.accounting'),
    type: ROLE_ACCOUNTING,
    readOnlyType: ROLE_ACCOUNTING_READ_ONLY,
  },
  {
    name: i18n.t('BusinessComponents:BusinessComponent.key_user_role.controlling'),
    type: ROLE_CONTROLLING,
    readOnlyType: ROLE_CONTROLLING_READ_ONLY,
  },
]
