import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { statuses } from 'redux/config/payrollInstanceTasks'
import Form from 'components/form/Form'
import moment from 'moment'
import { owners } from 'redux/config/tasks'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PayrollInstanceTaskList = (props) => {
  const { openFilesModal, onMultipleTaskClick, tasks } = props
  const { t } = useTranslation([i18nNameSpaces.Payrun])
  const renderOwner = (task) => {
    if (task.shouldShowMultiOwnersText) {
      return (
        <span onClick={() => onMultipleTaskClick(task.id, false)} className='u-text--curious u-cursor--pointer' data-testid={`mao-${task.id}`}>
          {t('Payrun.text.multiple_owners')}
        </span>
      )
    }

    if (task.ownerName === owners.company) {
      return <span className='u-text--italic'>{task.ownerName}</span>
    }

    return task.ownerName
  }

  const renderAction = (task) => {
    const { finishTaskLink, alphabeticName } = task

    if (!finishTaskLink) {
      return <span>{alphabeticName}</span>
    }

    if (_.includes(finishTaskLink, 'openFilesModal')) {
      return (
        <Link to={finishTaskLink} className='u-text--curious' onClick={openFilesModal} data-testid={`actionName-${alphabeticName}`}>
          {alphabeticName}
        </Link>
      )
    }

    return (
      <Link to={finishTaskLink} className='u-text--curious' data-testid={`actionName-${alphabeticName}`}>
        {alphabeticName}
      </Link>
    )
  }

  const buildStatuses = (task) => {
    const normalized = _.map(statuses, ({ value, label }) => ({
      value,
      label,
    }))

    return (
      normalized
        // Should or should not list `reverted` status
        .filter((status) => {
          // Here the logic is just for the 'reverted' status only. Don't touch the rest ones.
          if (status.value !== statuses.reverted.value) return true
          // Leave `reverted` status in the case the task `isReversible` or the current task already had `reverted` status
          return task.isReversible || task.status === statuses.reverted.value
        })
        .map((status) => ({
          ...status,
          // BE returns which statuses are disabled to be selected by the user.
          disabled: task.disabledStatuses.includes(status.value),
        }))
    )
  }

  const renderPopupRows = () => {
    return tasks.map((task) => {
      const lockPayrollInstanceToUpdateTask = task?.extraData?.lockPayrollInstanceToUpdateTask
      return {
        action: renderAction(task, openFilesModal),
        actionOwner: renderOwner(task),
        dueDate: task.deadline ? moment(task.deadline.date).format('DD/MM/YYYY') : t('Global:Global.text.not_set'),
        status: (
          <Field
            name={`${JSON.stringify({ id: task.id })}.status`}
            label=''
            component={CustomSelectField}
            type='text'
            className={`c-custom-select--transparent c-custom-select--min-width--small
          ${lockPayrollInstanceToUpdateTask ? 'is-frozen' : ''}
          `}
            labelClassName='c-label'
            formGroupClassName='u-margin-none'
            clearable={false}
            placeholder='Select status'
            options={buildStatuses(task)}
            disabled={!task.isEditable || lockPayrollInstanceToUpdateTask}
            data-testid={`payrollInstanceTaskId-${task.id}`}
            aria-label={`${lockPayrollInstanceToUpdateTask ? t('Payrun.text.payrun_unlocked_message') : ''}`}
          />
        ),
      }
    })
  }

  return (
    <div className='o-layout'>
      <Form {...props} data-testid='payrollInstanceTask-form'>
        <div className='o-layout__item u-1/1'>
          <PayslipTable
            wrapperClassName='u-1/1'
            tableElementClassName='u-margin-bottom-large table--layout-auto'
            data={renderPopupRows()}
            headings={[
              {
                Header: t('Payrun.payslipTable.header.action'),
                disableFilters: true,
                disableSortBy: true,
                accessor: 'action',
              },
              {
                Header: t('Payrun.payslipTable.header.action_owner'),
                disableFilters: true,
                disableSortBy: true,
                setWidth: '160px',
                classNames: 'white-space--unset',
                accessor: 'actionOwner',
              },
              {
                Header: t('Payrun.payslipTable.header.due_date'),
                disableFilters: true,
                disableSortBy: true,
                accessor: 'dueDate',
              },
              {
                Header: t('Payrun.payslipTable.header.status'),
                disableFilters: true,
                disableSortBy: true,
                accessor: 'status',
                columnClassName: 'u-overflow-visible',
              },
            ]}
          />
        </div>
      </Form>
    </div>
  )
}

PayrollInstanceTaskList.propTypes = {
  openFilesModal: PropTypes.func,
  onMultipleTaskClick: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  t: PropTypes.func,
}

export default PayrollInstanceTaskList
