import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'

const AddVendorToServiceForm = ({ isFetching, vendors, ...props }) => {
  const { t } = useTranslation()

  return (
    <Form {...props}>
      <div className='o-layout'>
        <div className='o-layout__item'>
          <div className='o-form-group'>
            <Field
              name='vendorCountryService'
              label={t('Global.text.vendor')}
              component={CustomSelectField}
              type='number'
              labelClassName='c-label u-text--small'
              formGroupClassName='u-margin-none'
              placeholder={t('Global.select.placeholder')}
              options={vendors.map((vendor) => ({
                value: vendor.vendorCountryService.id,
                label: vendor.name,
              }))}
            />
          </div>
        </div>
        <div className='o-layout__item u-text--center u-1/1'>
          <button className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'>{t('Global.button.save')}</button>
        </div>
      </div>
    </Form>
  )
}

AddVendorToServiceForm.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      vendorCountryService: PropTypes.shape({
        id: PropTypes.number,
      }),
    })
  ),
}

export default AddVendorToServiceForm
