import i18n from 'i18next'

export const getMonthNamesEnum = () => ({
  JANUARY: i18n.t('Global.month.january'),
  FEBRUARY: i18n.t('Global.month.february'),
  MARCH: i18n.t('Global.month.march'),
  APRIL: i18n.t('Global.month.april'),
  MAY: i18n.t('Global.month.may'),
  JUNE: i18n.t('Global.month.june'),
  JULY: i18n.t('Global.month.july'),
  AUGUST: i18n.t('Global.month.august'),
  SEPTEMBER: i18n.t('Global.month.september'),
  OCTOBER: i18n.t('Global.month.october'),
  NOVEMBER: i18n.t('Global.month.november'),
  DECEMBER: i18n.t('Global.month.december'),
})

export const MonthValueEnum = {
  1: 'JANUARY',
  2: 'FEBRUARY',
  3: 'MARCH',
  4: 'APRIL',
  5: 'MAY',
  6: 'JUNE',
  7: 'JULY',
  8: 'AUGUST',
  9: 'SEPTEMBER',
  10: 'OCTOBER',
  11: 'NOVEMBER',
  12: 'DECEMBER',
}

export const getMonthNameByIndex = (index) => {
  const monthNames = getMonthNamesEnum()
  const monthKey = MonthValueEnum[index]
  if (!monthNames[monthKey]) return ''
  return monthNames[monthKey]
}

export const getLocalizedWeekWithShortNameDays = () => [
  i18n.t('Global.weekday.short.monday'),
  i18n.t('Global.weekday.short.tuesday'),
  i18n.t('Global.weekday.short.wednesday'),
  i18n.t('Global.weekday.short.thursday'),
  i18n.t('Global.weekday.short.friday'),
  i18n.t('Global.weekday.short.saturday'),
  i18n.t('Global.weekday.short.sunday'),
]
