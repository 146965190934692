import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PropTypes from 'prop-types'
import { i18nNameSpaces } from 'i18n/types'

const StepCreate = (props) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const { services, processes } = props

  return (
    <div>
      <SectionHeading text={t('Global:Global.section.create_step')} />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <Form showSubmitBtn btnText={t('Global:Global.button.submit')} {...props}>
            <div className='o-form-group'>
              <Field name='name' label={t('Global:Global.text.name')} component={InputField} type='text' className='c-input' labelClassName='c-label' />
            </div>
            <div className='o-form-group'>
              <Field
                name='service'
                label={t('Global:Global.text.service')}
                component={CustomSelectField}
                labelClassName='c-label'
                clearable={false}
                placeholder={services.length ? t('BusinessComponent.placeholder.select_service') : t('Global:Global.text.loading')}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='process'
                label={t('Global:Global.text.process')}
                component={CustomSelectField}
                type='number'
                labelClassName='c-label'
                clearable={false}
                placeholder={t('BusinessComponent.placeholder.select_process')}
                options={processes.map((process) => ({
                  value: process.id,
                  label: process.name,
                }))}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

StepCreate.propTypes = {
  services: PropTypes.array,
  processes: PropTypes.array,
}

export default reduxForm({
  form: 'step', // a unique identifier for this form
})(StepCreate)
