import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import { getClientListTableConfig } from 'routes/Clients/components/clientListTableConfig'
import { useDispatch, useSelector } from 'react-redux'
import { isCotAdmin } from 'redux/selectors/auth'
import { ConfigurationModal } from 'components/configuration/ConfigurationModal'
import { i18nNameSpaces } from 'i18n/types'
import { getClientListFormFields } from 'routes/Clients/components/clientListFormConf'
import { getSystemAvailableLocales } from 'redux/actions/api'
import { updateParentCompany } from 'redux/actions/parentCompanies'
import { isEmpty } from 'utils/fnkit/typeChecks'

const ClientsList = ({ clients, referrals, selectTenantSubmit }) => {
  const { t } = useTranslation([i18nNameSpaces.Client])
  const dispatch = useDispatch()
  const systemAvailableLocales = useSelector((state) =>
    state.config.systemAvailableLocales?.map((lang) => ({
      label: lang.label,
      value: lang.id,
    }))
  )
  const showActions = useSelector(isCotAdmin)
  const editModalRef = useRef(null)
  const [selectedTenant, setSelectedTenant] = useState()
  const configurationModalLabels = {
    heading: t('Client:Client.text.details'),
  }
  const fetchSystemLocales = async () => {
    await dispatch(getSystemAvailableLocales())
  }

  useEffect(() => {
    fetchSystemLocales().catch(console.error)
  }, [dispatch])

  const renderData = (clients, referrals) => {
    return clients.map((client) => {
      // Filter referrals array, so we can extract the appropriate referral name, according to the parentCompanyId
      const referral = referrals.length > 0 ? referrals.find((referral) => referral.parentCompanies.includes(client.id)) : null
      return {
        id: client.id,
        name: client.name,
        url: client.url,
        shortUrl: client.shortUrl,
        referral: referral ? referral.name : '',
        locales: client.locales?.map((locale) => ({ value: locale.id, name: locale.label })),
      }
    })
  }

  const options = {
    searchPosition: 'left',
    noDataText: t('Client:Client.table.no_matching_records'),
  }

  const handleUpdateAction = (client) => {
    const newSelection = { ...client, locales: client.locales?.reduce((acc, l) => [...acc, l.value], []) }
    setSelectedTenant(newSelection)
    editModalRef.current?.showModal()
  }
  const handleSubmit = (data) => {
    const { id, locales, referral, ...updatedClient } = data
    updatedClient.locales = isEmpty(locales) ? ['en'] : locales
    dispatch(updateParentCompany(updatedClient, id))
  }

  return (
    <div>
      <SectionHeading text={t('Client:Client.sectionHeading.clients_list')} />
      <ConfigurationModal
        modalRef={editModalRef}
        formName={`configuration-modal-form`}
        labels={configurationModalLabels}
        fields={getClientListFormFields({
          systemAvailableLocales,
        })}
        initialValues={selectedTenant}
        onSubmit={handleSubmit}
      />
      <PayslipTable
        data={renderData(clients, referrals)}
        headings={getClientListTableConfig({ selectTenantSubmit, handleUpdateAction, showActions })}
        wrapperClassName='u-1/1@tablet '
        trClassName='react-bs-table__cell--overflow-ellipsis'
        options={options}
        search
      />
    </div>
  )
}

ClientsList.propTypes = {
  clients: PropTypes.array,
  referrals: PropTypes.array,
  selectTenantSubmit: PropTypes.func,
}

export default ClientsList
