import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { dateTimePayslipTable } from 'utils/tableDataFormatters'
import { replace, template } from 'utils/fnkit/string'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const HistoryList = ({ logs, actionColumnText, headingsOverrides = {} }) => {
  const { t } = useTranslation(i18nNameSpaces.BusinessComponents)
  const actionText = actionColumnText || t('Global:Global.button.action')
  const tableData = logs.map((log) => {
    let message = log.message

    // Replace '${' globally with '<b>${' and '}' with '}</b>'
    message = replace(message, '${', '<b>${')
    message = replace(message, ' ${', ' <b>${')
    message = replace(message, new RegExp('}', 'g'), '}</b>')

    // Compile message using lodash template
    let compiled = template(message)
    let builtMessage = compiled(log.template)

    return {
      id: log.id,
      actionDate: log.actionDate,
      message: <div dangerouslySetInnerHTML={{ __html: builtMessage }} />,
    }
  })
  const headings = [
    { accessor: 'actionDate', Header: t('Global:Global.text.date'), disableSortBy: true, Cell: dateTimePayslipTable, ...headingsOverrides },
    { accessor: 'message', Header: actionText, disableSortBy: true, ...headingsOverrides },
  ]

  const options = { noDataMessage: t('Global:Global.text.no_history'), pageIndex: 0, pageSize: 25 }

  return (
    <div className='o-layout'>
      <PayslipTable
        data={tableData}
        headings={headings}
        pagination
        options={options}
        trClassName='react-bs-table__cell--whitespace--normal'
        wrapperClassName='o-layout__item u-1/1'
        dynamicValues
      />
    </div>
  )
}

HistoryList.propTypes = {
  logs: PropTypes.array,
  actionColumnText: PropTypes.string,
}

export default HistoryList
