import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import moment from 'moment'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import ChangedBySelectContainer from '../containers/ChangedBySelectContainer'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import SubmitButtons from './SubmitButtons'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { sortByLabel } from 'utils/strings'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { getDateBasedOnMaxDate } from 'utils/fnkit/dates/checks'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const FiltersChangeLogReport = (props) => {
  const {
    selected,
    countries,
    companies,
    businessUnits,
    isFieldDisabled,
    disabledReason,
    mostRecentRunReport = {},
    hideNavigationButtons,
    navigateToParentOnClick,
    handleReportModalOpen,
    deleteReport,
    reportItem,
  } = props

  const [state, setState] = useState({
    startDate: moment(selected.fromDate || '01-06-2022', 'MM-DD-YYYY').toDate(),
    maxDate: moment(selected.fromDate || '01-06-2022', 'MM-DD-YYYY')
      .add(31, 'days')
      .toDate(),
  })

  const { t } = useTranslation([i18nNameSpaces.Report])
  const requiredSymbol = '*'

  const { startDate, maxDate } = state

  useEffect(() => {
    if (selected.fromDate) {
      setState((prevState) => ({
        ...prevState,
        maxDate: moment(selected.fromDate, 'MM-DD-YYYY').add(31, 'days').toDate(),
      }))
    }
  }, [selected.fromDate])

  const setMaxDate = () => {
    setState((prevState) => ({
      ...prevState,
      maxDate: moment(props.selected.fromDate).add(31, 'days').toDate(),
    }))
  }

  const shouldShowMostRecentRunReportItem = !reportItem
  const isRolesAndRightsReport = selected.subcategory === 'RolesAndRights'
  const showPayrollFilters = !isRolesAndRightsReport
  const shouldLimitFromTo = !isRolesAndRightsReport
  const isMultiCountryAndCompany = isRolesAndRightsReport

  return (
    <div className='o-block--inline'>
      {selected.subcategory && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input date-picker--with-tooltip'>
            <Field
              className='c-input c-input--transparent c-input__value-placeholder'
              label={t('Report.field.from_date_required', { requiredSymbol })}
              labelClassName='c-label u-text--small u-text--curious'
              name='fromDate'
              component={FieldDatePicker}
              calendarClassName='report-show-disabled-message'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              formGroupClassName='u-margin-none'
              isClearable={!isFieldDisabled}
              minDate={startDate}
              data-testid='from-date'
              onChange={shouldLimitFromTo ? setMaxDate : null}
            />
          </div>
        </div>
      )}
      {selected.fromDate && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              className='c-input c-input--transparent c-input__value-placeholder'
              label={t('Report.field.to_date_required', { requiredSymbol })}
              labelClassName='c-label u-text--small u-text--curious'
              name='toDate'
              component={FieldDatePicker}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              formGroupClassName='u-margin-none'
              isClearable={!isFieldDisabled}
              minDate={moment(selected.fromDate, 'MM-DD-YYYY').toDate()}
              maxDate={shouldLimitFromTo && maxDate}
              selected={shouldLimitFromTo && getDateBasedOnMaxDate(selected.toDate, maxDate)}
              data-testid='to-date'
            />
          </div>
        </div>
      )}
      {selected.fromDate && selected.toDate && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='country'
              label={t('Report.field.country_required', { requiredSymbol })}
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              multi={reportItem || isMultiCountryAndCompany}
              optionComponent={isMultiCountryAndCompany ? CheckboxOption : undefined}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
                abbreviature: country.abbreviature,
              }))}
              enableRemovedOptionFix
              data-testid='country-wrapper'
            />
          </div>
        </div>
      )}
      {!isEmpty(selected.country) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='company'
              label={t('Report.field.company_required', { requiredSymbol })}
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              multi={reportItem || isMultiCountryAndCompany}
              optionComponent={isMultiCountryAndCompany ? CheckboxOption : undefined}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              options={companies
                .map((company) => ({
                  value: company.id,
                  label: company.name,
                }))
                .sort(sortByLabel)}
              enableRemovedOptionFix
              data-testid='company-wrapper'
            />
          </div>
        </div>
      )}
      {!isEmpty(selected.company) && showPayrollFilters && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <PayrollSelectContainer
              hasNoneOption
              payrollRequired={false}
              optionComponent={CheckboxOption}
              multi
              {...props}
              selected={{
                ...selected,
                fromDate: null,
                toDate: null,
              }}
            />
          </div>
        </div>
      )}
      {!isEmpty(selected.company) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='employmentStatus'
              label={t('Report.field.type_of_employment_engagement')}
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={CheckboxOption}
              placeholder={t('Global:Global.placeholder.any')}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              options={EmploymentStatusHelper.allStatusOptionsForSelectBox}
              data-testid='type-of-employment'
              multi
              hasNoneOption
            />
          </div>
        </div>
      )}
      {!isEmpty(selected.company) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='businessUnit'
              label={t('Report.field.business_unit')}
              component={CustomSelectField}
              optionComponent={CheckboxOption}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.placeholder.any')}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={businessUnits
                .map((unit) => ({
                  value: unit.id,
                  label: unit.name,
                }))
                .sort(sortByLabel)}
              data-testid='businessUnit-wrapper'
              enableRemovedOptionFix
              hasNoneOption
            />
          </div>
        </div>
      )}
      {!isEmpty(selected.country) && !isEmpty(selected.company) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <ChangedBySelectContainer {...props} />
          </div>
        </div>
      )}
      {!hideNavigationButtons && <SubmitButtons pristineCheck={!isEmpty(selected.company)} onBackClick={navigateToParentOnClick} {...props} />}
      {shouldShowMostRecentRunReportItem && (
        <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem mostRecentRunReport={mostRecentRunReport} onOpenReport={handleReportModalOpen} onDelete={deleteReport} {...props} />
        </div>
      )}
    </div>
  )
}

FiltersChangeLogReport.propTypes = {
  resetFormFields: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  selected: PropTypes.object,
  countries: PropTypes.array,
  companies: PropTypes.array,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  departments: PropTypes.array,
  payrolls: PropTypes.array,
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  hideNavigationButtons: PropTypes.bool,
  reportItem: PropTypes.object,
}

export default FiltersChangeLogReport
