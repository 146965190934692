import i18n from 'i18next'

export const getStaticPaginationOpts = () => ({
  noDataMessage: i18n.t('Global:Global.text.no_matching_records_found'),
  sizePerPageList: [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ],
  showPageSizeSelector: false,
})

/**
 * Return pagination object
 *
 * @param {NUmber} limit
 * @param {NUmber} offset
 * @param {NUmber} totalCount
 *
 */
export const getPaginationObject = (limit = 10, offset = 0, totalCount) => {
  return {
    noDataText: i18n.t('Global:Global.text.no_matching_records_found'),
    pageSize: limit,
    pageIndex: Math.ceil(offset / limit),
    pageCount: Math.ceil(totalCount / limit),
  }
}
