import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import EventsFilters from 'components/EventsFilters'
import { fetchAssignments } from 'redux/actions/assignments'
import PropTypes from 'prop-types'
import { buildFilters } from 'utils/filters'
import { createFilter } from 'utils/redux/filter'
import moment from 'moment'
import { isEmpty, isDate, isString } from 'lodash'
import i18n from 'i18next'
class AssignmentsFiltersContainer extends React.Component {
  componentDidMount () {
    const { shouldFilterInitially, handleSubmit } = this.props

    if (shouldFilterInitially) handleSubmit()
  }

  render () {
    return <EventsFilters shouldDisplay {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, { initialValues }) => {
  return {
    onSubmit: ({ payrollsAndProcesses, ...rest }) => {
      let restFilters = rest

      if (isEmpty(rest)) return
      if (restFilters.actionStatus === 'overdue') {
        restFilters.view = 'year'
        restFilters.headerText = i18n.t('Global:Global.actions.overdue_actions')
        restFilters.shouldShowDateFilters = false
        restFilters.fromDate = moment.unix(0)
        restFilters.toDate = moment().endOf('day')
        restFilters.status = ['in-progress', 'not-started', 'reverted']
        restFilters.sort = [{ name: 'deadline', order: 'asc' }]
      } else {
        delete restFilters.view
        restFilters.shouldShowDateFilters = true
        restFilters.view = 'month'
        if (restFilters.fromDate && !isDate(restFilters.fromDate)) {
          if (isString(restFilters.fromDate) || restFilters.fromDate.unix() === 0) {
            restFilters.fromDate = moment().startOf('month')
          }
        }
        delete restFilters.status
      }

      const filters = buildFilters(restFilters)
      let defaultFilters = {
        ...filters,
        notInactive: true,
      }
      return dispatch(
        fetchAssignments({
          filter: createFilter(defaultFilters, 'assignments'),
          disableObsoleteFlow: true,
        })
      )
    },
  }
}

AssignmentsFiltersContainer.propTypes = {
  shouldFilterInitially: PropTypes.bool,
  handleSubmit: PropTypes.func,
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'filterTasks',
  })(AssignmentsFiltersContainer)
)
