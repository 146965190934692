/**
 * Creates a base route object with title, name, and path properties.
 * @param {string} title - The title of the route, displayed as part of the UI.
 * @param {string} name - The unique name of the route used for identification.
 * @param {string} [path=''] - The path of the route. Defaults to an empty string.
 * @returns {{title: string, name: string, path: string}} An object representing a base route.
 */
export const createBaseRoute = (title, name, path) => ({
  title,
  name,
  path,
})

/**
 * Enhances a base route object with additional options.
 *
 * This function takes a base route object and an options object, merging them to include
 * additional properties such as link behavior, child components, sub-route indicator, click handlers,
 * path matching exactness, active state, data test ID for testing, and home route indicator.
 *
 * @param {Object} baseRoute - The base route object to be enhanced. It should at least contain title, name, and path.
 * @param {Object} options - The options to add to the base route.
 * @param {boolean} [options.isLink=false] - Indicates if the route functions as a link.
 * @param {any} [options.children] - Child components or elements to include in the route.
 * @param {boolean} [options.sub=false] - Marks the route as a sub-route if true.
 * @param {Function} [options.onClick] - A click handler function for the route.
 * @param {boolean} [options.exact=false] - Ensures that the route matches exactly if true.
 * @param {boolean} [options.isActive=false] - Indicates if the route is currently active.
 * @param {string} [options.dataTestId] - A data test ID used for testing purposes.
 * @param {boolean} [options.isHome=false] - Marks the route as the home route if true.
 * @returns {Object} The enhanced route object with the original properties and the added options.
 */
export const addRouteOptions = (baseRoute, { path, isLink, children, sub, onClick, isExternal, exact, isActive, dataTestId, isHome }) => {
  return {
    ...baseRoute,
    path: baseRoute?.path || path,
    isLink,
    isExternal,
    children,
    sub,
    onClick,
    exact,
    isActive,
    dataTestId,
    isHome,
  }
}

/**
 * Defines global routes for the application's side navigation.
 *
 * This object contains a collection of routes used across different side menus within the application.
 * Each route is defined using the `createBaseRoute` function, ensuring consistency in structure.
 * These routes are pre-configured with titles from `asideTitles`, unique names, and paths,
 * making them ready for use in constructing side navigation menus or routing logic.
 *
 * @type {Object.<string, {title: string, name: string, path: string}>}
 */
export const createGlobalRoutes = (t) => ({
  home: createBaseRoute(t('Global:Global.aside.home'), 'home', '/home'),
  company: createBaseRoute(t('Global:Global.aside.company'), 'company', '/companies'),
  people: createBaseRoute(t('Global:Global.aside.people'), 'people', '/employees'),
  payroll: createBaseRoute(t('Global:Global.aside.payroll'), 'payroll', '/payruns/active'),
  calendar: createBaseRoute(t('Global:Global.aside.calendar'), 'calendar', '/toolbox/calendar'),
  alerts: createBaseRoute(t('Global:Global.aside.alerts'), 'payroll', '/toolbox/alerts'),
  toolbox: createBaseRoute(t('Global:Global.aside.toolbox'), 'toolbox', '/toolbox'),
  countries: createBaseRoute(t('Global:Global.aside.countries'), 'payroll', '/countries'),
  globalTerms: createBaseRoute(t('Global:Global.aside.globalTerms'), 'payroll', '/terms'),
  globalTermsSubcategories: createBaseRoute(t('Global:Global.aside.globalTermsSubcategories'), 'payroll', '/term-subcategories'),
  clients: createBaseRoute(t('Global:Global.aside.clients'), 'payroll', '/clients'),
  globalServices: createBaseRoute(t('Global:Global.aside.globalServices'), 'global-services', '/services'),
  processes: createBaseRoute(t('Global:Global.aside.processes'), 'processes', '/processes'),
  steps: createBaseRoute(t('Global:Global.aside.steps'), 'steps', '/steps'),
  actions: createBaseRoute(t('Global:Global.aside.actions'), 'actions', '/actions'),
  vendors: createBaseRoute(t('Global:Global.aside.vendors'), 'payroll', '/vendors'),
  cotUsers: createBaseRoute(t('Global:Global.aside.cotUsers'), 'cot-users', '/users'),
  picc: createBaseRoute(t('Global:Global.aside.picc'), 'control-center'),
  resources: createBaseRoute(t('Global:Global.aside.resources'), 'resources', '/resources/releases'),
})
