import i18n from 'i18next'

import { graphTypesEnum, totalsCalculations } from 'utils/enums/analyticsEnums'
import employeeStatusEnum from '../utils/enums/employmentStatuses'

const getAnalyticsRevisionConfig = () => ({
  SIDE_MULTI_SELECTOR_BARCHART: {
    chartId: 'reversionsChart',
    defaultAxis: {
      xAxis: 'country',
      yAxis: 'noOfReversions',
    },
    selectors: [
      {
        id: 'xAxis',
        title: i18n.t('Report:Report.config.reversions_by'),
        optionsKey: 'SIDE_BAR_FILTER_OPTIONS',
      },
      {
        id: 'yAxis',
        title: i18n.t('Report:Report.config.reversions_type'),
        optionsKey: 'SIDE_BAR_REVERSIONS_OPTIONS',
        className: 'u-min-width-250',
      },
    ],
  },
  SIDE_BAR_REVERSIONS_OPTIONS: [
    { value: 'noOfReversions', label: i18n.t('Report:Report.config.total_reversions') },
    { value: 'newRunRequestedCompany', label: i18n.t('Report:Report.config.new_run_requested_company') },
    { value: 'newRunRequestedPayrollPartner', label: i18n.t('Report:Report.config.new_run_requested_payroll_partner') },
    { value: 'newRunRequestedOther', label: i18n.t('Report:Report.config.new_run_requested_other') },
    { value: 'clarificationRequired', label: i18n.t('Report:Report.config.clarification_required') },
  ],
})
const getAnalyticsWorkflowConfig = () => ({
  CHART_LOGIC: {
    IS_WORKFLOW_TOTALS_COUNT: true,
    TOTALS_KEY_VALUE: 'Totals',
    TITLE: i18n.t('Report:Report.config.gtn_versions_by'),
    TILE_CALC_KEYS: {
      ON_TIME_COMPANY_KEY: 'onTimeActionsCompanyPercentage',
      ON_TIME_PAYROLL_PARTNER_KEY: 'onTimeActionsPayrollPartnerPercentage',
      ON_TIME_GTN_VERSIONS_KEY: 'noOfGtns',
    },
    SIDE_BARCHART_AXIS: { chartId: 'gtnChart', xAxis: 'country', yAxis: 'noOfGtns' },
    ...getAnalyticsRevisionConfig(),
    SIDE_BAR_FILTER_OPTIONS: [
      { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
      { value: 'company', label: i18n.t('Global:Global.text.company') },
      { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
    ],
    NOT_ASSIGNED_TEXT: i18n.t('Report:Report.config.not_assigned'),
  },
  REPORT_CHARTS: {
    AVG_ON_TIME_COMPLETION_COMPANY: graphTypesEnum.TILE_COUNT,
    AVG_ON_TIME_COMPLETION_PAYROLL_PROVIDER: graphTypesEnum.TILE_COUNT,
    AVG_ON_TIME_COMPLETION_GTN_VERSIONS: graphTypesEnum.TILE_COUNT,
    NUMBER_OF_GTN_VERSIONS: graphTypesEnum.SIDE_BARCHART,
    NUMBER_OF_REVERSIONS: graphTypesEnum.SIDE_BARCHART,
  },
})

export const getReportOptionsConfig = () => ({
  GLOBAL_WORK_HEADCOUNT: {
    LABEL: i18n.t('Report:Report.config.global_workforce_headcount_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_workforce_headcount_description'),
    VALUE: 1,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  GLOBAL_JOINERS_HEADCOUNT: {
    LABEL: i18n.t('Report:Report.config.global_joiners_headcount_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_joiners_headcount_description'),
    VALUE: 2,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  GLOBAL_LEAVERS_HEADCOUNT: {
    LABEL: i18n.t('Report:Report.config.global_leavers_headcount_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_leavers_headcount_description'),
    VALUE: 3,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  WORKFORCE_HEADCOUNT: {
    LABEL: i18n.t('Report:Report.config.workforce_headcount_label'),
    DESCRIPTION: i18n.t('Report:Report.config.workforce_headcount_description'),
    VALUE: 4,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  JOINERS_HEADCOUNT: {
    LABEL: i18n.t('Report:Report.config.joiners_headcount_label'),
    DESCRIPTION: i18n.t('Report:Report.config.joiners_headcount_description'),
    VALUE: 5,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  LEAVERS_HEADCOUNT: {
    LABEL: i18n.t('Report:Report.config.leavers_headcount_label'),
    DESCRIPTION: i18n.t('Report:Report.config.leavers_headcount_description'),
    VALUE: 6,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
    },
    REPORT_CHARTS: {
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  WORKFORCE_DETAIL: {
    LABEL: i18n.t('Report:Report.config.workforce_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.workforce_detail_description'),
    VALUE: 7,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      EMPLOYEMENT_STATUS: 'employementStatus',
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
        { value: 'businessUnit', label: 'Business Unit' },
        { value: 'costCenter', label: 'Cost Center' },
        { value: 'department', label: 'Department' },
        { value: 'officeLocation', label: 'Office Location' },
        { value: 'project', label: 'Project' },
        { value: 'subsidiary', label: 'Subsidiary' },
      ],
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      CHART_DATAKEYS_COLORS: {
        [employeeStatusEnum.LOCAL.VALUE]: '#5292dd',
        [employeeStatusEnum.EXPAT.VALUE]: '#2268b4',
        [employeeStatusEnum.CONTRACTOR.VALUE]: '#88c8b4',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: '#e293a7',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: '#91d4e8',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: '#ffca0a',
        [employeeStatusEnum.SUPPLIER.VALUE]: '#7ab059',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: '#462b65',
        'Not Assigned': '#f6ab66',
      },
      CHART_DATAKEYS_CSS_NAMES: {
        [employeeStatusEnum.LOCAL.VALUE]: 'local',
        [employeeStatusEnum.EXPAT.VALUE]: 'expat',
        [employeeStatusEnum.CONTRACTOR.VALUE]: 'contractor',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: 'employer-of-record',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: 'short-term-assignment',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: 'short-term-assignment-host',
        [employeeStatusEnum.SUPPLIER.VALUE]: 'supplier',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: 'non-employee',
        'Not Assigned': 'not-assigned',
      },
      LINE_CHART_DATAKEYS: {
        [employeeStatusEnum.LOCAL.VALUE]: [employeeStatusEnum.LOCAL.VALUE],
        [employeeStatusEnum.EXPAT.VALUE]: [employeeStatusEnum.EXPAT.VALUE],
        [employeeStatusEnum.CONTRACTOR.VALUE]: [
          employeeStatusEnum.CONTRACTOR.VALUE,
          employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE,
          employeeStatusEnum.CONTRACTOR_COMPANY.VALUE,
          employeeStatusEnum.CONTRACTOR_AGENCY.VALUE,
        ],
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE],
        [employeeStatusEnum.SUPPLIER.VALUE]: [employeeStatusEnum.SUPPLIER.VALUE],
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: [employeeStatusEnum.NON_EMPLOYEE.VALUE],
        'Not Assigned': ['Not Assigned'],
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      HEADCOUNT_BY_MONTH: graphTypesEnum.LINE_CHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
      HEADCOUNT_BY_WORKER_TYPE: graphTypesEnum.PIE_CHART,
    },
  },
  JOINERS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.joiners_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.joiners_detail_description'),
    VALUE: 8,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      EMPLOYEMENT_STATUS: 'employementStatus',
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      DISABLED_REPORTS: ['HEADCOUNT_BY_MONTH'],
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
        { value: 'businessUnit', label: 'Business Unit' },
        { value: 'costCenter', label: 'Cost Center' },
        { value: 'department', label: 'Department' },
        { value: 'officeLocation', label: 'Office Location' },
        { value: 'project', label: 'Project' },
        { value: 'subsidiary', label: 'Subsidiary' },
      ],
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      CHART_DATAKEYS_COLORS: {
        [employeeStatusEnum.LOCAL.VALUE]: '#5292dd',
        [employeeStatusEnum.EXPAT.VALUE]: '#2268b4',
        [employeeStatusEnum.CONTRACTOR.VALUE]: '#88c8b4',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: '#e293a7',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: '#91d4e8',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: '#ffca0a',
        [employeeStatusEnum.SUPPLIER.VALUE]: '#7ab059',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: '#462b65',
        'Not Assigned': '#f6ab66',
      },
      CHART_DATAKEYS_CSS_NAMES: {
        [employeeStatusEnum.LOCAL.VALUE]: 'local',
        [employeeStatusEnum.EXPAT.VALUE]: 'expat',
        [employeeStatusEnum.CONTRACTOR.VALUE]: 'contractor',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: 'employer-of-record',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: 'short-term-assignment',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: 'short-term-assignment-host',
        [employeeStatusEnum.SUPPLIER.VALUE]: 'supplier',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: 'non-employee',
        'Not Assigned': 'not-assigned',
      },
      LINE_CHART_DATAKEYS: {
        [employeeStatusEnum.LOCAL.VALUE]: [employeeStatusEnum.LOCAL.VALUE],
        [employeeStatusEnum.EXPAT.VALUE]: [employeeStatusEnum.EXPAT.VALUE],
        [employeeStatusEnum.CONTRACTOR.VALUE]: [
          employeeStatusEnum.CONTRACTOR.VALUE,
          employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE,
          employeeStatusEnum.CONTRACTOR_COMPANY.VALUE,
          employeeStatusEnum.CONTRACTOR_AGENCY.VALUE,
        ],
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE],
        [employeeStatusEnum.SUPPLIER.VALUE]: [employeeStatusEnum.SUPPLIER.VALUE],
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: [employeeStatusEnum.NON_EMPLOYEE.VALUE],
        'Not Assigned': ['Not Assigned'],
      },
    },
    REPORT_CHARTS: {
      GLOBAL_JOINERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      HEADCOUNT_BY_MONTH: graphTypesEnum.LINE_CHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
      HEADCOUNT_BY_WORKER_TYPE: graphTypesEnum.PIE_CHART,
    },
  },
  LEAVERS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.leavers_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.leavers_detail_description'),
    VALUE: 9,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      EMPLOYEMENT_STATUS: 'employementStatus',
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      DISABLED_REPORTS: ['HEADCOUNT_BY_MONTH'],
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
        { value: 'businessUnit', label: 'Business Unit' },
        { value: 'costCenter', label: 'Cost Center' },
        { value: 'department', label: 'Department' },
        { value: 'officeLocation', label: 'Office Location' },
        { value: 'project', label: 'Project' },
        { value: 'subsidiary', label: 'Subsidiary' },
      ],
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      CHART_DATAKEYS_COLORS: {
        [employeeStatusEnum.LOCAL.VALUE]: '#5292dd',
        [employeeStatusEnum.EXPAT.VALUE]: '#2268b4',
        [employeeStatusEnum.CONTRACTOR.VALUE]: '#88c8b4',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: '#e293a7',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: '#91d4e8',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: '#ffca0a',
        [employeeStatusEnum.SUPPLIER.VALUE]: '#7ab059',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: '#462b65',
        'Not Assigned': '#f6ab66',
      },
      CHART_DATAKEYS_CSS_NAMES: {
        [employeeStatusEnum.LOCAL.VALUE]: 'local',
        [employeeStatusEnum.EXPAT.VALUE]: 'expat',
        [employeeStatusEnum.CONTRACTOR.VALUE]: 'contractor',
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: 'employer-of-record',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: 'short-term-assignment',
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: 'short-term-assignment-host',
        [employeeStatusEnum.SUPPLIER.VALUE]: 'supplier',
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: 'non-employee',
        'Not Assigned': 'not-assigned',
      },
      LINE_CHART_DATAKEYS: {
        [employeeStatusEnum.LOCAL.VALUE]: [employeeStatusEnum.LOCAL.VALUE],
        [employeeStatusEnum.EXPAT.VALUE]: [employeeStatusEnum.EXPAT.VALUE],
        [employeeStatusEnum.CONTRACTOR.VALUE]: [
          employeeStatusEnum.CONTRACTOR.VALUE,
          employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE,
          employeeStatusEnum.CONTRACTOR_COMPANY.VALUE,
          employeeStatusEnum.CONTRACTOR_AGENCY.VALUE,
        ],
        [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE]: [employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE],
        [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE]: [employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE],
        [employeeStatusEnum.SUPPLIER.VALUE]: [employeeStatusEnum.SUPPLIER.VALUE],
        [employeeStatusEnum.NON_EMPLOYEE.VALUE]: [employeeStatusEnum.NON_EMPLOYEE.VALUE],
        'Not Assigned': ['Not Assigned'],
      },
    },
    REPORT_CHARTS: {
      GLOBAL_LEAVERS_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      HEADCOUNT_BY_MONTH: graphTypesEnum.LINE_CHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
      HEADCOUNT_BY_WORKER_TYPE: graphTypesEnum.PIE_CHART,
    },
  },
  EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT: {
    LABEL: i18n.t('Report:Report.config.ess_status_update_label'),
    DESCRIPTION: i18n.t('Report:Report.config.ess_status_update_description'),
    VALUE: 10,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Totals Both',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: ['totals'],
          blackList: ['ess turned', 'both', 'grand totals'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
      EMPLOYEE_SELF_SERVICE: graphTypesEnum.PIE_CHART,
    },
  },
  GLOBAL_WORKFLOW_AUDIT: {
    LABEL: i18n.t('Report:Report.config.global_workflow_audit_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_workflow_audit_description'),
    VALUE: 11,
    ...getAnalyticsWorkflowConfig(),
  },
  WORKFLOW_AUDIT: {
    LABEL: i18n.t('Report:Report.config.workflow_audit_label'),
    DESCRIPTION: i18n.t('Report:Report.config.workflow_audit_description'),
    VALUE: 12,
    ...getAnalyticsWorkflowConfig(),
  },
  PAYROLL_CHANGES_REPORT: {
    LABEL: i18n.t('Report:Report.config.payroll_changes_report_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payroll_changes_report_description'),
    VALUE: 13,
  },
  VARIANCE_REPORT: {
    LABEL: i18n.t('Report:Report.config.payrun_variance_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payrun_variance_description'),
    VALUE: 14,
  },
  EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT: {
    LABEL: i18n.t('Report:Report.config.ess_no_access_label'),
    DESCRIPTION: i18n.t('Report:Report.config.ess_no_access_description'),
    VALUE: 15,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: ['ess turned', 'both', 'grand totals'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYEE_SELF_SERVE_ACCESS_REPORT: {
    LABEL: i18n.t('Report:Report.config.ess_with_access_label'),
    DESCRIPTION: i18n.t('Report:Report.config.ess_with_access_description'),
    VALUE: 16,
    CHART_LOGIC: {
      TOTALS_KEY: 'country',
      OTHER_SHEETS_TOTALS: true,
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      ESS_ACTIVE_KEY: 'Totals ESS Turned On Yes',
      ESS_INACTIVE_KEY: 'Totals ESS Turned On No',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'total' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: ['ess turned', 'both', 'grand totals'],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
    },
    REPORT_CHARTS: {
      GLOBAL_WORKFORCE_HEADCOUNT: graphTypesEnum.TILE_COUNT,
      HEADCOUNT_BY_COUNTRY: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_TOTAL_EMPLOYER_COSTS: {
    LABEL: i18n.t('Report:Report.config.global_total_employer_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_total_employer_costs_description'),
    VALUE: 17,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_REPORT_KEY: 'totalEmployerCost',
      TOTALS_CALCULATION: totalsCalculations.CALCULATED,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totalEmployerCost' },
      SIDE_BARCHART_HEADER: 'Employer',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EMPLOYER_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYER_COSTS: {
    LABEL: i18n.t('Report:Report.config.total_employer_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.total_employer_costs_description'),
    VALUE: 18,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_REPORT_KEY: 'totalEmployerCost',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totalEmployerCost' },
      SIDE_BARCHART_HEADER: 'Employer',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EMPLOYER_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYER_COSTS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.employer_costs_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employer_costs_detail_description'),
    VALUE: 19,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_REPORT_KEY: 'totalEmployerCost',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totalEmployerCost' },
      SIDE_BARCHART_HEADER: 'Employer',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EMPLOYER_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_PAY_ELEMENT_COSTS: {
    LABEL: i18n.t('Report:Report.config.global_pay_element_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_pay_element_costs_description'),
    VALUE: 20,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Pay elements' },
      SIDE_BARCHART_HEADER: 'Pay Element',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_PAY_ELEMENT_COSTS: {
    LABEL: i18n.t('Report:Report.config.total_pay_element_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.total_pay_element_costs_description'),
    VALUE: 21,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Pay elements' },
      SIDE_BARCHART_HEADER: 'Pay Element',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  PAY_ELEMENTS_COSTS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.pay_elements_costs_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.pay_elements_costs_detail_description'),
    VALUE: 22,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Pay Element',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_EMPLOYER_CONTRIBUTION_COSTS: {
    LABEL: i18n.t('Report:Report.config.global_employer_contribution_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_employer_contribution_costs_description'),
    VALUE: 23,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employer Contributions' },
      SIDE_BARCHART_HEADER: 'Employer Contribution',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYER_CONTRIBUTION_COSTS: {
    LABEL: i18n.t('Report:Report.config.total_employer_contribution_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.total_employer_contribution_costs_description'),
    VALUE: 24,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employer Contributions' },
      SIDE_BARCHART_HEADER: 'Employer Contribution',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.employer_contributions_costs_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employer_contributions_costs_detail_description'),
    VALUE: 25,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Employer Contribution',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_EMPLOYEE_DEDUCTION_COSTS: {
    LABEL: i18n.t('Report:Report.config.global_employee_deduction_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_employee_deduction_costs_description'),
    VALUE: 26,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYEE_DEDUCTION_COSTS: {
    LABEL: i18n.t('Report:Report.config.total_employee_deduction_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.total_employee_deduction_costs_description'),
    VALUE: 27,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYEE_DEDUCTION_COSTS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.employee_deduction_costs_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employee_deduction_costs_detail_description'),
    VALUE: 28,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Employee Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS: {
    LABEL: i18n.t('Report:Report.config.global_employee_net_deduction_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_employee_net_deduction_costs_description'),
    VALUE: 29,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Net Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Net Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS: {
    LABEL: i18n.t('Report:Report.config.total_employee_net_deduction_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.total_employee_net_deduction_costs_description'),
    VALUE: 30,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'Employee Net Deductions' },
      SIDE_BARCHART_HEADER: 'Employee Net Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.employee_net_deduction_costs_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employee_net_deduction_costs_detail_description'),
    VALUE: 31,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'totals_numeric',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_HEADER: 'Employee Net Deduction',
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY: {
    LABEL: i18n.t('Report:Report.config.payrun_detail_by_subcategory_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payrun_detail_by_subcategory_description'),
    VALUE: 32,
  },
  PAYRUN_MANAGEMENT_REPORT: {
    LABEL: i18n.t('Report:Report.config.payrun_management_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payrun_management_detail_description'),
    VALUE: 33,
  },
  PAYRUN_REPORT: {
    LABEL: i18n.t('Report:Report.config.payrun_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payrun_detail_description'),
    VALUE: 34,
  },
  CHANGE_LOG: {
    LABEL: i18n.t('Report:Report.config.change_log_label'),
    DESCRIPTION: i18n.t('Report:Report.config.change_log_description'),
    VALUE: 35,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  CHANGE_LOG_GENERAL_UPDATES: {
    LABEL: i18n.t('Report:Report.config.general_updates_change_log_label'),
    DESCRIPTION: i18n.t('Report:Report.config.general_updates_change_log_description'),
    VALUE: 36,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  CHANGE_LOG_PAYROLL_DATA: {
    LABEL: i18n.t('Report:Report.config.payroll_data_change_log_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payroll_data_change_log_description'),
    VALUE: 37,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  GLOBAL_PAY_AND_TAXES_REPORT: {
    LABEL: i18n.t('Report:Report.config.global_pay_and_taxes_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_pay_and_taxes_description'),
    VALUE: 38,
  },
  PAY_AND_TAXES_REPORT: {
    LABEL: i18n.t('Report:Report.config.pay_and_taxes_label'),
    DESCRIPTION: i18n.t('Report:Report.config.pay_and_taxes_description'),
    VALUE: 39,
  },
  PAY_ELEMENTS_LIST: {
    LABEL: i18n.t('Report:Report.config.pay_elements_label'),
    DESCRIPTION: i18n.t('Report:Report.config.pay_elements_description'),
    VALUE: 40,
  },
  EMPLOYER_CONTRIBUTIONS_LISTING: {
    LABEL: i18n.t('Report:Report.config.employer_contributions_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employer_contributions_description'),
    VALUE: 41,
  },
  EMPLOYEE_DEDUCTIONS_LISTING: {
    LABEL: i18n.t('Report:Report.config.employee_deductions_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employee_deductions_description'),
    VALUE: 42,
  },
  EMPLOYEE_NET_DEDUCTIONS_LISTING: {
    LABEL: i18n.t('Report:Report.config.employee_net_deductions_label'),
    DESCRIPTION: i18n.t('Report:Report.config.employee_net_deductions_description'),
    VALUE: 43,
  },
  GLOBAL_PAYROLL_ELEMENT_COSTS: {
    LABEL: i18n.t('Report:Report.config.global_payroll_element_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_payroll_element_costs_description'),
    VALUE: 44,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'element' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS_FIRST: [
        { value: 'Pay elements', label: i18n.t('Report:Report.analytics.subtitle.pay_element_costs'), default: true },
        {
          value: 'Employee Deductions',
          label: i18n.t('Report:Report.analytics.subtitle.employee_deduction_costs'),
          default: true,
        },
        {
          value: 'Employee Net Deductions',
          label: i18n.t('Report:Report.analytics.subtitle.employee_net_deduction_costs'),
          default: true,
        },
        {
          value: 'Employer Contributions',
          label: i18n.t('Report:Report.analytics.subtitle.employer_contribution_cost'),
          default: true,
        },
      ],
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS_PER_ELEMENT: graphTypesEnum.SIDE_BARCHART,
    },
  },
  PAYROLL_ELEMENT_COSTS: {
    LABEL: i18n.t('Report:Report.config.total_payroll_element_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.total_payroll_element_costs_description'),
    VALUE: 45,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_ER_CONTRIBUTION_REPORT_KEY: 'Employer Contributions',
      TOTALS_PAY_ELEMENT_REPORT_KEY: 'Pay elements',
      TOTALS_EE_DEDUCTIONS_REPORT_KEY: 'Employee Deductions',
      TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY: 'Employee Net Deductions',
      TOTALS_CALCULATION: totalsCalculations.FLAT,
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'element' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS_FIRST: [
        { value: 'Pay elements', label: i18n.t('Report:Report.analytics.subtitle.pay_element_costs'), default: true },
        {
          value: 'Employee Deductions',
          label: i18n.t('Report:Report.analytics.subtitle.employee_deduction_costs'),
          default: true,
        },
        {
          value: 'Employee Net Deductions',
          label: i18n.t('Report:Report.analytics.subtitle.employee_net_deduction_costs'),
          default: true,
        },
        {
          value: 'Employer Contributions',
          label: i18n.t('Report:Report.analytics.subtitle.employer_contribution_cost'),
          default: true,
        },
      ],
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      GLOBAL_COSTS_PER_ELEMENT: graphTypesEnum.SIDE_BARCHART,
      EMPLOYER_CONTRIBUTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_PAY_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
      TOTAL_EE_NET_DEDUCTION_COSTS: graphTypesEnum.TILE_COUNT,
    },
  },
  PAYROLL_ELEMENT_COSTS_DETAIL: {
    LABEL: i18n.t('Report:Report.config.payroll_element_costs_detail_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payroll_element_costs_detail_description'),
    VALUE: 46,
    CHART_LOGIC: {
      IS_FINANCE_TILE: true,
      TOTALS_KEY: 'currency',
      TOTALS_KEY_VALUE: 'Grand Totals',
      TOTALS_PAYROLL_ELEMENT_REPORT_KEY: 'totals_numeric',
      SIDE_BARCHART_AXIS: { xAxis: 'Country', yAxis: 'totals_numeric' },
      SIDE_BARCHART_EXCLUSIONS: {
        individualRecords: {
          field: 'country',
          remapField: 'Country',
          whiteList: [],
          blackList: [],
          fieldFormatter: { exclude: 'Totals', replace: '' },
        },
      },
      SIDE_BAR_FILTER_OPTIONS: [
        { value: 'country', label: i18n.t('Global:Global.text.country'), default: true },
        { value: 'company', label: i18n.t('Global:Global.text.company') },
        { value: 'payroll', label: i18n.t('Global:Global.text.payroll') },
      ],
    },
    REPORT_CHARTS: {
      TOTAL_PAYROLL_ELEMENT_COSTS: graphTypesEnum.TILE_COUNT,
      GLOBAL_COSTS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  ROLES_AND_RIGHTS_CHANGE_LOG: {
    LABEL: i18n.t('Report:Report.config.roles_and_rights_change_log_label'),
    DESCRIPTION: i18n.t('Report:Report.config.roles_and_rights_change_log_description'),
    VALUE: 47,
    ENABLED: (check, hasAccess) => {
      return ['accessChangeLogReport'].includes(check) && hasAccess
    },
  },
  GLOBAL_ACCESS_MANAGEMENT: {
    LABEL: i18n.t('Report:Report.config.global_access_management_label'),
    DESCRIPTION: i18n.t('Report:Report.config.global_access_management_description'),
    VALUE: 48,
  },
  ACCESS_MANAGEMENT: {
    LABEL: i18n.t('Report:Report.config.access_management_label'),
    DESCRIPTION: i18n.t('Report:Report.config.access_management_description'),
    VALUE: 49,
  },
  CUSTOM_BUILD_REPORT: {
    LABEL: i18n.t('Report:Report.config.custom_build_report_label'),
    DESCRIPTION: i18n.t('Report:Report.config.custom_build_report_description'),
    VALUE: 50,
  },
  PAYROLL_WORKFLOW_AUDIT: {
    LABEL: i18n.t('Report:Report.config.payroll_workflow_audit_label'),
    DESCRIPTION: i18n.t('Report:Report.config.payroll_workflow_audit_description'),
    VALUE: 51,
    ...getAnalyticsWorkflowConfig(),
    CHART_LOGIC: {
      ...getAnalyticsWorkflowConfig().CHART_LOGIC,
      TITLE: 'GTN versions by Pay Date',
      SIDE_BARCHART_AXIS: { xAxis: 'payDate', yAxis: 'noOfGtns' },
      SIDE_MULTI_SELECTOR_BARCHART: {
        ...getAnalyticsRevisionConfig.SIDE_MULTI_SELECTOR_BARCHART,
        defaultAxis: {
          xAxis: 'payDate',
          yAxis: 'noOfReversions',
        },
        selectors: [
          {
            id: 'yAxis',
            title: 'Reversions by Pay Date and Reversion Type',
            optionsKey: 'SIDE_BAR_REVERSIONS_OPTIONS',
            className: 'u-min-width-250',
          },
        ],
      },
    },
    REPORT_CHARTS: {
      AVG_ON_TIME_COMPLETION_COMPANY: graphTypesEnum.TILE_COUNT,
      AVG_ON_TIME_COMPLETION_PAYROLL_PROVIDER: graphTypesEnum.TILE_COUNT,
      AVG_ON_TIME_COMPLETION_GTN_VERSIONS: graphTypesEnum.TILE_COUNT,
      NUMBER_OF_GTN_VERSIONS_BY_PAY_DATE: graphTypesEnum.SIDE_BARCHART,
      NUMBER_OF_REVERSIONS: graphTypesEnum.SIDE_BARCHART,
    },
  },
  ORG_UNIT_VARIANCE: {
    LABEL: i18n.t('Report:Report.config.org_unit_variance_label'),
    DESCRIPTION: i18n.t('Report:Report.config.org_unit_variance_description'),
    VALUE: 52,
  },
  WORKFORCE_VARIANCE: {
    LABEL: i18n.t('Report:Report.config.workforce_variance_label'),
    DESCRIPTION: i18n.t('Report:Report.config.workforce_variance_description'),
    VALUE: 53,
  },
  WORKFLOW_CALENDAR: {
    LABEL: i18n.t('Report:Report.config.workflow_calendar_label'),
    DESCRIPTION: i18n.t('Report:Report.config.workflow_calendar_description'),
    VALUE: 54,
  },
  ORG_UNIT_COSTS: {
    LABEL: i18n.t('Report:Report.config.org_unit_costs_label'),
    DESCRIPTION: i18n.t('Report:Report.config.org_unit_costs_description'),
    VALUE: 55,
  },
})

export const getWhiteListedReports = () => {
  const reportOptionsConfig = getReportOptionsConfig()
  return [
    reportOptionsConfig.GLOBAL_WORK_HEADCOUNT.LABEL,
    reportOptionsConfig.GLOBAL_JOINERS_HEADCOUNT.LABEL,
    reportOptionsConfig.GLOBAL_LEAVERS_HEADCOUNT.LABEL,
    reportOptionsConfig.WORKFORCE_HEADCOUNT.LABEL,
    reportOptionsConfig.JOINERS_HEADCOUNT.LABEL,
    reportOptionsConfig.LEAVERS_HEADCOUNT.LABEL,
    reportOptionsConfig.WORKFORCE_DETAIL.LABEL,
    reportOptionsConfig.LEAVERS_DETAIL.LABEL,
    reportOptionsConfig.JOINERS_DETAIL.LABEL,
    reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT.LABEL,
    reportOptionsConfig.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT.LABEL,
    reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_REPORT.LABEL,
    reportOptionsConfig.GLOBAL_TOTAL_EMPLOYER_COSTS.LABEL,
    reportOptionsConfig.TOTAL_EMPLOYER_COSTS.LABEL,
    reportOptionsConfig.GLOBAL_PAYROLL_ELEMENT_COSTS.LABEL,
    reportOptionsConfig.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS.LABEL,
    reportOptionsConfig.TOTAL_EMPLOYER_CONTRIBUTION_COSTS.LABEL,
    reportOptionsConfig.GLOBAL_PAY_ELEMENT_COSTS.LABEL,
    reportOptionsConfig.TOTAL_PAY_ELEMENT_COSTS.LABEL,
    reportOptionsConfig.GLOBAL_EMPLOYEE_DEDUCTION_COSTS.LABEL,
    reportOptionsConfig.TOTAL_EMPLOYEE_DEDUCTION_COSTS.LABEL,
    reportOptionsConfig.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS.LABEL,
    reportOptionsConfig.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS.LABEL,
    reportOptionsConfig.PAYROLL_ELEMENT_COSTS.LABEL,
    reportOptionsConfig.EMPLOYER_COSTS_DETAIL.LABEL,
    reportOptionsConfig.PAY_ELEMENTS_COSTS_DETAIL.LABEL,
    reportOptionsConfig.EMPLOYEE_DEDUCTION_COSTS_DETAIL.LABEL,
    reportOptionsConfig.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL.LABEL,
    reportOptionsConfig.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL.LABEL,
    reportOptionsConfig.PAYROLL_ELEMENT_COSTS_DETAIL.LABEL,
    reportOptionsConfig.GLOBAL_WORKFLOW_AUDIT.LABEL,
    reportOptionsConfig.WORKFLOW_AUDIT.LABEL,
    reportOptionsConfig.PAYROLL_WORKFLOW_AUDIT.LABEL,
  ]
}
