import React from 'react'
import { withTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import SwitchToggle from 'components/form/SwitchToggle'
import _ from 'lodash'
import PayslipTable from './table/PayslipTable'

const classNames = require('classnames')

class FieldsGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // Show/Hide fields
      // By default only first group of fields is visible
      expandFields: props.index === 0,
    }
  }

  /*
   * Arrow classes by toggling filters
   */
  getArrowClasses () {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-right-tiny': true,
      active: this.state.expandFields,
    })
  }

  /*
   * Toggle fields
   */
  toggleFields () {
    this.setState({
      expandFields: !this.state.expandFields,
    })
  }

  render () {
    const { t } = this.props
    const { categoryName, fields } = this.props
    return (
      <div>
        <div className='u-1/1@tablet u-2/3@desktop u-bg--white u-padding-tiny u-margin-bottom-small border-1-valhalla rounded'>
          <SectionHeading text={categoryName} className='u-margin-bottom-none'>
            <div className='o-layout__item u-1/1 u-1/2@tablet'>
              <div className='u-float--right'>
                <span
                  onClick={() => this.toggleFields()}
                  className={this.getArrowClasses()}
                  title={t(this.state.expandFields ? 'BusinessComponent.fieldsGroup.heading.collapse' : 'BusinessComponent.fieldsGroup.heading.expand')}
                />
              </div>
            </div>
          </SectionHeading>
        </div>
        {this.state.expandFields ? (
          !_.isEmpty(fields) ? (
            <PayslipTable
              data={fields.map((field) => {
                return {
                  fieldName: field.name,
                  isSensitiveData: <SwitchToggle input={{ value: field.isSensitive }} disabled />,
                  action: (
                    <button onClick={() => this.props.onClick(field.id)} className='c-btn c-btn--tiny c-btn--curious'>
                      <span className='icon icon--edit' title={t('Global:Global.text.edit')} />
                    </button>
                  ),
                }
              })}
              headings={[
                {
                  accessor: 'fieldName',
                  Header: t('BusinessComponent.payslipTable.header.fieldName'),
                  isKey: true,
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  accessor: 'isSensitiveData',
                  Header: t('BusinessComponent.payslipTable.header.isSensitive'),
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  accessor: 'action',
                  Header: t('BusinessComponent.payslipTable.header.action'),
                  actionCell: true,
                  disableFilters: true,
                  disableSortBy: true,
                },
              ]}
              wrapperClassName='u-margin-bottom u-1/1@tablet u-2/3@desktop'
            />
          ) : (
            <p className='u-margin-bottom'>{t('Global:Global.noData')}</p>
          )
        ) : null}
      </div>
    )
  }
}

FieldsGroup.propTypes = {
  categoryName: PropTypes.string,
  fields: PropTypes.array,
  index: PropTypes.number,
  t: PropTypes.func.isRequired,
}

export default withTranslation([i18nNameSpaces.BusinessComponents])(FieldsGroup)
