import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { addSign } from 'utils/numbers'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const VendorServicesTree = ({ rows, heading, onClick }) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])
  return (
    <div>
      <SectionHeading text={heading || t('Vendor.text.services_list')} />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1'>
          <PayslipTable
            data={rows.map((row) => ({
              ...row,
              monthlyDeadline: row?.deadlineMonthly !== null ? addSign(row.deadlineMonthly) : null,
              fortnightlyDeadline: row?.deadlineFortnightly !== null ? addSign(row.deadlineFortnightly) : null,
              weeklyDeadline: row?.deadlineWeekly !== null ? addSign(row.deadlineWeekly) : null,
              action: (
                <button
                  className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'
                  title={t('Global:Global.text.edit')}
                  onClick={() => onClick(row)}
                >
                  <span className='icon icon--edit' />
                </button>
              ),
            }))}
            headings={[
              { accessor: 'serviceName', Header: t('Global:Global.text.name'), disableSortBy: true },
              { accessor: 'serviceType', Header: t('Global:Global.text.type'), disableSortBy: true },
              { accessor: 'processName', Header: t('Global:Global.aside.processes'), disableSortBy: true },
              { accessor: 'stepName', Header: t('Vendor.text.header_steps'), disableSortBy: true },
              { accessor: 'taskName', Header: t('Vendor.text.header_actions'), disableSortBy: true },
              { accessor: 'taskOwner', Header: t('Global:Global.text.action_owner'), disableSortBy: true },
              {
                accessor: 'monthlyDeadline',
                Header: t('Vendor.form.deadlineMonthly'),
                formatExtraData: { isInheritColor: true },
                disableSortBy: true,
              },
              {
                accessor: 'fortnightlyDeadline',
                Header: t('Vendor.form.deadlineFortnightly'),
                formatExtraData: { isInheritColor: true },
                disableSortBy: true,
              },
              { accessor: 'weeklyDeadline', Header: t('Vendor.form.deadlineWeekly'), disableSortBy: true },
              { accessor: 'action', Header: ' ', disableSortBy: true, actionCell: true },
            ]}
            wrapperClassName='u-1/1'
            modifierClasses='o-payslip-table--wrap-header-text'
            trClassName='react-bs-table__cell--whitespace--normal'
            tableHeaderClass='react-bs-table__cell--whitespace--normal'
          />
        </div>
      </div>
    </div>
  )
}

VendorServicesTree.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      serviceName: PropTypes.string,
      serviceType: PropTypes.string,
      processName: PropTypes.string,
      processKeyEvent: PropTypes.string,
      stepName: PropTypes.string,
      taskName: PropTypes.string,
      taskOwner: PropTypes.string,
      finishTaskLink: PropTypes.string,
      taskDeadline: PropTypes.number,
    })
  ),
  heading: PropTypes.string,
  onClick: PropTypes.func,
}

export default VendorServicesTree
