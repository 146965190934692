import i18n from 'i18next'

/*
 * Provides an object of options for conditional rendering of individual buttons for the Options component
 *
 * example:
 * How to render only the "Pay date" button
 *
 * import { payDateButtonItem } from 'utils/buttonOptions'
 * <Options availableOptions={payDateButtonItem} />
 *
 * if no availableOptions is passed, the component defaults to all buttons
 */

export const PayrollTabModalOptionButtons = {
  payDateString: 'BusinessComponents:BusinessComponent.payroll_btn_options.pay_date',
  actionOwnersString: 'BusinessComponents:BusinessComponent.payroll_btn_options.action_owners',
  addMorePeopleString: 'BusinessComponents:BusinessComponent.payroll_btn_options.add_more_people',
  removePeopleString: 'BusinessComponents:BusinessComponent.payroll_btn_options.remove_people',
  addVariableElementsString: 'BusinessComponents:BusinessComponent.payroll_btn_options.add_variable_elements',
  removeVariableElementsString: 'BusinessComponents:BusinessComponent.payroll_btn_options.remove_variable_elements',
  addPayAndTaxesElementsString: 'BusinessComponents:BusinessComponent.payroll_btn_options.add_pay_and_taxes_elements',

  get allButtonItems () {
    return {
      payDateButton: i18n.t(this.payDateString),
      actionOwnersButton: i18n.t(this.actionOwnersString),
      addMorePeopleButton: i18n.t(this.addMorePeopleString),
      removePeopleButton: i18n.t(this.removePeopleString),
      addVariableElementsButton: i18n.t(this.addVariableElementsString),
      removeVariableElementsButton: i18n.t(this.removeVariableElementsString),
      addPayAndTaxesElementsButton: i18n.t(this.addPayAndTaxesElementsString),
    }
  },
  get payDateButtonItem () {
    return { payDateButton: i18n.t(this.payDateString) }
  },
  get actionOwnersButtonItem () {
    return { actionOwnersButton: i18n.t(this.actionOwnersString) }
  },
  get addMorePeopleButtonItem () {
    return { addMorePeopleButton: i18n.t(this.addMorePeopleString) }
  },
  get removePeopleButtonItem () {
    return { removePeopleButton: i18n.t(this.removePeopleString) }
  },
  get addVariableElementsButtonItem () {
    return { addVariableElementsButton: i18n.t(this.addVariableElementsString) }
  },
  get removeVariableElementsButton () {
    return { removeVariableElementsButton: i18n.t(this.removeVariableElementsString) }
  },
  get addPayAndTaxesElementsButton () {
    return { addPayAndTaxesElementsButton: i18n.t(this.addPayAndTaxesElementsString) }
  },
}
