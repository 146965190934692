import React from 'react'
import SectionHeading from 'components/SectionHeading'
import { privacyPolicy } from 'routes/PrivacySecurity/routes/Privacy/components/articleText'

const headingClassName = 'u-margin-top u-margin-bottom-none'
const paragraphClassName = 'u-margin-bottom-none'
const listClassName = 'u-margin-top-small u-margin-bottom-small'
const emailClassName = 'u-text--curious u-cursor--pointer'

const RouteView = () => (
  <div className='u-margin-top-neg u-text--normal'>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionA.heading} />
    <p className={paragraphClassName}> {privacyPolicy.sectionA.p1}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionA.p2}</p>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionB.heading} />
    <p className={paragraphClassName}>
      {privacyPolicy.sectionB.p1} <u>{privacyPolicy.sectionB.p1U}</u>
    </p>
    <ul className={listClassName}>
      <li>{privacyPolicy.sectionB.listA.l1}</li>
      <li>{privacyPolicy.sectionB.listA.l2}</li>
      <li>{privacyPolicy.sectionB.listA.l3}</li>
    </ul>
    <p className={paragraphClassName}>
      <u>{privacyPolicy.sectionB.p2U}</u>
      {privacyPolicy.sectionB.p2}
    </p>
    <p className={paragraphClassName}> {privacyPolicy.sectionB.p3}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionB.p4}</p>
    <br />
    <p className={paragraphClassName}>
      {privacyPolicy.sectionB.p5} <u>{privacyPolicy.sectionB.p5U}</u>
    </p>
    <ul className={listClassName}>
      <li>{privacyPolicy.sectionB.listB.l1}</li>
      <li>{privacyPolicy.sectionB.listB.l2}</li>
    </ul>
    <p className={paragraphClassName}>
      <u>{privacyPolicy.sectionB.p6U}</u>
      {privacyPolicy.sectionB.p6}
    </p>
    <p className={paragraphClassName}> {privacyPolicy.sectionB.p7}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionB.p8}</p>
    <br />
    <p className={paragraphClassName}>
      {privacyPolicy.sectionB.p9} <u>{privacyPolicy.sectionB.p9U}</u>
    </p>
    <ul className={listClassName}>
      <li>{privacyPolicy.sectionB.listC.l1}</li>
      <li>{privacyPolicy.sectionB.listC.l2}</li>
    </ul>
    <p className={paragraphClassName}>
      <u>{privacyPolicy.sectionB.p10U}</u>
      {privacyPolicy.sectionB.p10}
    </p>
    <p className={paragraphClassName}> {privacyPolicy.sectionB.p11}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionB.p12}</p>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionC.heading} />
    <p className={paragraphClassName}> {privacyPolicy.sectionC.p1}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionC.p2}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionC.p3}</p>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionD.heading} />
    <p className={paragraphClassName}> {privacyPolicy.sectionD.p1}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionD.p2}</p>
    <ul className={listClassName}>
      <li>{privacyPolicy.sectionD.list.l1}</li>
      <li>{privacyPolicy.sectionD.list.l2}</li>
      <li>{privacyPolicy.sectionD.list.l3}</li>
      <li>{privacyPolicy.sectionD.list.l4}</li>
      <li>{privacyPolicy.sectionD.list.l5}</li>
    </ul>
    <p className={paragraphClassName}> {privacyPolicy.sectionD.p3}</p>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionE.heading} />
    <p className={paragraphClassName}> {privacyPolicy.sectionE.p1}</p>
    <ul className={listClassName}>
      <li>{privacyPolicy.sectionE.list.l1}</li>
      <li>{privacyPolicy.sectionE.list.l2}</li>
      <li>{privacyPolicy.sectionE.list.l3}</li>
      <li>{privacyPolicy.sectionE.list.l4}</li>
      <li>{privacyPolicy.sectionE.list.l5}</li>
      <li>{privacyPolicy.sectionE.list.l6}</li>
      <li>{privacyPolicy.sectionE.list.l7}</li>
    </ul>
    <p className={paragraphClassName}> {privacyPolicy.sectionE.p2}</p>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionF.heading} />
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p1}</p>
    <p className={paragraphClassName}>
      {privacyPolicy.sectionF.p2}
      <a className={emailClassName} href={`mailto:${privacyPolicy.sectionF.p2Email}`}>
        {privacyPolicy.sectionF.p2Email}
      </a>
    </p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p3}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p4}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p5}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p6}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p7}</p>
    <br />
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p8}</p>
    <p className={paragraphClassName}>
      {privacyPolicy.sectionF.p9}
      <a className={emailClassName} href={`mailto:${privacyPolicy.sectionF.p9Email}`}>
        {privacyPolicy.sectionF.p9Email}
      </a>
    </p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p10}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p11}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p12}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p13}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p14}</p>
    <p className={paragraphClassName}> {privacyPolicy.sectionF.p15}</p>
    <SectionHeading className={headingClassName} text={privacyPolicy.sectionG.heading} />
    <p className={paragraphClassName}> {privacyPolicy.sectionG.p1}</p>
  </div>
)

export default RouteView
