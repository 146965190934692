import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const EmployeeHeader = ({ employee: { firstname, surname, position, companyRef, id }, ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  return (
    <div className='o-media o-grid o-grid--middle employee-header'>
      <div className='o-media__body employee-header-details line-height--20'>
        <span className='u-weight--bold u-text--large u-margin-right-small'>
          {t('BusinessComponent.employeeHeader.full_name', {
            firstname,
            surname,
          })}
        </span>
        <span className='u-text--small u-weight--regular'>{t('BusinessComponent.employeeHeader.id_number', { id })}</span>
        <span className='o-block o-block--left u-text--normal u-weight--regular'>
          {t('BusinessComponent.employeeHeader.position_and_company', {
            position: position || '',
            companyCode: companyRef.code || '',
            companyName: companyRef.name || '',
            countryName: companyRef.countryRef.name || '',
          })}
        </span>
      </div>
    </div>
  )
}

EmployeeHeader.propTypes = {
  employee: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    position: PropTypes.string,
    id: PropTypes.number.isRequired,
    companyRef: PropTypes.object,
  }),
}

export default EmployeeHeader
