import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import SLAsContainer from '../containers/SLAsContainer'
import TimelineFormCreateContainer from '../containers/TimelineFormCreateContainer'
import TimelineFormEditContainer from '../containers/TimelineFormEditContainer'
import Modal from 'components/Modal'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const RouteView = (props) => {
  const { dispatch } = props
  const [vendorProps, setVendorProps] = useState({})
  const modalEditServiceRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Vendor])

  useEffect(() => {
    dispatch(getWhiteLabeledBrandStyles())
  }, [dispatch])

  const hideModal = () => {
    if (modalEditServiceRef.current) {
      modalEditServiceRef.current.hideModal()
    }
  }

  const handleEditCreateClick = (newVendorProps) => {
    setVendorProps(newVendorProps)
    if (modalEditServiceRef.current) {
      modalEditServiceRef.current.showModal()
    }
  }

  const isEdit = vendorProps.vendorCountryTaskId !== undefined
  const isCreate = !isEdit

  return (
    <div>
      <SLAsContainer {...props} onClick={handleEditCreateClick} />
      <Modal ref={modalEditServiceRef} className='c-modal c-modal--half' modalHeading={t('Vendor.text.update_timeline')}>
        {isEdit ? <TimelineFormEditContainer onSubmit={hideModal} {...props} {...vendorProps} /> : null}
        {isCreate ? <TimelineFormCreateContainer onSubmit={hideModal} {...props} {...vendorProps} /> : null}
      </Modal>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

RouteView.propTypes = {
  dispatch: PropTypes.func,
}

const Container = connect(null, mapDispatchToProps)(RouteView)
export default Container
