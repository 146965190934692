import React from 'react'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PhoneNumberStepTwoView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Setting])
  const { senderService, pristine, submitting } = props
  const service = senderService === 'email' ? 'email' : 'mobile phone'

  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
        <Form {...props}>
          <div className='o-form-group'>
            <Field
              name='authCode'
              label={t('Setting.user.step_two_provide_auth_code', { service })}
              component={InputField}
              type='text'
              className='c-input c-input--mobile'
              autoFocus
              labelClassName='c-label c-label--mobile'
            />
          </div>
          <div className='o-form-group u-margin-top'>
            <button
              type='submit'
              disabled={pristine || submitting}
              className='c-btn c-btn--submit c-btn--small c-btn--curious c-btn--mobile u-padding-left u-padding-right'
              tabIndex='-1'
            >
              {t('Global:Global.button.send')}
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}

PhoneNumberStepTwoView.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  senderService: PropTypes.string,
}

export default PhoneNumberStepTwoView
