import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import InnerWrapperTabs from 'layouts/InnerWrapperTabs'

import { getUserSpecialRights } from 'redux/selectors/employees'
import { i18nNameSpaces } from 'i18n/types'

const InnerWrapper = (props) => {
  const specialRights = useSelector(getUserSpecialRights)
  const hasAccessToReportDesigner = specialRights?.reportDesigner
  const { t } = useTranslation(i18nNameSpaces.Report)
  const tabs = [
    { name: t('Report.tabs.report_library'), to: `/reporting/customize-report` },
    hasAccessToReportDesigner && { name: t('Report.tabs.report_designer'), to: `/reporting/report-designer` },
    { name: t('Report.tabs.recent_reports'), to: `/reporting/recent-reports` },
    { name: t('Report.tabs.saved_reports'), to: `/reporting/saved-reports` },
  ].filter(Boolean)

  return <InnerWrapperTabs tabs={tabs} {...props} />
}

export default InnerWrapper
