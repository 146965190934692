import { i18nNameSpaces } from 'i18n/types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import InnerWrapper from 'layouts/InnerWrapper'
import ClientsContainer from '../containers/ClientsContainer'
import ReferralsEditContainer from '../containers/ReferralsEditContainer'
import ReferralsCreateContainer from '../containers/ReferralsCreateContainer'

const RouteView = (props) => {
  const [referralId, setReferralId] = useState(null)
  const editModalRef = useRef(null)
  const createModalRef = useRef(null)
  const { t } = useTranslation([i18nNameSpaces.Client])

  return (
    <InnerWrapper>
      <ClientsContainer
        {...props}
        onEdit={(id) => {
          setReferralId(id)
          editModalRef.current.showModal()
        }}
        onCreate={(id) => {
          setReferralId(id)
          createModalRef.current.showModal()
        }}
      />
      <Modal ref={editModalRef} className='c-modal' modalHeading={t('Client.route_view_modal.editReferral')}>
        <ReferralsEditContainer referralId={referralId} onEdit={() => editModalRef.current.hideModal()} />
      </Modal>
      <Modal ref={createModalRef} className='c-modal' modalHeading={t('Client.route_view_modal.addReferral')}>
        <ReferralsCreateContainer referralId={referralId} onCreate={() => createModalRef.current.hideModal()} />
      </Modal>
    </InnerWrapper>
  )
}

export default RouteView
