import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import FlagValue from 'components/form/select/FlagValue'
import FlagOption from 'components/form/select/FlagOption'
import InputField from 'components/form/InputField'
import CheckboxField from 'components/form/CheckboxField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const withoutColonSymbol = ''
const VendorFormFields = ({ countries, statusOptions }) => {
  const { t } = useTranslation([i18nNameSpaces.Vendor])
  return (
    <div className='o-layout o-grid--strech'>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='name'
            label={t('Vendor.company_details_labels.vendor_name', {
              colonSymbol: withoutColonSymbol,
            })}
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='name'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='status'
            label={t('Vendor.company_details_labels.status', {
              colonSymbol: withoutColonSymbol,
            })}
            component={CustomSelectField}
            optionComponent={FlagOption}
            valueComponent={FlagValue}
            options={statusOptions}
            placeholder={t('Global:Global.select.placeholder')}
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='status'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='registrationNumber'
            label={t('Vendor.company_details_labels.company_reg_number', {
              colonSymbol: withoutColonSymbol,
            })}
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='registration-number'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='address'
            label={t('Vendor.company_details_labels.address', {
              colonSymbol: withoutColonSymbol,
            })}
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='address'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='registrationAddress'
            label={t('Vendor.company_details_labels.registered_address', {
              colonSymbol: withoutColonSymbol,
            })}
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='registered-address'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='website'
            label={t('Vendor.company_details_labels.website', {
              colonSymbol: withoutColonSymbol,
            })}
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='website'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='hqCountry'
            label={t('Vendor.company_details_labels.hq_country', {
              colonSymbol: withoutColonSymbol,
            })}
            component={CustomSelectField}
            optionComponent={FlagOption}
            valueComponent={FlagValue}
            options={countries.map((country) => ({
              value: country.id,
              label: country.name,
              abbreviature: country.abbreviature,
            }))}
            placeholder={t('Global:Global.select.placeholder')}
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='hq-country'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='payrollSoftware'
            label={t('Vendor.company_details_labels.payroll_software', {
              colonSymbol: withoutColonSymbol,
            })}
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='software'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='senderService'
            label={t('Vendor.company_details_labels.sender_service', {
              colonSymbol: withoutColonSymbol,
            })}
            component={CustomSelectField}
            options={[
              { label: 'sms', value: 'sms' },
              { label: 'email', value: 'email' },
            ]}
            placeholder={t('Global:Global.select.placeholder')}
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='sender-service'
          />
        </div>
      </div>
      <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            label={t('Vendor.company_details_labels.holidays_calendar', {
              colonSymbol: withoutColonSymbol,
            })}
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            name='holidaysCalendar'
            component={InputField}
            formGroupClassName='u-margin-none'
            data-testid='calendar'
          />
        </div>
      </div>
      <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='data-protection'>
          <Field
            name='dataProtection'
            label={t('Vendor.company_details_labels.data_protection', {
              colonSymbol: withoutColonSymbol,
            })}
            type='checkbox'
            labelClassName='u-hidden c-label u-text--small u-text--curious'
            component={CheckboxField}
            className='control__indicator__input'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='professional-indemnity'>
          <Field
            name='professionalIndemnity'
            label={t('Vendor.company_details_labels.professional_indemnity', {
              colonSymbol: withoutColonSymbol,
            })}
            type='checkbox'
            labelClassName='u-hidden c-label u-text--small u-text--curious'
            component={CheckboxField}
            className='control__indicator__input'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
    </div>
  )
}

VendorFormFields.propTypes = {
  countries: PropTypes.array,
  statusOptions: PropTypes.array,
}

export default VendorFormFields
