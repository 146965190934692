import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

export const Steps = ({ steps, isFetching }) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])

  if (isFetching) return <div>{t('Global:Global.text.loading')}</div>
  return (
    <div>
      <SectionHeading text={t('BusinessComponent.placeholder.step_list')} className='u-margin-top-large' />

      {isFetching === true ? t('Global:Global.text.loading') : null}

      <PayslipTable
        wrapperClassName='u-1/1@tablet u-1/2@desktop'
        headings={[
          {
            accessor: 'name',
            Header: t('Global:Global.text.name'),
            disableSortBy: true,
          },
          {
            accessor: 'processRef',
            Header: t('Global:Global.text.process'),
            disableSortBy: true,
          },
        ]}
        data={steps.map((step) => ({
          name: step.name,
          processRef: step.processRef.name,
        }))}
      />
    </div>
  )
}
Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
}
export default Steps
