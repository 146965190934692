import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import TermCategoriesAndSub from 'components/TermCategoriesAndSub'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TermsFormFields = ({ ...props }) => {
  const { t } = useTranslation([i18nNameSpaces.Term])
  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <Form {...props} showSubmitBtn colorClassBtn={'c-btn--curious'} btnText={t('Global:Global.button.submit')}>
          <Field name='name' label={t('Global:Global.text.name')} labelClassName='c-label' component={InputField} type='text' />
          <Field name='definition' label={t('Term.text.definition')} labelClassName='c-label' component={InputField} type='text' />
          {props.subCategories ? <TermCategoriesAndSub {...props} /> : null}
        </Form>
      </div>
    </div>
  )
}

export default TermsFormFields
