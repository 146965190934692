import React from 'react'
import { Field } from 'redux-form'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import SubmitButtons from './SubmitButtons'
import CheckboxOption from 'components/form/select/CheckboxOption'
import CustomSelectField from 'components/form/select/CustomSelectField'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const FiltersGlobalPayAndTaxesReport = (props) => {
  const { selected, countries, termsubcategories, terms, companies, isFieldDisabled, disabledReason, mostRecentRunReport = {}, hideNavigationButtons } = props

  const { t } = useTranslation([i18nNameSpaces.Report])
  const requiredSymbol = '*'

  // In the first case, it's a multi array, while in the second one it's a single Numeric value.
  // We can't catch empty payroll values with only one Lodash validation function,
  // because in the case of a single value and if we use `isEmpty` validation function only,
  // then any Numeric value (1,2 etc.) is threat as empty value.

  const isCountrySelected = !isEmpty(selected.country)
  const isCompanySelected = !isEmpty(selected.company)
  const isCombinedSubcategorySelected = !isEmpty(selected.combinedSubcategory)
  const isTermSubcategorySelected = !isEmpty(selected.termSubcategory)
  const shouldShowMostRecentRunReportItem = !props.reportItem

  /*
   * Every next filter is shown if the last required field has value
   */
  return (
    <div className='o-block--inline'>
      {isCombinedSubcategorySelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='country'
              label={t('Report.field.country_required', { requiredSymbol })}
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={CheckboxOption}
              placeholder={t('Global:Global.select.placeholder')}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={countries
                .map((country) => ({
                  value: country.id,
                  label: country.name,
                  abbreviature: country.abbreviature,
                }))
                .sort(sortByLabel)}
              enableRemovedOptionFix
              data-testid='country-wrapper'
            />
          </div>
        </div>
      )}
      {isCountrySelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='company'
              label={t('Report.field.company_required', { requiredSymbol })}
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={CheckboxOption}
              placeholder={t('Global:Global.select.placeholder')}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={companies
                .map((company) => ({
                  value: company.id,
                  label: company.name,
                }))
                .sort(sortByLabel)}
              enableRemovedOptionFix
              data-testid='company-wrapper'
            />
          </div>
        </div>
      )}
      {isCompanySelected && (
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='termSubcategory'
              label={t('Report.field.elements_subcategory')}
              component={CustomSelectField}
              optionComponent={CheckboxOption}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={termsubcategories.map((termcat) => ({
                value: termcat.id,
                label: termcat.name,
              }))}
              enableRemovedOptionFix
              data-testid='termSubcategory-wrapper'
            />
          </div>
        </div>
      )}
      {isTermSubcategorySelected && (
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='term'
              label={t('Report.field.elements')}
              component={CustomSelectField}
              optionComponent={CheckboxOption}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder={t('Global:Global.select.placeholder')}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={terms.map((termcat) => ({
                value: termcat.id,
                label: termcat.name,
              }))}
              enableRemovedOptionFix
              data-testid='term-wrapper'
            />
          </div>
        </div>
      )}

      {!hideNavigationButtons && <SubmitButtons pristineCheck={isCompanySelected} onBackClick={props.navigateToParentOnClick} {...props} />}
      {shouldShowMostRecentRunReportItem && (
        <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem
            mostRecentRunReport={mostRecentRunReport}
            onOpenReport={props.handleReportModalOpen}
            onDelete={props.deleteReport}
            {...props}
          />
        </div>
      )}
    </div>
  )
}

FiltersGlobalPayAndTaxesReport.propTypes = {
  resetFormFields: PropTypes.func,
  setCurrencyByPayrollInstance: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  payrollInstances: PropTypes.array,
  currencies: PropTypes.array,
  fxRates: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showRunReportBtn: PropTypes.bool,
  handleModalOpen: PropTypes.func,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  termSubcategories: PropTypes.array,
  terms: PropTypes.array,
  hideNavigationButtons: PropTypes.bool,
}

export default FiltersGlobalPayAndTaxesReport
