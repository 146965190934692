import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import LeavesTable from './LeavesTable'
import MobileDetailsView from 'components/MobileDetailsView'
import Modal from 'components/Modal'
import LeaveFormCreateContainer from '../containers/LeaveFormCreateContainer'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const LeavesView = (props) => {
  const { attendanceRows, isEmployeeOnly, match } = props
  const { t } = useTranslation([i18nNameSpaces.Employee])
  const modalCreateLeaveRef = useRef()

  /**
   * Handle modal opening
   * @param modalRef
   */
  const handleModalOpen = (modalRef) => {
    modalRef.current.showModal()
  }

  return (
    <div>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <Modal
          ref={modalCreateLeaveRef}
          className='c-modal c-modal--half'
          modalHeading={t('Employee.modal.add_personal_leave_heading')}
          data-testid='leave-create-modal'
        >
          <LeaveFormCreateContainer onCreate={() => modalCreateLeaveRef.current.hideModal()} match={match} />
        </Modal>
        <SectionHeading text={t('Employee.section.personal_leave')}>
          <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
            <div className='o-layout__item u-1/1 u-1/2@tablet'>
              <div className='u-float--right'>
                <div
                  onClick={() => handleModalOpen(modalCreateLeaveRef)}
                  className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
                  data-testid='leave-create'
                >
                  <span className='icon icon--plus' />
                  {t('Employee.button.add_leave')}
                </div>
              </div>
            </div>
          </Authorization>
        </SectionHeading>
        <LeavesTable {...props} />
      </div>
      {isEmployeeOnly && (
        <div className='u-hide-on-desktop'>
          <SectionHeading text={t('Employee.section.time_attendance')} />
          <AccordionContainer title={t('Employee.section.personal_leave')} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={attendanceRows} />
          </AccordionContainer>
        </div>
      )}
    </div>
  )
}

LeavesView.propTypes = {
  attendanceRows: PropTypes.array.isRequired,
  isEmployeeOnly: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
}
export default LeavesView
