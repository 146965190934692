import React from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'

export default ({ pristine, submitting, children, colorClass = 'c-btn--curious', className = '', btnFullWidth = false, ...rest }) => {
  className = `c-btn c-btn--small
  ${colorClass} u-padding-left u-padding-right u-margin-top-small
  ${submitting ? 'c-btn--submitting' : ''} ${className}
  ${btnFullWidth ? 'c-btn--full' : ''}`
  const { t } = useTranslation()
  return (
    <Button type='submit' disabled={pristine || submitting} className={className} {...rest} data-testid='submit-button'>
      {children || t('Global.button.save')}
    </Button>
  )
}
