import React from 'react'
import SectionHeading from 'components/SectionHeading'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'
import PropTypes from 'prop-types'

const ServiceCreate = ({ handleSubmit, pristine, submitting, types }) => {
  const { t } = useTranslation([i18nNameSpaces.Service])

  return (
    <div>
      <SectionHeading text={t('Service.heading.create_services')} />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <form onSubmit={handleSubmit}>
            <div className='o-form-group'>
              <Field name='name' label={t('Global:Global.text.name')} component={InputField} type='text' className='c-input' labelClassName='c-label' />
            </div>
            <div className='o-form-group'>
              <Field
                name='type'
                label={t('Global:Global.text.type')}
                component={CustomSelectField}
                type='text'
                labelClassName='c-label'
                placeholder={t('Global:Global.text.select_type')}
                clearable={false}
                options={types.map((type) => ({
                  value: type,
                  label: type,
                }))}
              />
            </div>
            <div className='u-padding-top-small text-center'>
              <button type='submit' disabled={pristine || submitting} className='c-btn c-btn--small c-btn--curious u-margin-right-small'>
                {t('Global:Global.button.submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

ServiceCreate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default reduxForm({
  form: 'service', // a unique identifier for this form
})(ServiceCreate)
