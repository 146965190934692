import React from 'react'
import PropTypes from 'prop-types'
import { setStatusForNotifications } from 'utils/payrollInstanceStatuses'
import { fetchPayrollInstances } from 'redux/actions/payrollInstances'
import { createFilter } from 'utils/redux/filter'
import { selectTenant } from 'redux/actions/tenants'
import { purify } from 'utils/router'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const PayrunLink = ({
  resetTenant,
  tenant,
  dispatch,
  isVendor,
  selectTenant: selectVendorTenant,
  isCotUser,
  history,
  payrollInstanceId,
  companyId,
  vendorId,
  isTimelineNavigation,
  isActionTimelineNavigation,
  isDocumentGTNReportSharedNotificationER,
  isDocumentGTNReportSharedNotificationCOT,
}) => {
  const { t } = useTranslation([i18nNameSpaces.BusinessComponents])
  const { id: tenantId } = tenant
  let pathToTenantPayrollInstanceNotes
  let pathToVendorPayrollInstanceNotes
  let pathToTenantPayrollInstanceNotesForCOTUsers
  let tenantPayrunChangeDetails
  let vendorPayrunChangeDetails
  let pathToTenantPayrunGTNFile
  let pathToTenantPayrunChangeDetailsCOTUsers
  const payrollInstanceFilter = createFilter({ id: payrollInstanceId })
  const handleClick = async () => {
    resetTenant()
    selectVendorTenant({
      url: tenant.url,
      schema: tenant.schema,
      shouldUse: true,
    })
    let status

    await dispatch(
      isVendor || isCotUser
        ? fetchPayrollInstances({ filter: payrollInstanceFilter, forceVendorTenantAPI: true })
        : fetchPayrollInstances({ filter: payrollInstanceFilter })
    ).then((res) => {
      if (isCotUser) dispatch(selectTenant({ url: tenant.url, type: tenant.type, id: tenantId }))

      try {
        let payrollInstanceStatus = res.payload.response.data[0].status
        status = setStatusForNotifications(payrollInstanceStatus)

        let baseVendor = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}`
        pathToTenantPayrollInstanceNotes = `/payruns/${status}/${payrollInstanceId}/notes`
        pathToTenantPayrollInstanceNotesForCOTUsers = `${pathToTenantPayrollInstanceNotes}?redirectHome=true`

        pathToVendorPayrollInstanceNotes = `${baseVendor}/payrun/${status}/${payrollInstanceId}/notes`

        tenantPayrunChangeDetails = `/payruns/${status}/${payrollInstanceId}/change-details`
        pathToTenantPayrunChangeDetailsCOTUsers = `${tenantPayrunChangeDetails}?redirectHome=true`
        vendorPayrunChangeDetails = `${baseVendor}/payroll/${payrollInstanceId}/timelines`

        pathToTenantPayrunGTNFile = `/payruns/${status}/${payrollInstanceId}/instance?redirectHome=true`

        let path = isVendor ? pathToVendorPayrollInstanceNotes : isCotUser ? pathToTenantPayrollInstanceNotesForCOTUsers : pathToTenantPayrollInstanceNotes

        if (isTimelineNavigation || isActionTimelineNavigation) {
          path = isVendor ? vendorPayrunChangeDetails : isCotUser ? pathToTenantPayrunChangeDetailsCOTUsers : tenantPayrunChangeDetails
        }

        if (isDocumentGTNReportSharedNotificationCOT || isDocumentGTNReportSharedNotificationER) {
          path = pathToTenantPayrunGTNFile
        }

        if (isCotUser) {
          purify(history).then(() => {
            history.push('/home')
            history.push(path)
          })
        } else {
          history.push(path)
        }
      } catch (e) {
        const endPath = '/payruns/active'
        let baseVendor = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}`
        let pathToTenantPayrollInstances = endPath
        let pathToVendorPayrollInstances = `${baseVendor}/${endPath}`

        let path = isVendor ? pathToVendorPayrollInstances : pathToTenantPayrollInstances

        return history.push(path)
      }
    })
  }
  const textForDocumentNotification =
    isDocumentGTNReportSharedNotificationCOT || isDocumentGTNReportSharedNotificationER
      ? t('BusinessComponent.text.review')
      : t('BusinessComponent.text.view_note')
  return (
    <span className='u-text--curious u-cursor--pointer' onClick={handleClick} data-testid='notification-link'>
      {isTimelineNavigation || isActionTimelineNavigation ? t('Global:Global.text.here') : textForDocumentNotification}
    </span>
  )
}
PayrunLink.proptypes = {
  resetTenant: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    schema: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isVendor: PropTypes.bool,
  selectTenant: PropTypes.func.isRequired,
  isCotUser: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  payrollInstanceId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  vendorId: PropTypes.string.isRequired,
  isTimelineNavigation: PropTypes.bool,
  isActionTimelineNavigation: PropTypes.bool,
  isDocumentGTNReportSharedNotificationER: PropTypes.bool,
  isDocumentGTNReportSharedNotificationCOT: PropTypes.bool,
}

export default PayrunLink
