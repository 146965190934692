import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const CompanyServiceVendorList = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Company])

  /**
   * Render the table rows
   *
   * @return array
   */
  const renderRows = (company) => {
    return company.companyServices.map((companyService) => ({
      name: companyService.service.name,
      serviceType: _.capitalize(companyService.service.type),
      vendors: companyService.vendor ? (
        companyService.vendor.name
      ) : (
        <button
          className='c-btn c-btn--tiny u-text--small c-btn--curious u-padding-left-small u-padding-right-small'
          onClick={() =>
            props.onOpenAddVendor({
              companyCountryServiceId: companyService.id,
              countryServiceId: companyService.countryService,
            })
          }
        >
          {t('Global:Global.text.add')}
        </button>
      ),
      serviceElements: '',
    }))
  }
  if (props.isFetching) return <div>{t('Global:Global.text.loading')}</div>

  return (
    <div className='o-layout'>
      {props.companies.map((company) => (
        <div className='o-layout__item u-1/1@tablet u-2/3@desktop u-margin-top u-margin-bottom' key={company.id}>
          <SectionHeading text={company.name}>
            <div className='o-layout__item u-1/1 u-1/2@tablet u-text--right'>
              <button
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
                onClick={() => props.onOpenAssign({ companyId: company.id })}
              >
                {t('Company.button.add_service')}
              </button>
            </div>
          </SectionHeading>
          <PayslipTable
            proportionsClassName='u-1/1'
            tableClassName='o-table--tiny o-table--striped'
            headings={[
              {
                Header: t('Global:Global.text.services'),
                accessor: 'name',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
              {
                Header: t('Company.text.service_type'),
                accessor: 'serviceType',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
              {
                Header: t('Global:Global.text.vendors'),
                accessor: 'vendors',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
              {
                Header: t('Global:Global.text.service_elements'),
                accessor: 'serviceElements',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
            ]}
            data={renderRows(company)}
          />
        </div>
      ))}
    </div>
  )
}

CompanyServiceVendorList.propTypes = {
  isFetching: PropTypes.bool,
  onOpenAddVendor: PropTypes.func,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      country: PropTypes.shape({
        name: PropTypes.string,
      }),
      companyServices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          countryService: PropTypes.number,
          service: PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
          }),
          vendor: PropTypes.object,
        })
      ),
    })
  ),
  onOpenAssign: PropTypes.func,
}

export default CompanyServiceVendorList
