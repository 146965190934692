import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'components/Moment'
import { Authorization } from 'containers/Authorization'
import { getReportOptionsConfig, getWhiteListedReports } from 'configs/reports'
import { batch, useDispatch, useSelector } from 'react-redux'
import { isCot, isVendor } from 'redux/selectors/auth'
import { getCompletedReportCreateJobs } from 'redux/selectors/pollingJobs'
import { flagAsRead } from 'redux/actions/pollingJob'
import { toast } from 'react-toastify'
import { reportsWithNoPayrollFilter } from '../utils/reports'
import { RetryBaseIcon } from 'components/icons/RetryIcons'
import { DownloadReportButton } from 'routes/Reporting/components/DownloadReportButton'
import { getLegacyReportTypes } from 'redux/config/reports'
import { getUserSpecialRights } from 'redux/selectors/employees'
import { ReportViewButton } from 'routes/Reporting/components/ReportViewButton'
import { useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const classNames = require('classnames')

const RecentReportItem = (props) => {
  const types = getLegacyReportTypes()

  const [showFiltersList, setShowFiltersList] = useState(false)
  const dispatch = useDispatch()
  const reportOptionsConfig = getReportOptionsConfig()

  const { t } = useTranslation([i18nNameSpaces.Report])

  const specialRights = useSelector(getUserSpecialRights)

  const { report, onDelete, onSaveReport } = props
  const { cat, subCat } = report

  const isReportDesigner = report?.feCategoryId === reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE
  const canViewTheReportDesigner = specialRights?.reportDesigner || !isReportDesigner

  const inProgressPollingJobs = useSelector((state) => getCompletedReportCreateJobs(state))
  const isVendorUser = useSelector((state) => isVendor(state))
  const shouldUseDefaultApi = useSelector((state) => isCot(state))

  const job = inProgressPollingJobs ? inProgressPollingJobs[0] : null

  const getBaseApiUsage = () => {
    let baseApiUsage = { forceDefaultAPI: shouldUseDefaultApi }
    if (isVendorUser) {
      baseApiUsage = { forceDefaultAPI: false }
    }
    return baseApiUsage
  }

  const onDeletePress = (id) => {
    if (job) {
      batch(() => {
        dispatch(flagAsRead(job.id, job, getBaseApiUsage()))
        toast.dismiss(job.id)
      })
    }
    onDelete(id)
  }

  /*
   * Toggle filters on every recent report item
   */
  const toggleFilters = () => {
    setShowFiltersList(!showFiltersList)
  }

  /*
   * Arrow classes by toggling filters
   */
  const getArrowClasses = () => {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-top-tiny': true,
      active: showFiltersList,
    })
  }

  const handleRegenerateReport = () => {
    let pathToGo = `/reporting/customize-report`
    const isCustomReport = report?.feCategoryId === reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE

    if (isCustomReport) {
      pathToGo = `/reporting/report-designer`
    }

    const historyStateData = {
      feCategoryId: report?.feCategoryId,
      report,
    }

    props.history.push(pathToGo, historyStateData)
  }

  const showPayrollFilter = !reportsWithNoPayrollFilter.includes(report.type)
  const hasGraphs = getWhiteListedReports().includes(report.name)

  if (!canViewTheReportDesigner) {
    return null
  }

  return (
    <div key={report.id} className='o-layout__item u-1/1'>
      <div className='c-panel u-bg--white u-padding-small rounded border-1-valhalla hover--shadow-md u-margin-bottom'>
        <div className='o-layout o-grid o-grid--middle'>
          <div className='o-layout__item u-padding-left-none u-1/1 u-2/3@tablet u-4/6@desktop'>
            <div>
              {report.categoryName ? (
                <span className='u-text--xtra-huge u-text--minsk'>
                  <span className='u-weight--bold'>{report.categoryName}</span>
                  <span className='u-text--xtra-huge u-text--minsk'> / {report.name}</span>
                </span>
              ) : (
                <span className='u-text--xtra-huge u-text--minsk'>{report.name}</span>
              )}
            </div>
            <div className='o-block o-block--left u-test--small u-margin-top-small'>
              <span>
                <Moment date={report.createdAt.date} format='DD/MM/YYYY HH:mm' />
                {report.createdAt.timezone ? ` ${report.createdAt.timezone} ` : null}
              </span>
              <span>{cat === subCat ? cat : `${cat}, ${subCat}`}, </span>
              {report.filters.fromDate ? <Moment format='DD/MM/YYYY'>{report.filters.fromDate}</Moment> : t('Report.text.any_date')}
              &nbsp;-&nbsp;
              {report.filters.toDate ? <Moment format='DD/MM/YYYY'>{report.filters.toDate}</Moment> : t('Report.text.any_date')}
            </div>
            <div className='o-block o-block--left'>
              <div onClick={() => toggleFilters()} className='u-text--small u-cursor--pointer'>
                <span className='u-float--left'>{showFiltersList ? t('Global:Global.text.hide_filters') : t('Global:Global.text.show_filters')}</span>
                <span className={getArrowClasses()} />
              </div>
              <br />
              {/* Filters list */}
              {showFiltersList && (
                <div className='u-text--small'>
                  {['OrgUnitVarianceReport', 'OrgUnitCostsReport'].includes(report.type) && (
                    <>
                      <span className='u-weight--bold'>{t('Report.field.report_subset')}: </span>
                      <span>
                        {report.filters.orgUnits.length
                          ? report.filters.orgUnits
                            .map(
                              (value) =>
                                types
                                  .find(({ type }) => ['OrgUnitVarianceReport', 'OrgUnitCosts'].includes(type))
                                  .subcategories.find((sub) => sub.value === value)?.label || value
                            )
                            .join(', ')
                          : t('Global:Global.placeholder.any')}
                      </span>
                      <br />
                    </>
                  )}
                  <span className='u-weight--bold'>{t('Report.field.country')}: </span>
                  <span>{report.filters.countryNames.length ? report.filters.countryNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                  <br />
                  <span className='u-weight--bold'>{t('Report.field.company')}: </span>
                  <span>{report.filters.companyNames.length ? report.filters.companyNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                  <br />
                  {showPayrollFilter && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.payroll')}: </span>
                      <span>{report.filters.payrollNames.length ? report.filters.payrollNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                    </span>
                  )}
                  {['OrgUnitVarianceReport', 'OrgUnitCostsReport', 'WorkforceVarianceReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.payrun')}: </span>
                      <span>
                        {report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : t('Global:Global.placeholder.any')}
                      </span>
                      <br />
                    </span>
                  )}
                  {['EmployeeReport', 'OrgUnitVarianceReport', 'WorkforceVarianceReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.type_of_employment_engagement')}: </span>
                      <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                      <span className='u-weight--bold'>{t('Report.field.business_unit')}: </span>
                      <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                      <span className='u-weight--bold'>{t('Report.field.cost_center')}: </span>
                      <span>{report.filters.costCenterNames.length ? report.filters.costCenterNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                    </span>
                  )}
                  {['OrgUnitCostsReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.business_unit')}: </span>
                      <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                      <span className='u-weight--bold'>{t('Report.field.cost_center')}: </span>
                      <span>{report.filters.costCenterNames.length ? report.filters.costCenterNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                    </span>
                  )}
                  {['OrgUnitVarianceReport', 'OrgUnitCostsReport', 'WorkforceVarianceReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.department')}: </span>
                      <span>{report.filters.departmentNames.length ? report.filters.departmentNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                    </span>
                  )}
                  {report.type === 'AuditTrailReport' && (
                    <span>
                      <span className='u-weight--bold'>>{t('Report.field.process_owner')}: </span>
                      <span>{report.filters.processOwnerNames.length ? report.filters.processOwnerNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                    </span>
                  )}
                  {['PayrollInstanceReport', 'EmployeeDataChangesReport', 'VarianceReport', 'GlobalPayrunManagementReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.payrun')}: </span>
                      <span>
                        {report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : t('Global:Global.placeholder.any')}
                      </span>
                      <br />
                    </span>
                  )}
                  {(report.type === 'PayrollReport' || report.type === 'PayrollInstanceReport' || report.type === 'GlobalPayrunManagementReport') && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.show_only_totals')}: </span>
                      <span>{report.filters.showOnlyTotals ? t('Global:Global.button.yes') : t('Global:Global.text.no')}</span>
                      <br />
                    </span>
                  )}
                  {['PayrollInstanceReport', 'EmployeeDataChangesReport', 'GlobalPayrunManagementReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.type_of_employment_engagement')}: </span>
                      <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                    </span>
                  )}
                  {['PayrollReport', 'GlobalPayAndTaxesReport'].includes(report.type) && report.filters.termCategory && (
                    <div>
                      <span>
                        <span className='u-weight--bold'>{t('Report.field.term_category')}: </span>
                        <span>
                          {report.filters.termCategoryNames.length ? report.filters.termCategoryNames.join(', ') : t('Global:Global.placeholder.any')}
                        </span>
                        <br />
                        <span className='u-weight--bold'>{t('Report.field.elements_subcategory')}: </span>
                        <span>
                          {report.filters.termSubCategoryNames.length ? report.filters.termSubCategoryNames.join(', ') : t('Global:Global.placeholder.any')}
                        </span>
                        <br />
                        <span className='u-weight--bold'>{t('Report.field.elements')}: </span>
                        <span>{report.filters.termNames.length ? report.filters.termNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      </span>
                    </div>
                  )}
                  {[
                    'PayrollInstanceReport',
                    'PayrollReport',
                    'EmployeeDataChangesReport',
                    'VarianceReport',
                    'GlobalPayrunManagementReport',
                    'OrgUnitVarianceReport',
                    'WorkforceVarianceReport',
                    'OrgUnitCostsReport',
                  ].includes(report.type) &&
                    report.reportingCurrency.length > 0 && (
                    <div>
                      <span className='u-weight--bold'>{t('Report.field.reporting_currency')}: </span>
                      <span>{report.reportingCurrency.join(', ')}</span>
                    </div>
                  )}
                  {report.type === 'ChangeLogReport' && (
                    <span>
                      <span className='u-weight--bold'>{t('Report.field.payrun')}: </span>
                      <span>
                        {report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : t('Global:Global.placeholder.any')}
                      </span>
                      <br />
                      <span className='u-weight--bold'>{t('Report.field.type_of_employment_engagement')}: </span>
                      <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                      <span className='u-weight--bold'>{t('Report.field.business_unit')}: </span>
                      <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : t('Global:Global.placeholder.any')}</span>
                      <br />
                      <span className='u-weight--bold'>{t('Report.field.modified_by')}: </span>
                      <span>
                        {report.filters.modifiedBy.length && report.filters.modifiedBy.filter((i) => i !== '').length
                          ? report.filters.modifiedBy.join(', ')
                          : t('Global:Global.placeholder.any')}
                      </span>
                      <br />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='o-layout__item u-text--right u-1/1 u-1/3@tablet u-2/6@desktop'>
            <button
              onClick={() => handleRegenerateReport()}
              className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
              data-testid='regenerateReport'
            >
              <RetryBaseIcon />
            </button>
            {hasGraphs && (
              <button
                onClick={() => {
                  props.history.push(`/reporting/analytics/${report.id}`, { from: props.location.pathname })
                }}
                className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
                data-testid='analytics'
              >
                <span className='icon icon--bar-chart' />
              </button>
            )}
            {props.showSave && (
              <button
                className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
                title={t('Global:Global.button.save')}
                onClick={(e) => {
                  e.preventDefault()
                  onSaveReport(report)
                }}
              >
                <span className='icon icon--save' />
              </button>
            )}
            <ReportViewButton
              report={report}
              handleClick={(e) => {
                e.preventDefault()
                props.handleReportModalOpen()
              }}
            />
            <DownloadReportButton report={report} dropdownButtonClass='dropdown-icon-button--right-aligned' />
            <Authorization permissions={['REPORT_DELETE']}>
              <div
                className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
                title={t('Global:Global.text.delete')}
                onClick={() => onDeletePress(report.id)}
              >
                <span className='icon icon--trash' />
              </div>
            </Authorization>
          </div>
        </div>
      </div>
    </div>
  )
}

RecentReportItem.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.object,
    type: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    cat: PropTypes.object.isRequired,
    subCat: PropTypes.object.isRequired,
    feCategoryId: PropTypes.number.isRequired,
    categoryName: PropTypes.string.isRequired,
    availableFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
    filters: PropTypes.shape({
      countryNames: PropTypes.array,
      companyNames: PropTypes.array,
      payrollNames: PropTypes.array,
      businessUnitNames: PropTypes.array,
      costCenterNames: PropTypes.array,
      processOwnerNames: PropTypes.array,
      termSubCategoryNames: PropTypes.array,
      termNames: PropTypes.array,
      employmentStatus: PropTypes.array,
      showOnlyTotals: PropTypes.bool,
      fromDate: PropTypes.object,
      toDate: PropTypes.object,
    }),
  }),
  location: PropTypes.object,
  history: PropTypes.object,
  onDelete: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  onSaveReport: PropTypes.func,
  showSave: PropTypes.bool,
}

export default RecentReportItem
