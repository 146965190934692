import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'

export const InlineTableActionButtons = ({ onCancel = null, onSave = null, isUpdating = false }) => {
  const { t } = useTranslation()

  return (
    <>
      <button
        onClick={onCancel}
        className={`${btnClasses}`}
        disabled={isUpdating}
        title={t('Global.button.cancel')}
        data-testid='general-ledger-account-cancel'
      >
        {t('Global.button.cancel')}
      </button>
      <button
        onClick={onSave}
        className={`${btnClasses} ${isUpdating ? 'c-btn--submitting' : ''}`}
        disabled={isUpdating}
        title={t('Global.button.save')}
        data-testid='inline-action-save'
      >
        {t('Global.button.save')}
      </button>
    </>
  )
}

InlineTableActionButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
}
