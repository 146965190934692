import React from 'react'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import employeeStatusEnum from 'utils/enums/employmentStatuses'
import { download, downloadTemplate } from 'redux/actions/employeeSystemUsers'
import { titleTypes } from 'redux/config/DropDownOptions/titleOptions'
import { createNewEmployeesBulkCreateJob } from 'redux/actions/pollingJob'
import { Trans, useTranslation } from 'react-i18next'
import { i18nNameSpaces } from 'i18n/types'

const TemplateClarifications = () => {
  const { t } = useTranslation([i18nNameSpaces.Import])
  return (
    <div className='u-margin-bottom-small u-text--normal'>
      <SectionHeading text={t('Import.employee_create_heading')} />
      <p className='u-margin-bottom-small'>{t('Import.employee_create_description')}</p>
      <ol className='u-margin-bottom-small'>
        <li>{t('Import.employee_create_step_1')}</li>
        <li>{t('Import.employee_create_step_2')}</li>
        <li>{t('Import.employee_create_step_3')}</li>
        <li>{t('Import.employee_create_step_4')}</li>
        <li>{t('Import.employee_create_step_5')}</li>
      </ol>
      <p className='u-margin-bottom-small'>{t('Import.employee_create_error_message')}</p>
      <b>
        {t('Import.employee_create.note', {
          colonSymbol: ':',
        })}
      </b>
      {t('Import.employee_create_note')}
    </div>
  )
}

const BulkImportView = (props) => {
  const { t } = useTranslation([i18nNameSpaces.Import])

  return (
    <ImportFacade
      {...props}
      heading={t('Import.employee_create_heading')}
      entityName='employees'
      downloadAction={download}
      downloadTemplateAction={() => downloadTemplate()}
      importAction={({ file }) => createNewEmployeesBulkCreateJob(file)}
      TemplateClarifications={TemplateClarifications}
      hasFilters={false}
      isAsync
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading text={t('Import.employee_create_required_fields')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.first_name'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.surname'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.work_email'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.payment_currency'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.company'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.country'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_required_fields.start_date'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
        </div>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading fullWidth text={t('Import.employee_create_validations')} />
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.date_format'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.title'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
              values={{
                value: titleTypes.map((t) => t.label).join(', '),
              }}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.gender'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.mobile_phone'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.office_phone'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.type_of_employment'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
              values={{
                value: employeeStatusEnum.ALL_IMPORT_LABELS.join(', '),
              }}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.full_time_part_time'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.visa_work_permit'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.employee_id'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.marital_status'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.employee_self_service'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.mode2FA'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.payment_type'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.org_unit'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.church_tax'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.tax_class'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.national_insurance_letter'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_dataValidationsLabels.hecs'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.other_address_fields'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.type_of_address'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.country_of_birth'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
          <p className='u-margin-bottom-tiny'>
            <Trans
              i18nKey='Import.employee_create_common_labels.type_of_address'
              ns={i18nNameSpaces.Import}
              tOptions={{
                lng: 'en',
              }}
              components={[<span key='import-requiredFields' className='u-weight--bold' />]}
            />
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}

export default BulkImportView
