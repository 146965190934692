import { extrafieldEntityKey, fixedCategoriesEntity, fixedOrgUnitFields, fixedPayrollInstanceFields } from 'utils/enums/fixedCategoriesField'
import { isArray, isEmpty } from 'utils/fnkit/typeChecks'

/**
 * Filters the data based on the provided countries. If countries is not provided,
 * it returns data without filtering based on countries.
 *
 * @param {Array} data - The array of data objects.
 * @param {Array} [countries] - The optional array of countries to filter by.
 * @returns {Array} The filtered data where each field contains only the specified countries.
 */
export const getSpecificFieldsByCountry = (data, countries) => {
  if (isEmpty(countries)) {
    return data
  }
  return data.map((d) => {
    const fields = d.fields?.filter((f) => {
      if (!isArray(f.countries)) return true
      return f.countries.some((c) => countries.includes(c)) || isEmpty(f.countries)
    })
    return { ...d, fields }
  })
}

export const getEntityCategoryField = (categoryKey, field) => {
  if (fixedPayrollInstanceFields.includes(field)) {
    return 'payrollinstanceemployee'
  }
  if (fixedOrgUnitFields.includes(field)) {
    return 'orgunit'
  }
  const entity = fixedCategoriesEntity[categoryKey]
  return entity || 'employeeuser'
}

export const combineAllCompanyExtrafields = (data) =>
  Object.keys(data).reduce((extrafields, companyId) => {
    return {
      ...extrafields,
      ...data[companyId].properties,
    }
  }, {})

export const extractAllAppSectionExtrafields = (data, fieldsMapper, appSection) => {
  const allCompaniesExtrafield = combineAllCompanyExtrafields(data)
  return Object.keys(allCompaniesExtrafield).reduce((extrafields, extraFieldKey) => {
    const extraField = allCompaniesExtrafield[extraFieldKey]
    const hasFeSection = !isEmpty(extraField.metaData.appSections?.filter((section) => section?.name === appSection))
    if (hasFeSection) {
      const category = extraField.metaData.appSections?.find((s) => s.name === appSection)?.locations?.reduce((_, item) => item.category, '')
      const hasCategory = isArray(extrafields[category])

      if (!hasCategory) {
        extrafields[category] = []
      }
      extrafields[category].push(
        fieldsMapper({
          id: `${extrafieldEntityKey}/${extraFieldKey}`,
          name: extraField.metaData.label,
        })
      )
    }
    return extrafields
  }, {})
}

export const combineFixedAndExtraFieldsByCategory = (fixedFields, extraFields, fieldsMapper, appSection = 'frontEndLocations') => {
  try {
    const appSectionsExtraFields = extractAllAppSectionExtrafields(extraFields, fieldsMapper, appSection)
    Object.keys(fixedFields).map((fixedFieldKey) => {
      const extraField = appSectionsExtraFields[fixedFieldKey]
      if (extraField) {
        fixedFields[fixedFieldKey] = fixedFields[fixedFieldKey].concat(extraField)
      }
    })
  } catch (error) {
    console.error('Error during the data transformation....', error)
  }
  return fixedFields
}
